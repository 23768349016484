import { get, throttle } from "lodash";
import type { Op as DeltaOp } from "quill-delta";
import { Doc } from "sharedb/lib/client";
import type { AddNumOp, ObjectInsertOp, ObjectReplaceOp, Op } from "sharedb/lib/client";

import {
  DATA_PATH,
  DEFAULT_FIELD_VERSION_NUMBER,
  STATIC_CONTENT_FIELD_TYPES,
} from "./shared/constants";
import { RichTextOpType } from "./shared/types/sharedb";
import { FieldType, WorksheetType } from "./shared/types/worksheet";

import { rollbarAndLogError, rollbarAndLogWarning } from "utils/logger";

/*
This file contains the logic for versioning fields in a worksheet.
We keep track of field versions for one simple reason: sometimes instructors edit questions after
the page has already been released. When that happens, we want to notify students that the question
has changed, and give them the option to update their response.

The versioning system works like this:

1. Every field has a version number.
2. When a student creates a response to an editable field, the response data
   records the version number of the field.
3. When an instructor edits a field, we compare the previous version of that field to the
   newly changed version. If these two version are meaningfully different,
   then we increment the version number for the field.
   - The definition of "meaningfully different" is currently
     "any change except whitespace-only changes to text".
     This definition could change over time.
4. When a student views an editable field that they've already written a response for,
   and the version number recorded in the response is different from the version
   number of the field, we show a banner on the page, informing the student that
   the question has changed since they wrote their response.
5. There is a button on the warning banner to dismiss the notification;
   clicking that button updates the version number in the response to match
   the version number in the field.

  TODO: Preferably we'd only check OPs for worksheets on Pages that are released, but worksheets
  themselves do not know about their release state right now, so this is not trivial.
*/

/** Get a key-value mapping from field IDs to current version of that field. */
export function fieldVersions(doc: Doc<WorksheetType>) {
  return doc.data.b.reduce(
    (acc, block) => {
      const blockFields = block.f as FieldType[];
      blockFields.forEach((field) => {
        // eslint-disable-next-line no-param-reassign
        acc[field.id] = field.ver || DEFAULT_FIELD_VERSION_NUMBER;
      });
      return acc;
    },
    {} as Record<string, number>,
  );
}

/** Get two key-value mapping from field IDs to current version of that field.
 * The first mapping is for static fields, the second is for seeded data fields.
 */
export function partitionedFieldVersions(doc: Doc<WorksheetType>) {
  return doc.data.b.reduce(
    ([staticAcc, seededAcc], block) => {
      const blockFields = block.f as FieldType[];
      blockFields.forEach((field) => {
        if (STATIC_CONTENT_FIELD_TYPES.includes(field.t)) {
          // eslint-disable-next-line no-param-reassign
          staticAcc[field.id] = field.ver || DEFAULT_FIELD_VERSION_NUMBER;
        } else {
          // eslint-disable-next-line no-param-reassign
          seededAcc[field.id] = field.ver || DEFAULT_FIELD_VERSION_NUMBER;
        }
      });
      return [staticAcc, seededAcc] as [Record<string, number>, Record<string, number>];
    },
    [{}, {}] as [Record<string, number>, Record<string, number>],
  );
}

/** Do the recorded versions in the data section match
 * the current versions in the blocks section? */
export function dataVersionsMatchFieldVersions(doc: Doc<WorksheetType>, accessId: string) {
  let staticFieldsMatch = true;
  let seededDataFieldsMatch = true;

  const [staticVersions, seededVersions] = partitionedFieldVersions(doc);
  const knownVersions = doc.data.d[accessId]?.versions || {};
  for (const [fieldId, fieldVersion] of Object.entries(knownVersions)) {
    if (fieldId in staticVersions && staticVersions[fieldId] !== fieldVersion) {
      staticFieldsMatch = false;
    }
    if (fieldId in seededVersions && seededVersions[fieldId] !== fieldVersion) {
      seededDataFieldsMatch = false;
    }
  }
  return {
    static: staticFieldsMatch,
    seededData: seededDataFieldsMatch,
    all: staticFieldsMatch && seededDataFieldsMatch,
  };
}

/** Reset all known versions for this accessId to the current field versions. */
export function upgradeDataVersions(doc: Doc<WorksheetType>, accessId: string) {
  const fVersions = fieldVersions(doc);
  const knownVersions = doc.data.d[accessId]?.versions || {};

  const ops = Object.entries(knownVersions)
    .map(([fieldId, knownVersion]) => {
      if (fieldId in fVersions && fVersions[fieldId] !== knownVersion) {
        return {
          p: [DATA_PATH, accessId, "versions", fieldId],
          od: knownVersion,
          oi: fVersions[fieldId],
        } as ObjectReplaceOp;
      }
      return null;
    })
    .filter(Boolean);

  if (ops.length) {
    doc.submitOp(ops);
  }
}

function isOnlyWhiteSpace(str: string) {
  return /^\s*$/.test(str);
}

/** We don't want to increment the version on whitespace-only changes.
 * This function checks if the operation is a whitespace-only change.
 */
function shouldIncrementVersion(doc: Doc<WorksheetType>, op: Op) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
  if ((op as any).t === "rich-text") {
    const richTextOp = op as RichTextOpType;
    const path = richTextOp.p;
    if (path.length > 4 && path[0] === "b" && path[2] === "f") {
      const blockIndexPart = path[1];
      const blockIndex =
        typeof blockIndexPart === "number" ? blockIndexPart : parseInt(blockIndexPart, 10);
      const fieldIndexPart = path[3];
      const fieldIndex =
        typeof fieldIndexPart === "number" ? fieldIndexPart : parseInt(fieldIndexPart, 10);
      const block = doc.data.b[blockIndex];
      if (!block) {
        const totalBlocks = doc?.data?.b?.length;
        // Sometimes deleting the last block will make some components unable to access the path
        // that they previously rendered at, which is expected.
        if (totalBlocks === 0) {
          return false;
        }
        // This shouldn't happen but we're seeing it in the logs so need to get more information.
        rollbarAndLogError(`Field not found in worksheet`, {
          blockIndex,
          docId: doc.id,
          totalBlocks,
          attemptedPath: path.join("."),
        });

        return false;
      }

      const field = block.f[fieldIndex];
      if (field.t === "Text" || field.t === "RichTextField") {
        // RichTextOpType thinks that o is a DeltaStatic, but it's actually a DeltaOp[]
        const deltaOps = richTextOp.o as unknown as DeltaOp[];
        // check if inserted text is only whitespace
        const insertedText = deltaOps
          .filter((o) => o.insert && typeof o.insert === "string")
          .map((o) => o.insert) as string[];
        if (insertedText.length > 0 && isOnlyWhiteSpace(insertedText.join(""))) {
          return false;
        }
        // check if deleted text is only whitespace
        const existingText = (field.v?.ops || []).map((o) => o.insert).join("");
        const retainOp = deltaOps.find((o) => o.retain);
        const deleteOp = deltaOps.find((o) => o.delete);
        if (retainOp && deleteOp) {
          const retainStart = retainOp.retain as number;
          const deletedText = existingText.slice(retainStart, retainStart + deleteOp.delete);
          if (isOnlyWhiteSpace(deletedText)) {
            return false;
          }
        }
      }
    }
  }
  return true;
}

/**
 * This factory function creates a system for incrementing field versions.
 * It returns an `incrementVersion` function that can be called as often as needed,
 * but will only be *executed* at most every 3 seconds. When this function is called,
 * the arguments are added to a queue, which is flushed every 3 seconds.
 * The main purpose is to collect and batch operations that might or might not trigger
 * a version increment on the worksheet fields they are changing.
 */
export const createIncrementVersionQueue = (docCache: Record<string, Doc<WorksheetType>>) => {
  // queue contains tuples of [docId, op]
  let queue: [string, Op][] = [];

  function flushQueue() {
    // Create a hash map to keep track of which fields to update on which doc, without duplicates.
    // The structure is: { docId: { blockIndex: { fieldIndex: true } }
    const fieldsToUpdate = {} as Record<string, Record<string, Record<string, boolean>>>;

    // populate hash map
    queue.forEach(([docId, op]) => {
      try {
        if (!shouldIncrementVersion(docCache[docId], op)) {
          return;
        }
      } catch (e) {
        rollbarAndLogError("Error in version increment logic", e);
        return;
      }

      if (!(docId in fieldsToUpdate)) {
        fieldsToUpdate[docId] = {};
      }
      const path = op.p;
      if (path.length > 4 && path[0] === "b" && path[2] === "f") {
        // this is an instructor editing a question
        const blockIndex = path[1];
        if (!(blockIndex in fieldsToUpdate[docId])) {
          fieldsToUpdate[docId][blockIndex] = {};
        }
        const fieldIndex = path[3];
        fieldsToUpdate[docId][blockIndex][fieldIndex] = true;
      }
    });

    // reset queue
    queue = [];

    // create operations from hash map
    Object.entries(fieldsToUpdate).forEach(([docId, blockIndices]) => {
      // get the doc from the cache
      const doc = docCache[docId];
      if (!doc) {
        // Presumably the doc failed to load, or wasn't loaded by the time this was called. This shouldn't
        // happen, but if it does, we can't really store data to be saved later or show a reasonable error,
        // so we're ok with potentially missing the version update. We log this to make sure our assumptions hold true.
        rollbarAndLogWarning("Doc wasn't in cache when field version update queue run", { docId });
        return;
      }

      // create operations from hash map
      const incrVersionOps = Object.entries(blockIndices).reduce(
        (acc, [blockIndex, fieldIndices]) => {
          const ops = Object.keys(fieldIndices).map((fieldIndex) => {
            const fieldVersionPath = ["b", blockIndex, "f", fieldIndex, "ver"];
            const currentVersion = get(doc.data, fieldVersionPath) as number | undefined;
            if (currentVersion === undefined) {
              return { p: fieldVersionPath, oi: DEFAULT_FIELD_VERSION_NUMBER } as ObjectInsertOp;
            }
            return { p: fieldVersionPath, na: 1 } as AddNumOp;
          });
          return acc.concat(ops);
        },
        [] as (AddNumOp | ObjectInsertOp)[],
      );

      if (!incrVersionOps.length) {
        return;
      }

      // submit operations to increment versions
      doc._submitOp(incrVersionOps, { source: "incrementVersion" }, (err) => {
        if (err) {
          rollbarAndLogError(
            "ShareDB error incrementing field version",
            { docId, path: incrVersionOps.map((o) => o.p.join(".")).join(",") },
            err,
          );
        }
      });
    });
  }

  const scheduleFlushOfQueue = throttle(flushQueue, 3000, { leading: false, trailing: true });

  return {
    // Note that "this" isn't a passed argument, it's just used for typescript inference
    incrementVersion(this: void, doc: Doc<WorksheetType>, op: Op | Op[]) {
      if (Array.isArray(op)) {
        queue = queue.concat(op.map((o) => [doc.id, o]));
      } else {
        queue.push([doc.id, op]);
      }
      scheduleFlushOfQueue();
    },
    flushQueue,
    scheduleFlushOfQueue,
  };
};
