import "./OutcomeInfo.scss";

import { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

import { useFetchAssessmentBaseData } from "components/materials/course/assessment/assessment-data";
import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { AccordionTitle } from "mds/components/AccordionTitle";
import { TabBar } from "mds/components/TabBar";
import { storeApi, useAppSelector } from "store/index";
import {
  NestedCourseOutcomeType,
  selectChunkedPagesForOutcomeByTopic,
  selectPagesForOutcome,
} from "store/selectors";
import { isCoursePage } from "utils/page";
import { courseContentUrl, coursePageUrl } from "utils/urls";

/**
 * The number of pages that must be present before the topics start being collapsed into an accordion.
 */
const MAX_PAGE_AMOUNT_BEFORE_ACCORDION = 6;

/**
 * Displays a list of all pages that are attached to the course outcome,
 * filterable by page type, and collapsible by topic.
 */
export const OutcomeUseIndex = ({ courseOutcome }: { courseOutcome: NestedCourseOutcomeType }) => {
  const pagesForOutcome = useAppSelector((s) => selectPagesForOutcome(s, courseOutcome.id));
  const chunkedPages = useAppSelector((s) =>
    selectChunkedPagesForOutcomeByTopic(s, courseOutcome.id),
  );

  useEffect(() => {
    if (courseOutcome?.id) {
      storeApi.page_outcomes.list({ course_outcome_id: courseOutcome.id });
    }
  }, [courseOutcome?.id]);

  useFetchAssessmentBaseData();

  const tabs = useMemo(() => {
    const topicPages = pagesForOutcome.filter((p) => p.category === "activity_page");
    const assignmentPages = pagesForOutcome.filter((p) => p.category === "assignment");
    const resourcePages = pagesForOutcome.filter((p) => p.category === "course_resource");

    const hrefForPage = (page: PageType) => {
      if (isCoursePage(page)) {
        return coursePageUrl(page.course_id, page.category, page.id);
      }
      const topic = chunkedPages.find((cp) => cp.pages.includes(page));
      return courseContentUrl(courseOutcome.course_id, topic.id, page.id);
    };

    return [
      {
        count: topicPages.length,
        label: (
          <div className="whitespace-nowrap">{`${t("glossary.topics")} (${topicPages.length})`}</div>
        ),
        title: t("glossary.topics"),
        panel: (
          <div className="mt-2 p-2">
            {chunkedPages
              .filter((topicChunk) => topicChunk.pages.length > 0)
              .map((topicChunk) => (
                <AccordionTitle
                  key={topicChunk.id}
                  startOpen={topicPages.length < MAX_PAGE_AMOUNT_BEFORE_ACCORDION}
                  title={topicChunk.title}
                >
                  {topicChunk.pages.map((p) => (
                    <OutcomeUseListPageItem href={hrefForPage(p)} key={p.id} page={p} />
                  ))}
                </AccordionTitle>
              ))}
          </div>
        ),
      },
      {
        count: assignmentPages.length,
        label: (
          <div className="whitespace-nowrap">{`${t("glossary.assignments")} (${assignmentPages.length})`}</div>
        ),
        title: t("glossary.assignments"),
        panel: (
          <div className="mt-2 p-2">
            {assignmentPages.map((p) => (
              <OutcomeUseListPageItem href={hrefForPage(p)} key={p.id} page={p} />
            ))}
          </div>
        ),
      },
      {
        count: resourcePages.length,
        label: (
          <div className="whitespace-nowrap">
            {`${t("glossary.course_resources")} (${resourcePages.length})`}
          </div>
        ),
        title: t("glossary.course_resources"),
        panel: (
          <div className="mt-2 p-2">
            {resourcePages.map((p) => (
              <OutcomeUseListPageItem href={hrefForPage(p)} key={p.id} page={p} />
            ))}
          </div>
        ),
      },
    ].filter((tab) => tab.count > 0);
  }, [pagesForOutcome, chunkedPages, courseOutcome.course_id]);

  return (
    <div className="pb-6">
      <h3 className="h3 m-0 mb-3 text-black-tint-40">{t("outcomes.usage.title")}</h3>
      {!pagesForOutcome?.length && (
        <div className="body-s rounded bg-black-tint-97 p-4 text-black-tint-20">
          {t("outcomes.usage.not_using_yet", { outcome_title: courseOutcome.outcome.title })}
        </div>
      )}
      {Boolean(pagesForOutcome?.length) && (
        <div>
          <TabBar kind="secondary" tabs={tabs} />
        </div>
      )}
    </div>
  );
};

export const OutcomeUseListPageItem = ({ page, href }: { page: PageType; href: string }) => {
  return (
    <div className="body-s flex items-center justify-between">
      <Link to={href}>{page.title}</Link>
      {page.max_points > 0 && (
        <div className="text-black-tint-40">
          {t("rubric_card.assessment_points_other", { count: page.max_points })}
        </div>
      )}
    </div>
  );
};
