// This is copied in from CourseBuilder's `edit-delete-widget.js` file with some edits.
import clsx from "clsx";
import type { CSSProperties, FC, MouseEvent } from "react";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { AddPlusIcon, PencilEditIcon, TrashDeleteIcon } from "mds/icons";

type EditDeleteWidgetProps = {
  classNames?: string;
  hover: boolean;
  onClickAdd?: () => void;
  onClickDelete?: () => void;
  onClickEdit?: () => void;
  /**
   * The reference is used to provide aria-label for the edit and delete buttons.
   * If you are adding a new reference type, please add the corresponding translation strings
   * for it in en.json under the edit_delete_widget section.
   * TODO: It'd be nice to try to get KeyWithContext/context on the tfunction to validate that
   *       all values in this union are applicable json keys. */
  reference:
    | "activity"
    | "archive"
    | "assignment"
    | "instructor_workspace"
    | "lesson"
    | "term"
    | "course_code"
    | "course_resource"
    | "course"
    | "outcome"
    | "page"
    | "title"
    | "topic";
  size?: "xs" | "s" | "m" | "l";
  styles?: CSSProperties;
};

export const EditDeleteWidget: FC<EditDeleteWidgetProps> = ({
  hover,
  reference,
  styles,
  onClickEdit,
  onClickDelete,
  onClickAdd,
  classNames,
  size = "xs",
}) => {
  return (
    <div
      className={clsx("edit-delete-widget ml-4 flex self-start", classNames, {
        hidden: !hover,
      })}
      style={styles}
    >
      {onClickAdd ? (
        <Button
          className="ml-2"
          kind="secondary"
          size="xs"
          title={t("edit_delete_widget.add", { context: reference })}
          iconOnly
          onClick={(e: MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            onClickAdd();
          }}
        >
          <AddPlusIcon />
        </Button>
      ) : null}
      {onClickEdit ? (
        <Button
          className="ml-2"
          kind="secondary"
          size={size}
          title={t("edit_delete_widget.edit", { context: reference })}
          iconOnly
          onClick={(e: MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            onClickEdit();
          }}
        >
          <PencilEditIcon />
        </Button>
      ) : null}
      {onClickDelete ? (
        <Button
          className="ml-2"
          kind="destructive"
          size={size}
          title={t("edit_delete_widget.delete", { context: reference })}
          iconOnly
          onClick={(e: MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            onClickDelete();
          }}
        >
          <TrashDeleteIcon />
        </Button>
      ) : null}
    </div>
  );
};
