import "./EmailLoginForm.scss";

import { type FC, ReactNode, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { emailMustBeValid, emailMustHaveAtSign } from "../../helpers/password-errors";

import { serverLogin } from "api/api-server";
import { LOGO_FORM_SIZE } from "components/constants";
import { ErrorMessageBanner } from "components/forms/ErrorMessageBanner";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { ForumLogoIcon } from "mds/icons";

interface EmailLoginFormValue {
  email: string;
  password: string;
}

interface EmailLoginFormProps {
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  children?: ReactNode | ReactNode[];
}

export const EmailLoginForm: FC<EmailLoginFormProps> = ({ setIsLoggedIn, children }) => {
  const [passwordResetMessage, setPasswordResetMessage] = useState<boolean>(false);
  const form = useForm<EmailLoginFormValue>();

  const handleSubmit: SubmitHandler<EmailLoginFormValue> = (formData) => {
    serverLogin(formData.email, formData.password)
      .then(() => {
        setIsLoggedIn(true);
      })
      .catch(() => {
        form.setError("password", {
          type: "server",
          message: t("error.incorrect_email_or_password"),
        });
      });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get("password_reset") === "true") {
      setPasswordResetMessage(true);
    }
  }, []);

  return (
    <div className="m-2 w-full max-w-[18rem] p-4 md:w-96">
      {/* TODO: Rewrite main-navbar so that we don't need !pl-0 override its properties */}
      <div className="main-navbar mb-3 flex items-center justify-between bg-white !pl-0 text-black-tint-20">
        <span className="mb-2 flex cursor-pointer items-center">
          <ForumLogoIcon width={LOGO_FORM_SIZE} />
        </span>
      </div>

      <h2 className="mb-3">{t("user_info.login_title")}</h2>

      {passwordResetMessage && (
        <span className="mb-2 flex cursor-pointer">
          <p className=" ">{t("user_info.login_after_password_reset")}</p>
        </span>
      )}

      <FormProvider {...form}>
        <form
          className="mb-3 flex w-full flex-col gap-3"
          noValidate
          onSubmit={form.handleSubmit(handleSubmit)}
        >
          <div>
            <label>
              <div className="body-m-bold">{t("user_info.email")}</div>

              <input
                {...form.register("email", {
                  required: t("error.field_required"),
                  validate: {
                    emailMustHaveAtSign,
                    emailMustBeValid,
                  },
                })}
                autoComplete="email"
                className="email-login-form-input"
                id="email"
                type="email"
              />
            </label>

            <ErrorMessageBanner className="body-s mt-1 w-full text-wrap break-all" name="email" />
          </div>

          <div>
            <label>
              <div className="body-m-bold">{t("user_info.password")}</div>

              <input
                {...form.register("password", {
                  required: t("error.field_required"),
                })}
                autoComplete="current-password"
                className="email-login-form-input"
                id="password"
                type="password"
              />
            </label>

            <ErrorMessageBanner className="body-s mt-1 w-full" name="password" />
          </div>

          {/* This invisible field is required for LastPass to recognize the autofill */}
          <input className="sr-only" type="submit" value="Login" />

          <Button className="mt-3" kind="primary" type="submit" value="Login">
            {t("user_info.sign_in")}
          </Button>

          {children}
        </form>
      </FormProvider>

      <Link
        className="body-s mb-2 mt-8 block cursor-pointer text-black-tint-40 underline"
        id="forgot-password"
        to="/forgot-password"
      >
        {t("user_info.forgot_password")}
      </Link>
    </div>
  );
};
