import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const CircleExclamationIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M8.00049 8.33337V4.33337"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="fill"
      d="M8.00033 10.3334C7.63214 10.3334 7.33366 10.6318 7.33366 11C7.33366 11.3682 7.63214 11.6667 8.00033 11.6667C8.36852 11.6667 8.66699 11.3682 8.66699 11C8.66699 10.6318 8.36852 10.3334 8.00033 10.3334Z"
    />
  </svg>
);
