import "./TabBar.scss";

import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import clsx from "clsx";
import { FC, Fragment, ReactNode, memo } from "react";
import { Link } from "react-router-dom";

import { forwardRefComponent } from "mds/utils/components";

export type ComponentTabItem = {
  label: ReactNode;
  className?: string;
  title: string;
  panel?: ReactNode;
  tabVariant?: string;
  to?: string;
  disabled?: boolean;
};

export type StringTabItem = {
  label: string;
  className?: string;
  panel?: ReactNode;
  tabVariant?: string;
  to?: string;
  disabled?: boolean;
};

export type TabItem = ComponentTabItem | StringTabItem;

type ContentTabBarProps = {
  tabs: TabItem[];
  onChange?: (tabIndex: number) => void;
  selectedIndex?: number;
  kind?: "primary" | "secondary";
  contentOnly?: boolean;
  className?: string;
  tabListClassName?: string;
  tabListEnd?: ReactNode;
};

// TODO: The memo call here might make the component unresponsive to screen size changes.
// Verify and fix  if necessary.
/**
 * This is tab bar component used for course content.  It's a simple tab
 * component styled for icons.  Consumers of this component will pass in
 * a list of tabs with labels and panels.
 */
export const TabBar: FC<ContentTabBarProps> = memo(
  ({
    onChange,
    tabs,
    selectedIndex,
    kind = "primary",
    contentOnly,
    className,
    tabListClassName,
    tabListEnd,
  }) => {
    const getTitle = (tabItem: TabItem) => ("title" in tabItem ? tabItem.title : tabItem.label);
    const baseClass = `tab-bar-${kind}`;

    const tabItems = tabs.map((tabItem) => {
      const title = getTitle(tabItem);
      const TabComponent = forwardRefComponent(() => tabItem.label);

      return (
        <Tab
          as={Link}
          className={clsx("tab -mb-0.5 disabled:text-black-tint-55", tabItem.className)}
          disabled={tabItem.disabled}
          key={title}
          title={title}
          to={tabItem.to}
          onClick={(e) => {
            if (!tabItem.to) {
              e.preventDefault();
              onChange?.(tabs.indexOf(tabItem));
            }
          }}
        >
          <TabComponent />
        </Tab>
      );
    });

    const tabPanels = tabs.map((tabItem) => {
      const PanelComponent = forwardRefComponent(() => tabItem.panel, {
        className: `${baseClass}-panel w-full h-full`,
      });

      return (
        <TabPanel as={Fragment} key={getTitle(tabItem)} tabIndex={-1}>
          <PanelComponent />
        </TabPanel>
      );
    });

    return (
      <TabGroup
        className={clsx(baseClass, className, { "content-only": contentOnly })}
        selectedIndex={selectedIndex}
        onChange={onChange}
      >
        <div className="flex w-full items-center justify-between md:mb-6">
          <TabList
            className={clsx(`${baseClass}-list grow items-center`, tabListClassName, {
              "!hidden": contentOnly,
            })}
          >
            {tabItems}
          </TabList>
          {tabListEnd}
        </div>
        <TabPanels className={`${baseClass}-content`}>{tabPanels}</TabPanels>
      </TabGroup>
    );
  },
  (prevProps, nextProps) =>
    (["tabs", "selectedIndex", "contentOnly"] as const).every(
      (propName) => prevProps[propName] === nextProps[propName],
    ),
);

TabBar.displayName = "TabBar";
