import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const LongFormPencilIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M12.6666 1L14.9999 3.33333L7.53327 10.8L4.2666 11.7333L5.19993 8.46667L12.6666 1Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M10.7998 2.86664L13.1331 5.19997"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M13.1333 9.39997V13.6C13.1333 14.3728 12.5061 15 11.7333 15H2.4C1.6272 15 1 14.3728 1 13.6V4.26664C1 3.49384 1.6272 2.86664 2.4 2.86664H6.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
