import { compareAsc } from "date-fns";
import { type FC, Fragment, useEffect } from "react";

import { useFetchAssessmentStatus } from "./course/assessment/assessment-data";
import { PagesCard } from "./topic/activity/detail/PagesCard";

import { isDueSoon, isInLateSubmissionWindow } from "components/materials/page/helpers";
import type { ActivityType, PageCategory, TopicType } from "components/server-types";
import { t } from "i18n/i18n";
import { Card } from "mds/components/Card";
import { HorizontalDivider } from "mds/components/HorizontalDivider";
import { IconText } from "mds/components/IconText";
import { useOrdered } from "mds/hooks/use-ordered";
import { CalendarIcon } from "mds/icons";
import { storeApi, useAppSelector } from "store/index";
import {
  type PageWithDueDateType,
  selectActivities,
  selectAllPagesWithDueDatesInCurrentCourse,
  selectCurrentCourse,
  selectVisibleTopicsForCurrentCourse,
} from "store/selectors";
import { useListSelector } from "store/store-hooks";

type AssignedPagesListCategory = "topic" | "course" | "all";
type AssignedPagesListTimeframe = "due-soon" | "late-only" | "all";

type AssignedPagesListProps = {
  category?: AssignedPagesListCategory;
  timeframe?: AssignedPagesListTimeframe;
};

type TopicWithPages = {
  topicIndex: number;
  activity: ActivityType;
  pages: PageWithDueDateType[];
};

// This function returns pages either sorted by due date and category or by topic depending
// on if we are showing it by topic.
// eslint-disable-next-line react-refresh/only-export-components
export const useAssignedPagesList = (
  category: AssignedPagesListCategory,
  timeframe: AssignedPagesListTimeframe,
  topics: TopicType[],
  activities: ActivityType[],
) => {
  const pagesWithDueDates = useListSelector(selectAllPagesWithDueDatesInCurrentCourse);

  const sortPageByTopic = (a: PageWithDueDateType, b: PageWithDueDateType) => {
    const aActivity = activities.find((activity) => activity.id === a.activity_id);
    const bActivity = activities.find((activity) => activity.id === b.activity_id);
    if (aActivity && bActivity) {
      if (aActivity.topic_id !== bActivity.topic_id) {
        const aTopic = topics.find((topic) => topic.id === aActivity.topic_id);
        const bTopic = topics.find((topic) => topic.id === bActivity.topic_id);
        if (aTopic && bTopic) {
          return aTopic.order - bTopic.order;
        }
      }

      if (aActivity.id !== bActivity.id) {
        return aActivity.order - bActivity.order;
      }
    }

    return a.order - b.order;
  };

  const pages = pagesWithDueDates.filter((page) => {
    if (timeframe === "due-soon") {
      return isDueSoon(page);
    }

    if (timeframe === "late-only") {
      return isInLateSubmissionWindow(page);
    }

    return true;
  });

  useFetchAssessmentStatus(pages);

  if (category === "topic") {
    return pages.sort(sortPageByTopic);
  }

  return pages.sort((a, b) => {
    // This sorting function is a bit complicated.
    // First, we want to sort by due date, with the earliest due date first.
    if (a.due_at !== b.due_at) {
      return compareAsc(a.due_at, b.due_at);
    }
    // Next, order by page type:
    // pages in topics first, then assignment pages, then course resources.
    if (a.category !== b.category) {
      // TODO: move this
      const pageTypeOrder: PageCategory[] = [
        "activity_page",
        "assignment",
        "course_resource",
        "instructor_workspace",
      ];

      return pageTypeOrder.indexOf(a.category) - pageTypeOrder.indexOf(b.category);
    }

    // For pages in topics, order by topic order, then by activity order, then by page order within activity
    if (a.category === "activity_page") {
      sortPageByTopic(a, b);
    }

    // For all other types of pages, order by page order
    return a.order - b.order;
  });
};

// eslint-disable-next-line react-refresh/only-export-components
export const orderPagesByTopic = (
  pages: PageWithDueDateType[],
  topics: TopicType[],
  activities: ActivityType[],
): TopicWithPages[] => {
  let currentActivity = activities.find((activity) => activity.id === pages[0]?.activity_id);
  let topicIndex = topics.findIndex((topic) => topic.id === currentActivity?.topic_id);

  const topicsByIndex = pages.reduce(
    (acc, page) => {
      // Performance Optimization: Instead of looping through topics and activities every time,
      // we only update indexes if the activity ID changes. This is slightly less simple, but
      // we call this ordering logic quite often, so it might be worth it.
      if (currentActivity?.id !== page.activity_id) {
        currentActivity = activities.find((activity) => activity.id === page.activity_id);
        topicIndex = topics.findIndex((topic) => topic.id === currentActivity?.topic_id);
      }

      if (!acc[topicIndex]) {
        // eslint-disable-next-line no-param-reassign
        acc[topicIndex] = { topicIndex: topicIndex + 1, activity: currentActivity, pages: [] };
      }

      acc[topicIndex].pages.push(page);

      return acc;
    },
    {} as Record<number, TopicWithPages>,
  );

  return Object.values(topicsByIndex);
};

const HEADER_KEYS = {
  "due-soon": "due_soon",
  "late-only": "accepting_late",
  all: "all",
} as const;

export const AssignedPagesList: FC<AssignedPagesListProps> = ({
  category = "all",
  timeframe = "all",
}) => {
  const course = useAppSelector(selectCurrentCourse);
  const visibleTopics = useOrdered(useListSelector(selectVisibleTopicsForCurrentCourse));
  const activities = useListSelector(selectActivities);

  const pages = useAssignedPagesList(category, timeframe, visibleTopics, activities);

  useEffect(() => {
    if (!course?.id) {
      return;
    }
    storeApi.pages.list({ course_id: course.id });
    storeApi.activities.list({ topic__course_id: course.id });
    storeApi.topics.list({ course_id: course.id });
  }, [course?.id]);

  if (category === "topic") {
    const nonAssignmentPages = pages.filter((page) => page.activity_id);
    const pagesByTopic = orderPagesByTopic(nonAssignmentPages, visibleTopics, activities);

    return pagesByTopic.map(({ topicIndex, activity, pages: topicPages }) => (
      <Fragment key={topicIndex}>
        <PagesCard
          activities={activity ? [activity] : []}
          activity_id={activity?.id}
          header={t("topic_sidebar.topic_and_order", { order: topicIndex })}
          pages={topicPages}
        />

        <HorizontalDivider className="mb-3 mt-5 w-full" />
      </Fragment>
    ));
  }

  const headerKey = HEADER_KEYS[timeframe];

  const header = (
    <IconText iconStart={<CalendarIcon />} text={t(`course.sidebar.planner.${headerKey}`)} />
  );

  if (!pages.length) {
    return (
      <>
        <div className="m-2 text-black-tint-40">{header}</div>
        <Card small>
          <div className="body-s p-2 font-light text-black-tint-40">
            {t("course.sidebar.planner.no_pages", { context: headerKey })}
          </div>
        </Card>
      </>
    );
  }

  return <PagesCard activities={activities} header={header} pages={pages} />;
};
