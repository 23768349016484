import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const PageListIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M10.9821 3.54541H0.800293V15H10.9821V3.54541Z"
      strokeLinecap="square"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      className="stroke"
      d="M2.70947 1H13.5277V13.7273"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      className="stroke"
      d="M3.3457 6.72729H8.43661"
      strokeLinecap="round"
      strokeMiterlimit="10"
    />
    <path
      className="stroke"
      d="M3.3457 9.27271H8.43661"
      strokeLinecap="round"
      strokeMiterlimit="10"
    />
    <path
      className="stroke"
      d="M3.3457 11.8181H5.89116"
      strokeLinecap="round"
      strokeMiterlimit="10"
    />
  </svg>
);
