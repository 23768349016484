import { isEqual, sortBy } from "lodash";

import {
  createAppSelector,
  selectCourseOutcomes,
  selectOutcomeGroups,
  selectOutcomeSubgroups,
  selectOutcomes,
  selectPageOutcomes,
} from "./base";
import { selectCurrentOrg } from "./course";
import {
  FullRubricType,
  NestedCourseOutcomeType,
  NestedOutcomeGroupType,
  NestedOutcomeSubgroupType,
  NestedPageOutcomeType,
} from "./types";

import { defaultRubricDescriptions, defaultRubricNames } from "utils/outcome";

export const selectNestedOutcomeGroups = createAppSelector(
  [selectOutcomeGroups, selectOutcomeSubgroups, selectOutcomes],
  (outcome_groups, outcome_subgroups, outcomes) => {
    return outcome_groups.map((outcome_group) => {
      let outcomeCount = 0;

      const filteredOutcomes = outcomes.filter(
        (outcome) => outcome.outcome_group_id === outcome_group.id,
      );
      outcomeCount += filteredOutcomes.length;

      const filteredOutcomeSubgroups = outcome_subgroups
        .filter((outcome_subgroup) => outcome_subgroup.outcome_group_id === outcome_group.id)
        .map<NestedOutcomeSubgroupType>((outcome_subgroup) => {
          const filteredSubgroupOutcomes = outcomes.filter(
            (outcome) => outcome.outcome_subgroup_id === outcome_subgroup.id,
          );

          outcomeCount += filteredSubgroupOutcomes.length;

          return {
            ...outcome_subgroup,
            outcomes: filteredSubgroupOutcomes,
          };
        });

      return {
        ...outcome_group,
        outcome_subgroups: filteredOutcomeSubgroups,
        outcomes: filteredOutcomes,
        outcome_count: outcomeCount,
      } as NestedOutcomeGroupType;
    });
  },
);

export const selectCourseOutcomesForCourse = createAppSelector(
  [(state, courseId: string) => courseId, selectCourseOutcomes],
  (courseId, course_outcomes) =>
    course_outcomes.filter((course_outcome) => course_outcome.course_id === courseId),
);

export const selectFullCourseOutcomes = createAppSelector(
  [(state, courseId: string) => courseId, selectCourseOutcomes, (state) => state.outcomes],
  (courseId, course_outcomes, outcomes) => {
    return course_outcomes
      .filter((course_outcome) => course_outcome.course_id === courseId)
      .reduce((acc, course_outcome) => {
        const outcome = outcomes[course_outcome.outcome_id];
        if (outcome) {
          acc.push({
            ...course_outcome,
            outcome,
          } as NestedCourseOutcomeType);
        }
        return acc;
      }, [] as NestedCourseOutcomeType[]);
  },
);

export const selectNestedPageOutcomes = createAppSelector(
  [
    (state, pageId: string) => pageId,
    selectPageOutcomes,
    (state) => state.course_outcomes,
    (state) => state.outcomes,
  ],
  (pageId, page_outcomes, course_outcomes, outcomes) => {
    return page_outcomes
      .filter((page_outcome) => page_outcome.page_id === pageId)
      .reduce((acc, page_outcome) => {
        const courseOutcome = course_outcomes[page_outcome.course_outcome_id];
        const outcome = outcomes[courseOutcome?.outcome_id];
        if (courseOutcome && outcome) {
          acc.push({
            ...page_outcome,
            outcome,
            course_outcome: courseOutcome,
          } as NestedPageOutcomeType);
        }
        return acc;
      }, [] as NestedPageOutcomeType[]);
  },
);

export const selectOrgOutcomeRubric = createAppSelector(
  [(state, orgOutcomeId: string) => orgOutcomeId, (state) => state.outcomes, selectCurrentOrg],
  (orgOutcomeId, outcomes, org) => {
    const outcome = outcomes[orgOutcomeId];

    if (!org || !outcome) return null;

    const descriptions = outcome.rubric_descriptions || defaultRubricDescriptions();
    const names = defaultRubricNames();

    return org.rubric.map(
      (rubric, index) =>
        ({
          name: names[index],
          score: rubric.score,
          description: descriptions[index],
        }) as FullRubricType,
    );
  },
);

export const selectCourseOutcomeRubric = createAppSelector(
  [
    (state, courseOutcomeId: string) => courseOutcomeId,
    (state) => state.course_outcomes,
    (state) => state.outcomes,
    selectCurrentOrg,
  ],
  (courseOutcomeId, course_outcomes, outcomes, org) => {
    const course_outcome = course_outcomes[courseOutcomeId];
    const outcome = outcomes[course_outcome?.outcome_id];

    if (!course_outcome || !org || !outcome) return null;

    const descriptions =
      course_outcome.rubric_descriptions ||
      outcome.rubric_descriptions ||
      defaultRubricDescriptions();
    const names = defaultRubricNames();

    return org.rubric.map(
      (rubric, index) =>
        ({
          name: names[index],
          score: rubric.score,
          description: descriptions[index],
        }) as FullRubricType,
    );
  },
);

export const selectPageOutcomeRubric = createAppSelector(
  [
    (state, pageOutcomeId: string) => state.page_outcomes[pageOutcomeId],
    (state) => state.course_outcomes,
    (state) => state.outcomes,
    selectCurrentOrg,
  ],
  (page_outcome, course_outcomes, outcomes, org) => {
    const courseOutcome = course_outcomes[page_outcome?.course_outcome_id];
    const outcome = outcomes[courseOutcome?.outcome_id];

    if (!page_outcome || !courseOutcome || !outcome || !org) return null;

    const descriptions =
      page_outcome.rubric_descriptions ||
      courseOutcome.rubric_descriptions ||
      outcome.rubric_descriptions ||
      defaultRubricDescriptions();
    const names = defaultRubricNames();

    return org.rubric.map(
      (rubric, index) =>
        ({
          name: names[index],
          score: rubric.score,
          description: descriptions[index],
          usingPageRubric: Boolean(
            page_outcome.rubric_descriptions?.[index] &&
              !isEqual(
                page_outcome.rubric_descriptions[index],
                courseOutcome.rubric_descriptions?.[index],
              ),
          ),
        }) as FullRubricType,
    );
  },
);

export const selectPageOutcomesByPageId = createAppSelector(
  [selectPageOutcomes, (state, pageId: string) => pageId],
  (pageOutcomes, pageId) => pageOutcomes.filter((po) => po.page_id === pageId),
);

export const selectOrderedRubricForPage = createAppSelector(
  [selectPageOutcomesByPageId, (state) => state.course_outcomes, (state) => state.outcomes],
  (page_outcomes, course_outcomes, outcomes) => {
    const sortedPageOutcomes = sortBy(page_outcomes, (po) => {
      const co = course_outcomes[po.course_outcome_id];
      const o = outcomes[co.outcome_id];
      return o.order;
    });

    return sortedPageOutcomes.map((po) => {
      const co = course_outcomes[po.course_outcome_id];
      const o = outcomes[co.outcome_id];
      return {
        page_outcome_id: po.id,
        title: o.title,
        rubric_descriptions:
          po.rubric_descriptions ||
          co.rubric_descriptions ||
          o.rubric_descriptions ||
          defaultRubricDescriptions(),
      };
    });
  },
);
