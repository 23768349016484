import "./AIChatPanel.scss";

import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

import { BlockType, FieldType } from "../../worksheets/shared/types/worksheet";
import { createId } from "../../worksheets/shared/utils";

import { AISettings, AISettingsType, AI_DEFAULT_SETTINGS, AI_MODEL_OPTIONS } from "./AISettings";
import {
  AIChatContextType,
  AI_CONTEXT_TO_SELECTOR,
  CREATE_BLOCK_ACTION_HOOK_REGEX,
  ChatMessage,
  DEFAULT_AI_INTRO_MESSAGE,
  getAIPageBlockCreationQuery,
  getAIViewContextQuery,
} from "./chat-query";
import { docToPageAndAnswerContext } from "./query";
import {
  CURRENT_PROMPT_STORAGE_KEY,
  MESSAGES_LOCAL_STORAGE_KEY,
  SETTINGS_LOCAL_STORAGE_KEY,
} from "./storage";

import { serverGetAIResponse } from "api/api-server";
import { ACCESS_ID_QUERY_PARAM, PAGE_ID_QUERY_PARAM } from "components/constants";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { Card } from "mds/components/Card";
import { TextArea } from "mds/components/TextArea";
import { ToggleSwitch } from "mds/components/ToggleSwitch";
import { CircleArrowRightIcon, LoadingDotsIcon } from "mds/icons";
import { docCache } from "providers/ShareDBDoc";
import { store, useAppDispatch, useAppSelector } from "store/index";
import {
  selectCurrentCourseUser,
  selectCurrentUserIsMPAdmin,
  selectIsStudentResponsesForAIEnabled,
  selectUserDocInfoByPageId,
} from "store/selectors";
import { localStateActions } from "store/slices/local";
import { trackEvent } from "utils/amplitude";
import { useFooterSpacingIfMobileBanner } from "utils/hooks/use-footer-spacing-if-mobile-banner";

export const Message = ({ message }: { message: ChatMessage }) => {
  // TODO: If message is loading, show loading indicator
  return (
    <div
      className={clsx("chat-message m-1 p-2", {
        "-is-query": message.isQuery,
      })}
    >
      {message.message}
      {message.isLoading && <LoadingDotsIcon />}
    </div>
  );
};

const getDefaultMessage = (isStudent: boolean, isStudentResponsesForAIEnabled: boolean) => {
  if (isStudentResponsesForAIEnabled) {
    return DEFAULT_AI_INTRO_MESSAGE;
  }

  const enableMessage: string = isStudent
    ? t("ai_chat_panel.default_student_response_text")
    : t("ai_chat_panel.default_instructor_response_text");

  return {
    ...DEFAULT_AI_INTRO_MESSAGE,
    message: `${DEFAULT_AI_INTRO_MESSAGE.message} ${enableMessage}`,
  };
};

export const AIChatPanel = ({ context }: { context: AIChatContextType }) => {
  const messageBoxRef = useRef<HTMLDivElement>(null);
  const isMPAdmin = useAppSelector(selectCurrentUserIsMPAdmin);
  const [input, setInput] = useLocalStorage(CURRENT_PROMPT_STORAGE_KEY, "");
  const [messages, setMessages] = useState<ChatMessage[]>(
    (JSON.parse(localStorage.getItem(MESSAGES_LOCAL_STORAGE_KEY)) as ChatMessage[]) || [
      DEFAULT_AI_INTRO_MESSAGE,
    ],
  );
  const [settings, setSettings] = useLocalStorage<AISettingsType>(
    SETTINGS_LOCAL_STORAGE_KEY,
    AI_DEFAULT_SETTINGS,
  );
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const lastMessage = messages[messages.length - 1]?.message;
  const lastLine = lastMessage?.split("\n").pop() || "";
  const showConfirmationUI = CREATE_BLOCK_ACTION_HOOK_REGEX.test(lastLine);
  const isStudentResponsesForAIEnabled = useAppSelector(selectIsStudentResponsesForAIEnabled);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const courseUser = useAppSelector(selectCurrentCourseUser);
  const isStudent = courseUser?.role === "student";
  const defaultMessage = getDefaultMessage(isStudent, isStudentResponsesForAIEnabled);

  // Save messages to localStorage when they change
  useEffect(() => {
    // On message changes, scroll all the way down, since otherwise the user might miss messages
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
    }

    localStorage.setItem(MESSAGES_LOCAL_STORAGE_KEY, JSON.stringify(messages));
  }, [messages]);

  // This ensures that when we change the default models that users were on before,
  // we can force them to the new default.
  useEffect(() => {
    if (!AI_MODEL_OPTIONS.includes(settings.aiModel)) {
      setSettings({ ...settings, aiModel: AI_DEFAULT_SETTINGS.aiModel });
    }
  }, [settings, setSettings]);

  const disabled = input === "";

  const handleAIQuery = () => {
    if (disabled) {
      return;
    }

    const state = store.getState();
    const selectAiContext = AI_CONTEXT_TO_SELECTOR[context];
    const pageId = searchParams.get(PAGE_ID_QUERY_PARAM);
    const aiContext = selectAiContext(state, pageId);
    const accessId = searchParams.get(ACCESS_ID_QUERY_PARAM);
    const studentDocInfo = selectUserDocInfoByPageId(state, pageId, accessId);

    const newId = messages.length;
    const response = {
      message: "",
      isQuery: false,
      id: newId + 1,
      isLoading: true,
    } as ChatMessage;
    const inputMessage = { message: input, isQuery: true, id: newId };
    setMessages((msgs) => [...msgs, inputMessage, response]);

    // TODO: cache
    try {
      const pageContentContext = ["topic", "course_page"].includes(context)
        ? docToPageAndAnswerContext(null, studentDocInfo)
        : undefined;

      const query = getAIViewContextQuery(
        settings.systemPrompt || t("ai_chat_panel.default_system_prompt"),
        aiContext,
        pageContentContext,
        [...messages, inputMessage],
      );

      // TODO: Later log the title of the Page (if viewing a page).
      trackEvent("AI Assistant - Send message", {
        eventCategory: "Button press",
        messageLength: input.length,
        message: input,
        messageNumber: newId,
        pageUrl: window.location.href,
        context,
        shareStudentResponse: isStudentResponsesForAIEnabled,
      });
      serverGetAIResponse(query, { model: settings.aiModel }, (chunk: string) => {
        if (chunk) {
          // Note that this does not change the number of messages in the data structure
          // because we are updating the existing message object.  So for each query, we will wind up with
          // one entry for the query and one for the AI response.
          response.message += chunk;
          setMessages((msgs) => [...msgs]);
          // Note: this tracking will need to be adapted later if we chunk the response messages.
          // Currently responses are always returned as one big string.
          // Note: Amplitude will truncate automatically the message if its over 1024 characters.
          // See https://amplitude.com/docs/faq/limits
          trackEvent("AI Assistant - Response received", {
            eventCategory: "General",
            messageLength: chunk.length,
            message: chunk,
          });
        }
      })
        .then(() => {
          response.isLoading = false;
          setMessages((msgs) => [...msgs]);
        })
        .catch(() => {
          response.isLoading = false;
          response.message = t("error.toasts.get_ai_response_failure");
          setMessages((msgs) => [...msgs]);
        });
    } catch (e) {
      console.error(e);
      response.message = t("error.toasts.get_ai_response_failure");
      response.isLoading = false;
      setMessages((msgs) => [...msgs]);
    }

    setInput("");
    textAreaRef.current.value = "";
  };

  // This is only used for the experimental feature (currently disabled) for the AI to create blocks.
  const onActionResponse = (confirmed: boolean) => {
    const n = messages.length;
    const responseText = t(`common.${confirmed ? "yes" : "no"}`);
    const userResponseMessage = { message: responseText, isQuery: true, id: n, isLoading: false };
    const loadingMessage = { message: "", isQuery: false, id: n + 1, isLoading: true };
    if (confirmed) {
      setMessages((msgs) => [...msgs, userResponseMessage, loadingMessage]);
    } else {
      setMessages((msgs) => [...msgs, userResponseMessage]);
      return;
    }

    const { current: currentDoc } = docCache;
    const actionMessageContent = messages[n - 1].message;
    const actionPrompt = getAIPageBlockCreationQuery(actionMessageContent);

    // TODO: Add additional backend param that forces JSON response
    return serverGetAIResponse(actionPrompt, { model: "gpt-4o" })
      .then((finalText: string) => {
        const blocks = JSON.parse(finalText) as BlockType[];
        // Make sure all blocks have actual CUIDs, since we can't rely on the AI to provide them
        blocks.forEach((b) => {
          // eslint-disable-next-line no-param-reassign
          b.id = createId();
          b.f.forEach((f: FieldType) => {
            // eslint-disable-next-line no-param-reassign
            f.id = createId();
          });
        });

        // TODO: Double check block integrity, and block types, to make sure we're not getting
        // any weird made-up content.
        return new Promise((res, rej) => {
          const ops = blocks.map((b, i) => ({
            p: ["b", String(currentDoc.data.b.length + i)],
            li: b,
          }));
          // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
          currentDoc.submitOp(ops, {}, (err) => (err ? rej(err) : res(true)));
        });
      })
      .then(() => {
        loadingMessage.isLoading = false;
        loadingMessage.message = "Changes made";
        setMessages((msgs) => [...msgs]);
      })
      .catch(() => {
        const errText = t("error.toasts.get_ai_response_failure");
        loadingMessage.isLoading = false;
        loadingMessage.message = errText;
        setMessages((msgs) => [...msgs]);
      });
  };

  const onToggleStudentResponses = () => {
    dispatch(localStateActions.setStudentResponsesForAIEnabled(!isStudentResponsesForAIEnabled));
  };

  const { footerMargin } = useFooterSpacingIfMobileBanner();

  return (
    <>
      <div className="content-sidebar-header mb-2 flex items-center justify-between">
        <h3 className="mb-0">{t("glossary.ai_assistant")}</h3>
        {/* TODO: this model selection is for internal experimentation only and should be limited to users at MP.  */}
        <div className="flex justify-end gap-2">
          {isMPAdmin && <AISettings setSettings={setSettings} settings={settings} canUseContext />}
          <Button
            kind="secondary"
            size="s"
            onClick={() => {
              setMessages([defaultMessage]);
              trackEvent("AI Assistant - Clear Chat", {
                eventCategory: "Button press",
              });
            }}
          >
            {t("ai_chat_panel.clear_chat")}
          </Button>
        </div>
      </div>

      <div className={`ai-chat-panel content-sidebar-main ${footerMargin}`}>
        <Card className="body-s flex h-full w-full flex-col">
          <div className="relative mt-2 w-full flex-auto overflow-hidden">
            <div
              className="chat-messages flex h-full flex-col overflow-y-auto whitespace-pre-line"
              ref={messageBoxRef}
            >
              {messages.map((message) => (
                <Message key={message.id} message={message} />
              ))}

              {showConfirmationUI && (
                <div className="flex w-full items-center justify-end gap-2">
                  <Button kind="secondary" size="s" onClick={() => onActionResponse(false)}>
                    {t("common.no")}
                  </Button>
                  <Button kind="primary" size="s" onClick={() => onActionResponse(true)}>
                    {t("common.yes")}
                  </Button>
                </div>
              )}
            </div>
          </div>

          <div className="chat-query inline flex">
            <div className="relative h-full w-full">
              <TextArea
                className="chat-query-input h-auto py-1 pl-2"
                defaultValue={input}
                maxRows={5}
                placeholder={t("ai_chat_panel.chat_placeholder")}
                ref={textAreaRef}
                wrap="soft"
                onChange={(event) => {
                  setInput(event.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleAIQuery();
                  }
                }}
              />
              <Button
                className="chat-query-button"
                disabled={disabled}
                kind="tertiary"
                size="xs"
                iconOnly
                onClick={() => handleAIQuery()}
              >
                <CircleArrowRightIcon />
              </Button>
            </div>
          </div>
        </Card>

        <div className="chat-student-data-switch mt-1 flex items-start">
          <ToggleSwitch
            kind="tertiary"
            selected={isStudentResponsesForAIEnabled}
            onClick={onToggleStudentResponses}
          >
            {t("ai_chat_panel.allow_responses")}
          </ToggleSwitch>
        </div>
      </div>
    </>
  );
};
