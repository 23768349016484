import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const ChevronRightIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="fill"
      d="M5.75631 2C5.58429 2 5.41076 2.05925 5.27015 2.18075C4.95676 2.44999 4.92011 2.92323 5.18937 3.23747L9.25896 7.99988L5.18862 12.7623C4.91936 13.0765 4.95601 13.549 5.2694 13.819C5.58279 14.089 6.05549 14.053 6.32401 13.7373L10.8117 8.48737C11.0518 8.20687 11.0518 7.79213 10.8117 7.51164L6.32401 2.26174C6.17666 2.0885 5.96649 2 5.75631 2Z"
    />
  </svg>
);
