import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { isNil } from "lodash";

import { rollbarAndLogWarning } from "../../utils/logger";

import {
  ASSESSMENT_VIEW_QUERY_PARAM,
  STUDENT_VIEW_QUERY_PARAM,
  TIMELINE_VIEW_QUERY_PARAM,
} from "components/constants";
import { PageTabVariant } from "components/materials/page/helpers";
import { createReplaceAllReducer } from "store/utils";

// TODO: Move all query param handling into the store
const initialQueryParams = new URLSearchParams(window.location.search);

// This is just for logging purposes, not to be used for a page refresh. This is used
// to track erroneous data that may be loaded into the UX when it shouldn't have been.
// It's only used by lowerCourseVersionTo at the moment, which is only called in
// pubsub-slice on API response data--if the course version returned differs by more
// than 50, there is a good chance that the API call was not filtered by the course.
const MAX_COURSE_VERSION_DIFF_FOR_LOGGING = 50;

// Keep this in sync with COURSE_MESSAGE_QUEUE_MAX_LENGTH from material/tasks--this
// is the most messages we'll store in our redis queue for missed pubsub messages.
// const MAX_REDIS_COURSE_VERSION_DIFF = 500;

const boolFromParam = (paramName: string) => {
  const value = initialQueryParams.get(paramName);
  return value === "1";
};

const replaceBoolQueryParam = (paramName: string, value: boolean) => {
  // This function specifically does not trigger a reload or history change
  const newParams = new URLSearchParams(window.location.search);
  if (value) {
    newParams.set(paramName, "1");
  } else {
    newParams.delete(paramName);
  }
  window.history.replaceState({}, "", `${window.location.pathname}?${newParams.toString()}`);
};

type ViewState = {
  isCourseVersionOutOfSync: boolean;
  courseVersion: number;
  isStudentPreview: boolean;
  isTimeline: boolean;
  isAssessing: boolean;
  showingEditAssessmentBanner: boolean;
  shouldShowModalOnExitAssessmentMode: boolean;
  isOutcomesSidebarOpen: boolean;
  selectedPageTabVariant: PageTabVariant | null;
  assetModalIsOpen: boolean;
  currentCourseId: string | null;

  // Websocket/Save status indicators
  lastOpAt: number | null;
  lastOpSavedAt: number | null;
  opError: string | null;
};

const isNextSequential = (prev: number, next: number) => {
  return prev + 1 === next;
};

const initialState = () =>
  ({
    courseVersion: 0,
    currentCourseId: null,
    isCourseVersionOutOfSync: false,
    isStudentPreview: boolFromParam(STUDENT_VIEW_QUERY_PARAM),
    isTimeline: boolFromParam(TIMELINE_VIEW_QUERY_PARAM),
    isOutcomesSidebarOpen: false,
    selectedPageTabVariant: null,
    assetModalIsOpen: false,
    isAssessing: boolFromParam(ASSESSMENT_VIEW_QUERY_PARAM),
    showingEditAssessmentBanner: false,
    shouldShowModalOnExitAssessmentMode: false,
    lastOpAt: null,
    lastOpSavedAt: null,
    opError: null,
  }) as ViewState;

export const ViewStore = createSlice({
  name: "view",
  initialState,
  reducers: {
    /**
     * If we are updating the course version number from the API (pubsub-slice), we want the
     * course version to be set to the lowest course version received. This is because we don't
     * know what messages we may miss in between loading the data on the page and pubsub messages
     * in between, so we pull the few messages we may miss in this transition period. Nothing is
     * set if the new course version is undefined. */
    lowerCourseVersionTo: (
      state,
      action: PayloadAction<{
        results: { course_version?: number; course_id?: string }[] | undefined;
        modelName: string;
        params?: Record<string, string>;
      }>,
    ) => {
      const { results, modelName, params } = action.payload;
      const courseId = state.currentCourseId;
      const result = results.find((res) => res.course_id === courseId);
      if (!result) {
        if (courseId) {
          rollbarAndLogWarning("Why are we returning data for a different course?", {
            result,
            currentCourseId: courseId,
            modelName,
            params,
          });
        }
        return;
      }
      const newCourseVersion = result?.course_version;
      const oldCourseVersion = state.courseVersion;
      if (!isNil(newCourseVersion) && newCourseVersion < oldCourseVersion) {
        const versionDiff = Math.abs(newCourseVersion - oldCourseVersion);
        if (versionDiff >= MAX_COURSE_VERSION_DIFF_FOR_LOGGING) {
          rollbarAndLogWarning(`Lowering course version, version diff: ${versionDiff}`, {
            modelName,
            currentCourseId: courseId,
            newCourseVersion,
            oldCourseVersion,
          });
        }
        state.courseVersion = newCourseVersion;
      }
    },
    /**
     * If we are updating the course version number from the websocket/collaboration, we
     * want the course version to be set in sequential order (receiving messages in order). */
    incrementCourseVersionTo: (
      state,
      action: PayloadAction<{ newCourseVersion: number | undefined; msgCourseId: string }>,
    ) => {
      const oldCourseVersion = state.courseVersion;
      const currentCourseId = state.currentCourseId;
      const { newCourseVersion, msgCourseId } = action.payload;

      if (
        currentCourseId !== msgCourseId ||
        isNil(newCourseVersion) ||
        newCourseVersion === oldCourseVersion
      )
        return;

      if (isNextSequential(oldCourseVersion, newCourseVersion)) {
        state.courseVersion = newCourseVersion;
      } else {
        state.isCourseVersionOutOfSync = true;
      }
    },
    /** Whenever we switch courses, we set the course version to the course's course_version.
     * This may do nothing because we already load the course version when we load data from
     * the API. Nothing is set if the new course version is undefined. */
    setCourseVersionTo: (state, action: PayloadAction<number | undefined>) => {
      const newCourseVersion = action.payload;
      if (!isNil(newCourseVersion)) {
        state.courseVersion = newCourseVersion;
      }
    },
    setIsCourseVersionOutOfSync: (state, action: PayloadAction<boolean>) => {
      state.isCourseVersionOutOfSync = action.payload;
    },
    startAssessing: (state) => {
      state.isAssessing = true;
      replaceBoolQueryParam(ASSESSMENT_VIEW_QUERY_PARAM, true);
    },
    stopAssessing: (state) => {
      state.isAssessing = false;
      replaceBoolQueryParam(ASSESSMENT_VIEW_QUERY_PARAM, false);
    },
    showEditAssessmentBanner: (state) => {
      state.showingEditAssessmentBanner = true;
    },
    hideEditAssessmentBanner: (state) => {
      state.showingEditAssessmentBanner = false;
    },
    setShouldShowModalOnExitAssessmentMode: (state, action: PayloadAction<boolean>) => {
      state.shouldShowModalOnExitAssessmentMode = action.payload;
    },
    openAssetModal: (state) => {
      state.assetModalIsOpen = true;
    },
    closeAssetModal: (state) => {
      state.assetModalIsOpen = false;
    },
    setSelectedPageTabVariant: (state, action: PayloadAction<PageTabVariant | null>) => {
      state.selectedPageTabVariant = action.payload;
    },
    startStudentPreview: (state) => {
      state.isStudentPreview = true;
      replaceBoolQueryParam(STUDENT_VIEW_QUERY_PARAM, true);
    },
    stopStudentPreview: (state) => {
      state.isStudentPreview = false;
      replaceBoolQueryParam(STUDENT_VIEW_QUERY_PARAM, false);
    },
    startTimelineView: (state) => {
      state.isTimeline = true;
      replaceBoolQueryParam(TIMELINE_VIEW_QUERY_PARAM, true);
    },
    stopTimelineView: (state) => {
      state.isTimeline = false;
      replaceBoolQueryParam(TIMELINE_VIEW_QUERY_PARAM, false);
    },
    onSendSharedbOp: (state) => {
      state.lastOpAt = new Date().getTime();
    },
    onReceiveSharedbOp: (state) => {
      state.lastOpSavedAt = new Date().getTime();
      state.opError = null;
    },
    onSharedbError: (state, action: PayloadAction<string>) => {
      state.opError = action.payload;
    },
    openOutcomesSidebar: (state) => {
      state.isOutcomesSidebarOpen = true;
    },
    closeOutcomesSidebar: (state) => {
      state.isOutcomesSidebarOpen = false;
    },
    toggleOutcomesSidebar: (state) => {
      state.isOutcomesSidebarOpen = !state.isOutcomesSidebarOpen;
    },
    setCurrentCourseId: (state, action: PayloadAction<string>) => {
      state.currentCourseId = action.payload;
    },
  },
  extraReducers: createReplaceAllReducer<ViewState>("view", initialState),
});

export const viewStateActions = ViewStore.actions;
