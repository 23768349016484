import clsx from "clsx";
import { FC, useState } from "react";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { IconText } from "mds/components/IconText";
import { ClickEvent, Menu, MenuItem } from "mds/components/Menu";
import { ChevronDownIcon, ClockIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import { selectIsEditModeEnabled } from "store/selectors";

const TIMES = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 70, 80, 90] as const;

export type TimesType = (typeof TIMES)[number];

export type EstimatedTimeMenuProps = {
  selectedTime?: TimesType;
  onClick: (value: TimesType) => void;
};

export const EstimatedTimeMenu: FC<EstimatedTimeMenuProps> = ({ selectedTime, onClick }) => {
  const [time, setTime] = useState<TimesType>(selectedTime);
  const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);

  const timeText = (duration: TimesType): string =>
    duration === 0
      ? t("estimated_time_menu.default_estimate")
      : t("common.in_minutes", { minutes: duration });

  const iconTimeText = (
    <IconText
      iconStart={<ClockIcon aria-label={t("estimated_time_menu.estimated_time")} />}
      text={timeText(time)}
    />
  );

  if (!isEditModeEnabled) {
    return (
      <div className="h6 my-1 flex cursor-default items-center justify-center gap-1 whitespace-nowrap rounded-lg border border-black-tint-90 px-2 py-1 text-black-tint-20">
        {iconTimeText}
      </div>
    );
  }

  const MenuButton = (
    <Button
      className="gap-1 !font-medium !text-black-tint-20"
      kind="secondary"
      size="xs"
      title={t("tooltip.estimated_completion_time")}
    >
      {iconTimeText}
      <ChevronDownIcon />
    </Button>
  );

  const onItemClick = (e: ClickEvent) => {
    const newTime = e.value as TimesType;
    setTime(newTime);
    onClick(newTime);
  };

  return (
    <div className="my-1" onClick={(e) => e.stopPropagation()}>
      <Menu
        direction="bottom"
        menuButton={MenuButton}
        menuClassName="max-h-200"
        position="initial"
        onItemClick={onItemClick}
      >
        {TIMES.map((timeOption) => {
          const selected = time === timeOption;
          return (
            <MenuItem
              checked={selected}
              className={clsx({ selected, "icon-white": selected })}
              key={timeOption}
              value={timeOption}
            >
              <IconText iconStart={<ClockIcon />} text={timeText(timeOption)} />
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};
