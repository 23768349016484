import { MouseEvent } from "react";
import type { FC } from "react";

import { t } from "i18n/i18n";
import { EditReferenceKeys } from "i18n/i18next";
import { Button } from "mds/components/Button";
import { PencilEditIcon } from "mds/icons";

export interface EditIconButtonProps {
  onClick: () => void;
  reference: EditReferenceKeys;
}

export const EditIconButton: FC<EditIconButtonProps> = ({ onClick, reference }) => {
  return (
    <Button
      className="p-2"
      kind="tertiary"
      size="xs"
      title={t("edit_delete_widget.edit", { context: reference })}
      iconOnly
      onClick={(e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
    >
      <PencilEditIcon />
    </Button>
  );
};
