import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { usePageActions } from "./page";
import { courseContentUrl, coursePageUrl } from "./urls";

import { serverReleaseAssessments } from "api/api-server";
import {
  ACCESS_ID_QUERY_PARAM,
  ASSESSMENT_VIEW_QUERY_PARAM,
  PAGE_ID_QUERY_PARAM,
  SUBMISSION_ID_QUERY_PARAM,
} from "components/constants";
import { getTotalSubmissions } from "components/materials/page/assessment/helpers";
import { AssessmentStatus, PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { storeApi, useAppDispatch, useAppSelector } from "store/index";
import {
  selectActivityById,
  selectCurrentCourseId,
  selectOfficialFullSubmissionsByPageId,
  selectSubmissionsForPage,
} from "store/selectors";
import { computedStateActions } from "store/slices/computed";
import { localStateActions } from "store/slices/local";
import { viewStateActions } from "store/slices/view";
import { useListSelector } from "store/store-hooks";
import { toastSuccessMessage } from "utils/alerts";

export const useAssessmentActions = () => {
  type AssessmentCreateOrUpdateType = {
    points: number;
    comment: string;
    page_outcomes: Record<string, number>;
    is_official: boolean;
  };
  const createAssessment = (
    params: AssessmentCreateOrUpdateType,
    assessedById: string,
    submissionId: string,
  ) => {
    return storeApi.assessments
      .create({
        points: params.points,
        comment: params.comment,
        page_outcomes: params.page_outcomes,
        assessed_by_id: assessedById,
        submission_id: submissionId,
        is_official: params.is_official,
      })
      .then(() => {
        toastSuccessMessage(t("success.toasts.create_success", { item: t(`glossary.assessment`) }));
      });
  };
  const updateAssessment = (
    assessmentId: string,
    params: AssessmentCreateOrUpdateType,
    assessedById: string,
    submissionId: string,
  ) => {
    return storeApi.assessments
      .partial_update({
        id: assessmentId,
        points: params.points,
        comment: params.comment,
        page_outcomes: params.page_outcomes,
        assessed_by_id: assessedById,
        submission_id: submissionId,
        is_official: params.is_official,
      })
      .then(() => {
        toastSuccessMessage(t("success.toasts.update_success", { item: t(`glossary.assessment`) }));
      });
  };

  const deleteAssessment = (assessmentId: string) => {
    return storeApi.assessments.destroy(assessmentId).then(() => {
      toastSuccessMessage(t("success.toasts.delete_success", { item: t(`glossary.assessment`) }));
    });
  };

  return { createAssessment, updateAssessment, deleteAssessment };
};

export const useAssessmentMode = (page: PageType) => {
  const dispatch = useAppDispatch();
  const { navigateToPage } = usePageActions();
  const [searchParams, setSearchParams] = useSearchParams();
  const courseId = useAppSelector(selectCurrentCourseId);
  const activity = useAppSelector((s) => selectActivityById(s, page.activity_id));

  const startAssessing = useCallback(() => {
    dispatch(localStateActions.disableEditMode());
    if (searchParams.get(PAGE_ID_QUERY_PARAM) !== page.id) {
      const pageUrl =
        page.category === "activity_page"
          ? courseContentUrl(courseId, activity.topic_id, page.id)
          : coursePageUrl(courseId, page.category, page.id);
      navigateToPage(page, pageUrl);
    }

    // Need to give the browser a chance to update the URL before dispatching the action
    setTimeout(() => dispatch(viewStateActions.startAssessing()), 100);
  }, [dispatch, page, searchParams, courseId, activity, navigateToPage]);

  const stopAssessing = useCallback(() => {
    dispatch(viewStateActions.stopAssessing());
    searchParams.delete(ACCESS_ID_QUERY_PARAM);
    searchParams.delete(ASSESSMENT_VIEW_QUERY_PARAM);
    searchParams.delete(SUBMISSION_ID_QUERY_PARAM);
    setSearchParams(searchParams);
  }, [dispatch, searchParams, setSearchParams]);

  return { startAssessing, stopAssessing };
};

export const useAssessmentPublishActions = (page: PageType) => {
  const dispatch = useAppDispatch();
  const publishGrades = () => {
    serverReleaseAssessments(page.id);
    toastSuccessMessage(t("success.toasts.publish_assessments_success"));
    dispatch(viewStateActions.showEditAssessmentBanner());
  };

  const unpublishGrades = () => {
    storeApi.pages.partial_update({ id: page.id, assessments_published_at: null });
    dispatch(
      computedStateActions.savePageAssessmentStatuses({ [page.id]: AssessmentStatus.ASSESSABLE }),
    );
    dispatch(viewStateActions.hideEditAssessmentBanner());
  };

  return { publishGrades, unpublishGrades };
};

export const useAssessedCountData = (page: PageType) => {
  const submissions = useAppSelector((s) => selectSubmissionsForPage(s, page.id));
  const officialSubmissions = useListSelector((s) =>
    selectOfficialFullSubmissionsByPageId(s, page.id),
  );

  useEffect(() => {
    storeApi.submissions.list({ page_id: page?.id });
  }, [page?.id]);

  const count = officialSubmissions.length;
  const total = getTotalSubmissions(page, submissions);
  return { count, total };
};
