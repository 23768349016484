import clsx from "clsx";
import { CSSProperties, FC, RefObject, useContext } from "react";

import { HoverableToolbarContext } from "components/hover-widgets/HoverableToolbarProvider";
import { t } from "i18n/i18n";
import { Button, ButtonProps } from "mds/components/Button";
import { IconText } from "mds/components/IconText";
import { Menu, MenuChangeEvent, MenuDirection, MenuInstance, MenuItem } from "mds/components/Menu";
import { AddPlusIcon, OutcomeHashtagIcon } from "mds/icons";

export type OutcomeGroupMenu = {
  styles?: CSSProperties;
  size?: ButtonProps["size"];
  className?: string;
  buttonKind?: ButtonProps["kind"];
  direction?: MenuDirection;
  instanceRef?: RefObject<MenuInstance>;
  onAddOutcome: () => void;
  onAddOutcomeSubgroup: () => void;
};

export const OutcomeGroupMenu: FC<OutcomeGroupMenu> = ({
  styles,
  size = "s",
  className,
  buttonKind = "secondary",
  direction = "right",
  instanceRef = null,
  onAddOutcome,
  onAddOutcomeSubgroup,
}) => {
  const MenuButton = (
    <Button
      className={clsx("p-2", className)}
      kind={buttonKind}
      size={size}
      title={t("common.add.outcome_or_outcome_subgroup")}
      iconOnly
    >
      <AddPlusIcon />
    </Button>
  );

  const hoverableContext = useContext(HoverableToolbarContext);

  const onMenuChange = (event: MenuChangeEvent) => {
    if (hoverableContext) {
      hoverableContext.setShouldStayOpen(event.open);
    }
  };

  return (
    <Menu
      direction={direction}
      instanceRef={instanceRef}
      menuButton={MenuButton}
      menuClassName="min-w-[180px]"
      menuStyle={styles}
      onClick={(e) => e.stopPropagation()}
      onMenuChange={onMenuChange}
    >
      <MenuItem onClick={onAddOutcomeSubgroup}>
        <IconText iconStart={<AddPlusIcon />} text={t("common.add.outcome_subgroup")} />
      </MenuItem>
      <MenuItem onClick={onAddOutcome}>
        <IconText iconStart={<OutcomeHashtagIcon />} text={t("common.add.outcome")} />
      </MenuItem>
    </Menu>
  );
};
