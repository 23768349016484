import { get, isNil } from "lodash";
import { type FC, useContext, useEffect, useMemo, useState } from "react";
import { Doc } from "sharedb/lib/client";

import { ACCESS_ID_WRAPPER, DATA_PATH } from "../shared/constants";
import { hasEditableBlocks } from "../shared/doc-helpers";
import { AUTH_ERRORS } from "../shared/types/websocket-auth";
import { AccessIdDataType, WorksheetType } from "../shared/types/worksheet";

import { Blocks } from "./Blocks";
import { SubmissionWorksheet } from "./SubmissionWorksheet";
import { WorksheetResponses } from "./WorksheetResponses";

import { ErrorScreen, LoadingTextIndicator } from "components/LoadingScreen";
import { WAITED_TOO_LONG_TIMEOUT_MS } from "components/constants";
import { PageTabVariant } from "components/materials/page/helpers";
import { t } from "i18n/i18n";
import { Banner } from "mds/components/Banner";
import { DocViewContext } from "providers/DocViewProvider";
import { useAppSelector } from "store/index";
import { selectIsTimeline } from "store/selectors";
import { rollbarAndLogError } from "utils/logger";

type WorksheetProps = {
  doc: Doc<WorksheetType>;
};

export const Worksheet: FC<WorksheetProps> = ({ doc }) => {
  const { accessId, readOnly } = useContext(DocViewContext);
  const isTimeline = useAppSelector(selectIsTimeline);

  // accessIdData may be undefined if either:
  // - The user/group has never been on the page and is waiting for initialization
  // - The page is readonly, and we haven't initialized the data on a previous visit.
  const accessIdPath = useMemo(() => (accessId ? [DATA_PATH, accessId] : [DATA_PATH]), [accessId]);
  const accessIdData = get(doc.data, accessIdPath) as AccessIdDataType;

  // This determines whether we initialize the access ID wrapper, which is a per-user/group
  // subdirectory in which field data will be saved later on. At creation time of a worksheet,
  // we don't know which users will be on it (and need wrapper objects), so we create these
  // dynamically. In read-only cases, we don't need to do this, since fields won't need to save data.
  const [initError, setInitError] = useState<string>(null);
  const needsAccessIdWrapperInit =
    !initError && !readOnly && !accessIdData && hasEditableBlocks(doc);

  useEffect(() => {
    if (needsAccessIdWrapperInit) {
      // If we somehow take longer than three seconds to do this, assume it's a backend issue,
      // and give the user a chance to retry.
      const timeout = setTimeout(() => {
        setInitError("Timeout");
        rollbarAndLogError("Access ID Wrapper init error", {
          err: "useEffect timeout",
          path: accessIdPath.join("."),
          docId: doc.id,
        });
      }, WAITED_TOO_LONG_TIMEOUT_MS);

      // There was a race condition where this useEffect triggered before the doc side-effects
      // that are store-based -- specifically the one responsible for refreshing the doc when
      // permissions change (see forceRefreshDoc called in useWorksheetPermissionRefresh).
      // The OP arriving during the doc refresh process puts the sharedb doc into a bad state
      // (that we can't quite describe or fix - internal library issues).
      // This timeout ensures the code runs in the next render cycle,
      // effectively waiting for the permission/doc refresh
      const delayedOpTimeout = setTimeout(() => {
        doc.submitOp({ p: accessIdPath, oi: ACCESS_ID_WRAPPER }, {}, (err) => {
          clearTimeout(timeout);
          if (err && err.message !== AUTH_ERRORS.OVERWRITE_DISALLOWED) {
            setInitError(err.message);
            rollbarAndLogError("Access ID Wrapper init error", err);
          }
        });
      }, 0);

      return () => {
        clearTimeout(delayedOpTimeout);
        clearTimeout(timeout);
      };
    }
  }, [accessIdPath, doc, needsAccessIdWrapperInit]);

  // If an error happens during initialization, instead of re-trying automatically and possibly
  // getting stuck in a loop, allow for manual retries, and show a graceful message.
  if (initError) {
    return (
      <ErrorScreen
        retryText={t("worksheet.error.try_again")}
        text={<div className="body-m">{t("worksheet.error.access_id_init")}</div>}
        onRetry={() => setInitError(null)}
      />
    );
  }

  // Waiting to initialize student data path. At creation time of a worksheet, we don't
  // know which users will be filling it out, so the first time a new user wants to fill
  // in data, indicated by needsPathInitialization, we have the user submit an op that
  // initializes their individual user sub-directory in doc.data.d.
  // When viewing the worksheet as anyone else but an editing user, we don't need to
  // do this, since if the doc doesn't have the accessId initialized, that's fine,
  // we just show an empty worksheet.
  if (needsAccessIdWrapperInit) {
    return (
      <LoadingTextIndicator
        className="worksheet-column-wrapper"
        text={t("worksheet.loading.access_id_init")}
      />
    );
  }

  if (!accessIdData && isTimeline) {
    return <Banner kind="info">{t("worksheet.timeline_empty")}</Banner>;
  }

  return (
    <div className="worksheet-column-wrapper">
      <Blocks accessIdPath={accessIdPath} doc={doc} />
    </div>
  );
};

type WorksheetFromSharedbDocProps = {
  doc: Doc<WorksheetType>;
  selectedTabVariant?: PageTabVariant;
  worksheetVersionNumber?: number;
};

export const WorksheetFromSharedbDoc: FC<WorksheetFromSharedbDocProps> = ({
  doc,
  selectedTabVariant,
  worksheetVersionNumber,
}) => {
  if (selectedTabVariant === "responses" && isNil(worksheetVersionNumber)) {
    return <WorksheetResponses doc={doc} />;
  }

  // TODO: SubmissionWorksheets should never be in rollup mode: we never show more than one
  //  submission at a time.
  // TODO: Submissions on rollup view look like a rollup now--we should make them look like
  //  submission worksheets again.
  if (worksheetVersionNumber) {
    return <SubmissionWorksheet doc={doc} worksheetVersionNumber={worksheetVersionNumber} />;
  }

  return <Worksheet doc={doc} />;
};
