import { useIsLgOrLarger } from "mds/hooks/use-responsive";
import { usePresentation } from "utils/presentation";

export const useFooterSpacingIfMobileBanner = () => {
  const { isFollowing, isPresentingUser, inPresentationMode } = usePresentation();
  const isLgOrLarger = useIsLgOrLarger();

  return !isLgOrLarger && inPresentationMode && !isFollowing && !isPresentingUser
    ? { footerMargin: "mb-16", footerPadding: "pb-16" }
    : { footerMargin: "", footerPadding: "" };
};
