import clsx from "clsx";
import { useEffect } from "react";

import { OrgUserOptionsMenu } from "./OrgUserOptionsMenu";
import { OrgRoleIconWithTooltip } from "./UserRoleIcons";

import { CourseUserType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { Tooltip } from "mds/components/Tooltip";
import { storeApi, useAppSelector } from "store/index";
import {
  selectCanAuthorOrg,
  selectCourseUsersByRole,
  selectCourses,
  selectCurrentUserId,
  selectCurrentUserIsMPAdmin,
  selectOrgUsers,
  selectUsers,
} from "store/selectors";
import { useListSelector } from "store/store-hooks";
import { toastLocalizedOperationCatcher } from "utils/alerts";
import { getUserFullName } from "utils/user-utils";

export const OrgUsersSidebarPanel = () => {
  const allUsers = useListSelector(selectUsers);
  const orgUsers = useAppSelector(selectOrgUsers);
  const courseUsers = useListSelector(selectCourseUsersByRole);
  const courses = useListSelector(selectCourses);
  const canAuthorOrg = useAppSelector(selectCanAuthorOrg);

  const user_id = useAppSelector(selectCurrentUserId);
  const isCurrentUserInOrg = orgUsers.some((ou) => ou.user_id === user_id);
  const isMpAdmin = useAppSelector(selectCurrentUserIsMPAdmin);
  const canAddThemselvesToOrg = isMpAdmin && !isCurrentUserInOrg;

  useEffect(() => {
    storeApi.course_users.list({});
    storeApi.org_users.list();
  }, []);

  // OrgUsers and CourseUsers are both links to the User model, and to Org and Course separately,
  // and both have a "role" string attached.
  // In this view we'd like to show a list of all users that have an OrgUser, their role, and the
  // amount of CourseUsers that they have.

  const userList = orgUsers.map((orgUser) => {
    const user = allUsers.find((u) => u.id === orgUser.user_id);
    const courseUserList = courseUsers.filter((cu) => cu.user_id === orgUser.user_id);

    const tooltipCourseRoleTable = courseUserList.map((cu) => {
      const course = courses.find((c) => c.id === cu.course_id);
      return (
        <div key={cu.id}>
          <strong>{course?.title}</strong>: {t(`roles.course_${cu.role}`)}
        </div>
      );
    });

    return {
      ...orgUser,
      ...user,
      org_user_id: orgUser.id,
      courseUserList,
      tooltipCourseRoleTable,
    };
  });

  const onDelete = (user: { org_user_id: string; courseUserList: CourseUserType[] }) => {
    if (user.courseUserList.length > 0) {
      return alert(t("error.user_still_enrolled"));
    }
    storeApi.org_users.destroy(user.org_user_id);
  };

  const onJoinAsAdmin = () => {
    storeApi.org_users
      .create({
        user_id,
        role: "admin",
      })
      .catch(toastLocalizedOperationCatcher("join_as_admin"));
  };

  // TODO: Localize the contents in the button
  return (
    <>
      <div className="content-sidebar-header mb-2">
        <h3 className="mb-0">{t("user_options_menu.org_sidebar_title")}</h3>
      </div>
      <div className="content-sidebar-main">
        {canAddThemselvesToOrg && (
          <Button className="mb-3 flex items-center gap-2" size="xs" onClick={onJoinAsAdmin}>
            <span>Join this org as an admin</span>
            <Tooltip>After joining, you can choose your role in the list below.</Tooltip>
          </Button>
        )}
        <table className="mds-table w-full">
          <thead>
            <tr>
              <th>{t("user_options_menu.table_header_name")}</th>
              <th>{t("user_options_menu.table_header_email")}</th>
            </tr>
          </thead>
          <tbody>
            {userList.map((user, index) => (
              <tr
                className={clsx(
                  "items-center justify-between p-1",
                  index % 2 === 0 && "bg-black-tint-90",
                )}
                key={user.id}
              >
                <td className="flex items-center gap-1 p-1">
                  <OrgRoleIconWithTooltip
                    extraContent={user.tooltipCourseRoleTable}
                    role={user.is_superuser ? "superuser" : user.role}
                  />
                  <span className="max-w-m mb-1 truncate" title={getUserFullName(user)}>
                    {getUserFullName(user)}
                  </span>
                </td>
                <td className="body-xs relative p-1">
                  {user.email}
                  {canAuthorOrg && (
                    <div className="absolute right-0 top-0 bg-white">
                      <OrgUserOptionsMenu
                        currentRole={user.is_superuser ? "superuser" : user.role}
                        onChangeRole={(newRole) => {
                          storeApi.org_users
                            .partial_update({
                              id: user.org_user_id,
                              role: newRole,
                            })
                            .catch(toastLocalizedOperationCatcher("update_user_role"));
                        }}
                        onClickDelete={() => onDelete(user)}
                      />
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
