import clsx from "clsx";
import { FC } from "react";

import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";

interface AssessmentGradingNoSubmissionProps {
  setShowLastPanel: (show: boolean) => void;
  page: PageType;
  nextURL?: URL;
}

export const AssessmentGradingNoSubmission: FC<AssessmentGradingNoSubmissionProps> = ({
  setShowLastPanel,
  page,
  nextURL,
}) => {
  const onClick =
    !page.assessments_published_at && !nextURL ? () => setShowLastPanel(true) : undefined;
  const to = nextURL ? nextURL.toString() : undefined;
  const isNextEnabled = (!page.assessments_published_at && !nextURL) || !!nextURL;

  return (
    <>
      <div className="text-black-tint-40">{t("submission_assessment.no_submission")}</div>
      <div className="mt-2 flex flex-row justify-end gap-1">
        <Button
          className={clsx(isNextEnabled && "!text-black-tint-20")}
          disabled={!isNextEnabled}
          kind="secondary"
          size="xs"
          to={to}
          onClick={onClick}
        >
          {t("common.next")}
        </Button>
      </div>
    </>
  );
};
