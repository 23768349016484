import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { Card } from "mds/components/Card";
import { HelpBugIcon } from "mds/icons";
import { trackEvent } from "utils/amplitude";
import { BUG_REPORT_ROUTE } from "utils/urls";

export const HelpCenterSidebarPanel = () => {
  return (
    <>
      <div className="content-sidebar-header">
        <h3 className="mb-0">{t("help_center.title")}</h3>
      </div>

      <div className="content-sidebar-main">
        <Card>
          <div className="body-s pb-4">{t("help_center.description")}</div>
          <Button
            className="gap-1"
            kind="secondary"
            target="_blank"
            to={BUG_REPORT_ROUTE}
            onClick={() => {
              trackEvent("Help Center - Report a problem", { eventCategory: "Button press" });
            }}
          >
            <HelpBugIcon />
            {t("help_center.report_problem")}
          </Button>
        </Card>
      </div>
    </>
  );
};
