import type Rollbar from "rollbar";

// In practice this function is intended to take strings or error objects (of various types). Once we start using Rollbar actively
// we should investigate both how the error is logged and if we can improve the typing.
export function rollbarAndLogError(...args: unknown[]) {
  console.error(...args);
  if (window.Rollbar) {
    window.Rollbar.error(...(args as Rollbar.LogArgument[]));
  }
}

export function rollbarAndLogWarning(...args: unknown[]) {
  console.warn(...args);
  if (window.Rollbar) {
    window.Rollbar.warn(...(args as Rollbar.LogArgument[]));
  }
}

export function rollbarAndLogInfo(...args: unknown[]) {
  if (window.Rollbar) {
    window.Rollbar.info(...(args as Rollbar.LogArgument[]));
  }
}
