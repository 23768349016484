import { ErrorBoundary, Provider } from "@rollbar/react";
import { ReactNode, useEffect } from "react";
import Rollbar, { Configuration } from "rollbar";

import { useAppSelector } from "store/index";
import { selectCurrentUserId, selectUserById } from "store/selectors";
import { getOrgIdFromCookie } from "utils/auth";

const config: Configuration = {
  accessToken: window._env_.ROLLBAR_CLIENT_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: window._env_.ENV,
  // TODO actual commit?
  code_version: window._env_.BRANCH_NAME || "unknown",
};

// eslint-disable-next-line react-refresh/only-export-components
export const rollbar = new Rollbar(config);

window.Rollbar = rollbar;

export const RollbarProvider = ({ children }: { children: ReactNode }) => {
  const userId = useAppSelector(selectCurrentUserId);
  const user = useAppSelector((s) => selectUserById(s, userId));
  const email = user?.email;

  useEffect(() => {
    rollbar.configure({
      payload: {
        client: {
          javascript: {
            source_map_enabled: true,
          },
        },
        person: {
          id: userId,
          email,
        },
        org_id: getOrgIdFromCookie(),
      },
    });
  }, [userId, email]);

  return (
    <Provider instance={rollbar}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
  );
};
