import "rc-slider/assets/index.css";
import "./TimelineWorksheet.scss";

import cloneDeep from "lodash/cloneDeep";
import Slider from "rc-slider";
import { useEffect, useState } from "react";
import { type Doc } from "sharedb/lib/client";

import { jsonOTType } from "../shared/ot-types";
import { RevMetaType, RevType } from "../shared/types/sharedb";
import { WorksheetType } from "../shared/types/worksheet";

import { getRevs } from "api/api-worksheets";
import { t } from "i18n/i18n";
import { useAppSelector } from "store/index";
import { selectCurrentCourse, selectUsersInCourse } from "store/selectors";
import { useListSelector } from "store/store-hooks";
import { toastLocalizedOperationError } from "utils/alerts";
import { Worksheet } from "worksheets/views/Worksheet";

type Revision = [WorksheetType, RevMetaType];

export const TimelineWorksheet = ({ docId }: { docId: string }) => {
  const [currentRev, setCurrentRev] = useState(0);
  const [revisions, setRevisions] = useState<Revision[]>([]);
  const [dayMarks, setDayMarks] = useState<Record<string, string>>({});
  const [revs, setRevs] = useState<RevType[]>(null);

  const course = useAppSelector(selectCurrentCourse);
  const users = useListSelector((s) => selectUsersInCourse(s, course?.id));

  useEffect(() => {
    const process = (res: RevType[]) => {
      setRevs(res);
      const beg = res.shift();
      if (!beg?.create?.data) {
        return;
      }
      const currentRevisions: Revision[] = res.reduce(
        (acc, x) => {
          const result = jsonOTType.type.apply(
            cloneDeep(acc[acc.length - 1][0]),
            x.op,
          ) as WorksheetType;
          return [...acc, [result, x.m]];
        },
        [[beg.create.data, beg.m]],
      );

      let previousDay = null;
      const currentDayMarks = currentRevisions.reduce((acc, [, meta]: Revision, i) => {
        const day = Math.floor(meta.ts / (1000 * 60 * 60 * 24));
        const dateName = new Date(meta.ts).toDateString().split(" ").slice(1, 3).join(" ");
        if (day !== previousDay) {
          previousDay = day;
          return { ...acc, [i]: dateName };
        }
        return acc;
      }, {});

      setRevisions(currentRevisions);
      setDayMarks(currentDayMarks);
    };

    getRevs(docId)
      .then((response) => process(response.data))
      .catch(() => toastLocalizedOperationError("failed_to_retrieve_revisions"));
  }, [docId]);

  if (!revs) {
    return (
      <div className="mt-4 flex items-center justify-center">{t("common.loading_ellipsis")}</div>
    );
  }

  if (revs.length === 0) {
    return <div className="mt-4 flex items-center justify-center">No revisions found</div>;
  }

  const [data, meta] = revisions[currentRev];
  const modifyDate = new Date(meta.ts);
  const modifyDateString = `${modifyDate
    .toISOString()
    .slice(0, 10)} - ${modifyDate.toLocaleTimeString()}`;
  const user = users.find((u) => u.id === meta.userId);
  const username = user?.email || <strong>Anonymous User (id={meta?.userId})</strong>;

  const skeletonDocWithHistoricData = {
    submitOp: (...args) => {
      // eslint-disable-next-line no-console, @typescript-eslint/no-unsafe-argument
      console.log("Skipping submitOp in timeline view:", ...args);
    },
    data,
    on: () => {},
    presence: { create: () => {}, on: () => {}, off: () => {} },
    removeListener: () => {},
  } as unknown as Doc<WorksheetType>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
  (window as any).revs = revs;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
  (window as any).currentRev = revs[currentRev + 1];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
  (window as any).currentRevData = [data, meta];

  return (
    <>
      {/* TODO: Preferably this slider would be pinned to the top of the page, and sliding
      it would scroll the page to the relevant change, which would require inspecting each
      op and jumping to the corresponding block component that renders it. */}
      <div className="body px-5 pt-5">
        <h2 className="h2">Version history timeline</h2>
        <div>
          Drag the timeline to see the edits in the worksheet as they were made by different users.
          The time shown is in your computer’s timezone.
        </div>
        <div className="body mb-2 mb-5">
          Current viewing edit{" "}
          <strong>
            {currentRev + 1}/{revisions.length}
          </strong>{" "}
          on <strong>{modifyDateString}</strong> by <strong>{username}</strong>
        </div>
        <div className="mx-4 pb-4">
          <Slider
            marks={dayMarks}
            max={revisions.length - 1}
            min={0}
            value={currentRev}
            onChange={(e) => setCurrentRev(e)}
          />
        </div>
        <div className="mb-2 mt-5 w-full border-b border-black-tint-70" />
      </div>
      <div>
        <Worksheet doc={skeletonDocWithHistoricData} />
      </div>
    </>
  );
};
