import { FC } from "react";
import { useSearchParams } from "react-router-dom";

import { Assessment } from "./assessment/Assessment";
import { PageOutcomeRubricSidebar } from "./outcomes/PageOutcomeRubricSidebar";

import { SUBMISSION_ID_QUERY_PARAM } from "components/constants.ts";
import { AssessmentSummary } from "components/materials/page/assessment/AssessmentSummary";
import { PageType } from "components/server-types";
import { useAppSelector } from "store/index";
import {
  selectAssessmentBySubmissionId,
  selectCanAuthorCourse,
  selectIsAssessing,
  selectIsOutcomesSidebarOpen,
  selectShowingEditAssessmentBanner,
} from "store/selectors";

type PageSecondarySidebarProps = {
  className?: string;
  page: PageType;
};

export const PageSecondarySidebar: FC<PageSecondarySidebarProps> = ({ className, page }) => {
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const isAssessing = useAppSelector(selectIsAssessing);
  const isOutcomesSidebarOpen = useAppSelector(selectIsOutcomesSidebarOpen);
  const showingEditAssessmentBanner = useAppSelector(selectShowingEditAssessmentBanner);

  const [searchParams] = useSearchParams();
  const submissionId = searchParams.get(SUBMISSION_ID_QUERY_PARAM);
  const assessment = useAppSelector((s) => selectAssessmentBySubmissionId(s, submissionId)) || null;

  if (!isAssessing && isOutcomesSidebarOpen) {
    return (
      <aside className={className}>
        <PageOutcomeRubricSidebar pageId={page.id} />
      </aside>
    );
  }

  if (!isAssessing && showingEditAssessmentBanner) {
    return (
      <aside className={className}>
        <AssessmentSummary page={page} />
      </aside>
    );
  }

  const isViewingAssessment = assessment && (canAuthorCourse || page.assessments_published_at);
  if (isAssessing || isViewingAssessment) {
    return (
      <aside className={className}>
        <Assessment page={page} />
      </aside>
    );
  }

  return null;
};
