import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const ArrowUpIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path className="stroke" d="M8 11.2667V1" strokeLinecap="round" strokeLinejoin="round" />
    <path
      className="stroke"
      d="M12.1998 5.2L7.9998 1L3.7998 5.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path className="stroke" d="M15 15H1" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
