import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const DoubleChevronLeftIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="fill"
      d="M12.3455 13.25C12.496 13.25 12.6478 13.1982 12.7709 13.0918C13.0451 12.8563 13.0772 12.4422 12.8416 12.1672L9.28067 8.00011L12.8422 3.83301C13.0778 3.55804 13.0458 3.14461 12.7715 2.90837C12.4973 2.67212 12.0837 2.70362 11.8488 2.9799L7.92202 7.57355C7.71194 7.81899 7.71194 8.18188 7.92202 8.42732L11.8488 13.021C11.9777 13.1726 12.1616 13.25 12.3455 13.25Z"
    />
    <path
      className="fill"
      d="M7.10281 13.25C7.25334 13.25 7.40517 13.1982 7.52821 13.0918C7.80242 12.8563 7.83449 12.4422 7.59889 12.1672L4.038 8.00011L7.59954 3.83301C7.83515 3.55804 7.80308 3.14461 7.52886 2.90837C7.25465 2.67212 6.84103 2.70362 6.60608 2.9799L2.67935 7.57355C2.46927 7.81899 2.46927 8.18188 2.67935 8.42732L6.60608 13.021C6.73501 13.1726 6.91891 13.25 7.10281 13.25Z"
    />
  </svg>
);
