import { useEffect } from "react";

import { FOLLOWING_QUERY_PARAM } from "components/constants";
import { storeApi, useAppSelector } from "store/index";
import { selectActivityById, selectPageById, selectTopicById } from "store/selectors";
import { isCoursePage } from "utils/page";
import { courseContentUrl, coursePageUrl } from "utils/urls";

export const usePageUrl = (pageId: string) => {
  const page = useAppSelector((s) => selectPageById(s, pageId));
  useEffect(() => {
    if (!page) {
      // This happens from a race condition where a new resource is created during presentation and we get the
      // presentation pubsub event before the page pubsub event or it can happen when the new presented page
      // is not part of this topic.  Retrieving the page should fix this.
      storeApi.pages.retrieve(pageId, { skipToast: true });
    }
  }, [page, pageId]);

  const activity = useAppSelector((s) => selectActivityById(s, page?.activity_id));
  useEffect(() => {
    if (page && !activity) {
      storeApi.activities.retrieve(page.activity_id, {
        skipToast: true,
      });
    }
  }, [page, activity]);

  const topic = useAppSelector((s) => selectTopicById(s, activity?.topic_id));
  useEffect(() => {
    if (activity && !topic) {
      storeApi.topics.retrieve(activity.topic_id, {
        skipToast: true,
      });
    }
  }, [activity, topic]);

  const followParam = `${FOLLOWING_QUERY_PARAM}=1`;
  if (!page) {
    return null;
  }
  if (isCoursePage(page)) {
    return `${coursePageUrl(page.course_id, page.category, pageId)}&${followParam}`;
  }
  if (topic) {
    return `${courseContentUrl(page.course_id, topic.id, pageId)}&${followParam}`;
  }
  return null;
};
