import { useNavigate, useSearchParams } from "react-router-dom";

import { CATEGORY_QUERY_PARAM } from "components/constants";
import { CourseResourceView } from "components/materials/page/course-page/CoursePageView";
import { useAppSelector } from "store/index";
import { selectCanAuthorCourse } from "store/selectors";

export default function Page() {
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  if (!canAuthorCourse && searchParams.get(CATEGORY_QUERY_PARAM) === "instructor_workspace") {
    navigate("/404");
  }

  return <CourseResourceView />;
}
