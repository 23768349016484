import { FC } from "react";

import { IconColorProps, initializeIconColorProps } from "mds/utils/images";

export const PageStatusIcon: FC<IconColorProps> = (props) => {
  const { fillClassName, ...svgProps } = initializeIconColorProps(props);
  return (
    <svg {...svgProps}>
      <path
        className="stroke"
        d="M14.0664 7V1H1.93311V15H7.49977"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        className="stroke"
        d="M4.73291 3.79999H11.2662"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        className="stroke"
        d="M4.73291 6.59998H10.7329"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        className="stroke"
        d="M4.73291 9.40002H7.13291"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        className="stroke"
        d="M4.73291 12.2H6.53291"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <rect className={fillClassName} height="6" rx="3" stroke="none" width="6" x="9" y="9" />
    </svg>
  );
};
