import "./OutcomeInfo.scss";

import { type FC, useState } from "react";

import { CourseOutcomeAverageScore } from "./CourseOutcomeAverageScore";
import { OutcomeStudentUseIndex } from "./OutcomeStudentUseIndex";
import { OutcomeUseIndex } from "./OutcomeUseIndex";
import { validateOutcomeName } from "./helpers";

import { HoverableEditField } from "components/hover-widgets/EditableField";
import { HoverableToolbar } from "components/hover-widgets/HoverableToolbar";
import { RubricList } from "components/outcomes/RubricList";
import { OutcomeType } from "components/server-types";
import { Trans, t } from "i18n/i18n";
import { AddButton } from "mds/components/AddButton";
import { TextAddButton } from "mds/components/TextAddButton";
import { storeApi, useAppSelector } from "store/index";
import {
  FullRubricType,
  NestedCourseOutcomeType,
  selectCanAuthorCourse,
  selectCurrentCourseRole,
  selectIsStudentPreview,
} from "store/selectors";
import { onTitleChanged } from "utils/store-utils";

type OutcomeInfoProps = {
  readOnly?: boolean;
  createNewOutcome?: () => void;
  rubric?: FullRubricType[];
} & (
  | {
      /**
       * This component is used both by the course-level and org-level outcomes pages,
       * and the logic is slightly different between the two.
       */
      isCourseLevel: true;
      outcomeItem: NestedCourseOutcomeType;
    }
  | {
      isCourseLevel: false;
      outcomeItem: OutcomeType;
    }
);

export const OutcomeInfo: FC<OutcomeInfoProps> = ({
  outcomeItem,
  readOnly,
  createNewOutcome,
  rubric,
  isCourseLevel,
}) => {
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const [isEditingEmptyDescription, setIsEditingEmptyDescription] = useState(false);
  const isStudentRole = useAppSelector(selectCurrentCourseRole) === "student";
  const isStudentPreview = useAppSelector(selectIsStudentPreview);

  // For now we only allow editing the description and title for outcomes at the org level page, even though
  // the instructor can edit the rubric at the course level.
  const isTitleAndDescriptionEditable = canAuthorCourse && !readOnly && !isCourseLevel;

  if (createNewOutcome) {
    return (
      <div className="flex h-full w-full justify-center text-black-tint-40">
        <div className="empty-topic">
          <div className="flex flex-col items-center gap-3">
            <div>{t("outcomes.no_outcomes")}</div>
            {canAuthorCourse && (
              <div className="flex items-center justify-center gap-2">
                <Trans i18nKey="outcomes.add_outcome_to_get_started" t={t}>
                  <AddButton onClick={() => createNewOutcome()}>Add Outcome</AddButton>
                  <span>to get started</span>
                </Trans>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (!outcomeItem) {
    return (
      <div className="flex h-full w-full justify-center text-black-tint-40">
        <div className="empty-topic">
          <div className="flex flex-col items-center gap-3">
            <div>{t("outcome_info.no_outcome_selected")}</div>
          </div>
        </div>
      </div>
    );
  }

  const onOutcomeDescriptionChange = (newDescription: string) => {
    if ("outcome_id" in outcomeItem) {
      storeApi.outcomes.partial_update({
        id: outcomeItem.outcome_id,
        description: newDescription,
      });
    } else {
      storeApi.outcomes.partial_update({ id: outcomeItem.id, description: newDescription });
    }
  };

  const outcome = "outcome" in outcomeItem ? outcomeItem.outcome : outcomeItem;
  // TODO: At some future point we need to lookup and return a string array with the previous names (if any).
  const previousNames = [];
  const showPreviousNames = previousNames.length > 0;

  return (
    <div className="page flex flex-col gap-3">
      <div className="items-centers flex justify-between">
        <div className="flex w-full items-center justify-start">
          <span className="h2 my-0">#</span>
          <HoverableToolbar
            className="flex w-full min-w-12 items-center justify-between"
            contentClassName="bg-white"
            readOnly={!isTitleAndDescriptionEditable}
            reference="outcome"
            showEditButton
          >
            <h2 className="my-0 flex w-full items-center">
              <HoverableEditField
                className="outcome-title pl-2"
                label={t("glossary.outcome_title")}
                textSize="h2"
                validatorFn={validateOutcomeName}
                value={outcome.title}
                onValueChanged={(title) => onTitleChanged("outcomes", outcomeItem.id, title)}
              />
            </h2>
          </HoverableToolbar>
          {!canAuthorCourse && isCourseLevel && (
            <CourseOutcomeAverageScore courseOutcome={outcomeItem} />
          )}
        </div>
        {canAuthorCourse && showPreviousNames && (
          <div className="body-s flex items-center gap-3 text-black-tint-40">
            <div className="bold">{t("outcomes.previous_names_list")}</div>
            <div className="body-xs">{previousNames.join(", ")}</div>
          </div>
        )}
      </div>
      <div>
        {isTitleAndDescriptionEditable &&
        outcome.description === "" &&
        !isEditingEmptyDescription ? (
          <div className="body-s mb-4">
            <TextAddButton
              reference="outcome_description"
              onClick={() => setIsEditingEmptyDescription(true)}
            />
          </div>
        ) : readOnly && outcome.description === "" ? null : (
          <div className="body-s mb-4">
            <HoverableToolbar
              className="mb-4"
              readOnly={!isTitleAndDescriptionEditable}
              reference="outcome_description"
              showEditButton
            >
              <HoverableEditField
                className="text-black-tint-40"
                editingOnStart={isEditingEmptyDescription}
                label={t("glossary.outcome_description")}
                textSize="s"
                value={outcome.description}
                multiline
                onValueChanged={onOutcomeDescriptionChange}
              />
            </HoverableToolbar>
          </div>
        )}
        {/* TODO: Fix the jiggling that happens when editing the page outcome rubrics. */}
        <div className="flex flex-wrap items-start justify-start gap-4">
          <div className="shrink grow-[2] basis-[300px]">
            <RubricList
              key={outcomeItem.id}
              outcomeItem={outcomeItem}
              readOnly={readOnly}
              rubric={rubric}
            />
          </div>
          {isCourseLevel && canAuthorCourse && (
            <div className="shrink-0 grow-0 basis-[320px]">
              <OutcomeUseIndex courseOutcome={outcomeItem} />
            </div>
          )}
        </div>
      </div>
      {isCourseLevel && (isStudentRole || isStudentPreview) && (
        <OutcomeStudentUseIndex courseOutcome={outcomeItem} />
      )}
    </div>
  );
};
