import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const ChevronLeftIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="fill"
      d="M10.2437 14C10.4157 14 10.5892 13.9408 10.7299 13.8193C11.0432 13.55 11.0799 13.0768 10.8106 12.7625L6.74104 8.00012L10.8114 3.23772C11.0806 2.92348 11.044 2.45099 10.7306 2.18099C10.4172 1.911 9.94451 1.947 9.67599 2.26274L5.1883 7.51263C4.9482 7.79313 4.9482 8.20787 5.1883 8.48836L9.67599 13.7383C9.82334 13.9115 10.0335 14 10.2437 14Z"
    />
  </svg>
);
