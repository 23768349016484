import { useCallback } from "react";
import type { FC } from "react";

import { t } from "i18n/i18n";
import { Menu, MenuDivider, MenuItem } from "mds/components/Menu";
import { DotMenuHorizontalIcon } from "mds/icons/DotMenuHorizontal";

export type CellContextMenuProps = {
  rowIndex: number;
  columnIndex: number;
  toggleCell: (rowIndex: number, columnIndex: number) => void;
  insertColumn: (columnIndex: number) => void;
  insertRow: (rowIndex: number) => void;
  removeColumn: (columnIndex: number) => void;
  removeRow: (rowIndex: number) => void;
  readOnly?: boolean;
};

export const CellContextMenu: FC<CellContextMenuProps> = ({
  rowIndex,
  columnIndex,
  toggleCell,
  insertColumn,
  insertRow,
  removeColumn,
  removeRow,
  readOnly = false,
}) => {
  const handleToggleCell = useCallback(() => {
    toggleCell(rowIndex, columnIndex);
  }, [toggleCell, rowIndex, columnIndex]);

  const handleInsertRowAbove = useCallback(() => insertRow(rowIndex), [insertRow, rowIndex]);
  const handleInsertRowBelow = useCallback(() => insertRow(rowIndex + 1), [insertRow, rowIndex]);
  const handleRemoveRow = useCallback(() => removeRow(rowIndex), [removeRow, rowIndex]);

  const handleInsertColumnLeft = useCallback(
    () => insertColumn(columnIndex),
    [insertColumn, columnIndex],
  );
  const handleInsertColumnRight = useCallback(
    () => insertColumn(columnIndex + 1),
    [insertColumn, columnIndex],
  );
  const handleRemoveColumn = useCallback(
    () => removeColumn(columnIndex),
    [removeColumn, columnIndex],
  );

  const buttons = [
    {
      label: readOnly ? t("cell_context_menu.unlock_cell") : t("cell_context_menu.lock_cell"),
      method: handleToggleCell,
      disabled: !toggleCell,
    },
    { separator: true, label: t("cell_context_menu.separator_1") },
    {
      label: t("cell_context_menu.add_column_left"),
      method: handleInsertColumnLeft,
      disabled: !insertColumn,
    },
    {
      label: t("cell_context_menu.add_column_right"),
      method: handleInsertColumnRight,
      disabled: !insertColumn,
    },
    {
      label: t("cell_context_menu.remove_column"),
      method: handleRemoveColumn,
      disabled: !removeColumn,
    },
    { separator: true, label: t("cell_context_menu.separator_2") },
    {
      label: t("cell_context_menu.add_row_above"),
      method: handleInsertRowAbove,
      disabled: !insertRow,
    },
    {
      label: t("cell_context_menu.add_row_below"),
      method: handleInsertRowBelow,
      disabled: !insertRow,
    },
    {
      label: t("cell_context_menu.remove_row"),
      method: handleRemoveRow,
      disabled: !removeRow,
    },
  ];

  return (
    <Menu
      menuButton={
        <div className="h-[16px] w-[16px]">
          <DotMenuHorizontalIcon className="block" />
        </div>
      }
    >
      {/* TODO: Set z-index to 2 */}
      {buttons.map(({ separator, label, method, disabled }) => {
        if (separator) {
          return <MenuDivider key={label} />;
        }
        return (
          <MenuItem disabled={disabled} key={label} onClick={method}>
            {label}
          </MenuItem>
        );
      })}
    </Menu>
  );
};
