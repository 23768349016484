import { t } from "i18n/i18n";
import { Banner } from "mds/components/Banner";

export const InstructorLateSubmissionBanner = () => {
  return (
    <Banner className="page-block-width" kind="warning">
      {t("warning.late_submission_alert_instructor")}
    </Banner>
  );
};
