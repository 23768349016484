import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const CodeBlockChevronIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M8.4668 13.6H15.0001"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M1 2.40002L6.6 8.00002L1 13.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
