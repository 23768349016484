import "highlight.js/styles/monokai-sublime.css";
import hljsCore from "highlight.js/lib/core";
import javascript from "highlight.js/lib/languages/javascript";
import python from "highlight.js/lib/languages/python";

hljsCore.registerLanguage("javascript", javascript);
hljsCore.registerLanguage("python", python);

// Quill will look for hljs on the window object when used with "syntax: true" or
// when using the "Quill.convert" method.
window.hljs = hljsCore;
