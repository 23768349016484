import "../../TopNavBar.scss";

import { AxiosError } from "axios";
import { FC, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router";

import { PageOverview } from "../page/PageOverview";

import { TopicSidebarPanel } from "./TopicSidebarPanel";

import { LoadingTextIndicator } from "components/LoadingScreen";
import { PAGE_ID_QUERY_PARAM } from "components/constants";
import { TopicSidebar } from "components/materials/topic/TopicSidebar";
import { ActivityCategory } from "components/server-types";
import { Trans, t } from "i18n/i18n";
import { AddButton } from "mds/components/AddButton";
import { useIsLgOrLarger } from "mds/hooks/use-responsive";
import { storeApi, useAppSelector } from "store/index";
import {
  selectCanAuthorCourse,
  selectCurrentCourseId,
  selectSortedTopicActivities,
  selectTopicById,
} from "store/selectors";
import { getSortedPages, isLesson, useActivityActions } from "utils/activity";
import { useFindItemBySearchParamId } from "utils/hooks/use-find-item-by-search-param-id";
import { usePageActions } from "utils/page";
import { usePresentation } from "utils/presentation";
import { courseHomeUrl } from "utils/urls";
import { ErrorBoundary } from "worksheets/views/ErrorBoundary";

export type TopicViewProps = {
  topicId: string;
};

export const TopicView: FC<TopicViewProps> = ({ topicId }) => {
  const navigate = useNavigate();
  const isLargeScreen = useIsLgOrLarger();
  const topicChanged = useRef(false);
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);

  const currentCourseId = useAppSelector(selectCurrentCourseId);
  const topic = useAppSelector((s) => selectTopicById(s, topicId));

  // Note: activities are now sorted by default
  const activities = useAppSelector((s) => selectSortedTopicActivities(s, topicId));
  const pages = useMemo(() => getSortedPages(activities), [activities]);

  const { selectPage } = usePageActions();
  const { presentedPageId, isPresenting } = usePresentation();

  const selectedPage = useFindItemBySearchParamId(pages, PAGE_ID_QUERY_PARAM);

  const shouldSelectForPresenting =
    !pages.some((page) => page.id === presentedPageId) && isPresenting;

  const { createActivity } = useActivityActions();
  // passing in an empty list of activities ensures we have order 0 which is the case for the first activity
  const createLessonActivity = () => createActivity(ActivityCategory.LESSON, [], topicId);
  const hasActivities = activities.some((activity) => isLesson(activity.category));

  useEffect(() => {
    if (!currentCourseId) {
      return;
    }
    if (topic) {
      storeApi.activities.list({ topic_id: topicId });
    } else {
      // If fetching the topic returns 404, we return to the main page
      storeApi.topics.retrieve(topicId).catch((e: AxiosError | Error) => {
        if (e instanceof AxiosError && e.response?.status === 404) {
          navigate(courseHomeUrl(currentCourseId));
        }
      });
    }
  }, [topic, topicId, navigate, currentCourseId]);

  useEffect(() => {
    topicChanged.current = true;
  }, [topicId]);

  useEffect(() => {
    // When we first display the view, change the topic, or delete a page
    // we select a new page automatically but without affecting some of the
    // secondary behaviors (like following, updating the presentation state, etc.).
    // In order to actually enforce those behaviors we need to directly call
    // selectPage with the selected page.  Also note, that you may have two browsers
    // open on different topics, so we only want to use the browser that has focus.
    if (
      isPresenting &&
      selectedPage &&
      (topicChanged.current || shouldSelectForPresenting) &&
      document.hasFocus()
    ) {
      selectPage(selectedPage);
      topicChanged.current = false;
    }
  }, [isPresenting, selectedPage, selectPage, shouldSelectForPresenting]);

  if (!topic) {
    return <LoadingTextIndicator text={t("content_view.loading_topic")} />;
  }

  return (
    <div className="topic-view flex h-full bg-black-tint-97">
      {isLargeScreen ? (
        <TopicSidebar topicId={topicId} />
      ) : (
        !selectedPage?.id && (
          <div className="content-sidebar-panel">
            <TopicSidebarPanel topicId={topicId} />
          </div>
        )
      )}

      {(isLargeScreen || selectedPage) && (
        <ErrorBoundary>
          {selectedPage ? (
            <PageOverview pageId={selectedPage?.id} pages={pages} selectedTopicId={topicId} />
          ) : (
            <div className="flex h-full w-full justify-center text-black-tint-40">
              <div className="empty-topic">
                <div className="flex flex-col items-center gap-3">
                  {!hasActivities && canAuthorCourse ? (
                    <>
                      <div>{t("page_detail_view.topic_is_empty")}</div>
                      <div className="flex items-center justify-center gap-2">
                        <Trans i18nKey="page_detail_view.add_activity_to_get_started" t={t}>
                          <AddButton onClick={createLessonActivity}>
                            {t("common.add.activity")}
                          </AddButton>
                          <span>to get started</span>
                        </Trans>
                      </div>
                    </>
                  ) : (
                    <div>{t("page_detail_view.no_content")}</div>
                  )}
                </div>
              </div>
            </div>
          )}
        </ErrorBoundary>
      )}
    </div>
  );
};
