import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const StarFilledIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="fill stroke"
      d="M8 1L9.6156 6.33099H15L10.7216 9.65262L12.4035 15L8 11.6948L3.59653 15L5.2784 9.65262L1 6.33099H6.3844L8 1Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
