import clsx from "clsx";
import { ReactNode, forwardRef } from "react";
import "./DualButton.scss";

export type DualButtonProps = {
  buttonLeft: ReactNode;
  buttonRight: ReactNode;
  gap?: boolean;
  onClick?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
};

/**
 * Pass in two instantiated MDS buttons to create a dual button.
 */
export const DualButton = forwardRef<HTMLDivElement, DualButtonProps>(
  ({ buttonLeft, buttonRight, onClick, onKeyDown, gap = false }, ref) => {
    return (
      <div
        className={clsx("dual-button flex", gap && "gap")}
        ref={ref}
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        <span className="dual-button-left">{buttonLeft}</span>
        <span className="dual-button-right">{buttonRight}</span>
      </div>
    );
  },
);

DualButton.displayName = "DualButton";
