import "./PagePresentingMenu.scss";
import { CSSProperties, FC } from "react";

import { t } from "i18n/i18n";
import { Button, ButtonProps } from "mds/components/Button";
import { IconText } from "mds/components/IconText";
import { Menu, MenuDivider, MenuItem } from "mds/components/Menu";
import { ChevronDownIcon, PresentPlayBackgroundIcon, PresentPlayIcon } from "mds/icons";

export type PagePresentingMenuProps = {
  styles?: CSSProperties;
  onPresent?: () => void;
  onPractice?: () => void;
  size?: ButtonProps["size"];
};

export const PagePresentingMenu: FC<PagePresentingMenuProps> = ({
  styles,
  onPresent,
  onPractice,
  size = "s",
}) => {
  const MenuButton = (
    <Button className="gap-1" kind="secondary" size={size}>
      <PresentPlayIcon />
      {t("page_presenting_menu.present")}
      <ChevronDownIcon />
    </Button>
  );

  return (
    <Menu
      className="page-presenting-menu"
      direction="bottom"
      menuButton={MenuButton}
      menuStyle={styles}
    >
      <MenuItem className="icon-green" onClick={onPresent}>
        <IconText
          iconStart={<PresentPlayBackgroundIcon />}
          text={t("page_presenting_menu.start_presenting")}
        />
      </MenuItem>

      <MenuDivider />

      <MenuItem className="icon-practice" onClick={onPractice}>
        <IconText
          iconStart={<PresentPlayBackgroundIcon />}
          text={t("page_presenting_menu.practice")}
        />
      </MenuItem>
    </Menu>
  );
};
