import { FC } from "react";

import { t } from "i18n/i18n";
import { Banner } from "mds/components/Banner";
import { useAppDispatch } from "store/index";
import { localStateActions } from "store/slices/local";

export const EmptyPageBanner: FC = () => {
  const dispatch = useAppDispatch();
  const enableEditMode = () => {
    dispatch(localStateActions.enableEditMode());
  };
  return (
    <Banner actionButtonText={t("warning.enable_edit_mode")} kind="info" onAction={enableEditMode}>
      {t("warning.page_empty")}
    </Banner>
  );
};
