import { type FC } from "react";
import { useSearchParams } from "react-router-dom";

import { ToggleButton } from "mds/components/ToggleButton";
import { exportActivitiesAndPagesForSeedData } from "seeds/export-seed";
import { ActivityWithPagesType } from "store/selectors";
import { toastLocalizedOperationCatcher } from "utils/alerts";

type SidebarToggleItemProps = {
  value: string;
  label: string;
};

const SidebarToggleItem: FC<SidebarToggleItemProps> = ({ value, label }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="body-s mt-1 flex items-center">
      <div className="mr-2">{label}</div>
      <ToggleButton
        id={value}
        initialValue={searchParams.get(value) === "1"}
        label={label}
        value={searchParams.get(value) === "1"}
        onChange={() => {
          if (searchParams.get(value) === "1") {
            searchParams.delete(value);
          } else {
            searchParams.set(value, "1");
          }
          setSearchParams(searchParams);
        }}
      />
    </div>
  );
};

export type TopicDevToolsProps = {
  activities: ActivityWithPagesType[];
};

// This component displays several dev tool related actions for a topic.
// Do not localize the strings in this component. They are for developer use only.
export const TopicDevTools: FC<TopicDevToolsProps> = ({ activities }) => {
  const onExportTopicClicked = () => {
    exportActivitiesAndPagesForSeedData(activities).catch(
      toastLocalizedOperationCatcher("export_seed_data_failure"),
    );
  };

  return (
    <div className="mt-5">
      <h3 className="mt-7">Developer Tools</h3>
      <div className="body-s ml-2">
        <div>For Seeding New Content</div>
        <div className="ml-2">
          <SidebarToggleItem label="Seed Worksheets" value="seedWorksheets" />
          <div className="cursor-pointer" onClick={onExportTopicClicked}>
            Export Topic for Seeding
          </div>
        </div>
        <div className="mt-2">Worksheet functionality</div>
        <div className="ml-2">
          <SidebarToggleItem label="Timeline" value="timeline" />
          <SidebarToggleItem label="Read Only" value="readOnly" />
        </div>
      </div>
    </div>
  );
};
