import "components/TopNavBar.scss";

import { FC, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import { PageOverview } from "../PageOverview";

import { CoursePageSidebarPanel } from "./CoursePageSidebarPanel";

import { CATEGORY_QUERY_PARAM, PAGE_ID_QUERY_PARAM } from "components/constants";
import { CoursePageSidebar } from "components/materials/page/course-page/CoursePageSideBar";
import { CoursePageCategory } from "components/server-types";
import { t } from "i18n/i18n";
import { AddButton } from "mds/components/AddButton";
import { useOrdered } from "mds/hooks/use-ordered";
import { useIsLgOrLarger } from "mds/hooks/use-responsive";
import { useAppSelector } from "store/index";
import { selectCanAuthorCourse, selectPagesForCurrentCourse } from "store/selectors";
import { useListSelector } from "store/store-hooks";
import { useFindItemBySearchParamId } from "utils/hooks/use-find-item-by-search-param-id";
import { isCoursePageCategory, usePageActions } from "utils/page";
import { usePresentation } from "utils/presentation";
import { ErrorBoundary } from "worksheets/views/ErrorBoundary";

export const CourseResourceView: FC = () => {
  const [searchParams] = useSearchParams();
  const categoryChanged = useRef(false);
  const { createPage } = usePageActions();
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const pageCategory = searchParams.get(CATEGORY_QUERY_PARAM) as CoursePageCategory;

  if (!isCoursePageCategory(pageCategory)) {
    throw new Error(`Invalid page category: ${pageCategory as string}`);
  }

  const createNewPage = () => createPage(pageCategory, []);

  const isLargeScreen = useIsLgOrLarger();
  const coursePages = useListSelector((s) => selectPagesForCurrentCourse(s, pageCategory));
  const pages = useOrdered(coursePages);
  const selectedPage = useFindItemBySearchParamId(pages, PAGE_ID_QUERY_PARAM);

  const { selectPage } = usePageActions();
  const { isPresenting } = usePresentation();

  useEffect(() => {
    categoryChanged.current = true;
  }, [pageCategory]);

  useEffect(() => {
    // When we first display the view or change the category we select a new page
    // automatically but without affecting some of the secondary behaviors
    // (like following, updating the presentation state, etc.).
    // In order to actually enforce those behaviors we need to directly call
    // selectPage with the selected page.  Also note, that you may have two browsers
    // open on different topics, so we only want to use the browser that has focus.
    if (isPresenting && selectedPage && categoryChanged.current && document.hasFocus()) {
      selectPage(selectedPage);
      categoryChanged.current = false;
    }
  }, [selectedPage, selectPage, isPresenting]);

  return (
    <div className="flex h-full bg-black-tint-97">
      {isLargeScreen ? (
        <CoursePageSidebar />
      ) : (
        !selectedPage?.id && (
          <div className="content-sidebar-panel">
            <CoursePageSidebarPanel />
          </div>
        )
      )}

      {(isLargeScreen || selectedPage?.id) && (
        <ErrorBoundary>
          {selectedPage ? (
            <PageOverview pageId={selectedPage.id} pages={coursePages} />
          ) : (
            <div className="flex h-full w-full justify-center text-black-tint-40">
              <div className="empty-topic">
                <div className="flex flex-col items-center gap-3">
                  <div>
                    {t("course_page_view.no_pages", { category: t(`glossary.${pageCategory}`) })}
                  </div>
                  {canAuthorCourse && (
                    <AddButton onClick={createNewPage}>{t("common.add.page")}</AddButton>
                  )}
                </div>
              </div>
            </div>
          )}
        </ErrorBoundary>
      )}
    </div>
  );
};
