import type { FC } from "react";

import { AddOutcome } from "components/outcomes/AddOutcome";
import { MAX_PAGE_OUTCOMES, courseOutcomesToOptions } from "components/outcomes/helpers";
import { OptionsType } from "mds/components/TextListDropdown";
import { storeApi, useAppSelector } from "store/index";
import {
  selectCanAuthorCourse,
  selectCurrentCourse,
  selectFullCourseOutcomes,
  selectNestedPageOutcomes,
} from "store/selectors";

interface AddOutcomePlusButtonProps {
  pageId: string;
  isDisabled: boolean;
  title?: string;
}

export const AddOutcomePlusButton: FC<AddOutcomePlusButtonProps> = ({
  pageId,
  isDisabled,
  title,
}) => {
  const pageOutcomes = useAppSelector((s) => selectNestedPageOutcomes(s, pageId));
  const pageCourseOutcomeIds = pageOutcomes.map((pageOutcome) => pageOutcome.course_outcome_id);
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);

  const course = useAppSelector(selectCurrentCourse);
  const courseOutcomes = useAppSelector((s) => selectFullCourseOutcomes(s, course.id));

  const selectableCourseOutcomeOptions = courseOutcomes.filter(
    (courseOutcome) => !pageCourseOutcomeIds.includes(courseOutcome.id),
  );
  const options: OptionsType = courseOutcomesToOptions(selectableCourseOutcomeOptions);

  if (!canAuthorCourse) return null;

  const onSave = (courseOutcomeIds: string[]) => {
    courseOutcomeIds.forEach((courseOutcomeId) => {
      storeApi.page_outcomes // TODO: Bulk create
        .create({ page_id: pageId, course_outcome_id: courseOutcomeId });
    });
  };

  return (
    <div className="flex items-center gap-1">
      <AddOutcome
        hasReachedLimit={pageOutcomes.length === MAX_PAGE_OUTCOMES}
        isDisabled={isDisabled}
        limit={Math.max(MAX_PAGE_OUTCOMES - pageCourseOutcomeIds.length, 0)}
        options={options}
        title={title}
        iconOnly
        onSave={onSave}
      />
    </div>
  );
};
