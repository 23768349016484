import { ImportCourseButton } from "./content/ImportCourseButton";

import { HoverableEditField } from "components/hover-widgets/EditableField";
import { HoverableToolbar } from "components/hover-widgets/HoverableToolbar";
import { CourseType } from "components/server-types";
import { t } from "i18n/i18n";
import { useAppSelector } from "store/index";
import {
  selectCanAuthorCourse,
  selectCourseCodes,
  selectCurrentOrg,
  selectPagesForCurrentCourse,
  selectTopicsForCurrentCourse,
} from "store/selectors";
import { useListSelector } from "store/store-hooks";
import { updateCourse } from "utils/course";
import { isCoursePageCategory } from "utils/page";

export const CourseTitleBar = ({ course }: { course: CourseType }) => {
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const org = useAppSelector(selectCurrentOrg);
  const courseCodes = useAppSelector(selectCourseCodes);
  const courseCode = courseCodes.find((cc) => cc.id === course.course_code_id);
  const coursePages = useListSelector(selectPagesForCurrentCourse).filter((p) =>
    isCoursePageCategory(p.category),
  );
  const topics = useListSelector(selectTopicsForCurrentCourse);
  const hasMaterials = topics.length > 0 || coursePages.length > 0;
  return (
    <div className="mb-3 flex flex-col">
      <div className="flex w-full items-center justify-between text-black-tint-40">
        <HoverableToolbar
          contentClassName="bg-white"
          readOnly={!canAuthorCourse}
          reference="course_title"
          showEditButton
        >
          <h2 className="flex items-center gap-2 text-base text-black-tint-20">
            <HoverableEditField
              label={t("glossary.course_title")}
              value={course.title}
              shouldWrap
              onValueChanged={(title) => updateCourse({ id: course.id, title })}
            />
            {courseCode && (
              <span className="whitespace-nowrap text-sm font-normal">({courseCode?.title})</span>
            )}
          </h2>
        </HoverableToolbar>
        {canAuthorCourse && !hasMaterials && <ImportCourseButton />}
      </div>
      {org && <div className="text-base text-black-tint-20">{org.name}</div>}
    </div>
  );
};
