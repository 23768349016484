import "./MainHeader.scss";

import clsx from "clsx";
import { FC, useEffect } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";

import { ConnectionStatusIndicator } from "./ConnectionStatusIndicator";
import { EditModeButton } from "./EditModeButton";

import { LOGO_HEADER_SIZE, PAGE_ID_QUERY_PARAM } from "components/constants";
import { CourseBreadcrumbs } from "components/header/CourseBreadcrumbs";
import { ManageInstitutionButton } from "components/header/ManageInstitutionButton";
import { BackToCourseButton } from "components/materials/page/BackToCourseButton";
import { PresentationOptions } from "components/materials/presentation/PresentationOptions";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { useIsMdOrLarger } from "mds/hooks/use-responsive";
import { ForumLogoIcon, ForumLogoWhiteIcon } from "mds/icons";
import { storeApi, useAppDispatch, useAppSelector } from "store/index";
import {
  selectCanAuthorCourse,
  selectCanAuthorOrg,
  selectCurrentCourse,
  selectCurrentUserId,
  selectIsAssessing,
  selectIsStudentPreview,
  selectOrgs,
  selectPageById,
} from "store/selectors";
import { viewStateActions } from "store/slices/view";
import { trackEvent } from "utils/amplitude";
import { usePresentation } from "utils/presentation";
import { courseHomeUrl, getCourseViewVariantFromPath } from "utils/urls";

type HeaderStateType =
  | "student_preview"
  | "presenting_other"
  | "practicing"
  | "following"
  | "presenting_current"
  | "not_following_presentation"
  | "default";

const HEADER_STATE_CLASSES: Record<HeaderStateType, string> = {
  default: "bg-white text-black-tint-20",
  // Student-only
  following: "bg-green text-white",
  not_following_presentation: "bg-orange text-white",
  // Instructor-only
  presenting_current: "bg-green text-white",
  presenting_other: "bg-orange text-white",
  practicing: "bg-black-tint-55 text-white",
  student_preview: "bg-violet",
};

// TODO: Potentially rename to CourseHeader
export const MainHeader: FC = () => {
  const [searchParams] = useSearchParams();

  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const canAuthorOrg = useAppSelector(selectCanAuthorOrg);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const course = useAppSelector(selectCurrentCourse);
  const isAssessing = useAppSelector(selectIsAssessing);

  const selectedPageId = searchParams.get(PAGE_ID_QUERY_PARAM);
  const selectedPage = useAppSelector((s) => selectPageById(s, selectedPageId));

  const dispatch = useAppDispatch();
  const isSmallScreen = !useIsMdOrLarger();
  const { pathname } = useLocation();
  const courseViewVariant = getCourseViewVariantFromPath(pathname);
  const isStudentPreview = useAppSelector(selectIsStudentPreview);
  const userOrgs = useAppSelector(selectOrgs);

  const {
    isPresenting,
    isPresentingUser,
    isPracticing,
    isFollowing,
    presentedPageId,
    unfeaturedTab,
    inPresentationMode,
  } = usePresentation();

  const viewingPresentation = isPresentingUser || isFollowing;
  const canManageInstitutions = !viewingPresentation && (canAuthorOrg || userOrgs.length > 1);

  const onlyShowCourseSelectBreadcrumb = isAssessing || viewingPresentation;
  // When presenting or assessing we intentionally avoid giving options for the user to leave the topic view
  // without them choosing to formally end the presentation or assessment mode first. This is to reduce
  // potential confusion on how the system will handle when they navigate away.
  const enableCourseSelectBreadcrumb = !isPresenting && !isAssessing;
  const isLogoClickable = enableCourseSelectBreadcrumb;

  useEffect(() => {
    if (currentUserId) {
      storeApi.org_users.list({ user_id: currentUserId });
    }
  }, [currentUserId]);

  const headerState = isStudentPreview
    ? "student_preview"
    : unfeaturedTab
      ? "presenting_other"
      : isPracticing
        ? "practicing"
        : isFollowing
          ? "following"
          : isPresenting
            ? "presenting_current"
            : presentedPageId
              ? "not_following_presentation"
              : "default";

  const connectionIndicator = (
    <ConnectionStatusIndicator
      className="flex items-center p-1"
      isWhite={headerState !== "default"}
    />
  );

  const logo = inPresentationMode ? (
    <ForumLogoWhiteIcon width={LOGO_HEADER_SIZE} />
  ) : (
    <ForumLogoIcon width={LOGO_HEADER_SIZE} />
  );

  if (isSmallScreen) {
    throw new Error("MobileMainHeader should be used for small screens.");
  }

  return (
    <header
      className={clsx(
        "main-navbar flex items-center justify-between",
        HEADER_STATE_CLASSES[headerState],
      )}
    >
      <div
        className={clsx("logo flex items-center gap-2", {
          presenting: inPresentationMode || isStudentPreview,
        })}
      >
        {isLogoClickable ? (
          <Link
            aria-label="forum"
            className="flex flex-shrink-0 cursor-pointer items-center border-0 text-black"
            to={courseHomeUrl(course?.id)}
          >
            {logo}
          </Link>
        ) : (
          <div className="mr-1 flex items-center text-black">{logo}</div>
        )}

        <div className="border-l border-black-tint-90 py-[7px]" />

        <CourseBreadcrumbs
          enableCourseSelect={enableCourseSelectBreadcrumb}
          onlyShowCourseSelect={onlyShowCourseSelectBreadcrumb}
          view={courseViewVariant}
        />

        {headerState !== "default" && connectionIndicator}
      </div>

      {isStudentPreview && (
        <div className="flex items-center gap-3 justify-self-center text-white">
          <span>{t("main_header.is_student_preview")}</span>
          <Button
            kind="secondary"
            onClick={() => {
              trackEvent("Header - Stop preview as a student", {
                eventCategory: "Button press",
              });
              dispatch(viewStateActions.stopStudentPreview());
            }}
          >
            {t("main_header.stop_student_preview_short")}
          </Button>
        </div>
      )}

      <div className="flex items-center gap-2 justify-self-end">
        {headerState === "default" && connectionIndicator}

        {(!isAssessing || !selectedPage) && (
          <>
            {courseViewVariant === "main" && canManageInstitutions && <ManageInstitutionButton />}

            <PresentationOptions
              courseViewVariant={courseViewVariant}
              selectedPage={selectedPage}
            />

            {["topic", "course_page"].includes(courseViewVariant) && (
              <>
                {canAuthorCourse && <EditModeButton />}
                <BackToCourseButton />
              </>
            )}
          </>
        )}
      </div>
    </header>
  );
};
