import clsx from "clsx";
import { useState } from "react";

import { LOGO_HEADER_SIZE } from "components/constants";
import { ConnectionIcon } from "components/header/ConnectionStatusIndicator";
import { HoverableEditField } from "components/hover-widgets/EditableField";
import { HoverableToolbar } from "components/hover-widgets/HoverableToolbar";
import { DueDateButton } from "components/materials/page/DueDateButton";
import { GroupNotAssignedBanner } from "components/materials/page/banners/GroupNotAssignedBanner";
import { InstructorLockedPageBanner } from "components/materials/page/banners/InstructorLockedPageBanner";
import { MismatchedVersionBanner } from "components/materials/page/banners/MismatchedVersionBanner";
import { PageReleasedBanner } from "components/materials/page/banners/PageReleasedBanner";
import { StudentLockedPageBanner } from "components/materials/page/banners/StudentLockedPageBanner";
import { PageSidebarOptionsMenu } from "components/menus/PageSidebarOptionsMenu";
import { GroupingCategory, PageType } from "components/server-types";
import { AddButton } from "mds/components/AddButton";
import { Avatar } from "mds/components/Avatar";
import { Banner } from "mds/components/Banner";
import { Button, IconColorType, IconKind, IconSizeType } from "mds/components/Button";
import { Checkbox } from "mds/components/Checkbox";
import { DropdownMenu } from "mds/components/DropdownMenu";
import { FileUploadField } from "mds/components/FileUploadField";
import { ListItem } from "mds/components/ListItem";
import { Menu, MenuDivider, MenuItem, SubMenu } from "mds/components/Menu";
import { OutcomeChip } from "mds/components/OutcomeChip";
import { TabBar } from "mds/components/TabBar";
import { TextAddButton } from "mds/components/TextAddButton";
import { TextArea } from "mds/components/TextArea";
import { TextField } from "mds/components/TextField";
import { TextListDropdown } from "mds/components/TextListDropdown";
import { ToggleButton } from "mds/components/ToggleButton";
import { Tooltip } from "mds/components/Tooltip";
import { BaseSizeType } from "mds/global-types";
import {
  AssessPointsMarkerIcon,
  CalendarIcon,
  ChevronDownIcon,
  LockLockedIcon,
  MinervaLogoIcon,
  PageListStatusIcon,
  PageResourcePDFIcon,
  PageStatusIcon,
  PresentPlayIcon,
  ScoreIcon,
  SidebarIcon,
  StatusDotIcon,
} from "mds/icons";
import * as allIcons from "mds/icons";

const fakePage: PageType = {
  id: "123",
  title: "Page title",
  due_at: "2022-12-31T23:59:59Z",
  late_submission_interval: null,
  category: "assignment",
  order: 1,
  worksheet_id: "",
  released_at: "2022-12-31T23:59:59Z",
  last_rolled_up_at: null,
  instructor_notes_worksheet_id: "",
  grouping_category: GroupingCategory.INDIVIDUAL,
  max_points: 0,
  course_id: "123",
  is_imported: false,
};

const IconsStates = ({
  kind,
  color,
  size,
}: {
  kind: IconKind;
  color: IconColorType;
  size: IconSizeType;
}) => {
  return (
    <div className={clsx("flex flex-col gap-2")}>
      <Button className="flex gap-2" color={color} kind={kind} size={size}>
        <PresentPlayIcon />
        <span>Action</span>
      </Button>
      <Button className="flex gap-2" color={color} kind={kind} size={size} disabled>
        <PresentPlayIcon />
        <span>Action</span>
      </Button>
      <Button className="flex gap-2" color={color} kind={kind} size={size} isLoading>
        <PresentPlayIcon />
        <span>Action</span>
      </Button>
      <Button className="flex gap-2" color={color} kind={kind} size={size} iconOnly>
        <PresentPlayIcon />
      </Button>
    </div>
  );
};

export default function Page() {
  // This serves as a temporary storybook page
  // This is somewhat duplicate with the actual FDS storybook, which
  // is located at picasso/forum-design-system/stories.
  // I only added things here that are specifically different from FDS, and that,
  // when we copy over the storybook implementation, should be overhauled there.
  const [iconColored, setIconColored] = useState(false);
  const [iconInteractive, setIconInteractive] = useState(false);
  const [iconSize, setIconSize] = useState<BaseSizeType>("m");
  const iconColorList = ["red", "green", "blue", "orange", "black"];

  return (
    <div className="page flex flex-col gap-6">
      <h1>Monarch Design System</h1>
      <div className="body-s">
        This page lists UI components used in Monarch, and is for display and development only.
      </div>
      <div className="gap-2">
        <h2>Buttons</h2>
        <div className="flex">
          {["primary", "secondary", "tertiary", "destructive", ...iconColorList].map(
            (_kind: IconKind | IconColorType | "dark") => (
              <div className="flex flex-wrap items-center gap-2" key={_kind}>
                {["xs", "s", "m", "l"].map((size: IconSizeType) => {
                  const isDark = _kind === "dark";
                  const kindIsColor = !isDark && iconColorList.includes(_kind as IconColorType);
                  const color: IconColorType = isDark
                    ? null
                    : kindIsColor
                      ? (_kind as IconColorType)
                      : null;
                  const kind: IconKind = isDark
                    ? "tertiary"
                    : kindIsColor
                      ? "primary"
                      : (_kind as IconKind);
                  return (
                    <IconsStates
                      color={color}
                      key={`${kind}-${size}-${color}`}
                      kind={kind}
                      size={size}
                    />
                  );
                })}
              </div>
            ),
          )}
        </div>
      </div>
      <div>
        <h2>Special Buttons</h2>
        <h3>Add Buttons</h3>
        <div>
          <div className="flex items-center gap-4">
            <AddButton onClick={() => {}}>Add Page</AddButton>
            <AddButton onClick={() => {}}>Add Block</AddButton>
          </div>
          <TextAddButton reference="page" onClick={() => {}} />
          <TextAddButton reference="topic" onClick={() => {}} />
          <TextAddButton reference="activity" onClick={() => {}} />
          <TextAddButton reference="assignment" onClick={() => {}} />
          <TextAddButton reference="course_resource" onClick={() => {}} />
          <TextAddButton reference="instructor_workspace" onClick={() => {}} />
        </div>
      </div>
      <div>
        <h2 className="mb-2">File Upload Fields</h2>
        <div className="flex flex-wrap gap-3">
          <div>
            <h3>Default</h3>
            <FileUploadField size="s" onDrop={() => {}} />
            <FileUploadField size="m" onDrop={() => {}} />
            <FileUploadField size="l" onDrop={() => {}} />
          </div>
          <div>
            <h3>With button prop</h3>
            <FileUploadField
              addButtonProps={{ type: "page", onClick: () => {} }}
              className="m-3"
              size="s"
              onDrop={() => {}}
            />
            <FileUploadField
              addButtonProps={{ type: "block", onClick: () => {} }}
              className="m-3"
              size="m"
              onDrop={() => {}}
            />
            <FileUploadField
              addButtonProps={{ type: "page", onClick: () => {} }}
              className="m-3"
              size="l"
              onDrop={() => {}}
            />
          </div>
          <div>
            <h3>Loading</h3>
            <FileUploadField className="m-3" size="s" isLoading onDrop={() => {}} />
            <FileUploadField className="m-3" size="m" isLoading onDrop={() => {}} />
            <FileUploadField className="m-3" size="l" isLoading onDrop={() => {}} />
          </div>
        </div>
      </div>
      <div>
        <h2>Popup menus</h2>
        <div className="flex">
          <Menu menuButton={<Button kind="primary">Open</Button>}>
            <MenuItem>
              <SidebarIcon />
              Close Sidebar
            </MenuItem>
            <MenuDivider />
            <MenuItem>
              <LockLockedIcon />
              Lock Page
            </MenuItem>
            <SubMenu
              label={
                <div className="flex items-center justify-between gap-2">
                  <div className="flex items-center gap-2">
                    <CalendarIcon />
                    <span>Sub-menu</span>
                  </div>
                </div>
              }
              withArrow
            >
              <MenuItem>
                <CalendarIcon />
                Choose Date
              </MenuItem>
            </SubMenu>
          </Menu>
        </div>
      </div>
      <div className="mb-2">
        <h2>Primary Tabs</h2>
        <TabBar
          tabs={[
            {
              label: <div>Tab 1</div>,
              title: "Tab 1",
              panel: <div>This is some content for tab 1</div>,
            },
            {
              label: <div>Tab 2</div>,
              title: "Tab 2",
              panel: <div>This is some other content for tab 2</div>,
            },
            {
              label: (
                <DropdownMenu
                  className="bg-black-tint-93"
                  items={[
                    { id: "1", title: "dropdown - item 1" },
                    { id: "2", title: "dropdown - item 2" },
                    { id: "3", title: "dropdown - item 3" },
                  ]}
                  onSelectionChange={() => {}}
                />
              ),
              title: "Tab 3",
              panel: <div>This is content for the third tab</div>,
            },
          ]}
        />
      </div>
      <div className="mb-2">
        <h2>Secondary Tabs</h2>
        <TabBar
          kind="secondary"
          tabs={[
            {
              label: <div>Tab 1</div>,
              title: "Tab 1",
              panel: <div>This is some content for tab 1</div>,
            },
            {
              label: <div>Tab 2</div>,
              title: "Tab 2",
              panel: <div>This is some other content for tab 2</div>,
            },
            {
              label: (
                <DropdownMenu
                  className="bg-black-tint-93"
                  items={[
                    { id: "1", title: "dropdown - item 1" },
                    { id: "2", title: "dropdown - item 2" },
                    { id: "3", title: "dropdown - item 3" },
                  ]}
                  onSelectionChange={() => {}}
                />
              ),
              title: "Tab 3",
              panel: <div>This is content for the third tab</div>,
            },
          ]}
        />
      </div>
      <div className="my-2 max-w-[600px]">
        <h2>Primary List Items</h2>
        <ListItem kind="primary">A list item</ListItem>
        <ListItem kind="primary" isSelected>
          A selected list item
        </ListItem>
        <ListItem kind="primary" to="/mds">
          A link list item
        </ListItem>
        <ListItem kind="primary" to="/mds">
          A selected link list item
        </ListItem>
        <ListItem className="justify-between" kind="primary">
          <HoverableToolbar className="w-full" reference="topic" showEditButton>
            <div className="body-m flex w-full items-center gap-2">
              <PageStatusIcon color="green" />
              <HoverableEditField
                className="editable-field w-full"
                textSize="m"
                value="List item with editable field and menu"
                onValueChanged={() => {}}
              />
            </div>
            <PageSidebarOptionsMenu
              buttonKind="tertiary"
              direction="left"
              page={{ id: "bla" } as PageType}
            />
          </HoverableToolbar>
          <DueDateButton page={fakePage} clickStopPropagation shortText />
        </ListItem>
      </div>
      <div className="my-2 max-w-[600px]">
        <h2>Secondary List Items</h2>
        <ListItem>A list item</ListItem>
        <ListItem isSelected>A selected list item</ListItem>
        <ListItem to="/mds">A link list item</ListItem>
        <ListItem to="/mds">A selected link list item</ListItem>
        <ListItem className="justify-between">
          <HoverableToolbar className="w-full" reference="topic" showEditButton>
            <div className="body-m flex items-center gap-2">
              <PageStatusIcon color="green" />
              <HoverableEditField
                className="editable-field"
                textSize="m"
                value="List item with editable field and menu"
                onValueChanged={() => {}}
              />
            </div>
            <PageSidebarOptionsMenu
              buttonKind="tertiary"
              direction="left"
              page={{ id: "bla" } as PageType}
            />
          </HoverableToolbar>
          <DueDateButton kind="list-item" page={fakePage} clickStopPropagation shortText />
        </ListItem>
      </div>
      <div>
        <h2>Tooltips</h2>
        <Tooltip>Test test!</Tooltip>
      </div>
      <div className="my-2">
        <h2>Toggles</h2>
        <div className="flex items-center gap-4">
          <ToggleButton id="t1" label="t1" value onChange={() => {}} />
          <ToggleButton id="t1" label="t1" value={false} onChange={() => {}} />
          <ToggleButton id="t1" label="t1" disabled value onChange={() => {}} />
          <ToggleButton id="t1" label="t1" value={false} disabled onChange={() => {}} />
        </div>
      </div>
      <div>
        <h2>Checkboxes</h2>
        <div className="flex items-center">
          Unchecked
          <Checkbox className="ml-1 mr-3" />
          Checked
          <Checkbox className="ml-1 mr-3" initialChecked />
          Disabled/Unchecked
          <Checkbox className="ml-1 mr-3" disabled />
          Disabled/Checked
          <Checkbox className="ml-1 mr-3" checked disabled />
        </div>
      </div>
      <div>
        <h2>Text List Dropdown</h2>
        <div className="mb-2 flex w-[500px] items-center">
          <div className="w-200px">With Checkbox</div>
          <TextListDropdown
            controlIcon={<ChevronDownIcon />}
            options={[
              { value: 1, label: "Apple" },
              { value: 2, label: "Orange" },
              { value: 3, label: "Banana" },
            ]}
            placeholder="Custom placeholder text..."
            useCheckboxOption
          />
        </div>
        <div className="flex w-[500px] items-center">
          <div className="w-[200px]">Without Checkbox</div>
          <TextListDropdown
            controlIcon={<ChevronDownIcon />}
            options={[
              { value: 1, label: "Apple" },
              { value: 2, label: "Orange" },
              { value: 3, label: "Banana" },
            ]}
            placeholder="Custom placeholder text..."
          />
        </div>
      </div>
      <div>
        <h2>Text Fields</h2>
        <div className="flex">
          <div className="flex flex-col items-center">
            <div className="body-s">Empty</div>
            <TextField className="mb-2 mt-2 flex w-40" placeholder="Text xs" size="xs" />
            <TextField className="mb-2 flex w-40" placeholder="Text s" size="s" />
            <TextField className="mb-2 flex w-40" placeholder="Text m" size="m" />
            <TextField className="mb-2 flex w-40" placeholder="Text h6" size="h6" />
            <TextField className="mb-2 flex w-40" placeholder="Text h4" size="h4" />
            <TextField className="mb-2 flex w-40" placeholder="Text h3" size="h3" />
            <TextField className="mb-2 flex w-40" placeholder="Text h2" size="h2" />
            <TextField className="mb-2 flex w-40" placeholder="Text h1" size="h1" />
          </div>
          <div className="ml-2 flex flex-col items-center">
            <div className="body-s">Filled</div>
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text xs" size="xs" />
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text s" size="s" />
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text m" size="m" />
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text h6" size="h6" />
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text h4" size="h4" />
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text h3" size="h3" />
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text h2" size="h2" />
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text h1" size="h1" />
          </div>
          <div className="ml-2 flex flex-col items-center">
            <div className="body-s">Disabled</div>
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text xs" size="xs" disabled />
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text s" size="s" disabled />
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text m" size="m" disabled />
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text h6" size="h6" disabled />
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text h4" size="h4" disabled />
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text h3" size="h3" disabled />
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text h2" size="h2" disabled />
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text h1" size="h1" disabled />
          </div>
          <div className="ml-2 flex flex-col items-center">
            <div className="body-s">Display Only</div>
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text xs" size="xs" readOnly />
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text s" size="s" readOnly />
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text m" size="m" readOnly />
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text h6" size="h6" readOnly />
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text h4" size="h4" readOnly />
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text h3" size="h3" readOnly />
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text h2" size="h2" readOnly />
            <TextField className="mb-2 mt-2 flex w-40" defaultValue="Text h1" size="h1" readOnly />
          </div>
        </div>
      </div>
      <div className="ml-2 w-40">
        <div className="body-s mb-2">With Icon</div>
        <TextField
          defaultValue="Text s"
          size="s"
          startIcon={<AssessPointsMarkerIcon />}
          wrapperClassName="flex items-center"
        />
      </div>
      <div>
        <h2>Text Areas</h2>
        <div className="flex">
          <div className="flex flex-col items-center">
            <div className="body-s">Empty</div>
            <TextArea className="mb-2 mt-2 flex w-40" placeholder="Text xs" size="xs" />
            <TextArea className="mb-2 flex w-40" placeholder="Text s" size="s" />
            <TextArea className="mb-2 flex w-40" placeholder="Text m" size="m" />
          </div>
          <div className="ml-2 flex flex-col items-center">
            <div className="body-s">Filled</div>
            <TextArea className="mb-2 mt-2 flex w-40" defaultValue="Text xs" size="xs" />
            <TextArea className="mb-2 mt-2 flex w-40" defaultValue="Text s" size="s" />
            <TextArea className="mb-2 mt-2 flex w-40" defaultValue="Text m" size="m" />
          </div>
          <div className="ml-2 flex flex-col items-center">
            <div className="body-s">Disabled</div>
            <TextArea className="mb-2 mt-2 flex w-40" defaultValue="Text xs" size="xs" disabled />
            <TextArea className="mb-2 mt-2 flex w-40" defaultValue="Text s" size="s" disabled />
            <TextArea className="mb-2 mt-2 flex w-40" defaultValue="Text m" size="m" disabled />
          </div>
          <div className="ml-2 flex flex-col items-center">
            <div className="body-s">Display Only</div>
            <TextArea className="mb-2 mt-2 flex w-40" defaultValue="Text xs" size="xs" readOnly />
            <TextArea className="mb-2 mt-2 flex w-40" defaultValue="Text s" size="s" readOnly />
            <TextArea className="mb-2 mt-2 flex w-40" defaultValue="Text m" size="m" readOnly />
          </div>
        </div>
      </div>
      <div>
        <h2>Typography</h2>
        <div className="flex">
          <div className="mr-6">
            <h1 className="h1">Heading 1</h1>
            <h2 className="h2">Heading 2</h2>
            <h3 className="h3">Heading 3</h3>
            <h4 className="h4">Heading 4</h4>
            <h5 className="h5">Heading 5</h5>
            <h6 className="h6">Heading 6</h6>
          </div>
          <div className="flex h-[300px] flex-col justify-around">
            <div className="body-xxs">Body XXS (body-xxs)</div>
            <div className="body-xs">Body XS (body-xs)</div>
            <div className="body-s">Body S (body-s)</div>
            <div className="body-s-bold">Body S Bold (body-s-bold)</div>
            <div className="body-s-italic">Body S Italic (body-s-italic)</div>
            <div className="body-m">Body M (body-m)</div>
            <div className="code">Code (code)</div>
          </div>
        </div>
      </div>
      <div className="w-[200px]">
        <h2>Avatars</h2>
        <div className="flex gap-3">
          <Avatar text="AK" />
          <Avatar className="bg-blue-tint-20 text-white" text="EK" />
        </div>
      </div>
      <div className="flex w-[200px] flex-col gap-2">
        <h2>Outcome Chips</h2>
        <OutcomeChip title="#my-outcome" />
        <OutcomeChip score={3} title="#my-outcome" />
        <OutcomeChip score={3} title="#my-outcome" selected />
        <OutcomeChip size="m" title="#my-outcome" />
        <OutcomeChip score={3} size="m" title="#my-outcome" />
        <OutcomeChip score={3} size="m" title="#my-outcome" selected />
      </div>
      <div className="w-[200px]">
        <h2>Score Icons</h2>
        <div className="flex w-full justify-between">
          <ScoreIcon className="my-2 mr-2" size="xs" value={0} />
          <ScoreIcon className="my-2 mr-2" size="xs" value={1} />
          <ScoreIcon className="my-2 mr-2" size="xs" value={2} />
          <ScoreIcon className="my-2 mr-2" size="xs" value={3} />
          <ScoreIcon className="my-2 mr-2" size="xs" value={4} />
          <ScoreIcon className="my-2 mr-2" size="xs" value={5} />
        </div>
        <div className="flex w-full justify-between">
          <ScoreIcon className="my-2 mr-2" value={0} />
          <ScoreIcon className="my-2 mr-2" value={1} />
          <ScoreIcon className="my-2 mr-2" value={2} />
          <ScoreIcon className="my-2 mr-2" value={3} />
          <ScoreIcon className="my-2 mr-2" value={4} />
          <ScoreIcon className="my-2 mr-2" value={5} />
        </div>
        <div className="flex w-full justify-between">
          <ScoreIcon className="my-2 mr-2" size="m" value={0} />
          <ScoreIcon className="my-2 mr-2" size="m" value={1} />
          <ScoreIcon className="my-2 mr-2" size="m" value={2} />
          <ScoreIcon className="my-2 mr-2" size="m" value={3} />
          <ScoreIcon className="my-2 mr-2" size="m" value={4} />
          <ScoreIcon className="my-2 mr-2" size="m" value={5} />
        </div>
      </div>
      <div
        className={clsx("w-[300px]", iconColored && "icon-red", iconInteractive && "interactive")}
      >
        <h2>Icons</h2>
        <div className="flex gap-3">
          <Button kind="secondary" onClick={() => setIconColored(!iconColored)}>
            Toggle Icon Color
          </Button>
          {iconColored && (
            <Button
              className="ml-2"
              kind="secondary"
              onClick={() => setIconInteractive(!iconInteractive)}
            >
              Toggle Icon Interactive
            </Button>
          )}
          <Button kind="secondary" onClick={() => setIconSize(iconSize === "m" ? "xs" : "m")}>
            Toggle Icon sizing
          </Button>
        </div>
        <div className="mt-5 flex w-full flex-wrap items-center justify-start gap-3">
          {Object.entries(allIcons).map(([key, Icon]) => (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Icon key={key} size={iconSize} title={key} />
          ))}
        </div>
        <h2>Colored Status Icons (icons with special props)</h2>
        <div className="mt-5 flex w-full flex-wrap justify-start gap-3">
          <PageStatusIcon color="gray" size={iconSize} />
          <PageStatusIcon color="orange" size={iconSize} />
          <PageStatusIcon color="green" size={iconSize} />
          <PageListStatusIcon color="gray" size={iconSize} />
          <PageListStatusIcon color="orange" size={iconSize} />
          <PageListStatusIcon color="green" size={iconSize} />
          <PageResourcePDFIcon color="gray" size={iconSize} />
          <PageResourcePDFIcon color="orange" size={iconSize} />
          <PageResourcePDFIcon color="green" size={iconSize} />
          <StatusDotIcon color="gray" size={iconSize} />
          <StatusDotIcon color="orange" size={iconSize} />
          <StatusDotIcon color="green" size={iconSize} />
        </div>
        <h2>Logos</h2>
        <div className="mt-5 flex w-full justify-start gap-1">
          <MinervaLogoIcon />
        </div>
        <div className="mt-5 flex w-full justify-start gap-1">
          <allIcons.ForumLogoIcon width={LOGO_HEADER_SIZE} />
        </div>
      </div>
      <div className="w-[300px]">
        <h2>Save Status Indicator</h2>
        <div className="flex flex-col gap-3">
          <ConnectionIcon iconState="connecting" />
          <ConnectionIcon iconState="connected" />
          <ConnectionIcon iconState="disconnected" />
        </div>
      </div>
      <div className="w-[660px]">
        <h2>Banners styles</h2>
        <div className="flex flex-col gap-4">
          {(["success", "info", "warning", "error"] as const).map((kind, index) => (
            <Banner key={kind} kind={kind} onAction={() => {}} onDismiss={() => {}}>
              This is a {kind} banner. {"text ".repeat(index)}
            </Banner>
          ))}
        </div>
        <h2>Banners as used in our app</h2>
        <div className="flex flex-col gap-4">
          <MismatchedVersionBanner onlySeeded={false} onDismiss={() => {}} />
          <MismatchedVersionBanner onlySeeded onDismiss={() => {}} />
          <PageReleasedBanner onDismiss={() => {}} />
          <GroupNotAssignedBanner />
          <StudentLockedPageBanner />
          <InstructorLockedPageBanner />
        </div>
      </div>
    </div>
  );
}
