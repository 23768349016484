import { FC, useEffect } from "react";

import {
  useAssessedCountData,
  useAssessmentMode,
  useAssessmentPublishActions,
} from "../../../../utils/assessment";
import { usePresentation } from "../../../../utils/presentation";

import { isGroupPage, isIndividualPage } from "components/materials/page/groups/helpers";
import { AssessmentStatus, PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { Banner, BannerProps } from "mds/components/Banner";
import { Button } from "mds/components/Button";
import { showConfirmationModal } from "mds/components/Modal";
import { AssessPointsMarkerIcon } from "mds/icons";
import { useAppDispatch, useAppSelector } from "store/index";
import {
  selectAssessmentsByPageId,
  selectCanAuthorCourse,
  selectIsAssessing,
  selectOfficialFullSubmissionsByPageId,
  selectShouldShowModalOnExitAssessmentMode,
  selectShowingEditAssessmentBanner,
  selectSubmissionsForPage,
} from "store/selectors";
import { selectAssessmentStatusForPage } from "store/selectors/computed";
import { viewStateActions } from "store/slices/view";
import { useListSelector } from "store/store-hooks";

const showExitAssessmentModeModal = (
  page: PageType,
  onStopAssessing: () => void,
  count: number,
  total: number,
) => {
  const labelText = t("assessment.assessment_in_progress_modal.description", {
    context: page.assessments_published_at ? "published" : "unpublished",
    count,
    total,
  });
  return showConfirmationModal({
    title: t("assessment.assessment_in_progress_modal.title"),
    labelText,
    confirmButtonText: t("assessment.resume_assessment"),
    cancelButtonText: t("assessment.finish_later"),
    onCancel: () => onStopAssessing(),
  });
};

type AssessmentBannerProps = {
  className?: string;
  page: PageType;
};

export const AssessmentBanner: FC<AssessmentBannerProps> = ({ className, page }) => {
  const dispatch = useAppDispatch();
  const submissions = useAppSelector((s) => selectSubmissionsForPage(s, page.id));
  const assessments = useAppSelector((s) => selectAssessmentsByPageId(s, page.id));
  const assessmentStatus = useAppSelector((s) => selectAssessmentStatusForPage(s, page));
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const isAssessing = useAppSelector(selectIsAssessing);
  const showingEditAssessmentBanner = useAppSelector(selectShowingEditAssessmentBanner);
  const { isPresentingUser, isFollowing, isPresenting, isPracticing } = usePresentation();
  const { startAssessing, stopAssessing } = useAssessmentMode(page);
  const { publishGrades } = useAssessmentPublishActions(page);
  const { count: officialSubmissionsCount, total: totalSubmissionsCount } =
    useAssessedCountData(page);

  const shouldShowModalOnExitAssessmentMode = useAppSelector(
    selectShouldShowModalOnExitAssessmentMode,
  );

  const officialSubmissions = useListSelector((s) =>
    selectOfficialFullSubmissionsByPageId(s, page?.id),
  );

  const viewingPresentation = isPresentingUser || isFollowing || isPresenting || isPracticing;
  const isAllAssessed = officialSubmissions.length === totalSubmissionsCount;

  const defaultBannerProps: BannerProps = {
    className: "page-block-width text-sm font-medium text-black-tint-10",
    Icon: page.assessments_published_at ? undefined : AssessPointsMarkerIcon,
    kind: "success",
    children: "",
  };
  const context = isGroupPage(page) ? "group" : "individual";

  const showAssessmentBanner =
    assessmentStatus !== AssessmentStatus.NOT_ASSESSABLE &&
    !viewingPresentation &&
    canAuthorCourse &&
    submissions.length !== 0 &&
    (isGroupPage(page) || isIndividualPage(page));

  // Banners that are shown when out of assessment mode
  const showSubmissionCollectedBanner =
    !isAssessing && !page.assessments_published_at && assessments.length === 0;
  const showAssessmentInProgressBanner =
    !isAssessing && !page.assessments_published_at && assessments.length !== 0;
  const showEditAssessmentBanner =
    !isAssessing && page.assessments_published_at && showingEditAssessmentBanner;
  const showViewAssessmentsBanner =
    !isAssessing && page.assessments_published_at && !showingEditAssessmentBanner;

  // Banners that are shown in assessment mode
  const showAssessmentProgressBanner = isAssessing && !page.assessments_published_at;
  const showAssessmentPublishedBanner = isAssessing && page.assessments_published_at;

  const onStopAssessing = () => {
    if (page.assessments_published_at) {
      dispatch(viewStateActions.showEditAssessmentBanner());
    }
    stopAssessing();
  };
  const onAssess = () => {
    dispatch(viewStateActions.hideEditAssessmentBanner());
    startAssessing();
  };

  const onPublishGrades = () => {
    publishGrades();
    stopAssessing();
  };

  useEffect(() => {
    dispatch(viewStateActions.hideEditAssessmentBanner());
  }, [page.id, dispatch]);

  const onExitAssessmentMode = shouldShowModalOnExitAssessmentMode
    ? () =>
        showExitAssessmentModeModal(
          page,
          onStopAssessing,
          officialSubmissionsCount,
          totalSubmissionsCount,
        )
    : onStopAssessing;

  if (!showAssessmentBanner) return null;

  if (showSubmissionCollectedBanner) {
    return (
      <div className={className}>
        <Banner
          {...defaultBannerProps}
          actionButtonSize="xs"
          actionButtonText={t("assessment.assess")}
          onAction={onAssess}
        >
          {t("assessment.submissions_collected", { context, count: totalSubmissionsCount })}
        </Banner>
      </div>
    );
  }

  if (showAssessmentInProgressBanner) {
    return (
      <div className={className}>
        <Banner
          {...defaultBannerProps}
          actionButtonSize="xs"
          actionButtonText={t("assessment.resume_assessment")}
          onAction={onAssess}
        >
          {t("assessment.assessment_in_progress", {
            count: officialSubmissionsCount,
            total: totalSubmissionsCount,
          })}
        </Banner>
      </div>
    );
  }

  if (showAssessmentProgressBanner) {
    return (
      <div className={className}>
        <Banner
          {...defaultBannerProps}
          buttonChildren={
            <>
              <Button
                className="border !border-green"
                kind="secondary"
                size="xs"
                onClick={onExitAssessmentMode}
              >
                {t("assessment.finish_later")}
              </Button>

              <Button
                className={
                  assessments.length === 0
                    ? "border !border-black-tint-93 !bg-black-tint-97"
                    : "border !border-green"
                }
                disabled={assessments.length === 0}
                kind="secondary"
                size="xs"
                onClick={onPublishGrades}
              >
                {t("assessment.publish_grades")}
              </Button>
            </>
          }
        >
          {t("assessment.assessment_progress", {
            count: officialSubmissionsCount,
            total: totalSubmissionsCount,
          })}
        </Banner>
      </div>
    );
  }

  if (showAssessmentPublishedBanner) {
    return (
      <div className={className}>
        <Banner
          {...defaultBannerProps}
          actionButtonClassName="border !border-green"
          actionButtonKind="secondary"
          actionButtonSize="xs"
          actionButtonText={t("assessment.stop_editing")}
          onAction={onExitAssessmentMode}
        >
          {t("assessment.assessments_published", {
            count: officialSubmissionsCount,
            total: totalSubmissionsCount,
          })}
        </Banner>
      </div>
    );
  }

  if (showViewAssessmentsBanner) {
    return (
      <div className={className}>
        <Banner
          {...defaultBannerProps}
          actionButtonClassName="border !border-green"
          actionButtonKind="secondary"
          actionButtonSize="xs"
          actionButtonText={t("assessment.view_assessments")}
          onAction={() => dispatch(viewStateActions.showEditAssessmentBanner())}
        >
          {t("assessment.assessments_published", {
            count: officialSubmissionsCount,
            total: totalSubmissionsCount,
          })}
        </Banner>
      </div>
    );
  }

  if (showEditAssessmentBanner) {
    return (
      <div className={className}>
        <Banner
          {...defaultBannerProps}
          actionButtonClassName="border !border-green"
          actionButtonKind="secondary"
          actionButtonSize="xs"
          actionButtonText={
            isAllAssessed ? t("assessment.edit_assessments") : t("assessment.resume_assessment")
          }
          onAction={onAssess}
        >
          {t("assessment.assessments_published", {
            count: officialSubmissionsCount,
            total: totalSubmissionsCount,
          })}
        </Banner>
      </div>
    );
  }

  return null;
};
