import { forwardRef } from "react";

import {
  PASSWORD_MIN_LENGTH,
  hasLowercase,
  hasNumber,
  hasUppercase,
  isMinLength,
} from "../../helpers/password-errors";

import { t } from "i18n/i18n";
import { Tooltip, TooltipProps, TooltipRefProps } from "mds/components/Tooltip";
import { useIsMdOrLarger } from "mds/hooks/use-responsive";
import { CircleCheckIcon, CircleSuccessGreenIcon } from "mds/icons";

export const PasswordRequirementTooltip = forwardRef<
  TooltipRefProps,
  TooltipProps & { isFieldFocused: boolean; password: string }
>(({ isFieldFocused, password, ...props }, ref) => {
  const isSmallScreen = !useIsMdOrLarger();
  const requirementMetIcon = (isReqMet: (password: string) => boolean) =>
    isReqMet(password) ? (
      <CircleSuccessGreenIcon aria-label={t("password_requirement.requirement_met")} />
    ) : (
      <CircleCheckIcon aria-label={t("password_requirement.requirement_unmet")} />
    );

  return (
    <Tooltip
      isOpen={isFieldFocused}
      wrapperClassName="inline-block"
      {...props}
      place={isSmallScreen ? "top" : "right"}
      ref={ref}
    >
      <div className="body-s-bold mb-1">{t("password_requirement.title")}</div>
      <ul className="body-s">
        <li className="flex flex-row-reverse items-center gap-1" key="one_number">
          <div className="flex-grow">{t("password_requirement.one_number")}</div>
          {requirementMetIcon(hasNumber)}
        </li>
        <li className="flex flex-row-reverse items-center gap-1" key="one_uppercase">
          <div className="flex-grow">{t("password_requirement.one_uppercase")}</div>
          {requirementMetIcon(hasUppercase)}
        </li>
        <li className="flex flex-row-reverse items-center gap-1" key="one_lowercase">
          <div className="flex-grow">{t("password_requirement.one_lowercase")}</div>
          {requirementMetIcon(hasLowercase)}
        </li>
        <li className="flex flex-row-reverse items-center gap-1" key="min_length">
          <div className="flex-grow">
            {t("password_requirement.min_length", { count: PASSWORD_MIN_LENGTH })}
          </div>
          {requirementMetIcon(isMinLength)}
        </li>
      </ul>
    </Tooltip>
  );
});

PasswordRequirementTooltip.displayName = "PasswordRequirementTooltip";
