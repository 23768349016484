import "./OrgSelect.scss";

import clsx from "clsx";
import { FC, useEffect } from "react";

import { switchOrg } from "api/api-server";
import { clearAIChatMessages } from "components/ai/storage";
import { OrgType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { ClickEvent, Menu, MenuItem } from "mds/components/Menu";
import { ChevronDownIcon } from "mds/icons";
import { storeApi, useAppSelector } from "store/index";
import { selectOrgs } from "store/selectors";
import { toastLocalizedOperationCatcher } from "utils/alerts";

/**
 * A dropdown list of orgs.
 */
// TODO: There's a lot of repeated code between this and CourseSelect. We should consider
//       refactoring this to be a more generic dropdown list component.
export const OrgSelect: FC = () => {
  const orgs = useAppSelector(selectOrgs);

  const currentOrg = orgs.find((org) => org.current === true);

  useEffect(() => {
    storeApi.orgs.list();
  }, []);

  if (orgs.length <= 0 || !currentOrg) {
    return null;
  }

  const MenuButton = (
    <Button className="org-select gap-1" kind="tertiary" size="s">
      {currentOrg?.name}
      {orgs.length > 1 && <ChevronDownIcon />}
    </Button>
  );

  const onSelectOrg = (org: OrgType) => {
    switchOrg(org.id)
      .then(() => {
        clearAIChatMessages();
        window.location.href = window.location.origin + window.location.pathname;
      })
      .catch(toastLocalizedOperationCatcher("switching_org"));
  };

  if (orgs.length === 1) {
    return <div>{MenuButton}</div>;
  }

  return (
    <div>
      <Menu menuButton={MenuButton} title={t("org.header.org_select")}>
        {orgs.map((org) => (
          <OrgMenuItem
            currentOrgId={currentOrg.id}
            key={org.id}
            org={org}
            onClick={() => onSelectOrg(org)}
          />
        ))}
      </Menu>
    </div>
  );
};

type OrgMenuItemProps = {
  org: OrgType;
  currentOrgId: string;
  onClick: (event: ClickEvent) => void;
};

const OrgMenuItem: FC<OrgMenuItemProps> = ({ org, currentOrgId, onClick }) => {
  const checked = org.id === currentOrgId;

  return (
    <MenuItem
      checked={checked}
      className={clsx("gap-1", { selected: checked })}
      value={org}
      onClick={onClick}
    >
      {org?.name}
    </MenuItem>
  );
};
