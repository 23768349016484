import "./Modal.scss";

import clsx from "clsx";
import type { MouseEvent, ReactNode } from "react";
import { forwardRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { createRoot } from "react-dom/client";

import { Button, type ButtonProps } from "./Button";
import { CloseButton } from "./CloseButton";

import { t } from "i18n/i18n";
import { trackEvent } from "utils/amplitude";

export type ModalProps<T> = {
  fullScreen?: boolean;
  children?:
    | ((
        onClose: (data?: T) => void | undefined,
        onConfirm: (data?: T) => void | undefined,
      ) => ReactNode)
    | ReactNode;
  className?: string;
  onConfirm?: (data?: T) => void;
  onCancel?: (data?: T) => void;
  onClose?: (data?: T) => void;
  hideCloseButton?: boolean;
  hideCancelConfirmButtons?: boolean;
  allowManualClose?: boolean;
  title?: ReactNode;
  labelText?: ReactNode;
  cancelButtonText?: ReactNode;
  confirmButtonText?: ReactNode;
  confirmButtonKind?: ButtonProps["kind"];
};

// eslint-disable-next-line react-refresh/only-export-components
export const showConfirmationModal = <T,>({
  onClose: customClose,
  onConfirm: customConfirm,
  onCancel: customCancel,
  allowManualClose = true,
  className,
  ...modalProps
}: ModalProps<T>) => {
  const container = document.createElement("div");
  document.body.appendChild(container);
  const root = createRoot(container);

  const confirmation = new Promise((resolve) => {
    const onConfirm = (data?: T) => {
      if (customConfirm) {
        customConfirm(data);
      }

      resolve(data || true);
    };

    const onClose = (data?: T) => {
      trackEvent("Close modal", { eventCategory: "Button press" });

      if (customClose) {
        customClose(data);
      }

      resolve(false);
    };

    const onCancel = customCancel
      ? (data?: T) => {
          trackEvent("Close modal", { eventCategory: "Button press" });

          if (customCancel) {
            customCancel(data);
          }

          resolve(false);
        }
      : onClose;

    root.render(
      <>
        <div className="modal-backdrop" onClick={allowManualClose ? () => onClose() : undefined} />
        <Modal
          allowManualClose={allowManualClose}
          className={clsx(className, "confirm-modal")}
          onCancel={onCancel}
          onClose={onClose}
          onConfirm={onConfirm}
          {...modalProps}
        />
      </>,
    );
  });

  return confirmation.finally(() => {
    root.unmount();
  });
};

const ignoreClick = (event: MouseEvent<HTMLDivElement>) => {
  event.stopPropagation();
};

// This is a very basic modal.  As we require more features, we can pull things from:
// https://github.com/minervaproject/picasso/blob/development/forum-design-system/src/components/modal.tsx

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Modal = forwardRef<HTMLDivElement, ModalProps<any>>(
  (
    {
      children,
      className,
      fullScreen,
      onClose,
      onConfirm,
      onCancel,
      allowManualClose = true,
      hideCloseButton = false,
      hideCancelConfirmButtons = false,
      cancelButtonText = t("common.cancel"),
      confirmButtonText = t("common.confirm"),
      confirmButtonKind = "primary",
      title,
      labelText,
    },
    ref,
  ) => {
    useEffect(() => {
      const closeOnEsc = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
          onClose();
        }
      };

      if (allowManualClose) {
        window.addEventListener("keydown", closeOnEsc);
      }

      return () => window.removeEventListener("keydown", closeOnEsc);
    }, [onClose, allowManualClose]);

    return createPortal(
      <div
        aria-modal="true"
        className={clsx(className, "modal", { "modal--full-screen": fullScreen })}
        ref={ref}
        role="dialog"
        onClick={ignoreClick}
      >
        {(title || !hideCloseButton) && (
          <div className="modal-header flex items-start justify-between">
            <div className="modal-title">{title}</div>
            {!hideCloseButton && <CloseButton onClick={onClose} />}
          </div>
        )}

        {labelText && <div className="modal-label">{labelText}</div>}

        <div className="modal-body">
          {typeof children === "function" ? children(onClose, onConfirm) : children}
        </div>

        {onConfirm && !hideCancelConfirmButtons && (
          <div className="modal-footer flex justify-end gap-2">
            <Button
              kind="secondary"
              title={typeof cancelButtonText === "string" ? cancelButtonText : undefined}
              onClick={onCancel}
            >
              {cancelButtonText}
            </Button>

            <Button
              kind={confirmButtonKind}
              title={typeof confirmButtonText === "string" ? confirmButtonText : undefined}
              onClick={onConfirm}
            >
              {confirmButtonText}
            </Button>
          </div>
        )}
      </div>,
      document.body,
    );
  },
);

Modal.displayName = "Modal";
