import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const InstructorNotesIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M8.3999 2V7.6L6.3999 6L4.3999 7.6V2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M2 12.8V3.6C2 3.17565 2.16857 2.76869 2.46863 2.46863C2.76869 2.16857 3.17565 2 3.6 2H12.4C12.8243 2 13.2313 2.16857 13.5314 2.46863C13.8314 2.76869 14 3.17565 14 3.6V12.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M12.8 11.6H3.2C2.53726 11.6 2 12.1372 2 12.8C2 13.4627 2.53726 14 3.2 14H12.8C13.4627 14 14 13.4627 14 12.8C14 12.1372 13.4627 11.6 12.8 11.6Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
