import clsx from "clsx";
import { FC, Ref, useEffect, useRef } from "react";

import { TermType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { IconText } from "mds/components/IconText";
import { ClickEvent, Menu, MenuInstance, MenuItem, MenuType, SubMenu } from "mds/components/Menu";
import { CalendarIcon, ChevronDownIcon } from "mds/icons";
import { storeApi, useAppSelector } from "store/index";
import { selectTerms } from "store/selectors";

export type TermMenuProps = {
  selectedTermId: string;
  onTermChange: (termId: string) => void;
  menuType?: MenuType;
};

export const TermMenu: FC<TermMenuProps> = ({
  selectedTermId,
  onTermChange,
  menuType = "submenu",
}) => {
  const subMenuRef: Ref<MenuInstance> = useRef(null);
  const terms = useAppSelector(selectTerms);
  const termOptions = [{ id: "", title: t("import_modal.select_term") }, ...terms];

  const selectedTerm = termOptions.find((term) => term.id === selectedTermId);

  useEffect(() => {
    storeApi.terms.list();
  }, []);

  const onItemClick = (e: ClickEvent) => {
    const newTerm = e.value as TermType;
    onTermChange(newTerm.id);
    // This is a supported feature of szhsin/react-menu which allows
    // the menu to stay open even if a menu item is clicked. We use this to keep parent menus open
    // eslint-disable-next-line no-param-reassign
    e.keepOpen = true;
    subMenuRef.current.closeMenu();
  };

  const menuButton = (
    <Button className="gap-1" kind="secondary" size="xs">
      <IconText iconStart={<CalendarIcon />} text={selectedTerm?.title} />
      <ChevronDownIcon />
    </Button>
  );

  const DropdownMenu = termOptions.map((termOption) => {
    const selected = selectedTerm?.id === termOption.id;
    return (
      <MenuItem
        checked={selected}
        className={clsx({ selected, "icon-white": selected }, "center dropdown-item")}
        key={termOption.id}
        value={termOption}
        onClick={onItemClick}
      >
        <IconText iconStart={<CalendarIcon />} text={termOption.title} />
      </MenuItem>
    );
  });

  const menuProps = {
    className: "dropdown",
    instanceRef: subMenuRef,
    children: DropdownMenu,
  };

  return menuType === "menu" ? (
    <Menu {...menuProps} direction="bottom" menuButton={menuButton} position="initial" />
  ) : (
    <SubMenu
      {...menuProps}
      direction="bottom"
      label={menuButton}
      openTrigger="clickOnly"
      position="initial"
    />
  );
};
