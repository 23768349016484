import clsx from "clsx";
import { FC, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { usePresentation } from "../../../utils/presentation";

import { SUBMISSION_ID_QUERY_PARAM } from "components/constants";
import { PageSecondarySidebar } from "components/materials/page/PageSecondarySidebar";
import { SubmissionMenu } from "components/materials/page/SubmissionMenu";
import { PageTabVariant } from "components/materials/page/helpers";
import { AddOutcomePlusButton } from "components/materials/page/outcomes/AddOutcomePlusButton";
import { PageOutcomeScore } from "components/outcomes/PageOutcomeScore";
import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { useIsMdOrLarger } from "mds/hooks/use-responsive";
import { TagIcon } from "mds/icons";
import { storeApi, useAppDispatch, useAppSelector } from "store/index";
import {
  selectAssessmentBySubmissionId,
  selectAssessmentsByPageId,
  selectCanAuthorCourse,
  selectFullSubmissionById,
  selectIsAssessing,
  selectIsEditModeEnabled,
  selectNestedPageOutcomes,
  selectOutcomeAssessmentByAssessmentId,
} from "store/selectors";
import { viewStateActions } from "store/slices/view";

type PageWorksheetHeaderProps = {
  page: PageType;
  selectedTabVariant: PageTabVariant;
  selectedAccessId: string;
};

export const PageWorksheetHeader: FC<PageWorksheetHeaderProps> = ({
  page,
  selectedTabVariant,
  selectedAccessId,
}) => {
  const [searchParams] = useSearchParams();
  const pageOutcomes = useAppSelector((s) => selectNestedPageOutcomes(s, page.id));
  const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);
  const isSmallScreen = !useIsMdOrLarger();

  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const isAssessing = useAppSelector(selectIsAssessing);

  const assessments = useAppSelector((s) => selectAssessmentsByPageId(s, page.id));

  const submissionId = searchParams.get(SUBMISSION_ID_QUERY_PARAM);
  const currentSubmission = useAppSelector((s) => selectFullSubmissionById(s, submissionId));
  const assessment =
    useAppSelector((s) => selectAssessmentBySubmissionId(s, currentSubmission?.id)) || null;
  const outcomeAssessments =
    useAppSelector((s) => selectOutcomeAssessmentByAssessmentId(s, assessment?.id)) || [];

  const { hasProjectorView } = usePresentation();

  const dispatch = useAppDispatch();
  const openOutcomesSidebar = useCallback(
    () => dispatch(viewStateActions.openOutcomesSidebar()),
    [dispatch],
  );
  const closeOutcomesSidebar = useCallback(
    () => dispatch(viewStateActions.closeOutcomesSidebar()),
    [dispatch],
  );
  const toggleOutcomesSidebar = useCallback(
    () => dispatch(viewStateActions.toggleOutcomesSidebar()),
    [dispatch],
  );

  const canSeeSubmissionMenu =
    selectedTabVariant === "my_submission" ||
    selectedTabVariant === "student_submission" ||
    (!hasProjectorView && selectedTabVariant === "responses");

  useEffect(() => {
    storeApi.page_outcomes.list({ page_id: page.id });
  }, [page.grouping_category, page.id]);

  useEffect(() => {
    if (currentSubmission) {
      storeApi.assessments.list({ submission_id: currentSubmission.id });
    }
    if (assessment) {
      storeApi.outcome_assessments.list({ assessment_id: assessment.id });
    }
  }, [currentSubmission, assessment]);

  useEffect(() => {
    closeOutcomesSidebar();
  }, [page, closeOutcomesSidebar]);

  return (
    <div className="mb-2 flex w-full max-w-[--page-default-max-width] flex-col gap-2">
      <div className="flex w-full flex-col flex-wrap gap-2">
        {!isAssessing && (pageOutcomes.length > 0 || canAuthorCourse) && (
          <div className={clsx(!isAssessing && "flex min-h-[32px] flex-wrap items-center gap-3")}>
            {pageOutcomes.map((pageOutcome) => (
              <PageOutcomeScore
                key={pageOutcome.id}
                outcomeAssessment={
                  page.assessments_published_at &&
                  outcomeAssessments.find((oa) => oa.page_outcome_id === pageOutcome.id)
                }
                pageOutcome={pageOutcome}
                size="s"
                onClick={openOutcomesSidebar}
              />
            ))}

            {canAuthorCourse &&
              assessments.length === 0 &&
              pageOutcomes.length < 3 &&
              isEditModeEnabled &&
              (pageOutcomes.length === 0 ? (
                <Button
                  className="h6 flex gap-1 text-black-tint-40"
                  kind="secondary"
                  size="xs"
                  onClick={toggleOutcomesSidebar}
                >
                  <TagIcon />
                  <span>{t("glossary.outcomes")}</span>
                </Button>
              ) : (
                <AddOutcomePlusButton isDisabled={assessments.length > 0} pageId={page.id} />
              ))}
          </div>
        )}

        {canSeeSubmissionMenu && (
          <SubmissionMenu
            page={page}
            selectedAccessId={selectedAccessId}
            showDraft={selectedTabVariant === "responses"}
          />
        )}
      </div>
      {isSmallScreen && <PageSecondarySidebar page={page} />}
    </div>
  );
};
