/**
 * This file defines shared configuration code that should be applied to both
 * Collaboration and Client ShareDB before creating/reading any documents.
 * OT Types are "operational transform" types, and define the structure of the granular
 * updates (OTs) that we apply to doc/worksheet data.
 */

import json0 from "@minervaproject/ot-json0";
import richText from "@minervaproject/rich-text";
import ShareDB from "sharedb";
import { Op } from "sharedb/lib/client";
// eslint-disable-next-line import/no-unresolved
import { Type } from "sharedb/lib/sharedb";

interface JSON0Type {
  type: Type;
}
interface RichTextType {
  type: Type;
}
export const jsonOTType: JSON0Type = json0 as JSON0Type;
const richTextType: RichTextType = richText as RichTextType;

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
jsonOTType.type.registerSubtype(richTextType.type);
ShareDB.types.register(jsonOTType.type);
// Client and server don't think this exists, but historically we've used it and it works
// eslint-disable-next-line
(ShareDB.types as any).defaultType = jsonOTType.type;

// Compatibility layer with main branch ShareDB presence API

// eslint-disable-next-line @typescript-eslint/unbound-method
const originalTransformPresence = jsonOTType.type.transformPresence;
jsonOTType.type.transformPresence = <T>(presence: T, op: Op, isOwnOp: boolean) =>
  presence ? originalTransformPresence(presence, op, isOwnOp) : presence;
