import "./MoveToSubMenu.scss";

import { FC, MouseEvent, useState } from "react";

import { CoursePageCategory, PageCategory, coursePageCategories } from "components/server-types";
import { t } from "i18n/i18n";
import { CancelSaveWidget } from "mds/components/CancelSaveWidget";
import { IconText } from "mds/components/IconText";
import { ListItem } from "mds/components/ListItem";
import { FolderIcon, PageListIcon } from "mds/icons";
import { selectTopicsForCurrentCourse } from "store/selectors";
import { useListSelector } from "store/store-hooks";

interface MoveActivityToSubMenuProps {
  itemKind: "activity";
  onCancel: () => void;
  onConfirm: (destinationTopicId: string) => void;
  currentTopicId: string;
}

interface MovePageToSubMenuProps {
  itemKind: "page";
  onCancel: () => void;
  onConfirm: (destinationCategory: PageCategory, destinationTopicId?: string) => void;
  currentPageCategory: PageCategory;
  currentTopicId?: string;
}

type MoveToSubMenuProps = MoveActivityToSubMenuProps | MovePageToSubMenuProps;

/** This component is used to move either a Page or Activity within the course.
 * - A Page can be moved another Topic or Category.
 * - An Activity can only be moved to another Topic.
 *
 * It does not allow moving the Page or Activity within the same Topic (which can be done via drag and drop). */
export const MoveToSubMenu: FC<MoveToSubMenuProps> = ({
  itemKind,
  onCancel,
  onConfirm,
  currentTopicId,
  ...props
}) => {
  // The selectedCategoryOrTopicId will either be a topic id or a category name (e.g. "assignment").
  const [selectedCategoryOrTopicId, setSelectedCategoryOrTopicID] = useState<string>();
  const topics = useListSelector(selectTopicsForCurrentCourse);

  let eligibleCourseCategories: CoursePageCategory[] = [];
  if (itemKind === "page") {
    const currentPageCategory = (props as MovePageToSubMenuProps).currentPageCategory;
    eligibleCourseCategories = coursePageCategories.filter(
      (category) => category !== currentPageCategory,
    );
  }

  const eligibleTopics = currentTopicId
    ? topics.filter((topic) => topic.id !== currentTopicId)
    : topics;

  const handleContainerClick = (event: MouseEvent, selectedValue: string) => {
    event.stopPropagation();
    event.preventDefault();

    setSelectedCategoryOrTopicID(selectedValue);
  };

  return (
    <div className="move-to-sub-menu mb-2 w-full bg-black-tint-99 p-3">
      <div className="body-s flex gap-1 pb-2">{t("move_to_sub_menu.title")}</div>

      <div className="mb-2 flex flex-col">
        {/* TODO: Fix color for icon when selected and hovered */}
        {eligibleCourseCategories.map((category) => (
          <ListItem
            className="w-full gap-1"
            isSelected={selectedCategoryOrTopicId === category}
            key={category}
            onClick={(e: MouseEvent) => handleContainerClick(e, category)}
          >
            <IconText
              iconStart={<FolderIcon />}
              key={category}
              text={t(`page_variant_plural.${category}`)}
            />
          </ListItem>
        ))}

        {eligibleTopics.map((topic) => (
          <ListItem
            className="w-full gap-1"
            isSelected={selectedCategoryOrTopicId === topic.id}
            key={topic.id}
            onClick={(e: MouseEvent) => handleContainerClick(e, topic.id)}
          >
            <IconText iconStart={<PageListIcon />} key={topic.id} text={topic.title} />
          </ListItem>
        ))}
      </div>

      <CancelSaveWidget
        className="right"
        isSaveDisabled={selectedCategoryOrTopicId === undefined}
        saveTextKey="move"
        onClickCancel={onCancel}
        onClickSave={() => {
          if (itemKind === "page") {
            // `selectedCategoryOrTopicId` has to be a topic id if it does not match any of the coursePageCategories.
            if (coursePageCategories.includes(selectedCategoryOrTopicId as CoursePageCategory)) {
              onConfirm(selectedCategoryOrTopicId as CoursePageCategory);
            } else {
              onConfirm("activity_page", selectedCategoryOrTopicId);
            }
          } else if (itemKind === "activity") {
            onConfirm(selectedCategoryOrTopicId);
          }

          setSelectedCategoryOrTopicID(undefined);
        }}
      />
    </div>
  );
};
