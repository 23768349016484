import { FC, useEffect } from "react";
import { useBoolean } from "usehooks-ts";

import { isGroupPage } from "components/materials/page/groups/helpers";
import {
  getLateSubmissionDueDate,
  isInLateSubmissionWindow,
  isLateSubmissionDueDateIndefinite,
} from "components/materials/page/helpers";
import { PageType } from "components/server-types";
import { monthDayAtTime } from "i18n/helpers";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { storeApi, useAppSelector } from "store/index";
import { selectCurrentCourseUser, selectPageGroupsByPage } from "store/selectors";

export interface SubmitLateButtonProps {
  page: PageType;
}

export const SubmitLateButton: FC<SubmitLateButtonProps> = ({ page }) => {
  const isWithinLateSubmissionPeriod = isInLateSubmissionWindow(page);

  // TODO: use date formatter for this
  const { month, day, time } = monthDayAtTime(
    isLateSubmissionDueDateIndefinite(page) ? "" : getLateSubmissionDueDate(page).toString(),
  );

  const { value: isEnabled, setTrue: enable, setFalse: disable } = useBoolean(true);

  const courseUser = useAppSelector(selectCurrentCourseUser);

  const pageGroups = useAppSelector((s) => selectPageGroupsByPage(s, page.id)) || [];
  const userPageGroup = pageGroups.find((pageGroup) =>
    pageGroup.page_group_users.find(
      (pageGroupUser) => pageGroupUser.course_user_id === courseUser?.id && pageGroupUser.is_ready,
    ),
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!isEnabled) {
      timeout = setTimeout(() => {
        enable();
      }, 5000); // Re-enable Submit Late button after 5 seconds.
    }
    return () => clearTimeout(timeout);
  }, [isEnabled, enable]);

  const options = isGroupPage(page)
    ? { page_group_id: userPageGroup?.id }
    : { course_user_id: courseUser?.id };

  const onSubmitLate = () => {
    disable();
    storeApi.submissions.create({ page_id: page.id, ...options });
  };

  // TODO: If the late due date passes, this will likely not update the disable state in real time for the student.
  return (
    <Button
      disabled={!isWithinLateSubmissionPeriod || !isEnabled}
      kind="primary"
      size="s"
      title={
        isWithinLateSubmissionPeriod
          ? t("page_worksheet_header.due_date_has_passed", {
              context: isLateSubmissionDueDateIndefinite(page)
                ? "indefinite_due_date"
                : "late_due_date",
              month,
              day,
              time,
            })
          : t("page_worksheet_header.late_due_date")
      }
      mobileFullWidth
      onClick={onSubmitLate}
    >
      {t("page_worksheet_header.submit_late")}
    </Button>
  );
};
