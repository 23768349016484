import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const GearSettingsIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <g clipPath="url(#clip0_2_5866)">
      <path
        className="stroke"
        d="M15 8C15 7.29682 14.4305 6.72727 13.7273 6.72727H12.9242C12.8039 6.26145 12.6206 5.82236 12.382 5.41764L12.9496 4.85C13.4466 4.353 13.4466 3.54736 12.9496 3.05036C12.4526 2.55336 11.647 2.55336 11.15 3.05036L10.5824 3.618C10.1776 3.37936 9.73855 3.19609 9.27273 3.07582V2.27273C9.27273 1.57018 8.70318 1 8 1C7.29682 1 6.72727 1.56955 6.72727 2.27273V3.07582C6.26145 3.19609 5.82236 3.37936 5.41764 3.618L4.85 3.05036C4.353 2.55336 3.54736 2.55336 3.05036 3.05036C2.55336 3.54736 2.55336 4.353 3.05036 4.85L3.618 5.41764C3.37936 5.82236 3.19609 6.26145 3.07582 6.72727H2.27273C1.57018 6.72727 1 7.29682 1 8C1 8.70318 1.56955 9.27273 2.27273 9.27273H3.07582C3.19609 9.73855 3.37936 10.1776 3.618 10.5824L3.05036 11.15C2.55336 11.647 2.55336 12.4526 3.05036 12.9496C3.54736 13.4466 4.353 13.4466 4.85 12.9496L5.41764 12.382C5.82173 12.6206 6.26145 12.8039 6.72727 12.9242V13.7273C6.72727 14.4298 7.29682 15 8 15C8.70318 15 9.27273 14.4305 9.27273 13.7273V12.9242C9.73855 12.8039 10.1776 12.6206 10.5824 12.382L11.15 12.9496C11.647 13.4466 12.4526 13.4466 12.9496 12.9496C13.4466 12.4526 13.4466 11.647 12.9496 11.15L12.382 10.5824C12.6206 10.1783 12.8039 9.73855 12.9242 9.27273H13.7273C14.4298 9.27273 15 8.70318 15 8Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M7.99991 9.90906C9.05427 9.90906 9.909 9.05433 9.909 7.99997C9.909 6.94561 9.05427 6.09088 7.99991 6.09088C6.94555 6.09088 6.09082 6.94561 6.09082 7.99997C6.09082 9.05433 6.94555 9.90906 7.99991 9.90906Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2_5866">
        <rect fill="white" height="16" width="16" />
      </clipPath>
    </defs>
  </svg>
);
