import clsx from "clsx";
import type { FC, MouseEvent } from "react";

import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { ChevronRightIcon, PresentPlayOutlineIcon } from "mds/icons";
import { FeatureStatus, featurePage } from "utils/presentation";

export type FeatureAllUsersBreadcrumbsProps = {
  featureStatus: FeatureStatus;
  classSessionId: string;
  featureBlockId?: string;
  showSingleQuestionStep: boolean;
  page: PageType;
};

export const FeatureAllUsersBreadcrumbs: FC<FeatureAllUsersBreadcrumbsProps> = ({
  featureStatus,
  classSessionId,
  featureBlockId,
  showSingleQuestionStep,
  page,
}) => {
  const showingSingleQuestion = featureStatus === FeatureStatus.ALL_RESPONSES_WITH_BLOCK;
  const showingSingleResponse = featureStatus === FeatureStatus.PARTICIPANT_RESPONSE_WITH_BLOCK;

  const onShowAllQuestions = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    featurePage(classSessionId, page, {
      blockId: null,
      accessId: null,
    });
  };

  const onShowSingleQuestions = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    featurePage(classSessionId, page, {
      blockId: featureBlockId,
      accessId: null,
    });
  };

  return (
    <div className="flex items-center gap-1 text-black-tint-20">
      <PresentPlayOutlineIcon className="icon-green mr-1" />

      <Button className="text-green-shade-20" kind="tertiary" onClick={onShowAllQuestions}>
        {t("presentation.featuring.all_responses")}
      </Button>

      {(showSingleQuestionStep || showingSingleQuestion) && (
        <>
          <ChevronRightIcon />

          <Button
            className={clsx(showingSingleQuestion ? "text-black-tint-20" : "text-green-shade-20")}
            disabled={showingSingleQuestion}
            kind="tertiary"
            onClick={onShowSingleQuestions}
          >
            {t("presentation.featuring.individual_questions")}
          </Button>
        </>
      )}

      {showingSingleResponse && (
        <>
          <ChevronRightIcon />

          {t("ns1:presentation.featuring.status.participant_response_with_block")}
        </>
      )}
    </div>
  );
};
