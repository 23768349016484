import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useIsLgOrLarger } from "mds/hooks/use-responsive";

export const useFindItemBySearchParamId = <T extends { id: string }>(
  items: T[],
  searchParam: string,
  shouldDefaultFirstEvenOnSmallScreen: boolean = false,
) => {
  const shouldDefaultFirst = useIsLgOrLarger() || shouldDefaultFirstEvenOnSmallScreen;

  const [searchParams, setSearchParams] = useSearchParams();
  const itemId = searchParams.get(searchParam);

  useEffect(() => {
    if (shouldDefaultFirst && itemId === null && items.length > 0 && items[0].id) {
      searchParams.set(searchParam, items[0].id);
      setSearchParams(searchParams);
    }
  }, [shouldDefaultFirst, itemId, items, searchParam, searchParams, setSearchParams]);

  if (items.length === 0) {
    return undefined;
  }

  return items.find((item) => item.id === itemId);
};
