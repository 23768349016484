import "./EmbedModal.scss";

import clsx from "clsx";
import { type FC, type MouseEvent, type SyntheticEvent, useState } from "react";

import type { OEmbedType } from "../../shared/types/oembed";
import type { OEmbedDataType } from "../../shared/types/worksheet";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { showConfirmationModal } from "mds/components/Modal";
import { TextField } from "mds/components/TextField";
import { CircleExclamationIcon } from "mds/icons";
import { fetchOEmbed } from "utils/oembed";

type OEmbedModalProps = {
  onClose?: () => void;
  onConfirm: (oEmbed: OEmbedDataType) => void;
  showEmbedPageLabel?: boolean;
  url?: string;
  invalid?: boolean;
};

// eslint-disable-next-line react-refresh/only-export-components
export const showOEmbedModal = (
  url: string,
  onComplete: (data: OEmbedDataType) => void,
  showEmbedPageLabel = false,
) => {
  return showConfirmationModal<OEmbedDataType>({
    className: "embed-modal",
    onConfirm: onComplete,
    hideCancelConfirmButtons: true,
    title: t("fields.embed.title"),
    confirmButtonText: t("fields.embed.embed_video"),
    children: (onClose, onConfirm) => (
      <OEmbedModal
        showEmbedPageLabel={showEmbedPageLabel}
        url={url}
        onClose={onClose}
        onConfirm={onConfirm}
      />
    ),
  });
};

export const OEmbedModal: FC<OEmbedModalProps> = ({
  onClose,
  onConfirm,
  url: initialUrl = "",
  showEmbedPageLabel = false,
  invalid = false,
}) => {
  const [url, setUrl] = useState(initialUrl);
  const [invalidUrl, setInvalidUrl] = useState(invalid);

  const labelKey = showEmbedPageLabel ? "fields.embed.embed_page_label" : "fields.embed.label";

  const onChangeUrl = (newUrl: string) => {
    setUrl(newUrl);
    setInvalidUrl(false);
  };

  const onSubmit = async (event: SyntheticEvent<HTMLFormElement> | MouseEvent) => {
    event.preventDefault();

    if (!url) {
      setInvalidUrl(true);
      return;
    }

    let oEmbedData: OEmbedType | undefined;
    try {
      oEmbedData = await fetchOEmbed(url);
    } catch (e) {
      console.error(e);
    }

    if (oEmbedData) {
      // This will close the modal
      onConfirm({ url, o: oEmbedData });
    }

    setInvalidUrl(true);
  };

  return (
    <form className="body-s flex flex-col items-start gap-2 text-black-tint-10" onSubmit={onSubmit}>
      {/* Need custom label here so we can connect it to the input field */}
      <label className="font-medium" htmlFor="url">
        {t(labelKey)}
      </label>

      <TextField
        className={clsx("border-black-tint-90", { "bg-orange-tint-90": invalidUrl })}
        id="url"
        placeholder="https://www.youtube.com/"
        readOnly={false}
        value={url}
        wrapperClassName="flex justify-center items-center grow"
        isEditing
        onChange={(e) => onChangeUrl(e.target.value)}
      />

      <div
        className={clsx("flex items-center gap-2 text-black-tint-20", {
          hidden: !invalidUrl,
        })}
      >
        <CircleExclamationIcon className="icon-orange" size="xs" />
        {t("fields.embed.invalid_url")}
      </div>

      <div className="modal-footer mt-6 flex w-full items-center justify-end gap-2">
        <Button kind="secondary" title={t("common.cancel")} onClick={onClose}>
          {t("common.cancel")}
        </Button>

        <Button
          disabled={url.length === 0 || invalidUrl}
          kind="primary"
          title={t("fields.embed.embed_video")}
          onClick={onSubmit}
        >
          {t("fields.embed.embed_video")}
        </Button>
      </div>
    </form>
  );
};
