import "./CourseAtRiskView.scss";

import { sortBy } from "lodash";
import { FC, useEffect, useState } from "react";
import { Trans } from "react-i18next";

import { AtRiskOptionsForm } from "./AtRiskOptionsForm";
import { UserAtRiskSection } from "./UserAtRiskSection";

import { serverGetAtRiskData } from "api/api-server";
import { LoadingScreen } from "components/LoadingScreen";
import {
  AtRiskLevel,
  AtRiskMeasurabilityReason,
  AtRiskOptions,
  AtRiskScore,
} from "components/server-types";
import { t } from "i18n/i18n";
import { AccordionTitle } from "mds/components/AccordionTitle";
import { Banner } from "mds/components/Banner";
import { IconText } from "mds/components/IconText";
import { ThreeBarsIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import {
  type UserWithCourseUserType,
  selectCurrentCourse,
  selectUsersInCurrentCourse,
} from "store/selectors";
import { useListSelector } from "store/store-hooks";
import { getUserLastNameSortKey } from "utils/user-utils";

const getAtRiskUsers = (
  activityUsers: UserWithCourseUserType[],
  atRiskScores: Record<string, AtRiskScore>,
) => {
  const highLevelUsers: UserWithCourseUserType[] = [];
  const mediumLevelUsers: UserWithCourseUserType[] = [];
  const lowLevelUsers: UserWithCourseUserType[] = [];

  const includesEffortScore = Object.values(atRiskScores).some((score) => score.effort_score);
  const includesPerfScore = Object.values(atRiskScores).some((score) => score.performance_score);

  if (!includesEffortScore && !includesPerfScore) {
    return { highLevelUsers, mediumLevelUsers, lowLevelUsers, hasScores: false };
  }

  const studentUsers = activityUsers.filter((user) => user.role === "student");

  sortBy<UserWithCourseUserType>(studentUsers, getUserLastNameSortKey).forEach((user) => {
    const score = atRiskScores[user.id];

    if (score) {
      if (
        (!score.effort_risk_level && includesEffortScore) ||
        (!score.performance_risk_level && includesPerfScore) ||
        score.effort_risk_level === AtRiskLevel.HIGH ||
        score.performance_risk_level === AtRiskLevel.HIGH
      ) {
        highLevelUsers.push(user);
      } else if (
        score.effort_risk_level === AtRiskLevel.MEDIUM ||
        score.performance_risk_level === AtRiskLevel.MEDIUM
      ) {
        mediumLevelUsers.push(user);
      } else {
        lowLevelUsers.push(user);
      }
    } else {
      highLevelUsers.push(user);
    }
  });

  return { highLevelUsers, mediumLevelUsers, lowLevelUsers, hasScores: true };
};

export const CourseAtRiskView: FC = () => {
  const currentCourse = useAppSelector(selectCurrentCourse);
  const [atRiskScores, setAtRiskScores] = useState<Record<string, AtRiskScore>>({});
  const [measurabilityReasons, setMeasurabilityReasons] = useState<AtRiskMeasurabilityReason[]>([]);

  const [options, setOptions] = useState<AtRiskOptions>({});

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const courseId = currentCourse.id;

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const { data: scores, reasons } = await serverGetAtRiskData(courseId, options);

        const scoreHash = scores.reduce(
          (acc, score) => {
            // eslinter should ignore reduce function
            // eslint-disable-next-line no-param-reassign
            acc[score.user_id] = score;
            return acc;
          },
          {} as Record<string, AtRiskScore>,
        );

        setMeasurabilityReasons(reasons);
        setAtRiskScores(scoreHash);
      } catch (err) {
        setError(err as Error);
      }

      setLoading(false);
    };

    getData();
  }, [courseId, options]);

  return (
    <div className="course-at-risk-view flex h-full w-full flex-wrap items-start justify-between overflow-y-auto">
      <div className="at-risk-main">
        <h3 className="mb-5">{t("course_at_risk_view.title")}</h3>
        {loading ? (
          <LoadingScreen text={t("common.loading")} />
        ) : error ? (
          <div>
            <h3 className="my-2">{t("course_at_risk_view.error_title")}</h3>
            <div className="body-s">
              {t("course_at_risk_view.error_body", { error_message: error?.message })}
            </div>
          </div>
        ) : (
          <CourseAtRiskPanel
            atRiskScores={atRiskScores}
            measurabilityReasons={measurabilityReasons}
          />
        )}
      </div>

      <AtRiskOptionsForm onSubmit={setOptions} />
    </div>
  );
};

type ListItemKeys =
  | "course_at_risk_view.disclaimer.low_score_statements"
  | "course_at_risk_view.disclaimer.low_effort_statements";

const mapTranslationsToListItems = (key: ListItemKeys) =>
  t(key, { returnObjects: true }).map((item) => <li key={item}>{item}</li>);

type CourseAtRiskPanelProps = {
  atRiskScores: Record<string, AtRiskScore>;
  measurabilityReasons: AtRiskMeasurabilityReason[];
};

const CourseAtRiskPanel: FC<CourseAtRiskPanelProps> = ({ atRiskScores, measurabilityReasons }) => {
  const users = useListSelector(selectUsersInCurrentCourse);

  const { hasScores, highLevelUsers, mediumLevelUsers, lowLevelUsers } = getAtRiskUsers(
    users,
    atRiskScores,
  );

  const [showReasons, setShowReasons] = useState(true);

  return (
    <div>
      {hasScores ? (
        <div className="mb-4 flex flex-wrap gap-3">
          <UserAtRiskSection level="low" scores={atRiskScores} users={lowLevelUsers} />

          <UserAtRiskSection level="medium" scores={atRiskScores} users={mediumLevelUsers} />

          <UserAtRiskSection level="high" scores={atRiskScores} users={highLevelUsers} />
        </div>
      ) : (
        <div className="mb-4 flex flex-col gap-4 rounded-lg bg-black-tint-97 p-4">
          <IconText
            className="icon-blue"
            iconStart={<ThreeBarsIcon />}
            text={t("course_at_risk_view.no_data_header")}
          />

          <div className="body-s">
            <Trans i18nKey="course_at_risk_view.no_data_body" t={t}>
              Track student engagement to better understand and support active learning. <br />
              Learn more about how metrics are calculated below.
            </Trans>
          </div>
        </div>
      )}

      {showReasons && measurabilityReasons.length > 0 && (
        <Banner
          className="at-risk-measurability-reasons"
          kind="warning"
          onDismiss={() => setShowReasons(false)}
        >
          <div className="body-xs flex flex-col">
            <h4 className="my-1 ml-1">{t("course_at_risk_view.measurability_reason_title")}</h4>
            <ul className="my-1 list-disc pl-6">
              {measurabilityReasons.map((reason) => (
                <li key={reason}>{t(`course_at_risk_view.measurability_reasons.${reason}`)}</li>
              ))}
            </ul>
          </div>
        </Banner>
      )}

      <div className="disclaimer body-s mt-6 flex flex-col gap-4 text-black-tint-40">
        <AccordionTitle
          iconDirection="end"
          title={t("course_at_risk_view.disclaimer.student_usage")}
          showBorder
        >
          <p className="mt-0">
            <Trans i18nKey="course_at_risk_view.disclaimer.intro" t={t}>
              We use student usage data to generate insights about course engagement.
              <p /> We use two types of data to determine engagement level: the first reflects the
              students <i>effort</i>, and the second reflects their
              <i>performance</i>. What follows is a non-technical summary of how we collect and
              process these data to make an overall determination of engagement level (high, medium,
              or low).
            </Trans>
          </p>

          <h4>{t("course_at_risk_view.disclaimer.sampling_title")}</h4>
          <p>{t("course_at_risk_view.disclaimer.sampling_body")}</p>

          <h4>{t("course_at_risk_view.disclaimer.measuring_title")}</h4>
          <p>
            <Trans i18nKey="course_at_risk_view.disclaimer.measuring_body" t={t}>
              To measure <i>effort</i>, we look at each students entries in Free Response blocks,
              Code blocks (if any), and Table cells. Specifically, we count the number of characters
              they typed in those fields. We dont examine the content itself, just the number of
              characters typed. We also count how many of the available Multiple Choice Questions
              the student answered. These are combined and compared to the class average. If a
              students measurement is 2 or more standard deviations below the average, that is
              recorded as low engagement with respect to effort. If the measurement is between 1 and
              2 standard deviations below the class average, its medium engagement. Otherwise, its
              high engagement.
              <p /> To measure <i>performance</i>, we look at outcome scores. If a students average
              score is greater than or equal to 2 (on the rubric scale), we categorize that as high
              engagement. If the average score is between 1 and 2, its medium engagement, and if
              between 0 and 1, its low engagement. The lower of the two—effort and performance—is
              recorded as the students overall engagement level.,
            </Trans>
          </p>
        </AccordionTitle>

        <AccordionTitle
          iconDirection="end"
          title={t("course_at_risk_view.disclaimer.best_practices")}
          showBorder
        >
          <h4 className="mt-0">{t("course_at_risk_view.disclaimer.low_score_title")}</h4>
          <ol>
            {mapTranslationsToListItems("course_at_risk_view.disclaimer.low_score_statements")}
          </ol>

          <h4>{t("course_at_risk_view.disclaimer.low_effort_title")}</h4>
          <ol>
            {mapTranslationsToListItems("course_at_risk_view.disclaimer.low_effort_statements")}
          </ol>
        </AccordionTitle>
      </div>
    </div>
  );
};
