import { CourseType } from "components/server-types";
import { storeApi, useAppSelector } from "store/index";
import {
  selectCanAuthorCourse,
  selectCurrentCourse,
  selectCurrentUserIsMPAdmin,
} from "store/selectors";

export const updateCourse = (course?: Partial<CourseType> & { id: string }) => {
  return storeApi.courses.partial_update(course);
};

export const deleteCourse = (courseId: string) => {
  storeApi.courses.destroy(courseId);
};

export const useUserCourseSettings = () => {
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const isMPAdmin = useAppSelector(selectCurrentUserIsMPAdmin);
  const course = useAppSelector(selectCurrentCourse);
  const canSeeAIPanel = canAuthorCourse || course.student_ai_assistant_enabled;

  return {
    canAuthorCourse,
    isMPAdmin,
    canSeeAIPanel,
  };
};
