import { OrgView } from "components/materials/org/OrgView";

const Page = () => {
  return (
    <div className="page">
      <OrgView />
    </div>
  );
};

export default Page;
