import { AxiosError } from "axios";
import clsx from "clsx";
import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import type { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import {
  groupAccessIdFromGroupId,
  studentAccessIdFromUserId,
} from "../../../../../../collaboration/src/shared/access-id";
import { useAssessmentActions } from "../../../../utils/assessment";

import { serverAssessSubmission } from "components/ai/assessment-query";
import { ACCESS_ID_QUERY_PARAM, SUBMISSION_ID_QUERY_PARAM } from "components/constants";
import { OutcomeAssessment } from "components/materials/page/assessment/OutcomeAssessment";
import { calculatePointsFromOutcomeScores } from "components/materials/page/helpers";
import { PointsConfig } from "components/materials/page/outcomes/PointsConfig";
import { PageType, SubmissionType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { BaseTextArea } from "mds/components/TextArea";
import { AIGeneratedSparkleIcon, LoadingDotsIcon } from "mds/icons";
import { storeApi, useAppDispatch, useAppSelector } from "store/index";
import {
  selectAssessmentBySubmissionId,
  selectCurrentCourseUser,
  selectCurrentOrg,
  selectFullSubmissionById,
  selectIsAssessWithAIModeEnabled,
  selectIsAssessing,
  selectNestedPageOutcomes,
  selectOutcomeAssessmentByAssessmentId,
  selectUserOfficialSubmissionForPage,
} from "store/selectors";
import { viewStateActions } from "store/slices/view";
import { toastErrorMessage } from "utils/alerts";
import { rollbarAndLogError } from "utils/logger";

interface AssessmentGradingProps {
  page: PageType;
  onIsEditingAssessment: (isEditingAssessment: boolean) => void;
  setShowAIGeneratedMsg?: (showAIGeneratedMsg: boolean) => void;
  isAIRunning?: boolean;
  setAIRunning?: (isAIRunning: boolean) => void;
  setShowLastPanel: (showLastPanel: boolean) => void;
  submissionForNextUser: SubmissionType;
  nextURL?: URL;
  nextId?: string;
}

type AssessmentFormInputType = {
  points: number;
  comment: string;
  page_outcomes: Record<string, number>;
  is_official: boolean;
};

type AssessmentFormErrors = AxiosError & {
  response: {
    data: {
      non_field_errors: string[];
    };
  };
};

export const AssessmentGradingForm: FC<AssessmentGradingProps> = ({
  page,
  onIsEditingAssessment,
  isAIRunning,
  submissionForNextUser,
  setShowAIGeneratedMsg,
  setAIRunning,
  setShowLastPanel,
  nextURL,
  nextId,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const org = useAppSelector(selectCurrentOrg);
  const isAssessing = useAppSelector(selectIsAssessing);
  const courseUser = useAppSelector(selectCurrentCourseUser);
  const isAssessWithAIModeEnabled = useAppSelector(selectIsAssessWithAIModeEnabled);
  const pageOutcomes = useAppSelector((s) => selectNestedPageOutcomes(s, page.id));
  const submissionId = searchParams.get(SUBMISSION_ID_QUERY_PARAM);
  const submission = useAppSelector((s) => selectFullSubmissionById(s, submissionId));
  const assessment = useAppSelector((s) => selectAssessmentBySubmissionId(s, submissionId)) || null;
  const outcomeAssessments = useAppSelector(
    (s) => selectOutcomeAssessmentByAssessmentId(s, assessment?.id) || [],
  );

  const selectedAccessId = submission
    ? submission.course_user_id
      ? studentAccessIdFromUserId(submission.course_user?.user_id)
      : groupAccessIdFromGroupId(submission.page_group_id)
    : undefined;

  const { createAssessment, updateAssessment, deleteAssessment } = useAssessmentActions();

  // Need to determine if there is an official submission for this user/group
  const officialSubmission = useAppSelector((s) =>
    selectUserOfficialSubmissionForPage(s, page, selectedAccessId),
  );

  const isOfficial = !!submission?.official_at;

  const defaultValues = useMemo(
    () => ({
      points: NaN,
      comment: "",
      page_outcomes: Object.fromEntries(pageOutcomes.map((po) => [po.id, NaN])),
      is_official: false,
    }),
    [pageOutcomes],
  );

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, isDirty },
    setError,
    clearErrors,
  } = useForm<AssessmentFormInputType>({
    defaultValues: assessment
      ? {
          points: assessment?.points,
          comment: assessment?.comment,
          page_outcomes: Object.fromEntries(
            outcomeAssessments.map((oa) => [oa.page_outcome_id, oa.score]),
          ),
          is_official: false,
        }
      : defaultValues,
  });

  const maxPoints = page?.max_points;
  const hasOutcomes = pageOutcomes.length > 0;
  const [isEditing, setIsEditing] = useState(false);
  const [aiAssessmentError, setAiAssessmentError] = useState<string | null>(null);
  const [showResetAssessment, setShowResetAssessment] = useState(false);
  const [hasAutomaticAssessWithAIRun, setHasAutomaticAssessWithAIRun] = useState(false);

  const scores = Object.values(watch("page_outcomes"));
  const points = watch("points");
  const comment = watch("comment");

  const isManuallyScoring = points
    ? calculatePointsFromOutcomeScores(scores, maxPoints, org.rubric) !== points
    : false;

  const hasPoints = maxPoints !== 0;
  const isEveryOutcomeScored = scores !== undefined && scores.some((os) => !isNaN(os));

  // The points configuration is only shown _after_ the user has selected an outcome score for every outcome.
  // TODO: It'd be nice to change this design in the future so that the config is only hidden when not
  //  applicable, not when outcome scores aren't selected.
  const showPoints = hasPoints && (isManuallyScoring || isEveryOutcomeScored);

  const formIsBlank = comment === "" && scores.every(isNaN) && !points;

  const showDelete = assessment?.id && formIsBlank && isEditing;
  const showUnofficialWarnings =
    !!officialSubmission && !isOfficial && isEditing && !showDelete && !formIsBlank;
  const isSaveEditOrDeleteButtonDisabled = isEditing && formIsBlank && !assessment?.id;
  const isDisabled = !isEditing || isAIRunning;

  const setScore = useCallback(
    (pageOutcomeId: string, score: number | null) => {
      setValue(`page_outcomes.${pageOutcomeId}`, score);
    },
    [setValue],
  );

  const onAssessWithAI = useCallback(async () => {
    setAIRunning(true);
    try {
      const result = await serverAssessSubmission(submission).catch((e: Error) => e);
      if (result instanceof Error) {
        console.error(result);
        setAiAssessmentError(t("assessment_card.ai.error"));
      } else if ("refused" in result) {
        setAiAssessmentError(t("assessment_card.ai.refused", { reason: result.refused }));
      } else {
        setValue("comment", result.feedback);
        result.outcomes.forEach(({ pageOutcomeId, score }) => {
          setScore(pageOutcomeId, score);
        });
        if (hasPoints) {
          const aiScores = result.outcomes.map(({ score }) => score);
          setValue("points", calculatePointsFromOutcomeScores(aiScores, maxPoints, org.rubric));
        }
        setShowResetAssessment(true);
        setShowAIGeneratedMsg(true);
      }
    } catch (e) {
      rollbarAndLogError("Error assessing with AI", e);
      setAiAssessmentError(t("assessment_card.ai.error"));
    } finally {
      setAIRunning(false);
    }
  }, [
    setValue,
    maxPoints,
    org.rubric,
    setScore,
    hasPoints,
    submission,
    setShowAIGeneratedMsg,
    setAIRunning,
  ]);

  const updatePoints = (newPoints?: number) => {
    if (!isNaN(newPoints)) {
      setValue("points", newPoints);
    } else if (!isManuallyScoring) {
      const updated_scores = Object.values(watch("page_outcomes"));
      setValue("points", calculatePointsFromOutcomeScores(updated_scores, maxPoints, org.rubric));
    }
  };

  const setScoreAndUpdatePoints = (pageOutcomeId: string, score: number | null) => {
    setScore(pageOutcomeId, score);
    updatePoints();
  };

  useEffect(() => {
    if (onIsEditingAssessment) {
      onIsEditingAssessment(!formIsBlank && isEditing && isDirty);
    }
  }, [onIsEditingAssessment, isEditing, formIsBlank, isDirty]);

  useEffect(() => {
    if (submission && !assessment) {
      reset(defaultValues);
      storeApi.assessments.list({ submission_id: submission.id });
    }
  }, [submission, assessment, defaultValues, reset]);

  useEffect(() => {
    if (assessment) {
      storeApi.outcome_assessments.list({ assessment_id: assessment.id });
      setValue("comment", assessment?.comment);
    }
    setIsEditing(!assessment);

    if (outcomeAssessments.length === pageOutcomes.length) {
      outcomeAssessments.forEach((oa) => {
        setScore(oa.page_outcome_id, oa.score);
      });
    } else {
      pageOutcomes.forEach((po) => {
        if (!outcomeAssessments.some((oa) => oa.page_outcome_id === po.id)) {
          setScore(po.id, NaN);
        }
      });
    }
    setValue("points", assessment?.points);
  }, [assessment, outcomeAssessments, pageOutcomes, setScore, setValue]);

  useEffect(() => {
    setShowAIGeneratedMsg(false);
    setHasAutomaticAssessWithAIRun(false);
    setShowResetAssessment(false);
  }, [submission, setHasAutomaticAssessWithAIRun, setShowAIGeneratedMsg]);

  useEffect(() => {
    if (
      formIsBlank &&
      isAssessing &&
      submission &&
      isEditing &&
      isAssessWithAIModeEnabled &&
      !hasAutomaticAssessWithAIRun
    ) {
      setHasAutomaticAssessWithAIRun(true);
      onAssessWithAI();
    }
  }, [
    formIsBlank,
    isAssessing,
    submission,
    isEditing,
    isAssessWithAIModeEnabled,
    onAssessWithAI,
    hasAutomaticAssessWithAIRun,
    setHasAutomaticAssessWithAIRun,
  ]);

  useEffect(() => {
    dispatch(
      viewStateActions.setShouldShowModalOnExitAssessmentMode(!formIsBlank && isEditing && isDirty),
    );
    return () => {
      dispatch(viewStateActions.setShouldShowModalOnExitAssessmentMode(false));
    };
  }, [isDirty, dispatch, formIsBlank, isEditing]);

  const onSubmit: SubmitHandler<AssessmentFormInputType> = async (params) => {
    if (!courseUser) {
      toastErrorMessage(t("assessment_card.cannot_assess_without_enrollment"));
      return;
    }

    try {
      const assessmentId = assessment?.id;
      if (assessmentId) {
        if (
          !params.comment &&
          !params.points &&
          Object.values(params.page_outcomes).every((v) => isNaN(v))
        ) {
          await deleteAssessment(assessmentId);
        } else {
          await updateAssessment(assessmentId, params, courseUser.id, submission.id);
          setIsEditing(false);
        }
      } else {
        await createAssessment(params, courseUser.id, submission.id);
        setIsEditing(false);
      }

      if (nextId) {
        searchParams.set(ACCESS_ID_QUERY_PARAM, nextId);
        searchParams.set(SUBMISSION_ID_QUERY_PARAM, submissionForNextUser?.id);
        setSearchParams(searchParams);
      }

      if (!page.assessments_published_at && !nextId) {
        setShowLastPanel(true);
      }
    } catch (error) {
      const axiosError = error as AssessmentFormErrors;
      setIsEditing(true);
      // TODO: better error handling
      setError("root", {
        type: "manual",
        message: (axiosError.response.data.non_field_errors ||
          axiosError?.response?.data[0]) as string,
      });
    }
  };

  const handleSaveAndNext = (data: AssessmentFormInputType) => {
    const params = data;
    params.is_official = true;
    onSubmit(params);
  };

  const saveDeleteOrEditButton = isEditing ? (
    <Button
      disabled={isSaveEditOrDeleteButtonDisabled || !courseUser}
      kind="primary"
      size="xs"
      title={!courseUser ? t("assessment_card.cannot_assess_without_enrollment") : undefined}
      onClick={handleSubmit(handleSaveAndNext)}
    >
      {showDelete
        ? t("common.delete")
        : nextId || !page.assessments_published_at
          ? t("common.save_and_next")
          : t("common.save")}
    </Button>
  ) : (
    <Button
      kind="primary"
      size="xs"
      type="button"
      onClick={(e: MouseEvent) => {
        e.preventDefault();
        setIsEditing(true);
      }}
    >
      {t("common.edit")}
    </Button>
  );

  const handleFormFocus = () => {
    clearErrors("root");
  };

  return (
    <form
      className={clsx(isAIRunning && "relative animate-pulse")}
      onFocus={handleFormFocus}
      onSubmit={handleSubmit(onSubmit)}
    >
      {isAIRunning && (
        <div className="absolute inset-0 flex items-center justify-center bg-white/50">
          <LoadingDotsIcon />
        </div>
      )}
      {hasOutcomes && (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            {pageOutcomes.map((pageOutcome) => (
              <OutcomeAssessment
                disabled={isDisabled}
                key={pageOutcome.id}
                pageOutcome={pageOutcome}
                register={register(`page_outcomes.${pageOutcome.id}`, {
                  valueAsNumber: true,
                  required: false,
                })}
                score={watch(`page_outcomes.${pageOutcome.id}`)}
                setScore={setScoreAndUpdatePoints}
              />
            ))}
          </div>

          {showPoints && (
            <div className="flex flex-col gap-1">
              <div className="flex items-center justify-between">
                <h3 className="mx-0 my-0 text-sm font-semibold text-black-tint-20">
                  {t("submission_assessment.total_points")}
                </h3>
                <PointsConfig
                  decimal={1}
                  disabled={isDisabled}
                  page={page}
                  saveTextKey="update"
                  studentPoints={watch("points")}
                  validatePoints={(currentPoints: number) =>
                    !isNaN(currentPoints) && currentPoints >= 0
                  }
                  onSave={(currentPoints) => {
                    updatePoints(currentPoints);
                  }}
                />
              </div>

              <div className="flex w-full justify-end">
                {isManuallyScoring && isEditing && (
                  <Button
                    kind="tertiary"
                    size="xs"
                    onClick={() => {
                      updatePoints(calculatePointsFromOutcomeScores(scores, maxPoints, org.rubric));
                    }}
                  >
                    {t("common.reset_points")}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <div className="mt-3 flex w-full flex-col gap-1">
        <h4 className="mx-0 my-0 text-sm font-semibold">
          {t("submission_assessment.instructor_comment")}
        </h4>

        <BaseTextArea
          {...register("comment", {
            disabled: isDisabled,
          })}
          aria-label="Comment"
          className="mb-1 text-black-tint-20"
          minRows={3}
          placeholder={t("submission_assessment.comment_placeholder")}
          size="s"
        />
      </div>
      {errors.root && <span className="body-s text-red">{errors.root.message}</span>}
      {aiAssessmentError && <span className="body-s text-red">{aiAssessmentError}</span>}
      {showUnofficialWarnings && (
        <div className="body-s italic text-black-tint-20">
          {t("assessment_card.official_assessment_explanation")}
        </div>
      )}
      <div className="mt-2 flex flex-row justify-end gap-1">
        {!page.assessments_published_at && !nextURL ? (
          <Button kind="secondary" size="xs" onClick={() => setShowLastPanel(true)}>
            {!isDisabled ? t("common.skip") : t("common.next")}
          </Button>
        ) : (
          <Button
            className={clsx(nextURL && "!text-black-tint-20")}
            disabled={!nextURL}
            kind="secondary"
            size="xs"
            to={nextURL ? nextURL.toString() : undefined}
          >
            {t("common.skip")}
          </Button>
        )}

        {showResetAssessment ? (
          <Button
            className={clsx(!isDisabled && "!text-black-tint-20")}
            disabled={isDisabled}
            kind="secondary"
            size="xs"
            onClick={() => {
              reset(defaultValues);
              storeApi.assessments.list({ submission_id: submission.id });
              setShowResetAssessment(false);
              setShowAIGeneratedMsg(false);
            }}
          >
            {t("assessment_card.ai.reset_assessment")}
          </Button>
        ) : (
          <Button
            className={clsx("flex gap-1", !isDisabled && "!text-black-tint-20")}
            disabled={isDisabled}
            kind="secondary"
            size="xs"
            onClick={onAssessWithAI}
          >
            {t("assessment_card.ai.assess_with_ai")}
            <AIGeneratedSparkleIcon isDisabled={isDisabled} />
          </Button>
        )}

        {saveDeleteOrEditButton}
      </div>
      {showUnofficialWarnings && (
        <div className="mt-2">
          <a className="body-s bold cursor-pointer text-blue" onClick={handleSubmit(onSubmit)}>
            {t("assessment_card.save_but_do_not_change_the_official_submission")}
          </a>
        </div>
      )}
    </form>
  );
};
