import { ReactNode } from "react";

import { CourseRoleType, OrgRoleType } from "components/server-types";
import { t } from "i18n/i18n";
import { Tooltip } from "mds/components/Tooltip";
import { PersonSingleIcon, StarFilledIcon, StarIcon } from "mds/icons";

export type ExtendedOrgRoleType = OrgRoleType | "superuser";

// eslint-disable-next-line react-refresh/only-export-components
export const ORG_ROLE_TO_ICON: Record<ExtendedOrgRoleType, ReactNode> = {
  superuser: <StarFilledIcon />,
  admin: <StarIcon />,
  other: <PersonSingleIcon />,
};

// eslint-disable-next-line react-refresh/only-export-components
export const COURSE_ROLE_TO_ICON: Record<CourseRoleType, ReactNode> = {
  admin: <StarFilledIcon />,
  instructor: <StarIcon />,
  student: <PersonSingleIcon />,
};

export const CourseRoleIconWithTooltip = ({
  role,
  extraContent = null,
}: {
  role: CourseRoleType;
  extraContent?: ReactNode;
}) => {
  const explanation = t(`roles.course_${role}_explanation`);
  return (
    <Tooltip element={COURSE_ROLE_TO_ICON[role]}>
      {explanation}

      {extraContent}
    </Tooltip>
  );
};

export const OrgRoleIconWithTooltip = ({
  role,
  extraContent = null,
}: {
  role: ExtendedOrgRoleType;
  extraContent?: ReactNode;
}) => {
  const explanation = t(`roles.org_${role}_explanation`);
  return (
    <Tooltip element={ORG_ROLE_TO_ICON[role]}>
      {explanation}
      {extraContent}
    </Tooltip>
  );
};
