import { useEffect } from "react";

import { OUTCOME_ID_QUERY_PARAM } from "components/constants";
import { OutcomesNavSidebar } from "components/materials/course/outcomes/OutcomesNavSidebar";
import { OutcomeInfo } from "components/outcomes/OutcomeInfo";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { useIsMdOrLarger } from "mds/hooks/use-responsive";
import { CrossRemoveIcon } from "mds/icons";
import { storeApi, useAppSelector } from "store/index";
import {
  selectCanAuthorCourse,
  selectCourseOutcomeRubric,
  selectCurrentCourse,
  selectFullCourseOutcomes,
} from "store/selectors";
import { useFindItemBySearchParamId } from "utils/hooks/use-find-item-by-search-param-id";
import { courseHomeUrl } from "utils/urls";

export const CourseOutcomesView = () => {
  const isSmallScreen = !useIsMdOrLarger();

  const course = useAppSelector(selectCurrentCourse);
  const courseOutcomes = useAppSelector((s) => selectFullCourseOutcomes(s, course.id));
  const outcomes = courseOutcomes.map((courseOutcome) => courseOutcome.outcome);

  const currentOutcome = useFindItemBySearchParamId(outcomes, OUTCOME_ID_QUERY_PARAM);
  const outcomeId = currentOutcome?.id;

  const currentCourseOutcome = courseOutcomes.find(
    (courseOutcome) => courseOutcome.outcome_id === outcomeId,
  );

  const rubric = useAppSelector((s) => selectCourseOutcomeRubric(s, currentCourseOutcome?.id));
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);

  useEffect(() => {
    storeApi.outcomes.list();
    storeApi.course_outcomes.list({ course_id: course.id });

    storeApi.orgs.list();
  }, [course.id]);

  return (
    <div className="flex h-full w-full overflow-y-auto">
      {!isSmallScreen && (
        <OutcomesNavSidebar courseId={course.id} selectedOutcome={currentOutcome?.title} />
      )}
      <div className="h-full w-full">
        {isSmallScreen && (
          // Mobile users need some way to get back to the navbar aside, browser-back doesn't work yet
          <Button
            aria-label={t("common.close")}
            className="ml-3 mt-3"
            kind="secondary"
            size="s"
            to={courseHomeUrl(course.id)}
            iconOnly
          >
            <CrossRemoveIcon />
          </Button>
        )}
        <OutcomeInfo
          outcomeItem={currentCourseOutcome}
          readOnly={!canAuthorCourse}
          rubric={rubric}
          isCourseLevel
        />
      </div>
      {outcomeId && !currentOutcome && <div className="page">{t("error.404_message")}</div>}
    </div>
  );
};
