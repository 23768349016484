import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "store";

export const createAppSelector = createSelector.withTypes<RootState>();

// This file only has the most basic selectors that do not have parameters, and do not filter
// or join objects.

// Single attribute selectors //

export const selectCurrentUserId = (state: RootState) => state.auth.user_id;
export const selectUserJWT = (state: RootState) => state.auth.collaboration_jwt;

// This needs to be in base.ts instead of local.ts to avoid circular dependencies
export const selectCurrentCourseId = (state: RootState) => state.view.currentCourseId;

// Pubsub Slice Selectors //
export const selectCourses = createAppSelector([(state) => state.courses], (courses) =>
  Object.values(courses),
);

export const selectCourseUsers = createAppSelector(
  [(state) => state.course_users],
  (course_users) => Object.values(course_users).filter((cu) => !cu.is_disabled),
);

export const selectAllCourseUsers = createAppSelector(
  [(state) => state.course_users],
  (course_users) => Object.values(course_users),
);

export const selectClassSessions = createAppSelector(
  [(state) => state.class_sessions],
  (class_sessions) => Object.values(class_sessions),
);

export const selectUsers = createAppSelector([(state) => state.users], (users) =>
  Object.values(users),
);

export const selectTopics = createAppSelector([(state) => state.topics], (topics) =>
  Object.values(topics),
);

export const selectActivities = createAppSelector([(state) => state.activities], (activities) =>
  Object.values(activities),
);

export const selectPages = createAppSelector([(state) => state.pages], (pages) =>
  Object.values(pages),
);

export const selectTerms = createAppSelector([(state) => state.terms], (terms) =>
  Object.values(terms),
);

export const selectCourseCodes = createAppSelector(
  [(state) => state.course_codes],
  (course_codes) => Object.values(course_codes),
);

export const selectOrgs = createAppSelector([(state) => state.orgs], (orgs) => Object.values(orgs));
export const selectOrgUsers = createAppSelector([(state) => state.org_users], (org_users) =>
  Object.values(org_users),
);

export const selectOutcomes = createAppSelector([(state) => state.outcomes], (outcomes) =>
  Object.values(outcomes),
);

export const selectOutcomeSubgroups = createAppSelector(
  [(state) => state.outcome_subgroups],
  (outcome_subgroups) => Object.values(outcome_subgroups),
);

export const selectOutcomeGroups = createAppSelector(
  [(state) => state.outcome_groups],
  (outcome_groups) => Object.values(outcome_groups),
);

export const selectCourseOutcomes = createAppSelector(
  [(state) => state.course_outcomes],
  (course_outcomes) => Object.values(course_outcomes),
);

export const selectPageOutcomes = createAppSelector(
  [(state) => state.page_outcomes],
  (page_outcomes) => Object.values(page_outcomes),
);

export const selectPageGroups = createAppSelector([(state) => state.page_groups], (page_groups) =>
  Object.values(page_groups),
);

export const selectPageGroupUsers = createAppSelector(
  [(state) => state.page_group_users],
  (page_group_users) => Object.values(page_group_users),
);

export const selectOutcomeAssessments = createAppSelector(
  [(state) => state.outcome_assessments],
  (outcome_assessments) => Object.values(outcome_assessments),
);

export const selectAssessments = createAppSelector([(state) => state.assessments], (assessments) =>
  Object.values(assessments),
);

export const selectSubmissions = createAppSelector([(state) => state.submissions], (submissions) =>
  Object.values(submissions),
);
