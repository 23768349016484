import { FC, useEffect, useRef } from "react";

import {
  VideoChangeMessage,
  WindowMessages,
  addProjectorMessageListener,
  removeProjectorMessageListener,
  sendMessageToProjectorView,
} from "components/materials/presentation/projector/projector-messaging";
import { usePresentation } from "utils/presentation";

type VideoAssetProps = {
  id: string;
  url: string;
  className: string;
  shouldSyncWithInstructorView?: boolean;
};

const VideoAsset: FC<VideoAssetProps> = ({
  id,
  url,
  className,
  shouldSyncWithInstructorView = false,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const { isPresenting, hasProjectorView } = usePresentation();
  const userIsProjecting = isPresenting && hasProjectorView;
  const muteAudio = userIsProjecting && !shouldSyncWithInstructorView;

  useEffect(() => {
    // If we are receiving messages from the instructor view,
    // we don't want to send messages
    if (shouldSyncWithInstructorView) {
      return;
    }

    const videoComponent = videoRef.current;

    const sendPlayChange = () => {
      if (userIsProjecting) {
        const message: VideoChangeMessage = {
          type: WindowMessages.VIDEO_CHANGE,
          id,
          currentTime: videoRef.current?.currentTime,
          playing: !videoRef.current?.paused,
        };

        sendMessageToProjectorView(message);
      }
    };

    const sendTimeChange = () => {
      if (userIsProjecting) {
        const message: VideoChangeMessage = {
          type: WindowMessages.VIDEO_CHANGE,
          id,
          currentTime: videoRef.current?.currentTime,
        };

        sendMessageToProjectorView(message);
      }
    };

    const sendRateChange = () => {
      if (userIsProjecting) {
        const message: VideoChangeMessage = {
          type: WindowMessages.VIDEO_CHANGE,
          id,
          playbackRate: videoRef.current?.playbackRate,
        };

        sendMessageToProjectorView(message);
      }
    };

    if (userIsProjecting && videoComponent) {
      videoComponent.addEventListener("play", sendPlayChange);
      videoComponent.addEventListener("pause", sendPlayChange);
      videoComponent.addEventListener("seeked", sendTimeChange);
      videoComponent.addEventListener("ratechange", sendRateChange);
    }

    return () => {
      videoComponent?.removeEventListener("play", sendPlayChange);
      videoComponent?.removeEventListener("pause", sendPlayChange);
      videoComponent?.removeEventListener("seeked", sendTimeChange);
      videoComponent?.removeEventListener("ratechange", sendRateChange);
    };
  }, [userIsProjecting, shouldSyncWithInstructorView, id]);

  useEffect(() => {
    const handleVideoChangeMessage = (message: MessageEvent<VideoChangeMessage>) => {
      if (
        message.data.type === WindowMessages.VIDEO_CHANGE &&
        videoRef.current &&
        shouldSyncWithInstructorView &&
        message.data.id === id
      ) {
        if (message.data.currentTime) {
          videoRef.current.currentTime = message.data.currentTime;
        }

        if (message.data.playbackRate) {
          videoRef.current.playbackRate = message.data.playbackRate;
        }

        if (message.data.playing && videoRef.current.paused) {
          videoRef.current.play();
        } else if (message.data.playing === false && !videoRef.current.paused) {
          videoRef.current.pause();
        }
      }
    };

    if (shouldSyncWithInstructorView) {
      addProjectorMessageListener(handleVideoChangeMessage);
    }

    return () => {
      removeProjectorMessageListener(handleVideoChangeMessage);
    };
  }, [shouldSyncWithInstructorView, id]);

  return <video className={className} muted={muteAudio} ref={videoRef} src={url} controls />;
};

// For Lazy loading it's easier to load a default component
// eslint-disable-next-line import/no-default-export
export default VideoAsset;
