import { FC } from "react";

import { IconColorProps, initializeIconColorProps } from "mds/utils/images";

export const PageListStatusIcon: FC<IconColorProps> = (props) => {
  const { fillClassName, ...svgProps } = initializeIconColorProps(props);
  return (
    <svg {...svgProps}>
      <path
        className="stroke"
        d="M6.79079 15H1.63623V3.54547H11.818V5.97578"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        className="stroke"
        d="M3.54541 1H14.3636V6.69798"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        className="stroke"
        d="M4.18164 6.72729H9.27255"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        className="stroke"
        d="M4.18164 9.27271H6.61741"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        className="stroke"
        d="M4.18164 11.8182H5.92407"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <rect className={fillClassName} height="6" rx="3" stroke="none" width="6" x="9" y="9" />
    </svg>
  );
};
