import { FC, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { CourseTitleBar } from "../CourseTitleBar";
import { CourseAssessmentStudentView } from "../assessment/CourseAssessmentStudentView";
import { CourseAssessmentView } from "../assessment/CourseAssessmentView";
import { useFetchAndSelectMyAssessments } from "../assessment/assessment-data";
import { CourseAtRiskView } from "../at-risk/CourseAtRiskView";

import { AIDashboardView } from "components/ai/dashboard/AIDashboardView";
import { CourseView } from "components/materials/course/content/CourseView";
import { CourseOutcomesView } from "components/materials/course/outcomes/CourseOutcomesView";
import { t } from "i18n/i18n";
import { IconText } from "mds/components/IconText";
import { TabBar } from "mds/components/TabBar";
import { useIsMdOrLarger } from "mds/hooks/use-responsive";
import {
  AssessPointsMarkerIcon,
  BookCourseIcon,
  OutcomeHashtagIcon,
  RobotAIIcon,
  ThreeBarsIcon,
} from "mds/icons";
import { useAppSelector } from "store/index";
import {
  selectCurrentCourse,
  selectCurrentCourseRole,
  selectCurrentUserIsMPAdmin,
  selectIsStudentPreview,
} from "store/selectors";
import { trackEvent } from "utils/amplitude";
import { useFooterSpacingIfMobileBanner } from "utils/hooks/use-footer-spacing-if-mobile-banner";
import { courseHomeUrl, withQueryParams } from "utils/urls";

const CourseContentTabKeys = [
  "content",
  "outcomes",
  "assessments",
  "engagement",
  "ai_dashboard",
] as const;

export const CourseContentView: FC = () => {
  const course = useAppSelector(selectCurrentCourse);
  const myAssessments = useFetchAndSelectMyAssessments();
  const isStudentRole = useAppSelector(selectCurrentCourseRole) === "student";
  const isStudentPreview = useAppSelector(selectIsStudentPreview);
  const isMpAdmin = useAppSelector(selectCurrentUserIsMPAdmin);
  const isSmallScreen = !useIsMdOrLarger();
  const [searchParams] = useSearchParams();

  // TODO: Should we move query-param based tab indexing into the TabBar component
  // as generic feature? It would be more accessible generally. Most uses of TabBar
  // would convert easily, but PageOverview might require some deeper changes.
  // If we do this, we should also render the tab items as anchor tabs to be fully accessible.
  const activeTab =
    (searchParams.get("tab") as (typeof CourseContentTabKeys)[number] | null) ??
    CourseContentTabKeys[0];

  const onTabChange = (index: number) => {
    const newTabKey = CourseContentTabKeys[index];
    const eventName = `Course homepage - View ${t(`glossary.${newTabKey}`, { lng: "en" })}`;
    trackEvent(eventName, { eventCategory: "Button press" });
    // Actual navigation happens implicitly via the query param change. We use the anchor tag (`to`)
    // pattern with the tabs here, as we've previously had issues where programmatically changing the
    // query params via setSearchParams sometimes loaded the wrong path, especially when recently having
    // switched path or course.
  };

  const tabs = useMemo(
    () =>
      [
        {
          key: CourseContentTabKeys[0],
          to: withQueryParams(courseHomeUrl(course?.id), { tab: CourseContentTabKeys[0] }),
          label: <IconText iconStart={<BookCourseIcon />} text={t("glossary.content")} />,
          title: t("glossary.content"),
          panel: <CourseView />,
        },
        {
          key: CourseContentTabKeys[1],
          to: withQueryParams(courseHomeUrl(course?.id), { tab: CourseContentTabKeys[1] }),
          label: <IconText iconStart={<OutcomeHashtagIcon />} text={t("glossary.outcomes")} />,
          title: t("glossary.outcomes"),
          panel: <CourseOutcomesView />,
        },
        {
          key: CourseContentTabKeys[2],
          to: withQueryParams(courseHomeUrl(course?.id), { tab: CourseContentTabKeys[2] }),
          label: (
            <IconText iconStart={<AssessPointsMarkerIcon />} text={t("glossary.assessments")} />
          ),
          title: t("glossary.assessments"),
          panel:
            !isStudentRole && !isStudentPreview ? (
              <CourseAssessmentView />
            ) : myAssessments && myAssessments.length > 0 ? (
              <CourseAssessmentStudentView />
            ) : null,
        },
        {
          key: CourseContentTabKeys[3],
          to: withQueryParams(courseHomeUrl(course?.id), { tab: CourseContentTabKeys[3] }),
          label: <IconText iconStart={<ThreeBarsIcon />} text={t("glossary.engagement")} />,
          title: t("glossary.engagement"),
          panel: isMpAdmin ? <CourseAtRiskView /> : null,
        },
        {
          key: CourseContentTabKeys[4],
          to: withQueryParams(courseHomeUrl(course?.id), { tab: CourseContentTabKeys[4] }),
          label: <IconText iconStart={<RobotAIIcon />} text={t("glossary.ai_dashboard")} />,
          title: t("glossary.ai_dashboard"),
          panel: isMpAdmin ? <AIDashboardView /> : null,
        },
      ].filter((tab) => tab.panel),
    [myAssessments, isStudentPreview, isStudentRole, isMpAdmin, course?.id],
  );

  const tabIndex = useMemo(() => tabs.map((tab) => tab.key).indexOf(activeTab), [activeTab, tabs]);
  const { footerPadding } = useFooterSpacingIfMobileBanner();

  return (
    <main className="page flex h-full w-full justify-center bg-white">
      <div className="w-full max-w-[--page-default-max-width]">
        <CourseTitleBar course={course} />
        <TabBar
          className="w-full"
          contentOnly={isSmallScreen}
          selectedIndex={tabIndex}
          tabs={tabs}
          onChange={onTabChange}
        />

        {footerPadding && <div className={footerPadding} />}
      </div>
    </main>
  );
};
