// Downloads a json blob as a file by
// creating an anchor element and clicking it.
export const downloadJsonFile = (data: JSON, fileName: string) => {
  const blob = new Blob([JSON.stringify(data)], { type: "application/json" });

  const anchorElement = document.createElement("a");
  anchorElement.download = fileName;
  anchorElement.href = window.URL.createObjectURL(blob);

  anchorElement.click();

  anchorElement.remove();
};
