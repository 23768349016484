import { createAppSelector } from "./base";

import { RootState } from "store";

export const selectCourseVersion = (state: RootState) => state.view.courseVersion;

export const selectWebsocketUserEmail = (state: RootState) => state.auth.websocket?.email;
export const selectWebsocketCourseId = (state: RootState) => state.auth.websocket?.course_id;

export const selectIsCourseVersionOutOfSync = (state: RootState) =>
  state.view.isCourseVersionOutOfSync;

export const selectAssetModalIsOpen = (state: RootState) => state.view.assetModalIsOpen;
export const selectIsOutcomesSidebarOpen = (state: RootState) => state.view.isOutcomesSidebarOpen;

export const selectIsStudentPreview = (state: RootState) => state.view.isStudentPreview;
export const selectIsTimeline = (state: RootState) => state.view.isTimeline;

export const selectIsAssessing = (state: RootState) => state.view.isAssessing;
export const selectShowingEditAssessmentBanner = (state: RootState) =>
  state.view.showingEditAssessmentBanner;
export const selectShouldShowModalOnExitAssessmentMode = (state: RootState) =>
  state.view.shouldShowModalOnExitAssessmentMode;

export const selectSelectedPageTabVariant = (state: RootState) => state.view.selectedPageTabVariant;

export const selectShareDBOpStatus = createAppSelector(
  [
    (state) => state.view.lastOpAt,
    (state) => state.view.lastOpSavedAt,
    (state) => state.view.opError,
  ],
  (lastOpAt, lastOpSavedAt, opError) => ({
    lastOpAt,
    lastOpSavedAt,
    opError,
  }),
);
