import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const OutcomeHashtagIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M4.6665 14.6666L6.6665 1.33331"
      strokeLinecap="square"
      strokeMiterlimit="10"
    />
    <path
      className="stroke"
      d="M9.33301 14.6666L11.333 1.33331"
      strokeLinecap="square"
      strokeMiterlimit="10"
    />
    <path
      className="stroke"
      d="M3.33301 5.33331H13.333"
      strokeLinecap="square"
      strokeMiterlimit="10"
    />
    <path
      className="stroke"
      d="M2.6665 10.6666H12.6665"
      strokeLinecap="square"
      strokeMiterlimit="10"
    />
  </svg>
);
