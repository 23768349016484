import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const CircleInfoIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M7.99951 7.66663V11.6666"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="fill"
      d="M7.99967 5.66665C8.36786 5.66665 8.66634 5.36817 8.66634 4.99998C8.66634 4.63179 8.36786 4.33331 7.99967 4.33331C7.63148 4.33331 7.33301 4.63179 7.33301 4.99998C7.33301 5.36817 7.63148 5.66665 7.99967 5.66665Z"
    />
  </svg>
);
