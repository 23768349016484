import { i18n } from "i18n/i18n";

export const DEFAULT_SHORT_DATE_FORMAT = "MMMM dd, yyyy";
export const DAY_AND_TIME_FORMAT = "MMM dd, h:mm a";

export const monthDayFormat = (date: Date) => ({
  val: date,
  formatParams: {
    val: { day: "numeric", month: "short" },
  },
});

export const monthDayAtTime = (dateStr: string) => {
  if (!dateStr) return {};

  const date = new Date(dateStr);

  const localizedDate = new Intl.DateTimeFormat(i18n.language, {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const dateParts = localizedDate.formatToParts(date);

  const month = dateParts.find((part) => part.type === "month")?.value;
  const day = dateParts.find((part) => part.type === "day")?.value;
  const hour = dateParts.find((part) => part.type === "hour")?.value;
  const minute = dateParts.find((part) => part.type === "minute")?.value;
  const dayPeriod = dateParts.find((part) => part.type === "dayPeriod")?.value;
  const time = `${hour}:${minute} ${dayPeriod}`;

  return { month, day, time };
};

export const weekdayMonthDayFormat = (date: Date) => ({
  val: date,
  formatParams: {
    val: { weekday: "short", month: "short", day: "numeric" },
  },
});

export const weekdayMonthDayAtTimeFormat = (date: Date) => ({
  val: date,
  formatParams: {
    val: { weekday: "short", month: "short", day: "numeric", hour: "numeric", minute: "numeric" },
  },
});
