import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const CrossRemoveIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M4.46436 4.46478L11.5354 11.5359"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      className="stroke"
      d="M11.5359 4.46445L4.46484 11.5355"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
  </svg>
);
