import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const PresentPlayIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <g clipPath="url(#clip0_1003_121092)">
      <g clipPath="url(#clip1_1003_121092)">
        <path
          className="stroke fill"
          d="M6.5 4.5L11.5 8L6.5 11.5V4.5Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="stroke"
          d="M14 15.5H2C1.60218 15.5 1.22064 15.342 0.93934 15.0607C0.658035 14.7794 0.5 14.3978 0.5 14V2C0.5 1.60218 0.658035 1.22064 0.93934 0.93934C1.22064 0.658035 1.60218 0.5 2 0.5H14C14.3978 0.5 14.7794 0.658035 15.0607 0.93934C15.342 1.22064 15.5 1.60218 15.5 2V14C15.5 14.3978 15.342 14.7794 15.0607 15.0607C14.7794 15.342 14.3978 15.5 14 15.5Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1003_121092">
        <rect fill="white" height="16" width="16" />
      </clipPath>
      <clipPath id="clip1_1003_121092">
        <rect fill="white" height="16" width="16" />
      </clipPath>
    </defs>
  </svg>
);
