import clsx from "clsx";
import { FC, MouseEvent } from "react";

import { useAssessmentMode } from "../../../../../utils/assessment";
import { usePresentation } from "../../../../../utils/presentation";

import { AssessmentStatus, PageType } from "components/server-types";
import { t } from "i18n/i18n";

export type AssessmentStatusChipProps = {
  page: PageType;
  assessmentStatus?: AssessmentStatus;
};

export const AssessmentStatusChip: FC<AssessmentStatusChipProps> = ({ page, assessmentStatus }) => {
  const { startAssessing } = useAssessmentMode(page);
  const { isPresentingUser, isFollowing, isPracticing, isPresenting } = usePresentation();

  // Shouldn't happen but we want to be safe
  if (assessmentStatus === AssessmentStatus.NOT_ASSESSABLE) {
    return null;
  }

  const viewingPresentation = isPresentingUser || isFollowing || isPresenting || isPracticing;
  const canAssess = !viewingPresentation;

  let extraClasses = "cursor-default text-black-tint-20 bg-black-tint-97";
  let role = "";
  let onClick: (e: MouseEvent) => void = () => {};
  if (assessmentStatus === AssessmentStatus.ASSESSABLE) {
    if (canAssess) {
      // Make the div button look green and clickable.
      extraClasses = "cursor-pointer bg-green-tint-90 text-green-shade-40 border-white";
    } else {
      // Make the div button look disabled (greyed out).
      extraClasses = "cursor-default !bg-black-tint-90 !text-black-tint-55 border-white";
    }

    role = "button";
    onClick = (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      startAssessing();
    };
  }

  return (
    <div
      className={clsx(
        "list-item-chip flex items-center justify-center gap-1 rounded-md border border-white px-2 py-1 text-xs font-medium",
        extraClasses,
      )}
      role={role}
      onClick={canAssess ? onClick : undefined}
    >
      <span>{t(`assessment_status.${assessmentStatus}`)}</span>
    </div>
  );
};
