import { FC } from "react";

import { PagesCard } from "./detail/PagesCard";

import { t } from "i18n/i18n";
import { Card } from "mds/components/Card";
import { TextAddButton } from "mds/components/TextAddButton";
import { useAppSelector } from "store/index";
import { type ActivityWithPagesType, selectCanAuthorCourse } from "store/selectors";
import { usePageActions } from "utils/page";

type PrepWorkActivityCardProps = {
  prepWorkActivity: ActivityWithPagesType;
  activities: ActivityWithPagesType[];
};

/** This component is for displaying the prep work activity for a topic.
 *  There should always be 1 prep work activity per topic, and this activity
 *  cannot be renamed or deleted. There is no time estimate for a prep work activity.
 */
export const PrepWorkActivityCard: FC<PrepWorkActivityCardProps> = ({
  prepWorkActivity,
  activities,
}) => {
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const pages = prepWorkActivity?.pages || [];

  const { createPage } = usePageActions();

  const createNewPage = () => {
    createPage("activity_page", pages, prepWorkActivity.id);
  };

  return (
    <>
      <PagesCard
        activities={activities}
        activity_id={prepWorkActivity.id}
        canManagePages={canAuthorCourse}
        header={t("topic_sidebar.prep_work")}
        pages={pages}
      />

      {canAuthorCourse && pages.length === 0 && (
        <Card className="mb-3 mt-2">
          <div className="h4 flex items-center justify-start">
            <TextAddButton hideUnlessEditMode underlined onClick={createNewPage} />
          </div>
        </Card>
      )}
    </>
  );
};
