import type { FC } from "react";
import { useSearchParams } from "react-router-dom";

import { getColoringFromPresentingStatus } from "../page/helpers";

import { FollowButton } from "./FollowButton";
import { MovePresentationButton } from "./MovePresentationButton";
import { PresentationControlMenu } from "./PresentationControlMenu";
import { ProjectingButton } from "./ProjectingButton";
import { StopPresentingButton } from "./StopPresentingButton";
import { FeatureStatusOptions } from "./featuring/FeatureStatusOptions";
import { openProjectorView } from "./projector/projector-messaging";

import { ACCESS_ID_QUERY_PARAM, PREVIEW_QUERY_PARAM } from "components/constants";
import { PagePresentingMenu } from "components/materials/presentation/PagePresentingMenu";
import { PageType } from "components/server-types";
import { useAppSelector } from "store/index";
import { selectCanAuthorCourse, selectCurrentUserId } from "store/selectors";
import { trackEvent } from "utils/amplitude";
import {
  presentSession,
  setPresentationWindow,
  stopPresenting,
  usePresentation,
} from "utils/presentation";
import { CourseViewVariantType } from "utils/urls";

type PresentOptions = {
  isRollup?: boolean;
  hasProjectorView?: boolean;
  isPractice?: boolean;
  accessId?: string;
};

export type PresentationOptionsProps = {
  selectedPage: PageType;
  courseViewVariant: CourseViewVariantType;
};

export const PresentationOptions: FC<PresentationOptionsProps> = ({
  courseViewVariant,
  selectedPage,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    presentedSessionId,
    presentedPageId,
    isFollowing,
    isPresenting,
    isPresentingUser,
    isPractice,
    isPracticing,
    unfeaturedTab,
    courseId,
  } = usePresentation();

  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const userId = useAppSelector(selectCurrentUserId);
  const pageView = ["topic", "course_page"].includes(courseViewVariant);
  const selectedAccessId = searchParams.get(ACCESS_ID_QUERY_PARAM);
  const pageCategory = searchParams.get("category");

  const onPresent = (options: PresentOptions = {}) => {
    setPresentationWindow();
    presentSession(presentedSessionId, { page: selectedPage, ...options, userId });
  };

  const onStopPresenting = () => {
    trackEvent(isPracticing ? "Presentation - End practice" : "Presentation - End", {
      eventCategory: "Button press",
    });
    stopPresenting(presentedSessionId).then(() => {
      searchParams.delete(PREVIEW_QUERY_PARAM);
      setSearchParams(searchParams);
    });
  };

  const onProjecting = () => {
    trackEvent("Presentation - Open projector view", { eventCategory: "Button press" });
    openProjectorView(courseId);
    onPresent({ hasProjectorView: true, isPractice: isPracticing });
  };

  if (!presentedPageId) {
    if (pageView && canAuthorCourse && pageCategory !== "instructor_workspace") {
      return (
        <PagePresentingMenu
          size="xs"
          onPractice={() => {
            trackEvent("Presentation Menu - Practice", { eventCategory: "Button press" });
            onPresent({ isPractice: true });
          }}
          onPresent={() => {
            trackEvent("Presentation Menu - Start", { eventCategory: "Button press" });
            onPresent();
          }}
        />
      );
    }

    return null;
  }

  if (isPresenting || isPracticing) {
    const coloring = getColoringFromPresentingStatus(unfeaturedTab, isPracticing);

    return (
      <>
        <FeatureStatusOptions
          onPresentRollup={() => {
            trackEvent("Feature all responses", { eventCategory: "Button press" });
            onPresent({ accessId: selectedAccessId, isRollup: true, isPractice: isPracticing });
          }}
        />

        <StopPresentingButton {...coloring} onStopPresenting={onStopPresenting} />

        {!unfeaturedTab && <ProjectingButton {...coloring} onProject={onProjecting} />}
      </>
    );
  }

  // Not presenting at all, or at least not presenting in this tab given the presentations
  // are tied to a specific tab.
  return (
    <>
      <PresentationControlMenu courseViewVariant={courseViewVariant} />

      {!isFollowing && !isPresentingUser && <FollowButton pageId={presentedPageId} />}

      {isPresentingUser && pageView && (
        <MovePresentationButton
          onClick={() => {
            trackEvent("Presentation - Move presentation here", { eventCategory: "Button press" });
            onPresent({ accessId: null, isRollup: false, isPractice });
          }}
        />
      )}

      {isPresentingUser && (
        <StopPresentingButton
          {...getColoringFromPresentingStatus(true, false)}
          onStopPresenting={onStopPresenting}
        />
      )}
    </>
  );
};
