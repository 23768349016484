import "./CourseUsersSidebarPanel.scss";

import type { FC } from "react";

import { CourseUserCard } from "./CourseUserCard";

import type { CourseRoleType } from "components/server-types";
import { t } from "i18n/i18n";
import { PersonJoinedIcon } from "mds/icons";
import { CourseUserActivityStatus, type UserWithCourseActivity } from "store/selectors";

interface CourseRoleCardProps {
  courseRole: CourseRoleType;
  users: UserWithCourseActivity[];
  className?: string;
  canModifyUsers?: boolean;
  showEmpty?: boolean;
}

export const CourseRoleCard: FC<CourseRoleCardProps> = ({
  courseRole,
  users,
  className,
  canModifyUsers = false,
  showEmpty = false,
}) => {
  const usersInRole = users.filter((user) => user.role === courseRole && !user.is_disabled);
  const onlineUsers = usersInRole.filter(
    (user) => user.courseActivityStatus !== CourseUserActivityStatus.OFFLINE,
  );

  return (
    <CourseUserCard
      canModifyUsers={canModifyUsers}
      className={className}
      filterUsers={(allUsers) =>
        allUsers.filter((user) => user.role === courseRole && !user.is_disabled)
      }
      headerCount={`${onlineUsers.length}/${usersInRole.length}`}
      headerIcon={<PersonJoinedIcon color="gray" />}
      headerTitle={t(`roles.course_${courseRole}_plural`)}
      showEmpty={showEmpty}
      users={users}
    />
  );
};
