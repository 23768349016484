import OEmbedProviders from "oembed-providers";

import type { OEmbedType } from "../worksheets/shared/types/oembed";

import { isValidUrl } from "./urls";

/**
 * This looks up the oEmbed provider for a given URL, using the static list of providers.
 * @param url URL to check for an OEmbed provider.
 * @returns provider URL, or undefined;
 */
export function getOEmbedProviderUrl(url: string) {
  for (const provider of OEmbedProviders) {
    const foundEndpoint = provider.endpoints.find((endpoint) =>
      endpoint.schemes?.some((scheme) => {
        const schemeRegex = new RegExp(scheme.replace(/\*/g, "[^ ]*"));
        return schemeRegex.test(url);
      }),
    );

    if (foundEndpoint) {
      return foundEndpoint.url.replace("{format}", "json");
    }
  }
}

/**
 * Attempt to fetch oEmbed data for a given URL. This function has no error handling.
 * @param url
 * @returns
 */
export async function fetchOEmbed(url: string, searchParams?: URLSearchParams) {
  // verify that the URL is valid if not return undefined
  if (!isValidUrl(url)) {
    return undefined;
  }

  // Does this provider support oEmbed?
  const oembedUrl = getOEmbedProviderUrl(url);
  if (!oembedUrl) {
    return undefined;
  }

  if (!searchParams) {
    // eslint-disable-next-line no-param-reassign
    searchParams = new URLSearchParams({ format: "json" });
  }

  searchParams.set("url", url);

  // Fetch oEmbed data
  const resp = await fetch(`${oembedUrl}?${searchParams.toString()}`);
  return resp.json() as Promise<OEmbedType>;
}

// It would be best to use oEmbed Discovery to look up if a given URL supports oEmbed.
// However, because of the same-origin policy, we can't do that in the browser.
// If we want to use a proxy server to make this work, here's how we could do it:
//
// export async function getOEmbedURL(url: string | URL) {
//   const resp = await fetch(url);
//   const parser = new DOMParser();
//   const htmlDoc = parser.parseFromString(await resp.text(), "text/html");
//   const oembedLinks = htmlDoc.querySelectorAll("link[type='application/json+oembed']");
//   if (oembedLinks.length > 0) {
//     return oembedLinks[0].getAttribute("href");
//   }
//   return null;
// }
