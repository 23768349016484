import "./Blocks.scss";

import clsx from "clsx";
import { get } from "lodash";
import { type FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import RenderIfVisible from "react-render-if-visible";
import type { Doc } from "sharedb/lib/client";

import {
  BLOCKS_PATH,
  BLOCKS_WITH_BUILT_IN_TOOLBAR,
  MAX_BLOCK_COUNT,
  isQuestionBlock,
} from "../../../../collaboration/src/shared/constants";
import { BlockMoveButtons } from "../components/Block/BlockMoveButtons";
import { BlockToolbar } from "../components/Block/BlockToolbar";
import { Field } from "../components/Field";
import { deleteBlockFromWorksheet } from "../shared/doc-helpers";
import type {
  BlockType,
  ChoiceFieldType,
  CodeFieldType,
  FieldType,
  FileFieldType,
  PathType,
  WorksheetType,
} from "../shared/types/worksheet";

import { ErrorBoundary } from "./ErrorBoundary";

import { HoverableToolbar } from "components/hover-widgets/HoverableToolbar";
import { FeatureButton } from "components/materials/presentation/featuring/FeatureButton";
import { t } from "i18n/i18n";
import { useHoverable } from "mds/hooks/use-hoverable";
import { useIsMdOrLarger } from "mds/hooks/use-responsive";
import { DocViewContext } from "providers/DocViewProvider";
import { toastLocalizedOperationError } from "utils/alerts";
import { FeatureStatus, usePresentation } from "utils/presentation";
import { AddElement } from "worksheets/components/AddElement";
import {
  CodeCellTypeSwitchDropdown,
  MultipleSwitchDropdown,
} from "worksheets/components/Field/AuthoringFields";
import { BlockResponsesHeader } from "worksheets/components/Field/BlockResponsesHeader";

// Estimated are based on empty fields
const ESTIMATED_AVG_FIELD_HEIGHT_PX_MAP: Record<FieldType["t"], number> = {
  Text: 32,
  RichTextField: 130,
  ChoiceField: 90,
  FileField: 46,
  TableField: 160,
  EmbedField: 32,
  CodeField: 90,
  // AssetField doesn't exist empty. Estimating roughly for a big image or PDF.
  AssetField: 400,
};

const FALLBACK_FIELD_HEIGHT_PX = 200;

interface BlockProps {
  accessIdPath: PathType;
  doc: Doc<WorksheetType>;
  blockPath: PathType;
  isFirstBlock: boolean;
  isLastBlock: boolean;
  blockIndex: number;
  onMove: (changeAmount: 1 | -1) => void;
  onDelete: () => void;
}

// This is to ensure the student and instructor's have the same
// vertical spacing between blocks, regardless of whether an add element is present.
const VerticalSpacerIfNoAdd = () => <div className="h-[46px]" />;

const Block: FC<BlockProps> = ({
  accessIdPath,
  blockPath,
  doc,
  onMove,
  onDelete,
  blockIndex,
  isFirstBlock,
  isLastBlock,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { canAuthorPage, isAuthoringPage, accessId, pageId, isProjecting, showResponses } =
    useContext(DocViewContext);
  const { isPresenting, featureStatus, presentedBlockId, isFollowing } = usePresentation();
  const isMobile = !useIsMdOrLarger();
  const block = get(doc.data, blockPath) as BlockType;
  const notFeaturing = featureStatus === FeatureStatus.NONE;
  const blockIsFeatured = block?.id === presentedBlockId;

  const showAddBlock =
    !presentedBlockId &&
    ((!isFollowing && !isPresenting && !isProjecting) || notFeaturing || !isFirstBlock);

  // Need to keep track of this as hiding it after it was featured makes closing the modal
  // act like it was closed manually
  const [wasFeatured, setWasFeatured] = useState<boolean>(false);
  const canFeature =
    isPresenting &&
    !isProjecting &&
    [FeatureStatus.ALL_RESPONSES, FeatureStatus.NONE, FeatureStatus.PARTICIPANT_RESPONSE].includes(
      featureStatus,
    ) &&
    block.t !== "Asset";

  const featureButton = canFeature ? (
    <FeatureButton
      accessId={accessId}
      blockId={block.id}
      isRollup={Boolean(showResponses)}
      pageId={pageId}
    />
  ) : null;

  useEffect(() => {
    if (blockIsFeatured) {
      setWasFeatured(true);
    }
  }, [blockIsFeatured]);

  const blockHover = useHoverable();

  const canModifyBlocks = isAuthoringPage && !showResponses && !isMobile && notFeaturing;
  const showBlockToolbarOptions =
    canModifyBlocks && !BLOCKS_WITH_BUILT_IN_TOOLBAR.includes(block.t);

  // Note: If the amount of fields changes, this will not be updated, but
  // we currently only support a fixed set of fields based on block type, so it's fine.
  const fieldPaths = useMemo(
    () => block.f?.map((bc, index) => [...blockPath, "f", String(index)]),
    [block.f, blockPath],
  );

  const hasSwitchForMultiple = block.t === "MultipleChoice" || block.t === "File";
  // By convention, all blocks only have at most one collaborative field, and if there is one, then it's
  // the latter of max two fields. This _might_ change, see `DEFAULT_BLOCKS` in `constants.ts`.
  const collaborativeField = block.f[block.f.length - 1];
  const isCodeBlock = block.t === "Code";
  const interactiveFieldIndex = block.f.findIndex((f) => f === collaborativeField);
  const interactiveFieldPath = fieldPaths[interactiveFieldIndex];

  return (
    <div
      className={clsx("extended-page-block-width relative", `block-${block.t}`)}
      // Used for block marker scrolling.  Position for div must not be static.
      id={`block-${block.id}`}
      ref={ref}
      {...blockHover.hoverParentProps}
    >
      {showAddBlock &&
        (canModifyBlocks ? (
          <AddElement doc={doc} insertAt={blockIndex} />
        ) : (
          <VerticalSpacerIfNoAdd />
        ))}
      {/* The Add Block or Upload modal sometimes blocks part of the Block element (e.g. Add Link), so we add the `z-[1]` class (z-index 1). */}
      <div className="relative z-[1]">
        {canModifyBlocks && (
          <span className="body-s mb-2 mt-0 text-black-tint-40">
            <BlockMoveButtons
              className={clsx(!blockHover.isHovering && "visible-on-focus")}
              reachedBottom={isLastBlock}
              reachedTop={isFirstBlock}
              onMoveDown={() => onMove(1)}
              onMoveUp={() => onMove(-1)}
            />
          </span>
        )}

        {canAuthorPage && (
          <BlockToolbar
            className={clsx(!blockHover.isHovering && "visible-on-focus")}
            onDelete={showBlockToolbarOptions ? onDelete : undefined}
          >
            {showBlockToolbarOptions && hasSwitchForMultiple && (
              <MultipleSwitchDropdown
                {...(collaborativeField as ChoiceFieldType | FileFieldType)}
                doc={doc}
                path={interactiveFieldPath}
              />
            )}

            {showBlockToolbarOptions && isCodeBlock && (
              <CodeCellTypeSwitchDropdown
                {...(collaborativeField as CodeFieldType)}
                doc={doc}
                path={interactiveFieldPath}
              />
            )}
          </BlockToolbar>
        )}

        <HoverableToolbar contentClassName="mt-1 mr-3" uncentered>
          <div className="w-full">
            {showResponses === "ALL" && <BlockResponsesHeader blockType={block.t} />}

            {block.f.map((field: FieldType, index: number) => {
              return (
                <ErrorBoundary key={field.id} minimal>
                  {wasFeatured ? (
                    <Field
                      accessIdPath={accessIdPath}
                      blockId={block.id}
                      doc={doc}
                      fieldPath={fieldPaths[index]}
                    />
                  ) : (
                    <RenderIfVisible
                      defaultHeight={
                        ESTIMATED_AVG_FIELD_HEIGHT_PX_MAP[field.t] || FALLBACK_FIELD_HEIGHT_PX
                      }
                      stayRendered
                    >
                      <Field
                        accessIdPath={accessIdPath}
                        blockId={block.id}
                        doc={doc}
                        fieldPath={fieldPaths[index]}
                      />
                    </RenderIfVisible>
                  )}
                </ErrorBoundary>
              );
            })}
          </div>

          {featureButton}
        </HoverableToolbar>

        {isCodeBlock && showResponses && !accessId && !isProjecting && notFeaturing && (
          <div className="body-s pt-4 text-black-tint-40">
            {t("fields.code.not_showing_outputs_in_all_responses")}
          </div>
        )}
      </div>
    </div>
  );
};

interface BlocksProps {
  accessIdPath: PathType;
  doc: Doc<WorksheetType>;
}

export const Blocks: FC<BlocksProps> = ({ doc, accessIdPath }) => {
  const blocks = doc.data[BLOCKS_PATH];
  const { canAuthorPage, isAuthoringPage, isFeaturing, showResponses } = useContext(DocViewContext);
  const isMobile = !useIsMdOrLarger();
  const { presentedBlockId } = usePresentation();

  const blockIdHash = blocks.map((block) => block.id).join(".");
  const blockPaths = useMemo(
    () => blocks.map((block, blockIndex) => [BLOCKS_PATH, String(blockIndex)]),
    // TODO: We don't properly react to changes in `blocks`, so we use an additional hash
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [blocks, blockIdHash],
  );

  // If an instructor is viewing a page without any blocks, always show the
  // large version of the `<AddElement />` component. We specifically do
  // *not* check for Edit Mode to be enabled (which means we have to use
  // `canAuthorPage` instead of `isAuthoringPage`), because the component
  // is smart enough to disable the "Add Block" button while Edit Mode
  // is off.
  //
  // This is similar to the `canModifyBlocks` boolean below, except that
  // `canModifyBlocks` also requires Edit Mode (via `isAuthoringPage`).
  if (blocks.length === 0 && canAuthorPage && !showResponses && !isMobile) {
    return (
      <AddElement
        className="last-element page-block-width"
        doc={doc}
        insertAt={MAX_BLOCK_COUNT}
        isLarge
      />
    );
  }

  if (blocks.length === 0 && !canAuthorPage) {
    return <div className="">{t("page_detail_view.worksheet_is_empty")}</div>;
  }

  const canModifyBlocks = isAuthoringPage && !showResponses && !isMobile && !isFeaturing;

  const onDelete = (blockIndex: number) => {
    deleteBlockFromWorksheet(doc, blockIndex);
  };

  const onMove = (blockIndex: number, changeAmount: 1 | -1) => {
    if (
      (blockIndex <= 0 && changeAmount < 0) ||
      (blockIndex >= blocks.length - 1 && changeAmount > 0)
    ) {
      return toastLocalizedOperationError("cannot_move_block");
    }
    doc.submitOp({
      p: [BLOCKS_PATH, blockIndex],
      lm: blockIndex + changeAmount,
    });
  };

  const filteredBlocks =
    isFeaturing && presentedBlockId
      ? // if we're featuring a specific block, only show that block
        [blocks.find((block) => block.id === presentedBlockId)]
      : showResponses
        ? // if we're showing responses, we don't show instructions-only blocks, just blocks that can have answers
          blocks.filter((block) => isQuestionBlock(block))
        : // otherwise, show all blocks
          blocks;

  return (
    <ErrorBoundary>
      {filteredBlocks.map((block, idx) => {
        const blockIndex = blocks.indexOf(block);
        const isFirstBlock = blockIndex === 0;
        const isLastBlock = blockIndex === blocks.length - 1;

        return (
          <div
            className={clsx(
              "block-component flex w-full flex-col items-center text-black-tint-20",
              {
                "last-element": !canModifyBlocks && blocks.length - 1 === idx,
              },
            )}
            key={block.id}
          >
            <Block
              accessIdPath={accessIdPath}
              blockIndex={blockIndex}
              blockPath={blockPaths[blockIndex]}
              doc={doc}
              isFirstBlock={isFirstBlock}
              isLastBlock={isLastBlock}
              key={block.id}
              onDelete={() => onDelete(blockIndex)}
              onMove={(changeAmount) => onMove(blockIndex, changeAmount)}
            />
          </div>
        );
      })}

      {canModifyBlocks && (
        <AddElement
          className="last-element page-block-width"
          doc={doc}
          insertAt={MAX_BLOCK_COUNT}
        />
      )}
    </ErrorBoundary>
  );
};
