// Re-export the components from react-menu in case we want to modify them later
// without changing downstream imports (proxy pattern).
/* eslint react-refresh/only-export-components: 0 */
import "./Menu.scss";

import {
  ControlledMenuProps,
  MenuItemProps,
  MenuProps,
  ControlledMenu as ReactControlledMenu,
  Menu as ReactMenu,
  MenuItem as ReactMenuItem,
  SubMenu as ReactSubMenu,
  SubMenuProps,
} from "@szhsin/react-menu";
import clsx from "clsx";
import type { FC } from "react";

import { ChevronRightIcon } from "mds/icons";

export {
  MenuDivider,
  MenuGroup,
  MenuRadioGroup,
  useClick,
  type MenuProps,
  type MenuDirection,
  type ClickEvent,
  type MenuInstance,
  type MenuChangeEvent,
} from "@szhsin/react-menu";

/**
 * A simple wrapper around the `@szhsin/react-menu` `Menu` component that sets some default props
 * and css classes for MDS styling.
 * Follow the documentation in https://szhsin.github.io/react-menu/ for usage.
 * Components like MenuItem, MenuDivider, and SubMenu should be imported from @szhsin/react-menu
 * directly and uses as is. They will be styles by this parent component.
 */
export const Menu: FC<MenuProps> = ({ className, ...menuProps }) => {
  return (
    <ReactMenu
      align="center"
      className={clsx("mds-menu", !menuProps.children && "empty", className)}
      direction="bottom"
      position="auto"
      viewScroll="auto"
      portal
      {...menuProps}
    >
      {menuProps.children}
    </ReactMenu>
  );
};

type SubMenuArrowProps = SubMenuProps & { withArrow?: boolean; iconColor?: string };

/**
 * A simple wrapper around the SubMenu allowing for some additional styling
 */
export const SubMenu: FC<SubMenuArrowProps> = ({
  label,
  withArrow,
  children,
  className,
  ...subMenuProps
}) => {
  const hasIconColor = typeof className === "string" && className.includes("icon-");
  const labelWithArrow = withArrow ? (
    <>
      {label}
      <span className="menu__withArrow">
        <ChevronRightIcon size="s" />
      </span>
    </>
  ) : (
    label
  );

  return (
    <ReactSubMenu
      className={clsx("interactive", className, !hasIconColor && "icon-black")}
      direction="right"
      label={labelWithArrow}
      {...subMenuProps}
    >
      {children}
    </ReactSubMenu>
  );
};

type MenuItemKindProps = MenuItemProps & {
  kind?: "destructive" | "instructional";
  iconColor?: string;
  download?: string;
};

/**
 * A simple wrapper around the SubMenu allowing for some additional styling
 */
export const MenuItem: FC<MenuItemKindProps> = ({ kind, className, ...menuItemProps }) => {
  const hasIconColor = typeof className === "string" && className.includes("icon-");
  return (
    <ReactMenuItem
      className={clsx(
        "mds-menu interactive",
        kind && `szh-menu__item-${kind}`,
        className,
        !hasIconColor && "icon-black",
      )}
      {...menuItemProps}
    >
      {menuItemProps.children}
    </ReactMenuItem>
  );
};

/**
 * See Menu. This is the equivalent for ControlledMenu.
 */
export const ControlledMenu: FC<ControlledMenuProps> = ({ className, ...menuProps }) => {
  return (
    <ReactControlledMenu
      align="center"
      className={clsx("mds-menu", className)}
      direction="bottom"
      position="auto"
      viewScroll="auto"
      {...menuProps}
    >
      {menuProps.children}
    </ReactControlledMenu>
  );
};

export type MenuType = "menu" | "submenu" | "controlledmenu";
