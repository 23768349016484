import "./ExpandableText.scss";

import clsx from "clsx";
import { FC, ReactNode, useEffect, useRef, useState } from "react";

import { t } from "i18n/i18n";

type ExpandableTextProps = {
  children: ReactNode | ReactNode[];
  className?: string;
  lines?: number;
};

export const ExpandableText: FC<ExpandableTextProps> = ({ children, className, lines = 3 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current) {
      const { clientHeight, scrollHeight } = textRef.current;
      if (scrollHeight > clientHeight) {
        setShowReadMore(true);
      }
    }
  }, [textRef]);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const maxHeight = `${lines * 1.5}em`;
  const readText = isExpanded ? t("expandable_text.read_less") : t("expandable_text.read_more");

  return (
    <div
      className={clsx("expandable-text-container", className, { expanded: isExpanded })}
      ref={textRef}
      style={{ maxHeight: isExpanded ? "none" : maxHeight }}
    >
      {children}
      {showReadMore && (
        <div className="read-more" onClick={toggleExpanded}>
          {readText}
        </div>
      )}
    </div>
  );
};
