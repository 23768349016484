import "./Avatar.scss";

import clsx from "clsx";
import { DetailedHTMLProps, FC, HTMLAttributes } from "react";

export interface AvatarProps {
  text: string;
  style?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>["style"];
  className?: string;
}

/**
 * A component that displays a simple circle with the text inside.
 */
export const Avatar: FC<AvatarProps> = ({ text, className, style }) => {
  return (
    <div className={clsx("mds-avatar", className)} style={style}>
      {text}
    </div>
  );
};
