import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const CircleSuccessGreenIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <g clipPath="url(#clip0_328_38738)">
      <path
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="#33AB6F"
        fillRule="evenodd"
      />
      <path
        d="M13.5334 5.53278C13.6208 5.62087 13.6212 5.76518 13.5345 5.85349L7.08238 12.4427C6.99568 12.5312 6.85393 12.5312 6.76723 12.4427L5.32052 10.965C5.23382 10.8765 5.09207 10.7317 5.00537 10.6432L3.26424 8.86482C3.17754 8.77628 3.17754 8.63152 3.26424 8.54298L4.7114 7.06508C4.7981 6.97654 4.93985 6.97654 5.02655 7.06508L6.7679 8.8432C6.8546 8.93174 6.99635 8.93174 7.08305 8.8432L11.7602 4.06672C11.8469 3.97818 11.9891 3.97772 12.0764 4.06558L13.5334 5.53278Z"
        fill="#EBF7F1"
      />
    </g>
    <defs>
      <clipPath id="clip0_328_38738">
        <rect fill="white" height="16" width="16" />
      </clipPath>
    </defs>
  </svg>
);
