import Agent from "sharedb/lib/agent";
import { Connection } from "sharedb/lib/client";
import ConnectionInternal from "sharedb/lib/client/connection";

import { PUBSUB_MESSAGE_TYPE } from "./types/pubsub";

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/**
 * There is bug in our interaction with ShareDB presence that causes a presence request loop,
 * and this is a temporary measure to prevent the issue from occurring, at the cost of some
 * functionality.
 * The fix is turning requesting and reacting to "pr" (presenceRequest) actions into a no-op,
 * which means that arriving at a new Page will no longer request (and show) presence data
 * of existing users. Only subsequent new user interactions will show up.
 *
 * The _handlePresenceRequest prevents the client from handling presence requests, and
 * the _requestPresence prevents the server from sending presence requests to clients when
 * a new client subscribes to presences.
 */
ConnectionInternal.prototype._handlePresenceRequest = function _handlePresenceRequest() {
  // noop
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(Agent as any).prototype._requestPresence = function _requestPresence(_, callback?: () => void) {
  // noop
  if (callback) {
    callback();
  }
};

/**
 * This function patches the prototype of the ShareDB Connection object to allow us to override
 * some of the logging. This is mainly to make ShareDB ignore messages that are Pubsub specific
 * and stop logging warnings. This is because we use the websocket both for ShareDB and for Pubsub, and we don't
 * and they don't (and shouldn't) know about each other.
 */
export function patchShareDBConnectionForPubsub() {
  const originalFunc = Connection.prototype.handleMessage;

  Connection.prototype.handleMessage = function handleMessage(message) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const boundOriginal = originalFunc.bind(this);
    if (message.error) {
      // All errors are for ShareDB, as we don't send any through Pubsub
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      boundOriginal(message);
    } else if (!message.a && message.type) {
      // Pubsub messages are not for ShareDB, ignore them, unless they are of unknown type
      if (message.type && PUBSUB_MESSAGE_TYPE !== message.type) {
        // eslint-disable-next-line no-console
        console.warn(`Receives unknown pubsub message of type "${message.type}":`, message);
      }
    } else if (message.a === "pong") {
      // Custom keepalive messages are not for ShareDB, ignoring
    } else {
      // Not a pubsub message: forward to ShareDB
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      boundOriginal(message);
    }
  };
}
