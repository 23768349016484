import { FC } from "react";

import { useFetchAssessmentStatus } from "../assessment/assessment-data";

import { PageListItem } from "components/materials/topic/activity/detail/PageListItem";
import { CoursePageCategory, CoursePageType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { TextAddButton } from "mds/components/TextAddButton";
import { useOrdered } from "mds/hooks/use-ordered";
import { useAppSelector } from "store/index";
import {
  selectAllPagesWithDueDatesInCurrentCourse,
  selectCurrentCourse,
  selectPagesForCurrentCourse,
} from "store/selectors";
import { useListSelector } from "store/store-hooks";
import { useDnDItemOrdering, useLocalCopy } from "utils/collections";
import { PAGE_LIST_CUTOFF_LENGTH, usePageActions } from "utils/page";
import { coursePageUrl } from "utils/urls";

type CoursePageListProps = {
  category: CoursePageCategory;
  shouldTruncateList?: boolean;
  canEdit?: boolean;
};

export const CoursePageList: FC<CoursePageListProps> = ({
  category,
  shouldTruncateList,
  canEdit,
}) => {
  const currentCourse = useAppSelector(selectCurrentCourse);
  const coursePages = useListSelector((s) =>
    selectPagesForCurrentCourse(s, category),
  ) as CoursePageType[];
  const orderedPages = useOrdered(coursePages);
  const [pages, setPages] = useLocalCopy<CoursePageType[]>(orderedPages);

  useFetchAssessmentStatus(pages);

  const { createPage, savePage, navigateToPage } = usePageActions();

  const pagesWithDueDates = useListSelector(selectAllPagesWithDueDatesInCurrentCourse);

  const movePagesLocally = useDnDItemOrdering(pages, setPages, "category");

  const onDropPage = (
    droppedPage: CoursePageType,
    _dropIndex: number,
    sourceContainerId: string,
  ) => {
    if (sourceContainerId !== droppedPage.category) {
      // If the item was dropped in a different container, we need to clean up afterwards
      // by removing it from the original container
      setPages((prevPages) => prevPages.filter((page) => page.id !== droppedPage.id));
    }
    savePage({ id: droppedPage.id, category: droppedPage.category, order: droppedPage.order });
  };

  const onCreatePage = () => createPage(category, pages);

  const isAssignment = category === "assignment";
  const shouldShowOverflowMenu = shouldTruncateList && pages.length > PAGE_LIST_CUTOFF_LENGTH;
  const visiblePages =
    !shouldShowOverflowMenu || (isAssignment && !shouldTruncateList)
      ? pages
      : pages.slice(0, PAGE_LIST_CUTOFF_LENGTH);

  let pageCount = pages.length;
  if (isAssignment) {
    const assignmentsWithoutDueDates = pages.filter((page) => !page.due_at);

    pageCount = pagesWithDueDates.length + assignmentsWithoutDueDates.length;
  }

  if (!visiblePages.length && !canEdit) {
    return null;
  }

  return (
    <div role="list">
      <h3 className="mb-2 flex items-center justify-between text-black-tint-10">
        {t(`page_variant_plural.${category}`)}
      </h3>

      {visiblePages.map((page, index) => {
        const pageURL = coursePageUrl(currentCourse.id, category, page.id);

        return (
          <PageListItem
            className={pages.length - 1 === index ? "last-item" : ""}
            containerId={category}
            editModeDependent={false}
            key={page.id}
            kind="primary"
            page={page}
            pageIndex={index}
            readOnly={!canEdit}
            onDropPage={onDropPage}
            onMovePageLocally={movePagesLocally}
            onPageClick={() => navigateToPage(page, pageURL)}
          />
        );
      })}

      {canEdit && (
        <TextAddButton
          className="mt-1 h-[42px]"
          reference={category}
          size="s"
          underlined
          onClick={onCreatePage}
        />
      )}

      {(shouldShowOverflowMenu || isAssignment) && pages.length > 0 && (
        <Button
          className="my-2"
          kind="secondary"
          size="s"
          to={coursePageUrl(pages[0].course_id, category, "")}
        >
          {t(`content_view.view_all.${category}`, { count: pageCount })}
        </Button>
      )}
    </div>
  );
};
