import "./CloseButton.scss";

import clsx from "clsx";
import { FC, forwardRef } from "react";

import { t } from "i18n/i18n";
import { Button, type ButtonProps } from "mds/components/Button";
import { CrossRemoveIcon } from "mds/icons";

interface CloseButtonProps {
  className?: string;
  size?: ButtonProps["size"];
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const CloseButton = forwardRef<HTMLButtonElement, CloseButtonProps>(
  ({ className, size = "xs", onClick }, ref) => (
    <Button
      className={clsx("close-button", className)}
      kind="secondary"
      ref={ref}
      size={size}
      title={t("common.close")}
      onClick={onClick}
    >
      <CrossRemoveIcon />
    </Button>
  ),
);

export const LargeCloseButton: FC<Omit<CloseButtonProps, "size">> = ({ className, ...props }) => (
  <CloseButton className={clsx("h-8 min-w-8 p-4", className)} size="m" {...props} />
);

CloseButton.displayName = "CloseButton";
