import { FC } from "react";

import { useAssessedCountData } from "../../../../utils/assessment";

import { PageType } from "components/server-types";
import { t } from "i18n/i18n";

type AssessedCountProps = {
  page: PageType;
  className?: string;
};

export const AssessedCount: FC<AssessedCountProps> = ({ page, className }) => {
  const { count, total } = useAssessedCountData(page);

  return <div className={className}>{t("assessment.assessed_count.number", { count, total })}</div>;
};
