import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const DoubleChevronRightIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="fill"
      d="M3.65451 2.75C3.50399 2.75 3.35215 2.80184 3.22912 2.90815C2.9549 3.14374 2.92283 3.55783 3.15843 3.83279L6.71933 7.99989L3.15778 12.167C2.92218 12.442 2.95424 12.8554 3.22846 13.0916C3.50268 13.3279 3.91629 13.2964 4.15124 13.0201L8.07798 8.42645C8.28806 8.18101 8.28806 7.81811 8.07798 7.57268L4.15124 2.97903C4.02232 2.82744 3.83841 2.75 3.65451 2.75Z"
    />
    <path
      className="fill"
      d="M8.89719 2.75C8.74666 2.75 8.59483 2.80184 8.47179 2.90815C8.19757 3.14374 8.16551 3.55783 8.40111 3.83279L11.962 7.99989L8.40046 12.167C8.16485 12.442 8.19692 12.8554 8.47114 13.0916C8.74535 13.3279 9.15897 13.2964 9.39392 13.0201L13.3207 8.42645C13.5307 8.18101 13.5307 7.81811 13.3207 7.57268L9.39392 2.97903C9.26499 2.82744 9.08109 2.75 8.89719 2.75Z"
    />
  </svg>
);
