import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const CheckIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M2 9.8001L5.6 13.4001L14 2.6001"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
