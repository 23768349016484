import clsx from "clsx";
import { FC, ReactNode } from "react";

import { IconProps } from "mds/utils/images";

export type IconTextProps = IconProps & {
  text: string | JSX.Element;
  iconStart?: ReactNode;
  iconEnd?: ReactNode;
  className?: string;
};

export const IconText: FC<IconTextProps> = ({ className, iconStart, iconEnd, text }) => {
  const textElement = typeof text === "string" ? <span>{text}</span> : text;
  return (
    <div className={clsx("flex items-center gap-2", className)}>
      {iconStart}
      {textElement}
      {iconEnd}
    </div>
  );
};
