import { FC } from "react";

import { isIndividualPage } from "components/materials/page/groups/helpers";
import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { Banner } from "mds/components/Banner";

interface NoSubmissionBannerProps {
  page: PageType;
}

export const NoSubmissionBanner: FC<NoSubmissionBannerProps> = ({ page }) => {
  return (
    <Banner className="page-block-width" kind="warning">
      {t("warning.no_submission_alert", { context: isIndividualPage(page) ? "student" : "group" })}
    </Banner>
  );
};
