import "./GroupCardShared.scss";

import type { FC } from "react";
import { useBoolean } from "usehooks-ts";

import { StudentGroupCard } from "components/materials/page/groups/StudentGroupCard";
import { JoinGroupButton } from "components/materials/page/groups/buttons/JoinGroupButton";
import { StartButton } from "components/materials/page/groups/buttons/StartButton";
import {
  hasReadiedUsersReachedMaxGroupSize,
  hasTotalUsersReachedMaxGroupSize,
  isPageGroupUserInPageGroup,
} from "components/materials/page/groups/helpers";
import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { Banner } from "mds/components/Banner";
import { TextAddButton } from "mds/components/TextAddButton";
import { storeApi, useAppSelector } from "store/index";
import { NestedPageGroupType, selectCurrentCourseUser } from "store/selectors";

interface StudentSelfSelectCardProps {
  pageGroups: NestedPageGroupType[];
  page: PageType;
}

export const StudentSelfSelectCard: FC<StudentSelfSelectCardProps> = ({ pageGroups, page }) => {
  const courseUser = useAppSelector(selectCurrentCourseUser);
  const { value: isFetching, setTrue: setFetching, setFalse: setNotFetching } = useBoolean(false);

  const pageGroupUsers = pageGroups.map((pageGroup) => pageGroup.page_group_users).flat();

  const userPageGroupUser = pageGroupUsers.find(
    (pageGroupUser) => pageGroupUser.course_user_id === courseUser.id,
  );

  // We show the Add Group button to the student if there's at least one student in each group (excluding self).
  const shouldShowAddGroup =
    !page.locked_at &&
    pageGroups.every(
      (pageGroup) =>
        pageGroup.page_group_users.filter(
          (pageGroupUser) => pageGroupUser.course_user_id !== courseUser.user_id,
        ).length > 0,
    );

  const onChangeGroup = async (pageGroupId: string) => {
    setFetching();
    if (userPageGroupUser) {
      // If the current user is already in a group, we just need to update their
      // page_group_id when they join or create a new group.
      await storeApi.page_group_users.partial_update({
        id: userPageGroupUser.id,
        page_group_id: pageGroupId,
        course_user_id: courseUser.id,
      });
    } else {
      // If the current user isn't in any group yet (i.e. don't have a pageGroupUser),
      // we need to create a pageGroupUser for the user.
      await storeApi.page_group_users.create({
        page_group_id: pageGroupId,
        course_user_id: courseUser.id,
      });
    }
    setNotFetching();
  };

  return (
    <div className="group-card flex w-full flex-col gap-4 rounded-2xl border border-black-tint-90 bg-black-tint-97 px-5 py-4">
      <div>
        <div className="h2 text-black-tint-20">
          {t("student_self_select_card.join_group_below")}
        </div>
        <div className="body-m text-black-tint-20">
          {t("student_self_select_card.cannot_change_group_after_start")}
        </div>
      </div>

      {page.locked_at ? (
        <Banner kind="info">{t("lock_status.group_card_lock_warning")}</Banner>
      ) : (
        pageGroups.map((pageGroup, index) => (
          <StudentGroupCard
            buttonComponent={
              isPageGroupUserInPageGroup(userPageGroupUser, pageGroup) ? (
                <StartButton
                  disabled={hasReadiedUsersReachedMaxGroupSize(pageGroup, page)}
                  isLoading={isFetching}
                  onClick={() => {
                    storeApi.page_group_users.partial_update({
                      id: userPageGroupUser.id,
                      is_ready: true,
                    });
                  }}
                />
              ) : (
                <JoinGroupButton
                  disabled={hasTotalUsersReachedMaxGroupSize(pageGroup, page)}
                  index={index}
                  isLoading={isFetching}
                  kind={userPageGroupUser ? "secondary" : "primary"}
                  onChangeGroup={() => onChangeGroup(pageGroup.id)}
                />
              )
            }
            isActive={isPageGroupUserInPageGroup(userPageGroupUser, pageGroup)}
            key={pageGroup.id}
            pageGroup={pageGroup}
            pageGroupIndex={index}
          />
        ))
      )}

      {shouldShowAddGroup && (
        <TextAddButton
          reference="page_group"
          onClick={() => {
            storeApi.page_groups
              .create({
                page_id: page.id,
              })
              .then((res) => {
                onChangeGroup(res.id);
              });
          }}
        />
      )}
    </div>
  );
};
