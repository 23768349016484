import { FC } from "react";

import { UserProfileSidebarPanel } from "../sidebar/UserProfileSidebarPanel";

import { CourseUsersSidebarPanel } from "./CourseUsersSidebarPanel";
import { PlannerSidebarPanel } from "./PlannerSidebarPanel";
import { SettingsSidebarPanel } from "./SettingsSidebarPanel";

import { AIChatPanel } from "components/ai/AIChatPanel";
import { ContentSidebar } from "components/materials/sidebar/ContentSidebar";
import { HelpCenterSidebarPanel } from "components/materials/sidebar/HelpCenterSidebarPanel";
import { useUserCourseSettings } from "utils/course";

export const CourseSidebar: FC = () => {
  const { canAuthorCourse, isMPAdmin, canSeeAIPanel } = useUserCourseSettings();

  /* TODO: needs mobile design and resulting mobile handling */
  return (
    <ContentSidebar
      AiAssistantPanel={canSeeAIPanel ? <AIChatPanel context="course" /> : undefined}
      context="course"
      HelpCenterPanel={<HelpCenterSidebarPanel />}
      PlannerPanel={<PlannerSidebarPanel />}
      ProfilePanel={<UserProfileSidebarPanel />}
      SettingsPanel={canAuthorCourse && <SettingsSidebarPanel />}
      UsersPanel={(canAuthorCourse || isMPAdmin) && <CourseUsersSidebarPanel />}
    />
  );
};
