import { FC } from "react";
import { useSearchParams } from "react-router-dom";

import { CATEGORY_QUERY_PARAM } from "components/constants";
import { AddIconButton } from "components/hover-widgets/AddIconButton";
import { AssignedPagesList } from "components/materials/AssignedPagesList";
import { PagesCard } from "components/materials/topic/activity/detail/PagesCard";
import { CoursePageCategory } from "components/server-types";
import { t } from "i18n/i18n";
import { Card } from "mds/components/Card";
import { HorizontalDivider } from "mds/components/HorizontalDivider";
import { TextAddButton } from "mds/components/TextAddButton";
import { useOrdered } from "mds/hooks/use-ordered";
import { EyeNotVisibleIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import {
  selectAllPagesWithDueDatesInCurrentCourse,
  selectCanAuthorCourse,
  selectIsEditModeEnabled,
  selectPagesForCurrentCourse,
} from "store/selectors";
import { useListSelector } from "store/store-hooks";
import { usePageActions } from "utils/page";

export const CoursePageSidebarPanel: FC = () => {
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);

  const [searchParams] = useSearchParams();
  const pageCategory = searchParams.get(CATEGORY_QUERY_PARAM) as CoursePageCategory;

  const pagesWithDueDates = useListSelector(selectAllPagesWithDueDatesInCurrentCourse);
  const pages = useOrdered(useListSelector((s) => selectPagesForCurrentCourse(s, pageCategory)));

  const { createPage } = usePageActions();
  const isAssigmentView = pageCategory === "assignment";

  const createNewPage = () => {
    createPage(pageCategory, pages);
  };

  const noPages = isAssigmentView
    ? pagesWithDueDates.length + pages.length === 0
    : pages.length === 0;

  return (
    <>
      <div className="content-sidebar-header">
        <div className="flex w-full items-center">
          <h3 className="icon-black-tint-70 flex flex-auto items-center gap-2 py-[5px]">
            {t(`page_variant_plural.${pageCategory}`)}

            {pageCategory === "instructor_workspace" && <EyeNotVisibleIcon />}
          </h3>

          {canAuthorCourse && isEditModeEnabled && (
            <AddIconButton reference="page" onClick={createNewPage} />
          )}
        </div>

        <HorizontalDivider className="-ml-2 mt-2" />
      </div>

      <div className="content-sidebar-main pt-2">
        {(pages?.length > 0 || (!noPages && isAssigmentView)) && (
          <>
            <PagesCard
              canManagePages={canAuthorCourse}
              category={pageCategory}
              header={isAssigmentView ? t("page_sidebar.course_assignments") : undefined}
              pages={pages}
            />

            <HorizontalDivider className="mb-3 mt-5" />
          </>
        )}

        {noPages && (
          <Card className="mt-3">
            <div className="body-s px-2 py-1 font-light text-black-tint-40">
              {t("page_sidebar.no_available", { context: pageCategory })}
            </div>

            <div className="-ml-1">
              <TextAddButton hideUnlessEditMode underlined onClick={createNewPage} />
            </div>
          </Card>
        )}

        {isAssigmentView && <AssignedPagesList category="topic" timeframe="all" />}
      </div>
    </>
  );
};
