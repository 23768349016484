import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const EyeVisibleIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="fill"
      d="M8.02197 13.2829C11.1818 13.2829 13.6395 10.5619 14.6928 8.98197C15.1317 8.36755 15.1317 7.57759 14.6928 6.96317C13.6395 5.471 11.1818 2.75 8.02197 2.75C4.8621 2.75 2.40442 5.471 1.35113 7.05094C0.912255 7.66536 0.912255 8.45533 1.35113 8.98197C2.40442 10.5619 4.8621 13.2829 8.02197 13.2829ZM8.02197 5.38323C9.51414 5.38323 10.6552 6.52429 10.6552 8.01646C10.6552 9.50862 9.51414 10.6497 8.02197 10.6497C6.52981 10.6497 5.38874 9.50862 5.38874 8.01646C5.38874 6.52429 6.52981 5.38323 8.02197 5.38323Z"
    />
  </svg>
);
