import { ReactNode } from "react";

import { GroupingCategory } from "components/server-types";
import { PersonClassIcon, PersonGroupIcon, PersonSingleIcon } from "mds/icons";

export const GROUPING_OPTION_ICONS: Record<GroupingCategory, ReactNode> = {
  [GroupingCategory.INDIVIDUAL]: <PersonSingleIcon />,
  [GroupingCategory.SHARED_CLASS]: <PersonClassIcon />,
  [GroupingCategory.ASSIGN_STUDENTS]: <PersonGroupIcon />,
  [GroupingCategory.SELF_ASSIGN]: <PersonGroupIcon />,
};
