import { i18n, t } from "i18n/i18n";
import { PasswordErrorKey } from "i18n/i18next";

// Keep in sync with server-side validation.
export const PASSWORD_MIN_LENGTH = 10;

export const hasNumber = (password: string) => /\d/.test(password);
export const hasUppercase = (password: string) => /[A-Z]/.test(password);
export const hasLowercase = (password: string) => /[a-z]/.test(password);
export const isMinLength = (password: string) => password?.length >= PASSWORD_MIN_LENGTH;

export const getPasswordErrorMessages = (
  keys: PasswordErrorKey[] | PasswordErrorKey,
  errorDetails: string | string[],
) => {
  let message = "";

  if (
    Array.isArray(keys) &&
    keys.every(
      (key) =>
        key === ("password_too_short" as PasswordErrorKey) ||
        i18n.exists(`error.change_password.${key}`),
    )
  ) {
    message = keys
      .map((key) =>
        key === ("password_too_short" as PasswordErrorKey)
          ? t(`error.change_password.${key}`, { count: PASSWORD_MIN_LENGTH })
          : t(`error.change_password.${key}`),
      )
      .join(" ");
  } else if (typeof keys === "string" && i18n.exists(`error.change_password.${keys}`)) {
    message = t(`error.change_password.${keys}`);
  } else if (Array.isArray(errorDetails)) {
    message = errorDetails.map((item) => `${item}`).join("  ");
  } else if (typeof errorDetails === "string") {
    message = errorDetails;
  }

  return message;
};

export const emailMustBeValid = (value: string) => {
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
    return t("user_options_menu.redeem.invalid_email");
  }
};

export const emailMustHaveAtSign = (value: string) => {
  if (!value.includes("@")) {
    return t("user_options_menu.redeem.incorrect_email_format", {
      email: value,
    });
  }
};
