import "./WorksheetResponses.scss";
import "./Worksheet.scss";

import { type FC, useContext } from "react";
import { Doc } from "sharedb/lib/client";

import { DATA_PATH } from "../shared/constants";
import { PathType, WorksheetType } from "../shared/types/worksheet";

import { Blocks } from "./Blocks";

import { DocViewContext } from "providers/DocViewProvider";
import { DoneProgress } from "worksheets/components/Aggregate/DoneProgress";
import { Worksheet } from "worksheets/views/Worksheet";

const RESPONSES_BASE_PATH: PathType = [DATA_PATH];

type WorksheetResponsesProps = {
  doc: Doc<WorksheetType>;
};

export const WorksheetResponses: FC<WorksheetResponsesProps> = ({ doc }) => {
  const { accessId, isFeaturing } = useContext(DocViewContext);

  if (!doc || !doc.data) return <div>Loading document</div>;

  if (accessId) {
    return <Worksheet doc={doc} />;
  }

  const shouldShowProgress = !accessId && !isFeaturing;

  return (
    <div className="worksheet-column-wrapper">
      {shouldShowProgress && <DoneProgress doc={doc} />}

      {/* TODO: Something is very wrong about accessIdPath here */}
      <Blocks accessIdPath={RESPONSES_BASE_PATH} doc={doc} />
    </div>
  );
};
