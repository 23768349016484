import clsx from "clsx";
import { FC } from "react";
import { useSearchParams } from "react-router-dom";

import { AIChatPanel } from "./ai/AIChatPanel";
import {
  MOBILE_OVERLAY_ROUTES,
  MOBILE_OVERLAY_ROUTE_QUERY_PARAM,
  MobileOverlayRouteType,
} from "./constants";
import { CourseUsersSidebarPanel } from "./materials/course/CourseUsersSidebarPanel";
import { PlannerSidebarPanel } from "./materials/course/PlannerSidebarPanel";
import { SettingsSidebarPanel } from "./materials/course/SettingsSidebarPanel";
import { HelpCenterSidebarPanel } from "./materials/sidebar/HelpCenterSidebarPanel";
import { UserProfileSidebarPanel } from "./materials/sidebar/UserProfileSidebarPanel";

import { MobileMainMenu } from "components/menus/MobileMainMenu";

const MOBILE_OVERLAY_ROUTE_COMPONENTS: Record<MobileOverlayRouteType, FC> = {
  menu: () => <MobileMainMenu />,
  users: () => <CourseUsersSidebarPanel />,
  settings: () => <SettingsSidebarPanel />,
  profile: () => <UserProfileSidebarPanel />,
  planner: () => <PlannerSidebarPanel />,
  help: () => <HelpCenterSidebarPanel />,
  ai: () => <AIChatPanel context="course" />,
};

/**
 * This component displays mobile-only routes (like the mobile menu, sidebar views, etc.)
 * over the main content. It needs to be inside a relative container.
 */
export const MobileRoutingOverlay = () => {
  const [searchParams] = useSearchParams();

  const mobileOverlayRoute = searchParams.get(
    MOBILE_OVERLAY_ROUTE_QUERY_PARAM,
  ) as MobileOverlayRouteType;

  if (!mobileOverlayRoute || !MOBILE_OVERLAY_ROUTES.includes(mobileOverlayRoute)) {
    return null;
  }

  const MobileOverlayComponent = MOBILE_OVERLAY_ROUTE_COMPONENTS[mobileOverlayRoute];

  return (
    <div
      className={clsx("page absolute inset-0 z-[4] bg-white lg:!hidden", {
        "content-sidebar-panel": mobileOverlayRoute !== "menu",
      })}
    >
      <MobileOverlayComponent />
    </div>
  );
};
