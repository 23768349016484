import { FC, ReactNode } from "react";

import { EmailLoginForm } from "./EmailLoginForm";

interface LoginViewProps {
  onLoggedIn: () => void;
  children?: ReactNode;
}

export const LoginView: FC<LoginViewProps> = ({ onLoggedIn, children }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <EmailLoginForm setIsLoggedIn={() => onLoggedIn()}>{children}</EmailLoginForm>
    </div>
  );
};
