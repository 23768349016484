import { useParams } from "react-router";

import { TopicView } from "components/materials/topic/TopicView";

export default function Page() {
  const params = useParams();
  const topicId = params.topicId;

  return <TopicView topicId={topicId} />;
}
