import { FC } from "react";

import { IconColorProps, initializeIconColorProps } from "mds/utils/images";

export const StatusDotIcon: FC<IconColorProps> = (props) => {
  const { fillClassName, ...svgProps } = initializeIconColorProps(props);
  return (
    <svg {...svgProps}>
      <rect className={fillClassName} height="6" rx="3" width="6" x="5" y="5" />
    </svg>
  );
};
