import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const CircleWarningOrangeIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <g clipPath="url(#clip0_328_38737)">
      <path
        clipRule="evenodd"
        d="M8 1.90735e-06C3.58172 1.90735e-06 1.90735e-06 3.58172 1.90735e-06 8C1.90735e-06 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 1.90735e-06 8 1.90735e-06Z"
        fill="#F0871E"
        fillRule="evenodd"
      />
      <path
        d="M6.80008 8.95992L6.80002 8.70162L6.80011 3.03826C6.80011 2.87278 6.9375 2.73539 7.10373 2.73464L8.90023 2.73466C9.06646 2.73542 9.20348 2.87244 9.20348 3.03792L9.20345 6.29744L9.20345 8.96033C9.20307 9.12694 9.06644 9.26357 8.9002 9.26357L7.10371 9.26355C6.93748 9.26355 6.80046 9.12653 6.80008 8.95992Z"
        fill="#FEF3E9"
      />
      <circle
        cx="8.01172"
        cy="11.75"
        fill="#FEF3E9"
        r="1.25"
        transform="rotate(-180 8.01172 11.75)"
      />
    </g>
    <defs>
      <clipPath id="clip0_328_38737">
        <rect fill="white" height="16" transform="matrix(-1 0 0 -1 16 16)" width="16" />
      </clipPath>
    </defs>
  </svg>
);
