import "./Checkbox.scss";

import clsx from "clsx";
import { ChangeEvent, InputHTMLAttributes, MouseEventHandler, ReactNode, forwardRef } from "react";

import { useControllable } from "mds/hooks/use-controllable";
import { CheckIcon } from "mds/icons";

interface CheckboxProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  children?: ReactNode;
  initialChecked?: boolean;
  onChange?: (newChecked: boolean, event: ChangeEvent<HTMLInputElement>) => void;
  onContainerClick?: MouseEventHandler<HTMLDivElement>;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      children,
      className,
      initialChecked,
      checked: controlledCheck,
      onChange,
      onContainerClick,
      ...props
    },
    ref,
  ) => {
    const [checked, setChecked] = useControllable<boolean, ChangeEvent<HTMLInputElement>>(
      controlledCheck,
      initialChecked,
      onChange,
    );
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => setChecked(!checked, event);
    return (
      <div className={clsx("checkbox flex", className)} onClick={onContainerClick}>
        <label>
          <input checked={checked} ref={ref} type="checkbox" onChange={handleChange} {...props} />
          <div>{checked && <CheckIcon className="check-icon" />}</div>
          {children}
        </label>
      </div>
    );
  },
);

Checkbox.displayName = "Checkbox";
