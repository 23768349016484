import "./FeatureProjectorView.scss";

import clsx from "clsx";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import { FeatureOutcomes } from "./FeatureOutcomes";

import { PageDetailView } from "components/materials/page/PageDetailView";
import { useAppSelector } from "store/index";
import { selectPageById } from "store/selectors";
import { usePresentation } from "utils/presentation";

type FeatureProjectorViewProps = {
  isStaticContent: boolean;
};

// This page represents the view of the projector when a page is being projected in feature mode
export const FeatureProjectorView = forwardRef<HTMLDivElement, FeatureProjectorViewProps>(
  ({ isStaticContent }, ref) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const {
      presentedPageId: pageId,
      presentingRollup,
      presentedAccessId,
      presentingOutcomes,
    } = usePresentation();
    const [alignmentClass, setAlignmentClass] = useState("align-center");
    const page = useAppSelector((s) => selectPageById(s, pageId));

    useImperativeHandle(ref, () => containerRef.current, []);

    useEffect(() => {
      const checkHeight = () => {
        if (containerRef.current) {
          const newAlignmentClass =
            containerRef.current.scrollHeight > containerRef.current.offsetHeight
              ? "align-top"
              : "align-center";

          setAlignmentClass(newAlignmentClass);
        }
      };

      const resizeObserver = new ResizeObserver(checkHeight);
      resizeObserver.observe(containerRef.current);

      setTimeout(checkHeight, 500);

      return () => {
        resizeObserver?.disconnect();
      };
    }, []);

    return (
      <div className="feature-projector-view">
        <div className="feature-projector-view-header">{page?.title}</div>

        <div
          className={clsx("feature-projector-view-main-container", alignmentClass)}
          id="feature-scroller"
          ref={containerRef}
        >
          <div className="feature-projector-view-main">
            <form>
              {presentingOutcomes ? (
                <FeatureOutcomes page={page} />
              ) : (
                <PageDetailView
                  isStaticContent={isStaticContent}
                  page={page}
                  selectedAccessId={presentedAccessId}
                  selectedTabVariant={presentingRollup ? "responses" : null}
                  isFeaturing
                  isProjecting
                />
              )}
            </form>
          </div>
        </div>
      </div>
    );
  },
);

FeatureProjectorView.displayName = "FeatureProjectorView";
