import { collaborationGetWorksheetJson } from "api/api-worksheets";
import { ActivityWithPagesType } from "store/selectors";

// This will export a given activities for use as seed data for later importing into a topic. Copy the object and store
// it in a file like `agile-innovation-activities.ts`.
// TODO: Until the ordering code is reworked, local activities `order` may be inaccurate
// if modified locally after downloading from the server.
export const exportActivitiesAndPagesForSeedData = async (activities: ActivityWithPagesType[]) => {
  const reducedSeedData = activities.map((activity) => {
    return {
      title: activity.title,
      order: activity.order,
      pages: activity.pages.map(async (page) => {
        const worksheetJson = await collaborationGetWorksheetJson(page.worksheet_id);
        return {
          title: page.title,
          order: page.order,
          category: page.category,
          worksheetBlocks: worksheetJson.b,
        };
      }),
    };
  });
  const populatedActivities = await Promise.all(
    reducedSeedData.map(async (activity) => {
      const pages = await Promise.all(activity.pages);
      return {
        ...activity,
        pages,
      };
    }),
  );

  // Now we want to make the browser trigger a download of the populated object.
  const exportJsonString = JSON.stringify(populatedActivities, null, 1);
  const blob = new Blob([exportJsonString], { type: "application/json" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  // Set the filename and trigger the download.
  a.download = "exportedTopicJson.json";
  a.click();

  // Revoke the Object URL to free up pages
  URL.revokeObjectURL(url);
};
