/**
 * This file defines types related to AI queries, and Redux store selectors to transform store
 * state into context for AI queries.
 */

import { DEFAULT_BLOCKS } from "../../worksheets/shared/constants";

import { AIMessageType, AIRoleType, CoursePageCategory } from "components/server-types";
import { t } from "i18n/i18n";
import { RootState } from "store/index";
import {
  createAppSelector,
  selectCourseOutcomes,
  selectCurrentCourse,
  selectOutcomes,
  selectPageById,
} from "store/selectors";

/**
 * Determines the context for the AI chat based on the current view.
 * course: is for the course view (content or outcomes tab), and includes outcome context.
 * topic: is for any specific topic page, whether a page is currently selected or not
 * course_pages: is for a view showing an assignment, course resource, or instructor workspace resource
 * org: is for any view on the /org subpage, and includes a list of courses
 */
export type AIChatContextType = "topic" | "course_page" | "course" | "org";

export type ChatMessage = {
  id: number;
  message: string;
  /**
   * Whether the message was written by the end user.
   */
  isQuery: boolean;
  /**
   * A message that's displayed on the client but won't be sent to the LLM.
   */
  clientOnly?: boolean;
  /**
   * Whether the message is a placeholder for an ongoing AI request.
   */
  isLoading?: boolean;
};

export const DEFAULT_AI_INTRO_MESSAGE: ChatMessage = {
  message: t("ai_chat_panel.intro_message"),
  clientOnly: true,
  isQuery: false,
  id: 0,
  isLoading: false,
};

// TODO: This is mostly an example. Fill out with more documentation.
const COURSE_EXPLANATION = [
  ...t("ai_chat_panel.course_explanation", { returnObjects: true }),
  t("ai_chat_panel.page_explanation_short"),
].join("\n");

// Because the AI doesn't always use the exact wording, we scan for some common patters in the last line
export const CREATE_BLOCK_ACTION_HOOK_REGEX =
  /(Should I|Would you like|Do you want).*(add|changes|Page).*\?/gi;

// TODO: This has been disabled for now is it was triggering unexpectedly.  Will need to redesign how we use this.
export const CREATE_BLOCK_ACTION_EXPLANATION = t("ai_chat_panel.create_block_action_explanation");

// TODO: This is not being used yet, but when it does we might need to localize it.  Maybe tell the chatbot that
// these are in english but you should create your own in the language given.
const EXAMPLE_FINAL_BLOCKS_JSON_RESPONSE = [
  {
    t: "FreeResponse",
    f: [
      {
        t: "Text",
        v: {
          ops: [
            { insert: "Today's homework" },
            { attributes: { header: 3 }, insert: "\\n" },
            { insert: "\\nMake sure you answer " },
            { attributes: { bold: true }, insert: "every" },
            { insert: " item. Items marked in " },
            { attributes: { color: "#008a00" }, insert: "green" },
            { insert: " are particularly important.\\n\\n" },
          ],
        },
      },
      { t: "RichTextField", maxLength: null },
    ],
  },
];

const PAGES_WORKSHEET_FORMAT_EXPLANATION = [
  `Here are examples of all possible block types and their content:\n\n`,
  Object.values(DEFAULT_BLOCKS)
    .map((b) => `${b.t}: \`${JSON.stringify(b)}\``)
    .join("\n"),
  `\n\nThe \`ops\` arrays can also contain RichText formatting, like so:`,
  `\`${JSON.stringify(EXAMPLE_FINAL_BLOCKS_JSON_RESPONSE[0].f[0].v.ops)}\`\n\n`,
  `Shortened attributes like "t" stand for "type", "m" for "multiple", "v" for "value",`,
  `"l" for "label", "c" for "choices" or "code" depending on the field type.\n`,
  `Note that all string content, such as ops content for the Text fields, is visible to and directed at students and should be written accordingly.\n\n`,
].join(" ");

/**
 * This file is responsible for taking various contexts and user or system messages
 * to construct a list of messages corresponding to the OpenAI API.
 *
 * An example output would be:
 * [
 *  { role: "system", message: "<generic context for AI>, <context and data for current page>" },
 *  { role: "assistant", message: "<introduction message hardcoded on client>" },
 *  { role: "user", message: "<first user request>" },
 *  { role: "assistant", message: "<response from AI>" },
 *  { role: "user", message: "<second user request>" },
 *  etc.
 * ]
 *
 */
export const getAIViewContextQuery = (
  systemPrompt: string,
  viewContext: string,
  pageContext: string,
  messages: ChatMessage[],
): AIMessageType[] => {
  const fullContext = [viewContext, pageContext].filter(Boolean).join("\n");

  const aiQuery: AIMessageType[] = [
    // Introducing general Minerva context and desired behavior
    { role: "system", content: systemPrompt },
    // Introducing current page context
    {
      role: "assistant",
      content: `${fullContext || String(t("ai_chat_panel.no_content"))} ${String(t("ai_chat_panel.help_prompt"))}`,
    },
    // Actual message history between user and AI assistant
    ...messages
      .filter((msg) => !msg.clientOnly && !msg.isLoading)
      .map((msg) => ({
        role: (msg.isQuery ? "user" : "assistant") as AIRoleType,
        content: msg.message,
      })),
  ];

  return aiQuery;
};

/**
 * Accepts a human-readable description of a list of fields to add to a page, and their contents,
 * and returns a query that can be sent to the AI model to generate the corresponding Block JSON
 * in a worksheet-compatible format.
 */
// TODO: Localize this when we start to use it, or figure out how to pass chat english but return in the language given.
export const getAIPageBlockCreationQuery = (description: string): AIMessageType[] => {
  return [
    // Introducing general Minerva context
    {
      role: "system",
      content: [
        t("ai_chat_panel.json_creator_system_prompt"),
        `You are specifically being asked to create Blocks for Forum V Pages.`,
        t("ai_chat_panel.page_explanation_long"),
        PAGES_WORKSHEET_FORMAT_EXPLANATION,
        `Your response should be a JSON array of Blocks and nothing else. Here's an example response for a single FreeResponse field:\n`,
        `\`${JSON.stringify(EXAMPLE_FINAL_BLOCKS_JSON_RESPONSE)}\`\n`,
        // TODO: Sometimes we do want only non-interactive fields. How do we handle that?
        `Make sure to include at least some interactive fields in your response.`,
      ].join("\n"),
    },
    {
      role: "user",
      content: `Can you translate the following description into Page compatible JSON? Message: ${description}`,
    },
  ];
};

// TODO: Also add rubric without adding too much text content
const selectCourseContextForAi = createAppSelector(
  [selectCourseOutcomes, selectOutcomes, selectCurrentCourse],
  (cos, outcomes, currentCourse) => {
    const coList = cos;
    const usedOutcomes = outcomes.filter((o) => coList.some((co) => co.outcome_id === o.id));

    const OUTCOME_CONTEXT = usedOutcomes.map((o) => `-${o.title}: ${o.description}`).join("\n");

    return [
      `${COURSE_EXPLANATION}.`,
      t("ai_chat_panel.current_course_title", {
        title: currentCourse?.title,
        description: currentCourse?.description,
      }),
      t("ai_chat_panel.current_course_outcomes", { outcomes: OUTCOME_CONTEXT }),
    ].join("\n");
  },
);

const selectTopicContextForAi = createAppSelector(
  [
    selectPageById,
    (state) => state.outcomes,
    (state) => state.course_outcomes,
    (state) => state.page_outcomes,
  ],
  (page, outcomes, cos, pos) => {
    const coList = Object.values(cos);
    const poList = Object.values(pos);
    const availableOutcomes = Object.values(outcomes).filter((o) =>
      coList.some((co) => co.outcome_id === o.id),
    );
    const usedOutcomes = Object.values(outcomes).filter((o) =>
      poList.some((po) => coList.some((co) => co.outcome_id === o.id && po.page_id === page.id)),
    );
    const COURSE_OUTCOME_CONTEXT = availableOutcomes
      .map((o) => `-${o.title}: ${o.description}`)
      .join("\n");
    const PAGE_OUTCOME_CONTEXT = usedOutcomes
      .map((o) => `-${o.title}: ${o.description}`)
      .join("\n");

    return [
      t("ai_chat_panel.topic_explanation"),
      t("ai_chat_panel.page_explanation_long"),
      t("ai_chat_panel.current_page_title", { title: page?.title || "<none>" }),
      t("ai_chat_panel.outcomes_available", { outcomes: COURSE_OUTCOME_CONTEXT }),
      t("ai_chat_panel.current_page_outcomes", { outcomes: PAGE_OUTCOME_CONTEXT }),
    ]
      .filter(Boolean)
      .join("\n");
  },
);

const categoryToExplanationMap: Record<CoursePageCategory, string> = {
  course_resource: t("ai_chat_panel.course_resource_explanation"),
  instructor_workspace: t("ai_chat_panel.instructor_workspace_explanation"),
  assignment: t("ai_chat_panel.assignment_explanation"),
};

const selectCoursePageContextForAi = createAppSelector(
  [
    selectPageById,
    (state) => state.outcomes,
    (state) => state.course_outcomes,
    (state) => state.page_outcomes,
  ],
  (page, outcomes, cos, pos) => {
    const coList = Object.values(cos);
    const poList = Object.values(pos);
    const availableOutcomes = Object.values(outcomes).filter((o) =>
      coList.some((co) => co.outcome_id === o.id),
    );
    const usedOutcomes = Object.values(outcomes).filter((o) =>
      poList.some((po) => coList.some((co) => co.outcome_id === o.id && po.page_id === page.id)),
    );
    const COURSE_OUTCOME_CONTEXT = availableOutcomes
      .map((o) => `-${o.title}: ${o.description}`)
      .join("\n");
    const PAGE_OUTCOME_CONTEXT = usedOutcomes
      .map((o) => `-${o.title}: ${o.description}`)
      .join("\n");

    return [
      categoryToExplanationMap[page.category],
      t("ai_chat_panel.page_explanation_long"),
      t("ai_chat_panel.current_page_title", { title: page?.title || "<none>" }),
      t("ai_chat_panel.outcomes_available", { outcomes: COURSE_OUTCOME_CONTEXT }),
      t("ai_chat_panel.current_page_outcomes", { outcomes: PAGE_OUTCOME_CONTEXT }),
    ]
      .filter(Boolean)
      .join("\n");
  },
);

// TODO: Optimize selectors for performance
// TODO: Add selectors for other contexts, according to docstring on AIChatContextType
export const AI_CONTEXT_TO_SELECTOR: Record<
  AIChatContextType,
  (state: RootState, pageId: string) => string
> = {
  course: selectCourseContextForAi,
  topic: selectTopicContextForAi,
  course_page: selectCoursePageContextForAi,
  org: () => "nothing",
};
