import type { FC } from "react";

import type { BlockType } from "../../shared/types/worksheet";

import { t } from "i18n/i18n";

type BlockResponsesHeaderProps = {
  blockType: BlockType["t"];
};

export const BlockResponsesHeader: FC<BlockResponsesHeaderProps> = ({ blockType }) => {
  return (
    <div className="body-s mb-3 mt-2 font-medium text-black-tint-55">
      {t(`fields.headers.${blockType}`)}
    </div>
  );
};
