import {
  getGroupIdFromAccessId,
  getStudentIdFromAccessId,
  groupAccessIdFromGroupId,
  isGroupAccessId,
  studentAccessIdFromUserId,
} from "../../worksheets/shared/access-id";
import { DEFAULT_CODE_KERNEL_LANG } from "../../worksheets/shared/code-kernels";
import { CourseUserActivityType } from "../../worksheets/shared/types/analytics";

import {
  createAppSelector,
  selectAllCourseUsers,
  selectClassSessions,
  selectCourseCodes,
  selectCourseUsers,
  selectCourses,
  selectCurrentCourseId,
  selectCurrentUserId,
  selectPageGroupUsers,
  selectPageGroups,
  selectPages,
  selectTerms,
} from "./base";
import { selectCanAuthorCourse } from "./permissions";
import {
  CourseUserActivityStatus,
  type ObjectWithCourses,
  type UserWithCourseActivity,
  type UserWithCourseUserType,
} from "./types";
import { selectIsAssessing, selectSelectedPageTabVariant } from "./view";

import {
  type CourseCodeType,
  type CourseRoleType,
  type CourseUserType,
  type FullCourseType,
  GroupingCategory,
  type PageCategory,
  type TermType,
  type UserType,
} from "components/server-types";
import { t } from "i18n/i18n";
import { RootState } from "store";
import { getUserShortName } from "utils/user-utils";

export const selectOrgIdForCourseId = (state: RootState, courseId: string) =>
  state.auth.course_to_org_id_map?.[courseId];

export const selectCurrentOrg = (state: RootState) =>
  Object.values(state.orgs).find((o) => o.current) || null;

export const selectCurrentCourse = (state: RootState) => {
  return state.courses[state.view.currentCourseId] || Object.values(state.courses)[0];
};

export const selectUserById = (state: RootState, userId: string) => state.users[userId];

export const selectCourseUserById = (state: RootState, courseUserId: string) =>
  state.course_users[courseUserId];

export const selectPageById = (state: RootState, pageId: string) => state.pages[pageId];

export const selectActivityById = (state: RootState, activityId: string) =>
  state.activities[activityId];

export const selectCourseUserByUserId = createAppSelector(
  [(state, userId: string) => userId, selectCourseUsers],
  (userId, courseUsers) => courseUsers.find((cu) => cu.user_id === userId),
);

export const selectCourseUsersByRole = createAppSelector(
  [selectCourseUsers, (state, role?: CourseRoleType) => role],
  (courseUsers, role) => courseUsers.filter((cu) => !role || cu.role === role),
);

export const selectCurrentCourseUser = createAppSelector(
  [selectCourseUsers, selectCurrentCourseId, selectCurrentUserId],
  (courseUsers, courseId, userId) =>
    courseUsers.find((cu) => cu.course_id === courseId && cu.user_id === userId),
);

export const selectCurrentCourseKernelLang = (state: RootState) =>
  selectCurrentCourse(state)?.code_blocks_default_language || DEFAULT_CODE_KERNEL_LANG;

export const selectFullCoursesSorted = createAppSelector(
  [selectCourses, (state) => state.course_codes],
  (courses, course_codes) =>
    courses
      .map((course) => {
        const course_code = course_codes[course.course_code_id];

        return {
          ...course,
          full_title: t("common.course_code_and_title", {
            course_title: course.title,
            course_code_title: course_code?.title,
          }),
        } as FullCourseType;
      })
      .sort((a, b) => a.full_title.localeCompare(b.full_title)),
);

export const selectCurrentCourseCode = createAppSelector(
  [(state) => state.course_codes, selectCurrentCourse],
  (codes, currentCourse) => codes[currentCourse.course_code_id] || codes[0],
);

export const selectCourseCodeById = createAppSelector(
  [(state, courseCodeId: string) => courseCodeId, (state) => state.course_codes],
  (courseCodeId, codes) => codes[courseCodeId],
);

const mapUserToCourseUsers = (
  courseUsers: CourseUserType[],
  usersMap: Record<string, UserType>,
  courseId: string,
): UserWithCourseUserType[] => {
  const courseUsersWithNames = Object.values(courseUsers)
    .filter((cu) => cu.course_id === courseId)
    .map((cu) => {
      const user = usersMap[cu.user_id];
      return {
        course_user_id: cu.id,
        ...cu,
        ...user,
      };
    });

  return courseUsersWithNames;
};

// TODO: Put these two in a more appropriate place.
export const determineActivityStatus = (
  activity: CourseUserActivityType,
): CourseUserActivityStatus => {
  if (!activity) {
    return CourseUserActivityStatus.OFFLINE;
  }

  return activity.active === "0"
    ? CourseUserActivityStatus.INACTIVE
    : CourseUserActivityStatus.ACTIVE;
};

const determineCurrentPageId = (activity: CourseUserActivityType): string | null => {
  if (!activity) {
    return null;
  }

  return activity.pageId;
};

export const selectActivityStatus = (state: RootState) => state.analytics.courseUserActivities;

export const selectActivityStatusForUser = (state: RootState, userId: string) =>
  state.analytics.courseUserActivities[userId];

export const selectUsersWithCourseActivityInCurrentCourse = createAppSelector(
  [
    selectCurrentCourseId,
    selectAllCourseUsers,
    (state) => state.users,
    (state) => state.analytics.courseUserActivities,
  ],
  (courseId, courseUsers, users, courseUserActivities): UserWithCourseActivity[] =>
    mapUserToCourseUsers(courseUsers, users, courseId).map((user) => ({
      ...user,
      courseActivityStatus: determineActivityStatus(courseUserActivities[user.user_id]),
      pageId: determineCurrentPageId(courseUserActivities[user.user_id]),
    })),
);

export const selectUsersInCourse = createAppSelector(
  [(state, courseId: string) => courseId, selectCourseUsers, (state) => state.users],
  (courseId, courseUsers, users) => mapUserToCourseUsers(courseUsers, users, courseId),
);

export const selectUsersInCurrentCourse = createAppSelector(
  [selectCourseUsers, (state) => state.users, selectCurrentCourse],
  (courseUsers, users, currentCourse): UserWithCourseUserType[] =>
    mapUserToCourseUsers(courseUsers, users, currentCourse?.id),
);

export const selectTermCoursesByCourseCode = createAppSelector(
  [(state, courseCodeId: string) => courseCodeId, selectTerms, selectCourses],
  (courseCodeId, terms, courses) => {
    const filteredCourses = courses.filter((course) => course.course_code_id === courseCodeId);

    return terms.map<ObjectWithCourses<TermType>>((term) => {
      const itemCourses = filteredCourses
        .filter((course) => course.term_id === term.id)
        .map((course, index) => ({ ...course, order: index }));
      return { ...term, courses: itemCourses };
    });
  },
);

export const selectTermById = (state: RootState, termId: string) => state.terms[termId];

export const selectCourseCodeCoursesByTerm = createAppSelector(
  [(state, termId: string) => termId, selectCourseCodes, selectCourses],
  (termId, course_codes, courses) => {
    const filteredCourses = courses.filter((course) => course.term_id === termId);

    return course_codes.map<ObjectWithCourses<CourseCodeType>>((course_code) => {
      const itemCourses = filteredCourses
        .filter((course) => course.course_code_id === course_code.id)
        .map((course, index) => ({ ...course, order: index }));
      return { ...course_code, courses: itemCourses };
    });
  },
);

export const selectCourseCodeCoursesByTerms = createAppSelector(
  [selectTerms, selectCourseCodes, selectCourses],
  (terms, course_codes, courses) => {
    return terms.reduce(
      (acc, term) => {
        const filteredCourses = courses.filter((course) => course.term_id === term.id);
        const courseCodes = course_codes.map<ObjectWithCourses<CourseCodeType>>((course_code) => {
          const itemCourses = filteredCourses
            .filter((course) => course.course_code_id === course_code.id)
            .map((course, index) => ({ ...course, order: index }));
          return { ...course_code, courses: itemCourses };
        });
        // eslint-disable-next-line no-param-reassign
        acc[term.id] = courseCodes;
        return acc;
      },
      {} as Record<string, ObjectWithCourses<CourseCodeType>[]>,
    );
  },
);

export const selectActiveClassSession = createAppSelector(
  [selectClassSessions, selectCurrentCourse],
  (classSessions, currentCourse) => classSessions.find((cs) => cs.course_id === currentCourse?.id),
);

// Separate from selectActiveClassSession just to be more specific and prevent re-renders
export const selectIsPresenting = createAppSelector([selectActiveClassSession], (activeSession) =>
  Boolean(activeSession.presented_page_id),
);

export const selectPresentingSessions = createAppSelector(
  [selectClassSessions, selectCurrentUserId],
  (classSessions, userId) => classSessions.filter((cs) => cs.presenting_user_id === userId),
);

export const selectPagesForCurrentCourse = createAppSelector(
  [
    (state, category?: PageCategory) => category,
    selectPages,
    selectCurrentCourse,
    selectCanAuthorCourse,
  ],
  (category, pages, currentCourse, canAuthorCourse) => {
    const results = pages
      .filter((p) => p.released_at || canAuthorCourse)
      .filter((r) => r.course_id === currentCourse.id && (!category || r.category === category));
    return results;
  },
);

export const selectCoursesByTermAndCourseCode = createAppSelector(
  [
    (state, termId: string) => termId,
    (state, termId: string, courseCodeId: string) => courseCodeId,
    selectCourses,
  ],
  (termId, courseCodeId, courses) =>
    courses.filter((course) => course.term_id === termId && course.course_code_id === courseCodeId),
);

export type StudentAccessIdInfoType = {
  name: string;
  accessId: string;
};

// TODO: Some of this logic is duplicated in calculateAccessId
// This function determines what access ids should be used for the current page
// If the user is a student it will return that student's access id and user name for the page.
// If the user is not a student it will determine which user has been selected or if all
// the courses users should be used and return those access ids with corresponding names.
export const selectUserDocInfoByPageId = createAppSelector(
  [
    selectPageById,
    (state, pageId: string, accessId: string) => accessId,
    selectPageGroups,
    selectPageGroupUsers,
    selectCurrentCourseUser,
    selectUsersInCurrentCourse,
    selectIsAssessing,
    selectSelectedPageTabVariant,
  ],
  (
    page,
    accessId,
    pageGroups,
    pageGroupUsers,
    currentCourseUser,
    courseUsers,
    isAssessing,
    selectedPageTabVariant,
  ): StudentAccessIdInfoType[] => {
    const userIsAStudent = currentCourseUser?.role === "student";

    if (
      !page ||
      !courseUsers ||
      !(
        isAssessing ||
        userIsAStudent ||
        selectedPageTabVariant === "responses" ||
        page.grouping_category === GroupingCategory.SHARED_CLASS
      )
    ) {
      return null;
    }

    if (page.grouping_category === GroupingCategory.SHARED_CLASS) {
      return [{ name: t("common.shared_class"), accessId: "g" }];
    }

    const groupsForPage = pageGroups.filter((pg) => pg.page_id === page.id);
    let groupIndex = -1;
    let userId = null;
    if (userIsAStudent) {
      const groupIdsForStudent = pageGroupUsers
        .filter((pgu) => pgu.course_user_id === currentCourseUser.id && pgu.is_ready)
        .map((pgu) => pgu.page_group_id);
      groupIndex = groupsForPage.findIndex(
        (pg) => pg.page_id === page.id && groupIdsForStudent.includes(pg.id),
      );

      if (groupIndex === -1) {
        userId = currentCourseUser.user_id;
      }

      // Here an insructor has selected a particular accessId
    } else if (accessId) {
      if (isGroupAccessId(accessId)) {
        const groupId = getGroupIdFromAccessId(accessId);
        groupIndex = groupsForPage.findIndex((pg) => pg.id === groupId);
      } else {
        userId = getStudentIdFromAccessId(accessId);
      }
    }

    if (groupIndex > -1) {
      return [
        {
          name: t("common.group_title", { number: groupIndex + 1 }),
          accessId: groupAccessIdFromGroupId(groupsForPage[groupIndex].id),
        },
      ];
    }

    if (userId) {
      const extendedCourseUser = courseUsers.find((cu) => cu.user_id === userId);

      return extendedCourseUser
        ? [
            {
              // If we are assessing we don't want to show the user's name
              name: isAssessing ? "" : getUserShortName(extendedCourseUser),
              accessId: studentAccessIdFromUserId(extendedCourseUser.user_id),
            },
          ]
        : null;
    }

    // If no user or group selected we need to return everyone
    if (groupsForPage.length) {
      return groupsForPage.map((pg, index) => ({
        name: t("common.group_title", { number: index + 1 }),
        accessId: groupAccessIdFromGroupId(pg.id),
      }));
    }

    return courseUsers
      .filter((cu) => cu.role === "student")
      .map((cu) => ({
        name: getUserShortName(cu),
        accessId: studentAccessIdFromUserId(cu.user_id),
      }));
  },
);
