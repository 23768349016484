import {
  ActivityPageType,
  ActivityType,
  AssessmentType,
  CourseCodeType,
  CourseOutcomeType,
  CourseType,
  CourseUserType,
  OutcomeAssessmentType,
  OutcomeGroupType,
  OutcomeSubgroupType,
  OutcomeType,
  PageGroupType,
  PageGroupUserType,
  PageOutcomeType,
  PageType,
  SubmissionType,
  TermType,
  TopicType,
  UserType,
} from "components/server-types";

export enum CourseUserActivityStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  OFFLINE = "offline",
}

export type UserWithCourseUserType = UserType & CourseUserType & { course_user_id: string };

export type UserWithCourseActivity = UserWithCourseUserType & {
  courseActivityStatus: CourseUserActivityStatus;
  pageId: string;
};

export type OrderedCourseType = CourseType & { order: number };

export type ObjectWithCourses<T extends TermType | CourseCodeType = TermType> = T & {
  courses: OrderedCourseType[];
};

export type ActivityWithPagesType = ActivityType & { pages: ActivityPageType[] };
export type TopicWithActivitiesType = TopicType & { activities: ActivityWithPagesType[] };

export type NestedOutcomeSubgroupType = OutcomeSubgroupType & {
  outcomes: OutcomeType[];
};

export type NestedOutcomeGroupType = OutcomeGroupType & {
  outcomes: OutcomeType[];
  outcome_subgroups: NestedOutcomeSubgroupType[];
  outcome_count: number;
};

export type NestedCourseOutcomeType = CourseOutcomeType & {
  outcome: OutcomeType;
};

export type NestedPageOutcomeType = PageOutcomeType & {
  course_outcome: CourseOutcomeType;
  outcome: OutcomeType;
};

export type NestedPageGroupUserType = PageGroupUserType & {
  user: UserWithCourseUserType;
};

export type NestedPageGroupType = PageGroupType & {
  page_group_users: NestedPageGroupUserType[];
};

export type FullRubricType = {
  name: string;
  score: number;
  description: string;
  usingPageRubric?: boolean;
};

export type PageWithDueDateType = PageType & { due_at: string };

export type FullSubmissionType = SubmissionType & {
  course_user?: CourseUserType;
  assessment?: AssessmentType;
  outcome_assessments?: OutcomeAssessmentType[];
};

export type NestedAssessmentType = AssessmentType & {
  submission: SubmissionType;
  outcome_assessments: OutcomeAssessmentType[];
};

export type PageWithAssessmentsType = PageType & {
  assessments: NestedAssessmentType[];
  nonOfficialAssessments: NestedAssessmentType[];
};

export type PagesWithOutcomeAssessmentType = PageType & {
  assessment: NestedAssessmentType;
  outcomeAssessment: OutcomeAssessmentType;
  nonOfficialAssessments: NestedAssessmentType[];
};
