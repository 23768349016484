import { FC } from "react";

import { Button } from "mds/components/Button";
import { ArrowDownIcon, RobotAIIcon } from "mds/icons";

type DownloadCSVButtonProps = {
  filename: string;
  downloadLink: string;
  disabled?: boolean;
};

export const DownloadCSVButton: FC<DownloadCSVButtonProps> = ({
  filename,
  downloadLink,
  disabled,
}) => {
  return (
    <Button
      className="h6 w-[160px] gap-2"
      disabled={disabled}
      download={filename}
      kind="secondary"
      target="_blank"
      title="Download CSV"
      to={downloadLink}
      iconOnly
    >
      <ArrowDownIcon />
    </Button>
  );
};

type GenerateCSVButtonProps = {
  onClick: () => void;
  disabled: boolean;
};

export const GenerateCSVButton: FC<GenerateCSVButtonProps> = ({ onClick, disabled }) => {
  return (
    <Button
      className="h6 w-[160px] gap-2"
      disabled={disabled}
      kind="secondary"
      size="xs"
      onClick={onClick}
    >
      <RobotAIIcon />
      <div>Run test</div>
    </Button>
  );
};
