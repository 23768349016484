import { FC } from "react";

import { AssessmentStatusChip } from "./AssessmentStatusChip";

import { DueDateButton } from "components/materials/page/DueDateButton";
import { AssessmentStatus, PageType } from "components/server-types";
import { useAppSelector } from "store/index";
import { selectCanAuthorCourse } from "store/selectors";
import { selectAssessmentStatusForPage } from "store/selectors/computed";

export type PageSubmissionStatusProps = {
  page: PageType;
  readOnly?: boolean;
};

export const PageSubmissionStatus: FC<PageSubmissionStatusProps> = ({ page, readOnly = false }) => {
  const assessmentStatus = useAppSelector((s) => selectAssessmentStatusForPage(s, page));
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);

  if (
    (canAuthorCourse && assessmentStatus === AssessmentStatus.ASSESSABLE) ||
    assessmentStatus === AssessmentStatus.ASSESSED
  ) {
    return <AssessmentStatusChip assessmentStatus={assessmentStatus} page={page} />;
  }

  if (page.due_at) {
    return (
      <DueDateButton
        kind="list-item"
        page={page}
        readOnly={!canAuthorCourse || readOnly}
        clickStopPropagation
        shortText
      />
    );
  }

  return null;
};
