import clsx from "clsx";
import { forwardRef } from "react";

import { Button, ButtonProps } from "mds/components/Button";
import { AddPlusIcon } from "mds/icons";

interface AddButtonProps {
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  title?: string;
  children?: React.ReactNode;
  size?: ButtonProps["size"];
  kind?: ButtonProps["kind"];
  disabled?: boolean;
}

export const AddButton = forwardRef<HTMLButtonElement, AddButtonProps>(
  ({ className, onClick, disabled, title, size = "xs", kind = "secondary", children }, ref) => (
    <Button
      className={clsx("flex items-center justify-center gap-2", className)}
      disabled={disabled}
      iconOnly={!children}
      kind={kind}
      ref={ref}
      size={size}
      title={title}
      onClick={onClick}
    >
      <AddPlusIcon />
      {children && <span>{children}</span>}
    </Button>
  ),
);

AddButton.displayName = "AddButton";
