import "./HoverableToolbar.scss";

import clsx from "clsx";
import { Children, FC, useCallback, useMemo, useState } from "react";

import { HoverableToolbarContext } from "./HoverableToolbarProvider";

import { AddIconButton, AddIconButtonProps } from "components/hover-widgets/AddIconButton";
import { EditIconButton, EditIconButtonProps } from "components/hover-widgets/EditIconButton";
import { useHoverable } from "mds/hooks/use-hoverable";
import { useAppSelector } from "store/index";
import { selectIsEditModeEnabled } from "store/selectors";

type HoverableToolbarProps = {
  className?: string;
  contentClassName?: string;
  uncentered?: boolean;
  reference?: AddIconButtonProps["reference"] | EditIconButtonProps["reference"];
  readOnly?: boolean;
  label?: string;
  children: React.ReactNode | [React.ReactNode, React.ReactNode];
  showEditButton?: boolean;
  disableUnlessEditMode?: boolean;
  onClickAdd?: () => void;
  onEditModeChange?: (isEditing: boolean) => void;
};

/*
 * A component used to display an editing toolbar when hovering over an element.
 * This component may need to work in conjunction with an editable field component
 * hence we use a context here to manage the editing state.
 */
export const HoverableToolbar: FC<HoverableToolbarProps> = ({
  className,
  contentClassName,
  label,
  reference,
  readOnly,
  children: childrenProp,
  disableUnlessEditMode = false,
  uncentered = false,
  onClickAdd,
  onEditModeChange,
  showEditButton,
}) => {
  const [isEditing, setIsEditingState] = useState(false);
  const [shouldStayOpen, setShouldStayOpen] = useState(/\btest\b/.test(contentClassName));
  const { isHovering, hoverParentProps } = useHoverable();
  const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);
  const shouldShowToolbar =
    !readOnly &&
    !isEditing &&
    (isHovering || shouldStayOpen) &&
    (!disableUnlessEditMode || isEditModeEnabled);
  const children = Children.toArray(childrenProp);

  // Using this function mainly to let callers know that we're editing
  // so they disable dragging and other functions we don't want while editing
  const setIsEditing = useCallback(
    (editing: boolean) => {
      setIsEditingState(editing);

      if (onEditModeChange) {
        onEditModeChange?.(editing);
      }
    },
    [setIsEditingState, onEditModeChange],
  );

  const contextValue = useMemo(() => {
    const setEditingIfAllowed = (editing: boolean) => {
      if (!readOnly) {
        setIsEditing(editing);
      }
    };

    return { isEditing, setIsEditing: setEditingIfAllowed, shouldStayOpen, setShouldStayOpen };
  }, [isEditing, setIsEditing, shouldStayOpen, setShouldStayOpen, readOnly]);

  const onClickEdit = () => {
    setIsEditing(true);
  };

  return (
    <HoverableToolbarContext.Provider value={contextValue}>
      {/* Using role=toolbar here to represent this as an object that generates a toolbar on hover
         Mainly we use this to simulate the hovering toolbar behavior in the tests */}
      <div
        className={clsx("hoverable-toolbar", className)}
        {...hoverParentProps}
        aria-label={label}
        role="toolbar"
      >
        <div className="relative flex w-full min-w-12 items-start justify-between">
          {children[0]}

          {shouldShowToolbar && (
            <div
              className={clsx(
                "hoverable-toolbar-content right-0 ml-2",
                { "hoverable-toolbar-uncentered": uncentered },
                contentClassName,
              )}
            >
              {onClickAdd && (
                <AddIconButton
                  reference={reference as AddIconButtonProps["reference"]}
                  onClick={onClickAdd}
                />
              )}

              {showEditButton && (
                <EditIconButton
                  reference={reference as EditIconButtonProps["reference"]}
                  onClick={onClickEdit}
                />
              )}

              {children.length > 1 && (
                <div
                  className="flex flex-row items-center justify-center"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {children.slice(1)}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </HoverableToolbarContext.Provider>
  );
};
