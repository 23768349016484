import "./PageDetailView.scss";

import { memo, useContext, useEffect, useState } from "react";

import { ErrorScreen, LoadingTextIndicator } from "components/LoadingScreen";
import { WAITED_TOO_LONG_TIMEOUT_MS } from "components/constants";
import { PageWorksheet } from "components/materials/page/PageWorksheet";
import { PageTabVariant } from "components/materials/page/helpers";
import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { DocViewContext } from "providers/DocViewProvider";
import { CONNECTION_STATUS_MESSAGE, WebsocketContext } from "providers/WebsocketProvider";
import { useCanLoadDoc, useDocContext } from "utils/worksheet";

type PageDetailViewProps = {
  page: PageType;
  selectedAccessId?: string;
  selectedTabVariant?: PageTabVariant;
  isProjecting?: boolean;
  isFeaturing?: boolean;
  isStaticContent?: boolean;
};

// TODO: Potentially merge parts of PageDetailView, PageOverview and PageWorksheet, since they
// all operate at the same level of abstraction, and add toolbars/headers/banners to the top or side
// of a worksheet. Preferably, all the added tabs/toolbars/headers/banners/sidebars would be separate
// components, reducing the code content inside PageDetailView/PageOverview/PageWorksheet, allowing
// us to combine the three into a single component.
export const PageDetailView = memo(
  ({
    page,
    selectedAccessId,
    selectedTabVariant,
    isFeaturing = false,
    isProjecting = false,
    isStaticContent = false,
  }: PageDetailViewProps) => {
    const { websocketStatus, retryConnection } = useContext(WebsocketContext);
    const [initError, setInitError] = useState<string>(null);
    const isConnected = websocketStatus === "connected";

    const canLoadDoc = useCanLoadDoc(page?.worksheet_id);

    const pageContext = useDocContext(
      page,
      selectedAccessId,
      selectedTabVariant,
      isProjecting,
      isFeaturing,
      isStaticContent,
    );

    useEffect(() => {
      if (canLoadDoc && isConnected && !pageContext.canWriteDoc) {
        const timeout = setTimeout(() => {
          setInitError("Timeout");
        }, WAITED_TOO_LONG_TIMEOUT_MS);
        return () => clearTimeout(timeout);
      }
    }, [canLoadDoc, isConnected, pageContext.canWriteDoc]);

    if (!isConnected) {
      return (
        <div className="worksheet-page">
          <div className="worksheet-page-padding h-full w-full">
            {["connecting", "disconnected"].includes(websocketStatus) ? (
              <LoadingTextIndicator text={CONNECTION_STATUS_MESSAGE[websocketStatus]} />
            ) : (
              <ErrorScreen
                retryText={t("worksheet.error.try_reconnect")}
                text={<div className="body-m">{CONNECTION_STATUS_MESSAGE[websocketStatus]}</div>}
                onRetry={() => {
                  retryConnection();
                  setInitError(null);
                }}
              />
            )}
          </div>
        </div>
      );
    }

    if (page.worksheet_id === undefined) {
      return <h3 className="m-4">There is an issue with this page. Please contact support.</h3>;
    }

    if (!canLoadDoc) {
      return <LoadingTextIndicator text={t("worksheet.loading.waiting_on_permissions")} />;
    }

    if (initError) {
      return (
        <div className="worksheet-page">
          <div className="worksheet-page-padding h-full w-full">
            <ErrorScreen
              retryText={t("worksheet.error.try_again")}
              text={<div className="body-m">{t("worksheet.error.waiting_on_permissions")}</div>}
              onRetry={() => {
                setInitError(null);
                retryConnection();
              }}
            />
          </div>
        </div>
      );
    }

    if (!pageContext.canWriteDoc) {
      return <LoadingTextIndicator text={t("worksheet.loading.waiting_on_permissions")} />;
    }

    return (
      <DocViewContext.Provider value={pageContext}>
        <PageWorksheet
          page={page}
          selectedAccessId={selectedAccessId}
          selectedTabVariant={selectedTabVariant}
        />
      </DocViewContext.Provider>
    );
  },
);

PageDetailView.displayName = "PageDetailView";
