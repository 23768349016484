import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { createReplaceAllReducer } from "store/utils";

const LOCAL_STATE_KEY = "monarch-local";

export type OnBoardingCourse = {
  id: string;
  isClosed: boolean;
  hasViewedGuide: boolean;
  hasImported: boolean;
  hasUpdatedTitleAndDescription: boolean;
  hasOutcome: boolean;
  hasTopic: boolean;
};

type OnBoardingCourses = {
  [id: string]: OnBoardingCourse;
};

type LocalState = {
  onBoardingCourses: OnBoardingCourses;
  isSidebarOpen: boolean;
  editMode: boolean;
  assessWithAIMode: boolean;
  isStudentResponsesForAIEnabled: boolean;
};

const defaultState: LocalState = {
  onBoardingCourses: {},
  isSidebarOpen: true,
  editMode: false,
  assessWithAIMode: false,
  isStudentResponsesForAIEnabled: false,
};

const retrieveLocalState = () => {
  const data = localStorage.getItem(LOCAL_STATE_KEY);
  return data ? (JSON.parse(data) as LocalState) : defaultState;
};

const saveLocalState = (state: LocalState) => {
  localStorage.setItem(LOCAL_STATE_KEY, JSON.stringify(state));
};

const deleteLocalState = () => {
  localStorage.removeItem(LOCAL_STATE_KEY);
};

const initialState = retrieveLocalState;

// TODO: add "saveLocalState" as a middleware for all reducers in this slice
export const LocalStore = createSlice({
  name: "local",
  initialState,
  reducers: {
    setOnBoardingCourse: (state, action: PayloadAction<OnBoardingCourse>) => {
      state.onBoardingCourses[action.payload.id] = action.payload;
      saveLocalState(state);
    },
    openSidebar: (state) => {
      state.isSidebarOpen = true;
      saveLocalState(state);
    },
    closeSidebar: (state) => {
      state.isSidebarOpen = false;
      saveLocalState(state);
    },
    toggleSidebarDisclosure: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
      saveLocalState(state);
    },
    enableEditMode: (state) => {
      state.editMode = true;
      saveLocalState(state);
    },
    disableEditMode: (state) => {
      state.editMode = false;
      saveLocalState(state);
    },
    toggleEditMode: (state) => {
      state.editMode = !state.editMode;
      saveLocalState(state);
    },
    enableAssessWithAIMode: (state) => {
      state.assessWithAIMode = true;
      saveLocalState(state);
    },
    disableAssessWithAIMode: (state) => {
      state.assessWithAIMode = false;
      saveLocalState(state);
    },
    toggleAssessWithAIMode: (state) => {
      state.assessWithAIMode = !state.assessWithAIMode;
      saveLocalState(state);
    },
    setStudentResponsesForAIEnabled: (state, action: PayloadAction<boolean>) => {
      state.isStudentResponsesForAIEnabled = action.payload;
      saveLocalState(state);
    },
  },
  extraReducers: createReplaceAllReducer<LocalState>("local", initialState, () => {
    deleteLocalState();
  }),
});

export const localStateActions = LocalStore.actions;
