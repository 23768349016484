import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const ExpandFullScreenIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M13.7273 1H2.27273C1.56982 1 1 1.59695 1 2.33333V11.6667C1 12.403 1.56982 13 2.27273 13H13.7273C14.4302 13 15 12.403 15 11.6667V2.33333C15 1.59695 14.4302 1 13.7273 1Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M3.54688 7.66602V10.3327H6.09233"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M9.9082 3.66602H12.4537V6.33268"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
