import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { getNavigationValues } from "../../../../utils/urls";

import { ACCESS_ID_QUERY_PARAM, SUBMISSION_ID_QUERY_PARAM } from "components/constants";
import { AssessWithAIModeButton } from "components/materials/page/assessment/AssessWithAIModeButton";
import { AssessmentGradingForm } from "components/materials/page/assessment/AssessmentGradingForm";
import { AssessmentGradingLastPanel } from "components/materials/page/assessment/AssessmentGradingLastPanel";
import { AssessmentGradingNav } from "components/materials/page/assessment/AssessmentGradingNav";
import { AssessmentGradingNoSubmission } from "components/materials/page/assessment/AssessmentGradingNoSubmission";
import { useResponsesForPage } from "components/materials/page/helpers";
import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { AssessPointsMarkerIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import { selectDefaultSubmissionForUser, selectFullSubmissionById } from "store/selectors";

interface AssessmentGradingViewProps {
  page: PageType;
}

export const AssessmentGradingView: FC<AssessmentGradingViewProps> = ({ page }) => {
  const [isNavigationDisabled, setIsIsNavigationDisabled] = useState(true);
  const [showAIGeneratedMsg, setShowAIGeneratedMsg] = useState(false);
  const [isAIRunning, setAIRunning] = useState(false);
  const [showLastGradingPanel, setShowLastGradingPanel] = useState(false);
  const [searchParams] = useSearchParams();

  const responses = useResponsesForPage(page);
  const { nextId, prevId, nextURL, prevURL } = getNavigationValues(
    responses.map((response) => response.id),
    {
      queryParam: ACCESS_ID_QUERY_PARAM,
      currentId: searchParams.get(ACCESS_ID_QUERY_PARAM),
    },
  );

  const submissionForNextUser = useAppSelector((s) =>
    selectDefaultSubmissionForUser(s, page, nextId),
  );
  const submissionForPrevUser = useAppSelector((s) =>
    selectDefaultSubmissionForUser(s, page, prevId),
  );
  nextURL?.searchParams?.set(SUBMISSION_ID_QUERY_PARAM, submissionForNextUser?.id);
  prevURL?.searchParams?.set(SUBMISSION_ID_QUERY_PARAM, submissionForPrevUser?.id);

  const submissionId = searchParams.get(SUBMISSION_ID_QUERY_PARAM);
  const submission = useAppSelector((s) => selectFullSubmissionById(s, submissionId));

  const onIsEditingAssessment = (isEditingAssessment: boolean) => {
    setIsIsNavigationDisabled(isEditingAssessment);
  };

  useEffect(() => {
    setShowLastGradingPanel(false);
  }, [submissionId]);

  // There are three different views in the grading view: the grading form, the no submission view, and the last panel.
  return (
    <div className="flex min-h-full w-full flex-col gap-4 rounded-2xl border border-black-tint-90 px-5 py-4">
      <div className="flex flex-row items-center justify-between">
        <h3 className="flex flex-row items-center gap-2">
          <AssessPointsMarkerIcon className="icon-green" />
          {t("glossary.assessment")}
        </h3>
        <AssessmentGradingNav
          isLastPanel={showLastGradingPanel}
          isNavigationDisabled={isNavigationDisabled}
          nextURL={nextURL}
          page={page}
          prevURL={prevURL}
          setShowLastPanel={setShowLastGradingPanel}
        />
      </div>
      {showLastGradingPanel ? (
        <AssessmentGradingLastPanel page={page} setShowLastPanel={setShowLastGradingPanel} />
      ) : !submission ? (
        <AssessmentGradingNoSubmission
          nextURL={nextURL}
          page={page}
          setShowLastPanel={setShowLastGradingPanel}
        />
      ) : (
        <>
          <AssessWithAIModeButton disabled={isAIRunning} showAIGeneratedMsg={showAIGeneratedMsg} />
          <AssessmentGradingForm
            isAIRunning={isAIRunning}
            nextId={nextId}
            nextURL={nextURL}
            page={page}
            setAIRunning={setAIRunning}
            setShowAIGeneratedMsg={setShowAIGeneratedMsg}
            setShowLastPanel={setShowLastGradingPanel}
            submissionForNextUser={submissionForNextUser}
            onIsEditingAssessment={onIsEditingAssessment}
          />
        </>
      )}
    </div>
  );
};
