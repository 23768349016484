import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const CircleArrowRightIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <g clipPath="url(#clip0_260_53)">
      <path
        className="stroke"
        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M11.1213 8.12132L8 11.2426M11.1213 8.12132L8 5M11.1213 8.12132H4.87868"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_260_53">
        <rect fill="white" height="16" width="16" />
      </clipPath>
    </defs>
  </svg>
);
