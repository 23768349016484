import type { FC } from "react";

import { OUTCOME_ID_QUERY_PARAM } from "components/constants";
import { HoverableToolbar } from "components/hover-widgets/HoverableToolbar";
import { SidebarOptionsMenu } from "components/menus/SidebarOptionsMenu";
import { AddOutcome } from "components/outcomes/AddOutcome";
import { displayOutcomeName, outcomesToOptions } from "components/outcomes/helpers";
import { t } from "i18n/i18n";
import { ListItem } from "mds/components/ListItem";
import { storeApi, useAppSelector } from "store/index";
import {
  NestedCourseOutcomeType,
  selectCanAuthorCourse,
  selectFullCourseOutcomes,
  selectOutcomes,
} from "store/selectors";

// TODO: When course-only outcomes are implemented, we should add it to this list
// eslint-disable-next-line react-refresh/only-export-components
export const useOrgOutcomeListForCourse = (courseId: string) => {
  const courseOutcomes = useAppSelector((s) => selectFullCourseOutcomes(s, courseId));
  const filteredCourseOutcomes = courseOutcomes.filter((courseOutcome) => !!courseOutcome.outcome);
  return filteredCourseOutcomes.map((courseOutcome) => courseOutcome.outcome);
};

const urlFromCourseOutcome = (courseOutcome: NestedCourseOutcomeType) => {
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set(OUTCOME_ID_QUERY_PARAM, courseOutcome.outcome.id);
  return currentUrl.toString();
};

type OutcomesNavSidebarProps = {
  selectedOutcome?: string;
  courseId: string;
};

export const OutcomesNavSidebar: FC<OutcomesNavSidebarProps> = ({ selectedOutcome, courseId }) => {
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);

  const courseOutcomes = useAppSelector((s) => selectFullCourseOutcomes(s, courseId));
  const orgOutcomes = useAppSelector(selectOutcomes);
  // TODO: When course-only outcomes are implemented, we should add it to this list
  const filteredCourseOutcomes = courseOutcomes.filter((courseOutcome) => !!courseOutcome.outcome);

  const courseOutcomeIds = courseOutcomes.map((courseOutcome) => courseOutcome.outcome_id);
  const selectableOutcomes = orgOutcomes.filter(
    (orgOutcome) => !courseOutcomeIds.includes(orgOutcome.id),
  );

  const onDelete = (outcomeId: string) => {
    storeApi.course_outcomes.destroy(outcomeId);
  };

  const options = outcomesToOptions(selectableOutcomes);

  return (
    <div className="w-full border-r border-black-tint-95 bg-white px-4 py-3 md:w-auto md:basis-[240px]">
      <h3 className="my-2">{t("glossary.outcomes")}</h3>
      {!canAuthorCourse && filteredCourseOutcomes.length === 0 && (
        <div className="body-s w-full py-2 text-black-tint-40">
          {t("outcomes.no_course_outcomes")}
        </div>
      )}
      {filteredCourseOutcomes.map((courseOutcome) => (
        <ListItem
          className="w-full pl-0"
          isSelected={courseOutcome.outcome.title === selectedOutcome}
          key={courseOutcome.outcome.title}
          to={urlFromCourseOutcome(courseOutcome)}
        >
          <HoverableToolbar className="w-full" readOnly={!canAuthorCourse}>
            <div className="body-s my-1 w-full cursor-pointer truncate px-2">
              {displayOutcomeName(courseOutcome.outcome)}
            </div>
            <SidebarOptionsMenu
              buttonKind="tertiary"
              optionsMenuActions={{
                onDelete: () => onDelete(courseOutcome.id),
              }}
            />
          </HoverableToolbar>
        </ListItem>
      ))}
      {canAuthorCourse && (
        <AddOutcome
          options={options}
          onSave={(orgOutcomeIds: string[]) => {
            orgOutcomeIds.forEach((orgOutcomeId) => {
              storeApi.course_outcomes // TODO: Bulk create
                .create({ course_id: courseId, outcome_id: orgOutcomeId });
            });
          }}
        />
      )}
    </div>
  );
};
