import { get } from "lodash";
import { useEffect, useMemo } from "react";

import type { DocViewContextType } from "../providers/DocViewProvider";
import {
  GLOBAL_SHARED_ACCESS_ID,
  INSTRUCTOR_SHARED_ACCESS_ID,
  getGroupIdFromAccessId,
  getStudentIdFromAccessId,
  groupAccessIdFromGroupId,
  isGroupAccessId,
  isStudentAccessId,
  studentAccessIdFromUserId,
} from "../worksheets/shared/access-id";
import { DATA_PATH } from "../worksheets/shared/constants";
import { WorksheetType } from "../worksheets/shared/types/worksheet";
import { createId } from "../worksheets/shared/utils";

import { rollbarAndLogError } from "./logger";

import { WAITED_TOO_LONG_TIMEOUT_MS } from "components/constants";
import { isGroupingCategory, isSharedClassPage } from "components/materials/page/groups/helpers";
import { PageTabVariant, isPastSubmissionDueDate } from "components/materials/page/helpers";
import { GroupingCategory, PageType, UserType } from "components/server-types";
import { useWorksheet } from "providers/ShareDBDoc";
import { useAppSelector } from "store/index";
import {
  selectCanAuthorCourse,
  selectCanAuthorOrg,
  selectCollaborationPermissionsForDoc,
  selectCurrentUserId,
  selectCurrentUserIsMPAdmin,
  selectIsAssessing,
  selectIsEditModeEnabled,
  selectIsStudentPreview,
  selectIsTimeline,
  selectPageGroupForReadyUser,
  selectReadiedUsersInPageGroup,
  selectUsersInCurrentCourse,
} from "store/selectors";
import { useListSelector } from "store/store-hooks";

export const blankInstructorNotesWorksheetData = () =>
  ({
    b: [
      {
        t: "Instructions" as const,
        id: createId(),
        f: [
          {
            t: "Text" as const,
            id: createId(),
            v: {},
          },
        ],
      },
    ],
  }) as Partial<WorksheetType>;

// This indicates the group/user/mode that the user is trying to read/write from.
const calculateAccessId = (
  page: PageType,
  canAuthor: boolean,
  userId: string,
  pageGroupId?: string,
) => {
  const groupingCategory = page?.grouping_category;
  if (groupingCategory === GroupingCategory.SHARED_CLASS) {
    return GLOBAL_SHARED_ACCESS_ID;
  }

  if (canAuthor) {
    return INSTRUCTOR_SHARED_ACCESS_ID;
  }

  if (isGroupingCategory(groupingCategory)) {
    return groupAccessIdFromGroupId(pageGroupId);
  }

  return studentAccessIdFromUserId(userId);
};

/** Decode an access id and return the corresponding list of User objects, or null if there is an error. */
export const useUsersFromAccessId = (accessId: string, page?: PageType): UserType[] => {
  const userId = isStudentAccessId(accessId) ? getStudentIdFromAccessId(accessId) : null;
  const pageGroupId = isGroupAccessId(accessId) ? getGroupIdFromAccessId(accessId) : null;
  const usersFromCourse = useListSelector(selectUsersInCurrentCourse);
  const usersFromPageGroup = useListSelector((s) => selectReadiedUsersInPageGroup(s, pageGroupId));

  if (userId) {
    // If the indicated User can be found in the current CourseUser data, return that User.
    const user = usersFromCourse.find((u) => u.id === userId);
    if (user) {
      return [user];
    }

    // If the current Page was imported from another environment, compensate for missing
    // CourseUsers by returning a fake User object.
    if (page?.is_imported) {
      return [{ id: userId, first_name: "", last_name: "", email: "", is_superuser: false }];
    }
  }

  if (pageGroupId) {
    // If a set of Users can be determined from the current set of PageGroupUsers, return those Users.
    if (usersFromPageGroup.length > 0) {
      return usersFromPageGroup;
    }

    // If the current page was imported from another environment, compensate for missing
    // PageGroupUsers by returning a fake User object.
    if (page?.is_imported) {
      return [
        {
          id: pageGroupId,
          first_name: "Group",
          last_name: pageGroupId,
          email: "",
          is_superuser: false,
        },
      ];
    }
  }

  // TODO: maybe raise an error in this case instead of returning null?
  return null;
};

export const useCanLoadDoc = (docId: string) => {
  const userId = useAppSelector(selectCurrentUserId);
  const isMpAdmin = useAppSelector(selectCurrentUserIsMPAdmin);
  const isStudentPreview = useAppSelector(selectIsStudentPreview);
  const canAuthorOrg = useAppSelector(selectCanAuthorOrg);
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const permissionsForThisPage = useAppSelector((s) =>
    selectCollaborationPermissionsForDoc(s, docId),
  );

  const canLoadDoc =
    isMpAdmin || canAuthorOrg || canAuthorCourse || isStudentPreview || permissionsForThisPage;

  // We want to track how often users run into infinite (or unreasonably long) loading states
  useEffect(() => {
    if (!canLoadDoc) {
      const timeout = setTimeout(() => {
        rollbarAndLogError("canLoadDoc never resolved to true", {
          docId,
          userId,
          canAuthorCourse,
          hasPermissions: Boolean(permissionsForThisPage),
        });
      }, WAITED_TOO_LONG_TIMEOUT_MS);
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canLoadDoc]);

  if (!docId) {
    return false;
  }

  return canLoadDoc;
};

export const useCanWriteDoc = (
  docId: string,
  accessId: string,
  selectedPageTabVariant: PageTabVariant,
) => {
  const isMpAdmin = useAppSelector(selectCurrentUserIsMPAdmin);
  const isStudentPreview = useAppSelector(selectIsStudentPreview);
  const canAuthorOrg = useAppSelector(selectCanAuthorOrg);
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const permissionsForThisPage = useAppSelector((s) =>
    selectCollaborationPermissionsForDoc(s, docId),
  );

  // We need both canAuthorOrg and canAuthorCourse in here, as org admins that are course students
  // do still always have view permission to all pages, but canAuthorCourse will be false for them.
  const canAlwaysSeeDoc =
    isMpAdmin ||
    canAuthorOrg ||
    canAuthorCourse ||
    isStudentPreview ||
    permissionsForThisPage?.rollup;

  const isGroupAndCanSeeGroup =
    isGroupAccessId(accessId) &&
    permissionsForThisPage?.group_ids.includes(getGroupIdFromAccessId(accessId));

  const isViewingResponsesTab = selectedPageTabVariant === "responses";

  // Note that this doesn't cover the specific case of e.g. trying to show a different student's
  // access ID on an individual page, without the rollup permissions. We don't currently have that
  // case, but if we did, we'd need to add a check here that the user has the specific access ID
  // they're trying to look up.
  const isOtherAndCanSeePage =
    !isGroupAccessId(accessId) && !isViewingResponsesTab && permissionsForThisPage;

  const canWriteDoc = Boolean(canAlwaysSeeDoc || isGroupAndCanSeeGroup || isOtherAndCanSeePage);

  // We want to track how often users run into infinite (or unreasonably long) loading states
  useEffect(() => {
    if (!canWriteDoc) {
      const timeout = setTimeout(() => {
        rollbarAndLogError("canWriteDoc never resolved to true", {
          docId,
          accessId,
          selectedPageTabVariant,
          isMpAdmin,
          isStudentPreview,
          canAuthorOrg,
          canAuthorCourse,
          permissions: JSON.stringify(permissionsForThisPage),
        });
      }, WAITED_TOO_LONG_TIMEOUT_MS);
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canWriteDoc]);

  return canWriteDoc;
};

export const useDocContext = (
  page: PageType,
  accessIdOverride?: string,
  selectedPageTabVariant?: PageTabVariant,
  isProjecting?: boolean,
  isFeaturing?: boolean,
  isStaticContent?: boolean,
): DocViewContextType => {
  const docId = page?.worksheet_id;
  const pageLocked = Boolean(page?.locked_at);
  const pageReleased = Boolean(page?.released_at);

  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const user_id = useAppSelector(selectCurrentUserId);
  const pageGroup = useAppSelector((s) => selectPageGroupForReadyUser(s, page?.id, user_id));
  const isAssessing = useAppSelector(selectIsAssessing);
  const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);
  const isTimeline = useAppSelector(selectIsTimeline);

  const groupingCategory = page?.grouping_category;
  const lockedForStudent = pageLocked && !canAuthorCourse;
  const isViewingResponsesTab = selectedPageTabVariant === "responses";
  const isViewingSeededContentTab = selectedPageTabVariant === "seeded_content";
  const isViewingMySubmissionsTab = selectedPageTabVariant === "my_submission";

  // The accessIdOverride may be an empty string (not referencing a pageGroup or user) when we're
  // showing All Responses on rollup view.  Note: we are also telling the page to show
  // seeded content when featuring to students or they are viewing the template path by not
  // providing an accessId.
  const accessId =
    isViewingResponsesTab || isAssessing
      ? accessIdOverride
      : (isViewingSeededContentTab || isFeaturing) && !canAuthorCourse && !isSharedClassPage(page)
        ? undefined
        : calculateAccessId(page, canAuthorCourse, user_id, pageGroup?.id);

  const accessIdPath = useMemo(() => (accessId ? [DATA_PATH, accessId] : [DATA_PATH]), [accessId]);

  const { doc } = useWorksheet(docId);
  const isDone = Boolean(get(doc?.data, [...accessIdPath, "done"]));

  const readOnly =
    !accessId ||
    isTimeline ||
    isViewingResponsesTab ||
    lockedForStudent ||
    isAssessing ||
    isViewingSeededContentTab ||
    isViewingMySubmissionsTab ||
    isProjecting ||
    // Students can't edit featured blocks (but instructors can)
    (isFeaturing && !canAuthorCourse) ||
    // Pages can't be edited if they are marked as done, unless they're past their submission due date,
    // as being past due disables the mark done functionality completely
    (isDone && !isPastSubmissionDueDate(page));

  const canAuthorPage = !isAssessing && !isViewingResponsesTab && !isProjecting && canAuthorCourse;
  // We want students to be able to interact with pages that aren't read-only, and instructors can do the
  // same on shared class pages. We make an exception for Projector Views (that are always read-only) to
  // also set this value to ensure the projector displays the collaborated data.
  const canInteractAsStudent =
    (!readOnly || isProjecting) &&
    (groupingCategory === GroupingCategory.SHARED_CLASS || !canAuthorCourse);
  const isAuthoringPage = canAuthorPage && !readOnly && isEditModeEnabled;
  const pageId = page.id;

  const canWriteDoc = useCanWriteDoc(docId, accessId, selectedPageTabVariant);

  const showResponses = isViewingResponsesTab ? (accessId ? "ONE" : "ALL") : false;

  const context = useMemo(
    () => ({
      readOnly,
      accessId,
      isFeaturing,
      isStaticContent,
      pageId,
      canAuthorPage,
      canInteractAsStudent,
      isAuthoringPage,
      isProjecting,
      canWriteDoc,
      isReleased: pageReleased,
      isViewingSeededContentTab,
      showResponses: showResponses as DocViewContextType["showResponses"],
    }),
    [
      canAuthorPage,
      canInteractAsStudent,
      isAuthoringPage,
      readOnly,
      canWriteDoc,
      pageId,
      isProjecting,
      isFeaturing,
      isStaticContent,
      accessId,
      pageReleased,
      isViewingSeededContentTab,
      showResponses,
    ],
  );

  return context;
};
