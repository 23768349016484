import clsx from "clsx";
import { CSSProperties, FC, ReactNode, RefObject, useContext } from "react";

import { HoverableToolbarContext } from "components/hover-widgets/HoverableToolbarProvider";
import { DownloadUploadOptions } from "components/menus/DownloadUploadOptions";
import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button, ButtonProps } from "mds/components/Button";
import { IconText } from "mds/components/IconText";
import {
  Menu,
  type MenuChangeEvent,
  MenuDirection,
  MenuDivider,
  MenuInstance,
  MenuItem,
} from "mds/components/Menu";
import { DotMenuVerticalIcon, DuplicateCopyIcon, TrashDeleteIcon } from "mds/icons";

// This is the base component for SideBarOptionsMenu.  It is used in the other components
// that extend it for different models ex: Course, Topic, Activity, Resource.
export type SidebarOptionsMenuProps = {
  styles?: CSSProperties;
  size?: ButtonProps["size"];
  className?: string;
  buttonKind?: ButtonProps["kind"];
  direction?: MenuDirection;
  children?: ReactNode;
  instanceRef?: RefObject<MenuInstance>;
  optionsMenuActions?: {
    onDelete?: () => void;
    isDeleteDisabled?: boolean;
    isDuplicateDisabled?: boolean;
    deleteTooltipText?: string;
    onDuplicate?: () => void;
    showDownloadUploadOptions?: boolean;
    page?: PageType;
  };
};

export const SidebarOptionsMenu: FC<SidebarOptionsMenuProps> = ({
  styles,
  size = "s",
  className,
  buttonKind = "secondary",
  direction = "right",
  children = null,
  optionsMenuActions,
  instanceRef = null,
}) => {
  // TODO: This should be tertiary, but it needs to handle the `active` styling with blue
  // background that comes from the parent `ListItem`.
  const MenuButton = (
    <Button
      className={clsx("p-2", className)}
      kind={buttonKind}
      size={size}
      title={t("common.options")}
      iconOnly
      onClick={(e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <DotMenuVerticalIcon />
    </Button>
  );

  const hoverableContext = useContext(HoverableToolbarContext);

  const onMenuChange = (event: MenuChangeEvent) => {
    hoverableContext?.setShouldStayOpen?.(event.open);
  };

  return (
    <Menu
      direction={direction}
      instanceRef={instanceRef}
      menuButton={MenuButton}
      menuClassName="min-w-[180px]"
      menuStyle={styles}
      onMenuChange={onMenuChange}
    >
      {children}
      {optionsMenuActions?.onDuplicate && (
        <MenuItem
          disabled={optionsMenuActions?.isDuplicateDisabled}
          onClick={optionsMenuActions.onDuplicate}
        >
          <IconText iconStart={<DuplicateCopyIcon />} text={t("sidebar_options_menu.duplicate")} />
        </MenuItem>
      )}
      {optionsMenuActions?.showDownloadUploadOptions && (
        <>
          <MenuDivider />
          <DownloadUploadOptions page={optionsMenuActions.page} />
        </>
      )}
      {optionsMenuActions?.onDelete && (
        <>
          {Object.keys(optionsMenuActions).length > 1 && <MenuDivider />}
          {/* TODO: The disabled state for the Delete action should be greyed out instead of hoverable & red. */}
          <MenuItem
            className="icon-red"
            disabled={optionsMenuActions.isDeleteDisabled}
            kind="destructive"
            title={optionsMenuActions.deleteTooltipText}
            onClick={optionsMenuActions.onDelete}
          >
            <IconText iconStart={<TrashDeleteIcon />} text={t("common.delete")} />
          </MenuItem>
        </>
      )}
    </Menu>
  );
};
