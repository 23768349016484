import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const CloudDisconnectedIcon: FC<IconProps> = (props) => {
  return (
    <svg {...initializeSvgIconProps(props)}>
      <path
        className="stroke"
        d="M 6.875 6.484375 C 6.489258 6.513672 6.079102 6.621094 5.664062 6.850586 C 5.585938 6.894531 5.507812 6.938477 5.429688 6.992188 C 4.140625 7.84668 4.140625 9.135742 4.140625 9.707031 C 2.998047 9.707031 1.425781 10.136719 1.425781 12.133789 C 1.425781 13.564453 2.856445 14.277344 4.140625 14.277344 C 4.96582 14.277344 12.612305 14.277344 14.677734 14.277344 M 17.788086 13.540039 C 18.188477 13.115234 18.427734 12.553711 18.427734 11.992188 C 18.427734 9.990234 16.99707 9.418945 15.854492 9.418945 C 16.044922 8.515625 16.000977 6.59668 13.999023 5.419922 C 12.480469 4.526367 10.932617 4.804688 9.838867 5.50293 "
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="4"
        strokeWidth="1.25"
        transform="matrix(0.8,0,0,0.8,0,0)"
      />
      <path
        className="stroke"
        d="M 5.571289 4.858398 L 16.000977 15.288086 "
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="4"
        strokeWidth="1.25"
        transform="matrix(0.8,0,0,0.8,0,0)"
      />
    </svg>
  );
};
