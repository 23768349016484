import clsx from "clsx";
import { Link } from "react-router-dom";

import { INDEX_NOT_FOUND } from "components/constants";
import { PageItemStatusIcon } from "components/materials/page/PageItemStatusIcon";
import { STATUS_COLORS, topicForPage } from "components/materials/page/helpers";
import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { useOrdered } from "mds/hooks/use-ordered";
import { useAppSelector } from "store/index";
import {
  selectActivities,
  selectCurrentCourse,
  selectVisibleTopicsForCurrentCourse,
} from "store/selectors";
import { useListSelector } from "store/store-hooks";
import { courseContentUrl, coursePageUrl } from "utils/urls";

/**
 * For a topic pages, shows "Topic {index}: {title}".
 * For other pages, shows "{title}".
 * Links directly to the page.
 *
 * kind="link" shows blue, smaller text (used on assessment dashboards)
 * kind+"text" shows black, larger text (used in sidebar and "assigned pages" lists)
 */
export const PageLink = ({
  page,
  withIcon,
  kind = "link",
}: {
  page: PageType;
  kind?: "link" | "text";
  withIcon?: boolean;
}) => {
  const course = useAppSelector(selectCurrentCourse);
  const visibleTopics = useOrdered(useListSelector(selectVisibleTopicsForCurrentCourse));
  const activities = useListSelector(selectActivities);

  const isTopicPage = page.category === "activity_page";
  const topic = isTopicPage && topicForPage(visibleTopics, activities, page);
  const topicIndex = visibleTopics.indexOf(topic);
  const topicIndexString = topicIndex === INDEX_NOT_FOUND ? "?" : topicIndex + 1;

  const topicPrefix = isTopicPage ? `${t("glossary.topic")} ${topicIndexString}: ` : "";

  // TODO Preload these via getUrlForPage?
  const href = isTopicPage
    ? courseContentUrl(course.id, topic?.id, page.id)
    : coursePageUrl(course.id, page.category, page.id);

  const kindStyles = kind === "text" ? "unlink text-black-tint-20 body-s" : "body-xs";

  return (
    <div className="flex min-w-12 items-center justify-start gap-2">
      {withIcon && (
        <PageItemStatusIcon
          color={page.released_at ? STATUS_COLORS.released : STATUS_COLORS.unreleased}
        />
      )}
      <Link className={clsx("page-title block", kindStyles)} to={href}>
        {Boolean(topic) && topicPrefix}
        {page.title}
      </Link>
    </div>
  );
};
