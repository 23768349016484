import clsx from "clsx";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { LoadingDotsIcon, MinervaLogoIcon } from "mds/icons";

export const LoadingScreen = ({ text }: { text?: string }) => (
  <div className="h-full w-full">
    <div className="flex h-full w-full flex-col items-center justify-center gap-3">
      {text || (
        <>
          <MinervaLogoIcon className="animate-pulse" height="96" width="96" />
          <div className="h5">{t("common.loading_ellipsis")}</div>
        </>
      )}
    </div>
  </div>
);

export const LoadingTextIndicator = ({
  text,
  className,
  height,
}: {
  text: string | ReactNode;
  className?: string;
  height?: number;
}) => (
  <div className={clsx("mt-4", className)}>
    <div
      className="flex flex-col items-center justify-center gap-2"
      style={height ? { height } : {}}
    >
      <div>{text}</div>
      <LoadingDotsIcon />
    </div>
  </div>
);

export const ErrorScreen = ({
  text,
  className,
  retryText,
  onRetry,
}: {
  text?: string | ReactNode;
  className?: string;
  retryText?: string;
  onRetry?: () => void;
}) => {
  const buttonText = retryText || t("error.return_home");
  return (
    <div className={clsx(className, "flex w-full flex-col items-center justify-center gap-2")}>
      <div className="h2">{text || t("error.generic_message")}</div>
      {onRetry ? (
        <Button kind="secondary" onClick={onRetry}>
          {buttonText}
        </Button>
      ) : (
        <Link to="/">{t("error.return_home")}</Link>
      )}
    </div>
  );
};
