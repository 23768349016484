import "../CourseDetailView.scss";

import { ChangeEvent, FC, useRef } from "react";

import { serverImportExternalCourse } from "api/api-server";
import { LoadingScreen } from "components/LoadingScreen";
import { FullCourseType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { TextField } from "mds/components/TextField";
import { storeApi, useAppSelector } from "store/index";
import { selectOrgs } from "store/selectors";
import { toastLocalizedOperationCatcher, toastSuccessMessage } from "utils/alerts";

type OrgSettingsMainProps = {
  courses: FullCourseType[];
};

export const OrgSettingsMain: FC<OrgSettingsMainProps> = ({ courses }) => {
  const orgs = useAppSelector(selectOrgs);

  const currentOrg = orgs.find((org) => org.current === true);
  const fileInput = useRef<HTMLInputElement>(null);

  if (!courses || !currentOrg) {
    return <LoadingScreen text={currentOrg && t("org.settings.no_courses")} />;
  }

  const handleNameChange = (name: string) => {
    storeApi.orgs.partial_update({ id: currentOrg.id, name });
  };

  const onImportCourse = async (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();

    const file = event.target.files[0];

    const formData = new FormData();
    formData.append("data", file);

    const id = await serverImportExternalCourse(formData);

    if (id) {
      toastSuccessMessage(t("org.settings.import_course_success"));
    }
  };

  return (
    <div>
      <label>
        <span className="h6 text-black-tint-40">{t("org.settings.institution_name")}</span>
        <TextField
          value={currentOrg?.name}
          wrapperClassName="flex justify-start items-center"
          isEditing
          onChange={(event) => handleNameChange(event.target.value)}
        />
      </label>

      <div className="flex w-full items-center gap-2 pb-3">
        <Button
          className="mt-2"
          kind="secondary"
          size="s"
          onClick={() => fileInput.current?.click()}
        >
          {t("org.settings.import_course")}
        </Button>

        <input
          className="invisible w-0"
          ref={fileInput}
          type="file"
          onChange={(event) => {
            onImportCourse(event).catch(toastLocalizedOperationCatcher("import_course_failure"));
          }}
        />
      </div>
    </div>
  );
};
