import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const CalendarIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M11.5837 1.87502V3.04169V3.55491H11.417V3.04169V1.87502C11.417 1.85292 11.4258 1.83172 11.4414 1.81609C11.457 1.80047 11.4782 1.79169 11.5003 1.79169C11.5224 1.79169 11.5436 1.80047 11.5593 1.81609C11.5749 1.83172 11.5837 1.85292 11.5837 1.87502Z"
    />
    <path
      className="stroke fill"
      d="M4.58366 1.93335V3.21669V3.52967H4.41699V3.21669V1.93335C4.41699 1.88122 4.43626 1.83968 4.45782 1.81596C4.47793 1.79384 4.49444 1.79169 4.50033 1.79169C4.50622 1.79169 4.52272 1.79384 4.54283 1.81596C4.56439 1.83968 4.58366 1.88122 4.58366 1.93335Z"
    />
    <path
      className="fill"
      d="M13.25 14.7083H2.75C2.28587 14.7083 1.84075 14.524 1.51256 14.1958C1.18437 13.8676 1 13.4225 1 12.9583L1 5.375C1 4.91087 1.18437 4.46575 1.51256 4.13756C1.84075 3.80937 2.28587 3.625 2.75 3.625H13.25C13.7141 3.625 14.1592 3.80937 14.4874 4.13756C14.8156 4.46575 15 4.91087 15 5.375V12.9583C15 13.4225 14.8156 13.8676 14.4874 14.1958C14.1592 14.524 13.7141 14.7083 13.25 14.7083ZM13.8333 6.54167H2.16667V12.9583C2.16667 13.113 2.22812 13.2614 2.33752 13.3708C2.44692 13.4802 2.59529 13.5417 2.75 13.5417H13.25C13.4047 13.5417 13.5531 13.4802 13.6625 13.3708C13.7719 13.2614 13.8333 13.113 13.8333 12.9583V6.54167Z"
    />
    <path className="fill" d="M5.66634 7.70837H3.33301V9.45837H5.66634V7.70837Z" />
    <path className="fill" d="M9.16634 7.70837H6.83301V9.45837H9.16634V7.70837Z" />
    <path className="fill" d="M5.66634 10.625H3.33301V12.375H5.66634V10.625Z" />
    <path className="fill" d="M9.16634 10.625H6.83301V12.375H9.16634V10.625Z" />
    <path className="fill" d="M12.6663 7.70837H10.333V9.45837H12.6663V7.70837Z" />
  </svg>
);
