import { isEqual } from "lodash";
import { useCallback, useContext, useEffect, useState } from "react";
import { ShareDBSourceOptions } from "sharedb";
import { Callback, Doc, Op } from "sharedb/lib/client";

import { createIncrementVersionQueue } from "../worksheets/field-versioning";
import { AuthError, checkUserCanPerformOperation } from "../worksheets/shared/auth";
import {
  AUTH_ERRORS,
  PagePermissionType,
  WebsocketErrorType,
} from "../worksheets/shared/types/websocket-auth";
import { WorksheetType } from "../worksheets/shared/types/worksheet";

import { WebsocketContext } from "./WebsocketProvider";

import { store, useAppDispatch, useAppSelector } from "store/index";
import { selectCollaborationPermissionsForDoc } from "store/selectors";
import { viewStateActions } from "store/slices/view";
import { rollbarAndLogError, rollbarAndLogWarning } from "utils/logger";

// This sets how many listeners we can register for a single EventEmitter.
// Doc and DocPresence are both EventEmitters, and the default is 10.
// If the max number is exceeded, we just get a warning, nothing breaks.
// For our implementation, each Field listens to the doc individually,
// so we expect this number to be quite a lot higher than the default.
// The current number is chosen so that we likely get a warning if something actually goes
// wrong, e.g. listeners get added 5-10x more often than expected.
export const MAX_DOC_LISTENERS = 256;

export const docCache: Record<string, Doc<WorksheetType>> = {};
const cacheCallbacks: Record<string, Set<() => void>> = {};

const cacheHasNoListeners = (docId: string) => {
  const callbacks = cacheCallbacks[docId];
  return !callbacks || callbacks.size === 0;
};

/**
 * We use a small custom event emitter system to allow multiple components to listen to changes
 * originating from a single ShareDB document, without re-running side effects.
 */
const triggerDocUpdate = (docId: string) => {
  const callbacks = cacheCallbacks[docId];
  if (callbacks) {
    callbacks.forEach((cb) => cb());
  }
};

const listenToDocUpdates = (docId: string, callback: () => void) => {
  if (!cacheCallbacks[docId]) {
    cacheCallbacks[docId] = new Set();
  }
  const callbacks = cacheCallbacks[docId];
  callbacks.add(callback);
  return () => {
    callbacks.delete(callback);
  };
};

const checkOpPermissions = (doc: Doc<WorksheetType>, docOp: Op | Op[]) => {
  const state = store.getState();
  const userData = state.auth.websocket;
  try {
    const ops = Array.isArray(docOp) ? docOp : [docOp];

    for (const op of ops) {
      // Check if we have permissions
      checkUserCanPerformOperation(userData, op, doc.id, doc.data, true);
    }
  } catch (err) {
    rollbarAndLogError("Failed client side op permission check", err, {
      op: JSON.stringify(docOp),
      userAllowedDocs: Object.keys(userData?.pages || {}).join(","),
      docId: doc.id,
    });
    // We ignore overwrite errors, as we assume the remote state (where the data exists),
    // is the correct one, and will be synced to the local state soon.
    if (!(err instanceof AuthError) || err.message !== AUTH_ERRORS.OVERWRITE_DISALLOWED) {
      throw err;
    }
  }
};

// If we want to refresh the doc because e.g. the permissions changed on the backend,
// but the doc is already cached by ShareDB, we need to fetch and re-ingest the data manually.
// Just calling connection.get(docId) will return the locally cached version.
export const forceRefreshDoc = (docId: string, callback?: (err?: Error) => void) => {
  const cachedDoc = docCache[docId];
  if (!cachedDoc) return;
  const docPrototype = cachedDoc as unknown as {
    _setType: (type: string) => void;
    version: number;
    _fetch: (options: { force: boolean }, cb: (err: Error) => void) => void;
  };
  // From reading the source code, these are the necessary changes to force a refresh.
  docPrototype.version = null;
  docPrototype._setType(null);
  docPrototype._fetch({ force: true }, (err) => {
    if (err) {
      rollbarAndLogError("Error fetching doc", docId, err);
    }
    triggerDocUpdate(docId);
    if (callback) {
      callback(err);
    }
  });
};

const { incrementVersion, flushQueue } = createIncrementVersionQueue(docCache);

/**
 * useWorksheet will connect to a remote ShareDB document and return the doc object,
 * and will also force a re-render when any of the document data changes.
 */
export const useWorksheet = (docId: string) => {
  const [doc, setDoc] = useState<Doc<WorksheetType>>(null);
  const [, rerenderComponent] = useState({});
  const dispatch = useAppDispatch();
  const forceRerender = useCallback(() => {
    rerenderComponent({});
    // For the case where we listen to a doc before it exists, we need to check if we've loaded
    // it by the time we receive an update.
    const cachedDoc = docCache[docId];
    setDoc((existingDoc) => existingDoc || cachedDoc);
  }, [docId]);
  const { sharedbConnection } = useContext(WebsocketContext);

  useEffect(() => {
    if (!docId || !sharedbConnection) return;

    const stopListening = listenToDocUpdates(docId, forceRerender);
    const unsubscribe = () => {
      const docToUnsubscribe = docCache[docId];
      stopListening();
      // Any caller of useWorksheet will listen to a global event emitter for changes to the doc,
      // and the last caller to unsubscribe will actually unsubscribe the doc from the remote server.
      if (cacheHasNoListeners(docId)) {
        flushQueue();
        delete docCache[docId];
        if (docToUnsubscribe?.localPresence) {
          docToUnsubscribe.localPresence.destroy();
        }
        if (docToUnsubscribe?.presence) {
          docToUnsubscribe.presence.unsubscribe();
        }
        if (docToUnsubscribe) {
          docToUnsubscribe.destroy();
        }
      }

      setDoc(null);
    };

    // The ShareDB code doesn't handle the case well where the same docId is requested
    // multiple times. We cache the instance and make sure future hook calls for the same ID
    // return the same instance, and share the same root listeners as whatever the first instance
    // was that called useWorksheet for that ID.
    const cachedDoc = docCache[docId];
    if (cachedDoc) {
      setDoc(cachedDoc);
      return unsubscribe;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const newDoc: Doc<WorksheetType> = sharedbConnection.get(
      window._env_.SHAREDB_COLLECTION,
      docId,
    );
    setDoc(newDoc);

    docCache[docId] = newDoc;

    const presence = sharedbConnection.getDocPresence(window._env_.SHAREDB_COLLECTION, docId);
    newDoc.presence = presence;
    newDoc.localPresence = presence.create();
    presence.subscribe();
    newDoc.presence.setMaxListeners(MAX_DOC_LISTENERS);

    const onError = (err: Error) => {
      rollbarAndLogError("Error during doc action", { err: err?.message, docId });
    };
    const onOp = () => {
      triggerDocUpdate(docId);
    };
    const onDel = () => unsubscribe();

    newDoc.on("op", onOp);
    newDoc.on("load", onOp);
    newDoc.on("del", onDel);
    newDoc.on("error", onError);
    newDoc.setMaxListeners(MAX_DOC_LISTENERS);

    // The ShareDB `doc` object is actually a function (not a class), which means we can't substitute
    // and methods on individual instances, rather, the changes below affect _all_ current and future
    // doc instances, hence, we make sure to only do it once.
    if (!newDoc._submitOp) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      newDoc._submitOp = newDoc.submitOp;

      newDoc.submitOp = function submitOp(
        this: Doc<WorksheetType>,
        op: Op | Op[],
        options: ShareDBSourceOptions,
        callback: Callback,
      ) {
        if (typeof options === "function") {
          throw new Error("second argument to submitOp can not be a callback");
        }

        checkOpPermissions(newDoc, op);

        dispatch(viewStateActions.onSendSharedbOp());
        this._submitOp(op, options, (error: WebsocketErrorType) => {
          if (error) {
            dispatch(viewStateActions.onSharedbError(error.message));
          } else {
            dispatch(viewStateActions.onReceiveSharedbOp());
          }
          incrementVersion(this, op);
          if (callback) {
            callback(error);
          }
        });
      };
    }

    newDoc.subscribe((err) => {
      if (err) {
        rollbarAndLogError("Error subscribing to doc", { err: err.message, docId });
      } else if (newDoc.type === null) {
        rollbarAndLogWarning("doc.subscribe called but no type set", { docId });
      }
    });

    return unsubscribe;
  }, [docId, sharedbConnection, dispatch, forceRerender]);

  if (doc && !doc.data) {
    // If the doc breaks in unexpected ways, doc.data will be undefined.
    // TODO: We might want to separate the two cases to distinguish between error and loading states.
    return {};
  }

  return { doc };
};

/**
 * Listens to permission changes and forces a data refresh on the document if the permissions
 * change in a meaningful way.
 */
export const useWorksheetPermissionRefresh = (docId: string) => {
  const docPermissions = useAppSelector((s) => selectCollaborationPermissionsForDoc(s, docId));
  const [previousDocPermissions, setPreviousDocPermissions] = useState<PagePermissionType>(null);

  useEffect(() => {
    if (!docId || !docPermissions) {
      return;
    }
    const doc = docCache[docId];

    if (!previousDocPermissions || !doc || doc.id !== docId) {
      setPreviousDocPermissions(docPermissions);
      return;
    }

    const rollupChanged = previousDocPermissions.rollup !== docPermissions?.rollup;
    const groupsChanged = !isEqual(
      previousDocPermissions?.group_ids || [],
      docPermissions?.group_ids || [],
    );

    if (rollupChanged || groupsChanged) {
      forceRefreshDoc(docId);
    }

    setPreviousDocPermissions(docPermissions);
  }, [docPermissions, previousDocPermissions, docId]);
};
