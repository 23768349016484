import { Switch } from "@headlessui/react";
import clsx from "clsx";
import type { FC } from "react";

import { Button, ButtonProps } from "./Button";

type ToggleSwitchProps = ButtonProps & {
  selected?: boolean;
  showDataSelection?: boolean;
};

// TODO: didn't realize toggle button is a switch.  We need to combine these.
export const ToggleSwitch: FC<ToggleSwitchProps> = ({
  className,
  selected = false,
  showDataSelection = true,
  kind = "secondary",
  size = "xs",
  onClick,
  children,
  ...buttonProps
}) => {
  return (
    <Button
      className={clsx("transition", className, {
        "data-[active]:border-blue data-[active]:bg-blue-tint-90": showDataSelection,
      })}
      data-active={selected || undefined}
      kind={kind}
      size={size}
      onClick={onClick}
      {...buttonProps}
    >
      {children}
      <Switch
        as="div"
        checked={selected}
        className="group ml-2 flex h-4 w-8 cursor-pointer items-center rounded-full bg-black-tint-70 transition data-[checked]:bg-blue"
      >
        <span className="size-3 translate-x-0.5 rounded-full bg-white shadow-md transition group-data-[checked]:translate-x-[1.125rem]" />
      </Switch>
    </Button>
  );
};
