import { FC, useState } from "react";

import { t } from "i18n/i18n";
import { Banner } from "mds/components/Banner";

export type LateSubmissionAvailableBannerProps = {
  lateSubmissionDate: string;
};

export const LateSubmissionAvailableBanner: FC<LateSubmissionAvailableBannerProps> = ({
  lateSubmissionDate,
}) => {
  const [showBanner, setShowBanner] = useState(true);
  const submissionKey = lateSubmissionDate ? "date" : "indefinite";

  return showBanner ? (
    <Banner className="page-block-width" kind="warning" onDismiss={() => setShowBanner(false)}>
      {t(`warning.Allowing_late_submissions_${submissionKey}`, { date: lateSubmissionDate })}
    </Banner>
  ) : null;
};
