import { FC } from "react";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { IconText } from "mds/components/IconText";
import { GearSettingsIcon } from "mds/icons";
import { trackEvent } from "utils/amplitude";
import { orgHomeUrl } from "utils/urls";

export const ManageInstitutionButton: FC = () => {
  return (
    <Button
      className="gap-2"
      kind="secondary"
      to={orgHomeUrl}
      onClick={() =>
        trackEvent("Header - Manage institution", {
          eventCategory: "Button press",
        })
      }
    >
      <IconText iconStart={<GearSettingsIcon />} text={t("main_nav.manage_institution")} />
    </Button>
  );
};
