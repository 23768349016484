import "./FileField.scss";

import clsx from "clsx";
import { get } from "lodash";
import prettyBytes from "pretty-bytes";
import { type FC, useCallback, useContext, useEffect, useState } from "react";
import { Doc } from "sharedb/lib/client";

import { FileCellType, FileDataType, PathType, WorksheetType } from "../../shared/types/worksheet";
import { createId } from "../../shared/utils";

import PdfAsset from "./PdfAsset";
import VideoAsset from "./VideoAsset";

import { collaborationUploadFile, deleteUploadedFile } from "api/api-worksheets";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { FileUploadField } from "mds/components/FileUploadField";
import { useHoverable } from "mds/hooks/use-hoverable";
import { CircleSuccessGreenIcon, CrossRemoveIcon, LoadingDotsIcon } from "mds/icons";
import { DocViewContext } from "providers/DocViewProvider";
import { toastLocalizedOperationError } from "utils/alerts";
import { isImageFile, isPDFFile, isVideoFile } from "worksheets/helpers/files";

interface FileType {
  type?: string;
  path?: string;
  size?: number;
}

const UPLOAD_SUCCESS_MESSAGE_DURATION = 3000;

const uploadFile = async (file: FileType, doc: Doc<WorksheetType>, path: PathType) => {
  const size = prettyBytes(file.size);
  const id = createId();
  const partialFileData: Omit<FileDataType, "url"> = {
    placeholder: true,
    t: file.type,
    filename: file.path || id,
    size,
    id,
  };
  // Submit placeholder
  doc.submitOp({ p: [...path, 99999], li: partialFileData });

  const { url: fileUrl } = await collaborationUploadFile(file as Blob as File);

  const newFileData: FileDataType = { ...partialFileData, url: fileUrl };
  delete newFileData.placeholder;
  const fileData = get(doc.data, path) as FileDataType[];
  const placeholder = fileData.find((fd) => fd.id === newFileData.id);
  const placeholderIndex = fileData.indexOf(placeholder);

  // Replace placeholder with actual image URL
  if (placeholder) {
    doc.submitOp({
      p: [...path, placeholderIndex],
      li: newFileData,
      ld: placeholder,
    });
  } else {
    // If the placeholder got deleted during upload, we still just insert the image
    doc.submitOp({
      p: [...path, 9999],
      li: newFileData,
    });
  }
};

const onDrop = (files: FileType[], doc: Doc<WorksheetType>, path: PathType) => {
  Promise.all(files.map((file) => uploadFile(file, doc, path))).catch(() => {
    const fileData = get(doc.data, path) as FileDataType[];
    const placeholders = fileData.filter((fd) => fd.placeholder);
    const ops = placeholders.map((p) => ({ p: [...path, fileData.indexOf(p)], ld: p }));
    doc.submitOp(ops);
    toastLocalizedOperationError("failed_to_upload_file");
  });
};

interface FilePreviewProps {
  className?: string;
  // TODO: Rollup-MVP: We used to enabled condensed mode for rollups due to network considerations
  // but chose to disable it for now to be more intuitive for testing.
  condensed?: boolean;
  id?: string;
  readOnly?: boolean;
  fileData: FileDataType;
  shouldSyncWithInstructorView: boolean;
  onDelete: () => void;
}

const FilePreview: FC<FilePreviewProps> = ({
  fileData,
  onDelete,
  id,
  className,
  condensed = false,
  shouldSyncWithInstructorView,
  readOnly,
}) => {
  // `placeholder` is true if the file is actively being uploaded (and may end up in state if the upload fails?).
  const { placeholder, url } = fileData;
  const isImage = isImageFile(fileData.t);
  const isVideo = isVideoFile(fileData.t);
  const isPDF = isPDFFile(fileData.t);
  const [recentlyUploaded, setRecentlyUploaded] = useState(placeholder);
  const showUploadSuccessful = recentlyUploaded && !placeholder;
  const filePreviewHover = useHoverable();

  // The filename are often (always?) prefixed with a "./" to the start of their filename, which we want to avoid
  // displaying to the user given this won't make sense to them.
  const trimmedFileName = fileData.filename.startsWith("./")
    ? fileData.filename.slice(2)
    : fileData.filename;

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showUploadSuccessful) {
      timeout = setTimeout(() => {
        setRecentlyUploaded(false);
      }, UPLOAD_SUCCESS_MESSAGE_DURATION); // disable notification after 3 seconds
    }
    return () => clearTimeout(timeout);
  }, [showUploadSuccessful, setRecentlyUploaded]);

  return (
    <div
      // The 26 extra pixels are the height of the top bar for the preview
      className={clsx("shadow-level-3", className)}
      {...filePreviewHover.hoverParentProps}
    >
      <div className="body-s uploaded-file-text flex w-full items-center px-4 pt-4">
        <span className="mb-2 flex w-full items-center gap-1">
          {placeholder ? (
            <span className="uploading font-bold">
              {t("file_field.uploading_filename", { filename: trimmedFileName })}
            </span>
          ) : (
            <a
              className={`uploaded-file-link font-bold ${showUploadSuccessful ? "uploading" : ""}`}
              href={fileData.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              {trimmedFileName}
            </a>
          )}

          {/* Avoid shrinking the size display as we want the full value to render properly. */}
          <span className="flex-shrink-0 text-black-tint-40">({fileData.size})</span>

          {placeholder && <LoadingDotsIcon />}
        </span>

        {!condensed && (
          <span className="ml-auto flex items-center">
            {showUploadSuccessful && (
              <span className="left-0 mx-4 flex items-center text-black-tint-40">
                <CircleSuccessGreenIcon size="xs" />
                <span className="ml-2">{t("common.uploaded")}</span>
              </span>
            )}

            {!readOnly && (
              <Button
                className={clsx("ml-4 flex", !filePreviewHover.isHovering && "invisible")}
                kind="secondary"
                iconOnly
                onClick={onDelete}
              >
                <CrossRemoveIcon size="xs" />
              </Button>
            )}
          </span>
        )}
      </div>

      {!condensed && isImage && url && (
        <div className="flex justify-center p-4">
          <img
            alt={t("file_field.uploaded_image")}
            className="h-auto w-auto max-w-full object-contain"
            src={url}
          />
        </div>
      )}

      {!condensed && isVideo && url && (
        <div className="p-2">
          <VideoAsset
            className="w-full"
            id={id}
            shouldSyncWithInstructorView={shouldSyncWithInstructorView}
            url={url}
          />
        </div>
      )}

      {!condensed && isPDF && url && (
        <div className="p-2">
          <PdfAsset footer="NARROW" url={url} />
        </div>
      )}
    </div>
  );
};

interface FileFieldProps {
  doc: Doc<WorksheetType>;
  path: PathType;
  id: string;
  m: boolean;
}

export const FileField: FC<FileFieldProps> = ({ doc, path, id, m: multiple }) => {
  const fileData = get(doc.data, path) as FileCellType;
  const { showResponses, readOnly, isProjecting, canInteractAsStudent } =
    useContext(DocViewContext);
  // `fileData` should be [] on a blank field in normal usage, though we have safeguards to prevent
  // issues if it's undefined, which seem to have occurred sporadically and caused bugs in the past.
  const hasNoFile = fileData && fileData.length <= 0;

  const deleteFile = (index: number) => {
    const fd = get(doc.data, path, index) as FileDataType;
    if (fd && fd.id && fd.url) {
      deleteUploadedFile(fd.id).catch(() =>
        toastLocalizedOperationError("failed_to_delete_uploaded_file"),
      );
    }
    doc.submitOp({ p: [...path, index], ld: fd });
  };

  const onFileDrop = useCallback(
    (droppedFiles: File[]) => onDrop(droppedFiles, doc, path),
    [doc, path],
  );
  return (
    <div>
      {/* Image List */}
      {!hasNoFile && fileData && (
        <div className={showResponses ? "flex flex-wrap items-start justify-start gap-4" : ""}>
          {fileData.map((fd, i) => (
            <FilePreview
              // It's extremely important that the key contains a unique ID that is preserved
              // during all upload stages, otherwise render order and callback association gets jumbled
              className={!showResponses ? "mb-4" : undefined}
              fileData={fd}
              id={id}
              key={`img-${id}-${fd.id}`}
              readOnly={readOnly}
              shouldSyncWithInstructorView={isProjecting}
              onDelete={() => deleteFile(i)}
            />
          ))}
        </div>
      )}

      {/* Upload Zone – this should never be shown on All Responses view, but we do want it to appear when
          featured as a block if there is no file, to avoid giving the impression of a bug in the UI.
           However, we avoid making the drop zone appear when featured if there is already a file */}
      {(!fileData || hasNoFile || (multiple && !showResponses)) && showResponses !== "ALL" && (
        <FileUploadField
          disabled={isProjecting || !canInteractAsStudent || !fileData}
          multiple={multiple}
          name={`file-${id}`}
          onDrop={onFileDrop}
        />
      )}
    </div>
  );
};
