import { FC } from "react";
import { Trans } from "react-i18next";

import { t } from "i18n/i18n";
import { ToggleSwitch } from "mds/components/ToggleSwitch";
import { AIGeneratedSparkleIcon } from "mds/icons";
import { useAppDispatch, useAppSelector } from "store/index";
import { selectIsAssessWithAIModeEnabled } from "store/selectors";
import { localStateActions } from "store/slices/local";

interface AssessWithAIModeButtonProps {
  showAIGeneratedMsg: boolean;
  disabled?: boolean;
}

export const AssessWithAIModeButton: FC<AssessWithAIModeButtonProps> = ({
  showAIGeneratedMsg,
  disabled,
}) => {
  const dispatch = useAppDispatch();
  const isAssessWithAIModeEnabled = useAppSelector(selectIsAssessWithAIModeEnabled);

  const toggleEditMode = () => dispatch(localStateActions.toggleAssessWithAIMode());

  return (
    <div className="flex flex-col gap-1">
      <ToggleSwitch
        className="!justify-between text-black-tint-20"
        disabled={disabled}
        selected={isAssessWithAIModeEnabled}
        fullWidth
        onClick={toggleEditMode}
      >
        <div className="flex flex-row items-center justify-center gap-1">
          <Trans i18nKey="assessment_card.ai.toggle" t={t}>
            <div className="flex text-sm font-semibold">Enable Auto AI Suggestions</div>
            <div className="flex text-xs font-normal leading-5">(BETA)</div>
          </Trans>
        </div>
      </ToggleSwitch>
      {showAIGeneratedMsg && (
        <div className="flex items-center gap-1 text-xs text-black-tint-40">
          <AIGeneratedSparkleIcon />
          {t("assessment_card.ai.ai_generated")}
        </div>
      )}
    </div>
  );
};
