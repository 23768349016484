import clsx from "clsx";
import type { FC, ReactNode } from "react";

import { Button, IconKind, IconSizeType } from "./Button";

import { t } from "i18n/i18n";
import {
  CircleCheckIcon,
  CircleCrossIcon,
  CircleExclamationIcon,
  CircleInfoIcon,
  CrossRemoveIcon,
} from "mds/icons";

type Color = "red" | "orange" | "blue" | "green";

type Kind = "info" | "warning" | "error" | "success";

const KIND_TO_ICON: Record<Kind, FC> = {
  info: CircleInfoIcon,
  warning: CircleExclamationIcon,
  error: CircleCrossIcon,
  success: CircleCheckIcon,
};

const KIND_TO_COLOR: Record<Kind, Color> = {
  info: "blue",
  warning: "orange",
  error: "red",
  success: "green",
};

const COLOR_TO_CLASSES: Record<Color, string> = {
  red: "bg-red-tint-90 border border-red-tint-70 icon-red",
  orange: "bg-orange-tint-90 border border-orange-tint-70 icon-orange",
  blue: "bg-blue-tint-90 border border-blue-tint-70 icon-blue",
  green: "bg-green-tint-90 border border-green-tint-70 icon-green",
};

export type BannerProps = {
  kind: Kind;
  onDismiss?: () => void;
  onAction?: () => void;
  actionButtonText?: string;
  actionButtonSize?: IconSizeType;
  actionButtonKind?: IconKind;
  actionButtonClassName?: string;
  buttonChildren?: ReactNode;
  className?: string;
  children: ReactNode;
  /**
   * If true, the banner will not display an icon, so you can put your own icon
   * into the children. Maybe it'd be easier to instead accept an icon prop which
   * can be either undefined, null, or a ReactNode. If it is undefined (the default),
   * then the component looks up the icon from KIND_TO_ICON and displays that.
   * If the prop is null, then the component does not display any icon at all.
   * And if the prop is a ReactNode, it displays that as is?
   */
  noIcon?: boolean;
  Icon?: FC;
};

/**
 * A Banner (Alert) component with optional pre-styled icons and actions buttons.
 */
export const Banner: FC<BannerProps> = ({
  kind,
  className,
  children,
  onDismiss,
  onAction,
  buttonChildren,
  Icon,
  actionButtonClassName,
  actionButtonText = "Take Action",
  actionButtonKind = "primary",
  actionButtonSize,
  noIcon,
}) => {
  const BannerIcon = Icon || KIND_TO_ICON[kind];
  const color = KIND_TO_COLOR[kind];
  return (
    <div
      className={clsx(
        "mds-banner flex items-center justify-between gap-2 rounded-lg p-2",
        color && COLOR_TO_CLASSES[color],
        className,
      )}
    >
      <div className="flex items-center gap-2">
        {BannerIcon && !noIcon && <BannerIcon />}
        {children}
      </div>
      <div className="flex items-center gap-2">
        {buttonChildren}
        {onAction && (
          <Button
            className={actionButtonClassName}
            color={actionButtonKind === "primary" ? color : undefined}
            kind={actionButtonKind}
            size={actionButtonSize}
            onClick={onAction}
          >
            {actionButtonText}
          </Button>
        )}
        {onDismiss && (
          <Button
            kind="tertiary"
            size="xs"
            title={t("common.dismiss")}
            iconOnly
            onClick={onDismiss}
          >
            <CrossRemoveIcon />
          </Button>
        )}
      </div>
    </div>
  );
};
