// eslint-disable-next-line filename-rules/match
import { formatDistanceToNow } from "date-fns";
import { enUS, es } from "date-fns/locale";

import { SupportedLanguage, i18n } from "i18n/i18n";

const locales = { en: enUS, es };

/**
 * Format the distance of the given date to now using the locale from i18next.
 *
 * @param {Date|number} date - The date to compare to now.
 * @param {Object} [options] - Additional options for formatDistanceToNow.
 * @param {boolean} [options.addSuffix] - Include a suffix in the output.
 * @returns {string} - The formatted distance to now.
 */
// eslint-disable-next-line import/no-default-export
export default function (date, options = {}) {
  const currentLocale = (i18n.language as SupportedLanguage) || "en"; // Get the current i18next language
  const dateFnsLocale = locales[currentLocale];

  return formatDistanceToNow(date, {
    ...options,
    locale: dateFnsLocale,
  });
}
