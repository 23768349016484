import clsx from "clsx";
import { FC } from "react";
import { Link } from "react-router-dom";

import { CourseType, FullCourseType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { ClickEvent, Menu, MenuItem } from "mds/components/Menu";
import { useIsMdOrLarger } from "mds/hooks/use-responsive";
import { BookCourseIcon, ChevronDownIcon, CircleSuccessGreenIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import {
  selectCanAuthorCourse,
  selectCurrentCourse,
  selectFullCoursesSorted,
} from "store/selectors";
import { useListSelector } from "store/store-hooks";
import { trackEvent } from "utils/amplitude";
import { courseHomeUrl } from "utils/urls";

/**
 * A dropdown list of courses.
 */
export const CourseSelect: FC<{
  disabled: boolean;
  readOnly?: boolean;
  truncateTitle?: boolean;
}> = ({ disabled, truncateTitle, readOnly }) => {
  const currentCourse = useAppSelector(selectCurrentCourse);
  const courses = useListSelector(selectFullCoursesSorted);
  const currentCourseLabel = courses.find((course) => course.id === currentCourse.id)?.full_title;

  if (courses.length === 0) {
    return null;
  }

  const onSelectCourse = (event: ClickEvent) => {
    const newCourse = event.value as CourseType;
    trackEvent("Header - Navigate to course", {
      eventCategory: "Button press",
      courseTitle: newCourse.title,
    });
  };

  const MenuButton = (
    <Button className="gap-1 text-black-tint-20" disabled={disabled} kind="tertiary" size="s">
      <span className={clsx({ "max-w-l truncate": truncateTitle })}>{currentCourseLabel}</span>
      {!readOnly && <ChevronDownIcon />}
    </Button>
  );

  return (
    <Menu menuButton={MenuButton} menuClassName="max-h-600" title={t("main_header.course_select")}>
      {courses.map((course) => (
        <CourseMenuItem
          course={course}
          currentCourseId={currentCourse.id}
          key={course.id}
          onClick={onSelectCourse}
        />
      ))}
    </Menu>
  );
};

type CourseMenuItemProps = {
  course: FullCourseType;
  currentCourseId: string;
  onClick: (event: ClickEvent) => void;
};

const CourseMenuItem: FC<CourseMenuItemProps> = ({ course, currentCourseId, onClick }) => {
  const checked = course.id === currentCourseId;
  const isMobile = !useIsMdOrLarger();
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);

  return (
    <Link className="" to={courseHomeUrl(course.id)}>
      <MenuItem
        checked={checked}
        className={clsx("icon-unset flex items-center justify-between gap-8", {
          selected: checked,
        })}
        value={course}
        onClick={onClick}
      >
        <div className="flex items-center gap-2">
          <BookCourseIcon />
          <div
            className={clsx("truncate", isMobile ? "max-w-xl" : "max-w-xxl")}
            title={course.full_title}
          >
            {course.full_title}
          </div>
        </div>
        {course.lms_section_id && canAuthorCourse && (
          <div className="flex items-center gap-1" title={course.full_title}>
            <CircleSuccessGreenIcon />
            <span>{t("main_header.course_linked")}</span>
          </div>
        )}
        {/* We are temporarily disabling this unlink notification to minimize
          confusion during the pilots that will never be linked to a LMS.
          TODO: This should be re-enabled once we start having LMS integrations in use in 2025. */}
        {/* <div className="flex items-center gap-1"> */}
        {/*  <CircleExclamationIcon className="icon-orange" /> */}
        {/*  <span>{t("main_header.course_unlinked")}</span>{" "} */}
        {/* </div> */}
      </MenuItem>
    </Link>
  );
};
