import "./FileUploadField.scss";

import clsx from "clsx";
import { FC, ForwardedRef, memo, useMemo } from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";

import { createId } from "../../worksheets/shared/utils";

import { TextAddButton } from "./TextAddButton";

import { Trans, t } from "i18n/i18n";
import { AddButton } from "mds/components/AddButton";
import { toastLocalizedOperationError } from "utils/alerts";

const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

type FileUploadFieldProps = {
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => void;
  isLoading?: boolean;
  name?: string;
  multiple?: boolean;
  size?: "s" | "m" | "l";
  isCard?: boolean;
  addButtonProps?: {
    type: "block" | "page";
    ref?: ForwardedRef<HTMLButtonElement>;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean;
  };
  AddElementButton?: JSX.Element;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
};

/**
 * A generic UI component for displaying file upload fields.
 * Does not handle file upload logic, only UI.
 * If provided with a buttonText, will render a button with the given text. Otherwise, only a
 * prompt to "upload a file" will be shown.
 * If children are passed, they replace both the button and the prompt.
 */
export const FileUploadField: FC<FileUploadFieldProps> = memo(
  ({
    onDrop,
    isLoading,
    name,
    size = "m",
    addButtonProps,
    isCard = false,
    children,
    className,
    multiple,
    disabled,
  }) => {
    const id = useMemo(() => `file-field-${createId()}`, []);

    const { getRootProps, getInputProps, isDragAccept } = useDropzone({
      noClick: true,
      multiple,
      onDrop,
      onDropRejected: () => toastLocalizedOperationError("failed_to_select_files"),
    });

    const defaultContent = (
      <div className="flex items-center gap-1">
        {isCard ? (
          <Trans i18nKey="file_upload_field.upload_or_add_card" t={t}>
            <TextAddButton underlined {...addButtonProps} />
            <span>or</span>
            <span className="link font-semibold">upload a file</span>
          </Trans>
        ) : addButtonProps ? (
          <Trans context={addButtonProps.type} i18nKey="file_upload_field.upload_or_add" t={t}>
            <AddButton {...addButtonProps}>
              Add {capitalizeFirstLetter(addButtonProps.type)}
            </AddButton>
            <span>or</span>
            <span className="link">upload</span>
            <span>a file</span>
          </Trans>
        ) : (
          <Trans i18nKey="file_upload_field.upload_or_drop" t={t}>
            <span>Drop or </span>
            <span className="link">upload</span>
            <span>a file</span>
          </Trans>
        )}
      </div>
    );

    const displayContent = isLoading ? (
      <div className="flex items-center">
        {/* TODO: Finish styling animation */}
        <div className="loading-dots">{t("common.loading")}</div>
      </div>
    ) : (
      children || defaultContent
    );

    return (
      <div
        {...(disabled ? {} : getRootProps())}
        className={clsx(
          disabled && "disabled",
          isCard ? "card file-card" : "mds-file-field",
          `size-${size}`,
          isDragAccept && "-dragging",
          isLoading && "-loading",
          className,
        )}
      >
        {!disabled && <input {...getInputProps({ id, name })} />}
        <label
          className={clsx("label flex items-center", isCard ? "-ml-1" : "justify-center")}
          htmlFor={id}
        >
          {displayContent}
        </label>
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.name === nextProps.name &&
    prevProps.size === nextProps.size &&
    prevProps.isCard === nextProps.isCard &&
    prevProps.children === nextProps.children &&
    prevProps.className === nextProps.className &&
    prevProps.disabled === nextProps.disabled,
);

FileUploadField.displayName = "FileUploadField";
