import "./Tooltip.scss";

import { createId } from "@paralleldrive/cuid2";
import clsx from "clsx";
import { forwardRef, useMemo } from "react";
import { ITooltip, Tooltip as ReactTooltip, TooltipRefProps } from "react-tooltip";

import { CircleInfoIcon } from "mds/icons";

export { type TooltipRefProps } from "react-tooltip";

// The current code is expecting every kind and prop variant be able to work together.
// If future changes do not follow this constraint (e.g. some `kinds` are only available in some `sizes`),
// we should consider integrating that property (e.g. `size`) into the `kind` property to be explicit
// on what is supported.
// TODO: Add all variations to a storybook page later.
export interface TooltipProps extends ITooltip {
  element?: React.ReactNode;
  contentClassName?: string;
  wrapperClassName?: string;
  children?: React.ReactNode;
}

/**
 * Styled wrapper for react-tooltip.
 * Docs at https://react-tooltip.com/docs/getting-started
 */
export const Tooltip = forwardRef<TooltipRefProps, TooltipProps>(
  ({ contentClassName, wrapperClassName = "flex", children, element, ...props }, ref) => {
    const hoverableElementContent = element || <CircleInfoIcon height="16" width="16" />;
    const id = useMemo(() => `tooltip-${createId()}`, []);

    return (
      <div className={clsx("mds-tooltip", wrapperClassName)}>
        {/* TODO: This breaks HTML spec when used on tables. Add an option to avoid the wrapping span? */}
        <span
          className="mds-tooltip-source"
          data-tooltip-delay-hide={0}
          data-tooltip-delay-show={0}
          data-tooltip-id={id}
          data-tooltip-variant="light"
        >
          {hoverableElementContent}
        </span>
        <ReactTooltip
          className={clsx("mds-tooltip-content", contentClassName)}
          id={id}
          {...props}
          ref={ref}
        >
          {children}
        </ReactTooltip>
      </div>
    );
  },
);

Tooltip.displayName = "Tooltip";
