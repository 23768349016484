import { useSearchParams } from "react-router-dom";

import { courseContentUrl, courseHomeUrl, coursePageUrl } from "../../utils/urls";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";

const makeRedirectURL = (searchParams: URLSearchParams) => {
  // For now, we just handle page redirects for LTI endpoint
  const courseId = searchParams.get("courseId");
  const pageId = searchParams.get("pageId");
  const topicId = searchParams.get("topicId");

  if (topicId) {
    return courseContentUrl(courseId, topicId, pageId);
  }
  if (pageId) {
    return coursePageUrl(courseId, "assignment", pageId);
  }
  return courseHomeUrl(courseId);
};

export default function Page() {
  const [searchParams] = useSearchParams();
  const url = makeRedirectURL(searchParams);

  return (
    <div className="m-5">
      <Button target="_blank" to={url}>
        {t("common.new_tab")}
      </Button>
    </div>
  );
}
