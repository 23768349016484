import { Suspense } from "react";
import { useRoutes } from "react-router-dom";

import { LoadingDotsIcon } from "mds/icons";
// Eslint is unable to resolve this path, but it works fine in practice.
// eslint-disable-next-line import/no-unresolved
import routes from "~react-pages";

export const Routes = () => {
  return <Suspense fallback={<LoadingDotsIcon />}>{useRoutes(routes)}</Suspense>;
};
