import "./NavList.scss";

import type { FC } from "react";
import { useSearchParams } from "react-router-dom";

import { HoverableToolbar } from "components/hover-widgets/HoverableToolbar";
import { SidebarOptionsMenu } from "components/menus/SidebarOptionsMenu";
import { CourseCodeType, TermType } from "components/server-types";
import { t } from "i18n/i18n";
import { AddButton } from "mds/components/AddButton";
import { DragAndDrop } from "mds/components/DragAndDrop";
import { ListItem } from "mds/components/ListItem";
import { IconProps } from "mds/utils/images";
import { storeApi, useAppSelector } from "store/index";
import { selectCanAuthorOrg } from "store/selectors";
import { useDnDItemOrdering, useLocalCopy } from "utils/collections";
import { useFindItemBySearchParamId } from "utils/hooks/use-find-item-by-search-param-id";
import { relativeLink } from "utils/urls";

type ModelType = "term" | "course_code";
type NavItemType<M extends ModelType> = M extends "term" ? TermType : CourseCodeType;

type ApiType<M extends ModelType> = M extends "term"
  ? typeof storeApi.terms
  : typeof storeApi.course_codes;

type NavListProps<M extends ModelType> = {
  title: string;
  items: NavItemType<M>[];
  model: M;
  api: ApiType<M>;
  Icon?: FC<IconProps>;
  queryParam: string;
};

export const NavList = <M extends ModelType>({
  title,
  model,
  items,
  api,
  Icon,
  queryParam,
}: NavListProps<M>) => {
  const currentItem = useFindItemBySearchParamId(items, queryParam);
  const [localItems, setLocalItems] = useLocalCopy(items);
  const canAuthorOrg = useAppSelector(selectCanAuthorOrg);
  const [searchParams, setSearchParams] = useSearchParams();

  const moveItemsLocally = useDnDItemOrdering(localItems, setLocalItems);
  const i18nContext: { context: ModelType } = { context: model };

  const onDropItem = (droppedItem: NavItemType<M>) => {
    api.partial_update({ id: droppedItem.id, order: droppedItem.order });
  };

  const handleCreateItem = () => {
    const newOrder = (localItems[localItems.length - 1]?.order || 0) + 1;
    api
      .create({
        title: t("nav_list.new_title", i18nContext),
        order: newOrder,
      })
      .then((newItem) => {
        searchParams.set(queryParam, newItem.id);
        setSearchParams(searchParams);
      });
  };

  const handleDeleteItem = (item: NavItemType<M>) => {
    api.destroy(item.id);
    if (item.id === currentItem?.id) {
      const newCurrentItem = items[0];
      searchParams.set(queryParam, newCurrentItem.id);
      setSearchParams(searchParams);
    }
  };

  const getUrl = (item: NavItemType<M>) => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set(queryParam, item.id);
    return relativeLink(currentUrl);
  };

  return (
    <div className="nav-list w-full" role="list">
      <HoverableToolbar
        reference={model}
        onClickAdd={canAuthorOrg ? () => handleCreateItem() : undefined}
      >
        <h3 className="h3 text-black-tint-40">{title}</h3>
      </HoverableToolbar>

      {localItems.map((item, index) => (
        <DragAndDrop
          draggable={canAuthorOrg}
          dragItemKind={model}
          dropContainerId={model}
          dropIndex={index}
          droppable={canAuthorOrg}
          item={item}
          key={item.id}
          onDraggedItemHover={moveItemsLocally}
          onDropItem={onDropItem}
        >
          <ListItem
            className="my-1 min-w-12 justify-between"
            isSelected={currentItem?.id === item.id}
            label={item.title}
            to={getUrl(item)}
            onClick={() => {
              searchParams.set(queryParam, item.id);
              setSearchParams(searchParams);
            }}
          >
            <HoverableToolbar className="w-full">
              <div className="body-m flex w-full min-w-12 items-center justify-between">
                <div className="flex min-w-12 items-center gap-2">
                  <Icon />
                  <h3 className="my-1 w-full min-w-12 cursor-pointer truncate font-normal">
                    {item.title}
                  </h3>
                </div>

                {canAuthorOrg && (
                  <SidebarOptionsMenu
                    buttonKind="tertiary"
                    optionsMenuActions={{
                      onDelete: () => handleDeleteItem(item),
                    }}
                  />
                )}
              </div>
            </HoverableToolbar>
          </ListItem>
        </DragAndDrop>
      ))}

      {canAuthorOrg && (
        <AddButton
          className={localItems.length ? "mt-2" : ""}
          kind="tertiary"
          size="s"
          title={t(`common.add.${model as "term" | "course_code"}`)}
          onClick={() => handleCreateItem()}
        >
          <span className="bold">{t(`common.add.${model as "term" | "course_code"}`)}</span>
        </AddButton>
      )}
    </div>
  );
};
