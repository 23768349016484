import "./OutcomeAssessment.scss";
import clsx from "clsx";
import type { FC } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

import { displayOutcomeName } from "components/outcomes/helpers";
import { Button } from "mds/components/Button";
import { OutcomeChip } from "mds/components/OutcomeChip";
import { ScoreIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import { NestedPageOutcomeType, selectPageOutcomeRubric } from "store/selectors";

interface OutcomeAssessmentProps {
  pageOutcome: NestedPageOutcomeType;
  score: number | null;
  setScore: (pageOutcomeId: string, score: number | null) => void;
  register: UseFormRegisterReturn;
  disabled?: boolean;
  readOnly?: boolean;
}

export const OutcomeAssessment: FC<OutcomeAssessmentProps> = ({
  pageOutcome,
  score,
  setScore,
  register,
  readOnly = false,
  disabled = false,
}) => {
  const rubric = useAppSelector((s) => selectPageOutcomeRubric(s, pageOutcome.id));
  const selectedScore = rubric.find((rubricItem) => rubricItem.score === score);
  const descriptionClassName = clsx("rubric-score-description", {
    [`bg-score-light-${selectedScore?.score}`]: selectedScore,
    disabled,
  });

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <OutcomeChip size="s" title={displayOutcomeName(pageOutcome.outcome)} />
        <div className="flex">
          {rubric.map((rubricItem) => (
            <Button
              className="m-0 !p-0"
              disabled={disabled || readOnly}
              key={rubricItem.score}
              kind="tertiary"
              title={`Score ${rubricItem.score}`}
              iconOnly
              onClick={() => {
                if (rubricItem.score === score) {
                  setScore(pageOutcome.id, NaN);
                } else {
                  setScore(pageOutcome.id, rubricItem.score);
                }
              }}
              {...register}
            >
              <ScoreIcon
                disabled={disabled}
                size="m"
                unselected={score !== rubricItem.score}
                value={rubricItem.score}
              />
            </Button>
          ))}
        </div>
      </div>

      {selectedScore && (
        <div className={`${descriptionClassName} p-2`}>
          <div className={clsx("title mr-2 flex gap-2", selectedScore.description ? "mb-2" : "")}>
            <ScoreIcon disabled={disabled} size="s" value={selectedScore.score} />
            <h4 className="m-0">{selectedScore.name}</h4>
          </div>
          {selectedScore.description ? (
            <div className="body-s mb-1 ml-6 mr-1 flex">{selectedScore.description}</div>
          ) : null}
        </div>
      )}
    </div>
  );
};
