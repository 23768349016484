import "./OrgOutcomesView.scss";

import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { OUTCOME_ID_QUERY_PARAM } from "components/constants";
import { OutcomeCount } from "components/materials/org/outcomes/OutcomeCount";
import { OutcomeGroupCard } from "components/materials/org/outcomes/OutcomeGroupCard";
import { OutcomeInfo } from "components/outcomes/OutcomeInfo";
import { OutcomeType } from "components/server-types";
import { t } from "i18n/i18n";
import { TextAddButton } from "mds/components/TextAddButton";
import { storeApi, useAppSelector } from "store/index";
import { selectNestedOutcomeGroups, selectOrgOutcomeRubric } from "store/selectors";
import { useFindItemBySearchParamId } from "utils/hooks/use-find-item-by-search-param-id";

const urlWithOutcomeId = (outcome: OutcomeType) => {
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set(OUTCOME_ID_QUERY_PARAM, outcome.id);
  return currentUrl.toString();
};

export const OrgOutcomesView: FC = () => {
  const navigate = useNavigate();
  const outcomeGroups = useAppSelector(selectNestedOutcomeGroups);

  const totalOutcomeCount = outcomeGroups.reduce((acc, group) => acc + group.outcome_count, 0);

  const outcomes = outcomeGroups
    .map((outcomeGroup) => outcomeGroup.outcomes)
    .flat()
    .concat(
      outcomeGroups
        .map((outcomeGroup) => outcomeGroup.outcome_subgroups)
        .flat()
        .map((subgroup) => subgroup.outcomes)
        .flat(),
    );

  const currentOutcome = useFindItemBySearchParamId(outcomes, OUTCOME_ID_QUERY_PARAM);

  const rubric = useAppSelector((s) => selectOrgOutcomeRubric(s, currentOutcome?.id));

  const onOutcomeChange = (outcome: OutcomeType) => {
    navigate(urlWithOutcomeId(outcome));
  };

  useEffect(() => {
    storeApi.outcomes.list();

    storeApi.outcome_subgroups.list();

    storeApi.outcome_groups.list();
  }, []);

  const createFirstOutcome = () => {
    // If there are no outcome groups, create the default outcome group "Outcomes", and then create the first outcome.
    // TODO: If we need to create the default outcome group for user, we likely missed a set-up step and should fix it.
    if (outcomeGroups.length === 0) {
      storeApi.outcome_groups
        .create({
          title: t("glossary.outcomes"),
        })
        .then((newOutcomeGroup) => {
          storeApi.outcomes.create({ outcome_group_id: newOutcomeGroup.id }).then(onOutcomeChange);
        });
    } else {
      // Create the first outcome in the first outcome group.
      storeApi.outcomes.create({ outcome_group_id: outcomeGroups[0].id }).then(onOutcomeChange);
    }
  };

  return (
    <main className="flex h-full w-full bg-white">
      <div className="org-outcomes-sidebar h-full w-full flex-auto flex-col gap-4 overflow-y-auto bg-black-tint-99 px-3 py-4">
        <div className="flex justify-between">
          <h3 className="h3 my-0 px-2 py-1 text-black-tint-40">{t("glossary.outcome_index")}</h3>
          <div className="flex">
            {/* We are disabling the deprecated outcomes button until support for this is implemented. */}
            {/* <Button */}
            {/*  className="items-center flex gap-1" */}
            {/*  kind="tertiary" */}
            {/*  size="xs" */}
            {/*  onClick={() => showComingSoonAlert()} */}
            {/* > */}
            {/*  <EyeVisibleIcon /> */}
            {/*  {t("outcomes.show_deprecated")} */}
            {/*  <ChevronDownIcon /> */}
            {/* </Button> */}
            <OutcomeCount count={totalOutcomeCount} />
          </div>
        </div>
        {outcomeGroups.map((group) => (
          <OutcomeGroupCard
            currentOutcome={currentOutcome}
            key={group.id}
            outcomeGroup={group}
            queryParam={OUTCOME_ID_QUERY_PARAM}
          />
        ))}
        <TextAddButton
          className="add-group"
          reference="outcome_group"
          onClick={() => {
            storeApi.outcome_groups.create({
              title: t("common.untitled.outcome_group"), // TODO: Dnd
            });
          }}
        />
      </div>
      <div className="h-full flex-auto">
        <OutcomeInfo
          createNewOutcome={outcomes.length === 0 ? createFirstOutcome : undefined}
          isCourseLevel={false}
          outcomeItem={currentOutcome}
          rubric={rubric}
        />
      </div>
    </main>
  );
};
