import { FC } from "react";

import { useCanDeleteActions } from "../../../utils/delete";

import { HoverableEditField } from "components/hover-widgets/EditableField";
import { HoverableToolbar } from "components/hover-widgets/HoverableToolbar";
import { TopicSidebarOptionsMenu } from "components/menus/TopicSidebarOptionsMenu";
import { TopicType } from "components/server-types";
import { t } from "i18n/i18n";
import { useIsMdOrLarger } from "mds/hooks/use-responsive";
import { PageListIcon } from "mds/icons/PageList";
import { useAppSelector } from "store/index";
import { selectCanAuthorCourse, selectVisibleTopicsForCurrentCourse } from "store/selectors";
import { useListSelector } from "store/store-hooks";
import { showComingSoonAlert } from "utils/alerts";
import { useTopicActions } from "utils/topic";

export type TopicHeaderProps = {
  topic: TopicType;
  onDelete: () => void;
};

// This panel displays each type of activity and its child pages for a given topic.
// It is also for selecting the individual page/worksheet to be displayed in main page.
export const TopicHeader: FC<TopicHeaderProps> = ({ topic, onDelete }) => {
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const visibleTopics = useListSelector(selectVisibleTopicsForCurrentCourse);
  const topicIndex = visibleTopics.findIndex((visibleTopic) => visibleTopic.id === topic.id) + 1;
  const topicOrderTitle =
    topicIndex > 0
      ? t("topic_sidebar.topic_and_order", { order: topicIndex })
      : t("topic_sidebar.unreleased_topic");

  const isSmallScreen = !useIsMdOrLarger();
  const { canDeleteTopic } = useCanDeleteActions(topic.id);

  const { saveTopic } = useTopicActions();

  const onTopicTitleChanged = (newTitle: string) => {
    saveTopic({ id: topic.id, title: newTitle });
  };

  return (
    <div className="mt-2.5">
      <div className="body-xs">{topicOrderTitle}</div>

      <HoverableToolbar
        contentClassName="mt-0.5 bg-black-tint-95"
        readOnly={!canAuthorCourse}
        reference="topic"
        disableUnlessEditMode
        showEditButton
        uncentered
      >
        <h3 className="h3 my-0 flex w-full items-center gap-2 text-black-tint-20">
          {isSmallScreen && <PageListIcon className="icon-blue" />}
          <HoverableEditField
            label={t("glossary.topic_title")}
            textSize="h3"
            value={topic.title}
            disableUnlessEditMode
            shouldWrap
            onValueChanged={onTopicTitleChanged}
          />
        </h3>

        <TopicSidebarOptionsMenu
          buttonKind="tertiary"
          optionsMenuActions={{
            onDelete,
            isDeleteDisabled: !canDeleteTopic,
            deleteTooltipText: canDeleteTopic
              ? undefined
              : t("tooltip.delete_topic_has_assessments"),
            onCopyToCourse: showComingSoonAlert,
            onDuplicate: showComingSoonAlert,
          }}
        />
      </HoverableToolbar>
    </div>
  );
};
