import "./TextField.scss";

import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from "react";
import type { FC, InputHTMLAttributes } from "react";

import { useOutsideClick } from "mds/hooks/use-outside-click";
import { useIsMdOrLarger } from "mds/hooks/use-responsive";

const MOBILE_FRIENDLY_SIZES = ["m", "h1", "h2", "h3"];

interface TextFieldProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "size"> {
  size?: "xs" | "s" | "m" | "h6" | "h4" | "h3" | "h2" | "h1";
  isEditing?: boolean;
  onSave?: (newValue: string | number) => void;
  type?: "text" | "number" | "password";
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  wrapperClassName?: string;
  validatorFn?: (value: string) => string[];
}

export const TextField: FC<TextFieldProps> = ({
  startIcon,
  endIcon,
  size = "s",
  className = "",
  wrapperClassName = "w-full flex justify-center items-center",
  onSave = () => null,
  isEditing = false,
  type = "text",
  value: originalValue,
  validatorFn,
  ...props
}) => {
  const [value, setValue] = useState(originalValue);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const validationErrors = validatorFn ? validatorFn(String(value)) : [];
  const hasValidationErrors = validationErrors.length > 0;
  const isMdOrLarger = useIsMdOrLarger();

  const onSaveIfEditing = useCallback(
    (event?: React.MouseEvent<HTMLInputElement>) => {
      if (event) {
        event.stopPropagation();
      }
      if (hasValidationErrors) {
        return;
      }
      if (isEditing) {
        const newValue = typeof value === "string" ? value.trim() : value;
        if (!newValue) {
          // We don't allow saving empty titles, as it causes UI issues, so we just roll back
          onSave(originalValue as string | number);
        } else {
          onSave(newValue as string | number);
        }
      }
    },
    [onSave, originalValue, value, isEditing, hasValidationErrors],
  );

  // We want to end editing when the user clicks anywhere outside the input component.
  useOutsideClick(inputRef, onSaveIfEditing);

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSaveIfEditing();
    }
  };
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };
  useEffect(() => {
    // If the original value changes from a different source while we are editing, it's
    // better to reflect that immediately, so the user can react and doesn't overwrite someone
    // else's changes.
    setValue(originalValue);
  }, [originalValue]);

  // If we pass a value for `props.readOnly`, that value should be passed directly
  // to the input element. Only if the value is undefined, should we default to the
  // inverse of the `isEditing` prop.
  const readOnly = typeof props.readOnly === "undefined" ? !isEditing : props.readOnly;

  // On mobile devices browsers automatically zoom when input font-size is < 16px
  // This is a workaround to ensure the page doesn't zoom in on mobile devices
  // https://wsform.com/knowledgebase/why-forms-zoom-on-some-mobile-devices-and-browsers-and-how-to-control-it/#:~:text=Mobile%20browsers%20typically%20zoom%20in,using%20your%20own%20custom%20CSS.
  const sizeClass = size.startsWith("h") ? size : `body-${size}`;
  const mobileFriendlySizeClass =
    MOBILE_FRIENDLY_SIZES.includes(size) || isMdOrLarger ? sizeClass : "body-m";

  return (
    <div
      className={clsx(
        wrapperClassName,
        // validationErrors.length > 0 && "border !border-red",
        (startIcon || endIcon) && "text-field-icon",
        sizeClass,
      )}
    >
      {startIcon}
      <div className="relative w-full">
        <input
          className={clsx(
            "text-field w-full text-black-tint-20",
            hasValidationErrors && "!border-red",
            mobileFriendlySizeClass,
            className,
          )}
          type={type}
          value={value}
          {...props}
          aria-invalid={hasValidationErrors ? "true" : "false"}
          readOnly={readOnly}
          ref={inputRef}
          onChange={onChange}
          onClick={(e) => {
            if (isEditing) {
              e.stopPropagation();
              e.preventDefault();
            }
          }}
          onKeyDown={onKeyDown}
        />
        {hasValidationErrors && (
          <div className="absolute left-0 top-[50px] z-[1] rounded-lg border border-red bg-white p-2 text-xs text-red">
            {validationErrors.map((error) => (
              <div key={error}>{error}</div>
            ))}
          </div>
        )}
      </div>
      {endIcon}
    </div>
  );
};
