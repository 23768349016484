import clsx from "clsx";
import { isEqual } from "lodash";
import { type FC } from "react";
import { useSearchParams } from "react-router-dom";

import { ACCESS_ID_QUERY_PARAM, SUBMISSION_ID_QUERY_PARAM } from "components/constants";
import { AssessmentStatusIcon } from "components/materials/page/assessment/AssessmentStatusIcon";
import { isGroupPage } from "components/materials/page/groups/helpers";
import {
  getOfficialSubmissionByAccessId,
  useResponsesForPage,
} from "components/materials/page/helpers";
import { ResponseType } from "components/server-types";
import { Button } from "mds/components/Button";
import { IconText } from "mds/components/IconText";
import { Menu, MenuItem } from "mds/components/Menu";
import { ChevronDownIcon, PersonGroupIcon, PersonSingleIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import {
  selectOfficialFullSubmissionsByPageId,
  selectPageById,
  selectUsersInCurrentCourse,
} from "store/selectors";
import { useListSelector } from "store/store-hooks";
import { useFindItemBySearchParamId } from "utils/hooks/use-find-item-by-search-param-id";

interface StudentDropdownProps {
  canAuthorCourse: boolean;
  pageId: string;
}

export const StudentDropdown: FC<StudentDropdownProps> = ({ pageId, canAuthorCourse }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = useAppSelector((s) => selectPageById(s, pageId));
  const assessmentsPublished = page?.assessments_published_at !== null;
  const courseUsers = useListSelector(selectUsersInCurrentCourse);
  const officialSubmissions = useAppSelector(
    (s) => selectOfficialFullSubmissionsByPageId(s, pageId),
    isEqual,
  );
  const responses = useResponsesForPage(page);
  const isGroup = isGroupPage(page);
  const DisplayIcon = isGroup ? PersonGroupIcon : PersonSingleIcon;

  const selectedResponse = useFindItemBySearchParamId(responses, ACCESS_ID_QUERY_PARAM, true);
  if (!selectedResponse) {
    return null;
  }

  const onSelectionChange = (item: ResponseType) => {
    if (item.id) {
      searchParams.set(ACCESS_ID_QUERY_PARAM, item.id);
    } else {
      searchParams.delete(ACCESS_ID_QUERY_PARAM);
    }

    // Clear submission id if it's not for the selected response
    searchParams.delete(SUBMISSION_ID_QUERY_PARAM);

    setSearchParams(searchParams);
  };

  const officialSubmission = getOfficialSubmissionByAccessId(
    selectedResponse.id,
    courseUsers,
    officialSubmissions,
  );

  const MenuButton = (
    <Button className="gap-1" kind="tertiary" size="xs">
      <IconText
        className="!gap-1"
        iconStart={
          !officialSubmission ? (
            <DisplayIcon />
          ) : (
            <AssessmentStatusIcon
              accessId={selectedResponse.id}
              assessmentsPublished={assessmentsPublished}
              canAuthorCourse={canAuthorCourse}
              page={page}
              iconOnly
            />
          )
        }
        text={selectedResponse.title}
      />
      <ChevronDownIcon />
    </Button>
  );

  return (
    <div className="flex items-center">
      <Menu
        direction="bottom"
        menuButton={MenuButton}
        overflow="auto"
        position="initial"
        onItemClick={(e) => {
          const response = e.value as ResponseType;
          onSelectionChange(response);
        }}
      >
        {responses.map((response) => {
          const selected = response.id === selectedResponse.id;
          const submission = getOfficialSubmissionByAccessId(
            response.id,
            courseUsers,
            officialSubmissions,
          );
          return (
            <MenuItem
              checked={selected}
              className={clsx({
                selected,
                "icon-blue": submission,
              })}
              key={response.id}
              value={response}
            >
              <IconText
                className="!gap-1"
                iconStart={
                  !submission ? (
                    <DisplayIcon />
                  ) : (
                    <AssessmentStatusIcon
                      accessId={response.id}
                      assessmentsPublished={assessmentsPublished}
                      canAuthorCourse={canAuthorCourse}
                      page={page}
                      iconOnly
                    />
                  )
                }
                text={response.title}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};
