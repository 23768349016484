import { type FC } from "react";

import { AssessmentDisplayView } from "./AssessmentDisplayView";

import { AssessmentGradingView } from "components/materials/page/assessment/AssessmentGradingView";
import { PageType } from "components/server-types";
import { useAppSelector } from "store/index";
import { selectIsAssessing } from "store/selectors";

interface AssessmentProps {
  page: PageType;
}

export const Assessment: FC<AssessmentProps> = ({ page }) => {
  const isAssessing = useAppSelector(selectIsAssessing);
  if (isAssessing) return <AssessmentGradingView page={page} />;
  return <AssessmentDisplayView page={page} />;
};
