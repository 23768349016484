import { FC, useState } from "react";

import { t } from "i18n/i18n";
import { Banner } from "mds/components/Banner";

interface StudentsNotInGroupBannerProps {
  onReviewGroups: () => void;
}

export const StudentsNotInGroupBanner: FC<StudentsNotInGroupBannerProps> = ({ onReviewGroups }) => {
  const [showBanner, setShowBanner] = useState(true);

  return showBanner ? (
    <Banner
      actionButtonText={t("warning.review_groups")}
      className="group-card"
      kind="warning"
      onAction={onReviewGroups}
      onDismiss={() => setShowBanner(false)}
    >
      {t("warning.not_assigned_to_groups")}
    </Banner>
  ) : null;
};
