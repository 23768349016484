import { compareDesc } from "date-fns";
import { partition } from "lodash";

import {
  getGroupIdFromAccessId,
  getStudentIdFromAccessId,
  isGroupAccessId,
  isStudentAccessId,
} from "../../worksheets/shared/access-id";

import {
  createAppSelector,
  selectAssessments,
  selectCourseUsers,
  selectCurrentUserId,
  selectOutcomeAssessments,
  selectPageGroupUsers,
  selectPageOutcomes,
  selectPages,
  selectSubmissions,
} from "./base";
import { selectCurrentCourseUser, selectPagesForCurrentCourse } from "./course";
import { selectPagesForOutcome, selectPagesForTopic } from "./topics";
import {
  FullSubmissionType,
  NestedAssessmentType,
  PageWithAssessmentsType,
  PagesWithOutcomeAssessmentType,
} from "./types";

import {
  isGroupPage,
  isIndividualPage,
  isSharedClassPage,
} from "components/materials/page/groups/helpers";
import { sortSubmissions } from "components/materials/page/helpers";
import { AssessmentType, PageCategory, PageType, SubmissionType } from "components/server-types";

// TODO: There's extraneous data we're getting from the server, and we should fix it + clean up our selectors.
//  1. We may have allowed users to have submissions on group and individual submissions on the same page in the past.
//  We should ensure there is backend logic to validate that this can't happen, and delete any invalid data.
//  2. We are unfortunately returning submissions that are from instructors (this can happen when admins switch
//  between course user types student <-> instructor, etc.) as well as students, but we aren't filtering the
//  submission data to only show student data. There is a short-term fix in the selectSubmissionsForPage selector
//  to filter the submissions by the course_user's role type, which works for individual submissions, but not
//  group ones. We should come up for a fix for group submissions, filter them in the backend (or here), delete any
//  invalid data in our system, have a way to automatically delete this data on switch perhaps.
//  3. We should tighten up our assessment and submissions filters so that they reuse selectSubmissionsForPage when
//  they can, and resolve some of our many submission types.
//  4. There are two different ways to access assessment mode for instructors, the Assess button next to a page item
//  on the sidebar and the Assess button on the banner on the PageOverviewHeader. The assess button is calculated via
//  backend logic, while the other is calculated via frontend logic. We ensure that they are both in sync and are
//  using the same logic to calculate assessment mode.

/* *************************** Outcome Assessments **************************** */
export const selectOutcomeAssessmentByAssessmentId = createAppSelector(
  [(state, assessmentId: string) => assessmentId, selectOutcomeAssessments],
  (assessmentId, outcomeAssessments) => {
    return outcomeAssessments.filter((oa) => oa.assessment_id === assessmentId);
  },
);

/* *************************** Assessments **************************** */
export const selectAssessmentsByPageId = createAppSelector(
  [(state, pageId: string) => pageId, selectAssessments, (state) => state.submissions],
  (pageId, assessments, submissions): AssessmentType[] => {
    return assessments.filter((assessment) => {
      const submission = submissions[assessment.submission_id];
      return submission?.page_id === pageId;
    });
  },
);

export const selectAssessmentBySubmissionId = createAppSelector(
  [(state, submissionId: string) => submissionId, selectAssessments],
  (submissionId, assessments) => {
    return assessments.find((a) => a.submission_id === submissionId);
  },
);

export const selectCurrentUserAssessments = createAppSelector(
  [
    selectCurrentCourseUser,
    selectAssessments,
    (state) => state.submissions,
    selectOutcomeAssessments,
    selectPageGroupUsers,
  ],
  (
    courseUser,
    assessments,
    submissions,
    outcomeAssessments,
    pageGroupUsers,
  ): NestedAssessmentType[] => {
    const myGroupUsers = pageGroupUsers.filter((pgu) => pgu.course_user_id === courseUser?.id);

    const mySubmissions = Object.values(submissions).filter(
      (submission) =>
        submission.course_user_id === courseUser?.id ||
        myGroupUsers.some((pgu) => pgu.page_group_id === submission.page_group_id),
    );

    const myAssessments = assessments.filter((assessment) =>
      mySubmissions.some((s) => s.id === assessment.submission_id),
    );

    return myAssessments.map((assessment) => {
      const submission = mySubmissions.find((s) => s.id === assessment.submission_id);
      const outcomeAssessmentsForAssessment = outcomeAssessments.filter(
        (oa) => oa.assessment_id === assessment.id,
      );
      const nestedAssessment: NestedAssessmentType = {
        ...assessment,
        submission,
        outcome_assessments: outcomeAssessmentsForAssessment,
      };
      return nestedAssessment;
    });
  },
);

/* *************************** Submissions **************************** */
export const selectSubmissionsForPage = createAppSelector(
  [(state, pageId: string) => pageId, selectSubmissions, (state) => state.course_users],
  (pageId, submissions, course_users): FullSubmissionType[] =>
    submissions
      .filter((submission) => submission.page_id === pageId)
      .map((submission) => {
        const course_user = course_users[submission.course_user_id];
        return {
          course_user,
          ...submission,
        };
      })
      .filter(
        (submission) => submission.page_group_id || submission.course_user.role === "student",
      ),
);

export const selectUserSubmissionsForPage = createAppSelector(
  [
    (state, page: PageType) => page,
    (state, page: PageType, selectedAccessId?: string) => selectedAccessId,
    selectCurrentUserId,
    selectCourseUsers,
    selectPageGroupUsers,
    (state, page: PageType) => selectSubmissionsForPage(state, page?.id),
    (state, page: PageType) => selectAssessmentsByPageId(state, page?.id),
  ],
  (page, selectedAccessId, userId, courseUsers, pageGroupUsers, submissions, assessments) => {
    if (
      !page ||
      isSharedClassPage(page) ||
      (selectedAccessId && isIndividualPage(page) && !isStudentAccessId(selectedAccessId)) ||
      (selectedAccessId && isGroupPage(page) && !isGroupAccessId(selectedAccessId))
    ) {
      return [];
    }

    if (isIndividualPage(page)) {
      const studentId = selectedAccessId ? getStudentIdFromAccessId(selectedAccessId) : userId;

      const userSubmissions = submissions.filter(
        (submission) => submission.course_user?.user_id === studentId,
      );

      const submissionsWithAssessments: FullSubmissionType[] = userSubmissions.map((submission) => {
        const assessment = assessments.find((a) => a.submission_id === submission.id);
        return { ...submission, assessment };
      });

      return sortSubmissions(submissionsWithAssessments);
    }

    let groupId: string;
    if (selectedAccessId) {
      groupId = getGroupIdFromAccessId(selectedAccessId);
    } else {
      const courseUser = courseUsers.find((cu) => cu.user_id === userId);
      const pageGroup = pageGroupUsers.find(
        (pgu) => pgu.course_user_id === courseUser?.id && pgu.is_ready,
      );
      groupId = pageGroup?.page_group_id;
    }

    const groupSubmissions = submissions.filter(
      (submission) => submission.page_group_id === groupId,
    );

    const groupSubmissionsWithAssessments = groupSubmissions.map((submission) => {
      const assessment = assessments.find((a) => a.submission_id === submission.id);
      return { ...submission, assessment };
    });

    return sortSubmissions(groupSubmissionsWithAssessments);
  },
);

export const selectUserOfficialSubmissionForPage = createAppSelector(
  [
    (state, page: PageType, selectedAccessId: string) =>
      selectUserSubmissionsForPage(state, page, selectedAccessId),
  ],
  (submissions) => {
    return submissions.find((submission) => submission.official_at);
  },
);

export const selectAssessedSubmissionsByTopicId = createAppSelector(
  [selectPagesForTopic, selectAssessments, selectSubmissions],
  (pages, assessments, submissions) => {
    const pageIds = pages.map((page) => page.id);
    return submissions
      .filter((s) => pageIds.includes(s.page_id))
      .filter((s) => assessments.find((a) => a.submission_id === s.id));
  },
);

export const selectAssessedSubmissionsByPageId = createAppSelector(
  [selectAssessmentsByPageId, selectSubmissions],
  (assessments, submissions) => {
    return submissions.filter((s) => assessments.find((a) => a.submission_id === s.id));
  },
);

export const selectSortedUserFullSubmissionsForPage = createAppSelector(
  [
    (state, page: PageType, selectedAccessId: string) =>
      selectUserSubmissionsForPage(state, page, selectedAccessId),
    selectAssessments,
    selectOutcomeAssessments,
  ],
  (submissions, assessments, outcome_assessments): FullSubmissionType[] => {
    return submissions
      .toSorted((a, b) => compareDesc(a.created_at, b.created_at))
      .map((submission) => {
        const assessment = assessments.find((a) => a.submission_id === submission.id);
        const outcomeAssessments = outcome_assessments.filter(
          (oa) => oa.assessment_id === assessment?.id,
        );
        return {
          ...submission,
          assessment,
          outcome_assessments: outcomeAssessments,
        };
      });
  },
);

export const selectDefaultSubmissionForUser = createAppSelector(
  [
    (state, page: PageType, selectedAccessId: string) =>
      selectUserSubmissionsForPage(state, page, selectedAccessId),
  ],
  (submissions): SubmissionType => {
    // TODO: move this logic to helper function
    const sortedSubmissions = submissions.toSorted((a, b) =>
      a.official_at ? -1 : b.official_at ? 1 : compareDesc(a.created_at, b.created_at),
    );

    return sortedSubmissions[0];
  },
);

export const selectOfficialFullSubmissionsByPageId = createAppSelector(
  [
    (state, pageId: string) => pageId,
    (state, pageId: string) => selectSubmissionsForPage(state, pageId),
    selectAssessments,
    selectOutcomeAssessments,
  ],
  (pageId, submissions, assessments, outcome_assessments): FullSubmissionType[] => {
    if (!pageId) {
      return [];
    }
    const officialSubmissions = submissions.filter(
      (submission) => submission.official_at && submission.page_id === pageId,
    );
    return officialSubmissions.map((submission) => {
      const assessment = assessments.find((a) => a.submission_id === submission.id);
      const outcomeAssessments = outcome_assessments.filter(
        (oa) => oa.assessment_id === assessment?.id,
      );
      return {
        ...submission,
        assessment,
        outcome_assessments: outcomeAssessments,
      };
    });
  },
);

/* *************************** Pages **************************** */
export const selectCurrentUserPagesWithAssessments = createAppSelector(
  [selectCurrentUserAssessments, selectPages],
  (currentUserAssessments, pages): PageWithAssessmentsType[] => {
    return pages
      .map((p) => {
        const pageAssessments = currentUserAssessments.filter(
          (ca) => ca.submission.page_id === p.id,
        );
        if (!pageAssessments.length) {
          return null;
        }

        const [officialPageAssessments, nonOfficialPageAssessments] = partition(
          pageAssessments,
          (a) => !!a.submission.official_at,
        );

        return {
          ...p,
          assessments: officialPageAssessments,
          nonOfficialAssessments: nonOfficialPageAssessments,
        };
      })
      .filter(Boolean);
  },
);

export const selectPagesWithAssessmentsForCourseOutcome = createAppSelector(
  [
    (state, courseOutcomeId: string) => courseOutcomeId,
    selectPageOutcomes,
    selectCurrentUserAssessments,
    selectPagesForOutcome,
  ],
  (
    courseOutcomeId,
    pageOutcomes,
    currentUserAssessments,
    pagesForOutcome,
  ): PagesWithOutcomeAssessmentType[] => {
    const pageOutcomesForCourseOutcome = pageOutcomes.filter(
      (page_outcome) => page_outcome.course_outcome_id === courseOutcomeId,
    );

    return pagesForOutcome.map((page) => {
      const pageAssessments = currentUserAssessments.filter(
        (a) => a.submission.page_id === page.id,
      );
      const officialPageAssessment = pageAssessments.find((a) => a.submission.official_at);
      const pageOutcomeForCourseOutcome = pageOutcomesForCourseOutcome.find(
        (po) => po.page_id === page.id,
      );
      const outcomeAssessment = officialPageAssessment?.outcome_assessments.find(
        (oa) => oa.page_outcome_id === pageOutcomeForCourseOutcome?.id,
      );

      return {
        ...page,
        assessment: officialPageAssessment,
        outcomeAssessment,
        nonOfficialAssessments: pageAssessments,
      };
    });
  },
);

export const selectPagesWithAssessments = createAppSelector(
  [selectPagesForCurrentCourse, selectSubmissions, selectAssessments, selectOutcomeAssessments],
  (pages, submissions, assessments, outcomeAssessments): PageWithAssessmentsType[] => {
    const scoreablePages = pages.filter((p) => p.released_at);
    const pagesWithAssessments = scoreablePages.map((page) => {
      const pageSubmissions = submissions.filter((s) => s.page_id === page.id);
      const officialPageSubmissions = pageSubmissions.filter((s) => s.official_at);
      const pageAssessments = assessments.filter((a) =>
        officialPageSubmissions.some((submission) => a.submission_id === submission?.id),
      );
      const nonOfficialAssessments = pageAssessments.filter(
        (a) => !officialPageSubmissions.some((submission) => a.submission_id === submission?.id),
      );
      const nestedPageAssessments: NestedAssessmentType[] = pageAssessments.map((assessment) => ({
        ...assessment,
        submission: officialPageSubmissions.find((s) => s.id === assessment.submission_id),
        outcome_assessments: outcomeAssessments.filter((oa) => oa.assessment_id === assessment.id),
      }));
      const nestedNonOfficialAssessments: NestedAssessmentType[] = nonOfficialAssessments.map(
        (assessment) => ({
          ...assessment,
          submission: pageSubmissions.find((s) => s.id === assessment.submission_id),
          outcome_assessments: outcomeAssessments.filter(
            (oa) => oa.assessment_id === assessment.id,
          ),
        }),
      );

      const ret: PageWithAssessmentsType = {
        ...page,
        assessments: nestedPageAssessments,
        nonOfficialAssessments: nestedNonOfficialAssessments,
      };
      return ret;
    });
    pagesWithAssessments.sort((a, b) => {
      if (a.category !== b.category) {
        const pageTypeOrder: PageCategory[] = ["assignment", "activity_page", "course_resource"];
        return pageTypeOrder.indexOf(a.category) - pageTypeOrder.indexOf(b.category);
      }
      // TODO: Preferably we'd also order these by their parent topic order
      return a.order - b.order;
    });
    return pagesWithAssessments;
  },
);

export const selectSubmissionById = createAppSelector(
  [(state) => state.submissions, (state, submissionId: string) => submissionId],
  (submissionsObj, submissionId): SubmissionType | undefined => submissionsObj[submissionId],
);

export const selectFullSubmissionById = createAppSelector(
  [
    selectSubmissionById,
    selectOutcomeAssessments,
    (state) => state.assessments,
    (state) => state.course_users,
  ],
  (
    submission,
    outcomeAssessments,
    assessmentsObj,
    courseUsersObj,
  ): FullSubmissionType | undefined => {
    if (!submission) {
      return submission;
    }

    const course_user = courseUsersObj[submission.course_user_id];
    const assessment = Object.values(assessmentsObj).find((a) => a.submission_id === submission.id);
    const outcome_assessments = outcomeAssessments.filter(
      (oa) => oa.assessment_id === assessment?.id,
    );
    return {
      ...submission,
      course_user,
      assessment,
      outcome_assessments,
    };
  },
);
