import { useEffect } from "react";
import { Link } from "react-router-dom";

import { useFetchAndSelectMyAssessments } from "components/materials/course/assessment/assessment-data";
import { tableDateFormat } from "components/materials/page/helpers";
import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { Tooltip } from "mds/components/Tooltip";
import { ChatIcon, ScoreIcon } from "mds/icons";
import { storeApi, useAppSelector } from "store/index";
import {
  NestedCourseOutcomeType,
  PagesWithOutcomeAssessmentType,
  selectActivities,
  selectCurrentCourseId,
  selectPagesWithAssessmentsForCourseOutcome,
} from "store/selectors";
import { courseContentUrl, coursePageUrl } from "utils/urls";
import { ErrorBoundary } from "worksheets/views/ErrorBoundary";

/**
 * Displays a list of all pages that are attached to the course outcome,
 * filterable by page type, and collapsible by topic.
 */
export const OutcomeStudentUseIndex = ({
  courseOutcome,
}: {
  courseOutcome: NestedCourseOutcomeType;
}) => {
  const courseId = useAppSelector(selectCurrentCourseId);
  const activities = useAppSelector(selectActivities);
  const pageAssessmentsForCourseOutcome: PagesWithOutcomeAssessmentType[] = useAppSelector((s) =>
    selectPagesWithAssessmentsForCourseOutcome(s, courseOutcome.id),
  );

  useFetchAndSelectMyAssessments();

  useEffect(() => {
    if (courseOutcome?.id) {
      storeApi.page_outcomes.list({ course_outcome_id: courseOutcome.id });
    }
  }, [courseOutcome?.id]);

  const hrefForPage = (page: PageType) => {
    if (page.category !== "activity_page") {
      return coursePageUrl(courseId, page.category, page.id);
    }
    const topicId = activities.find((a) => page.activity_id === a.id)?.topic_id;
    return courseContentUrl(courseId, topicId, page.id);
  };

  if (!pageAssessmentsForCourseOutcome.length) {
    return null;
  }

  return (
    // TODO: Shared classes should be extracted
    <div className="assessment-dashboard-table pb-6">
      <h2 className="h3 m-0 text-black-tint-40">
        {t("assessment_dashboard.student_outcome_page_list.title")}
      </h2>
      <table className="mds-table">
        <thead>
          {/* Header like Page/Date Assessed/Available Points/Score */}
          <tr>
            <th className="th-l">
              {t("assessment_dashboard.student_outcome_page_list.page_header_title")}
            </th>
            <th className="th-s">
              {t("assessment_dashboard.student_outcome_page_list.date_assessed_header_title")}
            </th>
            <th className="th-s">
              {t("assessment_dashboard.student_outcome_page_list.available_points_header_title")}
            </th>
            <th className="th-xs">
              {t("assessment_dashboard.student_outcome_page_list.score_header_title")}
            </th>
          </tr>
        </thead>
        <ErrorBoundary minimal>
          <tbody className="body-xs">
            {pageAssessmentsForCourseOutcome.map((page) => {
              const assessment = page.assessment;
              const outcomeScore = page.outcomeAssessment?.score;
              const dateAssessed = assessment?.created_at && tableDateFormat(assessment.created_at);

              return (
                <tr key={page.id}>
                  <td className="flex w-full justify-start gap-2">
                    <Link className="block truncate" to={hrefForPage(page)}>
                      {page.title}
                    </Link>
                    {assessment && (
                      <Tooltip element={<ChatIcon />} key={assessment.id}>
                        <div className="body-xs text-black-tint-40">{assessment.comment}</div>
                      </Tooltip>
                    )}
                  </td>
                  <td>{dateAssessed || "-"}</td>
                  <td>{page.max_points ?? "-"}</td>
                  <td>{outcomeScore ? <ScoreIcon value={outcomeScore} /> : "-"}</td>
                </tr>
              );
            })}
          </tbody>
        </ErrorBoundary>
      </table>
    </div>
  );
};

export const OutcomeUseListPageItem = ({ page, href }: { page: PageType; href: string }) => {
  return (
    <div className="body-s flex items-center justify-between">
      <Link to={href}>{page.title}</Link>
      {page.max_points > 0 && (
        <div className="text-black-tint-40">
          {t("rubric_card.assessment_points_other", { count: page.max_points })}
        </div>
      )}
    </div>
  );
};
