import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const VerifiedIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <g clipPath="url(#clip0_2679_182064)">
      <path
        className="fill"
        d="M16 7.99238L14.2255 5.86667L14.4727 3.05524L11.8473 2.43048L10.4727 0L8 1.11238L5.52727 0L4.15273 2.43048L1.52727 3.04762L1.77455 5.86667L0 7.99238L1.77455 10.1181L1.52727 12.9371L4.15273 13.5619L5.52727 16L8 14.88L10.4727 15.9924L11.8473 13.5619L14.4727 12.9371L14.2255 10.1257L16 7.99238Z"
      />
      <path
        d="M7.03333 9.1667L5.48667 7.61337L4.5 8.6067L7.03333 11.1467L11.9267 6.24004L10.94 5.2467L7.03333 9.1667Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2679_182064">
        <rect fill="white" height="16" width="16" />
      </clipPath>
    </defs>
  </svg>
);
