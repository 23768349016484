import { useEffect } from "react";
import { useNavigate } from "react-router";

import {
  CODE_LANG_OPTIONS,
  CodeKernelLanguage,
  DEFAULT_CODE_KERNEL_LANG,
} from "../../../worksheets/shared/code-kernels";

import { showDeleteConfirmationModal } from "components/modals/DeleteCourseModal";
import { i18n, t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { Card } from "mds/components/Card";
import { DropdownMenu } from "mds/components/DropdownMenu";
import { ToggleButton } from "mds/components/ToggleButton";
import { CircleExclamationIcon, CircleSuccessGreenIcon } from "mds/icons";
import { storeApi, useAppSelector } from "store/index";
import { selectCourseCodeById, selectCurrentCourse, selectTermById } from "store/selectors";
import { toastLocalizedOperationCatcher, toastSuccessMessage } from "utils/alerts";
import { trackEvent } from "utils/amplitude";
import { updateCourse } from "utils/course";
import { orgHomeUrl } from "utils/urls";

export const SettingsSidebarPanel = () => {
  const currentCourse = useAppSelector(selectCurrentCourse);
  const courseCode = useAppSelector((s) => selectCourseCodeById(s, currentCourse?.course_code_id));
  const term = useAppSelector((s) => selectTermById(s, currentCourse?.term_id));
  const navigate = useNavigate();

  useEffect(() => {
    i18n
      .loadLanguages(["en", "es"])
      .catch(toastLocalizedOperationCatcher("failed_to_load_language_options"));
  }, []);

  useEffect(() => {
    storeApi.terms.retrieve(currentCourse?.term_id);
  }, [currentCourse]);

  const handleCourseDelete = async () => {
    trackEvent("Course settings - Delete course", { eventCategory: "Button press" });
    await showDeleteConfirmationModal({ course: currentCourse, courseCode });

    navigate(orgHomeUrl);
  };

  const toggleAnonymousGradingEnabled = (anonymousGradingEnabled: boolean) => {
    trackEvent("Course settings - Enable anonymous grading", {
      eventCategory: "Button press",
      isEnabled: anonymousGradingEnabled,
    });
    updateCourse({
      id: currentCourse.id,
      anonymous_grading_enabled: anonymousGradingEnabled,
    });
  };

  const toggleAIAssistantForStudentEnabled = (studentAiPanelEnabled: boolean) => {
    trackEvent("Course settings - Enable AI for students", {
      eventCategory: "Button press",
      isEnabled: studentAiPanelEnabled,
    });
    updateCourse({
      id: currentCourse.id,
      student_ai_assistant_enabled: studentAiPanelEnabled,
    });
  };

  const toggleCodeBlocksEnabled = (codeBlocksEnabled: boolean) => {
    trackEvent("Course settings - Enable code blocks", {
      eventCategory: "Button press",
      isEnabled: codeBlocksEnabled,
    });
    updateCourse({
      id: currentCourse.id,
      code_blocks_enabled: codeBlocksEnabled,
    });
  };

  const handleCodeBlocksDefaultLanguageChange = (lang: CodeKernelLanguage) => {
    trackEvent("Programming language changed", {
      eventCategory: "General",
      newLanguage: lang,
    });
    updateCourse({
      id: currentCourse.id,
      code_blocks_default_language: lang,
    });
  };

  const handleCourseUnlink = () => {
    trackEvent("Course settings - Unlink course", {
      eventCategory: "Button press",
      lmsSectionId: currentCourse.lms_section_id,
    });
    updateCourse({
      id: currentCourse.id,
      lms_section_id: null,
    }).then(() => {
      toastSuccessMessage(t("success.toasts.unlink_course_success"));
    });
  };

  return (
    <>
      <div className="content-sidebar-header flex items-center">
        <h3 className="mb-0">{t("settings.settings")}</h3>
      </div>

      <div className="content-sidebar-main">
        <Card>
          <h3>{t("glossary.course_info")}</h3>
          <div className="body-s mt-2 flex flex-col gap-1">
            <div>
              {t("common.course_code_and_course_title", {
                course_code: courseCode?.title,
                course_title: currentCourse?.title,
              })}
            </div>
            <div>{t("common.term_title_format", { term: term?.title })}</div>
          </div>
        </Card>

        <Card className="my-3">
          <h3 className="text-black-tint-40">{t("settings.course_settings")}</h3>

          <div className="ml-2">
            <div className="body-s mt-2 flex items-center">
              <ToggleButton
                id="enable-student-ai-panel"
                initialValue={currentCourse.student_ai_assistant_enabled}
                label={t("settings.enable_student_ai_assistant")}
                onChange={toggleAIAssistantForStudentEnabled}
              />
              <div className="ml-2">{t("settings.enable_student_ai_assistant")}</div>
            </div>

            <div className="body-s mt-2 flex items-center">
              <ToggleButton
                id="enable-anonymous-grading-panel"
                initialValue={currentCourse.anonymous_grading_enabled}
                label={t("settings.enable_anonymous_grading")}
                onChange={toggleAnonymousGradingEnabled}
              />
              <div className="ml-2">{t("settings.enable_anonymous_grading")}</div>
            </div>

            <div className="body-s mt-2 flex items-center">
              <ToggleButton
                id="code-blocks"
                initialValue={currentCourse.code_blocks_enabled}
                label={t("settings.enable_code_blocks")}
                onChange={toggleCodeBlocksEnabled}
              />
              <div className="ml-2">{t("settings.enable_code_blocks")}</div>
            </div>

            <div className="ml-toggle-button mt-2">
              <div className="ml-2">
                <label>
                  <span className="body-s">{t("settings.default_code_block_language")}</span>

                  <div className="ml-2 inline-block">
                    <DropdownMenu
                      disabled={!currentCourse.code_blocks_enabled}
                      items={CODE_LANG_OPTIONS}
                      selectedValue={
                        currentCourse.code_blocks_default_language || DEFAULT_CODE_KERNEL_LANG
                      }
                      onSelectionChange={({ id }) => handleCodeBlocksDefaultLanguageChange(id)}
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
        </Card>

        <Card className="my-3">
          <h3 className="mb-2 text-black-tint-40">{t("settings.lms_integration")}</h3>

          <div className="flex items-center gap-1">
            {currentCourse.lms_section_id ? (
              <>
                <CircleSuccessGreenIcon size="xs" />
                <span className="body-s text-black-tint-40">
                  {" "}
                  {t("settings.connected_to_canvas")}
                </span>
              </>
            ) : (
              <>
                <CircleExclamationIcon className="icon-orange" size="xs" />
                <span className="body-s text-black-tint-40">
                  {" "}
                  {t("settings.not_connected_to_lms")}
                </span>
              </>
            )}
          </div>
        </Card>

        <Card className="bg-red-tint-90">
          <h3 className="mb-2 text-black-tint-40">{t("settings.danger_zone")}</h3>

          <div>
            <Button kind="destructive" size="s" onClick={handleCourseDelete}>
              {t("settings.delete_course")}
            </Button>

            {currentCourse.lms_section_id && (
              <Button className="mt-2" kind="destructive" size="s" onClick={handleCourseUnlink}>
                {t("settings.unlink_course")}
              </Button>
            )}
          </div>
        </Card>
      </div>
    </>
  );
};
