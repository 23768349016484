import "./groups/GroupCardShared.scss";

import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { DueDateButton } from "./DueDateButton";
import { GroupNotAssignedBanner } from "./banners/GroupNotAssignedBanner";
import { PointsConfig } from "./outcomes/PointsConfig";

import { INDEX_NOT_FOUND } from "components/constants";
import { AssessmentBanner } from "components/materials/page/banners/AssessmentBanner";
import { StudentsNotInGroupBanner } from "components/materials/page/banners/StudentsNotInGroupBanner";
import { PageGroupingCategoryMenu } from "components/materials/page/grouping-category/PageGroupingCategoryMenu";
import { GroupConfigCard } from "components/materials/page/groups/GroupConfigCard";
import { StudentGroupCard } from "components/materials/page/groups/StudentGroupCard";
import { StudentSelfSelectCard } from "components/materials/page/groups/StudentSelfSelectCard";
import {
  GROUP_TYPE_CHOICES,
  atLeastOneStudentNotInGroup,
  findPageGroupIndexOfAccessId,
  findPageGroupIndexOfStudentUser,
  isGroupingCategory,
} from "components/materials/page/groups/helpers";
import { GroupingCategory, PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { useOnChanged } from "mds/hooks/use-on-changed";
import { ORDER_BY_CREATED, useCustomOrdered } from "mds/hooks/use-ordered";
import { storeApi, useAppSelector } from "store/index";
import {
  selectActivityStatus,
  selectAssessmentsByPageId,
  selectCanAuthorCourse,
  selectCurrentCourseId,
  selectCurrentCourseUser,
  selectIsAssessing,
  selectNestedPageOutcomes,
  selectPageGroupsByPage,
  selectUserSubmissionsForPage,
  selectUsersWithCourseActivityInCurrentCourse,
} from "store/selectors";
import { useListSelector } from "store/store-hooks";
import { toastSuccessMessage } from "utils/alerts";
import { usePageActions } from "utils/page";

type PageOverviewHeaderProps = {
  page: PageType;
  className?: string;
  isStaticContent?: boolean;
  readOnly?: boolean;
  selectedAccessId?: string;
};

export const PageOverviewHeader: FC<PageOverviewHeaderProps> = ({
  page,
  className,
  isStaticContent,
  readOnly = false,
  selectedAccessId,
}) => {
  const [searchParams] = useSearchParams();
  const { savePage } = usePageActions();
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const isAssessing = useAppSelector(selectIsAssessing);
  const courseId = useAppSelector(selectCurrentCourseId);

  const courseUser = useAppSelector(selectCurrentCourseUser);
  const pageGroups = useCustomOrdered(
    useAppSelector((s) => selectPageGroupsByPage(s, page.id)),
    ORDER_BY_CREATED,
  );
  const pageOutcomes = useAppSelector((s) => selectNestedPageOutcomes(s, page.id));
  const assessments = useAppSelector((s) => selectAssessmentsByPageId(s, page.id));
  const studentUsers = useListSelector(selectUsersWithCourseActivityInCurrentCourse).filter(
    (user) => user.role === "student" && !user.is_disabled,
  );

  const [showGroupConfigurationCard, setShowGroupConfigurationCard] = useState(false);
  const [showStudentGroupCard, setShowStudentGroupCard] = useState(true);

  const submissions = useListSelector((s) => selectUserSubmissionsForPage(s, page));

  const pageGroupIndex = !canAuthorCourse
    ? findPageGroupIndexOfStudentUser(pageGroups, courseUser)
    : findPageGroupIndexOfAccessId(pageGroups, selectedAccessId);

  const isShowingStudentSelfSelectCard =
    !canAuthorCourse &&
    page.grouping_category === GroupingCategory.SELF_ASSIGN &&
    pageGroupIndex === INDEX_NOT_FOUND;

  const canSeeStudentGroupCard =
    (!canAuthorCourse &&
      GROUP_TYPE_CHOICES.includes(page.grouping_category) &&
      pageGroupIndex !== INDEX_NOT_FOUND) ||
    (canAuthorCourse &&
      GROUP_TYPE_CHOICES.includes(page.grouping_category) &&
      (!isAssessing || (isAssessing && page.assessments_published_at !== null)));

  const isShowingStudentGroupCard =
    showStudentGroupCard && canSeeStudentGroupCard && !showGroupConfigurationCard;

  const isShowingGroupConfigurationCard = showGroupConfigurationCard && !isShowingStudentGroupCard;

  const isShowingCurrentStudentNotInGroupBanner =
    !canAuthorCourse &&
    page.grouping_category === GroupingCategory.ASSIGN_STUDENTS &&
    pageGroupIndex === INDEX_NOT_FOUND;

  const userActivity = useAppSelector(selectActivityStatus);
  const isShowingStudentsNotInGroupBanner =
    !readOnly &&
    canAuthorCourse &&
    isGroupingCategory(page.grouping_category) &&
    atLeastOneStudentNotInGroup(pageGroups, studentUsers, userActivity);

  const searchParamsString = searchParams.toString();
  const canConfigurePoints = pageOutcomes.length > 0 && canAuthorCourse;
  const isPointsReadOnly = readOnly || assessments.length > 0 || !canAuthorCourse;

  useEffect(() => {
    setShowGroupConfigurationCard(false);
    setShowStudentGroupCard(true);
  }, [searchParamsString, selectedAccessId, setShowGroupConfigurationCard]);

  // Notify students when they've submitted a late submission or when the instructor has collected their submission.
  useOnChanged(
    submissions,
    (newSubmissions, oldSubmissions) => {
      const diff = newSubmissions.length - oldSubmissions.length;
      if (diff > 0) {
        toastSuccessMessage(t("success.toasts.submission_success", { count: diff }));
      }
    },
    true,
    page?.id,
  );

  useEffect(() => {
    if (isGroupingCategory(page.grouping_category)) {
      storeApi.page_group_users.list({ page_group__page_id: page.id });
      storeApi.page_groups.list({ page_id: page.id });
    }
  }, [page.grouping_category, page.id, courseId]);

  useEffect(() => {
    setShowGroupConfigurationCard(false);
  }, [isAssessing]);

  // Refresh page group data when window regains focus
  // This is a temporary fix for issues where group distribution looks different for different users, possibly due to
  // issues with pubsub. If it's not due to pubsub, this fix will likely do nothing. Wait and re-evaluate.
  // TODO: Store: If we have versioning in websocket messages and a replay feature,
  // we should replay missed messages instead.
  useEffect(() => {
    if (
      !page?.id ||
      [GroupingCategory.INDIVIDUAL, GroupingCategory.SHARED_CLASS].includes(page.grouping_category)
    ) {
      return;
    }
    const fetchPageGroupData = () => {
      return Promise.all([
        storeApi.page_group_users.list(
          { page_group__page_id: page.id },
          { skipCache: true, skipToast: true },
        ),
        storeApi.page_groups.list({ page_id: page.id }, { skipCache: true, skipToast: true }),
      ]);
    };

    window.addEventListener("focus", fetchPageGroupData);
    return () => {
      window.removeEventListener("focus", fetchPageGroupData);
    };
  }, [page.grouping_category, page.id, courseId]);

  if (!page) {
    return null;
  }

  const onChangeGroupingCategory = (groupingCategory: GroupingCategory) => {
    const isNowGrouped = isGroupingCategory(groupingCategory);
    if (groupingCategory === page.grouping_category) {
      if (isNowGrouped) {
        setShowGroupConfigurationCard(!showGroupConfigurationCard);
      }
      return;
    }

    if (isNowGrouped) {
      // If there are no page groups, create two.
      if (pageGroups.length === 0) {
        storeApi.page_groups.create({
          page_id: page.id,
        });
        storeApi.page_groups.create({
          page_id: page.id,
        });
      }

      setShowGroupConfigurationCard(!showGroupConfigurationCard);
    } else {
      // TODO: It's unhandled when one of the page groups fail to be removed. This should also be a batch delete
      //  all or delete many. And: should we really be deleting all page groups when the user switches to a
      //  non-group grouping type?
      pageGroups.forEach((pageGroup) => {
        storeApi.page_groups.destroy(pageGroup.id);
      });

      setShowGroupConfigurationCard(false);
    }

    storeApi.pages.partial_update({ id: page.id, grouping_category: groupingCategory });
  };

  const pointsMenu =
    (canAuthorCourse && !isStaticContent && !readOnly) || page?.max_points > 0 ? (
      <PointsConfig
        disabled={!canConfigurePoints}
        page={page}
        readOnly={isPointsReadOnly}
        size="xs"
        title={
          canAuthorCourse && assessments.length > 0
            ? t("tooltip.points_config_has_assessments")
            : canAuthorCourse && pageOutcomes.length === 0
              ? t("page_overview_header.disabled_info")
              : t("tooltip.assessment_points")
        }
        onSave={(points) => savePage({ id: page.id, max_points: points })}
      />
    ) : null;

  const GroupingMenu = isStaticContent ? null : (
    <PageGroupingCategoryMenu
      page={page}
      pageGroupIndex={pageGroupIndex}
      readOnly={readOnly}
      onAuthorClick={onChangeGroupingCategory}
      onStudentGroupCardToggle={
        GROUP_TYPE_CHOICES.includes(page.grouping_category) &&
        !isShowingCurrentStudentNotInGroupBanner &&
        canSeeStudentGroupCard
          ? () => setShowStudentGroupCard(!showStudentGroupCard)
          : null
      }
    />
  );

  return (
    <div className={clsx("flex w-full flex-col items-center px-4", className)}>
      <div
        className={clsx(
          "flex w-full max-w-[--page-default-max-width] flex-col",
          !readOnly && "gap-5",
        )}
      >
        <div className="flex gap-2">
          <div className="flex flex-wrap items-center gap-2">
            {GroupingMenu}
            {pointsMenu}
            <DueDateButton page={page} readOnly={!canAuthorCourse || readOnly} />
          </div>
        </div>

        {isShowingStudentGroupCard && (
          <div className={className}>
            <StudentGroupCard
              buttonComponent={
                <Button kind="primary" size="xs" onClick={() => setShowStudentGroupCard(false)}>
                  {t("common.close")}
                </Button>
              }
              pageGroup={pageGroups[pageGroupIndex]}
              pageGroupIndex={pageGroupIndex}
              isActive
              shouldFilterForReadiedUsers
            />
          </div>
        )}

        {isShowingCurrentStudentNotInGroupBanner && <GroupNotAssignedBanner />}

        {isShowingStudentsNotInGroupBanner && (
          <StudentsNotInGroupBanner onReviewGroups={() => setShowGroupConfigurationCard(true)} />
        )}

        {isShowingGroupConfigurationCard && (
          <div className={className}>
            <GroupConfigCard
              page={page}
              pageGroups={pageGroups}
              setShowGroupConfigurationCard={setShowGroupConfigurationCard}
            />
          </div>
        )}

        {isShowingStudentSelfSelectCard && (
          <div className={className}>
            <StudentSelfSelectCard page={page} pageGroups={pageGroups} />
          </div>
        )}

        <AssessmentBanner className={className} page={page} />
      </div>
    </div>
  );
};
