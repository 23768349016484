import { FC } from "react";

import { IconColorProps, initializeIconColorProps } from "mds/utils/images";

export const DoubleSparkleIcon: FC<IconColorProps> = (props) => {
  const { fillClassName, ...svgProps } = initializeIconColorProps(props);
  return (
    <svg {...svgProps}>
      <path
        className={fillClassName}
        clipRule="evenodd"
        d="M12.1745 6.33374C12.3586 6.33374 12.5078 6.48298 12.5078 6.66707C12.5078 9.53268 14.9159 11.0051 16.6151 11.0051C16.7992 11.0051 16.9484 11.1543 16.9484 11.3384C16.9484 11.5225 16.7992 11.6717 16.6151 11.6717C14.9449 11.6717 12.5078 13.1151 12.5078 16.0674C12.5078 16.2515 12.3586 16.4007 12.1745 16.4007C11.9904 16.4007 11.8411 16.2515 11.8411 16.0674C11.8411 13.1377 9.51246 11.6717 7.73385 11.6717C7.54975 11.6717 7.40051 11.5225 7.40051 11.3384C7.40051 11.1543 7.54975 11.0051 7.73385 11.0051C9.48799 11.0051 11.8411 9.5351 11.8411 6.66707C11.8411 6.48298 11.9904 6.33374 12.1745 6.33374Z"
        fillRule="evenodd"
      />
      <path
        className={fillClassName}
        clipRule="evenodd"
        d="M6.13803 4.11108C6.32212 4.11108 6.47136 4.26032 6.47136 4.44442C6.47136 5.75232 7.59243 6.43241 8.38718 6.43241C8.57127 6.43241 8.72051 6.58165 8.72051 6.76574C8.72051 6.94984 8.57127 7.09908 8.38718 7.09908C7.6072 7.09908 6.47136 7.7647 6.47136 9.11573C6.47136 9.29982 6.32212 9.44906 6.13803 9.44906C5.95393 9.44906 5.80469 9.29982 5.80469 9.11573C5.80469 7.77289 4.72031 7.09908 3.88888 7.09908C3.70478 7.09908 3.55554 6.94984 3.55554 6.76574C3.55554 6.58165 3.70478 6.43241 3.88888 6.43241C4.7101 6.43241 5.80469 5.75471 5.80469 4.44442C5.80469 4.26032 5.95393 4.11108 6.13803 4.11108Z"
        fillRule="evenodd"
      />
    </svg>
  );
};
