import { FC } from "react";

import {
  useAssessedCountData,
  useAssessmentMode,
  useAssessmentPublishActions,
} from "../../../../utils/assessment";

import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";

interface AssessmentGradingLastPanelProps {
  setShowLastPanel: (show: boolean) => void;
  page: PageType;
}

// This is the last panel in the assessment grading workflow. It can only be accessed
// if the assessments haven't been published yet.
export const AssessmentGradingLastPanel: FC<AssessmentGradingLastPanelProps> = ({
  setShowLastPanel,
  page,
}) => {
  const { publishGrades } = useAssessmentPublishActions(page);
  const { stopAssessing } = useAssessmentMode(page);
  const { count, total } = useAssessedCountData(page);

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4">
      <div className="text-sm font-normal leading-[1.3125rem]">
        {t("submission_assessment.last_panel_description", { count, total })}
      </div>
      <Button
        color="green"
        kind="primary"
        size="s"
        fullWidth
        onClick={() => {
          setShowLastPanel(false);
          publishGrades();
          stopAssessing();
        }}
      >
        {t("assessment.publish_page_assessments")}
      </Button>
      <Button
        kind="secondary"
        size="s"
        fullWidth
        onClick={() => {
          setShowLastPanel(false);
        }}
      >
        {t("assessment.edit_assessments")}
      </Button>
    </div>
  );
};
