import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const CloudConnectedIcon: FC<IconProps> = (props) => {
  return (
    <svg {...initializeSvgIconProps(props)}>
      <path
        className="stroke"
        d="M 15.375977 14.277344 L 15.859375 14.277344 C 17.143555 14.277344 18.427734 13.134766 18.427734 11.992188 C 18.427734 9.990234 17.001953 9.418945 15.859375 9.418945 C 16.049805 8.515625 16.000977 6.59668 13.999023 5.419922 C 11.68457 4.057617 9.287109 5.419922 8.569336 6.850586 C 7.998047 6.542969 6.713867 6.132812 5.429688 6.992188 C 4.140625 7.84668 4.140625 9.135742 4.140625 9.707031 C 2.998047 9.707031 1.430664 10.136719 1.430664 12.133789 C 1.430664 13.564453 2.856445 14.277344 4.140625 14.277344 L 5 14.277344 "
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="4"
        strokeWidth="1.25"
        transform="matrix(0.8,0,0,0.8,0,0)"
      />
      <path
        className="stroke"
        d="M 8.125 13.500977 L 10 15.375977 L 13.125 11.625977 "
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="4"
        strokeWidth="1.25"
        transform="matrix(0.8,0,0,0.8,0,0)"
      />
    </svg>
  );
};
