import { createId } from "../../../../worksheets/shared/utils";

import { SCALING_FACTOR } from "./ProjectorScroller";

import { PROJECTOR_PATH_REGEX, courseProjectorUrl } from "utils/urls";

export const PROJECTOR_ID_KEY = "ForumProjectorTabId";

const getProjectorId = () => localStorage.getItem(PROJECTOR_ID_KEY);
const setProjectorId = (id: string) => localStorage.setItem(PROJECTOR_ID_KEY, id);
const removeProjectorId = () => localStorage.removeItem(PROJECTOR_ID_KEY);

export enum WindowMessages {
  PAGE_SCROLL = "page_scroll",
  REQUEST_FRAME = "request_frame",
  WINDOW_FRAME = "window_frame",
  SESSION_SYNC = "session_sync",
  ASSET_CHANGE = "asset_change",
  VIDEO_CHANGE = "video_change",
}

export type PageScrollMessage = {
  type: WindowMessages.PAGE_SCROLL;
  blockMarkerId?: string;
  relativeBlockOffsetY?: number;
  scrollX: number;
  scrollY: number;
  scrollHeight?: number;
};

export type AssetChangeMessage = {
  type: WindowMessages.ASSET_CHANGE;
  scrollX?: number;
  scrollY?: number;
  pageNumber?: number;
  zoom?: number;
};

export type VideoChangeMessage = {
  type: WindowMessages.VIDEO_CHANGE;
  id?: string;
  playing?: boolean;
  currentTime?: number;
  playbackRate?: number;
};

export type ViewportDimensions = {
  width: number;
  height: number;
};

export type RequestFrameMessage = {
  type: WindowMessages.REQUEST_FRAME;
};

export type SyncClassSessionMessage = {
  type: WindowMessages.SESSION_SYNC;
};

export type WindowFrameMessage = {
  type: WindowMessages.WINDOW_FRAME;
  viewport: ViewportDimensions;
};

type ProjectorViewMessageType =
  | PageScrollMessage
  | AssetChangeMessage
  | VideoChangeMessage
  | RequestFrameMessage
  | SyncClassSessionMessage;
type InstructorViewMessageType = WindowFrameMessage;

export const isProjectorView = () => {
  return PROJECTOR_PATH_REGEX.test(window.location.pathname);
};

// TODO: Consider caching this with a mutationObserver
export const getBlockChildren = (div: HTMLDivElement): HTMLDivElement[] => {
  return Array.from(div.querySelectorAll("div[id^=block-],div[id^=outcome-]"));
};

export const getUserBlockChildren = (div: HTMLDivElement, blockId: string): HTMLDivElement[] => {
  return Array.from(div.querySelectorAll(`div[id^=user${blockId}-]`));
};

type BlockOffset = {
  id: string;
  relativeOffsetY: number;
  block?: HTMLDivElement;
};

// This requires that our root ancestor have a non-static position
// defined so that any offset calculations will start from that
// component
export const getOffsetFromScroller = (
  child: HTMLDivElement | null,
  ancestor: HTMLDivElement | null = null,
): number => {
  let parent = child?.offsetParent as HTMLElement | null;
  let offset = 0;

  while (parent && parent !== ancestor) {
    offset += parent?.offsetTop || 0;
    parent = parent?.offsetParent as HTMLElement | null;
  }

  return offset;
};

export const getClosestBlock = (div: HTMLDivElement): BlockOffset => {
  const blocks = getBlockChildren(div);
  const blockOffset = getOffsetFromScroller(blocks[0], div);
  const scrollTop = div.scrollTop - blockOffset;

  let previousBlock = { id: "", relativeOffsetY: Infinity } as BlockOffset;

  let closestBlock = blocks.reduce(
    (currentClosestBlock, block) => {
      const relativeOffsetY = scrollTop - block.offsetTop;

      if (relativeOffsetY > 0 && relativeOffsetY < previousBlock.relativeOffsetY) {
        previousBlock = { id: block.id, relativeOffsetY, block };
      }

      return Math.abs(currentClosestBlock.relativeOffsetY) > Math.abs(relativeOffsetY)
        ? {
            id: block.id,
            relativeOffsetY,
          }
        : currentClosestBlock;
    },
    { id: "", relativeOffsetY: Infinity } as BlockOffset,
  );

  if (previousBlock.id) {
    const userBlocks = getUserBlockChildren(previousBlock.block, previousBlock.id);
    if (!userBlocks.length) {
      return closestBlock;
    }

    const userBlockoffset = getOffsetFromScroller(userBlocks[0], previousBlock.block);

    closestBlock = userBlocks.reduce((currentClosestBlock, block) => {
      const relativeOffsetY =
        scrollTop - previousBlock.block.offsetTop - userBlockoffset - block.offsetTop;

      return Math.abs(currentClosestBlock.relativeOffsetY) > Math.abs(relativeOffsetY)
        ? {
            id: block.id,
            relativeOffsetY,
          }
        : currentClosestBlock;
    }, closestBlock);
  }

  return closestBlock;
};

export const createScrollMessageWithBlockMarker = (div: HTMLDivElement): PageScrollMessage => {
  const closestBlock = getClosestBlock(div);

  return {
    type: WindowMessages.PAGE_SCROLL,
    blockMarkerId: closestBlock?.id,
    relativeBlockOffsetY: closestBlock?.relativeOffsetY,
    scrollX: div.scrollLeft,
    scrollY: div.scrollTop,
    scrollHeight: div.scrollHeight,
  };
};

// Uses the blockmarker to scroll to the correct position
export const getScrollTopFromMessage = (div: HTMLDivElement, message: PageScrollMessage) => {
  const blockMarker: HTMLDivElement = div.querySelector(`#${message.blockMarkerId}`);
  if (!blockMarker) {
    return message.scrollY * SCALING_FACTOR;
  }
  const blockOffset = getOffsetFromScroller(blockMarker, div);

  return (blockMarker.offsetTop + message.relativeBlockOffsetY + blockOffset) * SCALING_FACTOR;
};

export const openProjectorView = (courseId: string) => {
  const windowId = createId();
  const tabName = `ForumProjectorTab-${windowId}`;
  setProjectorId(tabName);

  /*
   * There are concerns with using window.open as popup blockers can prevent
   * the projector view from opening and it's not guaranteed that the window
   * opens in a new tab vs a popup window:
   * https://developer.mozilla.org/en-US/docs/Web/API/Window/open#accessibility_concerns.
   * We are, however, trying to open a separate tab as the project view will need to be
   * a separate tab so it can be configured to run in a projector.
   * Also, note we are assigning the new tab to the window object as the div for scrolling
   * is not related to this component where we open the new tab.  We could have used a
   * context to store the tab object but I preferred attaching it to the window object
   * as it seemed simpler and is related to the window.
   */
  window.open(courseProjectorUrl(courseId), tabName);
};

export const confirmProjectorViewOpen = (courseId: string) => {
  const windowId = getProjectorId();

  if (!windowId) {
    openProjectorView(courseId);
  }
};

// Projector View methods
export const confirmCorrectProjectorView = () => {
  const windowId = getProjectorId();

  if (!windowId) {
    // This is now the valid projector view
    setProjectorId(window.name);
  } else if (window.name !== getProjectorId()) {
    // Another view is the valid projector view
    closeProjectorView();
  }
};

export const deregisterProjectorView = () => {
  const windowId = localStorage.getItem(PROJECTOR_ID_KEY);
  if (windowId === window.name) {
    removeProjectorId();
  }
};

export const closeProjectorView = () => {
  deregisterProjectorView();

  window.close();
};

export const projectorChannel = new BroadcastChannel("forum-projector");

// No real differences from these methods now with the broadcast channel, but we use
// the messageType to ensure we're sending only messages from and to that are appropriate
// Instructor View methods
export const sendMessageToProjectorView = (message: ProjectorViewMessageType) => {
  projectorChannel.postMessage(message);
};

export const sendMessageToInstructorView = (message: InstructorViewMessageType) => {
  projectorChannel.postMessage(message);
};

export const addProjectorMessageListener = (
  handler: (message: MessageEvent<ProjectorViewMessageType>) => void,
) => {
  projectorChannel.addEventListener("message", handler);
};

export const addInstructorMessageListener = (
  handler: (message: MessageEvent<InstructorViewMessageType>) => void,
) => {
  projectorChannel.addEventListener("message", handler);
};

export const removeProjectorMessageListener = (
  handler: (message: MessageEvent<ProjectorViewMessageType>) => void,
) => {
  projectorChannel.removeEventListener("message", handler);
};

export const removeInstructorMessageListener = (
  handler: (message: MessageEvent<InstructorViewMessageType>) => void,
) => {
  projectorChannel.removeEventListener("message", handler);
};
