import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const ArchiveFileBoxIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M15 1.93335H1V4.73335H15V1.93335Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      className="stroke"
      d="M13.1334 6.59998V14.0666H2.8667V6.59998"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      className="stroke"
      d="M10.3332 7.53339H5.6665V10.3334H10.3332V7.53339Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
  </svg>
);
