// General CSS
import "mds/css/index.scss";
import "index.css";

// React-menu CSS
// We only import the core styles. The rest is overwritten in mds/css/_menu.scss
import "@szhsin/react-menu/dist/core.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

import { createRoot } from "react-dom/client";
// eslint-disable-next-line no-restricted-imports
import { Provider as ReduxStoreProvider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
// eslint-disable-next-line no-restricted-imports
import { Slide, ToastContainer } from "react-toastify";

import { Routes } from "./routes";

import { RollbarProvider } from "providers/RollbarProvider";
import { store } from "store";
import { ErrorBoundary } from "worksheets/views/ErrorBoundary";

const root = createRoot(document.getElementById("root"));
root.render(
  // TODO: This ErrorBoundary catches any redux provider related errors. We should log
  // these to rollbar and ensure the user can recover from this.
  <ErrorBoundary minimal>
    <ReduxStoreProvider store={store}>
      {/*
        TODO This error boundary signifies a failure to render the top-level layout, independent of
        the route. It should be indicated to the user that this is not recoverable through navigation.
        We might want to ensure this is logged to rollbar with highest prio/notifications.
      */}
      <ErrorBoundary>
        <RollbarProvider>
          <Router>
            <Routes />
          </Router>
        </RollbarProvider>
      </ErrorBoundary>
    </ReduxStoreProvider>
    {/* We limit toasts to two, as it's unlikely that multiple errors are happening independently  */}
    <ToastContainer className="toast-container" limit={2} pauseOnHover={false} transition={Slide} />
  </ErrorBoundary>,
);
