import { type FC, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { PageBanners } from "./banners/PageBanners";

import { ErrorScreen } from "components/LoadingScreen";
import { SUBMISSION_ID_QUERY_PARAM, WAITED_TOO_LONG_TIMEOUT_MS } from "components/constants";
import { PageWorksheetHeader } from "components/materials/page/PageWorksheetHeader";
import { PageTabVariant } from "components/materials/page/helpers";
import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { useWakeLock } from "mds/hooks/use-wake-lock";
import { LoadingDotsIcon } from "mds/icons";
import { DocViewContext } from "providers/DocViewProvider";
import { docCache, forceRefreshDoc, useWorksheet } from "providers/ShareDBDoc";
import { useAppSelector } from "store/index";
import { selectIsTimeline, selectSubmissionById } from "store/selectors";
import { usePresentation } from "utils/presentation";
import { ErrorBoundary } from "worksheets/views/ErrorBoundary";
import { TimelineWorksheet } from "worksheets/views/TimelineWorksheet";
import { WorksheetFromSharedbDoc } from "worksheets/views/Worksheet";

type PageWorksheetProps = {
  page: PageType;
  selectedTabVariant?: PageTabVariant;
  selectedAccessId?: string;
};

export const PageWorksheet: FC<PageWorksheetProps> = ({
  page,
  selectedTabVariant,
  selectedAccessId,
}) => {
  const [searchParams] = useSearchParams();
  const { isProjecting, isFeaturing } = useContext(DocViewContext);
  const isTimeline = useAppSelector(selectIsTimeline);
  const submissionId = searchParams.get(SUBMISSION_ID_QUERY_PARAM);
  const submission = useAppSelector((s) => selectSubmissionById(s, submissionId));
  const docId = page.worksheet_id;
  const { doc } = useWorksheet(docId);
  const { lock, release } = useWakeLock();
  const { inPresentationMode, isFollowing, isPresentingUser } = usePresentation();

  const [initError, setInitError] = useState<string>(null);

  const isResponsesTab = selectedTabVariant === "responses";

  if (doc && !isResponsesTab) {
    // This is a hack to make the doc available to the AI sidebar
    docCache.current = doc;
    // This is a hack to make the doc available globally for debugging purposes
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
    (window as any).doc = doc;
  }

  useEffect(() => {
    if (!doc) {
      const timeout = setTimeout(() => setInitError("Timeout"), WAITED_TOO_LONG_TIMEOUT_MS);
      return () => clearTimeout(timeout);
    }
  }, [doc]);

  useEffect(() => {
    if (inPresentationMode && (isPresentingUser || isFollowing)) {
      // Prevent screen from dimming while presenting, or following the presentation.
      lock();
    } else {
      // When presentation ends, or student stops following the presentation,
      // allow the screen to dim again.
      release();
    }
    return release as () => void;
  }, [inPresentationMode, isPresentingUser, isFollowing, lock, release]);

  if (initError) {
    return (
      <div className="worksheet-page h-full">
        <div className="worksheet-page-padding h-full">
          <ErrorScreen
            retryText={t("worksheet.error.try_again")}
            text={<div className="body-m">{t("worksheet.error.loading_page")}</div>}
            onRetry={() => {
              forceRefreshDoc(docId);
              setInitError(null);
            }}
          />
        </div>
      </div>
    );
  }

  // When switching to a new page, the doc might take some time to update, so we need to wait
  // until the loaded doc exists and matches the page's worksheet_id before rendering the page.
  if (!doc || !doc.data || doc?.id !== page.worksheet_id) {
    return (
      <div className="worksheet-page h-full">
        <div className="worksheet-page-padding h-full">
          <div className="worksheet-page-content h-full">
            <div className="worksheet-column-wrapper mt-4">
              <div className="mb-2">{t("worksheet.loading.loading_page")}</div>
              {/* TODO: This should also be re-triable behind a timeout */}
              <LoadingDotsIcon />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isTimeline) {
    return <TimelineWorksheet docId={docId} />;
  }

  return (
    <div className="worksheet-page flex flex-col">
      <div className="worksheet-page-padding flex h-full items-start justify-center">
        <div className="worksheet-page-container flex w-full">
          <div className="worksheet-page-content flex h-full w-full flex-col items-center">
            {!isProjecting && !isFeaturing && (
              <>
                <PageWorksheetHeader
                  page={page}
                  selectedAccessId={selectedAccessId}
                  selectedTabVariant={selectedTabVariant}
                />

                <PageBanners doc={doc} page={page} selectedTabVariant={selectedTabVariant} />
              </>
            )}

            <article className="flex h-full w-full">
              <ErrorBoundary>
                <div className="relative w-full">
                  <WorksheetFromSharedbDoc
                    doc={doc}
                    selectedTabVariant={selectedTabVariant}
                    worksheetVersionNumber={submission?.worksheet_sharedb_version}
                  />
                </div>
              </ErrorBoundary>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};
