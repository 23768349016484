import { cloneDeep } from "lodash";
import { FC, useEffect, useMemo } from "react";

import { NavList } from "../NavList";

import { COURSE_CODE_ID_QUERY_PARAM } from "components/constants";
import { CourseDetailTermView } from "components/materials/org/course-codes/CourseDetailTermView";
import { t } from "i18n/i18n";
import { TextBooksIcon } from "mds/icons";
import { storeApi, useAppSelector } from "store/index";
import { selectCourseCodes } from "store/selectors";
import { useFindItemBySearchParamId } from "utils/hooks/use-find-item-by-search-param-id";

export const CourseCodeView: FC = () => {
  const courseCodes = useAppSelector(selectCourseCodes);
  const orderedCourseCodes = useMemo(
    () => courseCodes.sort((a, b) => a.order - b.order).map(cloneDeep),
    [courseCodes],
  );

  const courseCode = useFindItemBySearchParamId(orderedCourseCodes, COURSE_CODE_ID_QUERY_PARAM);

  useEffect(() => {
    storeApi.course_codes.list();
    storeApi.terms.list();
  }, []);

  return (
    <main className="h-full w-full bg-white">
      <div className="flex rounded-md border border-black-tint-90">
        <NavList
          api={storeApi.course_codes}
          Icon={TextBooksIcon}
          items={orderedCourseCodes}
          model="course_code"
          queryParam={COURSE_CODE_ID_QUERY_PARAM}
          title={t("org.tabs.catalog")}
        />
        <div className="h-full flex-auto overflow-y-auto">
          <CourseDetailTermView courseCode={courseCode} />
        </div>
      </div>
    </main>
  );
};
