import "./Button.scss";

import clsx from "clsx";
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ForwardedRef,
  MouseEventHandler,
  ReactNode,
  forwardRef,
} from "react";
import { Link, type LinkProps } from "react-router-dom";

import { LoadingDotsIcon } from "mds/icons";

export type IconSizeType = "xs" | "s" | "m" | "l";
export type IconColorType = "red" | "green" | "blue" | "orange" | "black";
export type IconKind = "primary" | "secondary" | "tertiary" | "destructive";

// The current code is expecting every kind and prop variant be able to work together.
// If future changes do not follow this constraint (e.g. some `kinds` are only available in some `sizes`),
// we should consider integrating that property (e.g. `size`) into the `kind` property to be explicit
// on what is supported.
// TODO: Add all variations to a storybook page later.
export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  kind?: IconKind;
  size?: IconSizeType;
  type?: "button" | "submit" | "reset";
  // Padding is set differently if we expect an icon only, as we expect icon-only buttons
  // to be square (and not have extra horizontal padding, which would make them rectangular)
  iconOnly?: boolean;
  mobileFullWidth?: boolean;
  fullWidth?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  // color is only implemented for primary buttons currently
  color?: IconColorType;
}

export interface LinkButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children?: ReactNode;
  kind?: IconKind;
  size?: IconSizeType;
  to: LinkProps["to"];
  // Padding might be set differently if we expect an icon only to ensure the buttons are square
  mobileFullWidth?: boolean;
  fullWidth?: boolean;
  iconOnly?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  // color is only implemented for primary buttons currently
  color?: IconColorType;
}

export const Button = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  LinkButtonProps | ButtonProps
>(
  (
    {
      kind = "primary",
      isLoading,
      size = "m",
      type = "button",
      children,
      className,
      mobileFullWidth,
      fullWidth,
      iconOnly,
      readOnly,
      disabled,
      color,
      ...props
    },
    ref,
  ) => {
    // Note that currently the FDS button CSS classes are imported and will cause naming collisions
    // with "button-{kind}-{size}". Though the unused CSS will be removed before release (TODO), so we can change
    // the 'btn' prefix to 'button' later if we prefer matching the same prefix.
    const buttonClass = clsx(
      "btn",
      `btn-${kind}`,
      `btn-${size}`,
      `${color || "blue"}`,
      readOnly && "readonly",
      mobileFullWidth
        ? "grow w-full max-w-full md:grow-0 md:w-auto md:max-w-fit"
        : fullWidth
          ? "w-full"
          : "max-w-fit",
    );

    if (color && kind !== "primary") {
      return <span>Color prop is only implemented for primary buttons</span>;
    }

    if ("to" in props && props.to && !disabled && !readOnly) {
      return (
        <Link
          aria-label={props.title}
          className={clsx(
            buttonClass,
            isLoading && "is-loading",
            (disabled || readOnly) && "disabled",
            (iconOnly || isLoading) && "icon-only",
            className,
          )}
          ref={ref as ForwardedRef<HTMLAnchorElement>}
          to={props.to}
          {...props}
        >
          {isLoading ? <LoadingDotsIcon /> : children}
        </Link>
      );
    }

    return (
      // @ts-expect-error Typescript isn't happy that `props` could be typed for `HTMLAnchorElement` instead of `HTMLButtonElement`
      <button
        // Passing an aria-label will still override this, but for IconButton,
        // the title and label are the same
        aria-label={props.title}
        className={clsx(
          buttonClass,
          isLoading && "is-loading",
          (iconOnly || isLoading) && "icon-only",
          className,
        )}
        disabled={disabled || readOnly}
        ref={ref as ForwardedRef<HTMLButtonElement>}
        // The linter does not like `type` to be dynamically defined, though disabling for this case which seems fine.
        // https://github.com/jsx-eslint/eslint-plugin-react/issues/1555
        // eslint-disable-next-line react/button-has-type
        type={type as "button" | "submit" | "reset"}
        {...props}
        // It makes sense for the loading state to effecticely disable the button, but we don't
        // want to use the disabled prop, as it inferes with the button's styling.
        onClick={isLoading ? () => null : (props.onClick as MouseEventHandler<HTMLButtonElement>)}
      >
        {isLoading ? <LoadingDotsIcon /> : children}
      </button>
    );
  },
);

Button.displayName = "Button";
