import { get } from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { INSTRUCTOR_NOTES_BLOCK_PATH } from "../../../worksheets/shared/constants";

import { PAGE_ID_QUERY_PARAM } from "components/constants";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { Card } from "mds/components/Card";
import { InstructorNotesIcon } from "mds/icons";
import { DocViewContext, DocViewContextType } from "providers/DocViewProvider";
import { useWorksheet } from "providers/ShareDBDoc";
import { useAppSelector } from "store/index";
import { selectPageById } from "store/selectors";
import { rollbarAndLogError } from "utils/logger";
import { RichTextField } from "worksheets/components/Field/RichText/RichTextField";
import { ErrorBoundary } from "worksheets/views/ErrorBoundary";

const NotesToggleButton = ({ toggleNotes }: { toggleNotes: () => void }) => (
  <Button kind="tertiary" size="s" iconOnly onClick={toggleNotes}>
    <InstructorNotesIcon size="s" />
  </Button>
);

/*
 * Component for Instructor notes annotation on a given page.  Page is a worksheet but
 * will have only one defined content block.
 */
export const InstructorNotesSidebarPanel: FC = () => {
  const [showInstructorNotes, setShowInstructorNotes] = useState(true);
  const [searchParams] = useSearchParams();
  const pageId = searchParams.get(PAGE_ID_QUERY_PARAM);
  const page = useAppSelector((s) => selectPageById(s, pageId));

  const docId = page?.instructor_notes_worksheet_id;
  const { doc } = useWorksheet(docId);

  const docViewContext: DocViewContextType = useMemo(
    () => ({
      readOnly: false,
      isGrading: false,
      canAuthorPage: true,
      isAuthoringPage: false,
      canWriteDoc: true,
      canInteractAsStudent: false,
      showResponses: false,
      isProjecting: false,
      isFeaturing: false,
      isReleased: false,
      isStaticContent: false,
      isViewingSeededContentTab: false,
      pageId: "",
      accessId: "",
    }),
    [],
  );

  useEffect(() => {
    if (doc && !get(doc.data, INSTRUCTOR_NOTES_BLOCK_PATH)) {
      rollbarAndLogError(
        "Instructor notes does not have content block",
        doc.id,
        INSTRUCTOR_NOTES_BLOCK_PATH,
      );
    }
  }, [doc]);

  const toggleNotes = () => {
    setShowInstructorNotes(!showInstructorNotes);
  };

  if (!page?.worksheet_id) {
    return <div className="w-full p-4">{t("instructor_notes_box.no_selection")}</div>;
  }

  if (!showInstructorNotes) {
    return (
      <div>
        <NotesToggleButton toggleNotes={toggleNotes} />
      </div>
    );
  }

  if (!doc) {
    return null;
  }

  if (get(doc.data, INSTRUCTOR_NOTES_BLOCK_PATH) === undefined) {
    return (
      <div>
        <h1 className="page-title">{t("error.error")}</h1>
        <p>{t("error.cannot_display_instructor_notes")}</p>
      </div>
    );
  }

  return (
    <>
      <h3 className="content-sidebar-header mb-0">{t("glossary.instructor_notes")}</h3>
      <Card className="body-s w-full grow overflow-y-auto">
        <ErrorBoundary>
          <DocViewContext.Provider value={docViewContext}>
            <div className="relative w-full">
              <RichTextField
                characterCounter="NONE"
                doc={doc}
                path={INSTRUCTOR_NOTES_BLOCK_PATH}
                placeHolderText={t("worksheet.instructor_notes_placeholder")}
                embedded
                showPlaceholder
              />
            </div>
          </DocViewContext.Provider>
        </ErrorBoundary>
      </Card>
    </>
  );
};
