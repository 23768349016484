import { FC } from "react";

import { t } from "i18n/i18n";
import { Button, ButtonProps } from "mds/components/Button";
import { IconText } from "mds/components/IconText";
import { ArrowTopRightIcon } from "mds/icons";

export type ProjectingButtonProps = {
  color?: ButtonProps["color"];
  className: string;
  onProject: () => void;
  size?: ButtonProps["size"];
};

export const ProjectingButton: FC<ProjectingButtonProps> = ({
  color,
  className,
  onProject,
  size = "xs",
}) => (
  <Button
    className={className}
    color={color}
    kind="primary"
    size={size}
    title={t("page_presenting_menu.start_projecting")}
    onClick={onProject}
  >
    <IconText iconStart={<ArrowTopRightIcon />} text={t("page_presenting_menu.start_projecting")} />
  </Button>
);
