import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { serverLogout } from "api/api-server";
import {
  FOLLOWING_QUERY_PARAM,
  PAGE_ID_QUERY_PARAM,
  PREVIEW_QUERY_PARAM,
} from "components/constants";
import { confirmProjectorViewOpen } from "components/materials/presentation/projector/projector-messaging";
import { useOnChanged } from "mds/hooks/use-on-changed";
import { storeApi, useAppDispatch, useAppSelector } from "store/index";
import {
  selectActiveClassSession,
  selectCanAuthorCourse,
  selectCurrentCourse,
  selectCurrentCourseRole,
  selectCurrentUserId,
  selectCurrentUserIsMPAdmin,
  selectIsCourseVersionOutOfSync,
  selectUserById,
} from "store/selectors";
import { authStateActions } from "store/slices/auth";
import { viewStateActions } from "store/slices/view";
import { usePromiseEffect } from "store/store-hooks";
import { toastLocalizedOperationError } from "utils/alerts";
import { maybeInitAmplitude } from "utils/amplitude";
import { getLastCourseIdFromCookie } from "utils/auth";
import { rollbarAndLogError } from "utils/logger";
import { getUrlForPage } from "utils/navigation";
import { usePresentation } from "utils/presentation";
import { PROJECTOR_PATH_REGEX, courseHomeUrl, orgHomeUrl } from "utils/urls";

export const Observers = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const userId = useAppSelector(selectCurrentUserId);
  const user = useAppSelector((s) => selectUserById(s, userId));
  const courseRole = useAppSelector(selectCurrentCourseRole);
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const isMPAdmin = useAppSelector(selectCurrentUserIsMPAdmin);
  const course = useAppSelector(selectCurrentCourse);
  const classSession = useAppSelector(selectActiveClassSession);
  const preVersionPlaybackPageId = useRef<string | null>(null);
  const isCourseVersionOutOfSync = useAppSelector(selectIsCourseVersionOutOfSync);

  const selectedPageId = searchParams.get(PAGE_ID_QUERY_PARAM);
  const isPreviewing = searchParams.get(PREVIEW_QUERY_PARAM);

  const { isFollowing, isPresenting, isPresentingUser, hasProjectorView } = usePresentation();

  const { isLoading: isLoadingCourses, data: courseList } = usePromiseEffect(
    () => storeApi.courses.list(),
    [],
  );

  const isProjectorPath = PROJECTOR_PATH_REGEX.test(location.pathname);
  const showingProjectorView = isPresenting && hasProjectorView;

  const email = user?.email;

  useEffect(() => {
    storeApi.users.list();
  }, []);

  useEffect(() => {
    // We want to make sure our Amplitude analytics are initialized with the user's email. We also
    // wait to get the user's MP admin state and course authoring status for session replay purposes.
    // We want to fully capture instructor's session replays, but potentially only
    // sample a small portion of student's session replays (and avoid capturing MP Admins)
    // given our limited session replay quota.
    // Note that for the Spring 2025 pilots we are recording 100% of the student sessions because we have the quota,
    // but we will reduce this later once usage exceeds our monthly quota.
    // There are edge cases where this may not capture an instructor correctly (e.g. if they are
    // a student in the course they start in, then switch to another course as an instructor), but
    // this is a rare enough case that we don't need to worry about it.
    // Note this will stop analytics tracking if the email is set to null or undefined.
    if (isMPAdmin !== undefined && canAuthorCourse !== undefined) {
      if (isMPAdmin) {
        // For MP Admins, we intentionally do not initialize session replay to avoid
        // using up our session replay quota.
        maybeInitAmplitude(email, 0.0);
      } else if (canAuthorCourse) {
        maybeInitAmplitude(email, 1.0);
      } else {
        maybeInitAmplitude(email, 1.0);
      }
    }
  }, [isMPAdmin, canAuthorCourse, email]);

  // Redirect to first available course if user is on root path
  useEffect(() => {
    const isRootPath = location.pathname === "/";
    if (isLoadingCourses || !isRootPath) {
      return;
    }

    const lastAccessedCourseId = getLastCourseIdFromCookie();
    const firstCourse = courseList[0];
    const targetCourseId =
      lastAccessedCourseId && courseList.find((c) => c.id === lastAccessedCourseId)?.id
        ? lastAccessedCourseId
        : firstCourse?.id;
    if (targetCourseId) {
      navigate(courseHomeUrl(targetCourseId));
    } else {
      navigate(orgHomeUrl);
    }
  }, [location, courseList, navigate, courseRole, isLoadingCourses]);

  // Refresh class session data when window regains focus
  // TODO: Store: If we have versioning in websocket messages and a replay feature,
  // we should replay missed messages instead.
  useEffect(() => {
    if (!classSession?.id) {
      return;
    }
    const fetchClassSessionData = () => {
      storeApi.class_sessions
        .retrieve(classSession?.id, { skipToast: true, skipCache: true })
        .catch((err) => {
          // We are piggybacking on this class session update to check for 401 errors
          // If we have 401 errors, we should log out the user.
          // Not all exceptions will have a response but this code should work fine if not
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (err.response?.status === 401) {
            // TODO: We don't clear out all the user data here like we do in the other serverLogout call
            //  and probably should
            serverLogout()
              .catch((e) => {
                rollbarAndLogError("logout after 401 failed", e);
              })
              .finally(() => {
                // Always clear user context
                dispatch(authStateActions.setCurrentUser(null));
              });
          } else {
            toastLocalizedOperationError("fetch_class_session_failure");
            rollbarAndLogError("Class Session response status ", err);
          }
        });
    };

    window.addEventListener("focus", fetchClassSessionData);
    return () => {
      window.removeEventListener("focus", fetchClassSessionData);
    };
  }, [classSession, dispatch]);

  useEffect(() => {
    // Make sure that we always have the presented page loaded if a presentation is ongoing
    if (classSession?.presented_page_id) {
      storeApi.pages.retrieve(classSession.presented_page_id, { skipToast: true });
    }
  }, [classSession?.presented_page_id]);

  useEffect(() => {
    // This is only for the instructorView that is projecting not the projector view.
    if (isProjectorPath) {
      return;
    }

    // Instructor could have 2 tabs open for the same course aside from the projector view.  We only
    // want to listen for this event if we are the tab with focus.
    const confirmProjectorIfProjecting = () => {
      if (showingProjectorView) {
        confirmProjectorViewOpen(course?.id);
      }
    };

    // If this page gets refreshed while presenting we will lose the connection
    // to the projector view.  Here we recreate the connection if that happens.
    if (showingProjectorView) {
      confirmProjectorViewOpen(course?.id);

      window.addEventListener("focus", confirmProjectorIfProjecting);
    }

    return () => {
      window.removeEventListener("focus", confirmProjectorIfProjecting);
    };
  }, [course, showingProjectorView, isProjectorPath]);

  // When a classSession changes from the backend
  useOnChanged(classSession, async (newSession, oldSession) => {
    const shouldBeFollowing =
      !isPresentingUser &&
      selectedPageId &&
      selectedPageId === newSession?.presented_page_id &&
      !newSession.is_practice;
    const followingOrShouldBe = isFollowing || shouldBeFollowing;
    const selectedPageChanged =
      newSession?.presented_page_id !== oldSession?.presented_page_id || newSession.is_practice;
    const userNoLongerPresenting =
      newSession?.presenting_user_id !== oldSession?.presenting_user_id &&
      oldSession?.presenting_user_id === userId;

    if ((followingOrShouldBe && selectedPageChanged) || userNoLongerPresenting) {
      if (isPreviewing) {
        searchParams.delete(PREVIEW_QUERY_PARAM);
      }

      if (newSession?.presented_page_id && !newSession.is_practice) {
        searchParams.set(FOLLOWING_QUERY_PARAM, "1");

        // TODO: Check if using navigate vs just setting searchparams has a performance impact
        // If the topic isn't changing we really only need to change the search params
        const pageUrl = await getUrlForPage(newSession.presented_page_id, true);
        if (pageUrl) {
          navigate(pageUrl);
        }
      } else {
        searchParams.delete(FOLLOWING_QUERY_PARAM);

        // We don't need to navigate to the first item just because we stopped presenting
        setSearchParams(searchParams);
      }
    } else if (!isFollowing && shouldBeFollowing) {
      searchParams.set(FOLLOWING_QUERY_PARAM, "1");
      setSearchParams(searchParams);
    }
  });

  // If the selected page changes, set isAssessing back to false.
  // TODO: Warn users that they will lose their progress if they switch pages during assessment mode.
  // TODO: Or do we want to permanently disallow opening the sidebar during assessment mode?
  useOnChanged(selectedPageId, (prev, newPage) => {
    if (prev && newPage && prev !== newPage && !isCourseVersionOutOfSync) {
      dispatch(viewStateActions.stopAssessing());
    }
  });

  // We don't want to set isAssessing to false if we are playing back previous versions of the course
  // until the playback completes and we're sure the page has changed.
  useEffect(() => {
    if (isCourseVersionOutOfSync && !preVersionPlaybackPageId.current) {
      preVersionPlaybackPageId.current = selectedPageId;
    } else if (!isCourseVersionOutOfSync && preVersionPlaybackPageId.current) {
      if (preVersionPlaybackPageId.current !== selectedPageId) {
        dispatch(viewStateActions.stopAssessing());
      }

      preVersionPlaybackPageId.current = null;
    }
  }, [isCourseVersionOutOfSync, selectedPageId, dispatch]);

  return null;
};
