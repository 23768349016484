import { AxiosError, isAxiosError } from "axios";
import { isObject } from "lodash";
// eslint-disable-next-line no-restricted-imports
import { ToastOptions, toast } from "react-toastify";

import { t } from "i18n/i18n";
import { BackendErrorCodeKeys, GlossaryKey, TOptionsType, ToastKeys } from "i18n/i18next";
import { rollbarAndLogError } from "utils/logger";

/**
 * Turns a network error into a human-readable string that can
 * be appended to sentences like "Failed to load data with ..."
 */
export const getReadableAxiosError = (error: AxiosError, verbose = false) => {
  if (!(error instanceof AxiosError)) {
    return "unknown local error";
  }

  const data = error.response?.data as Record<string, string>;
  // TODO: We don't set "error_description" consistently on the backend. Currently this is just
  // done by the OAuth2 provider and org middleware. We should make a convention for all
  // errors, so that the backend can easily decide which error should be user-facing and which shouldn't.
  if (data && isObject(data) && "error_description" in data) {
    if ("code" in data) {
      const key = `backend_error_codes.${data.code}` as BackendErrorCodeKeys;
      const translated = t(key);

      if (translated !== key) {
        return translated;
      }
    }

    return data.error_description;
  }

  if (data && isObject(data) && verbose) {
    return JSON.stringify(data);
  }

  if (error.response?.status) {
    const key = `backend_error_codes.${error.response.status}` as BackendErrorCodeKeys;
    const translated = t(key);

    // If react-i18next doesn't have a translation, it returns the original key
    if (translated !== key) {
      return `${translated}`;
    }

    return `status ${error.response.status}`;
  }

  if (error.message) {
    return `${error.message}`;
  }

  if (error.code) {
    return `code ${error.code}`;
  }

  return "unknown network error";
};

// TODO: We should create helper to configure ToastSuccessKeys for toastSuccessMessage similar
//  to toastErrorMessage and use that to ensure that the toast messages are localized.
export const toastSuccessMessage = (message: string, options: ToastOptions = {}) => {
  return toast.success(message, {
    // Ensures you can only have one toast per unique message string
    toastId: message,
    ...options,
  });
};

// TODO: There's only one more place that uses this in PasswordResetView. We should
//  localize these strings instead of displaying them directly to the users, and then
//  make sure this function does not get exported anymore.
export const toastErrorMessage = (message: string) => {
  return toast.error(message, {
    // Ensures you can only have one toast per unique message string
    toastId: message,
  });
};

// Do not export this (use toastLocalizedOperationCatcher).
const showToastError = (error: unknown, messagePrefix: string = "") => {
  if (document && !document.querySelector(".Toastify")) {
    const errorMsg = "Trying to show toast, but no toast container configured.";
    rollbarAndLogError(`${errorMsg} ${messagePrefix}`, error as Error);
    return;
  }

  let message = "";
  if (isAxiosError(error)) {
    const axiosError = error as AxiosError;
    message = getReadableAxiosError(axiosError);
  } else if (error instanceof Error) {
    message = error.message;
  } else {
    message = t("error.toasts.unknown");
  }

  if (messagePrefix) {
    toastErrorMessage(`${messagePrefix}: ${message}`);
  } else {
    toastErrorMessage(message);
  }
};

export const toastLocalizedOperationError = (operation: ToastKeys, options: TOptionsType = {}) => {
  toastErrorMessage(t(`error.toasts.${operation}`, options));
};

// Catch method to toast error messages on failed operations.
// Do not export this (use toastLocalizedOperationCatcher).
const toastOperationCatcher = (operation: string) => {
  return (error: unknown) => showToastError(error, operation);
};

export const toastErrorCatcher = (error: unknown) => showToastError(error);

export const toastLocalizedOperationCatcher = (
  operation: ToastKeys,
  options: TOptionsType = {},
) => {
  return (error: unknown) => showToastError(error, t(`error.toasts.${operation}`, options));
};

export const toastMoveOperationCatcher = (glossaryKey: GlossaryKey) => {
  return toastOperationCatcher(
    t("error.toasts.move_failure", { item: t(`glossary.${glossaryKey}`) }),
  );
};

export const toastCopyOperationCatcher = (glossaryKey: GlossaryKey) => {
  return toastOperationCatcher(
    t("error.toasts.copy_failure", { item: t(`glossary.${glossaryKey}`) }),
  );
};

export const toastCreateOperationCatcher = (glossaryKey: GlossaryKey) => {
  return toastOperationCatcher(
    t("error.toasts.create_failure", { item: t(`glossary.${glossaryKey}`) }),
  );
};

/**
 * A standard (and temporary) alert for features that are not yet implemented.
 * Intentionally not translating this message, as it's a temporary placeholder.
 */
export const showComingSoonAlert = () => {
  alert("Coming soon");
};
