import clsx from "clsx";
import prettyBytes from "pretty-bytes";
import { FC, useRef, useState } from "react";
import { Doc } from "sharedb/lib/client";

import { BLOCKS_PATH } from "../shared/constants";
import { addAssetToDoc, createBlockOfType } from "../shared/doc-helpers";
import { BlockType, OEmbedDataType, WorksheetType } from "../shared/types/worksheet";

import { showOEmbedModal } from "./Field/EmbedModal";

import { collaborationUploadFile } from "api/api-worksheets";
import { t } from "i18n/i18n";
import { FileUploadField } from "mds/components/FileUploadField";
import { ControlledMenu, MenuDivider, MenuItem } from "mds/components/Menu";
import { useHoverable } from "mds/hooks/use-hoverable";
import {
  ArrowUpIcon,
  CodeBlockChevronIcon,
  ContentBlockIcon,
  LongFormPencilIcon,
  MultipleChoiceSingleIcon,
  PresentPlayIcon,
  TableLayoutIcon,
} from "mds/icons";
import { useAppSelector } from "store/index";
import { selectCurrentCourse, selectIsEditModeEnabled } from "store/selectors";
import { toastLocalizedOperationCatcher } from "utils/alerts";

const showEmbedModal = async () => {
  let data: OEmbedDataType | null = null;

  const setData = (newData: OEmbedDataType) => {
    data = newData;
  };

  await showOEmbedModal("", setData);

  if (data) {
    const block = createBlockOfType("Embed");
    block.f[0] = { ...block.f[0], ...data };

    return block;
  }
};

// Note if you modify this list, make sure the menu renders as desired given its formatting depends on the order.
const BLOCK_LIST: {
  type: BlockType["t"];
  labelKey:
    | "content"
    | "free_response"
    | "multiple_choice"
    | "table"
    | "embed"
    | "file_submission"
    | "code_block";
  Icon: FC;
  customCreationFn?: () => Promise<BlockType | null>;
}[] = [
  { type: "Instructions", labelKey: "content", Icon: ContentBlockIcon },
  { type: "FreeResponse", labelKey: "free_response", Icon: LongFormPencilIcon },
  { type: "MultipleChoice", labelKey: "multiple_choice", Icon: MultipleChoiceSingleIcon },
  { type: "Table", labelKey: "table", Icon: TableLayoutIcon },
  { type: "Embed", labelKey: "embed", Icon: PresentPlayIcon, customCreationFn: showEmbedModal },
  {
    type: "File",
    labelKey: "file_submission",
    Icon: ArrowUpIcon,
  },
  { type: "Code", labelKey: "code_block", Icon: CodeBlockChevronIcon },
];

interface AddElementProps {
  doc: Doc<WorksheetType>;
  insertAt: number;
  className?: string;
  isLarge?: boolean;
}

export const AddElement: FC<AddElementProps> = ({ doc, insertAt, className, isLarge }) => {
  const addButtonHover = useHoverable();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const currentCourse = useAppSelector(selectCurrentCourse);
  const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);
  const isVisible = addButtonHover.isHovering || isOpen || isUploading || doc.data.b.length === 0;

  const blockListForCourse = currentCourse.code_blocks_enabled
    ? BLOCK_LIST
    : BLOCK_LIST.filter((block) => block.type !== "Code");

  const onAddBlock = async (type: BlockType["t"]) => {
    const blockTemplate = BLOCK_LIST.find((block) => block.type === type);
    const customCreationFn = blockTemplate?.customCreationFn;

    const block = customCreationFn ? await customCreationFn() : createBlockOfType(type);

    if (block) {
      doc.submitOp([{ p: [BLOCKS_PATH, insertAt], li: block }]);
    }
  };

  const onFileDrop = (files: File[]) => {
    setIsUploading(true);
    const uploadPromises = files.map(collaborationUploadFile);
    uploadPromises.forEach((uploadPromise, i) => {
      // Catch is handled in the Promise.all below

      uploadPromise.then((response) =>
        addAssetToDoc(
          doc,
          {
            url: response.url,
            name: files[i].name,
            size: prettyBytes(files[i].size),
            fileType: files[i].type,
          },
          insertAt,
        ),
      );
    });
    Promise.all(uploadPromises)
      .catch(toastLocalizedOperationCatcher("upload_failed"))
      .finally(() => setIsUploading(false));
  };

  return (
    <div className={className} {...addButtonHover.hoverParentProps}>
      <FileUploadField
        addButtonProps={{
          type: "block",
          ref,
          disabled: !isEditModeEnabled,
          onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation();
            setIsOpen(true);
          },
        }}
        className={clsx("whitespace-nowrap", !isVisible && "invisible")}
        disabled={!isEditModeEnabled}
        isLoading={isUploading}
        size={isLarge ? "l" : "s"}
        multiple
        onDrop={onFileDrop}
      />
      {/* The reason we use a controlled menu is so that we can keep the button visible
      while the menu is open */}
      <ControlledMenu
        anchorRef={ref}
        state={isOpen ? "open" : "closed"}
        onClose={() => setIsOpen(false)}
      >
        {blockListForCourse.map(({ type, labelKey, Icon }, index) => (
          <div key={type}>
            {index === 1 && <MenuDivider />}
            <MenuItem
              onClick={(e) => {
                e.syntheticEvent.stopPropagation();
                onAddBlock(type);
              }}
            >
              <Icon />
              {t(`add_element.${labelKey}`)}
            </MenuItem>
          </div>
        ))}
      </ControlledMenu>
    </div>
  );
};
