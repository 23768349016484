import "./AuthoringFields.scss";

import clsx from "clsx";
import type { FC, ReactNode } from "react";
import { Doc } from "sharedb/lib/client";

import { CODE_CELLTYPE_OPTIONS, getFieldShortId } from "../../shared/constants";
import {
  ChoiceFieldChoiceType,
  CodeCellTypeOption,
  CodeFieldType,
  PathType,
  WorksheetType,
} from "../../shared/types/worksheet";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { IconText } from "mds/components/IconText";
import { Menu, MenuItem } from "mds/components/Menu";
import {
  AddPlusIcon,
  ArrowUpIcon,
  EyeNotVisibleIcon,
  EyeVisibleIcon,
  LockUnlockedIcon,
  MultipleChoiceMultipleIcon,
  MultipleChoiceSingleIcon,
  TrashDeleteIcon,
} from "mds/icons";

interface MultipleSwitchDropdownProps {
  doc: Doc<WorksheetType>;
  path: PathType;
  t: "ChoiceField" | "FileField";
  m: boolean;
  id: string;
}

const CHOICE_FIELD_MULTIPLE_OPTIONS = [
  { value: true, labelKey: "multiple_answers", icon: MultipleChoiceMultipleIcon },
  { value: false, labelKey: "single_answer", icon: MultipleChoiceSingleIcon },
] as const;

const FILE_FIELD_MULTIPLE_OPTIONS = [
  { value: true, labelKey: "multiple_files", icon: ArrowUpIcon },
  { value: false, labelKey: "single_file", icon: ArrowUpIcon },
] as const;

const CODE_FIELD_ICONS: Record<CodeCellTypeOption, ReactNode> = {
  editable: <LockUnlockedIcon />,
  readonly: <EyeVisibleIcon />,
  hidden: <EyeNotVisibleIcon />,
};

// TODO: This component is only used in the block authoring toolbar now, and should maybe
// be moved to a common location.
export const MultipleSwitchDropdown: FC<MultipleSwitchDropdownProps> = ({
  doc,
  path,
  t: type,
  m: multiple,
  id,
}) => {
  const options =
    type === "ChoiceField" ? CHOICE_FIELD_MULTIPLE_OPTIONS : FILE_FIELD_MULTIPLE_OPTIONS;
  const titleKey = type === "ChoiceField" ? "mc_title" : "file_title";

  const onChange = (newValue: boolean) => {
    if (newValue !== multiple) {
      doc.submitOp([
        {
          p: [...path, "m"],
          oi: newValue,
          od: multiple,
        },
      ]);
    }
  };

  const currentOption = options.find((o) => o.value === multiple);
  const Icon = currentOption?.icon;

  return (
    <Menu
      direction="right"
      menuButton={
        <Button
          className="gap-2"
          kind="secondary"
          size="s"
          title={t("tooltip.answer_type")}
          iconOnly
        >
          <Icon />
        </Button>
      }
    >
      <MenuItem key="title" kind="instructional">
        {t(`authoring_fields.${titleKey}`)}
      </MenuItem>

      {options.map(({ labelKey, value, icon: IconComponent }) => {
        const key = `${id}-multiple-option-${value}`;
        return (
          <MenuItem
            className={clsx(value === multiple && "selected")}
            key={key}
            onClick={() => onChange(value as boolean)}
          >
            <IconComponent className="mx-2" />{" "}
            {t(
              `authoring_fields.${
                labelKey as "multiple_answers" | "single_answer" | "multiple_files" | "single_file"
              }`,
            )}
          </MenuItem>
        );
      })}
    </Menu>
  );
};

interface ChoicesAuthoringFieldProps {
  doc: Doc<WorksheetType>;
  path: PathType;
  id: string;
  c: ChoiceFieldChoiceType[];
  m: boolean;
}

export const ChoicesAuthoringField: FC<ChoicesAuthoringFieldProps> = ({
  doc,
  path,
  c: choices,
  id,
  m: multiple,
}) => {
  // Note that currently, the "value" of a choice is just the index of the choice as string
  // TODO: We should change this component directly act on the index, not the "value" and other
  // code to not use the value.

  // TODO: Editing of these text fields is very inefficient, because we're replacing the content every time
  // We should switch to using RichTextField or similar for text operations.

  const onChange = (targetChoice: ChoiceFieldChoiceType, newLabel: string) => {
    doc.submitOp([
      {
        p: [...path, "c", choices.indexOf(targetChoice), "l"],
        oi: newLabel,
        od: targetChoice.l,
      },
    ]);
  };

  const onRemove = (targetAnswer: string) => {
    doc.submitOp([
      {
        p: [...path, "c", choices.findIndex((c) => c.v === targetAnswer)],
        ld: choices.find((c) => c.v === targetAnswer),
      },
    ]);
  };

  const onAdd = () => {
    // In ShareDB, if we specify a position past any existing positions,
    // it just appends to the end of the list
    const INSERT_BEFORE_LARGE_INDEX = 999;
    doc.submitOp([
      {
        p: [...path, "c", INSERT_BEFORE_LARGE_INDEX],
        li: {
          v: String(choices.length),
          l: "",
          id: getFieldShortId(),
        },
      },
    ]);
  };

  return (
    <div className="mt-4">
      <div>
        {choices.map((choice) => {
          const key = `${id}-answers-${choice.id}`;
          const type = multiple ? "checkbox" : "radio";

          return (
            <div className="mc-authoring flex items-center" key={key}>
              <input className="mc-authoring-radio mr-2" type={type} disabled />

              <input
                className="mc-authoring-input w-full"
                id={key}
                name={key}
                type="text"
                value={choice.l}
                onChange={(e) => onChange(choice, e.target.value)}
              />

              {/* <RichTextField
                className="flex-auto"
                embedded
                doc={doc}
                path={[...path, "c", index, "l"]}
              /> */}

              <Button
                className="ml-2"
                kind="destructive"
                size="s"
                title={t("tooltip.delete_answer")}
                iconOnly
                onClick={() => onRemove(choice.v)}
              >
                <TrashDeleteIcon />
              </Button>
            </div>
          );
        })}

        <button className="link text-blue-shade-20" type="button" onClick={() => onAdd()}>
          <IconText iconStart={<AddPlusIcon />} text={t("authoring_fields.add_answer")} />
        </button>
      </div>
    </div>
  );
};

interface CodeCellTypeSwitchDropdownProps {
  doc: Doc<WorksheetType>;
  path: PathType;
  cellType: CodeFieldType["cellType"];
  id: string;
}

export const CodeCellTypeSwitchDropdown: FC<CodeCellTypeSwitchDropdownProps> = ({
  doc,
  path,
  cellType,
  id,
}) => {
  const onChange = (newCellType: string) => {
    if (newCellType !== cellType) {
      doc.submitOp([
        {
          p: [...path, "cellType"],
          oi: newCellType,
          od: cellType,
        },
      ]);
    }
  };

  return (
    <Menu
      direction="right"
      menuButton={
        <Button
          className="gap-2"
          kind="secondary"
          size="s"
          title={t("tooltip.code_block_settings")}
        >
          <LockUnlockedIcon />
        </Button>
      }
    >
      <MenuItem key="title" kind="instructional">
        {t("authoring_fields.code_title")}
      </MenuItem>

      {CODE_CELLTYPE_OPTIONS.map(({ value }) => {
        const key = `${id}-code-option-${value}`;
        return (
          <MenuItem
            className={clsx(value === cellType && "selected")}
            key={key}
            onClick={() => onChange(value)}
          >
            {CODE_FIELD_ICONS[value]}
            {t(`authoring_fields.${value}`)}
          </MenuItem>
        );
      })}
    </Menu>
  );
};
