import "./OrgHeader.scss";

import { FC } from "react";
import { Link } from "react-router-dom";

import { BackToCourseButton } from "./BackToCourseButton";
import { OrgSelect } from "./OrgSelect";

import { MinervaLogoIcon } from "mds/icons";
import { orgHomeUrl } from "utils/urls";

export const OrgHeader: FC = () => {
  return (
    <div className="org-header flex items-center justify-between">
      <span className="flex items-center gap-2">
        <Link className="logo flex cursor-pointer items-center border text-black" to={orgHomeUrl}>
          <MinervaLogoIcon height="16" width="16" />
        </Link>
        <OrgSelect />
      </span>

      <BackToCourseButton />
    </div>
  );
};
