import "./Popover.scss";

import { PopoverPanel as HeadlessPopoverPanel, PopoverPanelProps } from "@headlessui/react";
import clsx from "clsx";
import type { FC } from "react";

export const PopoverPanel: FC<PopoverPanelProps> = ({ className, ...props }) => (
  <HeadlessPopoverPanel className={clsx("popover-panel", className)} {...props} />
);
PopoverPanel.displayName = "PopoverPanel";

export { Popover, PopoverButton } from "@headlessui/react";
export type { PopoverProps, PopoverButtonProps, PopoverPanelProps } from "@headlessui/react";
