import type { FC, MouseEvent } from "react";

import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { ChevronRightIcon, PresentPlayOutlineIcon } from "mds/icons";
import { featurePage } from "utils/presentation";

export type FeatureUserBreadcrumbsProps = {
  classSessionId: string;
  accessId?: string;
  responseName?: string;
  page: PageType;
};

export const FeatureUserBreadcrumbs: FC<FeatureUserBreadcrumbsProps> = ({
  classSessionId,
  accessId,
  responseName,
  page,
}) => {
  const onShowAllStudentQuestions = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    featurePage(classSessionId, page, {
      blockId: null,
      accessId,
    });
  };

  return (
    <div className="g1 flex items-center text-black-tint-20">
      <PresentPlayOutlineIcon className="icon-green mr-1" />

      <Button className="text-green-shade-20" kind="tertiary" onClick={onShowAllStudentQuestions}>
        {t("presentation.featuring.student_responses", { name: responseName })}
      </Button>

      <ChevronRightIcon />

      {t("presentation.featuring.status.participant_response_with_block")}
    </div>
  );
};
