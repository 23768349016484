import "./Score.scss";
import clsx from "clsx";
import { isNil } from "lodash";
import type { CSSProperties, FC } from "react";

import { rollbarAndLogError } from "../../utils/logger";

interface ScoreIconProps {
  className?: string;
  style?: CSSProperties;
  value?: number;
  size?: "xs" | "s" | "m";
  unselected?: boolean;
  disabled?: boolean;
}

export const ScoreIcon: FC<ScoreIconProps> = ({
  className = "",
  style,
  value,
  size = "s",
  unselected,
  disabled,
}) => {
  // If value is a float, the coloring will be done based on the closest value
  const roundedValue = Math.round(value);
  const isInt = roundedValue === value;

  const isValid = !isNil(value) && !isNaN(value);
  const display = isValid ? (isInt ? value : value.toFixed(1)) : "-";

  if (value && !(roundedValue >= 0 && roundedValue <= 5)) {
    rollbarAndLogError(`[ScoreIcon] Unrecognized score value ${value}`);
  }

  const scoreBgClass = clsx(
    `score score-${size}`,
    !isNil(value) && `value-${roundedValue}`,
    unselected && "unselected",
    !unselected && disabled && "disabled",
    // Floats don't fit into the small size, so we make them a bit wider
    !isInt && isValid && "score-wide",
    className,
  );

  return (
    <div className={scoreBgClass} style={style}>
      <div aria-label={`score ${!isNil(value) ? value : "blank"}`} className="score-text">
        {display}
      </div>
    </div>
  );
};
