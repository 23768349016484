import { clone } from "lodash";
import { useMemo } from "react";

export const DEFAULT_ORDER_FN = <T extends { order: number }>(a: T, b: T) => a.order - b.order;
export const ORDER_BY_CREATED = <T extends { created_at_ms: number; id: string }>(a: T, b: T) =>
  a.created_at_ms === b.created_at_ms
    ? a.id.localeCompare(b.id)
    : a.created_at_ms - b.created_at_ms;

export const useOrdered = <T extends { order: number }>(
  items: T[],
  orderFn?: (a: T, b: T) => number,
) => {
  return useCustomOrdered(items, orderFn || DEFAULT_ORDER_FN);
};

export const useCustomOrdered = <T>(items: T[], orderFn: (a: T, b: T) => number) => {
  return useMemo(() => {
    const cloned = items.map(clone);
    cloned.sort(orderFn);
    return cloned;
  }, [items, orderFn]);
};
