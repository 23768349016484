import "./ImportCourseModal.scss";

import clsx from "clsx";
import { FC, useState } from "react";

import { CourseCodeMenu } from "components/menus/CourseCodeMenu";
import { TermMenu } from "components/menus/TermMenu";
import { ImportCourseRequestType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { CancelSaveWidget } from "mds/components/CancelSaveWidget";
import { Checkbox } from "mds/components/Checkbox";
import { IconText } from "mds/components/IconText";
import { ListItem } from "mds/components/ListItem";
import { BookCourseIcon, ChevronDownIcon, ChevronUpIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import { selectCoursesByTermAndCourseCode, selectCurrentCourse } from "store/selectors";
import { useListSelector } from "store/store-hooks";

export interface ImportCourseModalProps {
  onCancel: () => void;
  onConfirm: (data: ImportCourseRequestType) => void;
}

export const ImportCourseModal: FC<ImportCourseModalProps> = ({
  onCancel = null,
  onConfirm = null,
}) => {
  const currentCourse = useAppSelector(selectCurrentCourse);
  const [selectedCourseCodeId, setSelectedCourseCodeId] = useState<string>("");
  const [selectedTermId, setSelectedTermId] = useState<string>("");
  const [selectedCourseId, setSelectedCourseId] = useState<string>(null);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const showCourses = selectedTermId !== "" && selectedCourseCodeId !== "";

  const courses = useListSelector((s) =>
    selectCoursesByTermAndCourseCode(s, selectedTermId, selectedCourseCodeId),
  );

  interface ContentOptionProps {
    label: "topics" | "assignments" | "course_resources" | "instructor_workspace";
    selected: boolean;
  }

  enum ContentOption {
    TOPICS,
    ASSIGNMENTS,
    COURSE_RESOURCES,
    INSTRUCTOR_WORKSPACE,
  }

  const [contentOptions, setContentOptions] = useState<ContentOptionProps[]>([
    { label: "topics", selected: true },
    { label: "assignments", selected: true },
    { label: "course_resources", selected: true },
    { label: "instructor_workspace", selected: true },
  ]);
  const allOptionsSelected = contentOptions.every((option) => option.selected);
  const noOptionsSelected = contentOptions.every((option) => !option.selected);

  const handleCourseCodeChange = (newCourseCodeId: string) => {
    setSelectedCourseCodeId(newCourseCodeId);
  };

  const handleTermChange = (newTermId: string) => {
    setSelectedTermId(newTermId);
  };

  const handleCourseChange = (newCourseId: string) => {
    setSelectedCourseId(newCourseId);
  };

  const handleContentOptionsChange = (index) => {
    //   set the selected option to the opposite of what it currently is
    const newOptions = contentOptions.map((option, i) => {
      if (i === index) {
        return { ...option, selected: !option.selected };
      }

      return option;
    });

    // Update the state with the new options array
    setContentOptions(newOptions);
  };

  const handleAllContentOptionsChange = () => {
    setContentOptions(
      contentOptions.map((option) => ({ ...option, selected: !allOptionsSelected })),
    );
  };

  const toggleShowOptions = () => {
    setShowOptions(!showOptions);
  };

  // TODO: when the template nav is clicked update the list of courses and set the tab to active
  return (
    <div className="import-modal w-full">
      <div className="course-options mt-4 flex items-center gap-2 py-4">
        <TermMenu menuType="menu" selectedTermId={selectedTermId} onTermChange={handleTermChange} />

        <CourseCodeMenu
          menuType="menu"
          selectedCourseCodeId={selectedCourseCodeId}
          onCourseCodeChange={handleCourseCodeChange}
        />
      </div>

      <div className={clsx("course-list mb-2", { hidden: !showCourses })}>
        {/* TODO: add empty state if the selected and term and course code has no courses */}
        {courses
          .filter((course) => course.id !== currentCourse.id)
          .map((course) => (
            <div key={course.id}>
              <ListItem
                className="min-w-0"
                isSelected={selectedCourseId === course.id}
                onClick={() => handleCourseChange(course.id)}
              >
                <IconText
                  className="w-full"
                  iconStart={<BookCourseIcon />}
                  text={<div className="min-w-0 truncate">{course.title}</div>}
                />
              </ListItem>
            </div>
          ))}
      </div>

      <div className={clsx("p-2", { hidden: !showCourses })}>
        <div className="flex items-center gap-2 pb-1">
          <Checkbox checked={allOptionsSelected} onChange={handleAllContentOptionsChange} />

          {t("import_modal.include")}

          <Button
            kind="tertiary"
            size="xs"
            title={showOptions ? t("import_modal.hide_options") : t("import_modal.show_options")}
            iconOnly
            onClick={toggleShowOptions}
          >
            {showOptions ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </Button>
        </div>

        {showOptions &&
          contentOptions.map((option: ContentOptionProps, index) => (
            <div className="ml-4 flex gap-2 pb-1" key={option.label}>
              <Checkbox
                checked={option.selected}
                onChange={() => {
                  handleContentOptionsChange(index);
                }}
              />
              {t(`glossary.${option.label}`)}
            </div>
          ))}
      </div>

      <CancelSaveWidget
        className="right mt-4"
        isSaveDisabled={selectedCourseId === null || noOptionsSelected}
        saveTextKey="import_course"
        onClickCancel={onCancel}
        onClickSave={() => {
          onConfirm({
            source_course_id: selectedCourseId,
            target_course_id: currentCourse.id,
            include_topics: contentOptions[ContentOption.TOPICS].selected,
            include_assignments: contentOptions[ContentOption.ASSIGNMENTS].selected,
            include_course_resources: contentOptions[ContentOption.COURSE_RESOURCES].selected,
            include_workspace: contentOptions[ContentOption.INSTRUCTOR_WORKSPACE].selected,
          });
        }}
      />
    </div>
  );
};
