import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const ArrowDownIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M7.33203 1V11.6667"
      id="Vector"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M11.332 7.66602L7.33203 11.666L3.33203 7.66602"
      id="Vector_2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M13.9993 15H0.666016"
      id="Vector_3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
