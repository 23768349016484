import { useMediaQuery } from "usehooks-ts";

import { theme } from "virtual:tailwind-config";

export const useIsBreakpointOrLarger = (breakpoint: keyof typeof theme.screens) => {
  const minWidth = theme.screens[breakpoint];
  return useMediaQuery(`(min-width: ${minWidth})`);
};

export const useIsMdOrLarger = () => useIsBreakpointOrLarger("md");
export const useIsLgOrLarger = () => useIsBreakpointOrLarger("lg");
