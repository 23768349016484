import { FC, Ref, useRef } from "react";

import { SidebarOptionsMenu, SidebarOptionsMenuProps } from "./SidebarOptionsMenu";

import { CopyToCourseSubMenu } from "components/menus/CopyToCourseSubMenu";
import { t } from "i18n/i18n";
import { IconText } from "mds/components/IconText";
import { type MenuInstance, SubMenu } from "mds/components/Menu";
import { CopySendArrowIcon } from "mds/icons";

interface TopicSidebarOptionsMenuProps extends SidebarOptionsMenuProps {
  optionsMenuActions: SidebarOptionsMenuProps["optionsMenuActions"] & {
    onCopyToCourse?: (courseIds: string[]) => void;
  };
}

export const TopicSidebarOptionsMenu: FC<TopicSidebarOptionsMenuProps> = ({
  styles,
  size = "s",
  className,
  buttonKind = "secondary",
  direction = "right",
  optionsMenuActions,
}) => {
  const menuRef: Ref<MenuInstance> = useRef(null);

  return (
    <SidebarOptionsMenu
      buttonKind={buttonKind}
      className={className}
      direction={direction}
      instanceRef={menuRef}
      optionsMenuActions={optionsMenuActions}
      size={size}
      styles={styles}
    >
      {optionsMenuActions?.onCopyToCourse && (
        <SubMenu
          label={
            <IconText
              iconStart={<CopySendArrowIcon />}
              text={t("sidebar_options_menu.copy_to_course")}
            />
          }
          withArrow
        >
          <CopyToCourseSubMenu
            onCancel={() => {
              menuRef.current.closeMenu();
            }}
            onConfirm={(courseIds) => {
              optionsMenuActions?.onCopyToCourse(courseIds);
              menuRef.current.closeMenu();
            }}
          />
        </SubMenu>
      )}
    </SidebarOptionsMenu>
  );
};
