import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const DotMenuVerticalIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="fill"
      d="M9.5 8C9.5 7.17157 8.82843 6.5 8 6.5C7.17157 6.5 6.5 7.17157 6.5 8C6.5 8.82843 7.17157 9.5 8 9.5C8.82843 9.5 9.5 8.82843 9.5 8Z"
    />
    <path
      className="fill stroke"
      d="M8 11.5C8.55228 11.5 9 11.9477 9 12.5C9 13.0523 8.55228 13.5 8 13.5C7.44772 13.5 7 13.0523 7 12.5C7 11.9477 7.44772 11.5 8 11.5Z"
    />
    <path
      className="fill stroke"
      d="M8 2.5C8.55228 2.5 9 2.94772 9 3.5C9 4.05228 8.55228 4.5 8 4.5C7.44772 4.5 7 4.05228 7 3.5C7 2.94772 7.44772 2.5 8 2.5Z"
    />
  </svg>
);
