import clsx from "clsx";
import type { FC } from "react";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { TrashDeleteIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import { selectIsEditModeEnabled } from "store/selectors";

interface BlockToolbarProps {
  className?: string;
  onDelete: () => void;
  children?: React.ReactNode;
}

export const BlockToolbar: FC<BlockToolbarProps> = ({ className, onDelete, children }) => {
  const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);
  return (
    // z-index should be above `Block` (which is 1) to enable proper
    // visibility and interaction with the toolbar.
    <div
      className={clsx(
        "block-toolbar absolute right-1.5 top-1.5 z-[2] flex flex-col items-center gap-1",
        isEditModeEnabled ? "-mr-10" : "",
        className,
      )}
      role="toolbar"
    >
      {children}
      {isEditModeEnabled && onDelete && (
        <Button
          kind="destructive"
          size="s"
          title={t("tooltip.delete_block")}
          iconOnly
          onClick={onDelete}
        >
          <TrashDeleteIcon />
        </Button>
      )}
      {/* TODO: The Delete button should be moved into the "..." menu along with a "Duplicate" functionality.
          Removing the ... menu for now to simulate how it feels with the # of buttons it will have in the end.
       */}
      {/* <Button */}
      {/*  kind="secondary" */}
      {/*  size="s" */}
      {/*  iconOnly */}
      {/*  onClick={() => { */}
      {/*    alert("Coming soon"); */}
      {/*  }} */}
      {/* > */}
      {/*  <DotMenuVerticalIcon /> */}
      {/* </Button> */}
    </div>
  );
};
