import { useEffect } from "react";

import { serverGetPageAssessmentStatuses } from "api/api-server";
import { PageType } from "components/server-types";
import { storeApi, useAppDispatch, useAppSelector } from "store/index";
import {
  selectCanAuthorCourse,
  selectCurrentCourseId,
  selectCurrentCourseUser,
  selectCurrentUserAssessments,
} from "store/selectors";
import { computedStateActions } from "store/slices/computed";

/**
 * Ensures all user/course data that is required to resolve assessment data is fetched.
 * This does _not_ include assessment / submission / outcome-assessment data directly.
 */
export const useFetchAssessmentBaseData = () => {
  const courseUser = useAppSelector(selectCurrentCourseUser);
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const courseId = useAppSelector(selectCurrentCourseId);

  // Fetching all assessment-relevant data for the current user
  useEffect(() => {
    if (courseId) {
      storeApi.outcomes.list({});
      storeApi.course_outcomes.list({ course_id: courseId });
      storeApi.pages.list({
        course_id: courseId,
        // Technically we only need the unreleased pages, but we already fetch all pages in other
        // views, so dropping this filter attribute allows us to reuse the cached data.
        // released_at__isnull: false,
      });
      // Only to resolve links to pages
      storeApi.topics.list({ course_id: courseId });
      storeApi.activities.list({ topic__course_id: courseId });

      // This will only trigger for students, for now, as staff users will need access to _everyone's_
      // data, which is too much to load on the top level of the course.
      if (!canAuthorCourse && courseUser?.id) {
        storeApi.page_group_users.list({
          course_user_id: courseUser.id,
          course_user__course_id: courseId,
        });
        storeApi.page_groups.list({
          pagegroupuser__course_user_id: courseUser.id,
          page__course_id: courseId,
        });
      }
    }
  }, [courseUser, canAuthorCourse, courseId]);
};

/**
 * Ensures all assessments/submissions/outcome-assessments data for the current course is loaded.
 * If a student accesses this, the backend will limit the query to only be for their own data.
 */
export const useFetchAssessmentData = () => {
  const courseId = useAppSelector(selectCurrentCourseId);

  useEffect(() => {
    if (!courseId) {
      return;
    }
    storeApi.submissions.list({ page__course_id: courseId, official_at__isnull: false });
    storeApi.assessments.list({ submission__page__course_id: courseId });
    storeApi.outcome_assessments.list({ assessment__submission__page__course_id: courseId });
  }, [courseId]);
};

// Fetches and returns all assessments for the current user
export const useFetchAndSelectMyAssessments = () => {
  const assessments = useAppSelector(selectCurrentUserAssessments);
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const courseId = useAppSelector(selectCurrentCourseId);

  useFetchAssessmentBaseData();

  useEffect(() => {
    // Only students can "fetch my assessments", as instructors don't get assessed,
    // and this would also query way too much data for admins.
    // TODO: Org-level admins (like MP admins) who are also students in a course will accidentally
    // fetch way too much data here.
    if (!canAuthorCourse && courseId) {
      // We don't limit this to official submissions, as we might want to show comments from
      // unofficial submission assessments as well.
      storeApi.submissions.list({ page__course_id: courseId });
      storeApi.assessments.list({ submission__page__course_id: courseId });
      storeApi.outcome_assessments.list({ assessment__submission__page__course_id: courseId });
    }
  }, [canAuthorCourse, courseId]);

  return assessments;
};

// Gets the current assessment status for each of the pages in the given list.
// Then stores them in the store for later use.
export const useFetchAssessmentStatus = (pages: PageType[]) => {
  const pageIdString = pages
    .map((page) => page.id)
    .sort()
    .join(",");

  const dispatch = useAppDispatch();

  useEffect(() => {
    // TODO: Determine if this should be done differently (thunk) and
    // what type of caching scheme we might want to use.
    const serverPageAssessmentStatus = async () => {
      const pageIds = pageIdString.split(",");

      const statuses = await serverGetPageAssessmentStatuses(pageIds);

      dispatch(computedStateActions.savePageAssessmentStatuses(statuses));
    };

    if (pageIdString.length > 0) {
      serverPageAssessmentStatus();
    }
  }, [pageIdString, dispatch]);
};
