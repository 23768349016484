import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const AssessPointsMarkerIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M5.66704 12.667L3.80022 14.5339L1 13.6005L3.80022 10.8002"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M10.3328 1.46604L3.79932 7.99957L8.46631 12.6666L14.9998 6.13304L10.3328 1.46604Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
