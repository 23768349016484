import { MouseEvent } from "react";
import type { FC } from "react";

import { t } from "i18n/i18n";
import { AddReferenceKeys } from "i18n/i18next";
import { Button } from "mds/components/Button";
import { AddPlusIcon } from "mds/icons";

export interface AddIconButtonProps {
  onClick: () => void;
  reference: AddReferenceKeys;
}

// TODO: We have 3 separate AddButtons that are similar, review and potentially combine abstractions.
export const AddIconButton: FC<AddIconButtonProps> = ({ onClick, reference }) => {
  return (
    <Button
      className="p-2"
      kind="tertiary"
      size="xs"
      title={t("edit_delete_widget.add", { context: reference })}
      iconOnly
      onClick={(e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
    >
      <AddPlusIcon />
    </Button>
  );
};
