import "./GroupCardShared.scss";

import { type FC, ReactNode } from "react";

import { userActivityIcons } from "components/materials/course/helpers";
import { t } from "i18n/i18n";
import { Card } from "mds/components/Card";
import { ListItem } from "mds/components/ListItem";
import { PersonSingleIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import {
  NestedPageGroupType,
  NestedPageGroupUserType,
  determineActivityStatus,
  selectActivityStatus,
} from "store/selectors";
import { getUsername } from "utils/user-utils";

interface StudentGroupCardProps {
  pageGroup?: NestedPageGroupType;
  pageGroupIndex: number;
  buttonComponent?: ReactNode;
  isActive: boolean;
  shouldFilterForReadiedUsers?: boolean;
}

export const StudentGroupCard: FC<StudentGroupCardProps> = ({
  pageGroup,
  pageGroupIndex,
  buttonComponent,
  shouldFilterForReadiedUsers = false,
  isActive,
}) => {
  const activityStatus = useAppSelector(selectActivityStatus);

  if (!pageGroup) return null;

  const pageGroupUsers = shouldFilterForReadiedUsers
    ? pageGroup.page_group_users.filter((pageGroupUser) => pageGroupUser.is_ready)
    : pageGroup.page_group_users;

  const getUserStatus = (pageGroupUser: NestedPageGroupUserType) => {
    return determineActivityStatus(activityStatus[pageGroupUser.user.id]);
  };

  return (
    <Card className="group-card" isActive={isActive}>
      <div className="flex w-full items-center justify-between">
        <div className="h3 ml-2">
          {t("common.group_title", {
            number: pageGroupIndex + 1,
          })}
        </div>
        <div className="flex">
          <div className="h6 flex items-center justify-center gap-1 px-2 text-black-tint-40">
            <PersonSingleIcon />
            {pageGroupUsers.length}
          </div>
          {buttonComponent}
        </div>
      </div>
      <div className="flex flex-wrap">
        {pageGroupUsers.map((pageGroupUser) => (
          <ListItem className="gap-1" key={pageGroupUser.id}>
            {userActivityIcons[getUserStatus(pageGroupUser)]}{" "}
            <div className="min-w-12 truncate">{getUsername(pageGroupUser.user)}</div>
          </ListItem>
        ))}
        {pageGroupUsers.length === 0 && (
          <div className="body-s ml-2 pt-2 text-black-tint-55">
            {t("student_group_card.no_one_in_group")}
          </div>
        )}
      </div>
    </Card>
  );
};
