import "./GroupCardShared.scss";
import "./GroupConfigCard.scss";
import { sortBy } from "lodash";
import { type FC, useState } from "react";

import { toastLocalizedOperationCatcher } from "../../../../utils/alerts";
import { useDnDItemOrdering } from "../../../../utils/collections";

import { serverDistributeUsersToGroups } from "api/api-server";
import {
  getActiveStateSortKeyForUser,
  userActivityIcons,
} from "components/materials/course/helpers";
import { PageGroupCard } from "components/materials/page/groups/PageGroupCard";
import {
  DraggableCourseUserType,
  DraggablePageGroupUserType,
  getUngroupedStudentUsers,
} from "components/materials/page/groups/helpers";
import { GroupCategoryConfig } from "components/materials/page/groups/menus/GroupCategoryConfig";
import { GroupSizeConfig } from "components/materials/page/groups/menus/GroupSizeConfig";
import { GroupingCategory, PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { Banner } from "mds/components/Banner";
import { Button } from "mds/components/Button";
import { Card } from "mds/components/Card";
import { DragAndDrop } from "mds/components/DragAndDrop";
import { ListItem } from "mds/components/ListItem";
import { TextAddButton } from "mds/components/TextAddButton";
import { CrossRemoveIcon, LoadingDotsIcon, PersonSingleIcon } from "mds/icons";
import { storeApi, useAppSelector } from "store/index";
import {
  NestedPageGroupType,
  UserWithCourseActivity,
  selectAssessmentsByPageId,
  selectIsAssessing,
  selectUsersWithCourseActivityInCurrentCourse,
} from "store/selectors";
import { useListSelector } from "store/store-hooks";
import { getUserLastNameSortKey, getUsername } from "utils/user-utils";

interface GroupConfigCardProps {
  setShowGroupConfigurationCard: (showCard: boolean) => void;
  page: PageType;
  pageGroups: NestedPageGroupType[];
}

export const GroupConfigCard: FC<GroupConfigCardProps> = ({
  setShowGroupConfigurationCard,
  page,
  pageGroups,
}) => {
  const assessments = useAppSelector((s) => selectAssessmentsByPageId(s, page.id));
  const isAssessing = useAppSelector(selectIsAssessing);
  const users = useListSelector(selectUsersWithCourseActivityInCurrentCourse);
  const studentUsers = sortBy<UserWithCourseActivity>(users, [
    getActiveStateSortKeyForUser,
    getUserLastNameSortKey,
  ]).filter((course_user) => course_user.role === "student" && !course_user.is_disabled);

  const [isDistributingStudents, setIsDistributingStudents] = useState(false);
  const hasAssessments = assessments.length > 0;
  const isReadOnly = hasAssessments || isAssessing;

  const ungroupedStudentUsers = getUngroupedStudentUsers(pageGroups, studentUsers);

  const moveCourseUserLocally = useDnDItemOrdering(
    ungroupedStudentUsers,
    () => {},
    "page_group_id",
  );
  const onDropCourseUser = (
    droppedItem: DraggableCourseUserType | DraggablePageGroupUserType,
    newIndex: number,
    sourceType: "course_user" | "page_group_user",
  ) => {
    // If the dropped item is "course_user", a page group user is being dropped
    // into the ungrouped course_users list.
    if (droppedItem.page_group_id === "course_user" && sourceType === "page_group_user") {
      storeApi.page_group_users.destroy(droppedItem.id);
    }

    const targetPageGroup = pageGroups.find(
      (pageGroup) => pageGroup.id === droppedItem.page_group_id,
    );

    if (!targetPageGroup) return;

    if (sourceType === "course_user") {
      // If the source type is "course_user", a course_user is being dropped into a group and
      // a page_group_user needs to be created.
      storeApi.page_group_users.create({
        page_group_id: droppedItem.page_group_id,
        course_user_id: droppedItem.course_user_id,
        is_ready: true,
      });
    } else {
      // If the source type is "page_group_user", it means that a page_group_user is
      // being dropped into a group and its page_group_id needs to be updated.
      storeApi.page_group_users.partial_update({
        id: droppedItem.id,
        page_group_id: droppedItem.page_group_id,
        is_ready: true,
      });
    }
  };

  // If all course_users are grouped but there is a group that exceeds the max group size limit,
  // the Distribute Students button should be re-enabled.
  const isAssignedGroupsGroupingCategory =
    page.grouping_category === GroupingCategory.ASSIGN_STUDENTS;
  const isStudentSelfAssignGroupingCategory =
    page.grouping_category === GroupingCategory.SELF_ASSIGN;
  const areAllStudentsGrouped = ungroupedStudentUsers.length === 0;
  const isMaxGroupSizeSet = page.max_group_size !== null;
  const isAnyGroupOverSizeLimit = pageGroups.find(
    (pageGroup) => pageGroup.page_group_users.length > page.max_group_size,
  );
  const noStudentsLeftToDistribute =
    areAllStudentsGrouped && (!isMaxGroupSizeSet || !isAnyGroupOverSizeLimit);
  const isDistributeDisabled =
    !isAssignedGroupsGroupingCategory ||
    noStudentsLeftToDistribute ||
    isDistributingStudents ||
    isReadOnly;

  const onDistributeStudents = () => {
    setIsDistributingStudents(true);
    serverDistributeUsersToGroups(page.id)
      .catch(() => toastLocalizedOperationCatcher("failed_to_distribute_users_to_groups"))
      .finally(() => setIsDistributingStudents(false));
  };

  const isResetGroupsDisabled =
    pageGroups.reduce((acc, curr) => curr.page_group_users.length + acc, 0) === 0 || isReadOnly;

  const onResetGroups = () => {
    // TODO: This should be a batch delete all or delete many.
    pageGroups.forEach((pageGroup) => {
      pageGroup.page_group_users.forEach((pageGroupUser) => {
        storeApi.page_group_users.destroy(pageGroupUser.id);
      });
    });
  };

  return (
    <div className="group-config-card group-card flex w-full flex-col items-center justify-center gap-4 rounded-2xl border border-black-tint-90 bg-black-tint-97 px-5 py-4">
      <div className="flex w-full flex-wrap justify-between">
        <div className="h2 truncate">{t("group_configuration_card.groups")}</div>
        <div className="flex flex-wrap gap-1">
          <GroupCategoryConfig
            isReadOnly={isReadOnly}
            page={page}
            pageGroups={pageGroups}
            title={
              hasAssessments
                ? t("tooltip.group_config_has_assessments")
                : t("group_configuration_card.tooltip.group_selection_settings")
            }
          />

          <Button
            kind="secondary"
            size="xs"
            title={t("group_configuration_card.tooltip.close_group_settings")}
            iconOnly
            onClick={() => setShowGroupConfigurationCard(false)}
          >
            <CrossRemoveIcon />
          </Button>
        </div>
      </div>

      {isAssessing && !page.assessments_published_at && (
        <div className="w-full">
          <Banner kind="info">{t("group_configuration_card.anonymity_alert")}</Banner>
        </div>
      )}

      <div className="flex w-full flex-wrap justify-between gap-3">
        <div className="panel flex w-full flex-col gap-3">
          <DragAndDrop
            className="w-full"
            dragItemKind="course_user"
            dropContainerId="course_user"
            droppable
            onDraggedItemHover={moveCourseUserLocally}
          >
            <Card>
              <div className="mb-1 flex w-full justify-between">
                <div className="h3 ml-2 truncate">{t("group_configuration_card.students")}</div>
                <div className="h6 flex items-center justify-center gap-1 px-2 text-black-tint-40">
                  <PersonSingleIcon />
                  {ungroupedStudentUsers.length}/{studentUsers.length}
                </div>
              </div>

              {ungroupedStudentUsers.map((course_user) => (
                <DragAndDrop
                  className="w-full"
                  dragItemKind="course_user"
                  dropContainerId="course_user"
                  item={course_user}
                  key={course_user.id}
                  draggable
                  onDropItem={onDropCourseUser}
                >
                  <ListItem
                    className="flex w-full items-center gap-1.5"
                    key={course_user.id}
                    grabbable
                  >
                    {userActivityIcons[course_user.courseActivityStatus]}
                    <div className="truncate">{getUsername(course_user)}</div>
                  </ListItem>
                </DragAndDrop>
              ))}
            </Card>
          </DragAndDrop>
        </div>
        {isDistributingStudents ? (
          <div className="panel flex w-full justify-center">
            <div className="pt-7">
              <LoadingDotsIcon />
            </div>
          </div>
        ) : (
          <div className="panel flex w-full flex-col">
            <div className="flex flex-col gap-3">
              {pageGroups.map((pageGroup, index) => (
                <PageGroupCard
                  canDeleteGroup={!isReadOnly}
                  key={pageGroup.id}
                  moveCourseUserLocally={moveCourseUserLocally}
                  number={index + 1}
                  page={page}
                  pageGroup={pageGroup}
                  totalPageGroups={pageGroups.length}
                  onDropCourseUser={onDropCourseUser}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="flex w-full flex-wrap justify-between">
        <Button
          disabled={isResetGroupsDisabled}
          kind="tertiary"
          size="xs"
          title={
            hasAssessments ? t("tooltip.group_config_has_assessments") : t("tooltip.reset_groups")
          }
          onClick={onResetGroups}
        >
          {t("group_configuration_card.reset_groups")}
        </Button>
        <div className="flex gap-2">
          <TextAddButton
            className="add-page-group"
            kind="secondary"
            reference="page_group"
            onClick={() => {
              storeApi.page_groups.create({
                page_id: page.id,
              });
            }}
          />

          {isAssignedGroupsGroupingCategory && (
            <>
              <GroupSizeConfig
                isReadOnly={isReadOnly}
                page={page}
                title={
                  hasAssessments
                    ? t("tooltip.group_config_has_assessments")
                    : t("tooltip.group_size")
                }
              />
              <Button
                disabled={isDistributeDisabled}
                kind="secondary"
                size="xs"
                title={
                  hasAssessments
                    ? t("tooltip.group_config_has_assessments")
                    : noStudentsLeftToDistribute
                      ? t("tooltip.distribute.no_students")
                      : t("tooltip.distribute.default")
                }
                onClick={onDistributeStudents}
              >
                {t("group_configuration_card.distribute_students")}
              </Button>
            </>
          )}
          {isStudentSelfAssignGroupingCategory && (
            <GroupSizeConfig
              isReadOnly={isReadOnly}
              page={page}
              title={
                hasAssessments ? t("tooltip.group_config_has_assessments") : t("tooltip.group_size")
              }
            />
          )}
          <Button kind="primary" size="xs" onClick={() => setShowGroupConfigurationCard(false)}>
            {t("common.done")}
          </Button>
        </div>
      </div>
    </div>
  );
};
