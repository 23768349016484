// eslint-disable-next-line no-restricted-imports
import { Provider as ReduxStoreProvider } from "react-redux";

import { toastLocalizedOperationCatcher, toastSuccessMessage } from "../../../../utils/alerts";

import { serverImportCourse } from "api/api-server";
import { ImportCourseModal } from "components/materials/course/content/ImportCourseModal";
import { ImportCourseRequestType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { showConfirmationModal } from "mds/components/Modal";
import { CopySendArrowIcon } from "mds/icons";
import { store } from "store/index";

const onShowModal = async (onSave: (data: ImportCourseRequestType) => void) => {
  const data = (await showConfirmationModal({
    title: t("import_modal.import_course"),
    hideCancelConfirmButtons: true,
    children: (onClose: () => void, onConfirm: (data) => void) => (
      <ReduxStoreProvider store={store}>
        <ImportCourseModal onCancel={onClose} onConfirm={onConfirm} />
      </ReduxStoreProvider>
    ),
  })) as ImportCourseRequestType;
  if (data) {
    return onSave(data);
  }
};

export const ImportCourseButton = () => {
  const handleImport = (data: ImportCourseRequestType) => {
    serverImportCourse(data)
      .then(() => {
        toastSuccessMessage(t("success.toasts.import_course_started_success"));
      })
      .catch(toastLocalizedOperationCatcher("import_course_failure"));
  };

  return (
    <Button
      className="gap-2"
      kind="primary"
      size="s"
      title={t("content_view.import_course")}
      onClick={() => {
        onShowModal(handleImport).catch(toastLocalizedOperationCatcher("import_modal_failure"));
      }}
    >
      <CopySendArrowIcon />
      {t("content_view.import_course")}
    </Button>
  );
};
