import { FC, Ref, useRef } from "react";

import { MoveToSubMenu } from "./MoveToSubMenu";
import { SidebarOptionsMenu, SidebarOptionsMenuProps } from "./SidebarOptionsMenu";

import { ReleaseStatus } from "components/server-types";
import { t } from "i18n/i18n";
import { IconText } from "mds/components/IconText";
import { MenuDivider, MenuInstance, MenuItem, SubMenu } from "mds/components/Menu";
import { AddPlusIcon, ArchiveFileBoxIcon, CopySendArrowIcon, PageListStatusIcon } from "mds/icons";

interface ActivitySidebarOptionsMenuProps extends SidebarOptionsMenuProps {
  currentTopicId?: string;
  releaseStatus?: ReleaseStatus;
  optionsMenuActions: SidebarOptionsMenuProps["optionsMenuActions"] & {
    onAddPage?: () => void;
    onChangeStatus?: () => void;
    onArchive?: () => void;
    onUnarchive?: () => void;
    onUnarchiveAll?: () => void;
    onMoveTo?: (topicId: string) => void;
  };
}

export const ActivitySidebarOptionsMenu: FC<ActivitySidebarOptionsMenuProps> = ({
  currentTopicId,
  styles,
  size = "s",
  className,
  buttonKind = "secondary",
  direction = "right",
  releaseStatus,
  optionsMenuActions,
}) => {
  const menuRef: Ref<MenuInstance> = useRef(null);

  return (
    <SidebarOptionsMenu
      buttonKind={buttonKind}
      className={className}
      direction={direction}
      instanceRef={menuRef}
      optionsMenuActions={optionsMenuActions}
      size={size}
      styles={styles}
    >
      {optionsMenuActions?.onAddPage && (
        <MenuItem onClick={optionsMenuActions.onAddPage}>
          <IconText iconStart={<AddPlusIcon />} text={t("common.add.page")} />
        </MenuItem>
      )}

      {optionsMenuActions?.onChangeStatus &&
        (releaseStatus === "released" ? (
          <MenuItem
            title={t("sidebar_options_menu.tooltip.unrelease_pages")}
            onClick={optionsMenuActions.onChangeStatus}
          >
            <IconText
              iconStart={<PageListStatusIcon color="gray" />}
              text={t("sidebar_options_menu.unrelease_pages")}
            />
          </MenuItem>
        ) : (
          <MenuItem
            disabled={!releaseStatus}
            title={t(
              `sidebar_options_menu.tooltip.${releaseStatus ? "release_pages" : "no_pages"}`,
            )}
            onClick={optionsMenuActions.onChangeStatus}
          >
            <IconText
              iconStart={<PageListStatusIcon color="green" />}
              text={t("sidebar_options_menu.release_pages")}
            />
          </MenuItem>
        ))}

      <MenuDivider />
      {optionsMenuActions?.onArchive && (
        <MenuItem onClick={optionsMenuActions.onArchive}>
          <IconText iconStart={<ArchiveFileBoxIcon />} text={t("sidebar_options_menu.archive")} />
        </MenuItem>
      )}
      {optionsMenuActions?.onUnarchive && (
        <MenuItem onClick={optionsMenuActions.onUnarchive}>
          <IconText iconStart={<ArchiveFileBoxIcon />} text={t("sidebar_options_menu.unarchive")} />
        </MenuItem>
      )}
      {optionsMenuActions?.onUnarchiveAll && (
        <MenuItem onClick={optionsMenuActions.onUnarchiveAll}>
          <IconText
            iconStart={<ArchiveFileBoxIcon />}
            text={t("sidebar_options_menu.unarchive_all")}
          />
        </MenuItem>
      )}
      {optionsMenuActions?.onMoveTo && (
        <SubMenu
          label={
            <IconText iconStart={<CopySendArrowIcon />} text={t("sidebar_options_menu.move_to")} />
          }
          overflow="auto"
          withArrow
        >
          <MoveToSubMenu
            currentTopicId={currentTopicId}
            itemKind="activity"
            onCancel={() => {
              menuRef.current.closeMenu();
            }}
            onConfirm={(topicId) => {
              optionsMenuActions?.onMoveTo(topicId);
              menuRef.current.closeMenu();
            }}
          />
        </SubMenu>
      )}
    </SidebarOptionsMenu>
  );
};
