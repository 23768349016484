import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { BLOCKS_PATH, DATA_PATH } from "../worksheets/shared/constants";
import { FieldDataType, WorksheetType } from "../worksheets/shared/types/worksheet";

import { isArchived } from "./activity";
import { determineOrderForNewAppend } from "./collections";
import { presentSession, usePresentation } from "./presentation";
import { blankInstructorNotesWorksheetData } from "./worksheet";

import { serverCopyPage, serverMovePageWithinCourse } from "api/api-server";
import {
  ACCESS_ID_QUERY_PARAM,
  FOLLOWING_QUERY_PARAM,
  PAGE_ID_QUERY_PARAM,
  SUBMISSION_ID_QUERY_PARAM,
} from "components/constants";
import {
  ActivityPageType,
  ActivityType,
  CoursePageCategory,
  GroupingCategory,
  PageCategory,
  PageType,
  coursePageCategories,
} from "components/server-types";
import { t } from "i18n/i18n";
import { EXAMPLE_CONTENT_BLOCKS, EXAMPLE_CONTENT_DATA } from "seeds/worksheets/example-blocks";
import { storeApi, useAppSelector } from "store/index";
import { ActivityWithPagesType, selectCurrentCourseId, selectCurrentUserId } from "store/selectors";
import {
  toastCopyOperationCatcher,
  toastMoveOperationCatcher,
  toastSuccessMessage,
} from "utils/alerts";

export const PAGE_LIST_CUTOFF_LENGTH = 10;

export const pageTitle = (pageCategory: PageCategory) => {
  return t(`common.default.${pageCategory}`);
};

export const isAssignment = (pageCategory: PageCategory) => {
  return pageCategory === "assignment";
};

export const isCoursePageCategory = (
  pageCategory: PageCategory,
): pageCategory is CoursePageCategory => {
  return coursePageCategories.includes(pageCategory as CoursePageCategory);
};

export const isCoursePage = (page: PageType) => {
  return isCoursePageCategory(page.category);
};

export const sumActivityTimes = (activityList: ActivityType[]) => {
  return activityList.reduce((total, activity) => total + Number(activity.estimated_time), 0);
};

export const getReleaseStatus = (pages: PageType[]) =>
  pages.length === 0
    ? null
    : pages.every((page) => page.released_at)
      ? "released"
      : pages.some((page) => page.released_at)
        ? "partially_released"
        : "unreleased";

/*
 * The following hook returns functions for maintaining pages in the store.
 */
export const usePageActions = () => {
  const courseId = useAppSelector(selectCurrentCourseId);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    presentedSessionId,
    isPresenting,
    isPracticing,
    isPresentingUser,
    isFollowing,
    presentedPageId,
  } = usePresentation();
  const userId = useAppSelector(selectCurrentUserId);
  const navigate = useNavigate();

  const savePage = useCallback(
    (page: Partial<PageType> & { id: string }) => storeApi.pages.partial_update(page),
    [],
  );

  const dropPage = useCallback(
    (droppedPage: ActivityPageType, activities: ActivityWithPagesType[]) => {
      const targetActivity = activities.find((a) => a.id === droppedPage.activity_id);

      // Dragging a page to the archive should unrelease it
      if (isArchived(targetActivity.category) && droppedPage.released_at) {
        const confirmedDropToArchive = window.confirm(
          "Are you sure you want to unpublish this page? It will no longer be visible to students.",
        );

        if (confirmedDropToArchive) {
          // This also unpublishes the page
          savePage({
            id: droppedPage.id,
            released_at: null,
            activity_id: droppedPage.activity_id,
            order: droppedPage.order,
          });
        } else {
          // This forces a re-fresh, getting the server-side data and re-rendering the sidebar
          savePage({ id: droppedPage.id });
        }
      } else {
        savePage({ id: droppedPage.id, activity_id: targetActivity.id, order: droppedPage.order });
      }
    },
    [savePage],
  );

  const selectPage = useCallback(
    (page: PageType | null, pageUrl?: URL) => {
      const urlParams = pageUrl ? pageUrl.searchParams : searchParams;

      // Set the isFollowing parameter based on our class session state
      if (isPresenting) {
        // This shouldn't happen, but an instructor could type it into a url.
        if (isFollowing) {
          urlParams.delete(FOLLOWING_QUERY_PARAM);
        }

        // We need to update the session to present the new page
        if (page?.id !== presentedPageId) {
          presentSession(presentedSessionId, {
            page,
            userId,
            isPractice: isPracticing,
            accessId: null,
            isRollup: false,
          });
        }
      } else if (page?.id && page?.id === presentedPageId && !isFollowing && !isPresentingUser) {
        urlParams.set(FOLLOWING_QUERY_PARAM, "1");
      } else if (isFollowing && page?.id !== presentedPageId) {
        urlParams.delete(FOLLOWING_QUERY_PARAM);
      }

      urlParams.delete(ACCESS_ID_QUERY_PARAM);
      urlParams.delete(SUBMISSION_ID_QUERY_PARAM);

      if (page?.id) {
        urlParams.set(PAGE_ID_QUERY_PARAM, page.id);
      }

      if (!pageUrl) {
        setSearchParams(urlParams);
      }
    },
    [
      isPresenting,
      isPresentingUser,
      isPracticing,
      isFollowing,
      presentedPageId,
      presentedSessionId,
      userId,
      searchParams,
      setSearchParams,
    ],
  );

  // Normally when we call select page we are just updating the existing url, but it's possible
  // we are switching pages entirely, so we need to set any parameters it would set on our new url
  // not on the existing search pages.
  const navigateToPage = (page: PageType, pageUrl: string) => {
    const newUrl = new URL(pageUrl, window.location.origin);

    selectPage(page, newUrl);
    navigate(newUrl.pathname + newUrl.search);
  };

  const createPage = useCallback(
    async (category: PageCategory, otherPages: PageType[], activity_id?: string) => {
      const isSeedingNewWorksheets = searchParams.get("seedWorksheets") === "1";
      const initial_worksheet_data = isSeedingNewWorksheets
        ? ({
            [BLOCKS_PATH]: EXAMPLE_CONTENT_BLOCKS,
            [DATA_PATH]: EXAMPLE_CONTENT_DATA as unknown as FieldDataType, // TODO: Make example data match FieldDataType
          } as Partial<WorksheetType>)
        : {};

      const page = await storeApi.pages.create({
        category,
        title: pageTitle(category),
        order: determineOrderForNewAppend(otherPages),
        course_id: courseId,
        activity_id,
        initial_worksheet_data,
        grouping_category: GroupingCategory.INDIVIDUAL,
        instructor_notes_worksheet_data: blankInstructorNotesWorksheetData(),
      });

      selectPage(page);

      return page;
    },
    [searchParams, selectPage, courseId],
  );

  const deletePage = useCallback((page: PageType) => {
    storeApi.pages.destroy(page.id);
  }, []);

  const lockPage = useCallback(
    (page: PageType) => {
      savePage({ id: page.id, locked_at: new Date().toISOString() });
    },
    [savePage],
  );

  const unlockPage = useCallback(
    (page: PageType) => {
      savePage({ id: page.id, locked_at: null });
    },
    [savePage],
  );

  const copyPage = useCallback(
    (
      category: PageCategory,
      itemId: string,
      { courseIds, activityIds }: { courseIds?: string[]; activityIds?: string[] },
    ) => {
      serverCopyPage(itemId, courseIds, activityIds)
        .then(() => {
          toastSuccessMessage(t("content_view.item_copied", { item: t(`glossary.${category}`) }));
        })
        .catch(toastCopyOperationCatcher(category));
    },
    [],
  );

  const movePageWithinCourse = useCallback(
    (
      itemId: string,
      {
        destinationCategory,
        destinationTopicId,
      }: { destinationCategory: PageCategory; destinationTopicId?: string },
    ) => {
      // When a page is moved to the "activity_page" category, from the user's perspective it's moved
      // to another Topic. Better handling later would be to also show the Topic's name in the toast.
      const destinationKey =
        destinationCategory === "activity_page" ? "topic" : destinationCategory;

      serverMovePageWithinCourse(itemId, destinationCategory, destinationTopicId)
        .then(() => {
          toastSuccessMessage(
            t("content_view.item_moved", { destinationCategory: t(`glossary.${destinationKey}`) }),
          );
        })
        .catch(toastMoveOperationCatcher(destinationKey));
    },
    [],
  );

  return {
    createPage,
    savePage,
    deletePage,
    lockPage,
    unlockPage,
    copyPage,
    dropPage,
    selectPage,
    navigateToPage,
    movePageWithinCourse,
  };
};
