import clsx from "clsx";
import { FC } from "react";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { ChevronLeftIcon, ChevronRightIcon } from "mds/icons";
import { getNavigationValues } from "utils/urls";

export type NavigationButtonsProps = {
  ids: string[];
  currentId: string;
  className?: string;
  queryParam?: string;
  itemText?: string;
  onChange?: (itemId: string, itemUrl: URL) => void;
};

// This is a simple navigation row for displaying navigation buttons.
// TODO: look at combining this with ContentNavigationButtons once that has
// been better optimized.  The real difference in this component is that we
// display the buttons differently.
export const NavigationButtons: FC<NavigationButtonsProps> = ({
  ids,
  currentId,
  queryParam,
  itemText,
  className,
  onChange,
}) => {
  const { nextId, prevId, nextURL, prevURL } = getNavigationValues(ids, { queryParam, currentId });
  const currentIndex = ids.findIndex((id) => id === currentId);

  return (
    <div className={clsx("flex w-full items-center justify-between", className)}>
      <Button
        aria-label={t("common.previous")}
        disabled={!prevId}
        kind="secondary"
        size="l"
        to={!onChange && prevURL ? prevURL.toString() : undefined}
        iconOnly
        onClick={onChange ? () => onChange(prevId, prevURL) : undefined}
      >
        <ChevronLeftIcon />
      </Button>

      <div className="body-xs font-medium text-black-tint-55">
        {t("common.sequential_counter", {
          itemText,
          index: currentIndex + 1,
          total: ids.length,
        })}
      </div>

      <Button
        aria-label={t("common.next")}
        disabled={!nextId}
        kind="secondary"
        size="l"
        to={!onChange && nextURL ? nextURL.toString() : undefined}
        iconOnly
        onClick={onChange ? () => onChange(nextId, nextURL) : undefined}
      >
        <ChevronRightIcon />
      </Button>
    </div>
  );
};
