import clsx from "clsx";
import { ReactNode } from "react";

import { Button, ButtonProps } from "mds/components/Button";
import { ClickEvent, Menu, MenuItem, MenuProps } from "mds/components/Menu";
import { ChevronDownIcon } from "mds/icons";

type DropdownItemType = { id: string; title: string | ReactNode; iconStart?: ReactNode };

export type DropdownMenuProps<T extends DropdownItemType, V extends T[keyof T]> = {
  items: T[];
  selectedValue?: V;
  kind?: ButtonProps["kind"];
  size?: ButtonProps["size"];
  className?: string;
  buttonText?: ReactNode;
  onSelectionChange: (selectedItem: T) => void;
  disabled?: boolean;
  truncateWidth?: "xs" | "s" | "m" | "l" | "xl";
  allowFullLengthItems?: boolean;
  title?: string;
  titleIcon?: ReactNode;
  menuProps?: Partial<MenuProps>;
};

export const DropdownMenu = <T extends DropdownItemType, V extends T[keyof T]>({
  selectedValue,
  className,
  kind = "secondary",
  size = "xs",
  truncateWidth = "m",
  allowFullLengthItems = false,
  items,
  buttonText,
  onSelectionChange,
  disabled,
  title,
  menuProps,
}: DropdownMenuProps<T, V>) => {
  const selectedItem = items.find((item) => item.id === selectedValue) || items[0];

  const onItemClick = (e: ClickEvent) => {
    const item = e.value as T;
    onSelectionChange(item);
  };

  const menuButton = (
    <Button
      className={clsx(className, "gap-1", truncateWidth && `max-w-${truncateWidth}`)}
      disabled={disabled}
      kind={kind}
      size={size}
      title={title}
    >
      {!buttonText && selectedItem?.iconStart}
      <span className={clsx(truncateWidth && `block truncate max-w-${truncateWidth}`)}>
        {buttonText || selectedItem?.title}
      </span>
      <ChevronDownIcon />
    </Button>
  );

  const MenuItems = items.map((item) => {
    const selected = item.id === selectedValue;
    return (
      <MenuItem
        checked={selected}
        className={clsx({ selected })}
        disabled={"disabled" in item ? (item.disabled as boolean) : false}
        key={item.id}
        title={typeof item.title === "string" ? item.title : undefined}
        value={item}
        onClick={onItemClick}
      >
        {item.iconStart}
        <span
          className={clsx(
            truncateWidth && !allowFullLengthItems && `block truncate max-w-${truncateWidth}`,
          )}
        >
          {item.title}
        </span>
      </MenuItem>
    );
  });

  return (
    <Menu menuButton={menuButton} overflow="auto" {...menuProps}>
      {MenuItems}
    </Menu>
  );
};
