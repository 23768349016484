import { FC } from "react";
import { useNavigate } from "react-router";

import { NavigationButtons } from "../NavigationButtons";

import { TopicType } from "components/server-types";
import { t } from "i18n/i18n";
import { selectVisibleTopicsForCurrentCourse } from "store/selectors";
import { useListSelector } from "store/store-hooks";
import { useFooterSpacingIfMobileBanner } from "utils/hooks/use-footer-spacing-if-mobile-banner";
import { courseContentUrl } from "utils/urls";

export type TopicFooterProps = {
  topic: TopicType;
};

// This is the footer for the Topic Sidebar Panel used to navigate between topics.
export const TopicFooter: FC<TopicFooterProps> = ({ topic }) => {
  const visibleTopics = useListSelector(selectVisibleTopicsForCurrentCourse);
  const topicIds = visibleTopics.map((top) => top.id);

  const navigate = useNavigate();
  const { footerMargin } = useFooterSpacingIfMobileBanner();

  const onChange = (newTopicId: string) => {
    navigate(courseContentUrl(topic.course_id, newTopicId));
  };

  return (
    <NavigationButtons
      className={`content-sidebar-footer ${footerMargin} footer mt-2`}
      currentId={topic.id}
      ids={topicIds}
      itemText={t("glossary.topic")}
      onChange={onChange}
    />
  );
};
