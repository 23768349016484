import { createSlice } from "@reduxjs/toolkit";
import type { Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { isEqual } from "lodash";

import { truncate } from "../worksheets/shared/utils";

import { SERVER_API_PATH, ServerApiResultsResponseType, serverApi } from "api/api-server";
import { t } from "i18n/i18n";
import { GlossaryKey } from "i18n/i18next";
import { viewStateActions } from "store/slices/view";
import { createReplaceAllReducer } from "store/utils";
import { toastLocalizedOperationCatcher } from "utils/alerts";
import { addBroadcastListener } from "utils/broadcast";
import { rollbarAndLogError } from "utils/logger";
import { ModelType } from "utils/type-utils";

const ONE_HOUR_IN_MS = 3600 * 1000;
export const DEFAULT_CACHE_TIME_MS = ONE_HOUR_IN_MS;

/**
 * PubsubSlices internally cache API responses, which we sometimes want to invalidate
 * from an external source. Since the cache is stored internally, every slice invocation
 * saves a callback to clear its own cache here.
 */
const clearCacheCallbacks = new Set<() => void>();
export const clearPubsubAPICache = () => {
  clearCacheCallbacks.forEach((cb) => cb());
};

// None of the recommended types for empty model quite work here
// so we're just using an empty object for now.
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export type EmptyModel = {};

type OperationType = "create" | "update" | "destroy" | "retrieve" | "list" | "partial_update";

type OperationOptions = {
  skipToast?: boolean;
  skipCache?: boolean;
};

type CacheEntryType<T> = {
  ts: number;
  data: Promise<T | T[]>;
};

const storeOperationToToastKey: Record<OperationType, "update" | "load" | "delete" | "create"> = {
  create: "create",
  update: "update",
  destroy: "delete",
  retrieve: "load",
  list: "load",
  partial_update: "update",
};

/**
 * Creates a Redux-API-pubsub binding for a given model.
 * The binding consists of:
 * - A store slice mapping ID to model data.
 * - Reducers for adding/updating/removing model data from the slice.
 * - API methods for querying, getting, creating, updating, and removing model data.
 * - A pubsub listener setup that hooks into the Collaboration Websockets and forwards
 *   actions to the relevant reducers for the model. E.g. deleting a model on the backend
 *   will propagate through Collaboration and then delete the model in the store slice.
 *
 * @param modelName The model name should be the same as the pluralized model name on the server,
 * and the same as the main API endpoint path name for the model.
 */
export const createPubsubSlice = <
  T extends ModelType & { course_version?: number; course_id?: string },
  L = EmptyModel,
  I = EmptyModel,
>(
  modelApp: string,
  modelName: string,
  usesCache = true,
) => {
  type ListType = L;
  type InsertType = T & I;

  // We can completely skip cached API calls, as we expect them to be updated
  // via pubsub after the original fetch. We still expire this cache with time,
  // in case the user returns to an inactive tab and has missed any websocket
  // messages. Once we have pubsub versioning, we can instead replay missed messages
  // on window focus or in intervals, and remove the time-based cache expiry.
  const apiCache = new Map<string, CacheEntryType<T>>();
  clearCacheCallbacks.add(() => apiCache.clear());

  const initialState = () => ({}) as Record<T["id"], T>;
  const slice = createSlice({
    name: modelName,
    initialState,
    reducers: {
      updateMany: (state, action: PayloadAction<T[]>) => {
        action.payload.forEach((item) => {
          state[item.id] = item;
        });
      },
      updateOne: (state, action: PayloadAction<T>) => {
        const existing = state[action.payload.id] as T;
        if (!isEqual(existing, action.payload)) {
          state[action.payload.id] = action.payload;
        }
      },
      patchOne: (state, action: PayloadAction<Partial<T>>) => {
        const id = action.payload.id as string;
        state[id] = { ...state[id], ...action.payload } as T;
      },
      removeOne: (state, action: PayloadAction<{ id: string }>) => {
        delete state[action.payload.id];
      },
    },
    extraReducers: createReplaceAllReducer<Record<T["id"], T>>(modelName, initialState),
  });

  // TODO: Make sure all models on the backend publish their updates using this schema
  const addListener = <P extends Dispatch>(dispatch: P) =>
    addBroadcastListener((message) => {
      if (message.type !== "model-update" || message.model !== modelName) {
        return;
      }
      const newData = message.data as T;
      if (message.action === "create" || message.action === "update") {
        dispatch(slice.actions.updateOne(newData));
      } else if (message.action === "delete") {
        dispatch(slice.actions.removeOne(newData));
      }
    });

  const errorReporter =
    (
      action: OperationType,
      options?: OperationOptions & { bustKey?: string; params?: Record<string, string> },
    ) =>
    (error: AxiosError | Error) => {
      // TODO: We should use the skipToast option more often, possibly on all/most of the API
      //  use that isn't _directly_ related to user input
      if (!options?.skipToast) {
        const toastAction = storeOperationToToastKey[action];
        toastLocalizedOperationCatcher(`${toastAction}_failure`, {
          item: t(`glossary.${modelName as GlossaryKey}`),
        })(error);
      }

      // If an API call fails, we don't want to keep returning the cached error,
      // rather force a re-try on the next request.
      if (options?.bustKey) {
        apiCache.delete(options.bustKey);
      }

      function logAxiosErrorToRollbar(err: AxiosError) {
        const queryParams = err.config.params as Record<string, string>;
        const stringParams = Object.entries(queryParams || {})
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
        const requestString = `${err.config.url}?${stringParams}`;

        rollbarAndLogError(`storeAPI axios error: ${err.message}`, {
          model: modelName,
          action,
          requestUrl: requestString,
          refererUrl: window.location.pathname + window.location.search,
          err,
          status: err.response?.status,
          body: truncate(err.response?.data, 200),
        });
      }

      function logGenericErrorToRollbar(err: Error) {
        const queryParams = options?.params;
        const stringParams = Object.entries(queryParams || {})
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
        const requestString = `?${stringParams}`;
        rollbarAndLogError(`storeAPI generic error: ${err.message}`, {
          model: modelName,
          action,
          err,
          refererUrl: window.location.pathname + window.location.search,
          query: requestString,
        });
      }

      if (error instanceof AxiosError) {
        logAxiosErrorToRollbar(error);
      } else {
        logGenericErrorToRollbar(error);
      }

      throw error;
    };

  // TODO: Loading/error state indicator for the API methods
  // TODO: Make sure all backend handlers support these actions
  // Note: While list/retrieve endpoints do return data directly, we prefer downstream consumers
  // to use the store to get the data, and not rely on the API call, where possible.
  const getApi = <P extends Dispatch>(dispatch: P) => {
    const updateAndReturn = (res: AxiosResponse<T>) => {
      const item = res.data;

      if ("course_version" in item) {
        dispatch(
          viewStateActions.lowerCourseVersionTo({
            results: [item],
            modelName,
          }),
        );
        // We remove the course_version from the results, as it's not relevant after this.
        if (modelName !== "courses") {
          delete item.course_version;
        }
      }

      dispatch(slice.actions.updateOne(item));
      return item;
    };

    const retrieve = (id: string) => {
      return serverApi
        .get<T>(`${SERVER_API_PATH}/${modelApp}/${modelName}/${id}/`)
        .then(updateAndReturn);
    };

    return {
      retrieve: (id: string, options?: OperationOptions) => {
        const cacheKey = id;
        if (cacheKey && usesCache && !options?.skipCache && apiCache.has(cacheKey)) {
          const cacheEntry = apiCache.get(cacheKey);
          const isCacheValid = Date.now() - (cacheEntry?.ts || 0) < DEFAULT_CACHE_TIME_MS;

          if (isCacheValid) {
            return cacheEntry.data as Promise<T>;
          }

          apiCache.delete(cacheKey);
        }

        const dataPromise = retrieve(id).catch(
          errorReporter("retrieve", { ...options, params: { id }, bustKey: cacheKey }),
        );

        if (cacheKey && usesCache) {
          apiCache.set(cacheKey, { ts: Date.now(), data: dataPromise });
        }

        return dataPromise;
      },

      list: (params?: Partial<ListType>, options?: OperationOptions) => {
        const cacheKey = JSON.stringify(params || {});
        if (usesCache && !options?.skipCache && apiCache.has(cacheKey)) {
          const cacheEntry = apiCache.get(cacheKey);
          const isCacheValid = Date.now() - (cacheEntry?.ts || 0) < DEFAULT_CACHE_TIME_MS;
          if (isCacheValid) {
            return cacheEntry.data as Promise<T[]>;
          }
          apiCache.delete(cacheKey);
        }

        // We need to convert __in filters to comma-separated strings for the API.
        const modifiedParams = { ...params };
        for (const key in modifiedParams) {
          if (key.endsWith("__in") && Array.isArray(modifiedParams[key])) {
            modifiedParams[key] = modifiedParams[key].join(",") as L[Extract<keyof L, string>];
          }
        }

        const dataPromise = serverApi
          .get<ServerApiResultsResponseType<T[]>>(`${SERVER_API_PATH}/${modelApp}/${modelName}/`, {
            params: modifiedParams,
          })
          .then((res) => {
            let results = res.data.results;

            // A courses list call will contain a different course version per course, so we
            // don't want to update the current course version when we receive this data from
            // this list call.
            if (modelName !== "courses" && results?.length && "course_version" in results[0]) {
              dispatch(
                viewStateActions.lowerCourseVersionTo({
                  results,
                  modelName,
                  params: modifiedParams as Record<string, string>,
                }),
              );
              // We remove the course_version from the results, as it's not relevant after this.
              /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
              results = results.map(({ course_version, ...rest }) => rest) as T[];
            }

            dispatch(slice.actions.updateMany(results));
            return results;
          })
          .catch(
            errorReporter("list", {
              ...options,
              params: params as Record<string, string>,
              bustKey: cacheKey,
            }),
          );

        if (usesCache) {
          apiCache.set(cacheKey, { ts: Date.now(), data: dataPromise });
        }

        return dataPromise;
      },

      create: (data: Partial<Omit<InsertType, "id">>, options?: OperationOptions) => {
        // We don't currently support optimistic updates for create, because some fields
        // are defaulted on the server side that are locally required to render the item.
        // TODO: This is an anti-pattern and we should fix that separately before re-introducing
        // optimistic updates for create.
        return serverApi
          .post<T>(`${SERVER_API_PATH}/${modelApp}/${modelName}/`, data)
          .then(updateAndReturn)
          .catch(errorReporter("create", { ...options }));
      },

      // (Peter): There's currently no use case for an "update" operation, as we always use
      // partial_update, and PUT operations are needlessly dangerous.

      partial_update: (item: Partial<InsertType> & ModelType, options?: OperationOptions) => {
        const id = item.id;
        dispatch(slice.actions.patchOne({ id, ...item }));
        return serverApi
          .patch<T>(`${SERVER_API_PATH}/${modelApp}/${modelName}/${id}/`, { id, ...item })
          .then(updateAndReturn)
          .catch((err: AxiosError | Error) => {
            // Refresh to remove the optimistic update on failure
            retrieve(id).catch(
              errorReporter("partial_update", { params: { id }, skipToast: true }),
            );
            errorReporter("partial_update", options)(err);
          });
      },

      destroy: (id: string, options?: OperationOptions) => {
        dispatch(slice.actions.removeOne({ id }));
        return serverApi
          .delete(`${SERVER_API_PATH}/${modelApp}/${modelName}/${id}/`)
          .then(() => dispatch(slice.actions.removeOne({ id })))
          .catch((err: AxiosError | Error) => {
            // Refresh to remove the optimistic update on failure
            retrieve(id).catch(errorReporter("destroy", { skipToast: true, params: { id } }));
            errorReporter("destroy", options)(err);
          });
      },
    };
  };

  return { slice, addListener, getApi, actions: slice.actions };
};
