import { get } from "lodash";
import type { FC, ReactNode } from "react";

import {
  groupAccessIdFromGroupId,
  studentAccessIdFromUserId,
} from "../../../../worksheets/shared/access-id";
import { DATA_PATH } from "../../../../worksheets/shared/constants";
import { hasEditableBlocks } from "../../../../worksheets/shared/doc-helpers";

import { PAGE_ID_QUERY_PARAM } from "components/constants";
import { NavigationButtons } from "components/materials/NavigationButtons";
import { SubmitLateButton } from "components/materials/page/SubmitLateButton";
import { isGroupPage, isSharedClassPage } from "components/materials/page/groups/helpers";
import { PageTabVariant, isPastSubmissionDueDate } from "components/materials/page/helpers";
import { PageType } from "components/server-types";
import { monthDayAtTime } from "i18n/helpers";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { IconText } from "mds/components/IconText";
import { Tooltip } from "mds/components/Tooltip";
import { useIsLgOrLarger } from "mds/hooks/use-responsive";
import { CircleCheckIcon, PencilEditIcon } from "mds/icons";
import { useWorksheet } from "providers/ShareDBDoc";
import { useAppSelector } from "store/index";
import {
  selectCanAuthorCourse,
  selectCurrentUserId,
  selectPageGroupForReadyUser,
  selectVisibleTopicsForCurrentCourse,
} from "store/selectors";
import { useListSelector } from "store/store-hooks";
import { useFooterSpacingIfMobileBanner } from "utils/hooks/use-footer-spacing-if-mobile-banner";
import { usePageActions } from "utils/page";
import { usePresentation } from "utils/presentation";
import { relativeLink } from "utils/urls";

interface PageFooterBar {
  selectedPage: PageType;
  selectedTabVariant: PageTabVariant;
  pages: PageType[];
  topicId: string;
}

export const PageFooterBar: FC<PageFooterBar> = ({
  selectedPage,
  selectedTabVariant,
  pages,
  topicId,
}) => {
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const { doc } = useWorksheet(selectedPage?.worksheet_id);
  const isStaticContent = !doc || !hasEditableBlocks(doc);
  const user_id = useAppSelector(selectCurrentUserId);
  const group = useAppSelector((s) => selectPageGroupForReadyUser(s, selectedPage.id, user_id));
  const isGrouped = isGroupPage(selectedPage);
  const isSharedPage = isSharedClassPage(selectedPage);
  const isMyDraftPage = selectedTabVariant === "my_draft";
  const canSubmitLate = isPastSubmissionDueDate(selectedPage);
  const visibleTopics = useListSelector(selectVisibleTopicsForCurrentCourse);
  const { isPresenting, hasProjectorView } = usePresentation();
  const { navigateToPage } = usePageActions();
  const { footerMargin } = useFooterSpacingIfMobileBanner();

  let navigationPretext = t("glossary.page");
  if (topicId) {
    const topicIndex = visibleTopics.findIndex((visibleTopic) => visibleTopic.id === topicId) + 1;
    navigationPretext = t("topic_view.page_item_text", { order: topicIndex });
  }

  const onPageChange = (newPageId: string, pageUrl: URL) => {
    const newPage = pages.find((p) => p.id === newPageId);
    navigateToPage(newPage, relativeLink(pageUrl));
  };

  // Note: We are doing this check in react as most of the time we aren't going to want to render
  // this, but we could just as easily put this in css and hide it to display it when needed.
  const navButtons =
    useIsLgOrLarger() || (isPresenting && hasProjectorView) ? null : (
      <NavigationButtons
        className="px-3 py-2.5"
        currentId={selectedPage.id}
        ids={pages.map((p) => p.id)}
        itemText={navigationPretext}
        queryParam={PAGE_ID_QUERY_PARAM}
        onChange={onPageChange}
      />
    );

  if (!doc) {
    return null;
  }

  const shouldShowStudentFooterBar =
    !isStaticContent &&
    selectedPage &&
    !isSharedPage &&
    !canAuthorCourse &&
    !selectedPage.locked_at &&
    isMyDraftPage;

  if (!shouldShowStudentFooterBar) {
    return <div className={`z-[2] shrink-0 grow-0 ${footerMargin} w-full`}>{navButtons}</div>;
  }

  if (canSubmitLate) {
    return (
      <div
        className={`z-[2] ${footerMargin} w-full shrink-0 grow-0 border-t border-black-tint-70 bg-white`}
      >
        <div className="flex w-full items-center justify-end p-3">
          <SubmitLateButton page={selectedPage} />
        </div>

        {navButtons}
      </div>
    );
  }

  const accessId =
    isGrouped && group ? groupAccessIdFromGroupId(group.id) : studentAccessIdFromUserId(user_id);
  const path = [DATA_PATH, accessId, "done"];
  const isDone = get(doc.data, path, false) as boolean;

  return (
    <div
      className={`z-[2] ${footerMargin} w-full shrink-0 grow-0 border-t border-black-tint-70 bg-white`}
    >
      <div className="flex w-full items-center justify-end p-3">
        {isDone ? (
          <EditResponseButton
            page={selectedPage}
            onClick={() =>
              doc.submitOp({
                p: path,
                oi: false,
                od: true,
              })
            }
          />
        ) : (
          <MarkDoneButton
            disabled={isGrouped && !group}
            disabledTooltip={t("topic_view.group_mark_done_missing_group")}
            isGrouped={isGrouped}
            onClick={() =>
              doc.submitOp({
                p: path,
                oi: true,
                od: false,
              })
            }
          />
        )}
      </div>

      {navButtons}
    </div>
  );
};

export interface MarkDoneButtonProps {
  onClick: () => void;
  isGrouped: boolean;
  disabled?: boolean;
  disabledTooltip?: ReactNode;
}

export const MarkDoneButton: FC<MarkDoneButtonProps> = ({
  onClick,
  isGrouped,
  disabled,
  disabledTooltip,
}) => {
  const key = isGrouped ? "topic_view.group_mark_done" : "topic_view.individual_mark_done";
  const button = (
    <Button disabled={disabled} kind="primary" size="s" mobileFullWidth onClick={onClick}>
      <IconText iconStart={<CircleCheckIcon />} text={t(key)} />
    </Button>
  );
  if (disabled && disabledTooltip) {
    return <Tooltip element={button}>{disabledTooltip}</Tooltip>;
  }
  return button;
};

export interface EditResponseButtonProps {
  onClick: () => void;
  page: PageType;
}

export const EditResponseButton: FC<EditResponseButtonProps> = ({ onClick, page }) => {
  const { month, day, time } = monthDayAtTime(page.due_at);

  return (
    <Button
      kind="secondary"
      size="s"
      title={page.due_at && t("topic_view.response_will_be_submitted", { time, month, day })}
      mobileFullWidth
      onClick={onClick}
    >
      <IconText iconStart={<PencilEditIcon />} text={t("topic_view.edit_response")} />
    </Button>
  );
};
