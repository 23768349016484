import React, { memo } from "react";
import { useDrag, useDrop } from "react-dnd";

// This is a type used internally to keep track of the item being dragged.
export interface DraggableItemType {
  id: string;
  order: number;
}

/**
 *  Properties for supporting dropping an item on this component's children.
 *  `onDraggedItemHover` The function to call when the item being dragged is moved over this drop zone to update
 *    the local state (typically intended to mimic the final state if dropped here).
 * `dropIndex` The index the item being dragged will be set to if it moves over this drop zone.
 * `dropContainerId` The parent container id the item being dragged will be set to if it moves over this drop zone.
 */
interface DropProps {
  onDraggedItemHover: (
    item: DraggableItemType,
    toIndex: number,
    dropContainerId?: string | number,
  ) => void;
  dropIndex: number;
  dropContainerId?: string | number;
}

/**
 * Properties for supporting dragging an item from this component's children.
 * `itemId` The id of the item being dragged.
 * `onDropItem` The function to call when the item being dragged is dropped on a drop zone.
 */
interface DragProps {
  item: DraggableItemType;
  onDropItem?: (
    droppedItem: DraggableItemType,
    dropIndex: number,
    sourceContainerId?: string | number,
  ) => void;
}

// TODO: Add enforced typescript for supporting either Drag or Drop only,
//  note this requires adding some more guards in the code.
export interface DragAndDropProps extends Partial<DropProps>, Partial<DragProps> {
  dragItemKind: "topic" | "activity" | "page" | "term" | "course_code" | "course" | "course_user";
  draggable?: boolean;
  droppable?: boolean;
  children: React.ReactNode;
  className?: string;
}

/**
 * For supporting Drag or Drop functionality for its children. To use this component
 * for draggable or droppable, the `draggable` or `droppable` props must be set to true
 * and the associated DragProps or DropProps must be set, respectively.
 * The dragging and dropping will only be triggered for the `dragItemKind` specified.
 * Use multiple DragAndDrop components for supporting multiple dragItemKinds.
 */
export const DragAndDrop = memo(function DragAndDrop({
  children,
  dragItemKind,
  draggable = false,
  droppable = false,
  className,
  dropIndex,
  item,
  onDraggedItemHover,
  onDropItem,
  dropContainerId,
}: DragAndDropProps) {
  // We cannot make a hook conditionally created in React, but the useDrag and useDrop hooks code will only be used if
  // the component is `draggable` or `droppable` and has implemented the associated props.
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: dragItemKind,
      item,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: () => onDropItem && draggable,
      end: (droppedItem) => {
        // TODO: Reconsider as part of UX later:
        // If we want to revert on a failed drag (drops outside of a drop zone), we could later do something like this:
        // (requires adding `monitor` to the end function)
        // const didDrop = monitor.didDrop();
        // if (!didDrop) {
        //   // This is reverting the local dragging that took place given the user didn't drop the item on a drop zone,
        //   // effectively canceling the action.
        //   onDragItem(droppedItemId, originalItemIndex, originalParentContainerId);
        // } else {
        //    onDropItem(droppedItemId);
        // }

        if (onDropItem) {
          onDropItem(droppedItem, dropIndex, dropContainerId);
        }
      },
    }),
    [draggable, item, onDraggedItemHover, onDropItem, dragItemKind],
  );

  // TODO: Once a page is dragged across activities, it creates a new PageRow in the new activity
  // that doesn't have the opacity applied. I believe because React is reusing the same component instance
  // within the activity, but creates a new one when we populate a row in a new activity. Fix later - will affect
  // most cases of dragging to a different parent container.
  // See docs: https://react-dnd.github.io/react-dnd/docs/api/use-drag for tips.
  const [, drop] = useDrop(
    () => ({
      accept: dragItemKind,
      canDrop: () => onDraggedItemHover && droppable,
      hover(draggedItem: DraggableItemType) {
        if (onDraggedItemHover && (!item || draggedItem.id !== item.id)) {
          // Note that if the itemId is not specified (e.g. a parent container drop zone), this
          // function will be called repeatedly in quick succession while hovering.
          // TODO handle this better later - can use a ref to track, though
          // knowing when the dragging leaves the drop zone to reset the ref may
          // or may not be feasible directly with the react-dnd library.
          onDraggedItemHover(draggedItem, dropIndex, dropContainerId);
        }
      },
    }),
    [onDraggedItemHover, item, dragItemKind, dropIndex, dropContainerId],
  );

  const opacity = isDragging ? 0 : 1;
  return (
    <div
      className={className}
      ref={(node) => {
        drag(drop(node));
      }}
      style={{ opacity }}
    >
      {children}
    </div>
  );
});
