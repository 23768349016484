import "./CourseAssessmentView.scss";

import { useEffect } from "react";

import { CSVExportButton } from "./CSVExportButton";
import { PageLink } from "./PageLink";
import { PublishStatus } from "./PublishStatus";
import { useFetchAssessmentBaseData, useFetchAssessmentData } from "./assessment-data";
import { average } from "./utils";

import { AssessedCount } from "components/materials/page/assessment/AssessedCount";
import { tableDateFormat } from "components/materials/page/helpers";
import { displayOutcomeName } from "components/outcomes/helpers";
import { PageGroupUserType } from "components/server-types";
import { t } from "i18n/i18n";
import { Tooltip } from "mds/components/Tooltip";
import { ScoreIcon } from "mds/icons";
import { storeApi, useAppSelector } from "store/index";
import {
  selectCurrentCourseId,
  selectFullCourseOutcomes,
  selectPageGroupUsers,
  selectPageOutcomes,
  selectPagesWithAssessments,
  selectUsersInCurrentCourse,
} from "store/selectors";
import { getUsername } from "utils/user-utils";

export const CourseAssessmentView = () => {
  const courseId = useAppSelector(selectCurrentCourseId);
  const courseUsers = useAppSelector(selectUsersInCurrentCourse);
  const courseStudents = courseUsers.filter((u) => u.role === "student");
  const courseOutcomes = useAppSelector((s) => selectFullCourseOutcomes(s, courseId));
  // TODO: Should we bundle these in the selectors somehow?
  const pageOutcomes = useAppSelector(selectPageOutcomes);
  const pageGroupUsers = useAppSelector(selectPageGroupUsers);

  useFetchAssessmentBaseData();
  useFetchAssessmentData();

  useEffect(() => {
    storeApi.page_outcomes.list({ page__course_id: courseId });
    storeApi.page_group_users.list({ course_user__course_id: courseId });
  }, [courseId]);

  const pagesWithAssessments = useAppSelector(selectPagesWithAssessments);
  const allAssessments = pagesWithAssessments.map((p) => p.assessments).flat();
  const allScoredSubmissions = allAssessments.map((a) => a.submission);

  return (
    <div className="assessment-dashboard">
      <div className="mb-8">
        <div className="flex w-full items-center justify-between">
          <h2 className="h3 m-0 text-black-tint-40">{t("assessment_dashboard.all.title")}</h2>
          <div className="flex items-center gap-2">
            <CSVExportButton data={null} />
          </div>
        </div>
        <div className="assessment-dashboard-table">
          <table className="mds-table">
            {/* Header like: Page/Status/Assessed/Avg. Points/Outcomes/Last Submitted */}
            <thead>
              <tr>
                <th className="w-6/12">{t("assessment_dashboard.all.page_header_title")}</th>
                <th className="w-16">
                  <Tooltip element={t("assessment_dashboard.all.status_header_title")}>
                    {t("assessment_dashboard.all.status_header_tooltip")}
                  </Tooltip>
                </th>
                <th className="w-16">
                  <Tooltip element={t("assessment_dashboard.all.assessed_header_title")}>
                    {t("assessment_dashboard.all.assessed_header_tooltip")}
                  </Tooltip>
                </th>
                <th className="w-16">
                  <Tooltip element={t("assessment_dashboard.all.avg_points_header_title")}>
                    {t("assessment_dashboard.all.avg_points_header_tooltip")}
                  </Tooltip>
                </th>
                <th className="w-16">
                  <Tooltip element={t("assessment_dashboard.all.outcomes_header_title")}>
                    {t("assessment_dashboard.all.outcomes_header_tooltip")}
                  </Tooltip>
                </th>
                <th className="w-16">
                  <Tooltip element={t("assessment_dashboard.all.last_submitted_header_title")}>
                    {t("assessment_dashboard.all.last_submitted_header_tooltip")}
                  </Tooltip>
                </th>
              </tr>
            </thead>
            <tbody className="body-xs">
              {pagesWithAssessments
                .filter(
                  (page) => page.max_points || pageOutcomes.some((po) => po.page_id === page.id),
                )
                .map((page) => {
                  const pointList = page.assessments.map((a) => a.points);
                  const submissions = page.assessments.map((a) => a.submission);
                  const submissionsByDate = submissions
                    .slice(0)
                    .sort(
                      (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
                    );
                  const latestSubmissionDate =
                    submissionsByDate.length > 0 &&
                    tableDateFormat(submissionsByDate[0].created_at);

                  const pageSpecificOutcomes = pageOutcomes.filter((po) => po.page_id === page.id);

                  return (
                    <tr key={page.id}>
                      <td>
                        <PageLink page={page} />
                      </td>
                      <td>
                        <PublishStatus
                          status={page.assessments_published_at ? "published" : "draft"}
                        />
                      </td>
                      <td>
                        <AssessedCount page={page} />
                      </td>
                      <td>{pointList.length ? `${average(pointList)}/${page.max_points}` : "-"}</td>
                      <td>
                        <Tooltip element={pageSpecificOutcomes.length}>
                          {pageSpecificOutcomes
                            .map((po) =>
                              courseOutcomes.find((co) => co.id === po.course_outcome_id),
                            )
                            .filter(Boolean)
                            .map((co) => displayOutcomeName(co.outcome))
                            .join(", ")}
                        </Tooltip>
                      </td>
                      <td>{latestSubmissionDate || "-"}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="assessment-dashboard-section">
        <div className="flex items-center justify-between">
          <h2 className="h3 m-0 text-black-tint-40">
            {t("assessment_dashboard.student_points_per_page_title")}
          </h2>
          <CSVExportButton data={null} />
        </div>
        <div className="assessment-dashboard-table">
          <table className="mds-table">
            {/* Header like: Page/Avg. Points/User A/User B/User C... */}
            {/* One row per page, showing each student's score on that page */}
            <thead>
              <tr>
                <th className="w-4/12">{t("assessment_dashboard.all.page_header_title")}</th>
                <th className="w-16">
                  <Tooltip element={t("assessment_dashboard.all.avg_points_header_title")}>
                    {t("assessment_dashboard.all.avg_points_header_tooltip")}
                  </Tooltip>
                </th>
                {courseStudents.map((student) => (
                  <th className="w-8 content-end" key={student.user_id}>
                    <div className="vertical" title={getUsername(student)}>
                      {getUsername(student)}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="body-xs">
              {pagesWithAssessments
                // Here we specifically only want to show finalized assessments on pages with points
                .filter((page) => page.assessments_published_at && page.max_points)
                .map((page) => {
                  const pointList = page.assessments.map((a) => a.points) || [];
                  return (
                    <tr key={page.id}>
                      <td>
                        <PageLink page={page} />
                      </td>
                      <td>{pointList.length ? `${average(pointList)}/${page.max_points}` : "-"}</td>
                      {courseStudents.map((student) => {
                        const assessmentsForUser = page.assessments.filter(
                          (a) =>
                            a.submission.course_user_id === student.course_user_id ||
                            pageGroupUsers.find(
                              (pgu: PageGroupUserType) =>
                                pgu.page_group_id === a.submission.page_group_id &&
                                pgu.course_user_id === student.course_user_id,
                            ),
                        );
                        // Technically, this should always only be a single score,
                        // but we average it just in case
                        const pointsForUser = assessmentsForUser.map((a) => a.points);

                        return (
                          <td key={student.user_id}>
                            {pointsForUser.length ? average(pointsForUser) : "-"}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="assessment-dashboard-section">
        <div className="flex w-full items-center justify-between">
          <h2 className="h3 m-0 flex items-center gap-2 text-black-tint-40">
            <span className="pb-1">
              {t("assessment_dashboard.student_score_per_outcome_title")}
            </span>
            <Tooltip contentClassName="body-xs">
              {t("assessment_dashboard.student_score_per_outcome_tooltip")}
            </Tooltip>
          </h2>
          <CSVExportButton data={null} />
        </div>
        <div className="assessment-dashboard-table">
          <table className="mds-table">
            {/* Header like: <blank>/Outcome A/Outcome B/Outcome C */}
            {/* One row per student, showing each student's aggregate outcome scores */}
            <thead>
              <tr>
                <th className="w-6/12">
                  {t("assessment_dashboard.all.student_name_header_title")}
                </th>
                {courseOutcomes.map((courseOutcome) => (
                  <th className="w-8 content-end" key={courseOutcome.id}>
                    <div className="vertical extended">
                      {displayOutcomeName(courseOutcome.outcome)}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="body-xs">
              {courseStudents.map((student) => {
                // TODO: Also only show finalized assessments .filter((page) => page.assessments_published_at)
                return (
                  <tr key={student.user_id}>
                    <td>{getUsername(student)}</td>
                    {courseOutcomes.map((courseOutcome) => {
                      const userGroupIds = pageGroupUsers
                        .filter((pgu) => pgu.course_user_id === student.course_user_id)
                        .map((pgu) => pgu.page_group_id);

                      const userSubmissions = allScoredSubmissions.filter(
                        (s) =>
                          s.course_user_id === student.course_user_id ||
                          userGroupIds.includes(s.page_group_id),
                      );
                      const userAssessments = allAssessments.filter((a) =>
                        userSubmissions.find((s) => s.id === a.submission_id),
                      );
                      const userOutcomeAssessmentsForThisOutcome = userAssessments
                        .map((a) => a.outcome_assessments || [])
                        .flat()
                        .filter(
                          (oa) =>
                            pageOutcomes.find((po) => po.id === oa.page_outcome_id)
                              ?.course_outcome_id === courseOutcome.id,
                        );
                      const outcomeScore = userOutcomeAssessmentsForThisOutcome.length
                        ? average(userOutcomeAssessmentsForThisOutcome.map((oa) => oa.score))
                        : NaN;
                      return (
                        <td key={courseOutcome.id}>
                          <ScoreIcon value={outcomeScore} />
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
