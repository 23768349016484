import { ChangeEvent, type FC, useEffect, useState } from "react";

import { precisionRoundStr } from "components/materials/page/helpers";
import { CancelSaveMenu } from "components/menus/CancelSaveMenu";
import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button, ButtonProps } from "mds/components/Button";
import { TextField } from "mds/components/TextField";
import { AssessPointsMarkerIcon, ChevronDownIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import { selectCanAuthorCourse } from "store/selectors";

interface PointsConfigProps {
  page: PageType;
  studentPoints?: number;
  disabled?: boolean;
  readOnly?: boolean;
  size?: ButtonProps["size"];
  onSave: (points: number) => void;
  validatePoints?: (points: number) => boolean;
  saveTextKey?: "update" | "save";
  title?: string;
  decimal?: number;
}

const defaultValidationFunction = (points: number) => !isNaN(points) && points >= 0;

export const PointsConfig: FC<PointsConfigProps> = ({
  page,
  onSave,
  decimal = 0,
  disabled = false,
  readOnly = false,
  size = "s",
  title = "",
  validatePoints = defaultValidationFunction,
  studentPoints,
  saveTextKey,
}) => {
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const [currentPoints, setCurrentPoints] = useState(
    studentPoints === undefined ? page.max_points : studentPoints,
  );
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const onPointsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const newPoints = decimal ? precisionRoundStr(input, decimal) : parseInt(input, 10);
    if (validatePoints(newPoints)) {
      setIsSaveDisabled(false);
      setCurrentPoints(newPoints);
    } else {
      setIsSaveDisabled(true);
    }
  };

  useEffect(() => {
    setCurrentPoints(studentPoints === undefined ? page.max_points : studentPoints);
  }, [studentPoints, page.max_points]);

  return (
    <CancelSaveMenu
      button={
        <Button
          className="gap-1"
          disabled={disabled || readOnly}
          kind="secondary"
          readOnly={readOnly}
          size={size}
          title={title}
        >
          <AssessPointsMarkerIcon />
          {studentPoints === undefined
            ? t("rubric_card.assessment_points", { count: page.max_points })
            : t("rubric_card.current_assessment_points", {
                max: page.max_points,
                current: studentPoints,
              })}
          {canAuthorCourse && !readOnly && <ChevronDownIcon />}
        </Button>
      }
      isSaveDisabled={isSaveDisabled}
      saveTextKey={saveTextKey}
      title={t("glossary.points")}
      onCancel={() =>
        setCurrentPoints(studentPoints === undefined ? page.max_points : studentPoints)
      }
      onSave={() => onSave(currentPoints || 0)}
    >
      <div className="flex w-full flex-col">
        <TextField
          className="w-full"
          min="0"
          placeholder={t("rubric_card.assessment_points", { count: 0 })}
          startIcon={<AssessPointsMarkerIcon />}
          type="number"
          value={currentPoints || 0}
          isEditing
          onChange={(e) => onPointsChange(e)}
        />

        {isSaveDisabled && (
          <div className="body-xxs flex w-full justify-end text-red">
            {t("error.max_points_less_than_zero")}
          </div>
        )}
      </div>
    </CancelSaveMenu>
  );
};
