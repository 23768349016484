import { ActionReducerMapBuilder, createAction } from "@reduxjs/toolkit";

/**
 * These are helpers to define an action and reducer that replaces all the data in a slice,
 * which can be used across multiple slice models if injected into "extraReducers" in a slice.
 */
export const replaceAllAction = createAction<Record<string, unknown>>("replaceAll");
export const resetReduxContent = () => replaceAllAction(null);

export const createReplaceAllReducer =
  <T>(modelName: string, initialStateFunc: () => T, onReset?: () => void) =>
  (builder: ActionReducerMapBuilder<T>) => {
    builder.addCase(replaceAllAction, (state, action) => {
      // We allow easily re-setting all slices to their initial state by explicitly passing
      // null as the action payload
      if (!action.payload && initialStateFunc) {
        if (onReset) {
          onReset();
        }
        return initialStateFunc();
      }
      if (!action.payload) {
        return state;
      }
      return action.payload[modelName] as T;
    });
  };
