import clsx from "clsx";
import { useEffect, useMemo } from "react";
import { Outlet, useParams } from "react-router";
import { useBoolean } from "usehooks-ts";

import { switchOrg } from "api/api-server";
import { getCourseUserData } from "api/api-worksheets";
import { ErrorScreen, LoadingScreen } from "components/LoadingScreen";
import { MobileRoutingOverlay } from "components/MobileRoutingOverlay";
import { MainHeader } from "components/header/MainHeader";
import { MobileMainHeader } from "components/header/MobileMainHeader";
import { CourseSidebar } from "components/materials/course/CourseSidebar";
import { isProjectorView } from "components/materials/presentation/projector/projector-messaging";
import { CourseType } from "components/server-types";
import { t } from "i18n/i18n";
import { useIsLgOrLarger } from "mds/hooks/use-responsive";
import { BypassReleaseWarningContext } from "providers/BypassReleaseWarningProvider";
import { storeApi, useAppDispatch, useAppSelector } from "store/index";
import {
  selectCanAuthorCourse,
  selectIsSidebarOpen,
  selectOrgIdForCourseId,
} from "store/selectors";
import { analyticsStateActions } from "store/slices/analytics";
import { usePromiseEffect } from "store/store-hooks";
import { getOrgIdFromCookie, setLastCourseIdCookie } from "utils/auth";
import { rollbarAndLogError } from "utils/logger";
import { CONTENT_PATH_REGEX, RESOURCE_PATH_REGEX } from "utils/urls";
import { ErrorBoundary } from "worksheets/views/ErrorBoundary";

export default function Layout() {
  const isLargeScreen = useIsLgOrLarger();
  const isSidebarOpen = useAppSelector(selectIsSidebarOpen);

  // Ensure this component refreshes when we navigate between the course routes
  const { courseId } = useParams();
  const orgIdForCourseId = useAppSelector((s) => selectOrgIdForCourseId(s, courseId));
  const orgIdFromCookie = getOrgIdFromCookie();
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const dispatch = useAppDispatch();

  const { data: course, isLoading } = usePromiseEffect<CourseType | void>(() => {
    // If we detect that we need to switch org, we skip the course retrieval
    if (orgIdForCourseId && orgIdForCourseId !== orgIdFromCookie) {
      return Promise.resolve(undefined);
    }
    return storeApi.courses.retrieve(courseId);
  }, [courseId]);

  const courseFound = !!course;
  // If we try to load a course URL that doesn't exist, but it _does_ exist in another org
  // that the user has access to, we switch org and reload the page.
  useEffect(() => {
    if (orgIdForCourseId && orgIdForCourseId !== orgIdFromCookie) {
      switchOrg(orgIdForCourseId)
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          // We don't show the error the user, because it's too technical. They will just see a 404.
          rollbarAndLogError("Failed to auto-switch org", error);
        });
    }
  }, [orgIdFromCookie, orgIdForCourseId]);

  // Whenever we navigate to a course, we want to remember which course it was, so that accessing
  // the app root will preferably load the same course again.
  useEffect(() => {
    setLastCourseIdCookie(courseId);
  }, [courseId]);

  useEffect(() => {
    // Once we have the course and know it exists, we can start loading all related data
    if (courseFound && courseId) {
      storeApi.courses.list();
      storeApi.course_users.list({ course_id: courseId });

      storeApi.course_codes.list();
      storeApi.orgs.list();
      storeApi.class_sessions.list({ course_id: courseId });

      storeApi.topics.list({ course_id: courseId });
      storeApi.pages.list({ course_id: courseId });
      storeApi.outcomes.list();
      storeApi.course_outcomes.list({ course_id: courseId });
    }
  }, [courseFound, courseId]);

  useEffect(() => {
    const getCourseActivityData = async () => {
      const activityData = await getCourseUserData(courseId);
      dispatch(analyticsStateActions.updateAnalytics(activityData));
    };

    if (canAuthorCourse) {
      getCourseActivityData();
    }
  }, [courseId, canAuthorCourse, dispatch]);

  const { value: showWarning, setTrue: resetWarning, setFalse: bypassWarning } = useBoolean(true);
  const bypassReleaseWarningValue = useMemo(
    () => ({ showWarning, resetWarning, bypassWarning }),
    [showWarning, resetWarning, bypassWarning],
  );

  // If we show the layout before the dispatch goes through we can get some
  // interesting race conditions.
  if (isLoading) {
    return (
      <div className="h-screen w-screen">
        <LoadingScreen />
      </div>
    );
  }

  // If we're finished loading the courses, but we don't find the routed course ID in the
  // list, it means we don't have access, and we should redirect to the home page.
  // TODO: Find a generally better implementation for 404s. Could be a .catch on
  // the storeApi .retrieve call that automatically redirects to /404, and then use
  // .retrieve calls for critical data in the layout.
  if (!isLoading && !course) {
    return (
      <div className="h-screen w-screen">
        <ErrorScreen className="h-full" text={t("course.404")} />
      </div>
    );
  }

  const isViewingContentPath = CONTENT_PATH_REGEX.test(window.location.pathname);
  const isViewingPagePath = RESOURCE_PATH_REGEX.test(window.location.pathname);
  const isProjectorPath = isProjectorView();
  const mainLayout = !isViewingContentPath && !isViewingPagePath && !isProjectorPath;

  const hasMainSidebar = isLargeScreen && mainLayout;

  return (
    <BypassReleaseWarningContext.Provider value={bypassReleaseWarningValue}>
      <div className="flex h-full flex-col">
        {!isProjectorPath && (isLargeScreen ? <MainHeader /> : <MobileMainHeader />)}
        <div
          className={clsx(
            "flex h-1 grow",
            isLargeScreen
              ? {
                  "sidebar-open": isSidebarOpen,
                  "sidebar-closed": !isSidebarOpen,
                }
              : {},
          )}
        >
          {hasMainSidebar && <CourseSidebar />}

          <div className="relative h-full max-w-full grow">
            <ErrorBoundary>
              <MobileRoutingOverlay />
              <Outlet />
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </BypassReleaseWarningContext.Provider>
  );
}
