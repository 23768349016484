import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const CircleErrorRedIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      clipRule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#DF2F26"
      fillRule="evenodd"
    />
    <path
      d="M9.70101 8.00061L12.712 4.9895C12.8296 4.87142 12.8296 4.67765 12.712 4.5601L11.4417 3.28977C11.3242 3.17223 11.131 3.17223 11.0129 3.28977L8.00133 6.30141L4.9879 3.28817C4.86983 3.17063 4.67659 3.17063 4.55905 3.28817L3.28875 4.55904C3.17068 4.67605 3.17068 4.86929 3.28875 4.98843L6.30191 8.00115L3.28955 11.0131C3.17254 11.1301 3.17254 11.3238 3.28955 11.4419L4.55985 12.7123C4.67792 12.8293 4.87223 12.8293 4.98923 12.7123L8.00079 9.70088L11.0108 12.7109C11.1283 12.8285 11.3226 12.8285 11.4401 12.7109L12.711 11.4401C12.828 11.323 12.828 11.1293 12.711 11.0107L9.70101 8.00061Z"
      fill="#FCEAE9"
    />
  </svg>
);
