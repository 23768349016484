import { isEqual } from "lodash";
import { useEffect, useRef } from "react";

export const useOnChanged = <T, K>(
  value: T,
  onChanged: (newValue: T, oldValue: T) => void,
  skipFirst?: boolean,
  triggerSkipFirstKey?: K,
) => {
  const previousValue = useRef(value);
  const isFirstRun = useRef<boolean>(skipFirst);
  const previousTriggerSkipFirstKey = useRef(triggerSkipFirstKey);

  useEffect(() => {
    if (triggerSkipFirstKey !== previousTriggerSkipFirstKey.current) {
      isFirstRun.current = skipFirst;
      previousTriggerSkipFirstKey.current = triggerSkipFirstKey;
    } else if (isFirstRun.current && !isEqual(previousValue.current, value)) {
      previousValue.current = value;
      isFirstRun.current = false;
    } else if (!isEqual(previousValue.current, value)) {
      onChanged(value, previousValue.current);
      previousValue.current = value;
    }
  }, [onChanged, value, skipFirst, triggerSkipFirstKey]);
};
