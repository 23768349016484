import { type FC, useState } from "react";
import { type NoticeProps, components } from "react-select";

import { CancelSaveMenu } from "components/menus/CancelSaveMenu";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { TextAddButton } from "mds/components/TextAddButton";
import { OptionType, OptionsType, TextListDropdown } from "mds/components/TextListDropdown";
import { AddPlusIcon, TagIcon } from "mds/icons";

interface AddOutcomeProps {
  hasReachedLimit?: boolean;
  options: OptionsType;
  limit?: number;
  iconOnly?: boolean;
  onSave: (ids: string[]) => void;
  isDisabled?: boolean;
  title?: string;
}

type NoOptionsMessageType = (hasReachedLimit: boolean) => FC<NoticeProps>;
const createNoOptionsMessage: NoOptionsMessageType = (hasReachedLimit) => {
  const NoOptionsMessageComponent: FC<NoticeProps> = (props) => (
    <components.NoOptionsMessage {...props}>
      <div className="text-black-tint-70">
        {t("add_outcome.no_options", {
          context: hasReachedLimit ? "full" : "empty",
        })}
      </div>
    </components.NoOptionsMessage>
  );

  NoOptionsMessageComponent.displayName = "NoOptionsMessage";

  return NoOptionsMessageComponent;
};

export const AddOutcome: FC<AddOutcomeProps> = ({
  hasReachedLimit,
  limit,
  onSave,
  options,
  iconOnly,
  isDisabled,
  title,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<OptionsType>([]);

  return (
    <CancelSaveMenu
      button={
        iconOnly ? (
          <Button disabled={isDisabled} kind="secondary" size="s" title={title} iconOnly>
            <AddPlusIcon />
          </Button>
        ) : (
          <TextAddButton isDisabled={isDisabled} reference="outcome" title={title} />
        )
      }
      title={t("add_outcome.title")}
      onCancel={() => setSelectedOptions([])}
      onSave={() => {
        setSelectedOptions([]);
        onSave(selectedOptions.map((option) => option.value as string));
      }}
    >
      <TextListDropdown
        className="body-s w-full text-black-tint-20"
        components={{
          NoOptionsMessage: createNoOptionsMessage(hasReachedLimit),
        }}
        controlIcon={<TagIcon />}
        isOptionDisabled={(option: OptionType) =>
          limit === undefined
            ? false
            : selectedOptions.length >= limit &&
              !selectedOptions.map((o) => o.label).includes(option.label)
        }
        options={options}
        placeholder={t("add_outcome.search_placeholder")}
        value={selectedOptions}
        useCheckboxOption
        onChange={(selected: OptionsType) => setSelectedOptions(selected)}
      />
    </CancelSaveMenu>
  );
};
