import clsx from "clsx";
import { type FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { SUBMISSION_ID_QUERY_PARAM } from "components/constants";
import { PageOutcomeRubric } from "components/materials/page/outcomes/PageOutcomeRubric";
import { t } from "i18n/i18n";
import { storeApi, useAppSelector } from "store/index";
import {
  selectAssessmentBySubmissionId,
  selectAssessmentsByPageId,
  selectCanAuthorCourse,
  selectFullSubmissionById,
  selectNestedPageOutcomes,
  selectOutcomeAssessmentByAssessmentId,
  selectPageById,
} from "store/selectors";

interface PageOutcomeRubricPanelProps {
  pageId: string;
  className?: string;
}

export const PageOutcomeRubricPanel: FC<PageOutcomeRubricPanelProps> = ({ pageId, className }) => {
  const [searchParams] = useSearchParams();
  const page = useAppSelector((s) => selectPageById(s, pageId));
  const pageOutcomes = useAppSelector((s) => selectNestedPageOutcomes(s, pageId));
  const submissionId = searchParams.get(SUBMISSION_ID_QUERY_PARAM);
  const submission = useAppSelector((s) => selectFullSubmissionById(s, submissionId));
  const assessment =
    useAppSelector((s) => selectAssessmentBySubmissionId(s, submission?.id)) || null;
  const outcomeAssessments =
    useAppSelector((s) => selectOutcomeAssessmentByAssessmentId(s, assessment?.id)) || [];
  const assessments = useAppSelector((s) => selectAssessmentsByPageId(s, pageId));
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);

  useEffect(() => {
    if (!page) {
      storeApi.pages.retrieve(pageId);
    }
    if (page && !pageOutcomes.length) {
      storeApi.page_outcomes.list({ page_id: pageId });
    }
  }, [page, pageId, pageOutcomes]);

  return (
    <div className={clsx("flex h-full w-full flex-wrap gap-3", className)}>
      {!pageOutcomes.length && (
        <div className="body-s w-full py-2 text-black-tint-40">
          {t("outcomes.no_page_outcomes")}
        </div>
      )}

      {pageOutcomes.map((pageOutcome) => (
        <PageOutcomeRubric
          canModify={canAuthorCourse && assessments.length === 0}
          className="flex w-full flex-col overflow-y-auto"
          key={pageOutcome.id}
          outcomeAssessment={
            page.assessments_published_at &&
            outcomeAssessments.find((oa) => oa.page_outcome_id === pageOutcome.id)
          }
          pageOutcome={pageOutcome}
        />
      ))}
      {/*
      If the screen is taller than all the outcome rubrics, the flexbox
      tries to position them in the middle and space them out to occupy
      all the space. This extra <div> makes sure that doesn't happen,
      while still preserving the ability to vertically scroll if the screen
      is shorter than all the outcome rubrics.
      */}
      <div className="grow" />
    </div>
  );
};
