import React from "react";
const __pages_import_0__ = React.lazy(() => import("/src/routes/[...404].tsx"));
const __pages_import_1__ = React.lazy(() => import("/src/routes/forgot-password.tsx"));
import __pages_import_2__ from "/src/routes/index.tsx";
import __pages_import_3__ from "/src/routes/index/course.tsx";
import __pages_import_4__ from "/src/routes/index/course/[courseId].tsx";
import __pages_import_5__ from "/src/routes/index/course/[courseId]/index.tsx";
import __pages_import_6__ from "/src/routes/index/course/[courseId]/projector.tsx";
import __pages_import_7__ from "/src/routes/index/course/[courseId]/course-page/index.tsx";
import __pages_import_8__ from "/src/routes/index/course/[courseId]/topic/[topicId].tsx";
import __pages_import_9__ from "/src/routes/index/course/link.tsx";
import __pages_import_10__ from "/src/routes/index/mds.tsx";
import __pages_import_11__ from "/src/routes/index/org.tsx";
import __pages_import_12__ from "/src/routes/index/org/index.tsx";
import __pages_import_13__ from "/src/routes/index/redirect.tsx";
const __pages_import_14__ = React.lazy(() => import("/src/routes/privacy-policy-en.tsx"));
const __pages_import_15__ = React.lazy(() => import("/src/routes/reset-password.tsx"));
const __pages_import_16__ = React.lazy(() => import("/src/routes/terms-of-service-en.tsx"));

const routes = [{"caseSensitive":false,"path":"*","element":React.createElement(__pages_import_0__)},{"caseSensitive":false,"path":"forgot-password","element":React.createElement(__pages_import_1__)},{"caseSensitive":false,"path":"/","element":React.createElement(__pages_import_2__),"children":[{"caseSensitive":false,"path":"course","element":React.createElement(__pages_import_3__),"children":[{"caseSensitive":false,"path":":courseId","element":React.createElement(__pages_import_4__),"children":[{"caseSensitive":false,"path":"","element":React.createElement(__pages_import_5__)},{"caseSensitive":false,"path":"projector","element":React.createElement(__pages_import_6__)},{"caseSensitive":false,"path":"course-page","children":[{"caseSensitive":false,"path":"","element":React.createElement(__pages_import_7__)}]},{"caseSensitive":false,"path":"topic","children":[{"caseSensitive":false,"path":":topicId","element":React.createElement(__pages_import_8__)}]}]},{"caseSensitive":false,"path":"link","element":React.createElement(__pages_import_9__)}]},{"caseSensitive":false,"path":"mds","element":React.createElement(__pages_import_10__)},{"caseSensitive":false,"path":"org","element":React.createElement(__pages_import_11__),"children":[{"caseSensitive":false,"path":"","element":React.createElement(__pages_import_12__)}]},{"caseSensitive":false,"path":"redirect","element":React.createElement(__pages_import_13__)}]},{"caseSensitive":false,"path":"privacy-policy-en","element":React.createElement(__pages_import_14__)},{"caseSensitive":false,"path":"reset-password","element":React.createElement(__pages_import_15__)},{"caseSensitive":false,"path":"terms-of-service-en","element":React.createElement(__pages_import_16__)}];

export default routes;