import clsx from "clsx";
import type { FC } from "react";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";

import "./CancelSaveWidget.scss";

type CancelSaveWidgetProps = {
  className?: string;
  onClickCancel?: () => void;
  onClickSave?: () => void;
  size?: "xs" | "s" | "m" | "l";
  isSaveDisabled?: boolean;
  saveTextKey?: "save" | "copy" | "move" | "import_course" | "update";
};

export const CancelSaveWidget: FC<CancelSaveWidgetProps> = ({
  onClickCancel,
  onClickSave,
  className,
  isSaveDisabled = false,
  size = "s",
  saveTextKey = "save",
}) => {
  return (
    <div className={clsx("cancel-save-widget flex self-center", className)}>
      {onClickCancel && (
        <Button
          className="cancel"
          kind="secondary"
          size={size}
          title={t("common.cancel")}
          onClick={onClickCancel}
        >
          {t("common.cancel")}
        </Button>
      )}
      {onClickSave && (
        <Button
          className="save"
          disabled={isSaveDisabled}
          kind="primary"
          size={size}
          title={t(`common.${saveTextKey}`)}
          onClick={onClickSave}
        >
          {t(`common.${saveTextKey}`)}
        </Button>
      )}
    </div>
  );
};
