import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const PageIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M14.0664 1H1.93311V15H14.0664V1Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      className="stroke"
      d="M4.73291 3.79999H11.2662"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      className="stroke"
      d="M4.73291 6.59998H11.2662"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      className="stroke"
      d="M4.73291 9.40002H11.2662"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      className="stroke"
      d="M4.73291 12.2H7.53291"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
  </svg>
);
