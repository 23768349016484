// TODO: later rename file to api-collaboration and add `collaboration` prefix to all functions.
import axios, { AxiosInstance, AxiosResponse } from "axios";

import { type KernelSpecType } from "../worksheets/shared/code-kernels";
import { type CourseUserActivityType } from "../worksheets/shared/types/analytics";
import { type RevType } from "../worksheets/shared/types/sharedb";
import {
  AssetFieldType,
  type BlockType,
  type PathType,
  type WorksheetType,
} from "../worksheets/shared/types/worksheet";

import { store } from "store/index";
import { selectUserJWT } from "store/selectors";
import { rollbarAndLogError } from "utils/logger";

// Note: only the authoring API used the xsrf cookie/header and withCredentials flag. Review this later.
// TODO: Later stop exporting the axios instance and instead export the functions that use it.
// Only the DebugBanner uses this currently.
export const api: AxiosInstance = axios.create({
  baseURL: `${window._env_.COLLABORATION_URL}`,
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "x-csrftoken",
});

api.interceptors.request.use((config) => {
  if (!config.headers.Authorization) {
    const jwt = selectUserJWT(store.getState());
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${jwt}`;
  }
  return config;
});

// TODO
const minimalApi = api;

/** The below APIs are used by the authoring UI */
export const DOC_API_PATH = `/api/v1/doc`;

// This is only used for exporting purposes as otherwise we use ShareDB to get the worksheet's contents.
export const collaborationGetWorksheetJson = async (id: string) => {
  try {
    const response = await api.get<WorksheetType>(`${DOC_API_PATH}/${id}`);
    return response.data;
  } catch (error) {
    rollbarAndLogError("Failed to get worksheet JSON", error);
  }
};

export const collaborationPutWorksheetJson = async (id: string, body: WorksheetType) => {
  try {
    const response = await api.put<WorksheetType>(`${DOC_API_PATH}/${id}`, body);
    return response.data;
  } catch (error) {
    rollbarAndLogError("Failed to put worksheet JSON", error);
  }
};

// TODO: These response types come from collaboration, we should unify them in collaboration.ts.
interface UploadedFileType {
  url: string;
}

export const collaborationUploadFile = (file: FormData | File): Promise<UploadedFileType> => {
  let fileFormData: FormData;
  if (file instanceof FormData) {
    fileFormData = file;
  } else if (file instanceof File) {
    const data = new FormData();
    data.append("image", file);
    fileFormData = data;
  }
  return api.post<UploadedFileType>("/api/v1/upload/", fileFormData).then((r) => r.data);
};

// This endpoint is unused as of Feb 28, 2025. It was designed for allowing uploading a file asset and then
// creating a new Page with that asset as the first block. It will be permanently removed if
// it is not used again by the client in the future.
export const collaborationAddAssetToDoc = (id: string, asset: Partial<AssetFieldType>) => {
  return api.post(`${DOC_API_PATH}/${id}/add-asset-to-doc/`, asset);
};

/**  The below APIs are used by the collaboration UI */
export const getRevs: (docId: string) => Promise<AxiosResponse<RevType[]>> = (docId) =>
  minimalApi({
    method: "get",
    url: `/api/v1/revs/${docId}`,
  });

export const deleteUploadedFile = (filename: string) =>
  api({
    method: "post",
    url: "/api/v1/delete-file-upload",
    data: { filename },
  });

// Analytics API methods
export const getCourseUserData: (courseId: string) => Promise<CourseUserActivityType[]> = async (
  courseId,
) => {
  const response = await minimalApi<CourseUserActivityType[]>({
    method: "get",
    url: `/api/v1/courseUserData/${courseId}`,
  });

  return response.data;
};

/** These APIs are used for Code Blocks in a worksheet */
// TODO: Permission checks so users can only run their own code.
const kernelApi: (
  command: string,
  docAccessId: string,
  extraData?: object,
) => Promise<AxiosResponse<void>> = (command, docAccessId, extraData = {}) =>
  minimalApi({
    method: "post",
    url: `/api/v1/kernel/${command}`,
    data: {
      docId: docAccessId,
      ...extraData,
    },
  });

export const restartKernel = (docAccessId: string) => kernelApi("restart", docAccessId);

export const ensureKernel = (docAccessId: string, kernelSpec: KernelSpecType = {}) =>
  kernelApi("ensure", docAccessId, { kernelSpec });

// TODO: Unused for now
export const heartbeatKernel = (docAccessId: string) => kernelApi("heartbeat", docAccessId);

export const destroyKernel = (docAccessId: string) => kernelApi("destroy", docAccessId);

// Path is an optional initial path that points to the worksheets' location in the ShareDB
// document.
// TODO: CodeMVP: This was used for "Explore" mode, which has been removed. Re-implement.
export const runAllCells = (docAccessId: string, blocks: BlockType[], path: PathType) =>
  kernelApi("runall", docAccessId, { blocks, path });

export const executeCode = (
  docAccessId: string,
  code: string,
  path: PathType,
  kernelSpec: KernelSpecType,
) => kernelApi("execute", docAccessId, { code, path, kernelSpec });

export const sendInput = (docAccessId: string, input: string) =>
  kernelApi("reply", docAccessId, { content: input });

export const interruptKernel = (docAccessId: string) => kernelApi("interrupt", docAccessId);

export const changeKernel = async (docAccessId: string, kernelSpec: KernelSpecType = {}) => {
  await destroyKernel(docAccessId);
  await ensureKernel(docAccessId, kernelSpec);
};
