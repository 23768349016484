import type { FC, MouseEventHandler, ReactNode } from "react";
import Select, {
  type ControlProps,
  type MultiValueProps,
  type OptionProps,
  type Props as ReactSelectProps,
  type StylesConfig,
  components,
} from "react-select";

import { Checkbox } from "mds/components/Checkbox";
import { theme } from "virtual:tailwind-config";

export interface OptionType {
  readonly label: string;
  readonly value: number | string;
}
export type OptionsType = OptionType[];
interface TextListDropdownProps {
  /** Whether to format the dropdown options with checkboxes. */
  useCheckboxOption?: boolean;
  /** The icon to display on the control. */
  controlIcon?: ReactNode;
}

const CheckboxOption: FC<OptionProps> = ({ isSelected, isDisabled, children, ...rest }) => {
  const onClickMultiOption: MouseEventHandler<HTMLDivElement> = (e) => {
    rest.selectOption({ ...(rest.data as object) });
    e.stopPropagation();
    e.preventDefault();
  };

  const { useCheckboxOption } = rest.selectProps as TextListDropdownProps;

  return (
    <components.Option {...rest} isDisabled={isDisabled} isSelected={isSelected}>
      {useCheckboxOption && (
        <Checkbox
          checked={isSelected}
          className="mr-1"
          disabled={isDisabled}
          onContainerClick={onClickMultiOption}
        />
      )}
      {children}
    </components.Option>
  );
};

const Control: FC<ControlProps> = ({ children, ...props }) => {
  const { controlIcon } = props.selectProps as TextListDropdownProps;
  return (
    <components.Control {...props}>
      {controlIcon || null}
      {children}
    </components.Control>
  );
};

const CommaSeparatedMultiValue: FC<MultiValueProps> = ({ getValue, data }) => {
  const selectedOptions = getValue() as OptionsType;
  const index = selectedOptions.findIndex((option) => option.value === (data as OptionType).value);

  return (
    <div>
      {(data as OptionType).label}
      {index === selectedOptions.length - 1 ? "" : ",   "}
    </div>
  );
};

const colors = theme.colors;

/**
 * A dropdown component that displays its selected values in a comma-separated text list format. */
export const TextListDropdown: FC<TextListDropdownProps & ReactSelectProps> = ({ ...props }) => {
  // TODO: TextListDropdown not very accessible, cannot tab through and cannot use key up/key down.
  const styles = {
    placeholder: (defaultStyle) => {
      return { ...defaultStyle, color: colors.black["tint-70"] };
    },
    valueContainer: (defaultStyle) => {
      return { ...defaultStyle, gap: "2px", paddingTop: "4px", paddingBottom: "4px" };
    },
    control: (defaultStyle) => {
      return {
        ...defaultStyle,
        borderRadius: "6px",
        border: `1px solid ${colors.black["tint-90"]}`,
        paddingLeft: "6px",
        boxShadow: "none",
        "&:hover": {
          border: `1px solid ${colors.black["tint-90"]}`,
        },
      };
    },
    option: (defaultStyles, state) => {
      return {
        ...defaultStyles,
        display: "flex",
        alignItems: "center",
        borderLeft: `4px solid ${colors.white}`,
        borderRight: `4px solid ${colors.white}`,
        borderBottom: `4px solid ${colors.white}`,
        borderRadius: "12px",
        "&:hover": {
          backgroundColor: state.isSelected
            ? colors.blue.DEFAULT
            : state.isDisabled
              ? "inherit"
              : theme.colors.black["tint-95"],
        },
        color: state.isSelected
          ? colors.black["tint-90"]
          : state.isDisabled
            ? colors.black["tint-55"]
            : colors.black["tint-20"],
        backgroundColor: state.isSelected ? colors.blue.DEFAULT : "inherit",
      };
    },
  } as StylesConfig;

  return (
    <Select
      className="body-s w-full text-black-tint-20"
      closeMenuOnSelect={false}
      components={{
        Control,
        MultiValue: CommaSeparatedMultiValue,
        Option: CheckboxOption,
        IndicatorSeparator: () => null,
        ClearIndicator: () => null,
        DropdownIndicator: () => null,
        ...props.components,
      }}
      hideSelectedOptions={false}
      isClearable={false}
      styles={styles}
      isMulti
      {...props}
    />
  );
};
