import clsx from "clsx";

import { t } from "i18n/i18n";
import { Tooltip } from "mds/components/Tooltip";

export const PublishStatus = ({ status }: { status: "published" | "draft" | "error" }) => {
  const label = t(`assessment_dashboard.publish_status.${status}`);
  const tooltipText = t(`assessment_dashboard.publish_status.${status}_tooltip`);
  // TODO: If we every define a standardized color palette for status colors, we should
  // grab those instead of redefining them here and other places.
  const color =
    status === "published"
      ? "text-green-shade-20"
      : status === "draft"
        ? "text-yellow-shade-20"
        : "text-red-shade-20";
  return (
    // fit-content is used to make sure the tooltip is positioned over the middle of the text
    <div className="w-fit">
      <Tooltip element={<div className={clsx("h6", color)}>{label}</div>}>{tooltipText}</Tooltip>
    </div>
  );
};
