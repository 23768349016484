import { CourseOutcomeType } from "components/server-types";
import { ScoreIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import { selectPagesWithAssessmentsForCourseOutcome } from "store/selectors";

export const CourseOutcomeAverageScore = ({
  courseOutcome,
}: {
  courseOutcome: CourseOutcomeType;
}) => {
  const pageAssessmentsForCourseOutcome = useAppSelector((s) =>
    selectPagesWithAssessmentsForCourseOutcome(s, courseOutcome.id),
  );
  const onlyScoredAssessments = pageAssessmentsForCourseOutcome.filter(
    (pageAssessment) => pageAssessment.outcomeAssessment?.score,
  );

  const averageScore =
    onlyScoredAssessments.reduce((acc, pageAssessment) => {
      return acc + pageAssessment.outcomeAssessment.score;
    }, 0) / onlyScoredAssessments.length;

  return (
    <div className="ml-2">
      <ScoreIcon size="m" value={averageScore} />
    </div>
  );
};
