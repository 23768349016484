import {
  groupAccessIdFromGroupId,
  studentAccessIdFromUserId,
} from "../../worksheets/shared/access-id";
import { WorksheetType } from "../../worksheets/shared/types/worksheet";

import { AI_DEFAULT_SETTINGS } from "./AISettings";
import { docToPageAndAnswerContext } from "./query";

import { serverGetAIResponse } from "api/api-server";
import { isGroupPage } from "components/materials/page/groups/helpers";
import { AIMessageType, AIModelType, SubmissionType } from "components/server-types";
import { globalShareDBState } from "providers/WebsocketProvider";
import { RootState, store } from "store/index";
import {
  FullRubricType,
  selectCourseUsers,
  selectNestedPageOutcomes,
  selectPageById,
  selectPageOutcomeRubric,
} from "store/selectors";

/**
 * This prompt sets the general system prompt context for AI assessment on a specific page
 * outcome + student submission combination. It explains course outcomes, page outcomes, how
 * they fit together, and how we want assessments to look like on a certain page outcome + student
 * submission combination.
 */
export const assessmentSystemPrompt = [
  "You're Minerva's AI assistant, designed to help instructors assess individual student or group's submissions",
  "to in-class exercises and assignments.",
  "Your main concern is to assess based on learning outcomes, and give helpful but concise feedback.",
  "A course outcome is a learning objective that a course is designed to teach.",
  'Up to three course outcomes may be linked to a specific "Page" (which is either an assignment,',
  "a breakout activity, or a short in-class activity), and a Page should always be assessed on outcomes linked to it,",
  "unless there are no linked outcomes, in which case formative feedback should be given solely based on how closely the",
  "student answers follow the instructions on the Page.",
  "Each course outcome has a specific rubric that may be overwritten at the page level for additional detail.",
  "When you assess a student's submission, you should consider how well the student's work aligns",
  "with the specific outcomes and rubrics, and avoid giving scores based on other factors.",
  "Focus on giving formative feedback for how the student could specifically improve.",
  "Note that you're unable to access any files uploaded by students, and you should not count them towards the score.",
  "If it looks like a submission is substantially consisting of uploaded files, you should refuse to assess the submission and let them know you cannot read uploaded files yet.",
  "If a submission is lacking information from the student, you should still assess the submission and give appropriately low scores.",
  "For code written by students, also give feedback on the quality of the code, and whether it is human readable and easy to understand,",
  "though make sure to primarily assess on the outcomes rubric: the code quality, errors, and bugs are secondary concerns.",
].join(" ");

const forceSchemaQuery = [
  "You only answer in the following schema:",
  '{"outcomes": {"<outcome>": <score>, ...}, "feedback": "<feedback>"}',
  "where <score> is a score from 0 to 4 corresponding to the rubric index (if it exists),",
  "and feedback is a comment directed at the student, explaining the reasoning and giving feedback.",
  "Focus on giving formative feedback for how the student could specifically improve.",
  'If for any reason you have to refuse assessment, answer in the schema {"refused": "<reason>"}.',
  'The reason should be stated objectively, without using "I" or personal perspective.',
  "Respond with nothing but a JSON string, starting with the opening bracket.",
].join(" ");

/**
 * Gets LLM prompt context for a specific page-level outcome, which will include the rubric,
 * and whether or not the rubric is inherited from the course-level outcome.
 */
const selectPageOutcomeRubricContext = (
  state: RootState,
  pageId: string,
  pageOutcomeId: string,
) => {
  const allPageOutcomesForPage = selectNestedPageOutcomes(state, pageId);
  const pageOutcome = allPageOutcomesForPage.find((po) => po.id === pageOutcomeId);
  const pageRubric = selectPageOutcomeRubric(state, pageOutcomeId);
  const isInherited = (r: FullRubricType) => (r.usingPageRubric ? "(page-specific)" : "");

  return [
    `Outcome "${pageOutcome.outcome.title}", described as:`,
    `"""${pageOutcome.outcome.description}""".`,
    `With rubric:`,
    ...pageRubric.map((r) => `  - ${r.name} ${isInherited(r)}: ${r.description}`),
  ].join("\n");
};

const docSnapshotDataPromise = (
  docId: string,
  worksheetVersionNumber: number | null,
): Promise<WorksheetType> => {
  return new Promise((resolve, reject) => {
    const connection = globalShareDBState.connection;

    connection.fetchSnapshot(
      window._env_.SHAREDB_COLLECTION,
      docId,
      worksheetVersionNumber,
      (err, fetchedSnapshot) => {
        if (err) {
          // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
          reject(err);
        } else {
          resolve(fetchedSnapshot.data as WorksheetType);
        }
      },
    );
  });
};

export const selectAccessIdFromSubmission = (state: RootState, submission: SubmissionType) => {
  const page = selectPageById(state, submission.page_id);
  const isGrouped = isGroupPage(page);
  if (isGrouped) {
    return groupAccessIdFromGroupId(submission.page_group_id);
  }
  const userId = selectCourseUsers(state).find(
    (cu) => cu.id === submission.course_user_id,
  )?.user_id;
  if (userId) {
    return studentAccessIdFromUserId(userId);
  }
  throw new Error("Could not find user ID for submission");
};

export const selectSubmissionAssessmentContext = (
  state: RootState,
  docData: WorksheetType,
  pageId: string,
  accessId: string,
): AIMessageType[] => {
  const page = selectPageById(state, pageId);
  const pageOutcomes = selectNestedPageOutcomes(state, page.id);
  const outcomeContext =
    pageOutcomes.length > 0
      ? [
          "The following outcomes are marked for assessment on the current Page:",
          pageOutcomes
            .map((po) => selectPageOutcomeRubricContext(state, page.id, po.id))
            .join("\n\n"),
        ].join("\n")
      : "No outcomes are linked for assessment on the current Page.";

  const docAndAnswerContext = docToPageAndAnswerContext(docData, [{ name: null, accessId }]);

  // TODO: Base explanation of what 0-4 scores mean and how we think about them
  // TODO: General Minerva assessment philosophy
  // TODO: Context on current page (title, activity, topic, course description)
  const query: AIMessageType[] = [
    {
      role: "system",
      content: [assessmentSystemPrompt].join("\n\n"),
    },
    {
      role: "user",
      content: [
        outcomeContext,
        docAndAnswerContext,
        forceSchemaQuery,
        "Please help me with this assessment.",
      ].join("\n\n"),
    },
  ];

  return query;
};

// This cache is solely used for experimental AI assessment
const docDataCache = new Map<string, WorksheetType>();

type AIAssessmentDataResponseType = {
  outcomes: { pageOutcomeId: string; score: number }[];
  feedback: string;
};
type AIAssessmentErrorResponseType = {
  refused: string;
};
type AIAssessmentResponseType = AIAssessmentDataResponseType | AIAssessmentErrorResponseType;

export const serverAssessSubmission = async (
  submission: SubmissionType,
  model?: AIModelType,
): Promise<AIAssessmentDataResponseType | AIAssessmentErrorResponseType> => {
  const state = store.getState();
  const page = selectPageById(state, submission.page_id);
  const accessId = selectAccessIdFromSubmission(state, submission);
  const pageOutcomes = selectNestedPageOutcomes(state, page.id);

  let docData = docDataCache.get(page.worksheet_id);
  if (!docData) {
    docData = await docSnapshotDataPromise(page.worksheet_id, submission.worksheet_sharedb_version);
    docDataCache.set(page.worksheet_id, docData);
  }

  const query = selectSubmissionAssessmentContext(state, docData, page.id, accessId);

  const response = await (serverGetAIResponse(query, {
    model: model || AI_DEFAULT_SETTINGS.aiModel,
    stream: true,
  }) as Promise<string>);

  // eslint-disable-next-line no-console
  console.log(response);

  // parse json with expected structure
  let parsedResponse: AIAssessmentResponseType;
  try {
    parsedResponse = JSON.parse(response) as AIAssessmentResponseType;
  } catch (e) {
    const message = "Could not parse AI response to JSON";
    console.error(message, e, response);
    throw new Error(message);
  }

  if ("refused" in parsedResponse) {
    return parsedResponse;
  }

  const outcomes = Object.keys(parsedResponse.outcomes)
    .map((outcomeName) => {
      const pageOutcome = pageOutcomes.find((po) => po.outcome.title === outcomeName);
      if (!pageOutcome) {
        // If the AI invents an outcome, we ignore it
        return null;
      }
      return {
        pageOutcomeId: pageOutcome.id,
        score: Number(parsedResponse.outcomes[outcomeName]),
      };
    })
    .filter(Boolean);

  return {
    outcomes,
    feedback: parsedResponse.feedback,
  };
};
