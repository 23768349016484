// Layout CSS
import "./index.scss";
import "react-toastify/dist/ReactToastify.css";

import { AxiosError } from "axios";
import { Suspense, lazy, useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
// import { TouchBackend } from "react-dnd-touch-backend"; // Will use later, see comment below.
import { Outlet } from "react-router-dom";

import { serverGetUser, serverRedeemInviteKey } from "api/api-server";
import { LoadingScreen } from "components/LoadingScreen";
import { LoginView } from "components/sso/LoginView";
import { SignupView } from "components/sso/SignupView";
import { t } from "i18n/i18n";
import { Observers } from "providers/Observers";
import { WebsocketProvider } from "providers/WebsocketProvider";
import { useAppDispatch, useAppSelector } from "store";
import { selectCurrentUserId } from "store/selectors";
import { authStateActions } from "store/slices/auth";
import { toastErrorCatcher, toastLocalizedOperationCatcher } from "utils/alerts";
import { clearOrgIdCookie, getOrgIdFromCookie } from "utils/auth";
import { rollbarAndLogError } from "utils/logger";
import { courseHomeUrl } from "utils/urls";
import { isAVEnabled } from "utils/waffle";
import { ErrorBoundary } from "worksheets/views/ErrorBoundary";

// It's important we lazy-load any entry to the AV subsystem, as it uses many heavy dependencies
// and is only rarely enabled.
const AvContextProvider = lazy(() => import("components/av/AvProvider"));

export default function Layout() {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(selectCurrentUserId);
  const [inviteError, setInviteError] = useState<string | null>();
  const [isUserLoading, setIsUserLoading] = useState(true);

  // We don't use useSearchParams because this is only relevant on first load, and
  // we don't need to re-render the component when the query changes.
  const queryInviteLink = new URLSearchParams(window.location.search).get("invite_key");

  // User login management
  const checkActiveUser = useCallback(
    (justLoggedIn?: boolean) => {
      serverGetUser()
        .then((userResponse) => {
          dispatch(authStateActions.setCurrentUser(userResponse));
          setIsUserLoading(false);
        })
        .catch((error: AxiosError | Error) => {
          dispatch(authStateActions.setCurrentUser(null));
          setIsUserLoading(false);
          if (!(error instanceof AxiosError)) {
            toastLocalizedOperationCatcher("get_user_data_failure")(error);
            rollbarAndLogError("Failed to get user", error);
            return;
          }

          const data = error.response?.data as Record<string, string>;
          if (data && typeof data === "object" && "code" in data) {
            if (data.code === "NOT_IN_THIS_ORG" && getOrgIdFromCookie()) {
              clearOrgIdCookie();
              window.location.reload();
            }
            toastErrorCatcher(error);
            rollbarAndLogError("User failed to authenticate", error);
          } else if (error.response?.status === 401 && !justLoggedIn) {
            // We're simply not authenticated yet, the OAuth token expired,
            // or we changed something about the OAuth code.
          } else {
            toastLocalizedOperationCatcher("failed_to_check_login_data")(error);
            rollbarAndLogError("Failed to get user", error);
          }
        });
    },
    [dispatch],
  );

  useEffect(() => {
    if (!userId) {
      checkActiveUser();
    }
  }, [checkActiveUser, userId]);

  // If a logged-in user arrives via an invite link, we redeem it in the background before
  // forwarding them to the relevant course view.
  useEffect(() => {
    if (userId && !isUserLoading && queryInviteLink && !inviteError) {
      setIsUserLoading(true);

      serverRedeemInviteKey(queryInviteLink)
        .then(({ course_id }) => {
          // Force a page refresh to make sure auth related calls are made to the right
          // org, in case the org has changed with the invite link.
          window.location.href = courseHomeUrl(course_id);
        })
        .catch((error: AxiosError) => {
          const errorKey =
            error instanceof AxiosError && error.response?.status === 403
              ? "invalid_invite_key"
              : "unknown_error";

          setInviteError(t(`user_options_menu.redeem.${errorKey}`));
        })
        .finally(() => setIsUserLoading(false));
    }
  }, [queryInviteLink, isUserLoading, userId, inviteError]);

  if ((!userId && !isUserLoading) || inviteError) {
    return (
      <div className="flex h-full items-center justify-center">
        {queryInviteLink || inviteError ? (
          <SignupView
            checkActiveUser={checkActiveUser}
            setUserInviteError={setInviteError}
            userInviteError={inviteError}
          />
        ) : (
          <LoginView onLoggedIn={() => checkActiveUser(true)} />
        )}
      </div>
    );
  }

  if (!userId && isUserLoading) {
    return (
      <div className="h-screen w-screen">
        <LoadingScreen />
      </div>
    );
  }
  const mainContent = (
    <>
      <Observers />
      {/* TODO: The DnDProvider will only work on mobile touch devices with the TouchBackend (which we will support).
                However, the TouchBackend requires you to implement your own custom drag layer to display the UI
                while dragging. The HTML5Backend automatically makes an image of your component and uses that, which
                we are using now for expediency. Once the design is further along, we can switch over the backend as
                this will also give us more control over the look and feel when the item is dragging (e.g. we can
                remove the Edit/Delete icons then). At that point the react-dnd-html5-backend can be removed. */}
      {/* <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}> */}
      <DndProvider backend={HTML5Backend}>
        {/* TODO: This Error boundary should show a message indicating that the route itself has failed
                  and offer navigation to a different route. We could introduce a special kind of error that is
                  parsed differently, e.g. throw new QueryParamError that lets the user know the URL is malformed
                  somehow. */}
        <ErrorBoundary minimal>
          <Outlet />
        </ErrorBoundary>
      </DndProvider>
    </>
  );

  return (
    <div className="h-full w-full overflow-hidden">
      <WebsocketProvider>
        {isAVEnabled() ? (
          <Suspense fallback={<LoadingScreen text="Loading AV..." />}>
            <AvContextProvider userId={userId}>{mainContent}</AvContextProvider>
          </Suspense>
        ) : (
          mainContent
        )}
      </WebsocketProvider>
    </div>
  );
}
