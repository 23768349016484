import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const PresentIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M8.83366 9.99996C8.83366 10.4602 8.46056 10.8333 8.00033 10.8333C7.54009 10.8333 7.16699 10.4602 7.16699 9.99996C7.16699 9.53972 7.54009 9.16663 8.00033 9.16663C8.46056 9.16663 8.83366 9.53972 8.83366 9.99996Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M11.8002 6.19996C10.8002 5.26663 9.46686 4.66663 8.0002 4.66663C6.53353 4.66663 5.2002 5.26663 4.2002 6.19996"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M14.6004 3.39996C12.9337 1.73329 10.6004 0.666626 8.00039 0.666626C5.40039 0.666626 3.06706 1.73329 1.40039 3.39996"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
