import clsx from "clsx";
import { compareDesc } from "date-fns";
import { cloneDeep } from "lodash";
import { FC, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { getNavigationValues } from "../../utils/urls";
import {
  getGroupIdFromAccessId,
  getStudentIdFromAccessId,
  isGroupAccessId,
} from "../../worksheets/shared/access-id";

import { useResponsesForPage } from "./page/helpers";

import {
  ACCESS_ID_QUERY_PARAM,
  COURSE_CODE_ID_QUERY_PARAM,
  OUTCOME_ID_QUERY_PARAM,
  PAGE_ID_QUERY_PARAM,
  SUBMISSION_ID_QUERY_PARAM,
  TERM_ID_QUERY_PARAM,
} from "components/constants";
import { PageType, ResponseType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { ChevronLeftIcon, ChevronRightIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import {
  FullSubmissionType,
  selectCanAuthorOrg,
  selectCourseCodeCoursesByTerms,
  selectCourseCodes,
  selectNestedOutcomeGroups,
  selectPageById,
  selectSubmissionsForPage,
  selectTerms,
} from "store/selectors";

type ContentNavigationButtonsProps = {
  className?: string;
  onChange?: (itemId: string, itemUrl: URL) => void;
  disabled?: boolean;
  reference: "page" | "term" | "outcome" | "course" | "submission";
  pages?: PageType[];
};

const getSubmissionNavigationValues = (
  searchParams: URLSearchParams,
  responses: ResponseType[],
  submissions: FullSubmissionType[],
) => {
  const accessId = searchParams.get(ACCESS_ID_QUERY_PARAM);
  const responseIds = responses.map((response) => response.id);
  const sortedSubmissions = submissions.sort((a, b) =>
    a.official_at ? -1 : b.official_at ? 1 : compareDesc(a.created_at, b.created_at),
  );

  const { nextId, prevId, nextURL, prevURL } = getNavigationValues(responseIds, {
    queryParam: ACCESS_ID_QUERY_PARAM,
    currentId: accessId,
  });

  if (nextId) {
    let submissionForNextUser: FullSubmissionType | undefined;
    if (isGroupAccessId(nextId)) {
      const groupId = getGroupIdFromAccessId(nextId);
      submissionForNextUser = sortedSubmissions.find(
        (submission) => submission.page_group_id === groupId,
      );
    } else {
      const userId = getStudentIdFromAccessId(nextId);
      submissionForNextUser = sortedSubmissions.find(
        (submission) => submission.course_user.user_id === userId,
      );
    }

    nextURL.searchParams.set(SUBMISSION_ID_QUERY_PARAM, submissionForNextUser?.id);
  }

  if (prevId) {
    let submissionForPrevUser: FullSubmissionType | undefined;
    if (isGroupAccessId(prevId)) {
      const groupId = getGroupIdFromAccessId(prevId);
      submissionForPrevUser = sortedSubmissions.find(
        (submission) => submission.page_group_id === groupId,
      );
    } else {
      const userId = getStudentIdFromAccessId(prevId);
      submissionForPrevUser = sortedSubmissions.find(
        (submission) => submission.course_user.user_id === userId,
      );
    }

    prevURL.searchParams.set(SUBMISSION_ID_QUERY_PARAM, submissionForPrevUser?.id);
  }

  return { nextId, prevId, nextURL, prevURL };
};

/*
 * This component is used to navigate between different content items. It works in conjunction
 * with the store and the useContentNavigation hook to keep track of the next and previous items.
 * Consumers must send the list of items to navigate between and optionally include a query param
 * to update when the current item changes.
 */
export const ContentNavigationButtons: FC<ContentNavigationButtonsProps> = ({
  className,
  onChange,
  disabled = false,
  reference,
  pages = [],
}) => {
  const [searchParams] = useSearchParams();

  // TODO: Why are we getting every type of navigation item with every set of buttons???
  const terms = useAppSelector(selectTerms);
  const canAuthorOrg = useAppSelector(selectCanAuthorOrg);
  const courseCodeCoursesByTerm = useAppSelector(selectCourseCodeCoursesByTerms);
  const orderedTerms = useMemo(() => {
    const termsToOrder = canAuthorOrg
      ? terms
      : terms.filter((term) => {
          const courseCodeCourses = courseCodeCoursesByTerm[term.id];
          return courseCodeCourses.some(
            (courseCode) => courseCode.courses && courseCode.courses.length > 0,
          );
        });

    return termsToOrder.sort((a, b) => a.order - b.order).map(cloneDeep);
  }, [terms, canAuthorOrg, courseCodeCoursesByTerm]);

  const outcomeGroups = useAppSelector(selectNestedOutcomeGroups);
  const outcomes = outcomeGroups
    .map((outcomeGroup) => outcomeGroup.outcomes)
    .flat()
    .concat(
      outcomeGroups
        .map((outcomeGroup) => outcomeGroup.outcome_subgroups)
        .flat()
        .map((subgroup) => subgroup.outcomes)
        .flat(),
    );

  const courseCodes = useAppSelector(selectCourseCodes);
  const orderedCourseCodes = useMemo(
    () => courseCodes.sort((a, b) => a.order - b.order).map(cloneDeep),
    [courseCodes],
  );

  const pageId = searchParams.get(PAGE_ID_QUERY_PARAM);
  const page = useAppSelector((s) => selectPageById(s, pageId));
  const responses = useResponsesForPage(page);
  const submissions = useAppSelector((s) => selectSubmissionsForPage(s, pageId));

  const getNavValues = (list: { id: string }[], queryParam: string) =>
    getNavigationValues(
      list.map((l) => l.id),
      { queryParam, currentId: searchParams.get(queryParam) },
    );

  const navValuesLookup: Record<
    "page" | "term" | "outcome" | "course",
    ReturnType<typeof getNavigationValues>
  > = {
    page: getNavValues(pages, PAGE_ID_QUERY_PARAM),
    term: getNavValues(orderedTerms, TERM_ID_QUERY_PARAM),
    outcome: getNavValues(outcomes, OUTCOME_ID_QUERY_PARAM),
    course: getNavValues(orderedCourseCodes, COURSE_CODE_ID_QUERY_PARAM),
  };

  const { nextId, prevId, nextURL, prevURL } =
    reference === "submission"
      ? getSubmissionNavigationValues(searchParams, responses, submissions)
      : navValuesLookup[reference];

  return (
    <div className={clsx("hidden min-h-12 items-center md:flex", className)}>
      <Button
        aria-label={t("common.previous")}
        className="!rounded-r-none rounded-l-xl"
        disabled={!prevId || disabled}
        kind="secondary"
        title={t(`tooltip.previous.${reference}`)}
        to={!(disabled || onChange) && prevURL ? prevURL.toString() : undefined}
        iconOnly
        onClick={disabled || !onChange ? undefined : () => onChange(prevId, prevURL)}
      >
        <ChevronLeftIcon />
      </Button>

      <Button
        aria-label={t("common.next")}
        className="!rounded-l-none rounded-r-xl"
        disabled={!nextId || disabled}
        kind="secondary"
        title={t(`tooltip.next.${reference}`)}
        to={!(disabled || onChange) && nextURL ? nextURL.toString() : undefined}
        iconOnly
        onClick={disabled || !onChange ? undefined : () => onChange(nextId, nextURL)}
      >
        <ChevronRightIcon />
      </Button>
    </div>
  );
};
