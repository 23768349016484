// This was copied in from CourseBuilder's `editable-field-on-hover.js` file with minor edits.
import clsx from "clsx";
import { ReactNode } from "react";
import type { FC } from "react";

import { EditDeleteWidget } from "mds/components/EditDeleteWidget";
import { useHoverable } from "mds/hooks/use-hoverable";

type EditDeleteOnHoverProps = {
  children: ReactNode;
  className?: string;
  onClickDelete?: () => void;
  onClickEdit?: () => void;
  onClickAdd?: () => void;
  reference:
    | "lesson"
    | "archive"
    | "course_resource"
    | "outcome"
    | "course_code"
    | "term"
    | "course";
};

export const EditDeleteOnHover: FC<EditDeleteOnHoverProps> = ({
  children,
  onClickDelete,
  onClickEdit,
  onClickAdd,
  reference,
  className,
}) => {
  const { isHovering, hoverParentProps } = useHoverable();

  return (
    <div className={clsx("flex flex-auto items-center", className)} {...hoverParentProps}>
      {children}
      <EditDeleteWidget
        hover={isHovering}
        reference={reference}
        styles={{ alignSelf: "flex-center", marginLeft: "2px" }}
        onClickAdd={onClickAdd}
        onClickDelete={onClickDelete}
        onClickEdit={onClickEdit}
      />
    </div>
  );
};
