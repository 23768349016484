import i18nInstance from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { Trans as ReactTrans, initReactI18next } from "react-i18next";

import { TFunctionType } from "./i18next";

import enTranslations from "i18n/locales/en.json";
import esTranslations from "i18n/locales/es.json";

export const defaultNS = "ns1";

// TODO: Not necessary for now, but it would be nice to eventually use a backend
//       plugin to lazy load and/or initialize with necessary resources.
export const resources = {
  en: {
    ns1: enTranslations,
  },
  es: {
    ns1: esTranslations,
  },
} as const;

export type SupportedLanguage = keyof typeof resources;

export const LANGUAGE_LABELS: Record<SupportedLanguage, string> = {
  en: "English",
  es: "Español",
};

export const INTERFACE_LANGUAGES = ["en", "es"] as SupportedLanguage[];

export const DEFAULT_LANGUAGE = "en" as SupportedLanguage;

i18nInstance
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    ns: ["ns1"],
    /** Keep debug to true for now to monitor logs for any missing translations. */
    debug: process.env.NODE_ENV !== "test",
    defaultNS,
    resources,
    fallbackLng: DEFAULT_LANGUAGE,
    saveMissing: true, // Logs any missing translation keys to console.
    supportedLngs: INTERFACE_LANGUAGES,
  });

const currentLang = (localStorage.getItem("i18nextLng") as SupportedLanguage) || DEFAULT_LANGUAGE;

// i18next already exports "t", but this one is scoped to the default namespace, so we don't
// have to pass the namespace every time
export const t = i18nInstance.getFixedT(currentLang, defaultNS) as TFunctionType;

// These are just re-exports so that we can use eslint to force everything to be imported from
// this file, instead of i18next or react-i18next.
export const Trans = ReactTrans;
export const i18n = i18nInstance;
