import clsx from "clsx";
import { FC } from "react";

import { GroupMenuButton } from "components/materials/page/grouping-category/GroupMenuButton";
import { StudentGroupingCategoryView } from "components/materials/page/grouping-category/StudentGroupingCategoryView";
import { GROUPING_OPTION_ICONS } from "components/materials/page/grouping-category/helpers";
import { isGroupPage } from "components/materials/page/groups/helpers";
import { GroupingCategory, PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { IconText } from "mds/components/IconText";
import type { ClickEvent } from "mds/components/Menu";
import { Menu, MenuItem } from "mds/components/Menu";
import { ChevronDownIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import {
  selectAssessmentsByPageId,
  selectCanAuthorCourse,
  selectIsAssessing,
} from "store/selectors";

export type PageGroupingCategoryMenuProps = {
  page: PageType;
  onAuthorClick: (groupingCategory: GroupingCategory) => void;
  onStudentGroupCardToggle?: () => void;
  readOnly?: boolean;
  pageGroupIndex: number;
};

export const PageGroupingCategoryMenu: FC<PageGroupingCategoryMenuProps> = ({
  page,
  onAuthorClick,
  onStudentGroupCardToggle,
  readOnly = false,
  pageGroupIndex,
}) => {
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const assessments = useAppSelector((s) => selectAssessmentsByPageId(s, page.id));
  const isAssessing = useAppSelector(selectIsAssessing);
  const isReadOnly = readOnly || assessments.length > 0 || isAssessing;

  if (!canAuthorCourse) {
    return (
      <StudentGroupingCategoryView
        page={page}
        pageGroupIndex={pageGroupIndex}
        onStudentGroupCardToggle={onStudentGroupCardToggle}
      />
    );
  }

  const onItemClick = (event: ClickEvent) => {
    const groupingCategory = event.value as GroupingCategory;
    onAuthorClick(groupingCategory);
  };

  const MenuButton = !isGroupPage(page) ? (
    <Button
      className="gap-1"
      disabled={isReadOnly}
      kind="secondary"
      readOnly={isReadOnly}
      size="xs"
      title={
        assessments.length > 0
          ? t("tooltip.page_config_has_assessments")
          : t("tooltip.type_of_activity")
      }
    >
      <IconText
        iconStart={GROUPING_OPTION_ICONS[page.grouping_category]}
        text={t(`page_grouping_menu.grouping.${page.grouping_category}`)}
      />
      {!isReadOnly && <ChevronDownIcon />}
    </Button>
  ) : (
    <GroupMenuButton
      page={page}
      pageGroupIndex={pageGroupIndex}
      restricted={isReadOnly}
      onGroupConfigCardToggle={onAuthorClick}
      onStudentGroupCardToggle={onStudentGroupCardToggle}
    />
  );

  return (
    <div>
      <Menu
        menuButton={MenuButton}
        title={t("page_grouping_menu.change_page_grouping")}
        onItemClick={onItemClick}
      >
        {!isReadOnly && (
          <>
            <GroupingMenuItem groupingCategory={GroupingCategory.INDIVIDUAL} key="1" page={page} />
            <GroupingMenuItem
              groupingCategory={GroupingCategory.SHARED_CLASS}
              key="2"
              page={page}
            />
            <GroupingMenuItem
              groupingCategory={
                page.grouping_category === GroupingCategory.SELF_ASSIGN
                  ? page.grouping_category
                  : GroupingCategory.ASSIGN_STUDENTS
              }
              key="3"
              page={page}
            />
          </>
        )}
      </Menu>
    </div>
  );
};

type GroupingMenuItemProps = {
  groupingCategory: GroupingCategory;
  page: PageType;
};

const GroupingMenuItem: FC<GroupingMenuItemProps> = ({ groupingCategory, page }) => {
  const checked = groupingCategory === page.grouping_category;

  return (
    <MenuItem checked={checked} className={clsx({ selected: checked })} value={groupingCategory}>
      <IconText
        iconStart={GROUPING_OPTION_ICONS[groupingCategory]}
        text={t(`page_grouping_menu.grouping.${groupingCategory}`)}
      />
    </MenuItem>
  );
};
