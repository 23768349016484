import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const RefreshIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M13.2206 6.08524C12.739 4.91691 11.8775 3.94491 10.7753 3.32618C9.67301 2.70746 8.39431 2.47814 7.14567 2.67524C5.89702 2.87234 4.75131 3.48437 3.89353 4.41249C3.28111 5.07513 2.84099 5.87128 2.60371 6.731"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.14286"
    />
    <path
      className="stroke"
      d="M1.64881 12.0794L2.34851 9.61094L4.87176 10.4442"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.14286"
    />
    <path
      className="stroke"
      d="M14.1652 4.18136L13.4645 6.64955L10.9416 5.8152"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.14286"
    />
    <path
      className="stroke"
      d="M2.825 10.371C3.30661 11.5394 4.1681 12.5114 5.27036 13.1301C6.37262 13.7488 7.65131 13.9781 8.89996 13.781C10.1486 13.5839 11.2943 12.9719 12.1521 12.0438C12.7645 11.3812 13.2046 10.5851 13.4419 9.72551"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.14286"
    />
  </svg>
);
