import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const PresentPlayBackgroundIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="fill"
      clipRule="evenodd"
      d="M2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0H2ZM5.8163 3.89619C5.5501 3.72506 5.2 3.9162 5.2 4.23267V11.7673C5.2 12.0838 5.5501 12.2749 5.8163 12.1038L11.6766 8.33647C11.9215 8.17902 11.9215 7.82098 11.6766 7.66353L5.8163 3.89619Z"
      fillRule="evenodd"
    />
  </svg>
);
