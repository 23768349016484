import "./CancelSaveMenu.scss";

import type { FC, ReactNode, Ref } from "react";
import { useRef } from "react";

import { CancelSaveWidget } from "mds/components/CancelSaveWidget";
import { Menu, MenuInstance, MenuProps } from "mds/components/Menu";

interface CancelSaveMenuProps {
  button: MenuProps["menuButton"];
  children: ReactNode;
  title?: string;
  isSaveDisabled?: boolean;
  onSave: () => void;
  onCancel?: () => void;
  saveTextKey?: "save" | "update";
}

/** CancelSaveMenu is a wrapper with three parts:
 *   - a title
 *   - children (e.g. input fields)
 *   - a cancel save widget */
export const CancelSaveMenu: FC<CancelSaveMenuProps> = ({
  button,
  children,
  title,
  isSaveDisabled,
  onSave,
  onCancel,
  saveTextKey,
}) => {
  const cancelSaveMenuRef: Ref<MenuInstance> = useRef(null);

  return (
    <Menu instanceRef={cancelSaveMenuRef} menuButton={button}>
      <div className="cancel-save-menu flex flex-col gap-3 rounded-lg p-2">
        {title && <div className="h4 text-black-tint-40">{title}</div>}
        {children}
        <CancelSaveWidget
          className="flex w-full justify-end gap-2"
          isSaveDisabled={isSaveDisabled}
          saveTextKey={saveTextKey}
          size="xs"
          onClickCancel={() => {
            cancelSaveMenuRef.current.closeMenu();
            if (onCancel) {
              onCancel();
            }
          }}
          onClickSave={() => {
            cancelSaveMenuRef.current.closeMenu();
            onSave();
          }}
        />
      </div>
    </Menu>
  );
};
