import { isGroupPage } from "components/materials/page/groups/helpers";
import { OutcomeAssessmentType, PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { showConfirmationModal } from "mds/components/Modal";
import { FullSubmissionType } from "store/selectors";

export const getMedianScore = (scores: number[]) => {
  const sorted = scores.sort((a, b) => a - b);
  return sorted[Math.floor(sorted.length / 2)];
};

export const getMeanScore = (scores: number[]) => {
  if (scores.length === 0) return null;
  return scores.reduce((acc, score) => acc + score, 0) / scores.length;
};

export const getMeanScoreByPageOutcomeId = (outcomeAssessments: OutcomeAssessmentType[]) => {
  const scoresByPageOutcomeId = outcomeAssessments.reduce(
    (acc, oa) => ({ ...acc, [oa.page_outcome_id]: [...(acc[oa.page_outcome_id] || []), oa.score] }),
    {} as { [pageOutcomeId: string]: number[] },
  );
  return Object.entries(scoresByPageOutcomeId).reduce(
    (acc, [pageOutcomeId, scores]) => {
      return { ...acc, [pageOutcomeId]: getMeanScore(scores) };
    },
    {} as { [pageOutcomeId: string]: number },
  );
};

export const showUnpublishAssessmentModal = (count: number, onUnpublishAll: () => void) => {
  const labelText = t("assessment.unpublish_assessment_modal.description", { count });
  return showConfirmationModal({
    title: t("assessment.unpublish_assessment_modal.title"),
    labelText,
    confirmButtonText: t("assessment.unpublish_all"),
    onConfirm: onUnpublishAll,
  });
};

export const getTotalSubmissions = (page: PageType, submissions: FullSubmissionType[]) =>
  isGroupPage(page)
    ? new Set(submissions.map((s) => s.page_group_id)).size
    : new Set(submissions.map((s) => s.course_user_id)).size;
