/**
 * Takes a number of days and returns a Django duration field string like "DD HH:MM:SS".
 * The actual field format comes from https://www.django-rest-framework.org/api-guide/fields/#durationfield
 */
export const EMPTY_DATE = "00:00:00";

export function formatDaysToDjangoDurationField(days: number) {
  if (days === Infinity) {
    return null;
  }

  if (days === 0) {
    return EMPTY_DATE;
  }

  return `${days} ${EMPTY_DATE}`;
}

/**
 * Takes "DD HH:MM:SS" or `null` and returns the number of days.
 * The actual field format comes from https://www.django-rest-framework.org/api-guide/fields/#durationfield
 */
export function formatDjangoDurationFieldToDaysCount(duration: string | null) {
  if (duration === null) {
    return Infinity;
  }

  return parseInt(duration.split(" ")[0], 10);
}
