import { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router";

import { rollbarAndLogInfo } from "../../utils/logger";

import { store, storeApi, useAppDispatch, useAppSelector } from "store/index";
import { selectCourses, selectCurrentCourse, selectWebsocketUserEmail } from "store/selectors";
import { viewStateActions } from "store/slices/view";
import { usePromiseEffect } from "store/store-hooks";
import { trackUserProperty } from "utils/amplitude";
import { courseHomeUrl, orgHomeUrl } from "utils/urls";

export default function Layout() {
  const currentCourse = useAppSelector(selectCurrentCourse);
  const currentCourseId = currentCourse?.id;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const courseList = useAppSelector(selectCourses);

  useEffect(() => {
    rollbarAndLogInfo(`Attempting to set new course version`, {
      course_version: currentCourse?.course_version,
      course_id: currentCourse?.id,
      email: selectWebsocketUserEmail(store.getState()),
    });
    dispatch(viewStateActions.setCourseVersionTo(currentCourse?.course_version));
  }, [currentCourse, dispatch]);

  // Ensure this component refreshes when we navigate between the course routes
  const params = useParams();
  const routeCourseId = params.courseId;

  const { isLoading } = usePromiseEffect(() => storeApi.courses.list(), []);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!routeCourseId && courseList.length === 0) {
      navigate(orgHomeUrl);
      return;
    }

    if (routeCourseId) {
      dispatch(viewStateActions.setCurrentCourseId(routeCourseId));
      trackUserProperty("courseId", routeCourseId);
    }

    if (["/course", "/course/"].includes(location.pathname)) {
      navigate(courseHomeUrl(currentCourseId));
    }
  }, [routeCourseId, currentCourseId, location, dispatch, navigate, isLoading, courseList]);

  return <Outlet />;
}
