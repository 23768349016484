import "./CopyToCourseSubMenu.scss";

import { FC, type MouseEvent, useState } from "react";

import { CourseCodeMenu } from "components/menus/CourseCodeMenu";
import { TermMenu } from "components/menus/TermMenu";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { CancelSaveWidget } from "mds/components/CancelSaveWidget";
import { Checkbox } from "mds/components/Checkbox";
import { ListItem } from "mds/components/ListItem";
import { useAppSelector } from "store";
import { selectCoursesByTermAndCourseCode, selectCurrentCourse } from "store/selectors";

export interface CopyToCourseSubMenuProps {
  onCancel: () => void;
  onConfirm: (courseIds: string[]) => void;
}

export const CopyToCourseSubMenu: FC<CopyToCourseSubMenuProps> = ({ onCancel, onConfirm }) => {
  const currentCourse = useAppSelector(selectCurrentCourse);
  const [selectedCourseCodeId, setSelectedCourseCodeId] = useState<string>(
    currentCourse.course_code_id,
  );
  const [selectedTermId, setSelectedTermId] = useState<string>(currentCourse.term_id);
  const [selectedCourseIds, setSelectedCourseIds] = useState<string[]>([]);

  const courses = useAppSelector((s) =>
    selectCoursesByTermAndCourseCode(s, selectedTermId, selectedCourseCodeId),
  );

  const eligibleCourses = courses.filter((course) => course.id !== currentCourse.id);
  const allSelected = eligibleCourses.length === selectedCourseIds.length;

  const handleCourseCodeChange = (newCourseCodeId: string) => {
    setSelectedCourseCodeId(newCourseCodeId);
  };

  const handleTermChange = (newTermId: string) => {
    setSelectedTermId(newTermId);
  };

  const handleCourseSelected = (event: MouseEvent, courseId: string) => {
    event.preventDefault();
    event.stopPropagation();

    toggleCourseChecked(courseId);
  };

  const toggleCourseChecked = (courseId: string) => {
    const newSelectedCourseIds = [...selectedCourseIds];

    const courseIndex = newSelectedCourseIds.indexOf(courseId);
    if (courseIndex > -1) {
      newSelectedCourseIds.splice(courseIndex, 1);
    } else {
      newSelectedCourseIds.push(courseId);
    }

    setSelectedCourseIds(newSelectedCourseIds);
  };

  const onSelectAll = () => {
    setSelectedCourseIds(allSelected ? [] : eligibleCourses.map((course) => course.id));
  };

  return (
    <div className="copy-to-course-sub-menu w-full bg-black-tint-99 p-3">
      <div className="body-s flex gap-1 pb-5">{t("copy_to_course_sub_menu.title")}</div>

      <div className="flex items-center justify-between gap-2 pb-2">
        <div className="flex items-center gap-2">
          <CourseCodeMenu
            selectedCourseCodeId={selectedCourseCodeId}
            onCourseCodeChange={handleCourseCodeChange}
          />

          <TermMenu selectedTermId={selectedTermId} onTermChange={handleTermChange} />
        </div>

        <Button kind="tertiary" onClick={onSelectAll}>
          {t(allSelected ? "common.unselect_all" : "common.select_all")}
        </Button>
      </div>

      <div className="course-list mb-2">
        {/* TODO: add empty state if the selected and term and course code has no courses */}
        {eligibleCourses.length === 0 ? (
          <div className="text-black-tint-70">{t("copy_to_course_sub_menu.no_courses")}</div>
        ) : (
          eligibleCourses.map((course) => (
            <div key={course.id}>
              <ListItem className="gap-2" onClick={() => toggleCourseChecked(course.id)}>
                <Checkbox
                  checked={selectedCourseIds.includes(course.id)}
                  onContainerClick={(e) => handleCourseSelected(e, course.id)}
                />
                {course.title}
              </ListItem>
            </div>
          ))
        )}
      </div>

      <div className="right-align pb-2 text-black-tint-70">
        {t("copy_to_course_sub_menu.selected_courses", { count: selectedCourseIds.length })}
      </div>

      <CancelSaveWidget
        className="right"
        isSaveDisabled={selectedCourseIds.length === 0}
        saveTextKey="copy"
        onClickCancel={onCancel}
        onClickSave={() => {
          onConfirm(selectedCourseIds);
          setSelectedCourseIds([]);
        }}
      />
    </div>
  );
};
