import { useEffect, useState } from "react";
import { type Doc, type Snapshot } from "sharedb/lib/client";

import { WorksheetType } from "../shared/types/worksheet";

import { Worksheet } from "worksheets/views/Worksheet";

export const SubmissionWorksheet = ({
  doc,
  worksheetVersionNumber,
}: {
  doc: Doc<WorksheetType>;
  worksheetVersionNumber: number | null;
}) => {
  const [snapshot, setSnapshot] = useState<Snapshot<WorksheetType>>(null);

  useEffect(() => {
    doc.connection.fetchSnapshot(
      doc.collection,
      doc.id,
      worksheetVersionNumber,
      (err, fetchedSnapshot) => {
        if (err) {
          throw new Error(`Error fetching snapshot: ${String(err)}`);
        }
        setSnapshot(fetchedSnapshot);
      },
    );
  }, [worksheetVersionNumber, doc]);

  if (!snapshot) return null; // TODO: Replace with a better loading indicator.

  const skeletonDocWithHistoricData = {
    submitOp: (...args) => {
      // eslint-disable-next-line no-console, @typescript-eslint/no-unsafe-argument
      console.log("Skipping submitOp in submission view:", ...args);
    },
    data: snapshot.data,
    on: () => {},
    presence: { create: () => {}, on: () => {}, off: () => {} },
    removeListener: () => {},
  } as unknown as Doc<WorksheetType>;

  return (
    <div>
      <Worksheet doc={skeletonDocWithHistoricData} />
    </div>
  );
};
