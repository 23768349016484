import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const PaperClipLinkIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M12.8959 6.66625L7.67459 11.8875C6.19128 13.3708 3.69931 13.3708 2.216 11.8875C0.732687 10.4042 0.732687 7.91224 2.216 6.42892L6.84394 1.80099C7.91192 0.733004 9.57323 0.733004 10.6412 1.80099C11.7092 2.86897 11.7092 4.53028 10.6412 5.59827L6.36928 9.81088C5.77595 10.4042 4.82663 10.4042 4.29264 9.81088C3.69931 9.21755 3.69931 8.26823 4.29264 7.73424L7.85259 4.17429"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
