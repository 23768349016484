import clsx from "clsx";
import type { FC } from "react";

interface HorizontalDividerProps {
  className?: string;
}

export const HorizontalDivider: FC<HorizontalDividerProps> = ({ className }) => {
  return <div className={clsx("horizontal-divider border-t border-black-tint-90", className)} />;
};
