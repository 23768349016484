import { createSlice } from "@reduxjs/toolkit";

import {
  AvBandwidthModeType,
  AvStateType,
  AvSubsystemAvServiceType,
  BlurGlobalState,
  ConnectionStates,
  DevicesRequestStates,
  StreamStates,
} from "../types/av";

export type AvRootState = {
  av: AvStateType;
};

export const initialAvState: AvStateType = {
  local_user_id: undefined,
  room_id: undefined, // RoomId for Licode and Daily opentok_session_id for opentok
  av_service: AvSubsystemAvServiceType.Licode,
  connection_error: null,
  connection_state: ConnectionStates.initial, // This will be a dictionary { session_id: StreamState }
  connection_quality: null,
  bandwidth_mode: AvBandwidthModeType.AutomaticBandwidth,
  streams: {},
  screensharing_streams: {},
  device_list: [],
  aggregated_stats: { published: {}, subscribed: {} },
  screenshare_state: {
    request_state: DevicesRequestStates.initial,
    default_device_id: null,
    disabled: false,
    error: null,
  },
  mic_state: {
    request_state: DevicesRequestStates.initial,
    default_device_id: null,
    disabled: false,
    error: null,
  },
  camera_state: {
    request_state: DevicesRequestStates.initial,
    default_device_id: null,
    disabled: false,
    error: null,
  },
  blur_state: BlurGlobalState.INITIAL,
};

const avSlice = createSlice({
  name: "av",
  initialState: initialAvState,
  reducers: {
    addStream(state, action) {
      const {
        userId,
        domId,
        isScreenshare,
        streamState,
        videoEnabled,
        audioEnabled,
        qualityLevel,
      } = action.payload;
      const newStreamState = streamState || StreamStates.initial;
      if (isScreenshare) {
        state.screensharing_streams[userId] = {
          id: null,
          dom_id: domId,
          retries: 0,
          state: newStreamState,
          quality_level: qualityLevel,
          is_screensharing: true,
          video_enabled: videoEnabled,
          video_available: false,
          audio_enabled: audioEnabled,
          audio_available: false,
          copy_requested: false,
          stats: {
            video: {},
            audio: {},
            connection: {},
          },
        };
      } else {
        state.streams[userId] = {
          id: null,
          dom_id: domId,
          state: newStreamState,
          retries: 0,
          quality_level: qualityLevel,
          is_screensharing: false,
          video_enabled: videoEnabled,
          video_available: false,
          audio_enabled: audioEnabled,
          audio_available: false,
          copy_requested: false,
          stats: {
            video: {},
            audio: {},
            connection: {},
          },
        };
      }
    },

    updateStreamState(state, action) {
      const { userId, isScreenshare, streamState } = action.payload;
      if (isScreenshare) {
        state.screensharing_streams[userId].state = streamState;
      } else {
        state.streams[userId].state = streamState;
      }
    },

    updateStreamRetries(state, action) {
      const { userId, isScreenshare, retries } = action.payload;
      if (isScreenshare) {
        state.screensharing_streams[userId].retries = retries;
      } else {
        state.streams[userId].retries = retries;
      }
    },

    updateStreamStats(state, action) {
      const { userId, isScreenshare, stats } = action.payload;
      if (isScreenshare) {
        state.screensharing_streams[userId].stats = stats;
      } else {
        state.streams[userId].stats = stats;
      }
    },

    updateAggregatedStats(state, action) {
      const { aggregatedStats } = action.payload;
      state.aggregated_stats = aggregatedStats;
    },

    updateStreamEnableVideo(state, action) {
      const { userId, isScreenshare, videoEnabled } = action.payload;
      if (isScreenshare) {
        state.screensharing_streams[userId].video_enabled = videoEnabled;
      } else {
        state.streams[userId].video_enabled = videoEnabled;
      }
    },

    updateStreamEnableAudio(state, action) {
      const { userId, isScreenshare, audioEnabled } = action.payload;
      if (isScreenshare) {
        state.screensharing_streams[userId].audio_enabled = audioEnabled;
      } else {
        state.streams[userId].audio_enabled = audioEnabled;
      }
    },

    updateStreamVideoAvailable(state, action) {
      const { userId, isScreenshare, videoAvailable } = action.payload;
      if (isScreenshare) {
        state.screensharing_streams[userId].video_available = videoAvailable;
      } else {
        state.streams[userId].video_available = videoAvailable;
      }
    },

    updateStreamAudioAvailable(state, action) {
      const { userId, isScreenshare, audioAvailable } = action.payload;
      if (isScreenshare) {
        state.screensharing_streams[userId].audio_available = audioAvailable;
      } else {
        state.streams[userId].audio_available = audioAvailable;
      }
    },

    updateStreamRequestCopy(state, action) {
      const { userId, isScreenshare, requestCopy } = action.payload;
      if (isScreenshare) {
        state.screensharing_streams[userId].copy_requested = requestCopy;
      } else {
        state.streams[userId].copy_requested = requestCopy;
      }
    },

    removeStream(state, action) {
      const { userId, isScreenshare } = action.payload;
      if (isScreenshare) {
        delete state.screensharing_streams[userId];
      } else {
        delete state.streams[userId];
      }
    },

    updateStreamQualityLevel(state, action) {
      const { userId, isScreenshare, qualityLevel } = action.payload;
      if (isScreenshare) {
        state.screensharing_streams[userId].quality_level = qualityLevel;
      } else {
        state.streams[userId].quality_level = qualityLevel;
      }
    },

    clearAllStreamsState(state) {
      Object.keys(state.streams).forEach((key: string) => {
        state.streams[key].state = StreamStates.initial;
        state.streams[key].retries = 0;
        state.streams[key].stats = {
          video: {},
          audio: {},
          connection: {},
        };
        state.aggregated_stats = { published: {}, subscribed: {} };
      });
      Object.keys(state.screensharing_streams).forEach((key: string) => {
        state.screensharing_streams[key].state = StreamStates.initial;
      });
    },

    clearAllState(state) {
      Object.assign(state, initialAvState);
    },

    setStreamDomId(state, action) {
      const { userId, domId } = action.payload;
      state.streams[userId] = domId;
    },

    setStreamId(state, action) {
      const { userId, streamId } = action.payload;
      state.streams[userId] = streamId;
    },

    updateConnectionAvService(state, action) {
      const { avService } = action.payload;
      state.av_service = avService;
    },

    updateConnectionBandwidthMode(state, action) {
      const { bandwidthMode } = action.payload;
      state.bandwidth_mode = bandwidthMode;
    },

    updateConnectionState(state, action) {
      const { connectionState, connectionError } = action.payload;
      state.connection_state = connectionState;
      if (connectionState === ConnectionStates.connected) {
        state.connection_error = null;
      } else {
        state.connection_quality = null;
      }

      if (connectionError) {
        state.connection_error = connectionError;
      }
    },

    updateConnectionQualityLevel(state, action) {
      const { connectionQuality } = action.payload;
      state.connection_quality = connectionQuality;
    },

    setRoomId(state, action) {
      const { roomId } = action.payload;
      state.room_id = roomId;
    },

    updateDeviceList(state, action) {
      const { deviceList } = action.payload;
      state.device_list = deviceList;
    },

    updateDefaultDevices(state, action) {
      const { cameraDevice, micDevice } = action.payload;
      if (cameraDevice) {
        state.camera_state.default_device_id = cameraDevice;
      }
      if (micDevice) {
        state.mic_state.default_device_id = micDevice;
      }
    },

    updateSelectedDevices(state, action) {
      const { cameraDevice, micDevice } = action.payload;
      const camDeviceEntry = state.device_list.find(
        (device) => device.kind === "videoinput" && device.id === cameraDevice,
      );
      const micDeviceEntry = state.device_list.find(
        (device) => device.kind === "audioinput" && device.id === micDevice,
      );
      const previouslySelected = state.device_list.filter(
        (device) => device.kind !== "audiooutput" && device.selected,
      );
      for (const device of previouslySelected) {
        device.selected = false;
      }
      if (micDeviceEntry && micDeviceEntry.selected === false) {
        micDeviceEntry.selected = true;
      }
      if (camDeviceEntry && camDeviceEntry.selected === false) {
        camDeviceEntry.selected = true;
      }
    },

    updateSelectedAudioOutput(state, action) {
      const { audioOutputDevice } = action.payload;
      const audioOutputDeviceEntry = state.device_list.find(
        (device) => device.kind === "audiooutput" && device.id === audioOutputDevice,
      );
      const previouslySelected = state.device_list.filter(
        (device) => device.kind !== "audiooutput" && device.selected,
      );
      for (const device of previouslySelected) {
        device.selected = false;
      }
      if (audioOutputDeviceEntry) {
        audioOutputDeviceEntry.selected = true;
      }
    },
    updateDeviceGlobalState(state, action) {
      const {
        camDeviceState,
        micDeviceState,
        screenshareDeviceState,
        camDeviceError,
        micDeviceError,
        screenshareDeviceError,
      } = action.payload;
      if (camDeviceState) {
        state.camera_state.request_state = camDeviceState;
        if (camDeviceState !== DevicesRequestStates.blocked) {
          state.camera_state.error = null;
        }
      }
      if (micDeviceState) {
        state.mic_state.request_state = micDeviceState;
        if (micDeviceState !== DevicesRequestStates.blocked) {
          state.mic_state.error = null;
        }
      }
      if (screenshareDeviceState) {
        state.screenshare_state.request_state = screenshareDeviceState;
        if (screenshareDeviceState !== DevicesRequestStates.blocked) {
          state.screenshare_state.error = null;
        }
      }
      if (camDeviceError) {
        state.camera_state.error = camDeviceError;
      }
      if (micDeviceError) {
        state.mic_state.error = micDeviceError;
      }
      if (screenshareDeviceError) {
        state.screenshare_state.error = screenshareDeviceError;
      }
    },
    updateDeviceGlobalError(state, action) {
      const { camDeviceError, micDeviceError, screenshareDeviceError } = action.payload;
      if (camDeviceError) {
        state.camera_state.error = camDeviceError;
      }
      if (micDeviceError) {
        state.mic_state.error = micDeviceError;
      }
      if (screenshareDeviceError) {
        state.screenshare_state.error = screenshareDeviceError;
      }
    },

    clearDeviceGlobalErrors(state, action) {
      const { camDeviceError, micDeviceError, screenshareDeviceError } = action.payload;
      if (camDeviceError) {
        state.camera_state.error = null;
      }
      if (micDeviceError) {
        state.mic_state.error = null;
      }
      if (screenshareDeviceError) {
        state.screenshare_state.error = null;
      }
    },

    updateDeviceGlobalDisabled(state, action) {
      const { camDeviceDisabled } = action.payload;
      state.camera_state.disabled = camDeviceDisabled;
    },

    updateBlurState(state, action) {
      const { blurState } = action.payload;
      state.blur_state = blurState;
    },
  },
});

export const {
  addStream,
  removeStream,
  clearAllStreamsState,
  clearAllState,
  updateStreamState,
  updateStreamRetries,
  updateStreamEnableAudio,
  updateStreamAudioAvailable,
  updateStreamEnableVideo,
  updateStreamVideoAvailable,
  updateStreamRequestCopy,
  updateStreamQualityLevel,
  updateAggregatedStats,
  updateStreamStats,
  updateConnectionState,
  updateConnectionAvService,
  updateConnectionBandwidthMode,
  updateConnectionQualityLevel,
  setRoomId,
  updateDeviceList,
  updateDefaultDevices,
  updateSelectedDevices,
  updateSelectedAudioOutput,
  updateDeviceGlobalState,
  updateDeviceGlobalError,
  clearDeviceGlobalErrors,
  updateDeviceGlobalDisabled,
  updateBlurState,
} = avSlice.actions;
const avSliceReducer = avSlice.reducer;
export type avSliceReducerType = typeof avSliceReducer;
export { avSliceReducer };
