import { FC } from "react";

import { t } from "i18n/i18n";
import { ClockIcon } from "mds/icons";

interface DurationDisplayBoxProps {
  duration: number;
}

export const DurationDisplayBox: FC<DurationDisplayBoxProps> = ({ duration }) => {
  return (
    <div
      className="flex items-center justify-between gap-1 px-2 text-xs font-medium text-black-tint-20"
      title={t("tooltip.estimated_completion_time")}
    >
      <ClockIcon />
      <span className="whitespace-nowrap">{t("common.in_minutes", { minutes: duration })}</span>
    </div>
  );
};
