import clsx from "clsx";
import { type FC, ReactNode, useState } from "react";
import { useBoolean } from "usehooks-ts";

import { useCanDeleteActions } from "../../../../utils/delete";

import { HoverableEditField } from "components/hover-widgets/EditableField";
import { HoverableToolbar } from "components/hover-widgets/HoverableToolbar";
import { PageItemStatusIcon } from "components/materials/page/PageItemStatusIcon";
import { StatusColorType } from "components/materials/page/helpers";
import { TopicSidebarOptionsMenu } from "components/menus/TopicSidebarOptionsMenu";
import { ReleaseStatus, TopicType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { ConfirmDeletionDialog } from "mds/components/ConfirmDeletionDialog";
import { DragAndDrop } from "mds/components/DragAndDrop";
import { ListItem } from "mds/components/ListItem";
import { TextAddButton } from "mds/components/TextAddButton";
import { useOrdered } from "mds/hooks/use-ordered";
import { EyeNotVisibleIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import {
  selectCurrentCourse,
  selectTopicStatus,
  selectVisibleTopicsForCurrentCourse,
} from "store/selectors";
import { useListSelector } from "store/store-hooks";
import { useDnDItemOrdering, useLocalCopy } from "utils/collections";
import { PAGE_LIST_CUTOFF_LENGTH } from "utils/page";
import { onTitleChanged } from "utils/store-utils";
import { useTopicActions } from "utils/topic";
import { courseContentUrl } from "utils/urls";

const TOPIC_STATE_TO_COLOR: Record<Exclude<ReleaseStatus, "unreleased">, StatusColorType> = {
  released: "green",
  partially_released: "orange",
};

// All the prop passing is a bit annyoing, but this was the easiest way to make sure
// every topic can efficiently query it's own release status from the store
const TopicListItem: FC<{
  topic: TopicType;
  index: number;
  lastItem: boolean;
  canAuthorCourse: boolean;
  onMove: ReturnType<typeof useDnDItemOrdering>;
}> = ({ topic, lastItem, index, canAuthorCourse, onMove }) => {
  const { copyTopic, deleteTopic, dropTopic } = useTopicActions();
  const currentCourse = useAppSelector(selectCurrentCourse);
  const topicDerivedStatus = useAppSelector((s) => selectTopicStatus(s, topic.id));
  const topicHasPages = topicDerivedStatus !== null;
  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  const topicStatus = topicDerivedStatus || topic.release_status || "unreleased";
  const { canDeleteTopic } = useCanDeleteActions(topic.id);
  const {
    value: confirmationDialogIsOpen,
    setTrue: openConfirmationDialog,
    setFalse: closeConfirmationDialog,
  } = useBoolean(false);

  const confirmDeleteTopic = () => {
    if (topicHasPages) {
      openConfirmationDialog();
    } else {
      deleteTopic(topic);
    }
  };

  const menuProps = {
    onDelete: confirmDeleteTopic,
    isDeleteDisabled: !canDeleteTopic,
    deleteTooltipText: canDeleteTopic ? undefined : t("tooltip.delete_topic_has_assessments"),
    onDuplicate: () => copyTopic(topic.id, [topic.course_id]),
    onCopyToCourse: (ids: string[]) => copyTopic(topic.id, ids),
  };

  if (!canAuthorCourse && topicStatus === "unreleased") {
    return null;
  }

  let itemStatusIcon: ReactNode;
  let topicIndex: ReactNode;
  let className: string;
  if (topicStatus === "unreleased") {
    itemStatusIcon = <EyeNotVisibleIcon className="icon-black-tint-55" />;
    className = "text-black-tint-55";
  } else {
    topicIndex = <span>{index + 1}.</span>;
    itemStatusIcon = <PageItemStatusIcon color={TOPIC_STATE_TO_COLOR[topicStatus]} isMulti />;
  }

  return (
    <>
      {
        // TODO: This DragAndDrop -> ListItem -> HoverableToolbar -> Icon+EditableField needs
        // to be abstracted so we don't have style drift between all the activity card types,
        // and other list types (like TopicList, CoursePageList, etc). We should instead express
        // any style differences as part of the ListItem MDS styles using the `kind` property.
      }
      <DragAndDrop
        className="min-w-12"
        draggable={canAuthorCourse && !isEditingTitle}
        dragItemKind="topic"
        dropContainerId="topic"
        dropIndex={index}
        droppable={canAuthorCourse}
        item={topic}
        key={topic.id}
        onDraggedItemHover={onMove}
        onDropItem={dropTopic}
      >
        <ListItem
          className="justify-between"
          kind="primary"
          label={topic.title}
          lastItem={lastItem}
          to={courseContentUrl(currentCourse.id, topic.id)}
        >
          <HoverableToolbar
            className="w-full min-w-12 py-1.5"
            readOnly={!canAuthorCourse}
            reference="topic"
            showEditButton
            uncentered
            onEditModeChange={setIsEditingTitle}
          >
            <div className={clsx("flex w-full min-w-12 items-start gap-2", className)}>
              <div className="my-1 flex min-h-6 items-center gap-2">
                {itemStatusIcon}

                {topicIndex}
              </div>

              <HoverableEditField
                className="editable-field min-w-12"
                label={t("glossary.topic_title")}
                textSize="m"
                value={topic.title}
                shouldWrap
                onValueChanged={(newTitle) => onTitleChanged("topics", topic.id, newTitle)}
              />
            </div>

            <TopicSidebarOptionsMenu
              buttonKind="tertiary"
              direction="left"
              optionsMenuActions={menuProps}
            />
          </HoverableToolbar>
        </ListItem>
      </DragAndDrop>

      <ConfirmDeletionDialog
        glossaryKey="topic"
        instanceName={topic.title}
        open={confirmationDialogIsOpen}
        onClose={closeConfirmationDialog}
        onDelete={() => deleteTopic(topic)}
      />
    </>
  );
};

type TopicListProps = {
  canEdit?: boolean;
  shouldTruncateList?: boolean;
};

export const TopicList: FC<TopicListProps> = ({ canEdit, shouldTruncateList }) => {
  const courseTopics = useListSelector(selectVisibleTopicsForCurrentCourse);
  const course = useAppSelector(selectCurrentCourse);

  const sortedTopics = useOrdered(courseTopics);
  const [topics, setTopics] = useLocalCopy<TopicType[]>(sortedTopics);
  const { createTopic } = useTopicActions();

  const moveTopicsLocally = useDnDItemOrdering(topics, setTopics);

  const createNewTopic = () => {
    createTopic(topics, course.id);
  };

  const shouldShowOverflowMenu = shouldTruncateList && topics.length > PAGE_LIST_CUTOFF_LENGTH;
  const visibleTopics = !shouldShowOverflowMenu ? topics : topics.slice(0, PAGE_LIST_CUTOFF_LENGTH);

  return (
    <div role="list">
      <h3 className="mb-2 flex items-center justify-between text-black-tint-10">
        {t("content_view.topics")}
      </h3>

      {visibleTopics.map((topic, index) => {
        return (
          <TopicListItem
            canAuthorCourse={canEdit}
            index={index}
            key={topic.id}
            lastItem={index === topics.length - 1}
            topic={topic}
            onMove={moveTopicsLocally}
          />
        );
      })}

      {shouldShowOverflowMenu && (
        <Button className="my-2" kind="secondary" to={courseContentUrl(topics[0].course_id, null)}>
          {t(`content_view.view_all.topic`, { count: topics.length })}
        </Button>
      )}

      {canEdit && (
        <TextAddButton
          className="mt-1 h-[42px]"
          reference="topic"
          size="s"
          underlined
          onClick={() => createNewTopic()}
        />
      )}
    </div>
  );
};
