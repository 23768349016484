import { useState } from "react";

export const useHoverable = () => {
  const [isHovering, setIsHovering] = useState(false);

  const hoverParentProps = {
    onMouseEnter: () => setIsHovering(true),
    onMouseLeave: () => setIsHovering(false),
    onDragOver: () => setIsHovering(true),
    onDragEnter: () => setIsHovering(true),
    onDragLeave: () => setIsHovering(false),
  };

  return { isHovering, hoverParentProps };
};
