import { useState } from "react";

function useControllable<T, E>(
  controlledValue: T,
  initialValue: T,
  changeHandler: (newValue: T, event: E) => void = null,
): [T, (newValue: T, event: E) => void] {
  const [uncontrolledValue, setUncontrolledValue] = useState<T>(initialValue);
  const effectiveValue = controlledValue !== undefined ? controlledValue : uncontrolledValue;
  const setValue = (newValue: T, event: E) => {
    setUncontrolledValue(newValue);
    if (changeHandler) changeHandler(newValue, event);
  };

  return [effectiveValue, setValue];
}

export { useControllable };
