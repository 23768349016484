import "./AccordionTitle.scss";

import clsx from "clsx";
import type { FC, ReactNode } from "react";
import { useState } from "react";

import { t } from "i18n/i18n";
import { ChevronDownIcon, ChevronUpIcon } from "mds/icons";

type AccordionTitleProps = {
  children: ReactNode;
  title: string;
  iconDirection?: "start" | "end";
  startOpen?: boolean;
  showBorder?: boolean;
};

/**
 * Displays a title with an chevron icon to the left of it, which acts as an
 * accordion to toggle abitrary children.
 */
export const AccordionTitle: FC<AccordionTitleProps> = ({
  children,
  title,
  startOpen = false,
  iconDirection = "start",
  showBorder = false,
}) => {
  const [expanded, setExpanded] = useState(startOpen);

  const icon = expanded ? <ChevronUpIcon /> : <ChevronDownIcon />;

  return (
    <div className={clsx("accordion-title", { "accordion-title-border": showBorder })}>
      <div
        className={clsx("flex items-center gap-2", {
          "justify-between": showBorder,
        })}
        title={expanded ? t("tooltip.collapse") : t("tooltip.expand")}
        onClick={() => setExpanded(!expanded)}
      >
        {iconDirection === "start" && icon}

        <h4 className="accordion-title-header">{title}</h4>

        {iconDirection === "end" && icon}
      </div>

      {expanded && <div className="accordion-title-content">{children}</div>}
    </div>
  );
};
