import clsx from "clsx";
import type { ChangeEvent, HTMLAttributes, ReactNode, TextareaHTMLAttributes } from "react";
import { forwardRef } from "react";
import "./TextArea.scss";
import TextareaAutosize, { type TextareaAutosizeProps } from "react-textarea-autosize";

import { useIsMdOrLarger } from "mds/hooks/use-responsive";
import { useLocalCopy } from "utils/collections";

type TextAreaType = TextareaAutosizeProps & TextareaHTMLAttributes<HTMLTextAreaElement>;
interface TextAreaProps extends TextAreaType {
  children?: ReactNode;
  size?: "xs" | "s" | "m";
  divProps?: HTMLAttributes<HTMLDivElement>;
}

// TODO: Add a ResizeObserver so that auto sizing will work when we open/close the sidebar while viewing rubrics.
/** For single field text area features (e.g. AIChatPanel, CharacterCounter). */
export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ size, className = "", children, value: originalValue, ...props }, ref) => {
    // If the original value changes from a different source while we are editing, it's
    // better to reflect that immediately, so the user can react and doesn't overwrite someone
    // else's changes.
    const [value, setValue] = useLocalCopy(originalValue);

    const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);
      if (props.onChange) {
        props.onChange(event);
      }
    };

    return (
      <BaseTextArea
        className={className}
        ref={ref}
        size={size}
        value={value}
        onChange={onChange}
        {...props}
      >
        {children}
      </BaseTextArea>
    );
  },
);

TextArea.displayName = "TextArea";

/**
 * For multi-field text area features that rely on their own hooks (e.g. RubricList) to work rather than
 * rely on useLocalCopy from TextArea, which will cause rerender issues. */
export const BaseTextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ size, className = "", divProps = {}, children, ...props }, ref) => {
    // On mobile devices browsers automatically zoom when input font-size is < 16px
    // This is a workaround to ensure the page doesn't zoom in on mobile devices
    // https://wsform.com/knowledgebase/why-forms-zoom-on-some-mobile-devices-and-browsers-and-how-to-control-it/#:~:text=Mobile%20browsers%20typically%20zoom%20in,using%20your%20own%20custom%20CSS.
    const isMdOrLarger = useIsMdOrLarger();
    const { readOnly, value } = props;
    const mobileFriendlySizeClass = isMdOrLarger ? `body-${size}` : "body-m";
    const textAreaClass = clsx("text-area", mobileFriendlySizeClass, className);

    // We use a div instead of a textarea when the text area is read only so that
    // we can minimize resizing and scrolling issues when the space is constrained
    // or changed. We've run into issues where TextAreaAutosize may leave small
    // scrollbars or doesn't resize on different browsers or devices.
    return readOnly ? (
      <div
        {...divProps}
        className={clsx(
          "whitespace-pre-wrap rounded-lg border border-transparent pl-1.5",
          mobileFriendlySizeClass,
          divProps.className,
          className,
        )}
      >
        {value}
      </div>
    ) : (
      <TextareaAutosize className={textAreaClass} ref={ref} {...props}>
        {children}
      </TextareaAutosize>
    );
  },
);

BaseTextArea.displayName = "BaseTextArea";
