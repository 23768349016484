import clsx from "clsx";
import type { FC } from "react";
import { useSearchParams } from "react-router-dom";

import { AssessmentComment } from "./AssessmentComment";

import { SUBMISSION_ID_QUERY_PARAM } from "components/constants";
import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { OutcomeChip } from "mds/components/OutcomeChip";
import { AssessPointsMarkerIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import {
  selectAssessmentBySubmissionId,
  selectFullSubmissionById,
  selectOrderedRubricForPage,
  selectOutcomeAssessmentByAssessmentId,
} from "store/selectors";
import { defaultRubricNames } from "utils/outcome";

interface AssessmentDisplayViewProps {
  page: PageType;
}

export const AssessmentDisplayView: FC<AssessmentDisplayViewProps> = ({ page }) => {
  const [searchParams] = useSearchParams();
  const submissionId = searchParams.get(SUBMISSION_ID_QUERY_PARAM);
  const submission = useAppSelector((s) => selectFullSubmissionById(s, submissionId));
  const assessment =
    useAppSelector((s) => selectAssessmentBySubmissionId(s, submission?.id)) || null;

  const rubric = useAppSelector((s) => selectOrderedRubricForPage(s, page.id));
  const outcomeAssessments = useAppSelector(
    (s) => selectOutcomeAssessmentByAssessmentId(s, assessment?.id) || [],
  );
  const scoreNames = defaultRubricNames();
  const assessedRubric = rubric
    .map((rubricItem) => {
      const outcomeAssessment = outcomeAssessments.find(
        (oa) => oa.page_outcome_id === rubricItem.page_outcome_id,
      );
      if (!outcomeAssessment) {
        return undefined;
      }
      return {
        id: rubricItem.page_outcome_id,
        title: rubricItem.title,
        score: outcomeAssessment.score as 0 | 1 | 2 | 3 | 4,
        scoreName: scoreNames[outcomeAssessment.score],
        description: rubricItem.rubric_descriptions[outcomeAssessment.score],
      };
    })
    .filter(Boolean);

  const scoreColorClasses = {
    0: "bg-score-0 text-white",
    1: "bg-score-1 text-white",
    2: "bg-score-2 text-white",
    3: "bg-score-3 text-white",
    4: "bg-score-4 text-white",
  };

  const descriptionColorClasses = {
    0: "bg-score-light-0",
    1: "bg-score-light-1",
    2: "bg-score-light-2",
    3: "bg-score-light-3",
    4: "bg-score-light-4",
  };

  return (
    <div className="flex min-h-full w-full flex-col gap-4 rounded-2xl border border-black-tint-90 px-5 py-4">
      <h3 className="flex flex-row items-center gap-2">
        <AssessPointsMarkerIcon className="icon-green" />
        {t("glossary.assessment")}
      </h3>
      <div className="flex flex-col gap-6">
        {assessedRubric.length > 0 && (
          <ol className="flex flex-col gap-6">
            {assessedRubric.map((rubricItem) => (
              <li className="flex flex-col gap-2" key={rubricItem.id}>
                <div className="flex items-center justify-between">
                  <OutcomeChip size="m" title={`#${rubricItem.title}`} />
                  <div className="flex items-center rounded-md bg-black-tint-97 text-black-tint-86">
                    {[0, 1, 2, 3, 4].map(
                      (
                        score, // TODO: We shouldn't hardcode the scores like this.
                      ) => (
                        <div
                          aria-hidden={rubricItem.score !== score}
                          className={clsx(
                            "flex size-7 items-center justify-center rounded-lg",
                            rubricItem.score === score && scoreColorClasses[score],
                          )}
                          key={score}
                        >
                          {score}
                        </div>
                      ),
                    )}
                  </div>
                </div>
                <div
                  className={clsx(
                    "rounded-lg px-1 py-0.5 text-black-tint-20",
                    descriptionColorClasses[rubricItem.score],
                  )}
                >
                  <div className="flex flex-row items-center">
                    <div
                      className={clsx(
                        "m-1.5 flex size-4 items-center justify-center rounded text-xs",
                        scoreColorClasses[rubricItem.score],
                      )}
                    >
                      {rubricItem.score}
                    </div>
                    <div className="grow font-medium">{rubricItem.scoreName}</div>
                  </div>
                  <div className="ml-7 pb-2 pr-1 text-sm font-normal">{rubricItem.description}</div>
                </div>
              </li>
            ))}
          </ol>
        )}

        {page.max_points > 0 && (
          <div className="flex items-center gap-1 text-black-tint-20">
            <span className="font-semibold">{t("submission_assessment.total_points")}:</span>
            <span>
              {assessment?.points}/{page.max_points}
            </span>
          </div>
        )}

        {assessment?.comment && <AssessmentComment comment={assessment.comment} />}
      </div>
    </div>
  );
};
