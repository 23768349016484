import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const CopySendArrowIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path className="stroke" d="M1.75 7.71423H9.75" strokeLinecap="round" strokeLinejoin="round" />
    <path
      className="stroke"
      d="M7.46484 5.42859L9.75056 7.7143L7.46484 10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M3.25 12V14.5C3.25 14.7652 3.35536 15.0196 3.54289 15.2071C3.73043 15.3946 3.98478 15.5 4.25 15.5H13.25C13.5152 15.5 13.7696 15.3946 13.9571 15.2071C14.1446 15.0196 14.25 14.7652 14.25 14.5V1.5C14.25 1.23478 14.1446 0.98043 13.9571 0.792893C13.7696 0.605357 13.5152 0.5 13.25 0.5H4.25C3.98478 0.5 3.73043 0.605357 3.54289 0.792893C3.35536 0.98043 3.25 1.23478 3.25 1.5V4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
