import { FC, ReactNode } from "react";
import { Params, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { CATEGORY_QUERY_PARAM } from "components/constants";
import { CourseSelect } from "components/header/CourseSelect";
import { CoursePageCategory, TopicType } from "components/server-types";
import { t } from "i18n/i18n";
import { DropdownMenu } from "mds/components/DropdownMenu";
import { DEFAULT_ORDER_FN } from "mds/hooks/use-ordered";
import { AssessPointsMarkerIcon, EyeNotVisibleIcon, PageIcon, PageListIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import {
  selectCanAuthorCourse,
  selectCurrentCourse,
  selectVisibleTopicsForCurrentCourse,
} from "store/selectors";
import { useListSelector } from "store/store-hooks";
import { CourseViewVariantType, courseContentUrl, courseHomeUrl, coursePageUrl } from "utils/urls";

type CourseBreadcrumbsProps = {
  view: CourseViewVariantType;
  onlyShowCourseSelect: boolean;
  enableCourseSelect: boolean;
};

type CourseItemType = {
  title: ReactNode;
  id: "topic" | CoursePageCategory;
  iconStart?: ReactNode;
};

const getCourseItems = (
  canAuthorCourse: boolean,
  view: CourseViewVariantType,
  searchParams: URLSearchParams,
) => {
  if (view === "main") {
    return { courseItems: undefined, selectedCourseItem: undefined };
  }

  const courseItems: CourseItemType[] = [
    {
      iconStart: <PageListIcon />,
      title: t("glossary.topics"),
      id: "topic",
    },
    {
      iconStart: <AssessPointsMarkerIcon />,
      title: t("glossary.assignments"),
      id: "assignment",
    },
    {
      iconStart: <PageIcon />,
      title: t("glossary.resources"),
      id: "course_resource",
    },
  ];

  if (canAuthorCourse) {
    courseItems.push({
      iconStart: <EyeNotVisibleIcon />,
      title: t("glossary.instructor_workspace"),
      id: "instructor_workspace",
    });
  }

  const selectedCourseItem =
    view === "topic" ? "topic" : searchParams.get(CATEGORY_QUERY_PARAM) || courseItems[1]?.id;

  return { courseItems, selectedCourseItem };
};

const getItems = (view: CourseViewVariantType, topics: TopicType[], params: Params<string>) => {
  if (view !== "topic") {
    return { items: undefined, selectedItemId: undefined };
  }

  const items = topics.sort(DEFAULT_ORDER_FN).map((topic, index) => ({
    iconStart: <PageIcon />,
    title: `${index + 1}. ${topic.title}`,
    id: topic.id,
  }));

  return { items, selectedItemId: params.topicId };
};

export const CourseBreadcrumbs: FC<CourseBreadcrumbsProps> = ({
  view,
  onlyShowCourseSelect,
  enableCourseSelect,
}) => {
  const navigate = useNavigate();
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const params = useParams();
  const [searchParams] = useSearchParams();

  const course = useAppSelector(selectCurrentCourse);
  const visibleTopics = useListSelector(selectVisibleTopicsForCurrentCourse);

  const { courseItems, selectedCourseItem } = onlyShowCourseSelect
    ? ({} as { selectedCourseItem: string; courseItems: CourseItemType[] })
    : getCourseItems(canAuthorCourse, view, searchParams);
  const { items, selectedItemId } = getItems(view, visibleTopics, params);

  const navigateToCourseItem = (courseItem: CourseItemType) => {
    const url =
      courseItem.id === "topic"
        ? courseHomeUrl(course.id)
        : coursePageUrl(course.id, courseItem.id);

    navigate(url);
  };

  const navigateToTopic = (item: { id: string }) => {
    navigate(courseContentUrl(course.id, item.id));
  };

  return (
    <div className="flex items-center gap-1 text-black-tint-20">
      <CourseSelect disabled={!enableCourseSelect} readOnly={!enableCourseSelect} truncateTitle />
      <div className="hidden items-center gap-1 lg:flex">
        {!onlyShowCourseSelect && courseItems && (
          <>
            /
            <DropdownMenu
              className="text-black-tint-20"
              items={courseItems}
              kind="tertiary"
              selectedValue={selectedCourseItem}
              size="s"
              onSelectionChange={navigateToCourseItem}
            />
          </>
        )}
        {!onlyShowCourseSelect && items && (
          <>
            /
            <DropdownMenu
              className="text-black-tint-20"
              items={items}
              kind="tertiary"
              selectedValue={selectedItemId}
              size="s"
              allowFullLengthItems
              onSelectionChange={navigateToTopic}
            />
          </>
        )}
      </div>
    </div>
  );
};
