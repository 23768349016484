// As this file deals with components it needs to have the tsx extension.
// We do not want to capitialize it though as it's not really a component itself.
// eslint-disable-next-line filename-rules/match
import { FC, Ref, forwardRef } from "react";

// The Tab and TabPanel components expect children to be straight jsx.  As we
// are using custom components we we need to wrap them in a forwardRef to make
// them compatible. See: https://github.com/tailwindlabs/headlessui/issues/1512
export const forwardRefComponent = (
  Element: FC,
  divProps?: React.HTMLAttributes<HTMLDivElement>,
) => {
  const compFunction = (props, ref) => (
    <div ref={ref as Ref<HTMLDivElement>} {...divProps} {...props}>
      <Element />
    </div>
  );

  compFunction.displayName = "ForwardableComponent";
  return forwardRef(compFunction);
};
