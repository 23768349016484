import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const TextBooksIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M1 9.33301H5"
      stroke="#676767"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M5 9.33301H9"
      stroke="#676767"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M5 1.33301H1V13.333H5V1.33301Z"
      stroke="#676767"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M9 1.33301H5V13.333H9V1.33301Z"
      stroke="#676767"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M10.7539 9.43038L14.6572 8.55371"
      stroke="#676767"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M12.9028 0.749L9 1.625L11.628 13.3334L15.5308 12.4574L12.9028 0.749Z"
      stroke="#676767"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
