import clsx from "clsx";
import { FC, MouseEvent } from "react";

import { COURSE_ROLE_TO_ICON, ExtendedOrgRoleType, ORG_ROLE_TO_ICON } from "./UserRoleIcons";

import { CourseRoleType, OrgRoleType } from "components/server-types";
import { Trans, t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { IconText } from "mds/components/IconText";
import { Menu, MenuItem, SubMenu } from "mds/components/Menu";
import { Tooltip } from "mds/components/Tooltip";
import {
  CircleDisabledIcon,
  DotMenuVerticalIcon,
  LongFormPencilIcon,
  PersonSingleIcon,
  TrashDeleteIcon,
} from "mds/icons";
import { trackEvent } from "utils/amplitude";

const ORG_ROLES = ["admin", "other"] as OrgRoleType[];

const COURSE_ROLES = ["student", "instructor"] as CourseRoleType[];

export type OrgUserOptionsMenuProps = {
  onClickDelete?: () => void;
  onChangeRole?: (newRole: OrgRoleType) => void;
  currentRole?: ExtendedOrgRoleType;
};

export const OrgUserOptionsMenu: FC<OrgUserOptionsMenuProps> = ({
  onClickDelete,
  onChangeRole,
  currentRole,
}) => {
  const MenuButton = (
    <Button
      kind="tertiary"
      size="s"
      title={t("common.options")}
      onClick={(e: MouseEvent) => {
        e.stopPropagation();
      }}
    >
      <DotMenuVerticalIcon />
    </Button>
  );

  return (
    <Menu
      direction="bottom"
      menuButton={MenuButton}
      menuClassName="min-w-[180px]"
      onClick={(e) => e.stopPropagation()}
    >
      {onChangeRole && currentRole && (
        <SubMenu
          label={
            <IconText
              iconStart={ORG_ROLE_TO_ICON[currentRole]}
              text={t("user_options_menu.edit_role")}
            />
          }
          position="initial"
          withArrow
        >
          {ORG_ROLES.map((role) => (
            <MenuItem
              className={clsx({ selected: currentRole === role })}
              key={role}
              onClick={() => onChangeRole(role)}
            >
              <IconText iconStart={ORG_ROLE_TO_ICON[role]} text={t(`roles.org_${role}`)} />
            </MenuItem>
          ))}
        </SubMenu>
      )}

      {onClickDelete && (
        <MenuItem className="icon-red" kind="destructive" onClick={onClickDelete}>
          <IconText iconStart={<TrashDeleteIcon />} text={t("common.delete")} />
        </MenuItem>
      )}
    </Menu>
  );
};

export type CourseUserOptionsMenuProps = {
  onClickDisable?: () => void;
  onChangeRole?: (newRole: CourseRoleType) => void;
  onImpersonate?: () => void;
  currentRole?: CourseRoleType;
  email?: string;
  createdInLms?: boolean;
  is_disabled?: boolean;
};

export const CourseUserOptionsMenu: FC<CourseUserOptionsMenuProps> = ({
  onClickDisable,
  onChangeRole,
  onImpersonate,
  currentRole,
  email,
  createdInLms,
  is_disabled,
}) => {
  const MenuButton = (
    <Button
      kind="tertiary"
      size="s"
      title={t("common.options")}
      onClick={(e: MouseEvent) => {
        e.stopPropagation();
      }}
    >
      <DotMenuVerticalIcon />
    </Button>
  );

  const LmsManagedTooltip = <Tooltip content={t("user_options_menu.lms_managed_user")} />;

  return (
    <Menu
      direction="bottom"
      menuButton={MenuButton}
      menuClassName="min-w-[180px]"
      onClick={(e) => e.stopPropagation()}
    >
      {email && (
        <MenuItem
          className="h-auto"
          href={`mailto:${email}`}
          target="_blank"
          onClick={() => {
            trackEvent("User Options - Send email", {
              eventCategory: "Button press",
              userEmail: email,
            });
          }}
        >
          <IconText
            iconStart={<LongFormPencilIcon />}
            text={
              <Trans
                components={{ 1: <div className="block" /> }}
                i18nKey="user_options_menu.email_user"
                values={{ email }}
              >
                {`Email this user <1>({{email}})</1>`}
              </Trans>
            }
          />
        </MenuItem>
      )}

      {onImpersonate && (
        <MenuItem onClick={onImpersonate}>
          <IconText
            iconStart={<PersonSingleIcon />}
            text={t("user_options_menu.impersonate_user")}
          />
        </MenuItem>
      )}

      {onChangeRole && currentRole && (
        <SubMenu
          className={clsx({ disabled: createdInLms })}
          disabled={createdInLms}
          label={
            <div className="flex w-full items-center justify-between gap-1">
              <IconText
                iconStart={COURSE_ROLE_TO_ICON[currentRole]}
                text={t("user_options_menu.edit_role")}
              />
              {createdInLms && LmsManagedTooltip}
            </div>
          }
          position="initial"
          withArrow={!createdInLms}
        >
          {COURSE_ROLES.map((role) => (
            <MenuItem
              className={clsx({ selected: currentRole === role })}
              key={role}
              onClick={() => onChangeRole(role)}
            >
              <IconText iconStart={COURSE_ROLE_TO_ICON[role]} text={t(`roles.course_${role}`)} />
            </MenuItem>
          ))}
        </SubMenu>
      )}

      {onClickDisable && (
        <MenuItem
          className={clsx("flex w-full items-center justify-between", {
            "icon-red": !is_disabled,
            disabled: createdInLms,
          })}
          disabled={createdInLms}
          kind={!is_disabled ? "destructive" : undefined}
          onClick={onClickDisable}
        >
          <IconText
            iconStart={!is_disabled ? <CircleDisabledIcon /> : <PersonSingleIcon />}
            text={
              is_disabled
                ? t("user_options_menu.enable_user_menu_item")
                : t("user_options_menu.disable_user_menu_item")
            }
          />
          {createdInLms && LmsManagedTooltip}
        </MenuItem>
      )}
    </Menu>
  );
};
