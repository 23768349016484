import { FC } from "react";
import { useSearchParams } from "react-router-dom";

import { MOBILE_OVERLAY_ROUTE_QUERY_PARAM } from "components/constants";
import { CourseBreadcrumbs } from "components/header/CourseBreadcrumbs";
import { SIDEBAR_PANEL_ICONS } from "components/materials/sidebar/ContentSidebar";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { ListItem } from "mds/components/ListItem";
import { TextBooksIcon } from "mds/icons";
import { useAppDispatch, useAppSelector } from "store/index";
import {
  selectCanAuthorCourse,
  selectCourseCodes,
  selectCurrentCourse,
  selectCurrentOrg,
} from "store/selectors";
import { trackEvent } from "utils/amplitude";
import { logoutAndClearCaches } from "utils/auth";
import { courseHomeUrl } from "utils/urls";

export const MobileMainMenu: FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const org = useAppSelector(selectCurrentOrg);
  const course = useAppSelector(selectCurrentCourse);
  const courseCodes = useAppSelector(selectCourseCodes);
  const courseCode = courseCodes.find((cc) => cc.id === course.course_code_id);

  // Generates a link that sets `overlay=<route>` in the URL query params,
  // but otherwise preserves the current URL. Returns the new URL.
  const mobileOverlayRouteLink = (route: string | null, baseUrl?: string): string => {
    const params = new URLSearchParams(searchParams);
    if (route) {
      params.set(MOBILE_OVERLAY_ROUTE_QUERY_PARAM, route);
    } else {
      params.delete(MOBILE_OVERLAY_ROUTE_QUERY_PARAM);
    }
    return `${baseUrl || window.location.pathname}?${params.toString()}`;
  };

  return (
    <div className="flex h-full flex-col justify-between bg-white">
      <div>
        <div className="text-xs text-black-tint-55">
          {org && <div className="mb-1">{org.name}</div>}
          <div className="flex items-center gap-2">
            <span>{course.title}</span>
            {courseCode && <span className="whitespace-nowrap">({courseCode?.title})</span>}
          </div>
        </div>
        <div className="mt-4">
          <ListItem kind="primary" to={mobileOverlayRouteLink(null, courseHomeUrl(course.id))}>
            <div className="flex items-center gap-4">
              <TextBooksIcon />
              <span>{t("main_nav.home")}</span>
            </div>
          </ListItem>
          <ListItem kind="primary" to={mobileOverlayRouteLink("planner")}>
            <div className="flex items-center gap-4">
              <SIDEBAR_PANEL_ICONS.Planner />
              <span>{t("content_sidebar.title.Planner")}</span>
            </div>
          </ListItem>
          <ListItem kind="primary" to={mobileOverlayRouteLink("ai")}>
            <div className="flex items-center gap-4">
              <SIDEBAR_PANEL_ICONS.AiAssistant />
              <span>{t("content_sidebar.title.AiAssistant")}</span>
            </div>
          </ListItem>
          <ListItem kind="primary" to={mobileOverlayRouteLink("profile")} lastItem>
            <div className="flex items-center gap-4">
              <SIDEBAR_PANEL_ICONS.Profile />
              <span>{t("content_sidebar.title.Profile")}</span>
            </div>
          </ListItem>
          <ListItem kind="primary" to={mobileOverlayRouteLink("help")}>
            <div className="flex items-center gap-4">
              <SIDEBAR_PANEL_ICONS.HelpCenter />
              <span>{t("content_sidebar.title.HelpCenter")}</span>
            </div>
          </ListItem>
          {canAuthorCourse && (
            <>
              <ListItem kind="primary" to={mobileOverlayRouteLink("settings")}>
                <div className="flex items-center gap-4">
                  <SIDEBAR_PANEL_ICONS.Settings />
                  <span>{t("content_sidebar.title.Settings")}</span>
                </div>
              </ListItem>
              <ListItem kind="primary" to={mobileOverlayRouteLink("users")}>
                <div className="flex items-center gap-4">
                  <SIDEBAR_PANEL_ICONS.Users />
                  <span>{t("content_sidebar.title.Users")}</span>
                </div>
              </ListItem>
            </>
          )}
        </div>
        {/* TODO: Only show course selector if multiple courses available */}
        <div className="mt-4">
          <h3>Select Course</h3>
          <CourseBreadcrumbs onlyShowCourseSelect={false} view="main" enableCourseSelect />
        </div>
      </div>

      {/* TODO: Lots of mobile buttons are full-width, we should have a prop for that */}
      <div>
        <Button
          className="my-4 w-full !max-w-full"
          kind="secondary"
          onClick={() => {
            trackEvent("Mobile menu - Logout", {
              eventCategory: "Button press",
            });
            logoutAndClearCaches(dispatch);
          }}
        >
          {t("profile_panel.logout")}
        </Button>
      </div>
    </div>
  );
};
