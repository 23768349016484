import { OrgUsersSidebarPanel } from "../sidebar/OrgUsersSidebarPanel";
import { UserProfileSidebarPanel } from "../sidebar/UserProfileSidebarPanel";

import { AdminSettingsSidebarPanel } from "./settings/AdminSettingsSidebarPanel";

import { AIChatPanel } from "components/ai/AIChatPanel";
import { ContentSidebar } from "components/materials/sidebar/ContentSidebar";
import { HelpCenterSidebarPanel } from "components/materials/sidebar/HelpCenterSidebarPanel";

export const OrgSidebar = () => {
  /* TODO: needs mobile design and resulting mobile handling */
  return (
    <ContentSidebar
      AiAssistantPanel={<AIChatPanel context="org" />}
      context="org"
      HelpCenterPanel={<HelpCenterSidebarPanel />}
      ProfilePanel={<UserProfileSidebarPanel />}
      SettingsPanel={<AdminSettingsSidebarPanel />}
      UsersPanel={<OrgUsersSidebarPanel />}
    />
  );
};
