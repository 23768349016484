import { toastCreateOperationCatcher, toastSuccessMessage } from "./alerts";

import { serverBulkCreateSubmissions } from "api/api-server";
import { PageType } from "components/server-types";
import { t } from "i18n/i18n";

export const useSubmissions = (page: PageType) => {
  const createSubmissions = () => {
    return serverBulkCreateSubmissions(page.id)
      .then(() => toastSuccessMessage(t("success.toasts.submission_success_other")))
      .catch(toastCreateOperationCatcher("submission"));
  };

  return { createSubmissions };
};
