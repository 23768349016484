import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";

import {
  groupAccessIdFromGroupId,
  studentAccessIdFromUserId,
} from "../../../worksheets/shared/access-id";
import { createId } from "../../../worksheets/shared/utils";
import { AISettings, AISettingsType, AI_DEFAULT_SETTINGS } from "../AISettings";
import { assessmentSystemPrompt } from "../assessment-query";

import { DownloadCSVButton, GenerateCSVButton } from "./AIAssessmentButtons";
import {
  AIAssessmentComparisonType,
  csvObjUrlFromAiComparisonData,
  generateAiAssessmentsForAllAssessedSubmissions,
} from "./assessment-csv";

import { PageLink } from "components/materials/course/assessment/PageLink";
import {
  useFetchAssessmentBaseData,
  useFetchAssessmentData,
} from "components/materials/course/assessment/assessment-data";
import { isGroupPage } from "components/materials/page/groups/helpers";
import { Button } from "mds/components/Button";
import { OutcomeChip } from "mds/components/OutcomeChip";
import { Tooltip } from "mds/components/Tooltip";
import { PersonSingleIcon, RefreshIcon, RobotAIIcon, TrashDeleteIcon } from "mds/icons";
import { store, storeApi, useAppSelector } from "store/index";
import {
  selectCourseUsers,
  selectCurrentCourseId,
  selectPages,
  selectSubmissions,
} from "store/selectors";
import { courseOutcomesUrl } from "utils/urls";

type AITestItemType = {
  id: string;
  loading: boolean;
  data: AIAssessmentComparisonType[];
  settings: AISettingsType;
  date: string;
};

const LOCAL_STORAGE_KEYS = {
  SETTINGS: "ai-dashboard-settings-v2",
  ITEMS: "ai-dashboard-items-v2",
};

const getCourseSpecificKey = (key: string, courseId: string) => `${key}-${courseId}`;

const defaultSettings = {
  ...AI_DEFAULT_SETTINGS,
  systemPrompt: assessmentSystemPrompt,
};

/**
 * This view allows generating CSV files for AI assessment data on the current course data.
 * For each generated assessment, it keeps track of the system prompt used and the result data,
 * allowing downloading the CSV file for each result.
 *
 * The system prompt can be modified and the assessment can be re-run.
 * The list of items also shows simple success metrics for each run.
 */
export const AIDashboardView: FC = () => {
  const courseId = useAppSelector(selectCurrentCourseId);
  const [items, setItems] = useLocalStorage<AITestItemType[]>(
    getCourseSpecificKey(LOCAL_STORAGE_KEYS.ITEMS, courseId),
    [],
  );
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [settings, setSettings] = useLocalStorage<AISettingsType>(
    getCourseSpecificKey(LOCAL_STORAGE_KEYS.SETTINGS, courseId),
    defaultSettings,
  );
  const isAnyLoading = items.some((item) => item.loading);
  const selectedItem = items.find((item) => item.id === selectedId) || null;

  useFetchAssessmentBaseData();
  useFetchAssessmentData();

  useEffect(() => {
    storeApi.page_outcomes.list({ page__course_id: courseId });
    storeApi.page_group_users.list({ course_user__course_id: courseId });
  }, [courseId]);

  const onClearAllItems = () => {
    const confirm = window.confirm("Are you sure you want to clear all items?");
    if (!confirm) {
      return;
    }
    setItems([]);
  };

  const onResetSettings = () => {
    setSettings(defaultSettings);
  };

  const onRunAssessment = async () => {
    const state = store.getState();
    const newItem: AITestItemType = {
      id: createId(),
      loading: true,
      data: [],
      settings,
      date: new Date().toISOString(),
    };
    const newItems = [...items, newItem];
    setItems([...newItems]);
    const result = await generateAiAssessmentsForAllAssessedSubmissions(state, settings.aiModel);
    newItem.loading = false;
    newItem.data = result;
    setItems([...newItems]);
  };

  return (
    <div className="">
      <div className="flex items-center justify-between">
        <h3>AI Dashboard</h3>
        <div className="flex items-center gap-2">
          <AISettings setSettings={setSettings} settings={settings} />
          <GenerateCSVButton disabled={isAnyLoading} onClick={onRunAssessment} />
          <Button
            className="gap-2"
            disabled={isAnyLoading}
            kind="secondary"
            size="xs"
            onClick={onClearAllItems}
          >
            <TrashDeleteIcon />
            Clear Items
          </Button>
          <Button
            className="gap-2"
            disabled={isAnyLoading}
            kind="secondary"
            size="xs"
            onClick={onResetSettings}
          >
            <RefreshIcon />
            Reset Prompts
          </Button>
        </div>
      </div>
      <div className="my-4">
        {items.map((item) => (
          <ItemHeader
            item={item}
            key={item.id}
            selected={selectedItem && selectedItem.id === item.id}
            onDelete={() => {
              // TODO: Localize this
              const confirm = window.confirm("Are you sure you want to delete this item?");
              if (!confirm) {
                return;
              }
              const newItems = items.filter((i) => i.id !== item.id);
              setItems(newItems);
            }}
            onSelect={() =>
              selectedItem && selectedItem.id === item.id
                ? setSelectedId(null)
                : setSelectedId(item.data?.length ? item.id : null)
            }
          />
        ))}
      </div>
      <div className="mt-8 overflow-scroll">
        {selectedItem ? (
          <ItemTable item={selectedItem} />
        ) : (
          <div className="flex items-center justify-center p-6">
            {items.length > 0 ? (
              <div>Select an item to view the CSV</div>
            ) : (
              <div>Run a test to get started</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const ItemHeader: FC<{
  item: AITestItemType;
  onSelect: () => void;
  selected?: boolean;
  onDelete?: () => void;
}> = ({ item, onSelect, onDelete, selected }) => {
  if (!item || item.loading || !item.data?.length) {
    return (
      <div
        className={clsx("my-2 flex items-center justify-between border p-4 hover:bg-blue-tint-90")}
      >
        <div>Loading...</div>

        <Button kind="destructive" size="xs" iconOnly onClick={() => onDelete()}>
          <TrashDeleteIcon />
        </Button>
      </div>
    );
  }

  return (
    <div
      className={clsx("my-2 cursor-pointer border p-4 hover:bg-blue-tint-90", {
        "border-blue-tint-20 bg-blue-tint-95": selected,
      })}
      onClick={() => onSelect()}
    >
      <div className="flex items-center justify-between">
        <h4>{new Date(item.date).toLocaleString()}</h4>
        <div className="flex items-center gap-2">
          <ScoreBar item={item} />
          <DownloadCSVButton
            downloadLink={csvObjUrlFromAiComparisonData(item.data)}
            filename={`AI_Assessment_${new Date(item.date).toISOString()}.csv`}
          />
          <Button kind="destructive" size="xs" iconOnly onClick={() => onDelete()}>
            <TrashDeleteIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};

const ScoreBar: FC<{ item: AITestItemType; withPrompt?: boolean }> = ({ item, withPrompt }) => {
  const totalRowCount = item.data.length;
  const acceptedRows = item.data.filter((row) => !row.ai_refused);

  const getRowMatch = (within: number) =>
    acceptedRows.filter((row) => {
      const humanScores = row.outcomes.map((o) => o.score);
      const aiScores = row.outcomes.map((o) => o.ai_score);

      const match = humanScores.every((score, i) => {
        const hasHumanScore = typeof score === "number";
        const aiScore = aiScores[i];
        const hasAIScore = typeof aiScore === "number";
        if (!hasHumanScore && !hasAIScore) {
          return true;
        }
        if (hasHumanScore && hasAIScore) {
          return Math.abs(score - aiScore) <= within;
        }
        return false;
      });
      return match;
    }).length;

  // How many of the human scores match directly with the AI scores
  const withinZero = getRowMatch(0);

  // How many of the human scores are within one point of the AI scores
  const withinOne = getRowMatch(1);

  const withinZeroScore = Math.floor((withinZero / acceptedRows.length) * 100);
  const withinOneScore = Math.floor((withinOne / acceptedRows.length) * 100);
  const refusalRate = Math.floor(((totalRowCount - acceptedRows.length) / totalRowCount) * 100);

  return (
    <div className="flex items-center gap-1">
      <div className="body-xs">{withinZeroScore}% match</div>
      <div className="mx-1 h-4 border-l border-black-tint-70" />
      <div className="body-xs">{withinOneScore}% close</div>
      <div className="mx-1 h-4 border-l border-black-tint-70" />
      <div className="body-xs">{refusalRate}% refused</div>
      <div className="mx-1 h-4 border-l border-black-tint-70" />
      <div className="body-xs">{item.settings.aiModel}</div>
      {withPrompt && (
        <>
          <div className="mx-1 h-4 border-l border-black-tint-70" />
          <Tooltip
            element={<div className="body-xs rounded-lg border border-black p-1">prompt</div>}
          >
            <div className="max-w-[400px]">{item.settings.systemPrompt}</div>
          </Tooltip>
        </>
      )}
    </div>
  );
};

const ItemTable: FC<{ item: AITestItemType }> = ({ item }) => {
  const currentCourseId = useAppSelector(selectCurrentCourseId);
  const pages = useAppSelector(selectPages);
  const submissions = useAppSelector(selectSubmissions);
  const courseUsers = useAppSelector(selectCourseUsers);
  return (
    <div className="">
      <div className="flex items-center justify-between">
        <h3>Sample from {new Date(item.date).toLocaleString()}</h3>
        <ScoreBar item={item} withPrompt />
      </div>
      {item.data.map((row) => {
        const page = pages.find((p) => p.id === row.page_id);
        const submission = submissions.find((s) => s.id === row.submission_id);
        const userId = courseUsers.find((u) => u.id === submission.course_user_id)?.user_id;
        const isGrouped = isGroupPage(page);
        const accessId = isGrouped
          ? groupAccessIdFromGroupId(submission.page_group_id)
          : studentAccessIdFromUserId(userId);
        return (
          <div className="my-2 border border-black-tint-70 p-2" key={row.submission_id}>
            <div className="body-xs mb-2 flex items-center gap-3">
              <PageLink page={page} />
              <div>{accessId}</div>
            </div>
            <div className="flex">
              {[true, false].map((isHuman) => (
                <div
                  className="body-xs flex shrink-[1] grow-[1] basis-1/2 flex-col gap-2 p-2"
                  key={String(isHuman)}
                >
                  <div className="flex flex-wrap items-center gap-2">
                    {isHuman ? <PersonSingleIcon /> : <RobotAIIcon />}
                    {row.outcomes.map((o) => {
                      return (
                        <OutcomeChip
                          key={o.title}
                          score={isHuman ? o.score : o.ai_score}
                          title={o.title}
                          to={courseOutcomesUrl(currentCourseId, o.course_outcome_id)}
                        />
                      );
                    })}
                    {page.max_points > 0 && (
                      <div>
                        {(isHuman ? row.points : row.ai_points) || "?"}/{page.max_points} points
                      </div>
                    )}
                  </div>
                  <div className="body-xs">
                    {isHuman
                      ? row.comment
                      : row.ai_error
                        ? `Couldn't assess: ${row.ai_error}`
                        : row.ai_comment}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};
