import { createContext } from "react";

export type HoverableToolbarContextType = {
  isEditing: boolean;
  setIsEditing: (editing: boolean) => void;
  shouldStayOpen: boolean;
  setShouldStayOpen: (stayOpen: boolean) => void;
};

export const HoverableToolbarContext = createContext<HoverableToolbarContextType>(
  {} as HoverableToolbarContextType,
);
