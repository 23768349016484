import { FC } from "react";

import { t } from "i18n/i18n";
import { Banner } from "mds/components/Banner";

interface PageReleasedBannerProps {
  onDismiss: () => void;
}

export const PageReleasedBanner: FC<PageReleasedBannerProps> = ({ onDismiss }) => (
  <Banner className="body-m font-medium" kind="warning" onDismiss={onDismiss}>
    {t("warning.page_released")}
  </Banner>
);
