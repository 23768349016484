import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const TagIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M1 1H7.54657L14.5888 8.04223C14.7192 8.17251 14.8226 8.3272 14.8931 8.49746C14.9637 8.66772 15 8.85021 15 9.03451C15 9.21881 14.9637 9.4013 14.8931 9.57156C14.8226 9.74182 14.7192 9.8965 14.5888 10.0268L10.0268 14.5888C9.8965 14.7192 9.74182 14.8226 9.57156 14.8931C9.4013 14.9637 9.21881 15 9.03451 15C8.85021 15 8.66772 14.9637 8.49746 14.8931C8.3272 14.8226 8.17251 14.7192 8.04223 14.5888L1 7.54657V1Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M5.2085 6.61134C5.98326 6.61134 6.61134 5.98326 6.61134 5.2085C6.61134 4.43373 5.98326 3.80566 5.2085 3.80566C4.43373 3.80566 3.80566 4.43373 3.80566 5.2085C3.80566 5.98326 4.43373 6.61134 5.2085 6.61134Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
