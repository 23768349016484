import { OutcomeType } from "components/server-types";
import { t } from "i18n/i18n";
import { OptionsType } from "mds/components/TextListDropdown";
import { NestedCourseOutcomeType } from "store/selectors";

export const displayOutcomeName = (outcome: OutcomeType) => `#${outcome.title}`;

export const validateOutcomeName = (name: string) =>
  /^[\p{L}\p{N}_-]*$/u.test(name) ? [] : [t("validation.outcomes_title")];

export const outcomesToOptions = (o: OutcomeType[]) =>
  o.map((outcome) => ({ value: outcome.id, label: displayOutcomeName(outcome) })) as OptionsType;

export const courseOutcomesToOptions = (o: NestedCourseOutcomeType[]) =>
  o.map((courseOutcome) => ({
    value: courseOutcome.id,
    label: displayOutcomeName(courseOutcome.outcome),
  })) as OptionsType;

export const MAX_PAGE_OUTCOMES = 3;
