import { type FC, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useBoolean } from "usehooks-ts";

import { useCanDeleteActions } from "../../../../../utils/delete";

import { DurationDisplayBox } from "./DurationDisplayBox";
import { PageListItem } from "./PageListItem";

import { PAGE_ID_QUERY_PARAM } from "components/constants";
import { HoverableEditField } from "components/hover-widgets/EditableField";
import { HoverableToolbar } from "components/hover-widgets/HoverableToolbar";
import {
  EstimatedTimeMenu,
  TimesType,
} from "components/materials/topic/activity/detail/EstimatedTimeMenu";
import { ActivitySidebarOptionsMenu } from "components/menus/ActivitySidebarOptionsMenu";
import { ActivityPageType } from "components/server-types";
import { toastReleasePage, toastUnreleasePage } from "helpers/toasts";
import { t } from "i18n/i18n";
import { Card } from "mds/components/Card";
import { ConfirmDeletionDialog } from "mds/components/ConfirmDeletionDialog";
import { DragAndDrop, DraggableItemType } from "mds/components/DragAndDrop";
import { TextAddButton } from "mds/components/TextAddButton";
import { useOrdered } from "mds/hooks/use-ordered";
import { storeApi, useAppSelector } from "store/index";
import {
  ActivityWithPagesType,
  selectCanAuthorCourse,
  selectCurrentCourseId,
  selectIsEditModeEnabled,
  selectPageById,
} from "store/selectors";
import { isArchived, moveActivityWithinCourse, useActivityActions } from "utils/activity";
import { showComingSoonAlert, toastLocalizedOperationCatcher } from "utils/alerts";
import { useDnDItemOrdering, useLocalCopy } from "utils/collections";
import { getReleaseStatus, usePageActions } from "utils/page";
import { courseContentUrl } from "utils/urls";

const DEFAULT_TIME_ESTIMATE = 0;

type ActivityCardProps = {
  activity: ActivityWithPagesType;
  activityIndex: number;
  activities: ActivityWithPagesType[];
  moveActivityLocally: (item: DraggableItemType, toIndex: number, dropContainerId: string) => void;
  shouldDisplayActivityNumber?: boolean;
};

/* This is the card used to display Lesson and Archive activities. Additional Resources
   and Prep Work activities are displayed via AdditionalResourceList and PrepWorkActivityCard
   respectively. If you are doing an update here, you likely want to check and update the
   other components.
   TODO: We should probably integrate these components into a single ActivityCard component,
   or rename this one as we've introduced a number of bugs due to inconsistently updating
   the different components as the design evolves.
 */
export const ActivityCard: FC<ActivityCardProps> = ({
  activityIndex,
  activity,
  activities,
  moveActivityLocally,
  shouldDisplayActivityNumber = true,
}) => {
  const [searchParams] = useSearchParams();
  const pageId = searchParams.get(PAGE_ID_QUERY_PARAM);
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const courseId = useAppSelector(selectCurrentCourseId);
  const [_pages, setPages] = useLocalCopy(activity.pages);
  const selectedPage = useAppSelector((s) => selectPageById(s, pageId));
  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  const pages = useOrdered(_pages);
  const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);

  const { canDeleteActivity } = useCanDeleteActions(activity.topic_id, pages);

  const movePageLocally = useDnDItemOrdering(pages, setPages, "activity_id");
  const isActive = selectedPage?.activity_id === activity.id;
  const { saveActivity, deleteActivity, dropActivity, unarchiveActivity, archiveActivity } =
    useActivityActions();
  const { createPage, dropPage, selectPage, navigateToPage } = usePageActions();

  const {
    value: confirmationDialogIsOpen,
    setTrue: openConfirmationDialog,
    setFalse: closeConfirmationDialog,
  } = useBoolean(false);

  const activityIsArchived = isArchived(activity.category);
  const activityReleaseStatus = getReleaseStatus(pages);

  const createNewPage = () => {
    createPage("activity_page", pages, activity.id);
  };

  const onDropPage = (droppedPage: ActivityPageType) => dropPage(droppedPage, activities);

  const onActivityTitleChanged = (newTitle: string) => {
    saveActivity({ ...activity, title: newTitle });
  };

  const onActivityEstimatedTimeChanged = (newTime: number) => {
    saveActivity({ ...activity, estimated_time: newTime });
  };

  const onChangeStatus = async () => {
    // TODO: Eventually we'd like a way to make bulk updates via storeApi
    const shouldReleasePages = activityReleaseStatus !== "released";
    const length = activity.pages.length;

    try {
      await Promise.all(
        activity.pages.map((page) =>
          storeApi.pages.partial_update({
            id: page.id,
            released_at: shouldReleasePages ? new Date().toJSON() : null,
          }),
        ),
      );

      if (shouldReleasePages) {
        toastReleasePage(length);
      } else {
        toastUnreleasePage(length);
      }
    } catch (e) {
      toastLocalizedOperationCatcher("update_pages")(e);
    }
  };

  return (
    <Card className="mb-3 mt-2" isActive={isActive} small>
      <DragAndDrop
        draggable={canAuthorCourse && !isEditingTitle}
        dragItemKind="activity"
        dropContainerId={activity.category}
        dropIndex={activityIndex}
        droppable={canAuthorCourse}
        item={activity}
        onDraggedItemHover={moveActivityLocally}
        onDropItem={dropActivity}
      >
        <DragAndDrop
          dragItemKind="page"
          dropContainerId={activity.id}
          dropIndex={0}
          droppable={canAuthorCourse}
          onDraggedItemHover={movePageLocally}
          onDropItem={onDropPage}
        >
          <div className="mb-1 flex w-full items-start justify-between">
            {/* TODO: Fix: students and instructors might see different numbers because unpublished activities are skipped for students */}
            <HoverableToolbar
              className="ml-1 mr-2 flex w-full items-center justify-between"
              contentClassName="mt-0.5 bg-white"
              readOnly={!canAuthorCourse}
              reference="activity"
              disableUnlessEditMode
              showEditButton
              uncentered
              onClickAdd={canAuthorCourse ? createNewPage : undefined}
              onEditModeChange={setIsEditingTitle}
            >
              <h3
                className="semi-bold my-0 flex w-full cursor-pointer items-center"
                onClick={() => pages.length > 0 && selectPage(pages[0])}
              >
                {shouldDisplayActivityNumber && (
                  <div className="h3 mt-1 self-start pr-1">{activityIndex + 1}.</div>
                )}

                <HoverableEditField
                  className="pl-[3px]"
                  label={t("glossary.activity_title")}
                  textSize="h3"
                  value={activity.title}
                  disableUnlessEditMode
                  shouldWrap
                  onValueChanged={onActivityTitleChanged}
                />
              </h3>

              <ActivitySidebarOptionsMenu
                buttonKind="tertiary"
                currentTopicId={activity.topic_id}
                optionsMenuActions={{
                  onAddPage: createNewPage,
                  onArchive: activityIsArchived
                    ? null
                    : () => archiveActivity(activity, activities),
                  onUnarchive: activityIsArchived
                    ? () => unarchiveActivity(activity, activities)
                    : null,
                  onDelete: openConfirmationDialog,
                  isDeleteDisabled: !canDeleteActivity(activity),
                  deleteTooltipText: canDeleteActivity(activity)
                    ? undefined
                    : t("tooltip.delete_activity_has_assessments"),
                  onDuplicate: showComingSoonAlert,
                  onMoveTo: (destinationTopicId) =>
                    moveActivityWithinCourse(activity.id, destinationTopicId),
                  ...(!activityIsArchived ? { onChangeStatus } : {}),
                }}
                releaseStatus={activityReleaseStatus}
              />
            </HoverableToolbar>

            {canAuthorCourse && isEditModeEnabled && (
              <EstimatedTimeMenu
                selectedTime={(activity.estimated_time || DEFAULT_TIME_ESTIMATE) as TimesType}
                onClick={onActivityEstimatedTimeChanged}
              />
            )}

            {canAuthorCourse && !isEditModeEnabled && !!activity.estimated_time && (
              <div className="mt-2">
                <DurationDisplayBox duration={activity.estimated_time} />
              </div>
            )}
          </div>
        </DragAndDrop>

        {pages.map((page: ActivityPageType, index) => (
          <PageListItem
            containerId={activity.id}
            key={page.id}
            page={page}
            pageIndex={index}
            topicId={activity.topic_id}
            onDropPage={onDropPage}
            onMovePageLocally={movePageLocally}
            onPageClick={() =>
              navigateToPage(page, courseContentUrl(courseId, activity.topic_id, page.id))
            }
          />
        ))}
      </DragAndDrop>

      {(pages.length === 0 || isActive) && canAuthorCourse && (
        <div className="h4 -ml-1 flex items-center justify-start">
          <TextAddButton hideUnlessEditMode underlined onClick={createNewPage} />
        </div>
      )}

      <ConfirmDeletionDialog
        glossaryKey="activity"
        instanceName={activity.title}
        open={confirmationDialogIsOpen}
        onClose={closeConfirmationDialog}
        onDelete={() => deleteActivity(activity)}
      />
    </Card>
  );
};
