import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { isEqual } from "lodash";

import {
  WebsocketUserDataMessageType,
  WebsocketUserDataType,
} from "../../worksheets/shared/types/websocket-auth";
import { pageAuthorizationRemoved } from "../../worksheets/shared/utils";

import { createReplaceAllReducer } from "store/utils";

export type AuthState = {
  user_id: string;
  collaboration_jwt: string;
  websocket: WebsocketUserDataType;
  // This is mainly used to make sure we don't show 404 when we're loading
  // courses that are from a different org, and instead redirect the user.
  // We set this once at startup from the /users/me endpoint which is cross-org.
  course_to_org_id_map: Record<string, string>;
};

const initialState = (): AuthState => ({
  user_id: null,
  collaboration_jwt: null,
  course_to_org_id_map: {},
  websocket: {} as WebsocketUserDataType,
});

export const AuthStore = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Note that setting/clearing the current user will also trigger starting/stopping tracking events in Amplitude
    // if Amplitude is enabled for the current environment.
    setCurrentUser: (state, action: PayloadAction<Omit<AuthState, "websocket"> | null>) => {
      if (!action.payload) {
        state.user_id = null;
        state.collaboration_jwt = null;
        state.course_to_org_id_map = {};
        return;
      }
      state.user_id = action.payload.user_id;
      state.collaboration_jwt = action.payload.collaboration_jwt;
      state.course_to_org_id_map = action.payload.course_to_org_id_map;
    },
    // We don't set websocket data directly via client or API, instead, Collaboration/Pubsub
    // will send websocket messages to replace the websocket data when it changes in the backend.
    setWebsocketUserData: (state, action: PayloadAction<WebsocketUserDataMessageType>) => {
      const { scope, ...newData } = action.payload;
      // Since we upsert this deeply nested object, we perform a full equality check
      // to prevent re-rendering of components that depend on this state.
      if (scope === "all") {
        if (!isEqual(newData.pages, state.websocket.pages)) {
          state.websocket.pages = newData.pages as WebsocketUserDataType["pages"];
        }
      } else {
        // Individual page updates
        Object.keys(newData.pages).forEach((pageId) => {
          const authPage = newData.pages[pageId];

          if (pageAuthorizationRemoved(authPage)) {
            delete state.websocket.pages[pageId];
          } else {
            state.websocket.pages[pageId] = authPage;
          }
        });
      }

      state.websocket.user_id = newData.user_id;
      state.websocket.org_id = newData.org_id;
      state.websocket.course_id = newData.course_id;
      state.websocket.role = newData.role;
    },
  },
  extraReducers: createReplaceAllReducer<AuthState>("auth", initialState),
});

export const authStateActions = AuthStore.actions;
