import { FC, useState } from "react";

import { useOrgOutcomeListForCourse } from "../outcomes/OutcomesNavSidebar";

import { CoursePageList } from "./CoursePageList";
import { TopicList } from "./TopicList";

import { HoverableEditField } from "components/hover-widgets/EditableField";
import { HoverableToolbar } from "components/hover-widgets/HoverableToolbar";
import { displayOutcomeName } from "components/outcomes/helpers";
import type { CourseType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { OutcomeChip } from "mds/components/OutcomeChip";
import { TextAddButton } from "mds/components/TextAddButton";
import { useIsMdOrLarger } from "mds/hooks/use-responsive";
import { storeApi, useAppSelector } from "store/index";
import { selectCanAuthorCourse, selectCurrentCourse } from "store/selectors";
import { courseHomeUrl, courseOutcomesUrl, withQueryParams } from "utils/urls";

// TODO: This may be worth renaming to CourseContentView to better match the tab description, but
// the existing 'CourseContentView' component should then also be renamed.
export const CourseView: FC = () => {
  const [isEditingEmptyDescription, setIsEditingEmptyDescription] = useState(false);
  const course = useAppSelector(selectCurrentCourse);
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const outcomes = useOrgOutcomeListForCourse(course.id);
  const isSmallScreen = !useIsMdOrLarger();

  const updateCourse = (attributes: Partial<CourseType>) => {
    setIsEditingEmptyDescription(false);
    storeApi.courses.partial_update({ id: course.id, ...attributes });
  };

  return (
    <div className="bg-white pb-4">
      {canAuthorCourse && course.description === "" && !isEditingEmptyDescription ? (
        <div className="body-s my-4">
          <TextAddButton
            reference="course_description"
            onClick={() => setIsEditingEmptyDescription(true)}
          />
        </div>
      ) : (
        <div className="body-s max-w-[--page-default-max-width]">
          <HoverableToolbar
            readOnly={!canAuthorCourse}
            reference="course_description"
            showEditButton
            uncentered
          >
            <HoverableEditField
              className="text-black-tint-40"
              editingOnStart={isEditingEmptyDescription}
              label={t("glossary.course_description")}
              textSize="s"
              value={course.description}
              multiline
              onValueChanged={(description) => updateCourse({ description })}
            />
          </HoverableToolbar>
        </div>
      )}

      <div className="my-4 flex max-w-full flex-col gap-4">
        {/* Topics */}
        <TopicList canEdit={canAuthorCourse} />

        {/* Assignments */}
        <CoursePageList
          canEdit={canAuthorCourse}
          category="assignment"
          shouldTruncateList={isSmallScreen}
        />

        {/* Course Resources */}
        <CoursePageList
          canEdit={canAuthorCourse}
          category="course_resource"
          shouldTruncateList={isSmallScreen}
        />

        {/* Instructor Workspace */}
        {canAuthorCourse && (
          <CoursePageList
            canEdit={canAuthorCourse}
            category="instructor_workspace"
            shouldTruncateList={isSmallScreen}
          />
        )}
      </div>

      {isSmallScreen && outcomes.length > 0 && (
        <div className="mt-4">
          <h3 className="flex items-center justify-between text-black-tint-40">
            {t("glossary.outcomes")}
          </h3>
          <div className="my-2 flex flex-wrap items-center">
            {outcomes.map((outcome) => (
              <OutcomeChip
                className="mx-2 my-1"
                key={outcome.id}
                title={displayOutcomeName(outcome)}
                to={courseOutcomesUrl(course.id, outcome.id)}
              />
            ))}
          </div>

          {canAuthorCourse && (
            <div className="space-y-2">
              <Button
                kind="secondary"
                to={withQueryParams(courseHomeUrl(course?.id), { tab: "assessments" })}
                mobileFullWidth
              >
                {t("course.view_assessments")}
              </Button>

              <Button
                kind="secondary"
                to={withQueryParams(courseHomeUrl(course?.id), { tab: "engagement" })}
                mobileFullWidth
              >
                {t("course.view_engagement")}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
