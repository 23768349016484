import clsx from "clsx";
import React, { MouseEvent, memo } from "react";
import { Link, type To } from "react-router-dom";

import "./ListItem.scss";

export interface ListItemProps {
  kind?: "primary" | "secondary";
  isSelected?: boolean;
  className?: string;
  children?: React.ReactNode;
  label?: string;
  to?: To;
  onClick?: (event: MouseEvent) => void;
  grabbable?: boolean;
  lastItem?: boolean;
  title?: string;
}

/**
 * A ListItem provides the basic styling for single item in a list or navigation menu.
 */
export const ListItem = memo(function ListItem({
  kind = "secondary",
  isSelected = false,
  lastItem = false,
  className: customClassName,
  children,
  label,
  to,
  onClick,
  grabbable,
  title,
}: ListItemProps) {
  const className = clsx(
    "list-item-component flex items-center",
    `list-item-component-${kind}`,
    lastItem && "last-item",
    isSelected && "selected",
    grabbable ? "grabbable" : onClick ? "cursor-pointer" : "cursor-default",
    customClassName,
  );
  const props = {
    "aria-label": label,
    role: "listitem",
    onClick,
    className,
    title,
  };
  if (to) {
    return (
      <div {...props}>
        <Link className="flex w-full min-w-12 items-center" to={to}>
          {children}
        </Link>
      </div>
    );
  }
  return <div {...props}>{children}</div>;
});
