import {
  createAppSelector,
  selectCourseUsers,
  selectCurrentCourseId,
  selectCurrentUserId,
  selectOrgUsers,
  selectOrgs,
} from "./base";
import { selectIsStudentPreview } from "./view";

import { CourseRoleType, OrgRoleType } from "components/server-types";
import { RootState } from "store";
import { isMpOrg } from "utils/urls";

// TODO: This should be made more specific, e.g. canReadPage, canWritePage, etc., to avoid
// re-renders when the objects gets updated without causing any actual change.
export const selectCollaborationPermissionsForDoc = (state: RootState, docId: string) =>
  state.auth.websocket.pages?.[docId];

export const selectCurrentCourseRole = createAppSelector(
  [selectCourseUsers, selectCurrentUserId, selectCurrentCourseId],
  (courseUsers, userId, courseId): CourseRoleType => {
    const courseUser = courseUsers.find((cu) => cu.course_id === courseId && cu.user_id === userId);
    return courseUser?.role;
  },
);

export const selectCurrentOrgRole = createAppSelector(
  [selectOrgUsers, selectCurrentUserId],
  (orgUsers, userId): OrgRoleType => {
    const orgUser = orgUsers.find((ou) => ou.user_id === userId);
    return orgUser?.role;
  },
);

export const selectCurrentUserIsMPAdmin = createAppSelector(
  [selectCurrentUserId, (state) => state.users, selectIsStudentPreview],
  (userId, users, isStudentPreview) => !isStudentPreview && users[userId]?.is_superuser,
);

export const selectCanAuthorCourse = createAppSelector(
  [
    selectCurrentUserIsMPAdmin,
    selectIsStudentPreview,
    selectCurrentCourseRole,
    selectCurrentOrgRole,
  ],
  (
    isMpAdmin,
    isStudentPreview: boolean,
    courseRole: CourseRoleType,
    orgRole: OrgRoleType,
  ): boolean => {
    if (isStudentPreview) {
      return false;
    }
    if (courseRole) {
      return courseRole !== "student";
    }
    if (orgRole) {
      return orgRole !== "other";
    }
    return isMpAdmin;
  },
);

export const selectIsMPOrg = createAppSelector([selectOrgs], (orgs) =>
  isMpOrg(orgs.find((org) => org.current)),
);

export const selectCanAuthorOrg = createAppSelector(
  [selectCurrentOrgRole, selectCurrentUserIsMPAdmin],
  (orgRole, isMpAdmin) => {
    if (orgRole) {
      return orgRole !== "other";
    }
    return isMpAdmin;
  },
);
