import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { ArrowDownIcon } from "mds/icons";
import { showComingSoonAlert } from "utils/alerts";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CSVExportButton = ({ data }: { data?: unknown }) => {
  const onDownload = () => {
    showComingSoonAlert();
  };
  return (
    <Button className="h6 gap-2" kind="tertiary" onClick={onDownload}>
      <div>{t("common.export")}</div>
      <ArrowDownIcon />
    </Button>
  );
};
