import "./ProjectorScroller.scss";

import clsx from "clsx";
import { throttle } from "lodash";
import { FC, HTMLAttributes, useCallback, useEffect, useRef } from "react";

import {
  WindowFrameMessage,
  WindowMessages,
  addInstructorMessageListener,
  createScrollMessageWithBlockMarker,
  removeInstructorMessageListener,
  sendMessageToProjectorView,
} from "./projector-messaging";

import { t } from "i18n/i18n";
import { useAppSelector } from "store/index";
import { selectIsOutcomesSidebarOpen, selectIsSidebarOpen } from "store/selectors";
import { FeatureStatus, usePresentation } from "utils/presentation";

export const SCALING_FACTOR = 1.4;
export const SCROLL_DEBOUNCER_MS = 100;

type ProjectorScrollerProps = HTMLAttributes<HTMLDivElement> & {
  shouldScroll?: boolean;
};

// Handles syncing the scroll position of a div to the div container we have
// in the projector view.  Note that the header content at the start of this div
// will differ from what we show in the projector view.  So we need to calculate
// the offset height of the header content to make sure we are scrolling to the
// same position as the projector view.  Note: we are currently not scaling
// the width as this complicates the layout with the sidebar.  We hope the content
// remains the same size as it should be 660px wide for instructor view and 20%
// more for the projector view.
export const ProjectorScroller: FC<ProjectorScrollerProps> = ({
  children,
  className,
  shouldScroll = true,
  id,
  ...props
}) => {
  const scrollDivRef = useRef<HTMLDivElement | null>(null);
  const projectorFrameRef = useRef<WindowFrameMessage | null>(null);
  const isOutcomesSidebarOpen = useAppSelector(selectIsOutcomesSidebarOpen);

  const shouldSyncScrollRef = useRef(false);
  const fullScreen = !useAppSelector(selectIsSidebarOpen);
  const { featureStatus, isPresenting, hasProjectorView } = usePresentation();
  const isFeaturingRef = useRef(false);
  const isFeaturing = featureStatus !== FeatureStatus.NONE;
  isFeaturingRef.current = isFeaturing;

  const shouldSyncScrolling = shouldScroll && isPresenting && hasProjectorView;
  const shouldShowOverlay = shouldSyncScrolling && !isFeaturing;
  shouldSyncScrollRef.current = shouldSyncScrolling;

  const postScrollMessage = useCallback(() => {
    if (!projectorFrameRef.current) {
      sendMessageToProjectorView({ type: WindowMessages.REQUEST_FRAME });
      return;
    }

    if (scrollDivRef.current) {
      // Send the scroll position to the projector with the scaling factor applied
      const scrollMessage = createScrollMessageWithBlockMarker(scrollDivRef.current);

      sendMessageToProjectorView(scrollMessage);
    }
  }, []);

  // linter seems to be confused by the throttle function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(
    throttle(postScrollMessage, SCROLL_DEBOUNCER_MS, {
      trailing: true,
      leading: true,
    }),
    [postScrollMessage],
  );

  const handleResize = () => {
    if (projectorFrameRef.current && shouldSyncScrollRef.current && !isFeaturingRef.current) {
      scrollDivRef.current.style.flex = `0 0 ${(projectorFrameRef.current.viewport.height / SCALING_FACTOR).toString()}px`;
    }
  };

  // Need to reset scrolling after stopping featuring
  useEffect(() => {
    if (!isFeaturing && shouldSyncScrolling && scrollDivRef.current) {
      postScrollMessage();
    } else if (!isFeaturing) {
      sendMessageToProjectorView({
        type: WindowMessages.PAGE_SCROLL,
        scrollX: 0,
        scrollY: 0,
      });
    }
  }, [isFeaturing, shouldSyncScrolling, postScrollMessage]);

  useEffect(() => {
    if (!scrollDivRef.current) {
      return;
    }

    const scrollingDiv = scrollDivRef.current;
    handleResize();

    if (shouldSyncScrolling) {
      scrollingDiv.addEventListener("scroll", handleScroll);
    } else {
      // Remove any custom height we added so it goes back to the browser default
      scrollingDiv.style.flex = "1 1 auto";

      scrollingDiv.removeEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollingDiv) {
        scrollingDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, [shouldSyncScrolling, isPresenting, handleScroll]);

  useEffect(() => {
    const handleFrameMessage = (message: MessageEvent<WindowFrameMessage>) => {
      if (message.data.type === WindowMessages.WINDOW_FRAME && scrollDivRef.current) {
        projectorFrameRef.current = message.data;

        handleResize();
      }
    };

    addInstructorMessageListener(handleFrameMessage);
    window.addEventListener("resize", handleResize);

    return () => {
      removeInstructorMessageListener(handleFrameMessage);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {shouldShowOverlay && (
        <>
          <div
            className={clsx("projector-overlay-wall projector-overlay-left", {
              "full-screen": fullScreen,
              "secondary-open": isOutcomesSidebarOpen,
            })}
          />
          <div
            className={clsx("projector-overlay-wall projector-overlay-right", {
              "full-screen": fullScreen,
              "secondary-open": isOutcomesSidebarOpen,
            })}
          />
        </>
      )}

      <div
        // Note: we need the relative here to ensure our offset calculations can start
        // from this div when scrolling.
        className={clsx("projector-scroller", className)}
        id={id || "page-scroller"}
        ref={scrollDivRef}
        {...props}
      >
        {children}
      </div>

      {shouldShowOverlay && (
        <div
          className={clsx("projector-overlay-bottom", {
            "full-screen": fullScreen,
            "secondary-open": isOutcomesSidebarOpen,
          })}
        >
          {t("presentation.overlay_text")}
        </div>
      )}
    </>
  );
};
