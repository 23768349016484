export enum AnalyticsMessageDatasetType {
  COURSE_USER_ACTIVITY = "course-user-activity",
}

export type CourseUserActivityType = {
  courseId: string;
  pageId: string;
  userId: string;
  active: "0" | "1";
};

export type BaseAnalyticsMessage = {
  type: "analytics-update";
  datasetType: AnalyticsMessageDatasetType;
  targetRole: "all" | "student" | "staff";
  targetOrgId?: string;
  error?: unknown;
};

export type UserActivityMessage = BaseAnalyticsMessage &
  CourseUserActivityType & {
    datasetType: AnalyticsMessageDatasetType.COURSE_USER_ACTIVITY;
    removed?: "0" | "1";
  };

export type AnalyticsMessageType = UserActivityMessage;
