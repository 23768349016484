import { Quill } from "react-quill-new";

// There is no type for Link in quill. Suppressing the error for now.
const Link = Quill.import("formats/link");

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class CustomLinkSanitizer extends (Link as any) {
  static sanitize(url: string): string {
    // Run default sanitize method from Quill.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    const sanitizedUrl: string = super.sanitize(url) as string;

    // Check for relative links that need to be made into an absolute link. We want to avoid matching:
    // - Cases where it is another protocol (has a : like mailto:),
    // - Cases where it is an intentional relative link with a single /.
    // - Cases where it is an absolute link (these all have a : in them)
    // It is key to check that it starts and ends with this regex, otherwise it can incorrectly match with partials
    // e.g. if it was an intentional relative link.
    // See examples of the regex with test cases at: https://regex101.com/r/h8PZdE/1
    const match = sanitizedUrl.match(/(^[^/#][^:]*)$/);
    if (match) {
      return `https://${sanitizedUrl}`;
    }
    return sanitizedUrl;
  }
}
