import { FC, useContext } from "react";

import { t } from "i18n/i18n";
import { ToggleSwitch } from "mds/components/ToggleSwitch";
import { BypassReleaseWarningContext } from "providers/BypassReleaseWarningProvider";
import { useAppDispatch, useAppSelector } from "store/index";
import { selectIsEditModeEnabled } from "store/selectors";
import { localStateActions } from "store/slices/local";
import { trackEvent } from "utils/amplitude";
import { usePresentation } from "utils/presentation";

export const EditModeButton: FC = () => {
  const dispatch = useAppDispatch();
  const { resetWarning } = useContext(BypassReleaseWarningContext);
  const { inPresentationMode } = usePresentation();
  const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);

  const toggleEditMode = () => {
    trackEvent("Header - Edit mode toggle", {
      eventCategory: "Button press",
      isEnabled: !isEditModeEnabled,
    });
    dispatch(localStateActions.toggleEditMode());
    resetWarning();
  };

  return (
    <ToggleSwitch
      selected={isEditModeEnabled}
      showDataSelection={!inPresentationMode}
      onClick={toggleEditMode}
    >
      {t("main_header.edit_mode")}
    </ToggleSwitch>
  );
};
