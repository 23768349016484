import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const CircleCrossIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M10.5455 5.45459L5.45459 10.5455"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M10.5455 10.5455L5.45459 5.45459"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
