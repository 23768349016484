import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const PencilEditIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M12.6664 7.06671L8.93311 3.33337"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M5.66667 14.0667L1 15L1.93333 10.3333L11.2667 1L15 4.73333L5.66667 14.0667Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
