import { init } from "@paralleldrive/cuid2";

import { PagePermissionType, PageRemovalPermissionType } from "./types/websocket-auth";

export const DEFAULT_ID_SIZE = 12;

export const createId = init({ length: DEFAULT_ID_SIZE });

export const pageAuthorizationRemoved = (
  authPage: PagePermissionType | PageRemovalPermissionType,
): authPage is PageRemovalPermissionType => {
  return "visible" in authPage && authPage.visible === false;
};

export const truncate = (str: unknown, n = 20) => {
  if (!str || typeof str !== "string") {
    return "<empty>";
  }
  return `${str.slice(0, n)}${str.length > n ? "..." : ""}`;
};
