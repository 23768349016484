import clsx from "clsx";
import { FC, ReactNode } from "react";
import { useSearchParams } from "react-router-dom";

import { PageListItem } from "./PageListItem";

import { PAGE_ID_QUERY_PARAM } from "components/constants";
import { HoverableToolbar } from "components/hover-widgets/HoverableToolbar";
import { ActivityPageType, ActivityType, PageCategory, PageType } from "components/server-types";
import { Card } from "mds/components/Card";
import { DragAndDrop } from "mds/components/DragAndDrop";
import { TextAddButton } from "mds/components/TextAddButton";
import { useOrdered } from "mds/hooks/use-ordered";
import { useAppSelector } from "store/index";
import {
  type ActivityWithPagesType,
  selectCurrentCourseId,
  selectIsEditModeEnabled,
  selectPageById,
} from "store/selectors";
import { useDnDItemOrdering, useLocalCopy } from "utils/collections";
import { usePageActions } from "utils/page";
import { courseContentUrl, coursePageUrl } from "utils/urls";

export type PagesCardProps = {
  pages: PageType[];
  header?: string | ReactNode;
  category?: PageCategory;
  activities?: ActivityType[];
  activity_id?: string;
  canManagePages?: boolean;
};

// TODO: Consider consolidating with activity card
// This is s card component for displaying pages with a header element above.
// It's configurable in that pages can be draggable and allow for adding new
// pages.
export const PagesCard: FC<PagesCardProps> = ({
  header,
  pages,
  category = "activity_page",
  activities,
  activity_id,
  canManagePages = false,
}) => {
  const courseId = useAppSelector(selectCurrentCourseId);
  const [_pages, setPages] = useLocalCopy(pages);
  const [searchParams] = useSearchParams();
  const pageId = searchParams.get(PAGE_ID_QUERY_PARAM);
  const selectedPage = useAppSelector((s) => selectPageById(s, pageId));
  const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);

  const orderedPages = useOrdered(_pages);
  const movePageLocally = useDnDItemOrdering(pages, setPages, "activity_id");
  const isActive = activity_id && selectedPage?.activity_id === activity_id;
  const { createPage, dropPage, navigateToPage } = usePageActions();

  const activity = activities?.find((a) => a.id === activity_id);

  const getTopicId = (page: PageType) => {
    if (activity) {
      return activity.topic_id;
    }

    const pageActivity = activities?.find((a) => a.id === page.activity_id);
    return pageActivity?.topic_id;
  };

  const pageUrl = (page: PageType) => {
    if (page.category === "activity_page") {
      return courseContentUrl(courseId, getTopicId(page), page.id);
    }

    return coursePageUrl(courseId, page.category, page.id);
  };

  const createNewPage = () => {
    createPage(category, pages, activity_id);
  };

  const onDropPage = (droppedPage: ActivityPageType) =>
    dropPage(droppedPage, activities as ActivityWithPagesType[]);

  let headerElement = header ? (
    <div
      className={clsx("body-s mt-1 w-full truncate px-2 text-black-tint-40", {
        "cursor-pointer": isEditModeEnabled,
      })}
    >
      {header}
    </div>
  ) : null;

  if (canManagePages && header) {
    headerElement = (
      <DragAndDrop
        dragItemKind="page"
        dropContainerId={activity_id || category}
        dropIndex={0}
        onDraggedItemHover={movePageLocally}
        onDropItem={onDropPage}
      >
        <HoverableToolbar reference="page" disableUnlessEditMode onClickAdd={createNewPage}>
          <div
            className={clsx("body-s mt-1 w-full truncate px-2 text-black-tint-40", {
              "cursor-pointer": isEditModeEnabled,
            })}
          >
            {header}
          </div>
        </HoverableToolbar>
      </DragAndDrop>
    );
  }

  return (
    <>
      {headerElement}

      {orderedPages.length > 0 && (
        <Card className="mb-3 mt-2" isActive={isActive} small>
          {orderedPages?.map((page, index) => (
            <PageListItem
              containerId={activity_id || category}
              key={page.id}
              page={page}
              pageIndex={index}
              readOnly={!canManagePages}
              topicId={getTopicId(page)}
              onDropPage={canManagePages && onDropPage}
              onMovePageLocally={canManagePages && movePageLocally}
              onPageClick={() => navigateToPage(page, pageUrl(page))}
            />
          ))}

          {isActive && canManagePages && (
            <div className="h4 flex items-center justify-start">
              <TextAddButton hideUnlessEditMode underlined onClick={createNewPage} />
            </div>
          )}
        </Card>
      )}
    </>
  );
};
