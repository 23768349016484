import "./FeatureButton.scss";

import { FC, MouseEvent } from "react";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { useAppSelector } from "store/index";
import { selectActiveClassSession, selectPageById } from "store/selectors";
import { FeatureOptions, featurePage } from "utils/presentation";

export type FeatureButtonProps = FeatureOptions & {
  pageId: string;
  size?: "s" | "m" | "l";
};

export const FeatureButton: FC<FeatureButtonProps> = ({ pageId, size = "s", ...options }) => {
  const classSession = useAppSelector(selectActiveClassSession);
  const page = useAppSelector((s) => selectPageById(s, pageId));

  const setFeature = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    featurePage(classSession.id, page, options);
  };

  return (
    <Button
      className="feature-button"
      kind="secondary"
      size={size}
      title={t("presentation.feature")}
      onClick={setFeature}
    >
      {t("presentation.feature")}
    </Button>
  );
};
