import cookie from "cookie";

import { rollbarAndLogError } from "./logger";

import { serverLogout } from "api/api-server";
import { clearAIChatMessages } from "components/ai/storage";
import { AppDispatch } from "store/index";
import { clearPubsubAPICache } from "store/pubsub-slice";
import { resetReduxContent } from "store/utils";

export const getCsrfToken = () => cookie.parse(document.cookie).csrftoken;
export const getOrgIdFromCookie = () => cookie.parse(document.cookie).org_id;
export const setOrgIdCookie = (orgId: string) => {
  const domain = window.location.hostname;
  document.cookie = `org_id=${orgId}; path=/; domain=.${domain}`;
};
export const clearOrgIdCookie = () => {
  const domain = window.location.hostname;
  document.cookie = `org_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.${domain}`;
};

export const setLastCourseIdCookie = (courseId: string) => {
  const domain = window.location.hostname;
  document.cookie = `course_id=${courseId}; path=/; domain=.${domain}`;
};
export const getLastCourseIdFromCookie = () => cookie.parse(document.cookie).course_id;

export const logoutAndClearCaches = (dispatch: AppDispatch) => {
  serverLogout()
    .catch((error) => {
      rollbarAndLogError("Could not log out", error);
    })
    .finally(() => {
      // Even if we fail to log out, we clear all the local state and caches, so that
      // it appears the user is logged out, and the user has a chance to log back in,
      // possibly fixing the issue that caused the logout to fail.
      clearAIChatMessages();
      clearPubsubAPICache();
      dispatch(resetReduxContent());
    });
};
