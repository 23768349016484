import "./DueDateForm.scss";
import "react-day-picker/style.css";

import clsx from "clsx";
import { format, formatISO, isFuture, isValid, parse, parseISO } from "date-fns";
import { range } from "lodash";
import type { FC } from "react";
import { useLayoutEffect, useRef, useState } from "react";
import { DayPicker } from "react-day-picker";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { formatDaysToDjangoDurationField } from "./due-date-form-helpers";

import { intervalIsDefined } from "components/materials/page/helpers";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { Menu, MenuDivider, MenuItem } from "mds/components/Menu";
import { Popover, PopoverButton, PopoverPanel } from "mds/components/Popover";
import { CalendarIcon, ChevronDownIcon } from "mds/icons";
import { storeApi } from "store/index";
import { toastSuccessMessage } from "utils/alerts";

interface DueDateFormProps {
  pageId: string;
  date?: Date;
  /** Interval string of the format `[DD] HH:MM:SS`, or `null`.
   * `00:00:00` means an empty interval: no late submissions are allowed.
   * `null` means late submissions are accepted indefinitely.
   */
  lateInterval?: string;
  className?: string;
  close?: () => void;
}

interface FormInput {
  date: string;
  time: string;
  allowLate: boolean;
  daysLate: number;
}

export const DueDateForm: FC<DueDateFormProps> = ({
  pageId,
  className,
  date: dateProp,
  lateInterval,
  close = () => {},
}) => {
  const date = dateProp ? formatISO(dateProp, { representation: "date" }) : "";
  const time = dateProp ? formatISO(dateProp, { representation: "time" }).slice(0, 5) : "";
  const allowLate = intervalIsDefined(lateInterval);
  const daysLate = lateInterval && allowLate ? parseInt(lateInterval.split(" ")[0], 10) : Infinity;

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<FormInput>({
    defaultValues: { date, time, allowLate, daysLate },
  });

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    const dueDate = new Date(`${data.date}T${data.time}`);
    await storeApi.pages.partial_update({
      id: pageId,
      due_at: formatISO(dueDate),
      late_submission_interval: formatDaysToDjangoDurationField(data.allowLate ? data.daysLate : 0),
    });
    close();
  };

  const watchDate = watch("date");
  const watchTime = watch("time");
  const watchAllowLate = watch("allowLate");

  // Hold the input value in state
  const [inputValue, setInputValue] = useState(dateProp ? format(dateProp, "MM/dd/yyyy") : "");
  // Hold the month in state to control the calendar when the input changes
  const [month, setMonth] = useState(dateProp || new Date());
  const inputRef = useRef<HTMLInputElement>(null);
  const cursorPosition = useRef({
    beforeStart: 0,
    beforeEnd: 0,
  });

  // restore cursor position after re-render
  useLayoutEffect(() => {
    inputRef.current?.setSelectionRange(
      cursorPosition.current.beforeStart,
      cursorPosition.current.beforeEnd,
    );
  }, [inputValue]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // keep the input value in sync
    setInputValue(e.target.value);
    // save cursor position
    cursorPosition.current = {
      beforeStart: e.target.selectionStart,
      beforeEnd: e.target.selectionEnd,
    };

    const parsedDate = parse(e.target.value, "MM/dd/yyyy", new Date());

    if (isValid(parsedDate)) {
      setValue("date", formatISO(parsedDate, { representation: "date" }));
      setMonth(parsedDate);
    } else {
      setValue("date", "");
    }
    // re-check validation
    trigger("date");
  };

  const handleMarkDueNow = async () => {
    const now = new Date();
    now.setSeconds(0, 0);
    reset({
      date: formatISO(now, { representation: "date" }),
      time: formatISO(now, { representation: "time" }).slice(0, 5),
    });

    // Need to include due_at here or the serializer thinks no fields are being saved
    await storeApi.pages.partial_update({ id: pageId, due_now: true, due_at: formatISO(now) });
    toastSuccessMessage(t("success.toasts.submissions_will_be_collected_shortly"));

    close();
  };

  return (
    <form
      className={clsx("due-date-form flex min-w-72 flex-col p-1", className)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-row items-center justify-between">
        <h4 className="m-0 text-black-tint-40">{t("due_date.title")}</h4>
        <Button kind="tertiary" size="xs" onClick={handleMarkDueNow}>
          {t("due_date.make_due_now")}
        </Button>
      </div>

      <Popover>
        <div className="ml-1 flex flex-row items-center">
          <PopoverButton as={Button} iconOnly>
            <CalendarIcon />
          </PopoverButton>
          <input
            aria-invalid={errors.date ? "true" : "false"}
            className="date grow"
            placeholder="MM/dd/yyyy"
            ref={inputRef}
            type="text"
            value={inputValue}
            onChange={handleInputChange}
          />
        </div>

        {errors.date && (
          <div className="pl-1 text-red" role="alert">
            {errors.date?.type === "required" && t("due_date.date_required")}
            {errors.date?.type === "validateDate" && t("due_date.date_invalid")}
            {errors.date?.type === "futureIfChanged" && t("due_date.date_past")}
          </div>
        )}

        <PopoverPanel anchor={{ to: "top" }}>
          {({ close: closeDayPicker }) => (
            <Controller
              control={control}
              name="date"
              render={({ field: { onChange, onBlur, value } }) => (
                <DayPicker
                  disabled={{ before: new Date() }}
                  mode="single"
                  month={month}
                  selected={new Date(value)}
                  onDayBlur={onBlur}
                  onMonthChange={setMonth}
                  onSelect={(selectedDate) => {
                    if (!selectedDate) {
                      onChange("");
                      setInputValue("");
                    } else {
                      onChange(formatISO(selectedDate, { representation: "date" }));
                      setInputValue(format(selectedDate, "MM/dd/yyyy"));
                    }
                    closeDayPicker();
                  }}
                />
              )}
              rules={{
                required: true,
                validate: {
                  validateDate: (value) => {
                    const parsedDate = parseISO(value);
                    return isValid(parsedDate);
                  },
                  futureIfChanged: (value) => {
                    if (value === date) {
                      return true;
                    }
                    const selectedDateTime = parseISO(`${value}T${watchTime}`);
                    return isFuture(selectedDateTime);
                  },
                },
              }}
            />
          )}
        </PopoverPanel>
      </Popover>

      <datalist id="time-options">
        {range(1, 24).map((i) => (
          <option
            aria-label={`${i.toString().padStart(2, "0")}:00`}
            key={i}
            value={`${i.toString().padStart(2, "0")}:00`}
          />
        ))}
        <option aria-label="23:59" value="23:59" />
      </datalist>

      <input
        list="time-options"
        type="time"
        {...register("time", {
          required: true,
          validate: {
            validateTime: (value) => {
              const parsedTime = parse(value, "HH:mm", new Date());
              return isValid(parsedTime);
            },
            futureIfChanged: (value) => {
              if (value === time) {
                return true;
              }
              const selectedDateTime = parseISO(`${watchDate}T${value}`);
              return isFuture(selectedDateTime);
            },
          },
        })}
        aria-invalid={errors.time ? "true" : "false"}
      />

      <div className="flex flex-row justify-between">
        <div>
          {errors.time && (
            <div className="pl-1 text-red" role="alert">
              {errors.date?.type === "required" && t("due_date.time_required")}
              {errors.date?.type === "validateTime" && t("due_date.time_invalid")}
              {errors.date?.type === "futureIfChanged" && t("due_date.time_past")}
            </div>
          )}
        </div>

        <div className="body-xs text-black-tint-55">
          {Intl.DateTimeFormat().resolvedOptions().timeZone}
        </div>
      </div>

      <label className="mt-2">
        <input className="m-0" type="checkbox" {...register("allowLate")} />
        <span className="pl-2 text-black-tint-20">{t("due_date.allow_late_submissions")}</span>
      </label>

      {watchAllowLate && (
        <div className="ml-5 flex flex-row items-center gap-1">
          <Controller
            control={control}
            name="daysLate"
            render={({ field }) => {
              const menuButton = (
                <Button className="gap-1" kind="secondary" size="xs">
                  {field.value === Infinity
                    ? t("timedelta.indefinite")
                    : t("timedelta.days", { count: field.value })}
                  <ChevronDownIcon />
                </Button>
              );
              return (
                <Menu
                  menuButton={menuButton}
                  onItemClick={(e) => {
                    field.onChange(e.value);
                  }}
                >
                  <MenuItem checked={field.value === Infinity} value={Infinity}>
                    {t("timedelta.indefinite")}
                  </MenuItem>
                  <MenuDivider />
                  {[1, 2, 3, 4, 5].map((i) => (
                    <MenuItem checked={field.value === i} key={i} value={i}>
                      {t("timedelta.days", { count: i })}
                    </MenuItem>
                  ))}
                </Menu>
              );
            }}
          />
          <span className="body-xs text-black-tint-40">{t("due_date.after_due_date")}</span>
        </div>
      )}

      <div className="mt-2 flex flex-row justify-end gap-1">
        <Button
          kind="tertiary"
          size="xs"
          onClick={async () => {
            reset({ date: "", time: "", allowLate: false, daysLate: Infinity });
            await storeApi.pages.partial_update({ id: pageId, due_at: null });
            close();
          }}
        >
          {t("common.remove")}
        </Button>
        <Button kind="primary" size="xs" type="submit">
          {t("common.save")}
        </Button>
      </div>
    </form>
  );
};
