import { Component, type ReactNode } from "react";
import { type LogArgument } from "rollbar";

import { Button } from "mds/components/Button";

interface LevelErrorType extends Error {
  level: "warning" | "error";
}

interface Props {
  children: ReactNode;
  minimal?: boolean;
}

interface State {
  hasError: boolean;
  errorMessage: string;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, errorMessage: "Something went wrong." };
  }

  static getDerivedStateFromError(error: LevelErrorType) {
    // return { hasError: true, errorMessage: `${error.message} - ${error.stack}` };
    return { hasError: true, errorMessage: error.message };
  }

  componentDidCatch(error: LevelErrorType) {
    if (error) {
      console.error("Error: ", error);
    }
    const method = error.level === "warning" ? "warning" : "error";
    if (window.Rollbar && window.Rollbar[method]) {
      window.Rollbar[method](error as LogArgument);
    }
  }

  render() {
    const { hasError, errorMessage } = this.state;
    const { children, minimal } = this.props;
    if (hasError) {
      if (minimal) {
        return (
          <div className="flex h-full w-full items-center justify-center">
            <div>Error: {errorMessage}</div>
          </div>
        );
      }
      return (
        <div className="mx-auto my-0 w-[500px]">
          <h1 className="h1">Error</h1>
          <div className="body">
            The application encountered the following error when trying to load this document:
          </div>
          <div className="body-s mb-4 mt-2 text-black-tint-40">{errorMessage}</div>
          <div>
            The error has been recorded. If the error persists after trying again, please contact
            support.
          </div>
          <div className="mt-4 flex justify-around">
            <Button kind="primary" onClick={() => window.location.reload()}>
              Try again
            </Button>
          </div>
        </div>
      );
    }

    return children;
  }
}
