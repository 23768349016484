import "../worksheets/shared/ot-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import { Connection } from "sharedb/lib/client";
// eslint-disable-next-line import/no-unresolved
import { ConnectionState as _ConnectionState } from "sharedb/lib/sharedb";

import { patchShareDBConnectionForPubsub } from "../worksheets/shared/sharedb-patch";
import {
  type PubsubAuthUpdateType,
  type PubsubMessageType,
} from "../worksheets/shared/types/pubsub";
import { type WebsocketErrorType } from "../worksheets/shared/types/websocket-auth";
import { pageAuthorizationRemoved } from "../worksheets/shared/utils";

import { PingComponent } from "./PingComponent";

import { t } from "i18n/i18n";
import { slices, store, useAppDispatch, useAppSelector } from "store/index";
import { clearPubsubAPICache } from "store/pubsub-slice";
import { selectCurrentCourseId, selectCurrentUserId, selectUserJWT } from "store/selectors";
import { analyticsStateActions } from "store/slices/analytics";
import { authStateActions } from "store/slices/auth";
import { viewStateActions } from "store/slices/view";
import { sendBroadcast } from "utils/broadcast";
import { rollbarAndLogError } from "utils/logger";

// This mainly disables ShareDB throwing warnings when it receives pubsub messages that it
// doesn't know how to handle.
patchShareDBConnectionForPubsub();

// The minimum delay should be longer than the longest time we expect congested
// traffic to delay a message. Otherwise, ShareDB will think the connection is
// lost and try to reconnect, which can cause a loop of reconnecting and disconnecting.
const MIN_RECONNECTION_DELAY_MS = 3000;
// The maximum number of times we will try to reconnect before giving up, currently a total
// of MIN_RECONNECTION_DELAY_MS * MAX_RETRY_TIMES = 5 minutes.
const MAX_RETRY_TIMES = 100;

// eslint-disable-next-line react-refresh/only-export-components
export const globalShareDBState: { connection: Connection | null } = {
  connection: null,
};

/**
 * The connection state of the ShareDB connection.
 * The corresponding events are emitted when this changes.
 * - 'connecting'   The connection is still being established, or we are still
 *                 waiting on the server to send us the initialization message.
 * - 'connected'    The connection is open and we have connected to a server
 *                 and received the initialization message.
 * - 'disconnected' Connection is closed, but it will reconnect automatically.
 * - 'closed'       The connection was closed by the client, and will not reconnect.
 * - 'stopped'      The connection was closed by the server, and will not reconnect.
 */
export type ConnectionState = _ConnectionState;

export type WebsocketStatusType = ConnectionState | "401";

// TODO: This should be unified with ConnectionStatusIndicator states
// eslint-disable-next-line react-refresh/only-export-components
export const CONNECTION_STATUS_MESSAGE: Record<WebsocketStatusType, string> = {
  connecting: t("websocket.connecting"),
  connected: t("websocket.connected"),
  disconnected: t("websocket.disconnected"),
  closed: t("websocket.closed"),
  stopped: t("websocket.stopped"),
  401: t("websocket.401"),
};
export interface ShareDBContextType {
  sharedbConnection: Connection | null;
  websocketStatus: WebsocketStatusType;
  retryConnection: () => void;
}

// eslint-disable-next-line react-refresh/only-export-components
export const WebsocketContext = React.createContext<ShareDBContextType>({
  sharedbConnection: null,
  websocketStatus: "connecting",
  retryConnection: () => {},
});

export const WebsocketProvider = ({ children }: { children: React.ReactNode }) => {
  const courseId = useAppSelector(selectCurrentCourseId);
  const jwt = useAppSelector(selectUserJWT);
  const user_id = useAppSelector(selectCurrentUserId);

  const [sharedbConnection, setSharedbConnection] = useState<Connection | null>(null);
  const [websocketErrorCode, setWebsocketErrorCode] = useState<"401" | null>(null);
  const [sharedbStatus, setSharedbStatus] = useState<ConnectionState>("connecting");
  const [webSocket, setWebSocket] = useState<ReconnectingWebSocket | null>(null);
  const [connectionKey, setConnectionKey] = useState(0);
  const dispatch = useAppDispatch();
  const retryConnection = useCallback(() => {
    setConnectionKey((key) => key + 1);
  }, []);

  // This covers both the internal ShareDB status and our custom error codes,
  // and can be used to display status messages to the user.
  const websocketStatus: WebsocketStatusType = websocketErrorCode || sharedbStatus;

  const contextValue = useMemo(
    () => ({
      sharedbConnection,
      websocketStatus,
      retryConnection,
    }),
    [sharedbConnection, websocketStatus, retryConnection],
  );

  useEffect(() => {
    if (!jwt || !courseId) return;
    if (sharedbConnection) {
      console.warn(
        "Re-establishing websocket connection, is this intended? It should only happen when switching org or course.",
      );
      // We don't guarantee objects outside of our current websocket course scope to be updated via pubsub, so caches
      // might get stale. We clear them on scope change to prevent that.
      // TODO: We might want to make this more granular, e.g. keep org-scoped data in the cache.
      clearPubsubAPICache();
    }

    const socketUrl = `${window._env_.SHAREDB_WEBSOCKET_URL}?jwt=${jwt}&course_id=${courseId}`;
    const socket = new ReconnectingWebSocket(`${socketUrl}`, [], {
      // ShareDB handles dropped messages, and buffering them while the socket
      // is closed has undefined behavior
      maxRetries: MAX_RETRY_TIMES,
      maxEnqueuedMessages: 0,
      minReconnectionDelay: MIN_RECONNECTION_DELAY_MS,
    });

    setWebSocket(socket);

    const handleAuthMessage = (message: PubsubAuthUpdateType) => {
      dispatch(authStateActions.setWebsocketUserData(message.data));
      if (message.data.scope === "individual") {
        const worksheetId = Object.keys(message.data.pages)[0];
        const authPage = message.data.pages[worksheetId];

        if (pageAuthorizationRemoved(authPage)) {
          const state = store.getState();
          const page = Object.values(state.pages).find((p) => p.worksheet_id === worksheetId);

          if (page) {
            dispatch(slices.pages.actions.removeOne(page));
          }
        }
      }
    };

    socket.addEventListener("message", (evt: MessageEvent) => {
      try {
        if (typeof evt.data !== "string") {
          rollbarAndLogError("Socket got message evt.data of unexpected type", typeof evt.data);
          return;
        }

        const data = JSON.parse(evt.data) as
          | PubsubMessageType
          | { type: "error"; error: WebsocketErrorType };

        const messages = data.type && data.type === "pubsub" ? data.messages : [];

        for (const message of messages) {
          if (message.type && message.type === "auth-update") {
            handleAuthMessage(message);
          } else if (message.type && message.type === "model-update") {
            if ("course_version" in message && "course_id" in message) {
              dispatch(
                viewStateActions.incrementCourseVersionTo({
                  newCourseVersion: message.course_version,
                  msgCourseId: message.course_id,
                }),
              );
            }
            if ("model" in message) {
              sendBroadcast(message);
            }
          } else if (message.type && message.type === "analytics-update") {
            dispatch(analyticsStateActions.updateAnalyticsRecord(message));
          }
        }

        if ("error" in data && data.error) {
          const code = data.error.code;
          // All of these send rollbar messages on the backend, so we don't need to do that here
          if (code === 403) {
            // An operation was rejected due to missing permissions. This shouldn't happen,
            // but should also not be fatal. We leave it to ShareDBDoc to handle this.
            // TODO: We might want to briefly flash a small "error" connection indicator state once
            // we have the more subdued connection indicator design in place, just to give visual indications
            // for small things going wrong.
          } else if (code === 401) {
            // The JWT token is invalid. This is fatal, so we close the connection. We don't
            // log the user out forcefully to prevent login loops.
            setWebsocketErrorCode("401");
          } else {
            // If the error is critical, ShareDB will re-try connecting for a bit, and if not
            // successful, it will close the connection, which consumers can react to by listening
            // to the sharedbStatus.
          }
        }
      } catch (err) {
        console.error("Error reading socket data", err, evt.data);
      }
    });

    // It's important to initiate the connection on the socket _before_ the socket
    // finishes connecting, otherwise sharedb will miss the first message event and get stuck.
    const _shareDB = new Connection(socket);
    _shareDB.on("connected", () => {
      setWebsocketErrorCode(null);
    });

    globalShareDBState.connection = _shareDB;
    setSharedbConnection(_shareDB);
    _shareDB.on("state", (newState, reason) => {
      setSharedbStatus(newState);
      if (!["connected", "connecting", "disconnected"].includes(newState)) {
        rollbarAndLogError(`ShareDB connection in bad state: '${newState}'`, {
          user_id,
          courseId,
          reason,
        });
      }
    });

    return () => {
      // Sharedb is responsible for cleaning up the socket, so we don't close it separately
      _shareDB.close();
    };

    // This component only uses "sharedbConnection" to do a conditional log, and also sets it,
    // so we don't want to cause a loop by re-rendering when it changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt, courseId, connectionKey]);

  return (
    <>
      {webSocket && <PingComponent socket={webSocket} />}
      <WebsocketContext.Provider value={contextValue}>{children}</WebsocketContext.Provider>
    </>
  );
};
