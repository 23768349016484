import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const BookCourseIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <g clipPath="url(#clip0_197_41264)">
      <path
        className="stroke"
        d="M1 13.6V2.86667C1 1.83533 1.83533 1 2.86667 1H13.1333C14.1647 1 15 1.83533 15 2.86667V13.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M2.4 12.2H13.6C14.3728 12.2 15 12.8272 15 13.6C15 14.3728 14.3728 15 13.6 15H2.4C1.6272 15 1 14.3728 1 13.6C1 12.8272 1.6272 12.2 2.4 12.2Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M11.2667 3.79999H4.7334V8.46665H11.2667V3.79999Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_197_41264">
        <rect fill="white" height="16" width="16" />
      </clipPath>
    </defs>
  </svg>
);
