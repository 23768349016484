import "./CharacterCounter.scss";

import { type FC, useEffect, useState } from "react";
import { Quill } from "react-quill-new";
import { Doc } from "sharedb/lib/client";

import { PathType, WorksheetType } from "../../../shared/types/worksheet";

import { Trans, t } from "i18n/i18n";
import { Checkbox } from "mds/components/Checkbox";
import { TextArea } from "mds/components/TextArea";

interface CharacterCounterProps {
  doc: Doc<WorksheetType>;
  editor: InstanceType<typeof Quill>;
  maxLength: number | null;
  path: PathType;
  readOnly?: boolean;
  id?: string;
}

const DEFAULT_MAX_LENGTH = "600";

export const CharacterCounter: FC<CharacterCounterProps> = ({
  doc,
  editor,
  maxLength,
  path,
  readOnly,
  id,
}) => {
  const [textLength, setTextLength] = useState<number>(0);

  const onMaxLengthChange = (input: string) => {
    // Convert input to a number or null if empty
    const newMaxLength = input === "" ? null : parseInt(input, 10);

    // Check if the new value is different and is a valid number or null
    if (newMaxLength !== maxLength && (newMaxLength === null || !isNaN(newMaxLength))) {
      doc.submitOp([
        {
          p: [...path, "maxLength"],
          oi: newMaxLength,
          od: maxLength,
        },
      ]);
    }
  };

  useEffect(() => {
    if (editor) {
      editor.on("text-change", () => {
        // Quill editor always has a newline character, so length is always 1 more than expected
        const length = editor.getLength() - 1;
        setTextLength(length);
      });
    }
  }, [editor]);

  return (
    <div>
      {!readOnly ? (
        <div className="character-counter-edit body-s inline flex flex-wrap">
          <Checkbox
            checked={Boolean(maxLength)}
            className="px-2"
            onChange={(checked) => onMaxLengthChange(checked ? DEFAULT_MAX_LENGTH : "")}
          />
          <Trans count={maxLength} i18nKey="character_counter.character_limit" t={t}>
            <span className="pr-2">Limit Response to </span>
            <TextArea
              className="max-character-count-input align-middle"
              disabled={!maxLength}
              inputMode="numeric"
              name={`${id}-length-option`}
              placeholder={DEFAULT_MAX_LENGTH}
              value={maxLength || ""}
              onChange={(e) => onMaxLengthChange(e.target.value)}
            />
            <span className="pl-2"> characters </span>
          </Trans>
        </div>
      ) : (
        maxLength && (
          <div className="character-counter-display body-xs flex items-center gap-1">
            <div className="character-counter-warning">{t("character_counter.reached_limit")}</div>

            {t("character_counter.characters_used_count", { textLength, maxLength })}
          </div>
        )
      )}
    </div>
  );
};
