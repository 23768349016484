// This is the client's API it uses for interacting with the `server`.
import axios, { AxiosError } from "axios";

import { getPasswordErrorMessages } from "../helpers/password-errors";

import {
  AIMessageType,
  AIModelType,
  AtRiskData,
  AtRiskOptions,
  CourseInviteType,
  CsrfTokenType,
  FormattedValidationError,
  ImportCourseRequestType,
  InviteKeyCourseType,
  PageAssessmentStatusType,
  UserInformationType,
  UserType,
} from "components/server-types";
import { t } from "i18n/i18n";
import { PasswordErrorKey } from "i18n/i18next";
import { getReadableAxiosError } from "utils/alerts";
import { getCsrfToken } from "utils/auth";
import { rollbarAndLogError } from "utils/logger";

const CSRF_TOKEN_HEADER = "X-CSRFToken";

export const serverApi = axios.create({
  baseURL: `${window._env_?.SERVER_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
serverApi.interceptors.request.use((config) => {
  if (!config.headers[CSRF_TOKEN_HEADER]) {
    const csrfToken = getCsrfToken();
    // eslint-disable-next-line no-param-reassign
    config.headers[CSRF_TOKEN_HEADER] = csrfToken;
  }
  return config;
});

export const reportApiError = (name: string, error: unknown) => {
  if (error instanceof AxiosError) {
    const message = getReadableAxiosError(error);
    rollbarAndLogError(`${name} failed with message: ${message}`, error);
  } else {
    rollbarAndLogError(`${name}`, error);
  }
};

export const SERVER_API_PATH = "/api/v1";

// TODO: This should be handled properly later, but the server will only return 100 results by default for
// any API call that returns a list of items. We should handle pagination properly later and fill out this interface
// appropriately.
export interface ServerApiResultsResponseType<T> {
  results: T;
}

export function serverLogout() {
  return serverApi.post(`${SERVER_API_PATH}/auth/logout/`);
}

// All server API calls should have the `server` prefix to clarify that it's going to the `server`
// (as opposed to `collaboration`).

export interface ServerApiUserResponseType {
  user_id: string;
  collaboration_jwt: string;
  // See store/auth.ts for how this is used
  course_to_org_id_map: Record<string, string>;
}

export interface ServerApiAIResponseType {
  chunk: string;
  length: number;
}
export async function serverGetUser(): Promise<ServerApiUserResponseType> {
  // We don't catch/report an error here because this is a common call that will fail
  // if a user is just not logged in. Handled in detail further upstream.
  const response = await serverApi.get<ServerApiUserResponseType>(`${SERVER_API_PATH}/users/me/`, {
    withCredentials: true,
  });
  return response.data;
}

export type PasswordType = {
  current_password: string;
  password: string;
  confirm_password: string;
};

export interface ServerApiDetailResponseType {
  detail: string;
}

export async function serverSetPassword(
  passwords: PasswordType,
): Promise<ServerApiDetailResponseType> {
  // We don't do any error handling here; it's handled in the calling function.

  const response = await serverApi.post<ServerApiDetailResponseType>(
    `${SERVER_API_PATH}/auth/users/set_password/`,
    {
      ...passwords,
    },
  );

  return response.data;
}

export async function serverRequestResetPassword(email: string) {
  try {
    const response = await serverApi.post<ServerApiUserResponseType>(
      `${SERVER_API_PATH}/auth/password_reset/request_password_reset/`,
      {
        email,
      },
    );

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      const code = error.response?.status;

      let message: string;
      // TODO: Double check that the client is requiring email field before this request is sent.
      if (code === 400) {
        message = t("error.toasts.missing_field", { item: "Email" });
      } else {
        message = getReadableAxiosError(error);
      }

      rollbarAndLogError(`serverRequestResetPassword failed with message: ${message}`, error);

      throw new Error(message);
    } else {
      rollbarAndLogError("serverRequestResetPassword", error);
    }

    throw error;
  }
}

export async function serverResetPassword(
  token: string,
  newPassword: string,
  confirmPassword: string,
) {
  try {
    const response = await serverApi.post<ServerApiUserResponseType>(
      `${SERVER_API_PATH}/auth/password_reset/reset_password/`,
      {
        token,
        new_password: newPassword,
        confirm_password: confirmPassword,
      },
    );

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      const code = error.response?.status;

      let message = "";
      if (code === 400) {
        const data = error.response?.data as FormattedValidationError<PasswordErrorKey>;

        if (!data) {
          rollbarAndLogError("serverResetPassword", error);
          return;
        }

        for (const { details, t: translationKeys } of Object.values(data)) {
          message += getPasswordErrorMessages(translationKeys, details);
        }

        if (!message) {
          message = t("error.error");
        }
      } else if (code === 403) {
        message = t("error.toasts.token_invalid");
      } else {
        message = getReadableAxiosError(error);
      }

      rollbarAndLogError(`serverResetPassword failed with message: ${message}`, error);

      throw new Error(message);
    } else {
      rollbarAndLogError("serverResetPassword", error);
    }

    throw error;
  }
}

export async function switchOrg(newOrgId: string) {
  try {
    await serverApi.post(`${SERVER_API_PATH}/orgs/orgs/${newOrgId}/switch/`);
  } catch (error) {
    reportApiError("switchOrg", error);
    throw error;
  }
}

// NOTE:  The intent is to ultimately stream the language model response, however, half a dozen approaches
//     have been tried and none have worked.  The current approach results in a non-streaming response which will be
//     revisited in the future.
export async function streamFetch(
  url: string,
  data,
  onPartial?: (ServerApiAIResponseType) => void,
) {
  const csrfToken = getCsrfToken();
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      [CSRF_TOKEN_HEADER]: csrfToken,
    },
    body: JSON.stringify(data),
  });
  if (!response.ok || !response.body) {
    throw new Error(await response.text());
  }

  const decoder = new TextDecoder("utf-8");
  const reader = response.body.getReader();

  // We accumulate the response chunks here so that we can both return partial
  // updates via the callback, but also return the full response via promise resolution
  // at the end of the request.
  const acc: string[] = [];
  return new Promise((resolve, reject) => {
    reader
      .read()
      .then(function processText({ done, value }) {
        if (done) {
          resolve(acc.join(""));
          return;
        }
        const stringVal = decoder.decode(value);
        acc.push(stringVal);
        if (onPartial) {
          onPartial(stringVal);
        }
        reader
          .read()
          .then(processText)
          .catch((e) => {
            console.error("streamFetch error", e);
          });
      })
      .catch((e: Error) => {
        console.error("streamFetch error", e);
        reject(e);
      });
  });
}

export function serverGetAIResponse(
  prompt: AIMessageType[],
  options: { model: AIModelType; stream?: boolean },
  callback?: (ServerApiAIResponseType) => void,
) {
  // The "fetch" API does not handle having two slashes at the start
  // of the URL and finding the server (though Axios does), so this is a
  // temporary workaround until we can figure out how to handle this better.
  const baseURL = `${window._env_?.SERVER_URL === "/" ? "" : window._env_?.SERVER_URL}`;
  const url = `${baseURL}${SERVER_API_PATH}/ai/chat/`;
  return streamFetch(
    url,
    {
      prompt,
      model: options.model,
      stream: typeof options.stream === "undefined" ? true : options.stream,
    },
    callback,
  );
}

export async function serverLogin(email: string, password: string) {
  try {
    const response = await serverApi.get<CsrfTokenType>(`${SERVER_API_PATH}/auth/csrf/`);
    const csrftoken = response.data.csrftoken;
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      [CSRF_TOKEN_HEADER]: csrftoken,
    };

    await serverApi.post(`${SERVER_API_PATH}/auth/login/`, formData, {
      headers,
    });
  } catch (error) {
    // Don't bother reporting login failures due to Unauthorized (likely just wrong credentials provided by user).
    if ((error as { response: { status: number } })?.response?.status !== 401) {
      reportApiError("serverLogin", error);
    }

    throw error;
  }
}

export async function serverCreateImpersonationUrl(targetUserId: string): Promise<string> {
  try {
    const response = await serverApi.post<{
      redemption_url: string;
    }>(`${SERVER_API_PATH}/auth/impersonation_session/`, { target_user_id: targetUserId });
    return response.data.redemption_url;
  } catch (error) {
    reportApiError("serverCreateImpersonationUrl", error);
    throw error;
  }
}

export async function serverGetCourseInvite(courseId: string): Promise<CourseInviteType[]> {
  try {
    const response = await serverApi.get<ServerApiResultsResponseType<CourseInviteType[]>>(
      `${SERVER_API_PATH}/auth/course_invite/?course_id=${courseId}`,
    );
    return response.data.results;
  } catch (error) {
    reportApiError("serverGetCourseInvite", error);
    throw error;
  }
}

export async function serverCreateCourseInvite(courseId: string): Promise<CourseInviteType> {
  try {
    const response = await serverApi.post<CourseInviteType>(
      `${SERVER_API_PATH}/auth/course_invite/`,
      { course_id: courseId },
    );
    return response.data;
  } catch (error) {
    reportApiError("serverCreateCourseInvite", error);
    throw error;
  }
}

export async function serverDeleteCourseInvite(inviteId: string): Promise<void> {
  try {
    await serverApi.delete(`${SERVER_API_PATH}/auth/course_invite/${inviteId}/`);
  } catch (error) {
    reportApiError("serverDeleteCourseInvite", error);
    throw error;
  }
}

export async function serverRedeemInviteKey(inviteKey: string) {
  try {
    const ret = await serverApi.post(
      `${SERVER_API_PATH}/auth/course_invite/redeem_for_existing_user/?invite_key=${inviteKey}`,
    );
    return ret.data as { course_id: string; user_id: string };
  } catch (error) {
    reportApiError("serverRedeemInviteKey", error);
    throw error;
  }
}

export async function serverRegisterUserWithInviteKey(
  inviteKey: string,
  userData: UserInformationType & {
    password: string;
    password_confirm: string;
  },
) {
  try {
    const ret = await serverApi.post(
      `${SERVER_API_PATH}/auth/course_invite/redeem_for_new_user/?invite_key=${inviteKey}`,
      userData,
    );
    return ret.data as UserType;
  } catch (error) {
    reportApiError("serverRegisterUserWithInviteKey", error);
    throw error;
  }
}

export async function serverGetCourseMetadataFromInviteKey(inviteKey: string) {
  try {
    const ret = await serverApi.get(
      `${SERVER_API_PATH}/auth/course_invite/get_course_metadata/?invite_key=${inviteKey}`,
    );
    return ret.data as InviteKeyCourseType;
  } catch (error) {
    reportApiError("serverGetCourseMetadataFromInviteKey", error);
    throw error;
  }
}

export async function serverExportCourse(courseId: string): Promise<JSON> {
  try {
    const results = await serverApi.post<JSON>(
      `${SERVER_API_PATH}/material/courses/${courseId}/export_external/`,
    );

    return results.data;
  } catch (error) {
    reportApiError("serverExportCourse", error);
    throw error;
  }
}

export async function serverImportExternalCourse(formData: FormData): Promise<string> {
  try {
    const results = await serverApi.post<string>(
      `${SERVER_API_PATH}/material/courses/import_external/`,
      formData,
      { headers: { "Content-Type": "multipart/form-data" } },
    );

    return results.data;
  } catch (error) {
    reportApiError("serverImporExternalCourse", error);
    throw error;
  }
}

export async function serverCloneCourse(
  course_id: string,
  course_code_id?: string,
  term_id?: string,
): Promise<void> {
  const data: { [key: string]: string } = {};
  data.target_course_code_id = course_code_id;
  data.target_term_id = term_id;

  try {
    await serverApi.post(`${SERVER_API_PATH}/material/courses/${course_id}/clone/`, data);
  } catch (error) {
    reportApiError("serverCloneCourse", error);
    throw error;
  }
}

export async function serverImportCourse(data: ImportCourseRequestType): Promise<void> {
  try {
    await serverApi.post(`${SERVER_API_PATH}/material/courses/import/`, data);
  } catch (error) {
    reportApiError("serverImportCourse", error);
    throw error;
  }
}

export async function serverGetAtRiskData(
  courseId: string,
  options?: AtRiskOptions,
): Promise<AtRiskData> {
  try {
    const response = await serverApi.post<AtRiskData>(
      `${SERVER_API_PATH}/material/courses/${courseId}/at_risk/`,
      { options },
    );

    return response.data;
  } catch (error) {
    reportApiError("serverGetAtRiskData", error);
    throw error;
  }
}

export async function serverCopyTopic(topicId: string, courseIds: string[]): Promise<void> {
  try {
    await serverApi.post<ServerApiResultsResponseType<void>>(
      `${SERVER_API_PATH}/material/topics/${topicId}/copy/`,
      { course_ids: courseIds },
    );
  } catch (error) {
    reportApiError("serverCopyTopic", error);
    throw error;
  }
}

export async function serverMoveActivityWithinCourse(
  pageId: string,
  topicId: string,
): Promise<void> {
  const data = { topic_id: topicId };

  try {
    await serverApi.post<ServerApiResultsResponseType<void>>(
      `${SERVER_API_PATH}/material/activities/${pageId}/move/`,
      data,
    );
  } catch (error) {
    rollbarAndLogError("serverMoveActivityWithinCourse", error);
    throw error;
  }
}

export async function serverMovePageWithinCourse(
  pageId: string,
  category: string,
  topicId?: string,
): Promise<void> {
  const data: { category: string; topic_id?: string } = { category };
  if (topicId) {
    data.topic_id = topicId;
  }

  try {
    await serverApi.post<ServerApiResultsResponseType<void>>(
      `${SERVER_API_PATH}/material/pages/${pageId}/move/`,
      data,
    );
  } catch (error) {
    reportApiError("serverMovePageWithinCourse", error);
    throw error;
  }
}

export async function serverCopyPage(
  pageId: string,
  courseIds?: string[],
  activityIds?: string[],
): Promise<void> {
  const data: { course_ids?: string[]; activity_ids?: string[] } = {};
  if (courseIds?.length > 0) {
    data.course_ids = courseIds;
  }

  if (activityIds?.length > 0) {
    data.activity_ids = activityIds;
  }

  try {
    await serverApi.post<ServerApiResultsResponseType<void>>(
      `${SERVER_API_PATH}/material/pages/${pageId}/copy/`,
      data,
    );
  } catch (error) {
    reportApiError("serverCopyTopic", error);
    throw error;
  }
}

export async function serverGetLicodeToken(
  username: string,
  role: string,
  roomName: string,
  licodeUrl: string,
): Promise<string> {
  try {
    const data = {
      username,
      role,
      room_name: roomName,
      licode_nuve_server: licodeUrl,
    };
    const results = await serverApi.post<string>(`${SERVER_API_PATH}/auth/licodetoken/`, data);
    return results.data;
  } catch (error) {
    reportApiError("serverGetLicodeToken", error);
    throw error;
  }
}

export async function serverDistributeUsersToGroups(pageId: string): Promise<void> {
  try {
    await serverApi.post<ServerApiResultsResponseType<void>>(
      `${SERVER_API_PATH}/material/pages/${pageId}/distribute/`,
    );
  } catch (error) {
    reportApiError("serverDistributeUsersToGroups", error);
    throw error;
  }
}

export async function serverReleaseAssessments(pageId: string): Promise<void> {
  try {
    await serverApi.post<ServerApiResultsResponseType<void>>(
      `${SERVER_API_PATH}/material/pages/${pageId}/release_assessments/`,
    );
  } catch (error) {
    reportApiError("serverReleaseAssessments failed with error", error);
    throw error;
  }
}

export async function serverGetPageAssessmentStatuses(
  page_ids: string[],
): Promise<PageAssessmentStatusType> {
  try {
    const response = await serverApi.post<PageAssessmentStatusType>(
      `${SERVER_API_PATH}/material/pages/assessment_status/`,
      { page_ids },
    );

    return response.data;
  } catch (error) {
    reportApiError("serverPageAssessmentStatus", error);
    throw error;
  }
}

export async function serverBulkCreateSubmissions(page_id: string): Promise<void> {
  try {
    await serverApi.post<ServerApiResultsResponseType<void>>(
      `${SERVER_API_PATH}/material/submissions/bulk/`,
      { page_id },
    );
  } catch (error) {
    reportApiError("serverBulkCreateSubmissions", error);
    throw error;
  }
}
