import "./CourseUsersSidebarPanel.scss";

import clsx from "clsx";
import { useEffect, useState } from "react";
import type { FC } from "react";
import { useCopyToClipboard } from "usehooks-ts";

import { CourseRoleCard } from "./CourseRoleCard";
import { DisabledUsersCard } from "./CourseUserCard";

import {
  serverCreateCourseInvite,
  serverDeleteCourseInvite,
  serverGetCourseInvite,
} from "api/api-server";
import { LoadingScreen } from "components/LoadingScreen";
import type { CourseInviteType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { IconText } from "mds/components/IconText";
import { showConfirmationModal } from "mds/components/Modal";
import { TextField } from "mds/components/TextField";
import { Tooltip } from "mds/components/Tooltip";
import { AddPlusIcon, DuplicateCopyIcon, TrashDeleteIcon } from "mds/icons";
import { storeApi, useAppSelector } from "store/index";
import {
  selectCanAuthorCourse,
  selectCanAuthorOrg,
  selectCurrentCourse,
  selectCurrentUserId,
  selectUsersWithCourseActivityInCurrentCourse,
} from "store/selectors";
import { useListSelector } from "store/store-hooks";
import { toastCreateOperationCatcher, toastLocalizedOperationCatcher } from "utils/alerts";
import { trackEvent } from "utils/amplitude";

export const CourseUsersSidebarPanel = () => {
  const currentCourse = useAppSelector(selectCurrentCourse);
  const users = useListSelector(selectUsersWithCourseActivityInCurrentCourse);
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const canAuthorOrg = useAppSelector(selectCanAuthorOrg);
  // This makes sure an org admin that's a student in the course can then change themselves back to an instructor
  const canModifyUsers = canAuthorCourse || canAuthorOrg;
  const courseId = currentCourse.id;
  const userId = useAppSelector(selectCurrentUserId);

  const currentUserInThisCourse = users.find((user) => user.user_id === userId);

  const canAddThemselvesToCourse = canAuthorOrg && !currentUserInThisCourse;

  const onJoinAsAdmin = () =>
    storeApi.course_users
      .create(
        {
          course_id: courseId,
          user_id: userId,
          role: "admin",
        },
        { skipToast: true },
      )
      .catch(toastLocalizedOperationCatcher("join_as_admin"));

  return (
    <>
      <div className="content-sidebar-header flex items-center justify-between">
        <h3>{t("user_options_menu.course_sidebar_title")}</h3>

        {canModifyUsers && (
          <Button
            className="flex gap-2"
            kind="primary"
            size="xs"
            onClick={() => onShowInviteStudentModal({ courseId })}
          >
            <AddPlusIcon />
            <div>{t("user_options_menu.invite_students.button")}</div>
          </Button>
        )}
      </div>

      <div className="course-user-sidebar-panel content-sidebar-main">
        {canAddThemselvesToCourse && (
          <Button className="mb-3 flex items-center gap-2" size="xs" onClick={onJoinAsAdmin}>
            <span>{t("user_options_menu.join_as_admin.title")}</span>
            <Tooltip>{t("user_options_menu.join_as_admin.tooltip")}</Tooltip>
          </Button>
        )}

        <CourseRoleCard
          canModifyUsers={canModifyUsers}
          className="mb-4"
          courseRole="admin"
          users={users}
        />

        <CourseRoleCard
          canModifyUsers={canModifyUsers}
          className="mb-4"
          courseRole="instructor"
          users={users}
        />

        <CourseRoleCard
          canModifyUsers={canModifyUsers}
          className="mb-4"
          courseRole="student"
          users={users}
          showEmpty
        />

        <DisabledUsersCard canModifyUsers={canModifyUsers} className="mb-4" users={users} />
      </div>
    </>
  );
};

interface InviteStudentModalProps {
  courseId: string;
}

const onShowInviteStudentModal = (props: InviteStudentModalProps) => {
  trackEvent("Course users - Add students", { eventCategory: "Button press" });
  showConfirmationModal({
    title: t("user_options_menu.invite_students.title"),
    hideCancelConfirmButtons: true,
    children: (onClose) => <InviteStudentModal {...props} onClose={onClose} />,
  }).catch(toastLocalizedOperationCatcher("show_student_invite_modal"));
};

export const InviteStudentModal: FC<InviteStudentModalProps & { onClose: () => void }> = ({
  courseId,
  onClose,
}) => {
  const [recentlyCopied, setRecentlyCopied] = useState(false);
  // We separately have controls to hide the modal, because we may need to show the delete invite modal
  // _while_ showing this modal
  const [hideModal, setHideModal] = useState(false);

  const [courseInvite, setCourseInvite] = useState<CourseInviteType | null>(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const [, copyToClipboard] = useCopyToClipboard();

  const link = `${window.location.origin}/?invite_key=${courseInvite?.invite_key}`;

  // TODO: Replace with storeApi
  useEffect(() => {
    serverGetCourseInvite(courseId)
      // We assume there's only one invite per course right now
      .then((invites) => setCourseInvite(invites[0]))
      .catch(setError)
      .finally(() => setLoading(false));
  }, [courseId]);

  const onDeleteInvite = async () => {
    await serverDeleteCourseInvite(courseInvite.id);
    setCourseInvite(null);
  };

  const onCreateInvite = async () => {
    trackEvent("New invitation modal - Confirm", { eventCategory: "Button press" });
    const invite = await serverCreateCourseInvite(courseId).catch(
      toastCreateOperationCatcher("invite"),
    );

    setCourseInvite(invite || null);
  };

  const onCopy = async () => {
    trackEvent("Copy student invitation link", { eventCategory: "Button press" });
    await copyToClipboard(link);
    setRecentlyCopied(true);
    setTimeout(() => setRecentlyCopied(false), 2000);
  };

  if (error) {
    return (
      <div className="course-invite-modal px-6 pb-6">
        <h3 className="my-2">{t("user_options_menu.invite_students.error_title")}</h3>
        <div className="body-s">
          {t("user_options_menu.invite_students.error_body", { error_message: error?.message })}
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="course-invite-modal px-6 pb-6">
        <LoadingScreen text={t("common.loading")} />
      </div>
    );
  }

  if (!courseInvite) {
    return (
      <div className="course-invite-modal flex flex-col items-center gap-5">
        <div className="body-s">{t("user_options_menu.invite_students.explanation")}</div>
        <div className="flex w-full justify-end gap-2">
          <Button kind="secondary" onClick={onClose}>
            {t("common.cancel")}
          </Button>
          <Button onClick={() => onCreateInvite()}>
            {t("user_options_menu.invite_students.action_create")}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx("course-invite-modal flex flex-col items-start", {
        hidden: hideModal,
      })}
    >
      <div className="body-s course-invite-modal-text mb-6">
        {t("user_options_menu.invite_students.label")}
      </div>

      <div className="my-3 mb-6 flex w-full justify-center">
        <img
          alt={t("user_options_menu.invite_students.qr_code_alt_text")}
          className="course-invite-modal-qr-code"
          src={`https://api.qrserver.com/v1/create-qr-code/?size=128x128&data=${link}`}
        />
      </div>

      <div className="mt-3 flex items-center gap-2">
        <Button
          kind="destructive"
          title={t("user_options_menu.invite_students.action_delete")}
          iconOnly
          onClick={() => {
            trackEvent("Delete student invitation link", { eventCategory: "Button press" });
            setHideModal(true);
            showConfirmationModal({
              title: t("user_options_menu.invite_students.delete_title"),
              labelText: t("user_options_menu.invite_students.warning_delete"),
              confirmButtonText: t("user_options_menu.invite_students.action_delete"),
              confirmButtonKind: "destructive",
              onClose: () => {
                setHideModal(false);
              },
              onConfirm: () => {
                trackEvent("Delete invitation modal - Confirm", { eventCategory: "Button press" });
                onDeleteInvite();
                onClose();
              },
            });
          }}
        >
          <TrashDeleteIcon />
        </Button>

        <TextField className="course-invite-modal-link" value={link} readOnly />

        <div className="course-invite-copy flex items-center">
          <Tooltip
            element={
              <Button
                kind="primary"
                title={t("user_options_menu.invite_students.copy_link")}
                iconOnly
                onClick={onCopy}
              >
                <IconText
                  iconStart={<DuplicateCopyIcon />}
                  text={t("user_options_menu.invite_students.copy_link")}
                />
              </Button>
            }
          >
            {recentlyCopied
              ? t("user_options_menu.invite_students.copy_link_success")
              : t("user_options_menu.invite_students.copy_link")}
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
