import { Quill } from "react-quill-new";

/**
 * Quill 2.0 made some changes as to how the composition API is handled, which tries to batch
 * an entire IME composition (used in e.g. Chinese/Japanese/Korean) at once without triggering
 * onChange events.
 * This is a problem for us, as we rely on the fact that any change to the editor content immediately
 * triggers an onChange event. If we delay the event, and another user's input comes in, we detect the new
 * content that's still part of the composition as something we just wrote, and send it back to the server,
 * which is then duplicated once we actually commit the composition. Additionally, updates from other users
 * that arrive are seen as local updates, which also causes them to be duplicated back to the sender.
 * If many users are typing with IMEs in the same document, this becomes extremely messy.
 *
 * We tried many solutions (see https://github.com/minervaproject/structured-learning-exercises/pull/1586).
 * The simplest and hopefully lowest-risk solution we found was to patch the Quill instance to force it to
 * only tiny batches and immediately trigger onChange events. This doesn't prevent _all_ issues, but improves
 * the experience enough for it to be, at most, a minor annoyance.
 */
export const maybePatchQuillCompositionAPI = (quill: Quill) => {
  // We only want to patch this once per underlying quill instance, which might be the same
  // even if the component re-renders, so we use a flag on the quill object.
  const quillPatched = quill as { compositionPatched?: boolean };
  if (!quillPatched.compositionPatched) {
    quill.scroll.domNode.addEventListener("compositionupdate", () => {
      if (quill.composition.isComposing) {
        quill.scroll.batchEnd();
        quill.scroll.batchStart();
      }
    });
    quillPatched.compositionPatched = true;
  }
};
