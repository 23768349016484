import { FC, useEffect } from "react";

import { PageOutcomeRubric } from "components/materials/page/outcomes/PageOutcomeRubric";
import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { storeApi, useAppSelector } from "store/index";
import { selectNestedPageOutcomes } from "store/selectors";

export type FeatureOutcomesProps = {
  page: PageType;
};

export const FeatureOutcomes: FC<FeatureOutcomesProps> = ({ page }) => {
  const pageOutcomes = useAppSelector((s) => selectNestedPageOutcomes(s, page.id));

  useEffect(() => {
    storeApi.page_outcomes.list({ page_id: page.id });
  }, [page.id]);

  return (
    <div className="px-10 pb-7 pt-4">
      <div className="flex items-center justify-between">
        <h2>{t("glossary.outcomes")}</h2>
      </div>

      <div className="page-outcome-rubrics flex w-full flex-wrap gap-3">
        {pageOutcomes.map((pageOutcome) => (
          <PageOutcomeRubric
            className="flex w-full flex-col"
            key={pageOutcome.id}
            pageOutcome={pageOutcome}
          />
        ))}
      </div>
    </div>
  );
};
