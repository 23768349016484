import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const TableLayoutIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path className="stroke" d="M1 5.66669H15" strokeLinecap="round" strokeLinejoin="round" />
    <path className="stroke" d="M5.6665 1V15" strokeLinecap="round" strokeLinejoin="round" />
    <path
      className="stroke"
      d="M13.6 15H2.4C2.0287 15 1.6726 14.8525 1.41005 14.5899C1.1475 14.3274 1 13.9713 1 13.6V2.4C1 2.0287 1.1475 1.6726 1.41005 1.41005C1.6726 1.1475 2.0287 1 2.4 1H13.6C13.9713 1 14.3274 1.1475 14.5899 1.41005C14.8525 1.6726 15 2.0287 15 2.4V13.6C15 13.9713 14.8525 14.3274 14.5899 14.5899C14.3274 14.8525 13.9713 15 13.6 15Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
