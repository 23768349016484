import "./CourseUsersSidebarPanel.scss";

import clsx from "clsx";
import { sortBy } from "lodash";
import type { FC, ReactNode } from "react";
import { useCopyToClipboard } from "usehooks-ts";

import { CourseUserOptionsMenu } from "../sidebar/OrgUserOptionsMenu";

import { serverCreateImpersonationUrl } from "api/api-server";
import {
  getActiveStateSortKeyForUser,
  userActivityIcons,
} from "components/materials/course/helpers";
import { t } from "i18n/i18n";
import { Card } from "mds/components/Card";
import { IconText } from "mds/components/IconText";
import { showConfirmationModal } from "mds/components/Modal";
import { PersonSingleIcon } from "mds/icons";
import { storeApi, useAppSelector } from "store/index";
import {
  CourseUserActivityStatus,
  type UserWithCourseActivity,
  selectCurrentCourse,
  selectCurrentCourseCode,
  selectCurrentUserIsMPAdmin,
} from "store/selectors";
import { toastLocalizedOperationCatcher, toastSuccessMessage } from "utils/alerts";
import { trackEvent } from "utils/amplitude";
import { getUserFullName, getUserLastNameSortKey } from "utils/user-utils";

interface DisabledUsersCardProps {
  users: UserWithCourseActivity[];
  className?: string;
  canModifyUsers?: boolean;
}

export const DisabledUsersCard: FC<DisabledUsersCardProps> = ({
  users,
  className,
  canModifyUsers = false,
}) => {
  return (
    <CourseUserCard
      canModifyUsers={canModifyUsers}
      className={className}
      filterUsers={(allUsers) => allUsers.filter((user) => user.is_disabled)}
      headerCount={users.filter((user) => user.is_disabled).length.toString()}
      headerIcon={<PersonSingleIcon />}
      headerTitle={t("user_options_menu.suspended_accounts")}
      users={users}
    />
  );
};

interface CourseUserCardProps {
  users: UserWithCourseActivity[];
  className?: string;
  canModifyUsers?: boolean;
  headerTitle: string;
  headerCount: string;
  headerIcon: ReactNode;
  showEmpty?: boolean;
  filterUsers: (users: UserWithCourseActivity[]) => UserWithCourseActivity[];
}

const handleDisableUser = (
  user: UserWithCourseActivity,
  course: { title: string },
  courseCode: { title: string },
) => {
  trackEvent(user.is_disabled ? "User Options - Restore user" : "User Options - Disable user", {
    eventCategory: "Button press",
    userEmail: user.email,
    userGivenName: user.first_name,
    userFamilyName: user.last_name,
  });

  const role = t(`roles.course_${user.role}`);
  const name = getUserFullName(user);
  const courseName = t("common.course_code_and_title", {
    course_title: course.title,
    course_code_title: courseCode.title,
  });

  const modalAction = user.is_disabled ? "grant" : "restrict";

  return showConfirmationModal({
    title: t(`user_options_menu.role.${modalAction}_title`, { role }),
    labelText: t(`user_options_menu.role.${modalAction}_label`, {
      name,
      course: courseName,
    }),
    confirmButtonText: t(`user_options_menu.role.${modalAction}_button_text`),
    confirmButtonKind: user.is_disabled ? "primary" : "destructive",
    onConfirm: () => {
      trackEvent(
        user.is_disabled ? "Restore account modal - Confirm" : "Restrict account modal - Confirm",
        { eventCategory: "Button press" },
      );
      storeApi.course_users.partial_update({
        id: user.course_user_id,
        is_disabled: !user.is_disabled,
      });
    },
  });
};

export const CourseUserCard: FC<CourseUserCardProps> = ({
  users,
  className,
  canModifyUsers = false,
  headerTitle,
  headerCount,
  headerIcon,
  showEmpty = false,
  filterUsers,
}) => {
  const currentCourse = useAppSelector(selectCurrentCourse);
  const currentCourseCode = useAppSelector(selectCurrentCourseCode);
  const filteredUsers = sortBy<UserWithCourseActivity>(filterUsers(users), [
    getActiveStateSortKeyForUser,
    getUserLastNameSortKey,
  ]);
  const isMpAdmin = useAppSelector(selectCurrentUserIsMPAdmin);
  const canImpersonate = isMpAdmin;
  const [, copyToClipboard] = useCopyToClipboard();

  if (!showEmpty && filteredUsers.length === 0) {
    return null;
  }

  return (
    <Card className={clsx("pt-0", className)}>
      <div className="sticky top-0 flex w-full flex-row items-center justify-between bg-white py-2">
        <span>{headerTitle}</span>
        <IconText className="text-black-tint-40" iconStart={headerIcon} text={headerCount} />
      </div>
      <ol className="flex list-none flex-col gap-2 p-0">
        {filteredUsers.map((user) => (
          <li
            className={clsx("flex flex-row items-center justify-between gap-2", {
              offline:
                user.courseActivityStatus === CourseUserActivityStatus.OFFLINE && !user.is_disabled,
            })}
            key={user.id}
          >
            <div className="flex flex-row items-center gap-2" title={user.email}>
              {userActivityIcons[user.is_disabled ? "disabled" : user.courseActivityStatus]}
              <span className={clsx({ "text-black-tint-55": user.is_disabled })}>
                {getUserFullName(user)}
              </span>
            </div>
            <div className="flex flex-row items-center gap-2">
              {canModifyUsers && (
                <CourseUserOptionsMenu
                  createdInLms={user.creation_source === "lms"}
                  currentRole={user.role}
                  email={user.email}
                  is_disabled={user.is_disabled}
                  onChangeRole={(newRole) => {
                    trackEvent("User options - Change user role", {
                      eventCategory: "Button press",
                      userEmail: user.email,
                      userGivenName: user.first_name,
                      userFamilyName: user.last_name,
                      newRole,
                    });

                    storeApi.course_users
                      .partial_update({
                        id: user.course_user_id,
                        role: newRole,
                      })
                      .catch(
                        toastLocalizedOperationCatcher("update_failure", {
                          item: t("glossary.user_role"),
                        }),
                      );
                  }}
                  onClickDisable={() => handleDisableUser(user, currentCourse, currentCourseCode)}
                  onImpersonate={
                    canImpersonate
                      ? () => {
                          serverCreateImpersonationUrl(user.id)
                            .then((url) => {
                              const serverDomain =
                                window._env_.SERVER_URL.slice(0, -1) || window.location.origin;
                              copyToClipboard(`${serverDomain}${url}`);
                              toastSuccessMessage(t("user_options_menu.impersonate_user_success"));
                            })
                            .catch((e) =>
                              toastLocalizedOperationCatcher("failed_to_impersonate_user")(e),
                            );
                        }
                      : null
                  }
                />
              )}
            </div>
          </li>
        ))}
      </ol>
    </Card>
  );
};
