// Copied from FDS, unmodified
import "./ToggleButton.scss";

import clsx from "clsx";
import type { ChangeEvent, FC } from "react";

import { useControllable } from "../hooks/use-controllable";

type ToggleButtonProps = {
  className?: string;
  value?: boolean;
  initialValue?: boolean;
  label: string;
  onChange: (value: boolean, event: ChangeEvent<HTMLLabelElement>) => void;
  id: string;
  disabled?: boolean;
};

const ToggleButton: FC<ToggleButtonProps> = ({
  className,
  label,
  value: controlledValue,
  id,
  initialValue = false,
  onChange,
  disabled,
}) => {
  const [value, setValue] = useControllable<boolean, ChangeEvent<HTMLLabelElement>>(
    controlledValue,
    initialValue,
    onChange,
  );

  return (
    <div className={className}>
      <label
        className={clsx("toggle-button", value && "active", disabled && "disabled")}
        htmlFor={id}
        onChange={(event: ChangeEvent<HTMLLabelElement>) => setValue(!value, event)}
      >
        <div className={clsx("invisible", disabled && "text-black-tint-70")}>{label}</div>
        <input className="hidden" disabled={disabled} id={id} name={id} type="checkbox" />
      </label>
    </div>
  );
};

export { ToggleButton };
