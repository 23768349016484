import {
  STATIC_CONTENT_BLOCK_TYPES,
  STATIC_CONTENT_FIELD_TYPES,
} from "../../worksheets/shared/constants";
import type {
  AssetFieldType,
  ChoiceFieldType,
  CodeCellType,
  CodeFieldType,
  EmbedFieldType,
  FieldType,
  FileFieldType,
  FreeResponseFieldType,
  InstructionsFieldType,
  MultipleChoiceCellType,
  RichTextCellType,
  TableDimensionsType,
  TableFieldType,
  WorksheetType,
} from "../../worksheets/shared/types/worksheet";

import { docCache } from "providers/ShareDBDoc";

export const pageContentToContextString = (pageContext: unknown) => {
  return `The instructions for the current Page are: ${JSON.stringify(pageContext)}`;
};

export const studentAnswerToContextString = (pageContext: unknown, name?: string) => {
  const handle = name ? `Student ${name}` : "The student";
  return `${handle} responded with: ${JSON.stringify(pageContext)}`;
};

/*
 Some of our example pages, e.g., "agile-innovation-activities.tx" use the
 Zero Width Non-breaking Space" character, in that case to surround the "3" prefaced learning outcomes.
 Below we remove it from any strings that will be send to the LLM (that may not be the right thing to do.)
*/
const zero_width_non_breaking_space = "\\ufeff";
const non_breaking_space = "\\00a0";

const richTextToPlainText = (value: RichTextCellType) => {
  let fieldContent = "";

  if (value) {
    const ops = value.ops;
    if (ops) {
      ops.forEach((op) => {
        if (op.insert && typeof op.insert === "string") {
          const insert: string = op.insert;
          fieldContent += insert
            .replace(non_breaking_space, " ")
            .replace(zero_width_non_breaking_space, " ");
        }
      });
    }
  }

  return fieldContent;
};

const getTableCellContent = (
  tableData: TableDimensionsType | null,
  rows: string[],
  cols: string[],
) => {
  let fieldValue = "";
  const cellMap = tableData?.cm;
  if (!cellMap) {
    return fieldValue;
  }

  Object.entries(cellMap).forEach(([cellId, cellValue]) => {
    const [row, col] = cellId.split(",");
    const rowIndex = rows.indexOf(row) + 1;
    const colIndex = cols.indexOf(col) + 1;
    const readOnly = cellValue.ro ? " (Read Only)" : "";
    const cellContent = richTextToPlainText(cellValue.v);

    fieldValue += `Row ${rowIndex}, Column ${colIndex}${readOnly}: "${cellContent}", `;
  });

  return fieldValue;
};

type FieldContextType = {
  // A human-readable explanation of the field/question
  explanation: string;
  // For instructor-provided content, the content of the field
  // TODO: Handle file uploads
  content?: string;
  // For Free Response and Code fields, plain text content that is prefilled by the instructor
  prefilled_content?: string;
  // For Table fields only, the content of the table, with prefilled cells
  table?: string;
  // For Multiple Choice fields, the choices that students can select from
  choices?: string[];
  // For any question field, the instructions that go along with the question
  instructions?: string;
};

type FieldStudentAnswerContextType = {
  // For Free Response and Code fields, the student answer as plain text content
  answer?: string;
  // For Multiple Choice fields, the student's selected choice(s)
  selected_choices?: number[];
  // For Table fields only, the content of the table, after the student filled in the table
  table?: string;
  // For File Upload fields and Free Response fields, any uploaded files
  // TODO
  // TODO: Also add a note for the LLM right now that adds "you currently don't have access to file uploads
  // and should refuse to assess them"
  files?: unknown[];
};

const fieldToContextEntry: Record<
  FieldType["t"],
  (field: FieldType, index?: number) => FieldContextType
> = {
  // Instruction types
  Text: (field: InstructionsFieldType, index) => ({
    explanation: index
      ? `Instructor-provided content: Instructions for Question ${index}`
      : "Instructor-provided content: General Instructions",
    content: richTextToPlainText(field.v),
  }),
  EmbedField: (field: EmbedFieldType) => ({
    explanation: "Instructor-provided content: Embedded Resource",
    content: field.url,
  }),
  AssetField: (field: AssetFieldType) => ({
    explanation: "Instructor-provided content: File",
    content: field.name,
  }),
  // Question types
  ChoiceField: (field: ChoiceFieldType, index) => {
    const choiceType = field.m ? "one or multiple" : "one";
    const explanation = `This field is a multiple choice question, students can select ${choiceType} of the options. Indexes in "selected_choices" start at 0.`;
    const title = `Question ${index}: Multiple Choice`;
    return {
      explanation: `${title} (${explanation})`,
      choices: field.c.map(
        (choice, choiceIndex) => `${String.fromCharCode(97 + choiceIndex)}) ${choice.l}`,
      ),
    };
  },
  FileField: (field: FileFieldType, index) => ({
    explanation: `Question ${index}: File Upload (This field prompts students to upload ${field.m ? "one or multiple" : "one"} file.)`,
  }),
  // Mixed types (content that is partially instructor provided but can be modified/filled out by students)
  RichTextField: (field: FreeResponseFieldType, index) => ({
    explanation: `Question ${index}: Free Response (This field is a question that the student answers with a free-form text response)`,
    prefilled_content: richTextToPlainText(field.v),
  }),
  TableField: (field: TableFieldType, index) => ({
    explanation: `Question ${index}: Table (This field is a table question, students can fill in the table, some fields are prefilled)`,
    table: getTableCellContent(field.v, field.v.r, field.v.c),
  }),
  CodeField: (field: CodeFieldType, index) => ({
    explanation: `Question ${index}: Code (This field is a code block with possibly prefilled code that the student can edit and run.)`,
    prefilled_content: field.c,
  }),
};

/**
 * This takes worksheet data and creates a json object that represents the instructions
 * and pre-filled content for each question in the worksheet. Questions get numbered
 * relative to their position in the document, ignoring static fields, like instructions,
 * assets, and embeds.
 *
 * This function is just a wrapper around fieldToContextEntry that handles the iteration
 * and numbering of fields. Actual field to context transformation is done in fieldToContextEntry.
 *
 ** Example Output **
 * [
 *  {
 *    explanation: "General Instructions",
 *    content: "For each passage below, ...",
 *  },
 *  {
 *    explanation: "Question 1: Multiple Choice (This field is a multiple choice question, students can select one of the options)",
 *    instructions: "...",
 *    choices: [...],
 *  }
 * ]
 */
export const docToInstructionsContext = (
  docData: WorksheetType,
): FieldContextType[] | undefined => {
  const { current: currentDoc } = docCache;
  const data = docData || currentDoc?.data;
  if (!data) {
    return undefined;
  }

  let questionIndex = 0;
  return data.b.map((block) => {
    const blockType = block.t;
    const isQuestionBlock = !STATIC_CONTENT_BLOCK_TYPES.includes(blockType);

    const question: FieldContextType = {
      explanation: "",
    };
    if (isQuestionBlock) {
      questionIndex += 1;
    }

    block.f.forEach((field: FieldType) => {
      const fieldType = field.t;
      const isInstructionsText = fieldType === "Text";

      // If we find an instructions field inside a question block, we'll add it as the instructions
      // for that question block. We distinguish these
      if (isQuestionBlock && isInstructionsText) {
        const fieldContext = fieldToContextEntry[fieldType](field, questionIndex);
        question.instructions = fieldContext.content;
      } else if (isInstructionsText) {
        const fieldContext = fieldToContextEntry[fieldType](field);
        question.explanation = fieldContext.explanation;
        question.content = fieldContext.content;
      } else {
        const fieldContext = fieldToContextEntry[fieldType](field, questionIndex);
        if (fieldContext.explanation) {
          question.explanation = fieldContext.explanation;
        }
        if (fieldContext.content) {
          question.content = fieldContext.content;
        }
        if (fieldContext.prefilled_content) {
          question.prefilled_content = fieldContext.prefilled_content;
        }
        if (fieldContext.choices) {
          question.choices = fieldContext.choices;
        }
      }
    });

    return question;
  });
};

/**
 * This takes worksheet data and creates a json object that represents the student's
 * answers to each question in the worksheet. Questions get numbered relative to their
 * position in the document, ignoring static fields, like instructions, assets, and embeds.
 *
 ** Example Output **
 * {
 *  "Question 1": {
 *    "answer": "some answer",
 *  },
 *  "Question 2": {
 *    "choices": [1, 3],
 *  },
 *  "Question 3": {
 *    "answer": "print('some code by the student')"
 *  }
 * }
 */
export const docToStudentAnswersContext = (
  docData: WorksheetType,
  accessId: string,
): FieldStudentAnswerContextType => {
  const { current: currentDoc } = docCache;
  const data = docData || currentDoc?.data;
  if (!data) {
    return undefined;
  }

  const questionBlocks = data.b.filter((block) => !STATIC_CONTENT_BLOCK_TYPES.includes(block.t));
  const questionFields = questionBlocks.map((block) =>
    block.f.find((field) => !STATIC_CONTENT_FIELD_TYPES.includes(field.t)),
  );

  const studentAnswers: Record<string, FieldStudentAnswerContextType> = {};

  questionFields.forEach((field, index) => {
    const fieldType = field.t;
    const studentAnswer: FieldStudentAnswerContextType = {};

    const studentData = data.d[accessId];
    if (!studentData) {
      studentAnswer.answer = "<no answer>";
      studentAnswers[`Question ${index + 1}`] = studentAnswer;
      return;
    }

    const studentFieldData = studentData[field.id];
    if (!studentFieldData) {
      studentAnswer.answer = "<no answer>";
      studentAnswers[`Question ${index + 1}`] = studentAnswer;
      return;
    }

    if (fieldType === "ChoiceField") {
      const cell = studentFieldData as MultipleChoiceCellType;
      studentAnswer.selected_choices = cell ? cell.filter((v) => typeof v === "number") : [];
    } else if (fieldType === "RichTextField") {
      studentAnswer.answer = studentFieldData
        ? richTextToPlainText(studentFieldData as RichTextCellType)
        : "<no answer>";
    } else if (fieldType === "TableField") {
      const table = studentFieldData as TableDimensionsType;
      studentAnswer.table = getTableCellContent(table, table.r, table.c);
    } else if (fieldType === "CodeField") {
      studentAnswer.answer = (studentFieldData as CodeCellType)?.c || "";
    } else if (fieldType === "FileField") {
      studentAnswer.files = studentFieldData as unknown[];
    } else {
      throw new Error(`Unimplemented field type: ${fieldType}`);
    }

    studentAnswers[`Question ${index + 1}`] = studentAnswer;
  });

  return studentAnswers;
};
