// eslint-disable-next-line no-restricted-imports
import {
  Description,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  CloseButton as HeadlessCloseButton,
} from "@headlessui/react";
import type { FC, ReactNode } from "react";

import { Button, type IconKind } from "./Button";
import { CloseButton } from "./CloseButton";

import { t } from "i18n/i18n";
import { trackEvent } from "utils/amplitude";

type ConfirmationDialogProps = {
  open: boolean;
  onClose: () => void;
  /** The title is used for accessibility purposes, and is displayed at the top of the dialog. */
  title?: string;
  /** The description is used for accessibility purposes. */
  description?: string;
  /** Use the `children` prop to render what you want in the body of the dialog. */
  children: ReactNode;

  /** If `standardButtons` is true, the dialog will have standard cancel/confirm buttons.
   * Need something custom? Keep `standardButtons` as false (the default value),
   * and pass your own buttons as children. Ignore the `onConfirm` prop, as well as
   * all other button-related props on this component.
   */
  standardButtons?: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  confirmButtonKind?: IconKind;
  onConfirm?: () => void;
};

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  title,
  description,
  children,
  standardButtons = false,
  cancelButtonText = t("common.cancel"),
  confirmButtonText = t("common.confirm"),
  confirmButtonKind = "primary",
  onConfirm = () => {},
}) => {
  const closeAndTrack = () => {
    trackEvent("Close modal", { eventCategory: "Button press" });
    onClose();
  };

  return (
    <Dialog className="relative z-50" open={open} onClose={closeAndTrack}>
      <div className="fixed inset-0 z-50">
        <DialogBackdrop className="absolute inset-0 bg-black/50" />

        <div className="absolute left-0 right-0 top-0 flex max-h-full w-full items-center justify-center p-4 md:p-10 md:pt-[175px]">
          <div className="overflow-y-auto rounded-lg border border-black-tint-90 bg-white p-4 md:p-8">
            <DialogPanel className="w-[400px]">
              <div className="flex items-center justify-between">
                <DialogTitle className="text-base font-normal">{title}</DialogTitle>
                <HeadlessCloseButton as={CloseButton} onClick={closeAndTrack} />
              </div>
              {description && <Description>{description}</Description>}

              {children}

              {standardButtons && (
                <div className="flex justify-end gap-2">
                  <Button kind="secondary" title={cancelButtonText} onClick={closeAndTrack}>
                    {cancelButtonText}
                  </Button>

                  <Button
                    kind={confirmButtonKind}
                    title={confirmButtonText}
                    onClick={() => {
                      onConfirm();
                      onClose();
                    }}
                  >
                    {confirmButtonText}
                  </Button>
                </div>
              )}
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
