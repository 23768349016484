import { toastCopyOperationCatcher, toastSuccessMessage } from "./alerts";
import { determineOrderForNewAppend } from "./collections";
import { blankInstructorNotesWorksheetData } from "./worksheet";

import { serverCopyTopic } from "api/api-server";
import { TopicType } from "components/server-types";
import { t } from "i18n/i18n";
import { storeApi } from "store/index";

export const useTopicActions = () => {
  const createTopic = (topics: TopicType[], courseId: string) =>
    storeApi.topics.create({
      course_id: courseId,
      title: t("common.default.topic"),
      order: determineOrderForNewAppend(topics),
      instructor_notes_worksheet_data: blankInstructorNotesWorksheetData(),
    });

  const deleteTopic = (topic: TopicType) => storeApi.topics.destroy(topic.id);

  const saveTopic = (topic: Partial<TopicType> & { id: string }) =>
    storeApi.topics.partial_update(topic);

  const dropTopic = (topic: TopicType) => saveTopic({ id: topic.id, order: topic.order });

  const copyTopic = (itemId: string, courseIds: string[]) =>
    serverCopyTopic(itemId, courseIds)
      .then(() => {
        toastSuccessMessage(t("content_view.item_copied", { item: t("glossary.topic") }));
      })
      .catch(toastCopyOperationCatcher("topics"));

  return { createTopic, copyTopic, dropTopic, deleteTopic, saveTopic };
};
