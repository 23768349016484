/**
 * Django uses Collaboration to distribute messages to users. This file defines the types
 * and content these messages can have.
 *
 * These messages are not persisted, and are only relevant to inform UI or permissions changes for
 * active users, under the assumption that the same data can be retrieved from Django API at any time.
 */

import type { AnalyticsMessageType } from "./analytics";
import { WebsocketUserDataMessageType } from "./websocket-auth";

export const PUBSUB_MESSAGE_TYPE = "pubsub";

/**
 * A list of messages is wrapped into one type, so that we can guarantee relative ordering
 * between multiple messages. E.g. if a model gets updated, and the permissions related to that
 * model get updated at the same time, we need to parse the permission update first.
 */
export type PubsubMessageType = {
  type: typeof PUBSUB_MESSAGE_TYPE;
  messages: PubsubMessageKindType[]; // These are the possible message types that will be forwarded to client.
};
export type PubsubMessageKindType =
  | PubsubAuthUpdateType
  | AnalyticsMessageType
  | (ClientPubsubModelUpdateType | CollabPubsubModelUpdateType);

export type TargetingOptionsType = {
  // These fields are stripped by collab before passing to client
  // Note that roles here are different from actual roles in the UI, as targeting is less
  // granular, as "staff" encompasses all roles that can view unpublished content in a course.
  target_role: "all" | "student" | "staff";
  target_course_id: string | null;
  target_org_id: string | null;
};

export type ModelUpdateMessageType = {
  action: "create" | "update" | "delete";
  model: string; // TODO: Type to existing model names
  data: unknown; // TODO: Type to existing model data
};

export type CourseVersionMessageType = {
  type: "model-update";
  course_version: number; // 0 means that there's no versioning yet, or the model isn't course versioned
  course_id: string;
};

export type CourseVersionModelUpdateType = ModelUpdateMessageType & CourseVersionMessageType;

// This is the type of model update message that is forwarded to the client from collaboration.
export type ClientPubsubModelUpdateType = CourseVersionMessageType | CourseVersionModelUpdateType;

// This is the type of model update message that is forwarded to collaboration from server.
export type CollabPubsubModelUpdateType = CourseVersionModelUpdateType & TargetingOptionsType;

export interface PubsubAuthUpdateType {
  type: "auth-update";
  data: WebsocketUserDataMessageType;
}

export interface SocketPongType {
  type: "pong";
  data?: unknown;
}
