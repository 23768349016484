import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const RobotAIIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <rect
      className="stroke"
      height="11.622"
      rx="2.667"
      strokeWidth="1.06667"
      width="13.867"
      x="1.066"
      y="3.456"
    />
    <path
      className="fill"
      d="M 4 12.83 C 4 12.54 4.341 12.32 4.643 12.386 C 5.24 12.516 6.298 12.667 8 12.667 C 9.702 12.667 10.76 12.516 11.357 12.386 C 11.659 12.32 12 12.54 12 12.83 C 12 13.009 11.871 13.164 11.684 13.202 C 11.043 13.332 9.521 13.603 8 13.603 C 6.479 13.603 4.957 13.332 4.316 13.202 C 4.129 13.164 4 13.009 4 12.83 Z"
      transform="matrix(1, 0, 0, 1, 0, -1.353728)"
    />
    <rect
      className="fill"
      height="1.998"
      rx="1.067"
      transform="matrix(1, 0, 0, 1, 0, -1.353728)"
      width="2.133"
      x="3.732"
      y="6.952"
    />{" "}
    <rect
      className="fill"
      height="1.998"
      rx="1.067"
      transform="matrix(1, 0, 0, 1, 0, -1.353728)"
      width="2.133"
      x="10.133"
      y="6.952"
    />
    <path
      className="fill"
      d="M 5.751 0.922 C 5.725 0.647 5.467 0.444 5.173 0.468 C 4.88 0.492 4.663 0.735 4.688 1.009 L 5.751 0.922 Z M 5.955 3.112 L 5.751 0.922 L 4.688 1.009 L 4.893 3.199 L 5.955 3.112 Z"
    />
    <path
      className="fill"
      d="M 11.31 1.009 C 11.335 0.735 11.118 0.492 10.825 0.468 C 10.531 0.444 10.273 0.647 10.247 0.922 L 11.31 1.009 Z M 11.106 3.199 L 11.31 1.009 L 10.247 0.922 L 10.043 3.112 L 11.106 3.199 Z"
    />
  </svg>
);
