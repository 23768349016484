import clsx from "clsx";
import { ChangeEvent, type FC, useState } from "react";

import { CancelSaveMenu } from "components/menus/CancelSaveMenu";
import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { TextField } from "mds/components/TextField";
import { ChevronDownIcon, PersonSingleIcon } from "mds/icons";
import { storeApi } from "store/index";

interface GroupSizeConfigProps {
  page: PageType;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  title: string;
}
export const GroupSizeConfig: FC<GroupSizeConfigProps> = ({
  page,
  isDisabled,
  isReadOnly,
  title,
}) => {
  const [currentGroupSize, setCurrentGroupSize] = useState(page.max_group_size);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const onMaxGroupSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const newMaxGroupSize = input === "" ? null : parseInt(input, 10);
    if (newMaxGroupSize === null || (!isNaN(newMaxGroupSize) && newMaxGroupSize > 0)) {
      setIsSaveDisabled(false);
      setCurrentGroupSize(newMaxGroupSize);
    } else {
      setCurrentGroupSize(null);
      setIsSaveDisabled(true);
    }
  };

  return (
    <CancelSaveMenu
      button={
        <Button
          className="gap-1"
          disabled={isDisabled || isReadOnly}
          kind="secondary"
          size="xs"
          title={title}
        >
          <PersonSingleIcon />
          {page.max_group_size !== null
            ? t("group_configuration_card.group_size.groups_of_less", {
                count: page.max_group_size,
              })
            : t("group_configuration_card.group_size.no_group_size_limit")}
          {!isReadOnly && <ChevronDownIcon />}
        </Button>
      }
      isSaveDisabled={isSaveDisabled}
      title={t("group_configuration_card.config.size")}
      onCancel={() => setCurrentGroupSize(page.max_group_size)}
      onSave={() => {
        storeApi.pages.partial_update({ id: page.id, max_group_size: currentGroupSize });
      }}
    >
      <div className="flex w-full flex-col">
        <TextField
          className="w-full"
          min="1"
          placeholder={t("group_configuration_card.group_size.no_limit")}
          size="s"
          startIcon={<PersonSingleIcon />}
          type="number"
          value={currentGroupSize === null ? "" : currentGroupSize.toString()}
          isEditing
          onChange={(e) => onMaxGroupSizeChange(e)}
        />

        <div
          className={clsx(
            "body-xxs flex w-full justify-end",
            isSaveDisabled ? "text-red" : "text-black-tint-70",
          )}
        >
          {isSaveDisabled
            ? t("group_configuration_card.group_size.error")
            : currentGroupSize === null
              ? t("group_configuration_card.group_size.no_size_limit")
              : t("group_configuration_card.group_size.limited_to", { count: currentGroupSize })}
        </div>
      </div>
    </CancelSaveMenu>
  );
};
