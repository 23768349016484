import type { FC } from "react";
import { Link } from "react-router-dom";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { usePageUrl } from "mds/hooks/use-page-url";
import { useIsLgOrLarger } from "mds/hooks/use-responsive";
import { trackEvent } from "utils/amplitude";

export type FollowButtonProps = {
  pageId: string;
};

export const FollowButton: FC<FollowButtonProps> = ({ pageId }) => {
  const pageURL = usePageUrl(pageId);
  const isLargeScreen = useIsLgOrLarger();
  const onClick = () => {
    trackEvent("Presentation - Follow along", { eventCategory: "Button press" });
  };

  return isLargeScreen ? (
    <Button color="orange" kind="primary" size="xs" to={pageURL} onClick={onClick}>
      {t("main_header.follow")}
    </Button>
  ) : (
    <Link
      className="rounded-md bg-white px-2 py-1 text-black-tint-20 hover:text-black-tint-20"
      to={pageURL}
      onClick={onClick}
    >
      {t("main_header.follow_short")}
    </Link>
  );
};
