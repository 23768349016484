import clsx from "clsx";
import { FC } from "react";

import { ContentNavigationButtons } from "components/materials/ContentNavigationButtons";
import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { ChevronLeftIcon, ChevronRightIcon } from "mds/icons";

interface AssessmentGradingNavProps {
  isLastPanel?: boolean;
  isNavigationDisabled?: boolean;
  nextURL?: URL;
  page: PageType;
  prevURL?: URL;
  setShowLastPanel: (show: boolean) => void;
}

export const AssessmentGradingNav: FC<AssessmentGradingNavProps> = ({
  setShowLastPanel,
  prevURL,
  nextURL,
  page,
  isNavigationDisabled,
  isLastPanel,
}) => {
  // If this is the last panel, the nav buttons should be a disabled > button and
  // a < button that goes back to the last assessment right before this panel.
  if (isLastPanel) {
    return (
      <div className={clsx("hidden items-center md:flex")}>
        <Button
          aria-label={t("common.previous")}
          className="!rounded-r-none rounded-l-xl"
          kind="secondary"
          title={t("tooltip.previous.submission")}
          iconOnly
          onClick={() => setShowLastPanel(false)}
        >
          <ChevronLeftIcon />
        </Button>

        <Button
          aria-label={t("common.next")}
          className="!rounded-l-none rounded-r-xl"
          kind="secondary"
          title={t("tooltip.next.submission")}
          disabled
          iconOnly
        >
          <ChevronRightIcon />
        </Button>
      </div>
    );
  }

  // If the assessments have already been published, then the instructor cannot access
  // the last panel. The nav buttons should behave as expected to navigate the instructor
  // between the  users' submissions. But if they are published, the nav buttons should
  // behave as expected only when the instructor is not viewing the penultimate panel.
  if (page.assessments_published_at || !!nextURL) {
    return (
      <ContentNavigationButtons
        className="!min-h-0"
        disabled={isNavigationDisabled}
        reference="submission"
      />
    );
  }

  // This is the penultimate panel. The instructor can navigate to the last panel with
  // the > and the < button behaves as expected.
  return (
    <div className={clsx("hidden items-center md:flex")}>
      <Button
        aria-label={t("common.previous")}
        className="!rounded-r-none rounded-l-xl"
        disabled={isNavigationDisabled || !prevURL}
        kind="secondary"
        title={t("tooltip.previous.submission")}
        to={prevURL ? prevURL.toString() : undefined}
        iconOnly
      >
        <ChevronLeftIcon />
      </Button>

      <Button
        aria-label={t("common.next")}
        className="!rounded-l-none rounded-r-xl"
        disabled={isNavigationDisabled}
        kind="secondary"
        title={t("tooltip.next.submission")}
        iconOnly
        onClick={() => setShowLastPanel(true)}
      >
        <ChevronRightIcon />
      </Button>
    </div>
  );
};
