import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { useAppDispatch, useAppSelector } from "store/index";
import { selectCanAuthorCourse, selectIsStudentPreview } from "store/selectors";
import { viewStateActions } from "store/slices/view";
import { trackEvent } from "utils/amplitude";

export const StudentPreview = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const isStudentPreview = useAppSelector(selectIsStudentPreview);

  const startStudentPreview = useCallback(() => {
    trackEvent("Account settings - Preview as a student", {
      eventCategory: "Button press",
    });
    dispatch(viewStateActions.startStudentPreview());
  }, [dispatch]);

  const stopStudentPreview = useCallback(() => {
    trackEvent("Account settings - Stop preview as a student", {
      eventCategory: "Button press",
    });
    dispatch(viewStateActions.stopStudentPreview());
  }, [dispatch]);

  return (
    <>
      {isStudentPreview && (
        <Button className="-ml-2 text-black" kind="tertiary" onClick={stopStudentPreview}>
          {t("profile_panel.stop_student_preview")}
        </Button>
      )}

      {!isStudentPreview && canAuthorCourse && (
        <Button
          className="-ml-2"
          kind="tertiary"
          to={{ pathname: ".", search: `?${searchParams.toString()}&student=1` }}
          onClick={startStudentPreview}
        >
          {t("course.sidebar.planner.preview_as_student")}
        </Button>
      )}
    </>
  );
};
