import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const ContactsIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <g clipPath="url(#clip0_446_386596)">
      <path
        className="stroke"
        d="M0.5 0.5H12C12.828 0.5 13.5 1.172 13.5 2V14C13.5 14.828 12.828 15.5 12 15.5H0.5V0.5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M10.5 3.5H3.5V7.5H10.5V3.5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path className="stroke" d="M15.5 2.5V4.5" strokeLinecap="round" strokeLinejoin="round" />
      <path className="stroke" d="M15.5 6.5V8.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_446_386596">
        <rect className="fill" height="16" width="16" />
      </clipPath>
    </defs>
  </svg>
);
