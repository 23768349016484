import { FC } from "react";

import { LOGO_DEFAULT_SIZE } from "components/constants";

export type ForumLogoIconProps = {
  width?: number;
};

const SVG_DEFAULT_HEIGHT = 18;
const SVG_DEFAULT_WIDTH = 68;

// Calculate the height to ensure we keep the desired aspect ratio given it's width
const calculateHeight = (width: number) => (width * SVG_DEFAULT_HEIGHT) / SVG_DEFAULT_WIDTH;

export const ForumLogoIcon: FC<ForumLogoIconProps> = ({ width = LOGO_DEFAULT_SIZE }) => (
  <svg
    fill="none"
    height={calculateHeight(width)}
    viewBox={`0 0 ${SVG_DEFAULT_WIDTH} ${SVG_DEFAULT_HEIGHT}`}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.72852 2.2959H10.7121V3.43648H3.02196V8.33982H9.58326V9.45689H3.02196V16.0887H1.72852V2.2959Z"
      fill="black"
    />
    <path
      d="M25.6582 2.2959H31.808C34.3008 2.2959 35.7236 3.71869 35.7236 6.01162C35.7236 8.30454 34.9946 8.82192 33.4072 9.32754V9.38634C34.83 9.86844 35.3003 10.915 35.3944 13.055C35.4884 15.2774 35.7471 15.8301 35.9941 16.0064V16.0887H34.6065C34.3008 15.8771 34.2185 15.3597 34.1009 13.008C33.9833 10.621 33.1602 9.93899 31.1495 9.93899H26.9399V16.0887H25.6465V2.2959H25.6582ZM31.3729 8.83368C33.3836 8.83368 34.4066 7.84596 34.4066 6.1292C34.4066 4.41245 33.6306 3.42472 31.5493 3.42472H26.9634V8.82192H31.3847H31.3729V8.83368Z"
      fill="black"
    />
    <path
      d="M36.6641 11.7145V2.2959H37.9575V11.597C37.9575 14.1486 39.3215 15.1833 41.732 15.1833C44.1425 15.1833 45.4948 14.1368 45.4948 11.597V2.2959H46.7882V11.7028C46.7882 14.9011 44.6717 16.2886 41.6732 16.2886C38.6748 16.2886 36.6641 14.8658 36.6641 11.7028V11.7145Z"
      fill="black"
    />
    <path
      d="M48.2813 2.2959H50.0334L54.7956 14.3249H54.8309L59.4755 2.2959H61.2863V16.0887H60.0282V4.07145H59.9929C59.9929 4.07145 59.6284 5.17676 59.3344 5.94107L55.3365 16.0887H54.1959L50.1627 5.94107C49.8687 5.22379 49.4925 4.07145 49.4925 4.07145H49.4572V16.0887H48.2578V2.2959H48.2813Z"
      fill="black"
    />
    <path
      d="M15.5919 14.6773C13.3343 13.8777 11.7116 11.7141 11.7116 9.17427C11.7116 6.63441 13.3343 4.48259 15.5919 3.67125V2.27197C12.5817 3.11859 10.3711 5.89362 10.3711 9.17427C10.3711 12.4549 12.5817 15.2182 15.5919 16.0766V14.6655V14.6773Z"
      fill="#DF2F26"
    />
    <path
      d="M19.5078 2.2832V3.69424C21.7655 4.49382 23.3764 6.6574 23.3764 9.1855C23.3764 11.7136 21.7655 13.8772 19.5078 14.6768V16.0878C22.518 15.2412 24.7286 12.4662 24.7286 9.1855C24.7286 5.90485 22.518 3.12982 19.5078 2.2832Z"
      fill="#DF2F26"
    />
    <path
      d="M63.2367 4.28408V2.4615H62.543V2.29688H64.1069V2.4615H63.4249V4.28408H63.2485H63.2367ZM66.0823 2.29688H66.3292V4.28408H66.1646V2.57908C66.1646 2.57908 66.0588 2.86129 65.9882 3.06118L65.4944 4.28408H65.318L64.8241 3.06118C64.7536 2.88481 64.6478 2.61436 64.6478 2.59084V4.29584H64.4714V2.29688H64.7183L65.4238 4.06067L66.1058 2.29688H66.0823Z"
      fill="black"
    />
  </svg>
);

export const ForumLogoWhiteIcon: FC<ForumLogoIconProps> = ({ width = LOGO_DEFAULT_SIZE }) => (
  <svg
    fill="none"
    height={calculateHeight(width)}
    viewBox={`0 0 ${SVG_DEFAULT_WIDTH} ${SVG_DEFAULT_HEIGHT}`}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.72852 2.2959H10.7121V3.43648H3.02196V8.33982H9.58326V9.45689H3.02196V16.0887H1.72852V2.2959Z"
      fill="white"
    />
    <path
      d="M25.6582 2.2959H31.808C34.3008 2.2959 35.7236 3.71869 35.7236 6.01162C35.7236 8.30454 34.9946 8.82192 33.4072 9.32754V9.38634C34.83 9.86844 35.3003 10.915 35.3944 13.055C35.4884 15.2774 35.7471 15.8301 35.9941 16.0064V16.0887H34.6065C34.3008 15.8771 34.2185 15.3597 34.1009 13.008C33.9833 10.621 33.1602 9.93899 31.1495 9.93899H26.9399V16.0887H25.6465V2.2959H25.6582ZM31.3729 8.83368C33.3836 8.83368 34.4066 7.84596 34.4066 6.1292C34.4066 4.41245 33.6306 3.42472 31.5493 3.42472H26.9634V8.82192H31.3847H31.3729V8.83368Z"
      fill="white"
    />
    <path
      d="M36.6641 11.7145V2.2959H37.9575V11.597C37.9575 14.1486 39.3215 15.1833 41.732 15.1833C44.1425 15.1833 45.4948 14.1368 45.4948 11.597V2.2959H46.7882V11.7028C46.7882 14.9011 44.6717 16.2886 41.6732 16.2886C38.6748 16.2886 36.6641 14.8658 36.6641 11.7028V11.7145Z"
      fill="white"
    />
    <path
      d="M48.2813 2.2959H50.0334L54.7956 14.3249H54.8309L59.4755 2.2959H61.2863V16.0887H60.0282V4.07145H59.9929C59.9929 4.07145 59.6284 5.17676 59.3344 5.94107L55.3365 16.0887H54.1959L50.1627 5.94107C49.8687 5.22379 49.4925 4.07145 49.4925 4.07145H49.4572V16.0887H48.2578V2.2959H48.2813Z"
      fill="white"
    />
    <path
      d="M15.5919 14.6773C13.3343 13.8777 11.7116 11.7141 11.7116 9.17427C11.7116 6.63441 13.3343 4.48259 15.5919 3.67125V2.27197C12.5817 3.11859 10.3711 5.89362 10.3711 9.17427C10.3711 12.4549 12.5817 15.2182 15.5919 16.0766V14.6655V14.6773Z"
      fill="white"
    />
    <path
      d="M19.5078 2.2832V3.69424C21.7655 4.49382 23.3764 6.6574 23.3764 9.1855C23.3764 11.7136 21.7655 13.8772 19.5078 14.6768V16.0878C22.518 15.2412 24.7286 12.4662 24.7286 9.1855C24.7286 5.90485 22.518 3.12982 19.5078 2.2832Z"
      fill="white"
    />
    <path
      d="M63.2367 4.28408V2.4615H62.543V2.29688H64.1069V2.4615H63.4249V4.28408H63.2485H63.2367ZM66.0823 2.29688H66.3292V4.28408H66.1646V2.57908C66.1646 2.57908 66.0588 2.86129 65.9882 3.06118L65.4944 4.28408H65.318L64.8241 3.06118C64.7536 2.88481 64.6478 2.61436 64.6478 2.59084V4.29584H64.4714V2.29688H64.7183L65.4238 4.06067L66.1058 2.29688H66.0823Z"
      fill="white"
    />
  </svg>
);
