import "./TableField.scss";

import clsx from "clsx";
import { FC, useContext, useMemo } from "react";
import type { Doc, ListDeleteOp, ObjectDeleteOp, Op } from "sharedb/lib/client";

import { VALUE_KEY, createTableCellId, getFieldShortId } from "../../shared/constants";
import type {
  PathType,
  TableCellId,
  TableFieldType,
  WorksheetType,
} from "../../shared/types/worksheet";
import { CellContextMenu, CellContextMenuProps } from "../CellContextMenu";

import { RichTextField } from "./RichText/RichTextField";

import { t } from "i18n/i18n";
import { useHoverable } from "mds/hooks/use-hoverable";
import { LockLockedIcon } from "mds/icons/LockLocked";
import { PencilEditIcon } from "mds/icons/PencilEdit";
import { DocViewContext } from "providers/DocViewProvider";
import { useAppSelector } from "store/index";
import { selectIsEditModeEnabled } from "store/selectors";
import { toastLocalizedOperationError } from "utils/alerts";

const MAX_COLUMN_COUNT = 8;
const MAX_ROW_COUNT = 32;

type TableCellProps = CellContextMenuProps & {
  tableCellReadOnly?: boolean;
  doc: Doc<WorksheetType>;
  id: string;
  path: PathType;
  toggleCell: (rowIndex: number, columnIndex: number) => void;
  numColumns: number;
};

const Cell: FC<TableCellProps> = ({
  id,
  path,
  doc,
  tableCellReadOnly = false,
  rowIndex,
  columnIndex,
  insertColumn,
  removeColumn,
  insertRow,
  removeRow,
  toggleCell,
  numColumns,
}) => {
  const { readOnly: readOnlyCtx, isFeaturing } = useContext(DocViewContext);
  const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);

  // We need to distinguish if the cell has been locked by the instructor from student editing (tableCellReadOnly)
  // though the instructor can still edit, versus the state where the instructor themselves are viewing the
  // worksheet in read-only mode (readOnly).
  const readOnly = readOnlyCtx || (tableCellReadOnly && (isFeaturing || !isEditModeEnabled));

  const showAuthoringControls = isEditModeEnabled && !readOnly && !isFeaturing;
  const { isHovering, hoverParentProps } = useHoverable();

  return (
    <td
      className={clsx(
        "cell secondary-font overflow-x-auto",
        tableCellReadOnly && "student-read-only",
        readOnly && "cell-disabled",
      )}
      id={id}
      style={{ maxWidth: `calc(1000px / ${numColumns})` }}
      {...hoverParentProps}
    >
      <div className="relative h-full w-full pb-3">
        <RichTextField
          characterCounter="NONE"
          doc={doc}
          path={path}
          readOnly={readOnly}
          create
          embedded
        />
        {showAuthoringControls && isHovering && (
          <div className="absolute right-1 top-1 z-[2] cursor-pointer">
            <CellContextMenu
              columnIndex={columnIndex}
              insertColumn={insertColumn}
              insertRow={insertRow}
              readOnly={tableCellReadOnly}
              removeColumn={removeColumn}
              removeRow={removeRow}
              rowIndex={rowIndex}
              toggleCell={toggleCell}
            />
          </div>
        )}
        {showAuthoringControls && isHovering && (
          <div
            className="absolute bottom-1 left-1 z-[2] flex cursor-pointer items-center justify-start gap-2 text-xs text-black-tint-55"
            onClick={() => toggleCell(rowIndex, columnIndex)}
          >
            {tableCellReadOnly ? (
              <>
                <LockLockedIcon />
                {t("fields.table.question_locked")}
              </>
            ) : (
              <>
                <PencilEditIcon />
                {t("fields.table.answer_editable")}
              </>
            )}
          </div>
        )}
      </div>
    </td>
  );
};

/*
Table Fields are a bit complicated.  We store the ids of rows and columns in separate array fields.
Individual cell values are only stored if they have a value.  The key for these cells is a combination of
row id and column id.   For example, the following is a table with 3 rows and 3 columns:

{
  id: "abcd",
  t: "TableField",
  ver: 1,
  v: {
    r: ['efgh', 'ijkl', 'mnop'],
    c: ['a123', 'b456', 'c789'],
    cm: {
      "efgh,b456": {v: {ops: [{insert: "hello"}, {retain: 1}, {insert: "↵"}]}},
      "efgh,c789": {ro: true},
      "mnop,c789": {v: {ops: [{insert: "world"}, {retain: 1}, {insert: "↵"}]}},
    },
  }
}

Only the 3 cells have values.  The first cell is row 1 column 2 and has the value of "hello", The
second cell is row 1 and column 3 and is read-only.  The third cell is row 3 column 3 and has the
value of "world".
*/

interface TableProps {
  doc: Doc<WorksheetType>;
  path: PathType;
  fieldPath: PathType;
}

export const TableField: FC<TableProps & TableFieldType> = ({ doc, path, fieldPath, v: value }) => {
  // `value` ((get(doc, fieldPath).v) is the value from the "blocks" section of the document,
  // no matter which mode we're in dimensions are only stored in the block section.
  // In the data section (doc[fieldPath]) we only store the actual cell values when we have user content.
  const fieldValuePath = [...fieldPath, VALUE_KEY];

  const insertColumn = (columnIndex: number) => {
    if (value.c.length >= MAX_COLUMN_COUNT) {
      toastLocalizedOperationError("column_count_limited_to", { count: MAX_COLUMN_COUNT });
      return;
    }

    const columnId = getFieldShortId();
    const firstRowId = value.r[0];
    doc.submitOp([
      {
        p: [...fieldValuePath, "c", columnIndex],
        li: columnId,
      },
      // First cell of the new column needs to be read-only
      {
        p: [...path, "cm", createTableCellId(firstRowId, columnId)],
        oi: { ro: true },
      },
    ]);
  };

  const removeColumn = (columnIndex: number) => {
    if (value.r.length <= 1) {
      toastLocalizedOperationError("cannot_remove_last_column");
      return;
    }

    const colId = value.c[columnIndex];
    const ops: Op[] = [
      // remove the column ID from `value.c`
      {
        p: [...fieldValuePath, "c", columnIndex],
        ld: colId,
      } as ListDeleteOp,
    ];

    const cellsInColumn = (Object.keys(value.cm) as TableCellId[]).filter(
      (cellId) => cellId.split(",")[1] === colId,
    );
    cellsInColumn.forEach((cellId) => {
      // remove the cell data from `value.cm`
      ops.push({
        p: [...path, "cm", cellId],
        od: value.cm[cellId],
      } as ObjectDeleteOp);
    });

    doc.submitOp(ops);
  };

  const insertRow = (rowIndex: number) => {
    if (value.r.length >= MAX_ROW_COUNT) {
      toastLocalizedOperationError("row_count_limited_to", { count: MAX_ROW_COUNT });
      return;
    }

    doc.submitOp({
      p: [...fieldValuePath, "r", rowIndex],
      li: getFieldShortId(),
    });
  };

  const removeRow = (rowIndex: number) => {
    if (value.r.length <= 1) {
      toastLocalizedOperationError("cannot_remove_last_row");
      return;
    }

    const rowId = value.r[rowIndex];
    const ops: Op[] = [
      // remove the row ID from `value.r`
      {
        p: [...fieldValuePath, "r", rowIndex],
        ld: rowId,
      } as ListDeleteOp,
    ];

    const cellsInRow = (Object.keys(value.cm) as TableCellId[]).filter(
      (cellId) => cellId.split(",")[0] === rowId,
    );
    cellsInRow.forEach((cellId) => {
      // remove the cell data from `value.cm`
      ops.push({
        p: [...path, "cm", cellId],
        od: value.cm[cellId],
      } as ObjectDeleteOp);
    });

    doc.submitOp(ops);
  };

  const toggleCell = (rowNumber: number, columnNumber: number) => {
    const rowId = value.r[rowNumber];
    const colId = value.c[columnNumber];
    const cellId = createTableCellId(rowId, colId);
    const exists = cellId in value.cm;

    if (exists) {
      const ro = !!value.cm[cellId].ro;
      doc.submitOp({
        p: [...fieldValuePath, "cm", cellId, "ro"],
        od: ro,
        oi: !ro,
      });
      return;
    }

    // need to create the cell data!
    doc.submitOp({
      p: [...fieldValuePath, "cm", cellId],
      oi: { ro: true },
    });
  };

  // We need to memoize this data structure, so that the path values stay identical across renders.
  // Because the path values are arrays, it's not enough for them to have equal contents;
  // they must be the *same array reference* to not trigger re-renders for children.
  const pathMap = useMemo(() => {
    const cellIds = value.r.flatMap((rowId) =>
      value.c.map((colId) => createTableCellId(rowId, colId)),
    );
    return cellIds.reduce(
      (acc, cellId) => {
        // eslint-disable-next-line no-param-reassign
        acc[cellId] = [...path, "cm", cellId, VALUE_KEY];
        return acc;
      },
      {} as Record<TableCellId, PathType>,
    );

    // Note that we can't use `value.r` and `value.c` in the dependency array,
    // even though the `react-hooks/exhaustive-deps` rule would suggest that we should.
    // Instead, we must specifically use their `length` values. Why?
    // Because when the dimensions of the table change, the `r` and `c` arrays
    // are *mutated*, not re-created. Because their references don't change,
    // React doesn't realize that the array has changed, and doesn't re-run the function.
    // Using the `length` property helps React understand when the arrays have mutated.
    // (We know that these arrays will only change by adding and removing IDs,
    // not by changing the IDs themselves, so this is safe.)
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.r.length, value.c.length, path]);

  const colWidth = `${((1 / value.c.length) * 100).toFixed(2)}%`;

  return (
    <table className="table-field">
      <colgroup>
        {value.r.map((colId) => (
          <col key={colId} width={colWidth} />
        ))}
      </colgroup>

      <tbody>
        {value.r.map((rowId, rowIndex) => (
          <tr key={rowId}>
            {value.c.map((colId, columnIndex: number) => {
              const cellId = createTableCellId(rowId, colId);
              const readOnly = !!value.cm[cellId]?.ro;

              // We could construct the `path` value here, but that's a bad idea.
              // In JS, arrays do not compare equal to each other, even if the contents are equal.
              // If we construct the `path` value here, we would get a new array every time,
              // and React would re-render the child components every time.
              // Instead, we use the memoized `pathMap` object to get the correct `path` value;
              // since it's memoized, we get the same array reference every time this component renders.
              const deepPath = pathMap[cellId];

              return (
                <Cell
                  columnIndex={columnIndex}
                  doc={doc}
                  id={cellId}
                  insertColumn={insertColumn}
                  insertRow={insertRow}
                  key={cellId}
                  numColumns={value.c.length}
                  path={deepPath}
                  removeColumn={removeColumn}
                  removeRow={removeRow}
                  rowIndex={rowIndex}
                  tableCellReadOnly={readOnly}
                  toggleCell={toggleCell}
                />
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
