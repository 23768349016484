import { FC } from "react";

import { InstructorNotesSidebarPanel } from "../../sidebar/InstructorNotesSidebarPanel";

import { CoursePageSidebarPanel } from "./CoursePageSidebarPanel";

import { AIChatPanel } from "components/ai/AIChatPanel";
import { CourseUsersSidebarPanel } from "components/materials/course/CourseUsersSidebarPanel";
import { SettingsSidebarPanel } from "components/materials/course/SettingsSidebarPanel";
import { ContentSidebar } from "components/materials/sidebar/ContentSidebar";
import { HelpCenterSidebarPanel } from "components/materials/sidebar/HelpCenterSidebarPanel";
import { UserProfileSidebarPanel } from "components/materials/sidebar/UserProfileSidebarPanel";
import { useUserCourseSettings } from "utils/course";

export const CoursePageSidebar: FC = () => {
  const { canAuthorCourse, isMPAdmin, canSeeAIPanel } = useUserCourseSettings();

  /* TODO: needs mobile design and resulting mobile handling */
  return (
    <ContentSidebar
      AiAssistantPanel={canSeeAIPanel ? <AIChatPanel context="course_page" /> : undefined}
      ContentPanel={<CoursePageSidebarPanel />}
      context="course_page"
      HelpCenterPanel={<HelpCenterSidebarPanel />}
      InstructorNotesPanel={canAuthorCourse && <InstructorNotesSidebarPanel />}
      ProfilePanel={<UserProfileSidebarPanel />}
      SettingsPanel={canAuthorCourse && <SettingsSidebarPanel />}
      UsersPanel={(canAuthorCourse || isMPAdmin) && <CourseUsersSidebarPanel />}
    />
  );
};
