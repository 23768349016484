import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const DuplicateCopyIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <g clipPath="url(#clip0_1098_156411)">
      <path
        className="stroke"
        d="M4.73333 15L15 15L15 4.73333L4.73333 4.73333L4.73333 15Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M2.8666 11.2667H0.999935L0.999935 0.999996L11.2666 0.999996V2.86666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1098_156411">
        <rect fill="white" height="16" width="16" />
      </clipPath>
    </defs>
  </svg>
);
