import { addDays, differenceInDays, format, isAfter, isFuture, isPast, subDays } from "date-fns";
import { sortBy } from "lodash";
import { useMemo } from "react";

import { WorksheetType } from "../../../../../collaboration/src/shared/types/worksheet";
import { getUserShortName } from "../../../utils/user-utils";
import {
  getGroupIdFromAccessId,
  getStudentIdFromAccessId,
  groupAccessIdFromGroupId,
  isGroupAccessId,
  isStudentAccessId,
  studentAccessIdFromUserId,
} from "../../../worksheets/shared/access-id";

import {
  EMPTY_DATE,
  formatDjangoDurationFieldToDaysCount,
} from "components/forms/due-date-form-helpers";
import {
  ActivityType,
  GroupingCategory,
  OrgRubricType,
  PageGroupType,
  PageType,
  ResponseType,
  TopicType,
} from "components/server-types";
import { DEFAULT_SHORT_DATE_FORMAT, monthDayAtTime } from "i18n/helpers";
import { t } from "i18n/i18n";
import { ButtonProps } from "mds/components/Button";
import { ORDER_BY_CREATED, useCustomOrdered } from "mds/hooks/use-ordered";
import { useWorksheet } from "providers/ShareDBDoc";
import { useAppSelector } from "store/index";
import {
  selectCurrentCourse,
  selectIsAssessing,
  selectPageGroupsByPage,
  selectSubmissionsForPage,
  selectUsersInCurrentCourse,
} from "store/selectors";
import { FullSubmissionType, UserWithCourseUserType } from "store/selectors/types";
import { useListSelector } from "store/store-hooks";

/**
 * Anonymize responses by shuffling them with a deterministic seed (pageId).
 * Using Fisher-Yates shuffle algorithm.
 */
export const anonymizeResponses = (
  page: PageType,
  responses: ResponseType[],
  groupingCategory: GroupingCategory,
): ResponseType[] => {
  const seededRandom = (seed: number) => {
    // Deterministic pseudo-random number generator
    const x = Math.sin(seed) * 10000;
    // Number returned is between 0 and 1
    return x - Math.floor(x);
  };
  // Create a seed by summing the char codes of the pageId
  let pageSeed = page.id.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);

  // We pre-sort by ID in case the responses aren't. Sometimes "order" is "0" for all responses
  // and we want to ensure that the order is consistent going into the anonymization function.
  const shuffledResponses = sortBy(responses, "id").slice();
  for (let i = shuffledResponses.length - 1; i > 0; i -= 1) {
    // Generate a pseudo-random index based on the seed
    const j = Math.floor(seededRandom(pageSeed) * (i + 1));
    // Swap elements at index i and j
    [shuffledResponses[i], shuffledResponses[j]] = [shuffledResponses[j], shuffledResponses[i]];
    pageSeed += 1; // Increment the seed to change the random number sequence
  }

  // Sort the responses by order, if they have one. Ordered by responses that have submissions first.
  shuffledResponses.sort((a, b) => (a.order || 0) - (b.order || 0));

  if (groupingCategory === GroupingCategory.INDIVIDUAL) {
    //   Rename to Student 1, Student 2, etc.
    return shuffledResponses.map((response, index) => ({
      title: t("common.student_title", { number: index + 1 }),
      id: response.id,
    }));
  }
  // Rename to Anon. Group 1, Anon. Group 2, etc.
  return shuffledResponses.map((response, index) => ({
    title: t("common.group_title_anonymous", { letter: indexToLetters(index) }),
    id: response.id,
  }));
};

export const indexToLetters = (origIndex: number) => {
  let currIndex = origIndex;
  let letters = "";
  while (currIndex >= 0) {
    letters = String.fromCharCode((currIndex % 26) + 65) + letters;
    currIndex = Math.floor(currIndex / 26) - 1;
  }
  return letters;
};

export const getOfficialSubmissionByAccessId = (
  accessId: string,
  courseUsers: UserWithCourseUserType[],
  officialSubmissions: FullSubmissionType[],
) => {
  if (isStudentAccessId(accessId)) {
    const userId = getStudentIdFromAccessId(accessId);
    const courseUserId = courseUsers.find((u) => u.user_id === userId)?.course_user_id;
    return officialSubmissions.find((s) => s.course_user_id === courseUserId);
  }

  const pageGroupId = getGroupIdFromAccessId(accessId);

  return officialSubmissions.find((s) => s.page_group_id === pageGroupId);
};

export const getResponses = (
  anonymize: boolean,
  isAssessing: boolean,
  page: PageType,
  groupingCategory: GroupingCategory,
  pageGroups: PageGroupType[],
  courseUsers: UserWithCourseUserType[],
  worksheet: WorksheetType,
  submissions: FullSubmissionType[],
): ResponseType[] => {
  // Class pages don't feature a responses tab
  if (groupingCategory === GroupingCategory.SHARED_CLASS) {
    return [];
  }

  let responses = [] as ResponseType[];

  // Offer the default "all responses" option first, unless we're in the middle of an assessment.
  if (!isAssessing) {
    responses.push({
      title: t("presentation.view_options.responses"),
      id: "",
    });
  }

  if (groupingCategory === GroupingCategory.INDIVIDUAL) {
    // Start by populating the options for all the known users according to CourseUser data.
    const knownUsers = courseUsers.filter((user) => user.role === "student");
    const knownUserResponses = knownUsers.map((user) => ({
      title: getUserShortName(user),
      id: studentAccessIdFromUserId(user.id),
      order: submissions.some((s) => s.course_user_id === user.course_user_id) ? 0 : 1,
    }));
    responses = responses.concat(knownUserResponses);

    // Potentially extend that set of options with additional users gleaned from the worksheet. This takes care
    // of the case of a Page created by means of uploading a JSON snapshot, which will tend to have a worksheet
    // that makes reference to Users that don't actually exist in the current environment.
    if (page?.is_imported && worksheet) {
      const knownUserIds = new Set(knownUsers.map((user) => user.id));
      const accessIdsFromWorksheet = Object.keys(worksheet.d).filter(isStudentAccessId);
      const userIdsFromWorksheet = accessIdsFromWorksheet.map(getStudentIdFromAccessId);
      const unknownUserIds = userIdsFromWorksheet.filter((id) => !knownUserIds.has(id));
      const unknownUserResponses = unknownUserIds.map((userId) => ({
        title: `User ${userId}`,
        id: studentAccessIdFromUserId(userId),
        order: submissions.some((s) => s.course_user?.user_id === userId) ? 0 : 1,
      }));
      responses = responses.concat(unknownUserResponses);
    }
  } else {
    // Start by populating the options for all the known groups according to PageGroup data.
    const knownGroupResponses = pageGroups.map((group, index) => ({
      title: t("common.group_title", { number: index + 1 }),
      id: groupAccessIdFromGroupId(group.id),
      order: submissions.some((s) => s.page_group_id === group.id) ? 0 : 1,
    }));
    responses = responses.concat(knownGroupResponses);

    // Potentially extend options with additional groups gleaned from the worksheet, similar to above.
    if (page?.is_imported && worksheet) {
      const knownGroupIds = new Set(pageGroups.map((pageGroup) => pageGroup.id));
      const accessIdsFromWorksheet = Object.keys(worksheet.d).filter(isGroupAccessId);
      const groupIdsFromWorksheet = accessIdsFromWorksheet.map(getGroupIdFromAccessId);
      const unknownGroupIds = groupIdsFromWorksheet.filter((id) => !knownGroupIds.has(id));
      const unknownGroupResponses = unknownGroupIds.map((groupId) => ({
        title: `Group ${groupId}`,
        id: groupAccessIdFromGroupId(groupId),
        order: submissions.some((s) => s.page_group_id === groupId) ? 0 : 1,
      }));
      responses = responses.concat(unknownGroupResponses);
    }
  }

  // Optional anonymization.
  if (anonymize) {
    responses = anonymizeResponses(page, responses, groupingCategory);
  }

  return responses;
};

export const useResponsesForPage = (page: PageType | undefined) => {
  const groupingCategory = page?.grouping_category;
  const assessmentsPublished = page?.assessments_published_at !== null;
  const course = useAppSelector(selectCurrentCourse);
  const isAssessing = useAppSelector(selectIsAssessing);
  const submissions = useAppSelector((s) => selectSubmissionsForPage(s, page?.id));
  const anonymize = isAssessing && !assessmentsPublished && course.anonymous_grading_enabled;
  const courseUsers = useListSelector(selectUsersInCurrentCourse);
  const pageGroups = useCustomOrdered(
    useAppSelector((s) => selectPageGroupsByPage(s, page?.id)),
    ORDER_BY_CREATED,
  );
  const worksheet = useWorksheet(page?.worksheet_id)?.doc?.data;
  const responses = useMemo(
    () =>
      getResponses(
        anonymize,
        isAssessing,
        page,
        groupingCategory,
        pageGroups,
        courseUsers,
        worksheet,
        submissions,
      ),
    [
      anonymize,
      isAssessing,
      page,
      groupingCategory,
      pageGroups,
      courseUsers,
      worksheet,
      submissions,
    ],
  );
  return responses;
};

export const useNameFromPageAccessId = (accessId: string, pageId: string) => {
  const users = useListSelector(selectUsersInCurrentCourse);
  const pageGroups = useCustomOrdered(
    useAppSelector((s) => selectPageGroupsByPage(s, pageId)),
    ORDER_BY_CREATED,
  );

  if (!accessId) {
    return;
  }

  return getNameFromPageAccessId(accessId, pageId, users, pageGroups);
};

export const getNameFromPageAccessId = (
  accessId: string,
  pageId: string,
  users: UserWithCourseUserType[],
  pageGroups: PageGroupType[],
) => {
  if (isStudentAccessId(accessId)) {
    const studentId = getStudentIdFromAccessId(accessId);
    const user = users.find((u) => u.id === studentId);

    return user ? getUserShortName(user) : t("field.no_user");
  }
  if (isGroupAccessId(accessId)) {
    const groupId = getGroupIdFromAccessId(accessId);
    const groupIndex = pageGroups.findIndex((g) => g.id === groupId);

    return t("common.group_title", { number: groupIndex + 1 });
  }

  return null;
};

const PageTabVariantLabel = {
  responses: t("presentation.view_options.responses"),
  template: t("common.template"),
  my_submission: t("topic_view.my_submission"),
  my_draft: t("topic_view.my_draft_one"),
  class_draft: t("topic_view.class_draft"),
  student_submission: "", // This label isn't shown to the user.
  seeded_content: t("common.template"), // Students can see instructor seeded content if instructor changed the content after releasing.
};

export type PageTabVariant = keyof typeof PageTabVariantLabel;

export const getPageVariantLabel = (variant: PageTabVariant, isGrouped: boolean) => {
  if (isGrouped && variant === "my_draft") {
    return t("topic_view.my_draft", { count: isGrouped ? 2 : 1 });
  }
  return PageTabVariantLabel[variant];
};

export const SELECTED_ACCESS_ID_PAGE_TABS = ["responses", "student_submission"];
export const SUBMISSION_PAGE_TABS = ["my_submission", "responses", "student_submission"];

export const calculateShownTabVariants = (
  isResponsesTabVisible: boolean,
  isMySubmissionTabVisible: boolean,
  isStudentSubmissionTabVisible: boolean,
  canAuthorCourse: boolean,
  isStaticContent: boolean,
  page: PageType,
): PageTabVariant[] => {
  const shownTabs: PageTabVariant[] = [];

  const firstTabVariant: PageTabVariant = canAuthorCourse
    ? "template"
    : page?.grouping_category === GroupingCategory.SHARED_CLASS
      ? "class_draft"
      : "my_draft";

  shownTabs.push(firstTabVariant);

  if (
    !canAuthorCourse &&
    !isStaticContent &&
    page?.grouping_category !== GroupingCategory.SHARED_CLASS
  ) {
    shownTabs.push("seeded_content");
  }

  if (isMySubmissionTabVisible) {
    shownTabs.push("my_submission");
  }

  if (isResponsesTabVisible) {
    shownTabs.push("responses");
  }

  if (isStudentSubmissionTabVisible) {
    shownTabs.push("student_submission");
  }

  return shownTabs;
};

export const getColoringFromPresentingStatus = (
  unfeaturedTab: boolean,
  isPracticing: boolean,
): { isPracticing: boolean; color: ButtonProps["color"]; className: string } => {
  if (unfeaturedTab) {
    return { isPracticing, color: "orange", className: "text-white" };
  }

  if (isPracticing) {
    return { isPracticing, color: "black", className: "bg-black-tint-40" };
  }

  return { isPracticing, color: "green", className: "" };
};

/**
 * Calculate the total points based on the scores and max points.
 * Points are calculated by taking the rubric's conversion percent
 * for each score, converting them to points, and then taking
 * the average of those points. This needs the org rubric to work,
 * since it has the conversion percent for each score (unless we
 * change FullRubricType to also include this information).
 */
export const calculatePointsFromOutcomeScores = (
  scores: number[],
  maxPoints: number | undefined,
  rubric: OrgRubricType[],
) => {
  if (!maxPoints) {
    return null;
  }

  if (Object.keys(scores).length === 0 || Object.values(scores).some((score) => isNaN(score))) {
    return null;
  }

  const percentByScore: { [score: string]: number } = rubric.reduce(
    (acc, { score, conversion_percent }) => {
      return { ...acc, [score]: conversion_percent };
    },
    {},
  );

  const calculatePointsFromRubricScore = (score: number) =>
    percentByScore[score] * 0.01 * maxPoints || 0;

  const points = Object.values(scores).map((score) => calculatePointsFromRubricScore(score) || 0);

  const totalPoints = points.reduce((acc, score) => acc + score, 0);

  return Math.round((totalPoints / points.length) * 10) / 10 || 0;
};

/**
 * Sorts an array of submissions based on the following priorities:
 * 1. Submissions with an `official_at` timestamp are prioritized first (bubbled to the top).
 * 2. Submissions that have been assessed are next.
 * 3. Finally, submissions are ordered by `created_at` in ascending order (earliest first).
 */
export const sortSubmissions = (submissions: FullSubmissionType[]) => {
  return submissions.sort((a, b) => {
    if (a.official_at && !b.official_at) return -1;
    if (!a.official_at && b.official_at) return 1;

    if (a.assessment && !b.assessment) return -1;
    if (!a.assessment && b.assessment) return 1;

    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  });
};

export const isSubmissionLate = (submission: FullSubmissionType, page: PageType) =>
  page?.due_at && submission && new Date(submission.created_at) > new Date(page.due_at);

export const isPastSubmissionDueDate = (page: PageType) => page.due_at && isPast(page.due_at);

export const isBeforeLateSubmissionDueDate = (page: PageType) =>
  isFuture(getLateSubmissionDueDate(page));

export const getLateSubmissionDueDate = (page: PageType) =>
  addDays(page.due_at, formatDjangoDurationFieldToDaysCount(page.late_submission_interval));

export const isLateSubmissionDueDateIndefinite = (page: PageType) =>
  page.late_submission_interval === null;

export const tableDateFormat = (timestamp: string) => format(timestamp, DEFAULT_SHORT_DATE_FORMAT);

// Currently all rubrics have the same scoring system. Post-MVP this will change, but for now rely on
// the following constant.
export const OUTCOME_MAX_SCORE = 4;

export const FIRST_TAB_INDEX = 0;
export const UNKNOWN_TAB_INDEX = -1;

export const precisionRoundStr = (numStr: string, precision: number) => {
  const factor = 10 ** precision;
  return Math.round(parseFloat(numStr) * factor) / factor;
};

export const isDateWithinLastXDays = (date: string, daysAgo: number) => {
  if (!date) return false;
  return isAfter(new Date(date), subDays(new Date(), daysAgo));
};

export const topicForPage = (topics: TopicType[], activities: ActivityType[], page: PageType) => {
  return topics.find(
    (topic) =>
      activities.find((activity) => activity.id === page.activity_id)?.topic_id === topic.id,
  );
};

export const isDueSoon = (page: PageType) => {
  return isFuture(page.due_at) && differenceInDays(page.due_at, new Date()) <= 7;
};

export const intervalIsDefined = (interval: string) => interval !== EMPTY_DATE;

export const isInLateSubmissionWindow = (page: PageType) => {
  if (!intervalIsDefined(page.late_submission_interval) || !isPastSubmissionDueDate(page)) {
    return false;
  }

  // Late submissions allowed forever
  if (!page.late_submission_interval) {
    return true;
  }

  return isBeforeLateSubmissionDueDate(page);
};

export const STATUS_COLORS = {
  released: "green",
  unreleased: "gray",
  partially_released: "orange",
} as const;

export type StatusColorType = (typeof STATUS_COLORS)[keyof typeof STATUS_COLORS];

export const getSubmissionTimeText = (submission: FullSubmissionType): string => {
  const { month, day, time } = monthDayAtTime(submission.created_at);
  return t("submission_menu.month_day_at_time", {
    month,
    day,
    time,
  });
};
