import clsx from "clsx";
import { FC, useMemo, useState } from "react";

import { HoverableToolbar } from "components/hover-widgets/HoverableToolbar";
import { ActivityCard } from "components/materials/topic/activity/detail/ActivityCard";
import { ActivitySidebarOptionsMenu } from "components/menus/ActivitySidebarOptionsMenu";
import { ActivityCategory } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { Card } from "mds/components/Card";
import { DragAndDrop } from "mds/components/DragAndDrop";
import { TextAddButton } from "mds/components/TextAddButton";
import { ChevronDownIcon, ChevronLeftIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import { ActivityWithPagesType, selectIsEditModeEnabled } from "store/selectors";
import { isArchived, useActivityActions } from "utils/activity";
import { useDnDItemOrdering, useLocalCopy } from "utils/collections";

export type ArchiveActivitiesListProps = {
  activities: ActivityWithPagesType[];
  topicId: string;
};

// This component is for displaying a list of archived activities related to a topic.
export const ArchiveActivitiesList: FC<ArchiveActivitiesListProps> = ({ activities, topicId }) => {
  const [isArchiveSectionExpanded, setIsArchiveSectionExpanded] = useState(true);
  const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);

  const { createActivity, unarchiveActivity, dropActivity } = useActivityActions();

  // Students should not see archived activities.
  const memoArchivedActivities = useMemo(
    () => activities.filter((activity) => isArchived(activity.category)),
    [activities],
  );

  const [archivedActivities, setArchivedActivities] = useLocalCopy(memoArchivedActivities);
  const moveArchivedActivityLocally = useDnDItemOrdering(
    archivedActivities,
    setArchivedActivities,
    "category",
  );

  const createNewActivity = () => {
    createActivity(ActivityCategory.ARCHIVE, activities, topicId);
  };

  return (
    // TODO: This DragAndDrop -> ListItem -> HoverableToolbar -> Icon+EditableField needs
    // to be abstracted so we don't have style drift between all the activity card types,
    // and other list types (like TopicList, CoursePageList, etc). We should instead express
    // any style differences as part of the ListItem MDS styles using the `kind` property.
    <>
      <DragAndDrop
        dragItemKind="activity"
        dropContainerId="archive"
        dropIndex={0}
        droppable
        onDraggedItemHover={moveArchivedActivityLocally}
        onDropItem={dropActivity}
      >
        <HoverableToolbar reference="activity" disableUnlessEditMode onClickAdd={createNewActivity}>
          <div
            className={clsx("body-s mt-1 w-full truncate px-2 text-black-tint-40", {
              "cursor-pointer": isEditModeEnabled,
            })}
          >
            {t("topic_sidebar.archive")}
          </div>

          <div className="flex">
            <Button
              kind="tertiary"
              title={isArchiveSectionExpanded ? t("tooltip.collapse") : t("tooltip.expand")}
              iconOnly
              onClick={() => setIsArchiveSectionExpanded(!isArchiveSectionExpanded)}
            >
              {isArchiveSectionExpanded ? <ChevronDownIcon /> : <ChevronLeftIcon />}
            </Button>

            <ActivitySidebarOptionsMenu
              buttonKind="tertiary"
              optionsMenuActions={{
                onUnarchiveAll: () => {
                  archivedActivities.forEach((activity) => unarchiveActivity(activity, activities));
                },
              }}
            />
          </div>
        </HoverableToolbar>
      </DragAndDrop>

      {isArchiveSectionExpanded && (
        <>
          {archivedActivities.map((activity, activityIndex) => (
            <ActivityCard
              activities={activities}
              activity={activity}
              activityIndex={activityIndex}
              key={activity.id}
              moveActivityLocally={moveArchivedActivityLocally}
              shouldDisplayActivityNumber={false}
            />
          ))}

          {archivedActivities.length === 0 && isEditModeEnabled && (
            <Card className="mt-2" small>
              <div className="-ml-1">
                <TextAddButton
                  reference="activity"
                  hideUnlessEditMode
                  underlined
                  onClick={createNewActivity}
                />
              </div>
            </Card>
          )}
        </>
      )}
    </>
  );
};
