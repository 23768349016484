import clsx from "clsx";
import { FC } from "react";

import { IconSizeType } from "mds/components/Button";
import { DoubleSparkleIcon } from "mds/icons/DoubleSparkle";

export interface AIGeneratedSparkleIconProps {
  isDisabled?: boolean;
  size?: IconSizeType;
}

export const AIGeneratedSparkleIcon: FC<AIGeneratedSparkleIconProps> = ({
  isDisabled,
  size = "m",
}) => {
  return (
    <DoubleSparkleIcon
      className={clsx(
        "rounded-[6px]",
        isDisabled
          ? "bg-black-tint-90 fill-black-tint-55"
          : "bg-violet-tint-95 fill-violet-tint-40",
      )}
      size={size}
    />
  );
};
