import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const SidebarIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path className="stroke" d="M1 5.66669H15" strokeMiterlimit="10" />
    <path className="stroke" d="M5.2002 5.66669V14.0667" strokeMiterlimit="10" />
    <path
      className="stroke"
      d="M13.6 1.93335H2.4C1.6268 1.93335 1 2.56015 1 3.33335V12.6667C1 13.4399 1.6268 14.0667 2.4 14.0667H13.6C14.3732 14.0667 15 13.4399 15 12.6667V3.33335C15 2.56015 14.3732 1.93335 13.6 1.93335Z"
      strokeLinecap="square"
      strokeMiterlimit="10"
    />
    <path
      className="fill"
      d="M2.98324 4.50004C3.3054 4.50004 3.56657 4.23887 3.56657 3.91671C3.56657 3.59454 3.3054 3.33337 2.98324 3.33337C2.66107 3.33337 2.3999 3.59454 2.3999 3.91671C2.3999 4.23887 2.66107 4.50004 2.98324 4.50004Z"
    />
    <path
      className="fill"
      d="M5.08333 4.50004C5.4055 4.50004 5.66667 4.23887 5.66667 3.91671C5.66667 3.59454 5.4055 3.33337 5.08333 3.33337C4.76117 3.33337 4.5 3.59454 4.5 3.91671C4.5 4.23887 4.76117 4.50004 5.08333 4.50004Z"
    />
  </svg>
);
