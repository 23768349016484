/**
 * This file provides helpers to access/modify ShareDB documents in worksheet format.
 * Helpers for the worksheet format are defined in `constants.ts`. The difference with
 * this file is that it provides functions with side effects, and mainly functions that
 * directly interface with an instantiated "Doc" class from ShareDB.
 */

import { cloneDeep, get, isEmpty } from "lodash";
import type { Doc, Op, ShareDBSourceOptions } from "sharedb/lib/client";

import {
  ASSET_BLOCK,
  BLOCKS_PATH,
  DATA_PATH,
  DEFAULT_BLOCKS,
  MAX_BLOCK_COUNT,
  STATIC_CONTENT_BLOCK_TYPES,
} from "./constants";
import type {
  AssetBlockType,
  AssetFieldType,
  BlockType,
  CodeBlockType,
  EmbedBlockType,
  FieldDataType,
  FieldType,
  FileBlockType,
  FreeResponseBlockType,
  InstructionsBlockType,
  MultipleChoiceBlockType,
  TableBlockType,
  WorksheetType,
} from "./types/worksheet";
import { createId } from "./utils";

export const hasCodeCells = (doc: Doc<WorksheetType>) =>
  doc?.data?.b.some((block) => block.t === "Code");

export const isEditableBlock = (block: BlockType) => !STATIC_CONTENT_BLOCK_TYPES.includes(block.t);

export const getEditableBlocks = (doc: Doc<WorksheetType>) => doc?.data?.b.filter(isEditableBlock);

export const hasEditableBlocks = (doc: Doc<WorksheetType>) => doc?.data?.b.some(isEditableBlock);

export const blockIsAsset = (doc: Doc<WorksheetType>, blockId: string) => {
  const block = doc?.data?.b?.find((b) => b.id === blockId);
  return block?.t === ASSET_BLOCK;
};

/**
 * This can be used with any ShareDB operation to return a promise instead of using
 * the callback. This prompts the linter to show an error if the promise is not
 * "caught" correctly. We also have a general error handler that responds to all
 * submitOp errors in ShareDBDoc.tsx, but using this pattern we ensure that local
 * calling functions can respond to errors _in addition to_ the app in general.
 */
export const submitOpPromise = (
  doc: Doc<WorksheetType>,
  ops: Op | Op[],
  options: ShareDBSourceOptions = {},
) => {
  return new Promise((resolve, reject) => {
    doc.submitOp(ops, options, (err) => {
      if (err) {
        // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
        return reject(err);
      }
      resolve(true);
    });
  });
};

export function createBlockOfType(type: "Asset"): AssetBlockType & { id: string };
export function createBlockOfType(type: "Code"): CodeBlockType & { id: string };
export function createBlockOfType(type: "Embed"): EmbedBlockType & { id: string };
export function createBlockOfType(type: "File"): FileBlockType & { id: string };
export function createBlockOfType(type: "FreeResponse"): FreeResponseBlockType & { id: string };
export function createBlockOfType(type: "Instructions"): InstructionsBlockType & { id: string };
export function createBlockOfType(type: "MultipleChoice"): MultipleChoiceBlockType & { id: string };
export function createBlockOfType(type: "Table"): TableBlockType & { id: string };
export function createBlockOfType(type: BlockType["t"]): BlockType;
export function createBlockOfType(type: BlockType["t"]) {
  const block = cloneDeep(DEFAULT_BLOCKS[type]) as BlockType;
  block.id = createId();
  block.f = block.f.map(
    (component: FieldType) =>
      ({
        ...component,
        id: createId(),
      }) as FieldType,
  ) as Extract<FieldType, "field">;
  return block;
}

export const deleteBlockFromWorksheet = async (doc: Doc<WorksheetType>, blockIndex: number) => {
  const block = doc.data.b[blockIndex];
  const userData = get(doc.data, DATA_PATH, {}) as FieldDataType;
  const ops = [
    // Op to remove the actual block definition from the document
    { p: [BLOCKS_PATH, blockIndex], ld: block },
    // Ops to wipe student content referencing fields that this block contains
    ...block.f
      .map((bc: FieldType) =>
        Object.keys(userData).map(
          (accessId) =>
            userData[accessId] &&
            userData[accessId][bc.id] && {
              p: [DATA_PATH, accessId, bc.id],
              od: userData[accessId][bc.id],
            },
        ),
      )
      .flat()
      // Block component might not have student content, like for instruction text
      .filter((op) => op && op.od),
  ];
  return submitOpPromise(doc, ops);
};

export const addAssetToDoc = (
  doc: Doc<WorksheetType>,
  assetData: Partial<AssetFieldType>,
  insertAtIndex = MAX_BLOCK_COUNT,
) => {
  const block = createBlockOfType("Asset");
  const bc = block.f[0];
  Object.assign(bc, assetData);
  return submitOpPromise(doc, [{ p: [BLOCKS_PATH, insertAtIndex], li: block }]);
};

export const fieldIdsFromDoc = (doc: Doc<WorksheetType>) => {
  const blocks = get(doc.data, BLOCKS_PATH);
  return blocks.flatMap((block) => block.f.map((field: { id: string }) => field.id));
};

export const accessIdHasData = (doc: Doc<WorksheetType>, accessId: string) => {
  const dataForAccessId = get(doc.data, [DATA_PATH, accessId]);
  if (!dataForAccessId) return false;
  // We have *some* kind of object, but is it meaningful?
  const fieldIds = fieldIdsFromDoc(doc);
  const dataFieldIds = Object.keys(dataForAccessId).filter((key) => {
    // We only care about data that is associated with fields that are currently in the doc.
    // This will filter out data associated with fields that have been deleted,
    // as well as bookkeeping data like "done" flags and the "versions" object.
    return fieldIds.includes(key);
  });
  // If any of these fields have data, then we return true.
  return dataFieldIds.some((fieldId) => {
    const fieldData = dataForAccessId[fieldId];
    return !isEmpty(fieldData);
  });
};
