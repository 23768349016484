import "./PageOverview.scss";

import clsx from "clsx";
import { FC, Suspense, lazy, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { hasEditableBlocks } from "../../../worksheets/shared/doc-helpers";

import { AllResponsesDropdown } from "./AllResponsesDropdown";
import { PageTitlebar } from "./PageTitlebar";

import { ACCESS_ID_QUERY_PARAM, SUBMISSION_ID_QUERY_PARAM } from "components/constants";
import { PageDetailView } from "components/materials/page/PageDetailView";
import { PageOverviewHeader } from "components/materials/page/PageOverviewHeader";
import { PageSecondarySidebar } from "components/materials/page/PageSecondarySidebar";
import { StudentDropdown } from "components/materials/page/StudentDropdown";
import { isGroupPage, isGroupingCategory } from "components/materials/page/groups/helpers";
import {
  FIRST_TAB_INDEX,
  PageTabVariant,
  SELECTED_ACCESS_ID_PAGE_TABS,
  UNKNOWN_TAB_INDEX,
  calculateShownTabVariants,
  getPageVariantLabel,
} from "components/materials/page/helpers";
import { FeatureModal } from "components/materials/presentation/featuring/FeatureModal";
import { ProjectorScroller } from "components/materials/presentation/projector/ProjectorScroller";
import { PageFooterBar } from "components/materials/topic/submissions/PageFooterBar";
import { GroupingCategory, PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { IconText } from "mds/components/IconText";
import { TabBar, type TabItem } from "mds/components/TabBar";
import { useIsLgOrLarger, useIsMdOrLarger } from "mds/hooks/use-responsive";
import { PageIcon, PresentPlayOutlineIcon } from "mds/icons";
import { BypassReleaseWarningContext } from "providers/BypassReleaseWarningProvider";
import { PageTabContext } from "providers/PageTabProvider";
import { useWorksheet, useWorksheetPermissionRefresh } from "providers/ShareDBDoc";
import { storeApi, useAppDispatch, useAppSelector } from "store/index";
import {
  selectCanAuthorCourse,
  selectIsAssessing,
  selectIsEditModeEnabled,
  selectPageById,
  selectUserSubmissionsForPage,
} from "store/selectors";
import { viewStateActions } from "store/slices/view";
import { useListSelector } from "store/store-hooks";
import { FeatureStatus, usePresentation } from "utils/presentation";
import { isAVEnabled } from "utils/waffle";

type PageOverviewProps = {
  pageId: string;
  selectedTopicId?: string;
  pages: PageType[];
};

// It's important we lazy-load any entry to the AV subsystem, as it uses many heavy dependencies
// and is only rarely enabled.
const FloatingVideos = lazy(() => import("components/av/VideoLayer"));

// TODO: See if it makes sense to separate assessing into it's own overview component
export const PageOverview: FC<PageOverviewProps> = ({ pageId, selectedTopicId, pages }) => {
  const [searchParams] = useSearchParams();
  const selectedAccessId = searchParams.get(ACCESS_ID_QUERY_PARAM);
  const submissionId = searchParams.get(SUBMISSION_ID_QUERY_PARAM);
  const isSmallScreen = !useIsMdOrLarger();

  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const page = useAppSelector((s) => selectPageById(s, pageId));
  const isAssessing = useAppSelector(selectIsAssessing);
  const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);
  const submissions = useListSelector((s) => selectUserSubmissionsForPage(s, page));
  const dispatch = useAppDispatch();
  const isLgOrLarger = useIsLgOrLarger();

  const {
    presentedPageId,
    isPresenting,
    hasProjectorView,
    presentingRollup,
    isFollowing,
    featureStatus,
  } = usePresentation();

  const pageIsPresenting = presentedPageId === page?.id;
  const { doc } = useWorksheet(page?.worksheet_id);
  const isStaticContent = !doc || !hasEditableBlocks(doc);
  useWorksheetPermissionRefresh(page?.worksheet_id);

  const courseId = page?.course_id;
  const groupingCategory = page?.grouping_category;

  const canSeeStudentDropdownOnTab =
    groupingCategory !== GroupingCategory.SHARED_CLASS &&
    // We are allowing the instructor to see the student dropdown on a page with static content, because:
    // 1) we've allowed the instructor to collect submissions on a page with static content (to be fixed here https://app.asana.com/0/1206079475640061/1208403523398930)
    // 2) if the instructor deletes all interactive blocks on the page after submissions are collected, we still want to show the submission list dropdown to the instructor in assessment mode
    (!isStaticContent || isAssessing) &&
    canAuthorCourse;

  const isResponsesTabVisible = !isAssessing && canSeeStudentDropdownOnTab;
  const isMySubmissionTabVisible = submissions.length > 0 && !canAuthorCourse;
  const isStudentSubmissionTabVisible = isAssessing;

  // Keep in sync with tabs.
  const shownTabVariants = useMemo(
    () =>
      calculateShownTabVariants(
        isResponsesTabVisible,
        isMySubmissionTabVisible,
        isStudentSubmissionTabVisible,
        canAuthorCourse,
        isStaticContent,
        page,
      ),
    [
      isResponsesTabVisible,
      isMySubmissionTabVisible,
      isStudentSubmissionTabVisible,
      canAuthorCourse,
      isStaticContent,
      page,
    ],
  );

  const defaultTabIndex = isAssessing
    ? shownTabVariants.indexOf("student_submission")
    : isResponsesTabVisible && selectedAccessId
      ? shownTabVariants.indexOf("responses")
      : isMySubmissionTabVisible && submissionId
        ? shownTabVariants.indexOf("my_submission")
        : FIRST_TAB_INDEX;

  const [tabIndex, setTabIndex] = useState(defaultTabIndex);

  const setTab = useCallback(
    (index: number) => {
      setTabIndex(index);
      dispatch(viewStateActions.setSelectedPageTabVariant(shownTabVariants[index]));
    },
    [setTabIndex, dispatch, shownTabVariants],
  );

  const presentedTabIndex = pageIsPresenting
    ? presentingRollup
      ? shownTabVariants.indexOf("responses")
      : FIRST_TAB_INDEX
    : UNKNOWN_TAB_INDEX;
  const presentingTab = tabIndex === presentedTabIndex;
  const presentingOtherTab = pageIsPresenting && !presentingTab;
  const featuring =
    pageIsPresenting && featureStatus !== FeatureStatus.NONE && (isPresenting || isFollowing);
  const shouldSyncScrolling =
    isPresenting &&
    hasProjectorView &&
    pageIsPresenting &&
    !featuring &&
    tabIndex !== shownTabVariants.indexOf("responses");

  useEffect(() => {
    // If the page was deleted, the pageId doesn't resolve to a page anymore and we don't
    // want to trigger side effects.
    if (!page?.id) {
      return;
    }
    // Load the page groups or course users depending on the type of page
    if (isGroupingCategory(groupingCategory)) {
      storeApi.page_groups.list({ page_id: page.id });
      storeApi.page_group_users.list({ page_group__page_id: page.id });
    } else if (groupingCategory === GroupingCategory.INDIVIDUAL) {
      storeApi.course_users.list({ course_id: courseId });
    }
  }, [page, groupingCategory, courseId]);

  const { resetWarning } = useContext(BypassReleaseWarningContext);

  useEffect(() => {
    // Load the submissions, assessments and outcome assessments for the page.
    if (pageId) {
      storeApi.submissions.list({ page_id: pageId });
      storeApi.assessments.list({ submission__page_id: pageId });
      storeApi.outcome_assessments.list({ assessment__submission__page_id: pageId });
    }

    // Reset the "bypass warning" state when the page changes.
    resetWarning();
  }, [pageId, resetWarning]);

  // Reset tab index when page changes or when presenting
  // and switching between responses and template page.
  useEffect(() => {
    setTab(defaultTabIndex);
    // Only want to do this on page change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResponsesTabVisible, isMySubmissionTabVisible, isStudentSubmissionTabVisible, pageId]);

  const tabs: TabItem[] = useMemo(() => {
    const shownTabs: TabItem[] = [];

    shownTabVariants.forEach((shownTabVariant) => {
      const tabLabel = getPageVariantLabel(shownTabVariant, page ? isGroupPage(page) : false);
      if (
        shownTabVariant === "my_draft" ||
        shownTabVariant === "class_draft" ||
        shownTabVariant === "template"
      ) {
        const tabTitle =
          shownTabVariant === "my_draft" && !canAuthorCourse
            ? t("tooltip.instructor_can_view_draft")
            : tabLabel;
        const isPresentingThisTab = isPresenting && presentedTabIndex === FIRST_TAB_INDEX;
        shownTabs.push({
          label: (
            <IconText
              className={
                isAssessing ? "icon-black-tint-55 cursor-not-allowed text-black-tint-55" : null
              }
              iconStart={isPresentingThisTab ? <PresentPlayOutlineIcon /> : <PageIcon />}
              size="xs"
              text={tabLabel}
            />
          ),
          className: clsx({
            presented: presentedTabIndex === FIRST_TAB_INDEX,
          }),
          title: tabTitle,
          tabVariant: shownTabVariant,
          disabled: isAssessing,
        });
      }

      if (shownTabVariant === "seeded_content") {
        shownTabs.push({
          label: <IconText iconStart={<PageIcon />} size="xs" text={tabLabel} />,
          title: tabLabel,
          tabVariant: shownTabVariant,
        });
      }

      if (shownTabVariant === "my_submission") {
        // This is a student-only tab to see their submissions if they have any.
        shownTabs.push({
          label: <IconText iconStart={<PageIcon />} size="xs" text={tabLabel} />,
          title: tabLabel,
          tabVariant: shownTabVariant,
        });
      }

      if (shownTabVariant === "student_submission" && canSeeStudentDropdownOnTab) {
        // This is an instructors-only tab in assessment mode and can't be presented. They
        // shouldn't be able to enter assessment mode and present at the same time.
        shownTabs.push({
          label: <StudentDropdown canAuthorCourse={canAuthorCourse} pageId={pageId} />,
          title: tabLabel,
          tabVariant: shownTabVariant,
        });
      }

      if (shownTabVariant === "responses" && canSeeStudentDropdownOnTab) {
        const isPresentingAllResponses =
          isPresenting && presentedTabIndex === shownTabVariants.indexOf("responses");
        shownTabs.push({
          label: (
            <AllResponsesDropdown activelyPresented={isPresentingAllResponses} pageId={pageId} />
          ),
          className: clsx({
            presented: presentedTabIndex === shownTabVariants.indexOf("responses"),
          }),
          title: tabLabel,
          tabVariant: "responses",
        });
      }
    });

    return shownTabs;
  }, [
    page,
    pageId,
    presentedTabIndex,
    isPresenting,
    isAssessing,
    shownTabVariants,
    canAuthorCourse,
    canSeeStudentDropdownOnTab,
  ]);

  const tabContextValue = useMemo(
    () => ({
      tabs,
      tabIndex,
      setTab,
      setTabVariant: (tabVariant: PageTabVariant) => {
        const index = tabs.findIndex((tab) => tab.tabVariant === tabVariant);
        if (index !== -1) {
          setTab(index);
        }
      },
    }),
    [tabs, tabIndex, setTab],
  );

  const tabVariant = tabs[tabIndex]?.tabVariant as PageTabVariant;

  const toolbarFooter = (
    <PageFooterBar
      pages={pages}
      selectedPage={page}
      selectedTabVariant={tabVariant}
      topicId={selectedTopicId}
    />
  );

  // <div> wrappers are for the secondary sidebar, if necessary.
  // Outermost <div> encapsulates the entire main section of the page, including the
  // secondary sidebar if present. Inside that is either one or two <div>'s:
  // two if the secondary sidebar is present, one if it is not.
  return (
    <main className="flex h-full w-full flex-row items-stretch overflow-hidden bg-white">
      <div className="relative flex shrink-[2] grow-[2] basis-2/3 flex-col items-center">
        <PageTitlebar page={page} pages={pages} selectedTopicId={selectedTopicId} />

        <ProjectorScroller
          className="page-overview flex w-full flex-grow flex-col items-center"
          shouldScroll={shouldSyncScrolling}
        >
          {isAVEnabled() ? (
            <Suspense
              fallback={
                <div className="fixed bottom-0 right-0 w-[128px] flex-shrink-0 flex-grow-0 bg-white p-2">
                  {t("av.loading_users")}
                </div>
              }
            >
              <FloatingVideos />
            </Suspense>
          ) : null}
          {page && (
            <PageOverviewHeader
              isStaticContent={isStaticContent}
              page={page}
              selectedAccessId={
                // TODO: This shouldn't be necessary if we are correctly unsetting the selectedAccessId.
                //  We should check all edge cases (switching between pages, switching between tabs,
                //  selecting dropdowns options into switching tabs before removing this.
                SELECTED_ACCESS_ID_PAGE_TABS.includes(tabVariant) ? selectedAccessId : undefined
              }
            />
          )}

          <div
            className={clsx("mt-2 flex w-full flex-grow flex-col items-center px-4", {
              presenting: presentingTab,
              "presenting-other-tab": presentingOtherTab,
            })}
          >
            {/* Default index should be the student responses tab in assessment mode. */}
            <TabBar
              className="w-full max-w-[--page-default-max-width]"
              selectedIndex={tabIndex}
              tabs={tabs}
              onChange={setTab}
            />

            <PageTabContext.Provider value={tabContextValue}>
              {/* We hide the tab panel content and instead control the content via props here.
                This allows us to avoid conditionally render multiple worksheets, which often broke. */}
              <div
                className={clsx("w-full", {
                  "edit-mode": isEditModeEnabled && tabVariant === "template",
                })}
              >
                {featuring && <FeatureModal />}

                {page ? (
                  <PageDetailView
                    isStaticContent={isStaticContent}
                    page={page}
                    selectedAccessId={selectedAccessId}
                    selectedTabVariant={tabVariant}
                  />
                ) : (
                  <div>{t("page_detail_view.no_content")}</div>
                )}
              </div>
            </PageTabContext.Provider>
          </div>

          {/* We display the footer in the scroll window for mobile just to give us more screen real-estate */}
          {!isLgOrLarger && toolbarFooter}
        </ProjectorScroller>
        {isLgOrLarger && toolbarFooter}
      </div>

      {!isSmallScreen && (
        <PageSecondarySidebar
          className="h-full min-w-[392px] max-w-[520px] flex-1 basis-1/3 overflow-y-auto px-3 py-4"
          page={page}
        />
      )}
    </main>
  );
};
