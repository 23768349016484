import clsx from "clsx";
import { forwardRef } from "react";

import { t } from "i18n/i18n";
import { Button, ButtonProps, IconKind } from "mds/components/Button";
import { AddPlusIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import { selectIsEditModeEnabled } from "store/selectors";

interface TextAddButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  kind?: IconKind;
  reference?:
    | "page"
    | "block"
    | "activity"
    | "topic"
    | "assignment"
    | "course_description"
    | "course_resource"
    | "instructor_workspace"
    | "outcome"
    | "outcome_subgroup"
    | "outcome_group"
    | "outcome_description"
    | "page_group";
  isDisabled?: boolean;
  hideUnlessEditMode?: boolean;
  underlined?: boolean;
  title?: string;
  size?: ButtonProps["size"];
}

export const TextAddButton = forwardRef<HTMLButtonElement, TextAddButtonProps>(
  (
    {
      onClick,
      reference = "page",
      className,
      isDisabled,
      title,
      size = "xs",
      underlined = false,
      hideUnlessEditMode = false,
      kind = "tertiary",
    },
    ref,
  ) => {
    const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);

    if (hideUnlessEditMode && !isEditModeEnabled) {
      return null;
    }

    return (
      <Button
        className={clsx(
          "text-add-button flex gap-1",
          { "icon-blue ml-1 text-blue underline": underlined },
          className,
        )}
        disabled={isDisabled}
        kind={kind}
        ref={ref}
        size={size}
        title={title || t(`common.add.${reference}`)}
        onClick={onClick}
      >
        <AddPlusIcon /> <span>{t(`common.add.${reference}`)}</span>
      </Button>
    );
  },
);
TextAddButton.displayName = "TextAddButton";
