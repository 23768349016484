import { FC, useContext, useEffect, useRef, useState } from "react";
// eslint-disable-next-line no-restricted-imports
import { toast } from "react-toastify";

import { AUTH_ERRORS } from "../../worksheets/shared/types/websocket-auth";

import { t } from "i18n/i18n";
import { Fade } from "mds/components/Fade";
import { Tooltip } from "mds/components/Tooltip";
import { CloudConnectedIcon, CloudDisconnectedIcon, RefreshIcon } from "mds/icons";
import { IconProps } from "mds/utils/images";
import { WebsocketContext, WebsocketStatusType } from "providers/WebsocketProvider";
import { useAppDispatch, useAppSelector } from "store/index";
import { selectShareDBOpStatus } from "store/selectors";

// After this threshold the connection is considered delayed and an
// error banner is shown to the user.
const SHAREDB_DELAY_THRESHOLD_MS = 5000;
const DISCONNECT_TOAST_DELAY_MS = 500;
const ERROR_TOAST_ID = "websocket-disconnected";

type ConnectionStateType = "connected" | "connecting" | "disconnected";

/**
 * Active states for the saving status of a document / sharedb operation.
 * null indicates that the document is saved and no special status should be shown.
 */
type DocSavingStatusType = "delayed" | "saving" | null;

const SAVING_STATUS: Record<string, DocSavingStatusType> = {
  DELAYED: "delayed",
  SAVING: "saving",
};

const WEBSOCKET_STATE_TO_ICON_STATE: Record<
  WebsocketStatusType | DocSavingStatusType,
  ConnectionStateType
> = {
  connected: "connected",
  delayed: "connecting",
  saving: "connecting",
  connecting: "connecting",
  disconnected: "disconnected",
  stopped: "disconnected",
  closed: "disconnected",
  "401": "disconnected",
};

const ICON_STATE_TO_COLOR: Record<ConnectionStateType, string> = {
  connecting: "icon-blue",
  connected: "icon-green",
  disconnected: "icon-orange",
};

const ICON_STATE_TO_ICON_COMPONENT: Record<ConnectionStateType, FC<IconProps>> = {
  connecting: RefreshIcon,
  connected: CloudConnectedIcon,
  disconnected: CloudDisconnectedIcon,
};

const ICON_STATE_TO_TOOLTIP: Record<ConnectionStateType, string> = {
  connected: t("websocket.saving_indicator.connected"),
  connecting: t("websocket.saving_indicator.connecting"),
  disconnected: t("websocket.saving_indicator.disconnected"),
};

type ConnectionIconProps = {
  iconState: ConnectionStateType;
  classOverride?: string;
};

export const ConnectionIcon = ({ iconState, classOverride }: ConnectionIconProps) => {
  const Icon = ICON_STATE_TO_ICON_COMPONENT[iconState];
  const colorClass = ICON_STATE_TO_COLOR[iconState];
  const tooltipMessage = ICON_STATE_TO_TOOLTIP[iconState];

  const IconElement = <Icon className={classOverride || colorClass} size="l" />;

  // The connected state should be the default, so we only show it for at most 3 seconds,
  // after any transition to connected state.
  if (iconState === "connected") {
    return <Tooltip element={<Fade delay={3000}>{IconElement}</Fade>}>{tooltipMessage}</Tooltip>;
  }

  return <Tooltip element={IconElement}>{tooltipMessage}</Tooltip>;
};

type ConnectionStatusIndicatorProps = {
  className?: string;
  isWhite?: boolean;
};

/**
 * Represents both the current connection status to the websocket/server,
 * and also displays saving status indication for any document currently open.
 */
export const ConnectionStatusIndicator: FC<ConnectionStatusIndicatorProps> = ({
  className,
  isWhite,
}) => {
  const dispatch = useAppDispatch();
  const { websocketStatus } = useContext(WebsocketContext);
  const [savingStatus, setSavingStatus] = useState<DocSavingStatusType>(SAVING_STATUS.SAVED);
  const { lastOpAt, lastOpSavedAt, opError } = useAppSelector(selectShareDBOpStatus);
  const delayTimeout = useRef<NodeJS.Timeout>(null);
  const showToastTimeout = useRef<NodeJS.Timeout>(null);
  const minUiFlickerTimeout = useRef<NodeJS.Timeout>(null);

  const connectionIndicatorStatus =
    opError && opError !== AUTH_ERRORS.OVERWRITE_DISALLOWED
      ? "disconnected"
      : savingStatus || websocketStatus;
  const iconState = WEBSOCKET_STATE_TO_ICON_STATE[connectionIndicatorStatus];

  useEffect(() => {
    if (!lastOpAt) {
      return;
    }
    clearTimeout(delayTimeout.current);
    clearTimeout(minUiFlickerTimeout.current);
    if (lastOpAt < lastOpSavedAt) {
      minUiFlickerTimeout.current = setTimeout(() => {
        setSavingStatus(SAVING_STATUS.SAVED);
      }, 1000);
    } else {
      setSavingStatus((status) => status || SAVING_STATUS.SAVING);
      delayTimeout.current = setTimeout(() => {
        setSavingStatus(SAVING_STATUS.DELAYED);
      }, SHAREDB_DELAY_THRESHOLD_MS);
    }
  }, [lastOpSavedAt, lastOpAt]);

  useEffect(() => {
    clearTimeout(showToastTimeout.current);
    toast.dismiss(ERROR_TOAST_ID);

    // We only show toasts for op and connection errors
    if (iconState !== "disconnected") {
      return;
    }

    setSavingStatus(null);

    const toastText = opError ? t("error.toasts.save_failure") : t("websocket.disconnected");

    // We slightly delay showing the toast so that minimal flickers in connection do not
    // cause a flickering toast banner
    showToastTimeout.current = setTimeout(() => {
      toast.error(toastText, {
        toastId: "websocket-disconnected",
        autoClose: false,
        closeButton: false,
      });
    }, DISCONNECT_TOAST_DELAY_MS);
  }, [opError, iconState, dispatch]);

  return (
    <div className={className}>
      <ConnectionIcon classOverride={isWhite && "icon-white"} iconState={iconState} />
    </div>
  );
};
