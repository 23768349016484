import type { FC } from "react";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { CrossRemoveIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import { selectCurrentCourse } from "store/selectors";
import { usePresentation } from "utils/presentation";
import { courseHomeUrl } from "utils/urls";

type BackToCourseButtonProps = {
  color?: "orange" | "blue" | "green";
};

export const BackToCourseButton: FC<BackToCourseButtonProps> = ({ color }) => {
  const currentCourse = useAppSelector(selectCurrentCourse);
  const { isPresenting } = usePresentation();
  if (!currentCourse?.id) {
    return null;
  }

  if (isPresenting) {
    return null;
  }

  return (
    <Button
      aria-label={t("common.close")}
      color={color}
      kind={color ? "primary" : "secondary"}
      size="xs"
      title={t("tooltip.close_topic")}
      to={courseHomeUrl(currentCourse.id)}
      iconOnly
    >
      <CrossRemoveIcon />
    </Button>
  );
};
