import type { FC } from "react";

import { displayOutcomeName } from "components/outcomes/helpers";
import { OutcomeAssessmentType } from "components/server-types";
import { OutcomeChip } from "mds/components/OutcomeChip";
import { NestedPageOutcomeType } from "store/selectors";

/** Displays a single outcome and possibly its score in a list of outcomes. */
interface OutcomeScoreProps {
  pageOutcome: NestedPageOutcomeType;
  className?: string;
  onClick?: () => void;
  to?: string;
  outcomeAssessment?: OutcomeAssessmentType;
  size?: "s" | "m";
}

export const PageOutcomeScore: FC<OutcomeScoreProps> = ({
  pageOutcome,
  className = "",
  onClick,
  to,
  outcomeAssessment,
  size = "m",
}) => {
  const title = displayOutcomeName(pageOutcome.outcome);

  return (
    <OutcomeChip
      className={className}
      score={outcomeAssessment?.score}
      size={size}
      title={title}
      to={to}
      onClick={onClick}
    />
  );
};
