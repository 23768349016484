import { FC } from "react";

import { INDEX_NOT_FOUND } from "components/constants";
import { GROUPING_OPTION_ICONS } from "components/materials/page/grouping-category/helpers";
import { GROUP_TYPE_CHOICES } from "components/materials/page/groups/helpers";
import { GroupingCategory, PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { IconText } from "mds/components/IconText";

interface StudentGroupingCategoryView {
  pageGroupIndex: number;
  page: PageType;
  onStudentGroupCardToggle?: () => void;
}

export const StudentGroupingCategoryView: FC<StudentGroupingCategoryView> = ({
  page,
  pageGroupIndex,
  onStudentGroupCardToggle,
}) => {
  if (GROUP_TYPE_CHOICES.includes(page.grouping_category)) {
    const iconText = (
      <IconText
        iconStart={GROUPING_OPTION_ICONS[page.grouping_category]}
        text={
          pageGroupIndex === INDEX_NOT_FOUND
            ? t("page_grouping_menu.grouping.self_assign")
            : t("common.group_title", {
                number: pageGroupIndex + 1,
              })
        }
      />
    );
    if (onStudentGroupCardToggle) {
      return (
        <Button
          className="gap-1"
          kind="secondary"
          size="xs"
          title={t("tooltip.type_of_activity")}
          onClick={onStudentGroupCardToggle}
        >
          {iconText}
        </Button>
      );
    }
    return (
      <div className="h6 flex cursor-default items-center justify-center gap-1 whitespace-nowrap rounded-lg border border-black-tint-90 px-2 py-1">
        {iconText}
      </div>
    );
  }

  if (page.grouping_category === GroupingCategory.SHARED_CLASS) {
    return (
      <IconText
        className="h6 cursor-default justify-center gap-1 whitespace-nowrap rounded-lg border border-black-tint-90 px-2 py-1 text-black-tint-40"
        iconStart={GROUPING_OPTION_ICONS[page.grouping_category]}
        text={t("page_grouping_menu.grouping.all")}
      />
    );
  }

  return null;
};
