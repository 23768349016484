import { format } from "date-fns";
import type { FC } from "react";
import { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { Doc } from "sharedb/lib/client";

import {
  dataVersionsMatchFieldVersions,
  upgradeDataVersions,
} from "../../../../worksheets/field-versioning";
import { BLOCKS_PATH } from "../../../../worksheets/shared/constants";
import { hasEditableBlocks } from "../../../../worksheets/shared/doc-helpers";
import { WorksheetType } from "../../../../worksheets/shared/types/worksheet";
import {
  PageTabVariant,
  getLateSubmissionDueDate,
  intervalIsDefined,
  isInLateSubmissionWindow,
  isSubmissionLate,
} from "../helpers";

import { SUBMISSION_ID_QUERY_PARAM } from "components/constants";
import { EmptyPageBanner } from "components/materials/page/banners/EmptyPageBanner";
import { InstructorLateSubmissionBanner } from "components/materials/page/banners/InstructorLateSubmissionBanner";
import { InstructorLockedPageBanner } from "components/materials/page/banners/InstructorLockedPageBanner";
import { LateSubmissionAvailableBanner } from "components/materials/page/banners/LateSubmissionsAvailableBanner";
import { MismatchedVersionBanner } from "components/materials/page/banners/MismatchedVersionBanner";
import { NoSubmissionBanner } from "components/materials/page/banners/NoSubmissionBanner";
import { PageReleasedBanner } from "components/materials/page/banners/PageReleasedBanner";
import { StudentLateSubmissionBanner } from "components/materials/page/banners/StudentLateSubmissionBanner";
import { StudentLockedPageBanner } from "components/materials/page/banners/StudentLockedPageBanner";
import { GroupingCategory, type PageType } from "components/server-types";
import { DAY_AND_TIME_FORMAT } from "i18n/helpers";
import { BypassReleaseWarningContext } from "providers/BypassReleaseWarningProvider";
import { DocViewContext } from "providers/DocViewProvider";
import { useAppSelector } from "store/index";
import {
  selectCanAuthorCourse,
  selectFullSubmissionById,
  selectIsEditModeEnabled,
} from "store/selectors";
import { usePresentation } from "utils/presentation";

type PageBannersProps = {
  page: PageType;
  doc: Doc<WorksheetType>;
  selectedTabVariant: PageTabVariant;
};

export const PageBanners: FC<PageBannersProps> = ({ page, doc, selectedTabVariant }) => {
  const [searchParams] = useSearchParams();
  const { canAuthorPage, isReleased, accessId } = useContext(DocViewContext);
  const { showWarning, bypassWarning } = useContext(BypassReleaseWarningContext);
  const { isPresenting } = usePresentation();

  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);
  const submissionId = searchParams.get(SUBMISSION_ID_QUERY_PARAM);
  const submission = useAppSelector((s) => selectFullSubmissionById(s, submissionId));

  const blocks = doc.data[BLOCKS_PATH];

  const isResponsesTab = selectedTabVariant === "responses";
  const isSeededContentTab = selectedTabVariant === "seeded_content";
  const isSubmissionsTab =
    selectedTabVariant === "my_submission" ||
    selectedTabVariant === "student_submission" ||
    !!submission;
  const isDraftTab = ["my_draft", "class_draft", "template"].includes(selectedTabVariant);
  const isStaticContent = !doc || !hasEditableBlocks(doc);
  const isClassCollab = page.grouping_category === GroupingCategory.SHARED_CLASS;
  const versionsMatch = dataVersionsMatchFieldVersions(doc, accessId);
  const submissionIsLate = isSubmissionLate(submission, page);
  const lateSubmissionDate =
    page.late_submission_interval && intervalIsDefined(page.late_submission_interval)
      ? format(getLateSubmissionDueDate(page), DAY_AND_TIME_FORMAT)
      : undefined;

  const showInstructorLockedPageBanner =
    !isSeededContentTab && !isSubmissionsTab && canAuthorPage && page.locked_at;

  const showStudentLockedPageBanner =
    !isSeededContentTab && !isSubmissionsTab && !canAuthorPage && page.locked_at;

  const showPageReleasedBanner =
    isEditModeEnabled &&
    !isSeededContentTab &&
    canAuthorPage &&
    isReleased &&
    !isPresenting &&
    showWarning &&
    !isSubmissionsTab;

  const showMismatchedVersionBanner =
    !isStaticContent &&
    !isSeededContentTab &&
    !canAuthorPage &&
    !versionsMatch.all &&
    !isResponsesTab &&
    !isSubmissionsTab &&
    !isClassCollab;

  const showInstructorLateSubmissionBanner =
    submissionIsLate &&
    canAuthorCourse &&
    (selectedTabVariant === "student_submission" || submission);

  const showStudentLateSubmissionBanner =
    submissionIsLate && !canAuthorCourse && selectedTabVariant === "my_submission";

  const showLateSubmissionAvilableBanner = isDraftTab && isInLateSubmissionWindow(page);

  const showNoSubmissionBanner =
    selectedTabVariant === "student_submission" && !submission && canAuthorCourse;

  const showEmptyPageBanner = canAuthorPage && blocks.length === 0 && !isEditModeEnabled;

  if (
    !showInstructorLockedPageBanner &&
    !showStudentLockedPageBanner &&
    !showPageReleasedBanner &&
    !showInstructorLateSubmissionBanner &&
    !showStudentLateSubmissionBanner &&
    !showLateSubmissionAvilableBanner &&
    !showNoSubmissionBanner &&
    !showMismatchedVersionBanner &&
    !showEmptyPageBanner
  ) {
    return null;
  }

  return (
    <div className="page-block-width mb-2 flex flex-col gap-4">
      {showInstructorLockedPageBanner && <InstructorLockedPageBanner />}
      {showStudentLockedPageBanner && <StudentLockedPageBanner />}
      {showPageReleasedBanner && <PageReleasedBanner onDismiss={bypassWarning} />}
      {showInstructorLateSubmissionBanner && <InstructorLateSubmissionBanner />}
      {showStudentLateSubmissionBanner && <StudentLateSubmissionBanner />}
      {showLateSubmissionAvilableBanner && (
        <LateSubmissionAvailableBanner lateSubmissionDate={lateSubmissionDate} />
      )}
      {showNoSubmissionBanner && <NoSubmissionBanner page={page} />}
      {showMismatchedVersionBanner && (
        <MismatchedVersionBanner
          onlySeeded={versionsMatch.static}
          onDismiss={() => upgradeDataVersions(doc, accessId)}
        />
      )}
      {showEmptyPageBanner && <EmptyPageBanner />}
    </div>
  );
};
