// This file exists to provide default data for testing
// Note that this is missing hidden code fields, read-only code fields, different table sizes
// and images and formatting in rich text fields

export const EXAMPLE_CONTENT_BLOCKS = [
  {
    t: "Instructions",
    f: [
      {
        t: "Text",
        // Contains one of every richtext formatting option
        v: {
          ops: [
            { attributes: { bold: true }, insert: "bold" },
            { insert: " " },
            { attributes: { italic: true }, insert: "italic" },
            { insert: " " },
            { attributes: { underline: true }, insert: "underline" },
            { insert: " " },
            { attributes: { link: "https://./#some-id" }, insert: "relative-link" },
            { insert: " " },
            {
              attributes: { link: "https://www.youtube.com/watch?v=dQw4w9WgXcQ" },
              insert: "absolute-link",
            },
            { insert: "\nquotes" },
            { attributes: { blockquote: true }, insert: "\n" },
            { insert: '\nprint("hello")' },
            { attributes: { "code-block": true }, insert: "\n" },
            { insert: { formula: "e=mc^2" } },
            { insert: " \n\nitem 1" },
            { attributes: { list: "ordered" }, insert: "\n" },
            { insert: "item 2" },
            { attributes: { list: "ordered" }, insert: "\n" },
            { insert: "\nitem 1" },
            { attributes: { list: "bullet" }, insert: "\n" },
            { insert: "item 2" },
            { attributes: { list: "bullet" }, insert: "\n" },
            { insert: "indented text" },
            { attributes: { indent: 1 }, insert: "\n" },
            { insert: "colored text", attributes: { color: "#6b24b2" } },
            { insert: "\n" },
            { delete: 1 },
          ],
        },
        id: "97f93dc3-e4cb-4fdc-ab66-8ab3f9e06161",
      },
    ],
    id: "ec4ad7b7-7c56-4aae-b01b-1b86f3a2b9c4",
  },
  {
    t: "FreeResponse",
    f: [
      {
        t: "Text",
        v: { ops: [{ insert: "Free response instructions herea\n" }] },
        id: "aa88fc98-39f4-45c0-8118-9a168715cc0e",
      },
      {
        t: "RichTextField",
        maxLength: null,
        id: "09aaf488-8872-45ea-91e6-d11e2b14986d",
      },
    ],
    id: "5de80280-5278-4ff6-b4bc-01bd3f954ff4",
  },
  {
    t: "MultipleChoice",
    f: [
      {
        t: "Text",
        v: { ops: [{ insert: "Multiple choice, multiple answer text instructions here\n" }] },
        id: "65e03a83-5e3e-44b4-8bf6-6919448e0b16",
      },
      {
        t: "ChoiceField",
        m: true,
        choices: [
          {
            l: "A",
            v: "0",
            id: "ab",
          },
          {
            l: "B",
            v: "1",
            id: "cd",
          },
          {
            l: "C",
            v: "2",
            id: "ef",
          },
        ],
        id: "3ea820ba-9367-4638-aa8f-3bb8577a8f80",
      },
    ],
    id: "274ebe32-7769-4623-ad28-9fa96e2dc582",
  },
  {
    t: "Table",
    f: [
      {
        t: "Text",
        v: { ops: [{ insert: "Table instructions here\n" }] },
        id: "d4204ad2-99e5-4135-b4ec-33eb1de045ce",
      },
      {
        t: "TableField",
        v: {
          r: ["aanf", "aaey", "aa28"],
          c: ["aanf", "aadl", "aa01"],
          cm: {
            "aanf,aanf": { v: { ro: true } },
            "aanf,aadl": { v: { ro: true } },
            "aanf,aa01": { v: { ro: true } },
            "aaey,aanf": { v: { ops: [{ insert: "AA" }] } },
            "aaey,aadl": { v: { ops: [{ insert: "CC" }] } },
          },
        },
        id: "0132f963-5680-4343-b8aa-05ad52467d1f",
      },
    ],
    id: "0d24747a-c5ed-4f00-9869-f26cd3427831",
  },
  {
    t: "MultipleChoice",
    f: [
      {
        t: "Text",
        v: { ops: [{ insert: "Multiple choice, single answer text instructions here\n" }] },
        id: "d39e02ab-ff16-4fff-9890-0a36782de1a2",
      },
      {
        t: "ChoiceField",
        m: false,
        choices: [
          {
            l: "Liu Zhu",
            v: "0",
            id: "de",
          },
          {
            l: "Sun Tze",
            v: "1",
            id: "01",
          },
          {
            l: "Wang Qing",
            v: "2",
            id: "23",
          },
        ],
        id: "3501c905-9bd5-4775-bef9-c903ec8b6297",
      },
    ],
    id: "d07959b3-f127-45c2-a51d-75ec82b16988",
  },
  {
    t: "File",
    f: [
      {
        t: "Text",
        v: { ops: [{ insert: "Multiple file upload instructions here\n" }] },
        id: "05893923-d671-4642-ae90-2f02a745e201",
      },
      {
        m: true,
        t: "FileField",
        id: "ca392cca-398b-42f2-abcb-916ca517ec52",
      },
    ],
    id: "c3c3a943-acab-4ba2-bffa-521ecd61b476",
  },
  {
    t: "File",
    f: [
      {
        t: "Text",
        v: { ops: [{ insert: "Single File Upload text instructions here\n" }] },
        id: "5b692cb1-a2ad-4aed-a1f1-f0a00d644fdb",
      },
      {
        m: false,
        t: "FileField",
        id: "bf9398d8-1e3d-4435-99ca-4eee6d4868b7",
      },
    ],
    id: "aa0e906b-ef83-4b27-a4a8-89a002ed852f",
  },
  {
    t: "Code",
    f: [
      {
        t: "CodeField",
        c: 'print("Code here")',
        cellType: "editable",
        id: "dd953bb7-60ed-43f2-9de2-829f65e98fe1",
      },
    ],
    id: "bb3707fd-36e5-4cf6-97b1-0782ccea5e83",
  },
  {
    t: "Code",
    f: [
      {
        t: "CodeField",
        c: 'print("read-only code")',
        cellType: "readonly",
        id: "e87b4de8-af83-4c33-a64c-ae69575f65cc",
      },
    ],
    id: "31c23621-0477-4158-915b-640ef0ede42c",
  },
  {
    t: "Code",
    f: [
      {
        t: "CodeField",
        c: 'print("hidden code")',
        cellType: "hidden",
        id: "98078cbd-024a-44b0-9105-9610032f911a",
      },
    ],
    id: "f1e71e72-2cee-4ce6-8151-6501c879ed21",
  },
];

export const EXAMPLE_CONTENT_DATA = {
  "09aaf488-8872-45ea-91e6-d11e2b14986d": {},
  "3ea820ba-9367-4638-aa8f-3bb8577a8f80": [],
  "e87b4de8-af83-4c33-a64c-ae69575f65cc": {
    c: 'print("read-only code")',
    o: [],
    p: [],
    ec: 0,
  },
  "98078cbd-024a-44b0-9105-9610032f911a": {
    c: 'print("hidden code")',
    o: [],
    p: [],
    ec: 0,
  },
  "0132f963-5680-4343-b8aa-05ad52467d1f": [
    [
      { v: { ops: [] }, ro: true, id: "nf" },
      { v: { ops: [] }, ro: true, id: "dl" },
      { v: { ops: [] }, ro: true, id: "01" },
    ],
    [
      { v: { ops: [{ insert: "AA" }] }, ro: false, id: "ey" },
      { v: { ops: [] }, ro: false, id: "13" },
      { v: { ops: [{ insert: "CC" }] }, ro: false, id: "9y" },
    ],
    [
      { v: { ops: [] }, ro: false, id: "28" },
      { v: { ops: [] }, ro: false, id: "ad" },
      { v: { ops: [] }, ro: false, id: "g7" },
    ],
  ],
  "ca392cca-398b-42f2-abcb-916ca517ec52": [],
  "dd953bb7-60ed-43f2-9de2-829f65e98fe1": {
    c: 'print("Code here")',
    o: [],
    p: [],
    ec: 0,
  },
  "3501c905-9bd5-4775-bef9-c903ec8b6297": [],
  "bf9398d8-1e3d-4435-99ca-4eee6d4868b7": [],
};
