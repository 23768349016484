import clsx from "clsx";
import { FC } from "react";

import { isSubmissionLate } from "components/materials/page/helpers";
import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { CheckIcon, ClockIcon, VerifiedIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import {
  FullSubmissionType,
  selectDefaultSubmissionForUser,
  selectFullSubmissionById,
} from "store/selectors";

export type AssessmentStatusIconProps = {
  assessmentsPublished: boolean;
  canAuthorCourse: boolean;
  page: PageType;
  accessId: string;
  selectedSubmission?: FullSubmissionType;
  iconOnly?: boolean;
  showLate?: boolean;
  selected?: boolean;
};

export const AssessmentStatusIcon: FC<AssessmentStatusIconProps> = ({
  assessmentsPublished,
  canAuthorCourse,
  page,
  accessId,
  selected,
  selectedSubmission,
  showLate,
  iconOnly,
}) => {
  const userDefaultSubmission = useAppSelector((s) =>
    selectDefaultSubmissionForUser(s, page, accessId),
  );
  const defaultSubmission = useAppSelector((s) =>
    selectFullSubmissionById(s, userDefaultSubmission?.id),
  );

  const submission = selectedSubmission || defaultSubmission;

  if (!submission) return null;

  const baseClassName = clsx(
    "flex items-center gap-1 rounded-md",
    !iconOnly && "px-1.5 py-0.5",
    !selected && !iconOnly && "bg-green-tint-90",
  );

  const isLate = isSubmissionLate(submission, page);
  const lateSymbol =
    showLate && isLate ? (
      <div
        className={clsx(
          baseClassName,
          "icon-orange",
          !selected && !iconOnly && "bg-orange-tint-90",
        )}
      >
        <ClockIcon />
        {!iconOnly && t("assessment_status_icon.late")}
      </div>
    ) : null;

  if ((!canAuthorCourse && !assessmentsPublished) || !submission.assessment) {
    return lateSymbol;
  }

  const assessedDivClassName = clsx(baseClassName, !selected && !iconOnly && "bg-green-tint-90");

  if (submission.official_at) {
    return (
      <div className="flex gap-2">
        {lateSymbol}
        <div className={assessedDivClassName}>
          <VerifiedIcon />
          {!iconOnly && t("assessment_status_icon.assessed")}
        </div>
      </div>
    );
  }

  return (
    <div className="flex gap-2">
      {lateSymbol}
      <div className={assessedDivClassName}>
        <CheckIcon />
        {!iconOnly && t("assessment_status_icon.assessed")}
      </div>
    </div>
  );
};
