import { FC, useEffect } from "react";

import { OrgLmsSettings } from "components/materials/org/settings/OrgLmsSettings";
import { OrgSettingsMain } from "components/materials/org/settings/OrgSettingsMain";
import { t } from "i18n/i18n";
import { storeApi } from "store/index";
import { selectFullCoursesSorted } from "store/selectors";
import { useListSelector } from "store/store-hooks";

export const AdminSettingsSidebarPanel: FC = () => {
  const courses = useListSelector(selectFullCoursesSorted);

  useEffect(() => {
    storeApi.orgs.list();
  }, []);

  return (
    <>
      <div className="content-sidebar-header">
        <h3 className="mb-0">{t("org.settings.admin_settings")}</h3>
      </div>

      <div className="content-sidebar-main">
        <OrgSettingsMain courses={courses} />
        <OrgLmsSettings />
      </div>
    </>
  );
};
