import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { createId } from "../worksheets/shared/utils";

import { toastLocalizedOperationCatcher } from "./alerts";
import { PartialUpdateable } from "./type-utils";

import { FOLLOWING_QUERY_PARAM } from "components/constants";
import {
  WindowMessages,
  sendMessageToProjectorView,
} from "components/materials/presentation/projector/projector-messaging";
import { ClassSessionType, GroupingCategory, PageType } from "components/server-types";
import { toastReleasePage } from "helpers/toasts";
import { storeApi, useAppSelector } from "store/index";
import {
  selectActiveClassSession,
  selectCurrentUserId,
  selectSelectedPageTabVariant,
} from "store/selectors";

const PRESENTER_ID_KEY = "ForumPresenterTabId";

const getPresenterWindow = () => localStorage.getItem(PRESENTER_ID_KEY);
const setPresenterWindow = (id: string) => localStorage.setItem(PRESENTER_ID_KEY, id);
const removePresenterWindow = () => localStorage.removeItem(PRESENTER_ID_KEY);

export const setPresentationWindow = () => {
  if (!window.name.startsWith("ForumPresenterTab")) {
    window.name = `ForumPresenterTab-${createId()}`;
  }

  setPresenterWindow(window.name);
};

export const isPresentationWindow = () => {
  const presentationWindowId = getPresenterWindow();

  return window.name === presentationWindowId;
};

export const sendSessionUpdateForProjector = () => {
  if (isPresentationWindow()) {
    sendMessageToProjectorView({ type: WindowMessages.SESSION_SYNC });
  }
};

export const updateSession = (session: PartialUpdateable<ClassSessionType>) => {
  return storeApi.class_sessions
    .partial_update(session, { skipToast: true })
    .then(sendSessionUpdateForProjector);
};

export const presentSession = async (
  sessionId: string,
  {
    page,
    isRollup,
    isPractice,
    hasProjectorView,
    accessId = null,
    userId,
  }: {
    page: PageType;
    isRollup?: boolean;
    isPractice?: boolean;
    hasProjectorView?: boolean;
    accessId?: string;
    userId?: string;
  },
) => {
  const session: PartialUpdateable<ClassSessionType> = {
    id: sessionId,
    presented_page_id: page?.id,
    presenting_user_id: page ? userId : null,
    presented_block_id: null,
    presenting_outcomes: false,
  };

  if (isRollup !== undefined) {
    session.presenting_rollup = isRollup;
  }

  if (isPractice !== undefined) {
    session.is_practice = isPractice;
  }

  if (hasProjectorView !== undefined) {
    session.is_projecting = hasProjectorView;
  }

  if (accessId !== undefined) {
    session.presented_access_id = accessId;
  }

  try {
    await updateSession(session);

    // We just released the page so make a toast
    if (page && !page.released_at && !isPractice) {
      toastReleasePage();
    }
  } catch (e) {
    toastLocalizedOperationCatcher("present_page_failure")(e);
  }
};

export type FeatureOptions = {
  blockId?: string;
  accessId?: string;
  isPresentingOutcomes?: boolean;
  isRollup?: boolean;
};

export const featurePage = (
  sessionId: string,
  page: PageType,
  {
    blockId,
    accessId,
    isPresentingOutcomes = false,
    isRollup = true, // Most of the time when we feature it's rollup
  }: FeatureOptions,
) => {
  const featureAccessId =
    page.grouping_category !== GroupingCategory.SHARED_CLASS ? accessId : null;

  return updateSession({
    id: sessionId,
    presented_page_id: page.id,
    presenting_rollup: isRollup,
    presented_access_id: featureAccessId,
    presented_block_id: blockId,
    presenting_outcomes: isPresentingOutcomes,
  }).catch(toastLocalizedOperationCatcher("present_page_failure"));
};

export const stopPresenting = (sessionId: string) => {
  removePresenterWindow();

  return updateSession({
    id: sessionId,
    presented_page_id: null,
    presented_block_id: null,
    presenting_user_id: null,
    presenting_outcomes: false,
    presenting_rollup: false,
    is_practice: false,
    is_projecting: false,
    presented_access_id: null,
  }).catch(toastLocalizedOperationCatcher("stop_presenting_failure"));
};

export const stopFeaturing = (sessionId: string) => {
  return updateSession({
    id: sessionId,
    presenting_rollup: false,
    presenting_outcomes: false,
    presented_access_id: null,
    presented_block_id: null,
  }).catch(toastLocalizedOperationCatcher("stop_presenting_failure"));
};

export const takeOverPresenting = (sessionId: string, userId: string) => {
  return updateSession({ id: sessionId, presenting_user_id: userId }).catch(
    toastLocalizedOperationCatcher("stop_presenting_failure"),
  );
};

export enum FeatureStatus {
  NONE = "none",
  BLOCK = "block",
  OUTCOME = "outcome",
  ALL_RESPONSES = "all_responses",
  ALL_RESPONSES_WITH_BLOCK = "all_responses_with_block",
  PARTICIPANT_RESPONSE = "participant_response",
  PARTICIPANT_RESPONSE_WITH_BLOCK = "participant_response_with_block",
}

const getFeatureStatus = (classSession: ClassSessionType) => {
  if (!classSession) {
    return null;
  }

  if (classSession.presenting_outcomes) {
    return FeatureStatus.OUTCOME;
  }

  if (classSession.presenting_rollup) {
    if (classSession.presented_block_id) {
      return classSession.presented_access_id
        ? FeatureStatus.PARTICIPANT_RESPONSE_WITH_BLOCK
        : FeatureStatus.ALL_RESPONSES_WITH_BLOCK;
    }

    return classSession.presented_access_id
      ? FeatureStatus.PARTICIPANT_RESPONSE
      : FeatureStatus.ALL_RESPONSES;
  }

  return classSession.presented_block_id ? FeatureStatus.BLOCK : FeatureStatus.NONE;
};

export const usePresentation = () => {
  const classSession = useAppSelector(selectActiveClassSession);

  const userId = useAppSelector(selectCurrentUserId);
  const selectedPageTabVariant = useAppSelector(selectSelectedPageTabVariant);
  const [searchParams] = useSearchParams();
  const isFollowing = searchParams.get(FOLLOWING_QUERY_PARAM) === "1";

  return useMemo(() => {
    const isPractice = !!classSession?.is_practice;
    const isPresentingUser = classSession?.presenting_user_id === userId;
    const isPresenting = isPresentingUser && isPresentationWindow();

    // We use presentedPageId to determine if we are in fact presenting
    const presentedPageId =
      isPresentingUser || !isPractice ? classSession?.presented_page_id : null;
    const inPresentationMode = !!presentedPageId;
    const featureStatus = getFeatureStatus(classSession);

    return {
      courseId: classSession?.course_id,
      featureStatus,
      isFollowing,
      isPracticing: isPresenting && isPractice,
      isPractice,
      inPresentationMode,
      isPresenting,
      isPresentingUser,
      hasProjectorView: classSession?.is_projecting,
      presentedAccessId: classSession?.presented_access_id,
      presentedBlockId: classSession?.presented_block_id,
      presentingOutcomes: classSession?.presenting_outcomes,
      presentedPageId,
      presentedSessionId: classSession?.id,
      presentingRollup: classSession?.presenting_rollup,
      unfeaturedTab:
        isPresenting &&
        selectedPageTabVariant === "responses" &&
        featureStatus === FeatureStatus.NONE,
    };
  }, [classSession, userId, isFollowing, selectedPageTabVariant]);
};
