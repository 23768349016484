import { createContext } from "react";

type ShowResponsesType = false | "ONE" | "ALL";

export type DocViewContextType = {
  readOnly: boolean;
  /**
   * Indicates the user viewing the document has permissions to add/remove blocks from
   * the document, and to read all user data in the document.
   */
  canAuthorPage: boolean;
  /**
   * One step beyond `canAuthorPage`, this indicates that the user not only has permission
   * to add/remove blocks from the document, but is currently engaged in doing so.
   */
  isAuthoringPage: boolean;
  /**
   * Indicates that the user should be able to interact with the page as a student,
   * e.g. answer questions. Typically, instructors cannot do this -- unless the page
   * is in "Class Collaboration" mode, which specifically allows them to collaborate
   * with their students.
   */
  canInteractAsStudent: boolean;
  /**
   * Whether we're trying to view the page template as a student
   */
  isViewingSeededContentTab: boolean;
  canWriteDoc: boolean;
  /**
   * Indicates that blocks should be rendered in a compact view,
   * displaying one or more responses rather than allowing for interactive
   * content. Can be `false`, `"ONE"`, or `"ALL"`.
   * TODO / Note: showResponses is currently set to "ONE" when the instructor is viewing a student's whole page OR
   *      when featured a student's response, though these should not be treated the same (probably viewing the
   *      students page should set this value to be `false` instead, given it shouldn't use a compact view (this may
   *      break things however in the transition).
   */
  showResponses: ShowResponsesType;
  /**
   * Indicates whether this document is being viewed in project mode.
   */
  isProjecting: boolean;
  /**
   * Indicates that the document is featuring a block or page
   */
  isFeaturing: boolean;
  /**
   * Indicates that the document is released to students.
   */
  isReleased: boolean;
  /**
   * Indicates that the document has no questions or other interactive content.
   */
  isStaticContent: boolean;
  /**
   * Id of the page for the document being viewed.  We need this for featuring
   */
  pageId: string;
  accessId: string;
};

export const DocViewContext = createContext<DocViewContextType>({} as DocViewContextType);
