// These are constants that are only used on the `client`.
// TODO: Future file/folder location should probably change.

export const INDEX_NOT_FOUND = -1;

export const WAITED_TOO_LONG_TIMEOUT_MS = 3000;

// View state //

export const PREVIEW_QUERY_PARAM = "preview";
export const FOLLOWING_QUERY_PARAM = "following";
export const DEVTOOL_QUERY_PARAM = "devtools";
export const STUDENT_VIEW_QUERY_PARAM = "student";
export const TIMELINE_VIEW_QUERY_PARAM = "timeline";
export const ASSESSMENT_VIEW_QUERY_PARAM = "assessing";

// Nav lists //

export const PAGE_ID_QUERY_PARAM = "page";
export const COURSE_CODE_ID_QUERY_PARAM = "course-code";
export const TERM_ID_QUERY_PARAM = "term";
// TODO: Revisit the query param for outcomes. We will likely want to query by outcome name instead of ID.
//       This will need to be changed for the course outcome page as well.
export const OUTCOME_ID_QUERY_PARAM = "outcome-id";

export const ACCESS_ID_QUERY_PARAM = "user";
export const SUBMISSION_ID_QUERY_PARAM = "submission";

// Content variants //

export const CATEGORY_QUERY_PARAM = "category";

// LOGO Sizes //
export const LOGO_DEFAULT_SIZE = 68;
export const LOGO_HEADER_SIZE = 88;
export const LOGO_FORM_SIZE = 124;

// Mobile routing //

export const MOBILE_OVERLAY_ROUTE_QUERY_PARAM = "overlay";
export type MobileOverlayRouteType =
  | "menu"
  | "users"
  | "settings"
  | "profile"
  | "planner"
  | "help"
  | "ai";
export const MOBILE_OVERLAY_ROUTES: MobileOverlayRouteType[] = [
  "menu",
  "users",
  "settings",
  "profile",
  "planner",
  "help",
  "ai",
];
