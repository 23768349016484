import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const HelpBugIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <rect
      className="stroke"
      height="12"
      rx="4"
      strokeLinecap="round"
      strokeLinejoin="round"
      width="8"
      x="2.99683"
      y="2.5"
    />
    <path
      className="stroke"
      d="M1.16663 5.66675H2.91663"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M11.0833 5.66675H12.8333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path className="stroke" d="M7 6.5L7 10.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      className="stroke"
      d="M3.43164 1L4.90423 3.07407"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M1.16663 8.58325H2.91663"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M1.16663 11.5H2.91663"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M10.525 1L9.05243 3.07407"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M11.0833 8.58325H12.8333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M11.0833 11.5H12.8333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
