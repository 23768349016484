import { FC, useEffect, useState } from "react";

import { updateCourse } from "../../../utils/course";
import { downloadJsonFile } from "../../../utils/file";

import { serverCloneCourse, serverExportCourse } from "api/api-server";
import { HoverableEditField } from "components/hover-widgets/EditableField";
import { HoverableToolbar } from "components/hover-widgets/HoverableToolbar";
import { CourseSidebarOptionsMenu } from "components/menus/CourseSidebarOptionsMenu";
import { showDeleteConfirmationModal } from "components/modals/DeleteCourseModal";
import type { CourseType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { DragAndDrop } from "mds/components/DragAndDrop";
import { ListItem } from "mds/components/ListItem";
import { AddPlusIcon, BookCourseIcon } from "mds/icons";
import { storeApi, useAppSelector } from "store/index";
import { OrderedCourseType, selectCourseCodeById } from "store/selectors";
import { selectCanAuthorOrg } from "store/selectors/permissions";
import { toastLocalizedOperationCatcher, toastSuccessMessage } from "utils/alerts";
import { useDnDItemOrdering } from "utils/collections";
import { onTitleChanged } from "utils/store-utils";
import { courseHomeUrl } from "utils/urls";

type CourseListPanelProps = {
  title: string;
  newCourseProps: Partial<CourseType>;
  courses: OrderedCourseType[];
  containerId: string;
  containerField: keyof CourseType;
};

export const CourseListPanel: FC<CourseListPanelProps> = ({
  title,
  newCourseProps,
  courses,
  containerId,
  containerField,
}) => {
  const [courseList, setCourseList] = useState<OrderedCourseType[]>(courses);
  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  const courseCode = useAppSelector((s) => selectCourseCodeById(s, courses?.[0]?.course_code_id));
  const canAuthorOrg = useAppSelector(selectCanAuthorOrg);

  useEffect(() => {
    setCourseList(courses.map((course) => ({ ...course })));
  }, [courses]);

  const moveItemsLocally = useDnDItemOrdering(courseList, setCourseList, containerField);

  const onDropItem = (
    droppedItem: OrderedCourseType,
    _dropIndex: number,
    sourceContainerId: string,
  ) => {
    const itemType = droppedItem[containerField];
    if (sourceContainerId !== itemType) {
      // If the item was dropped in a different container, we need to clean up afterwards
      // by removing it from the original container
      setCourseList((prevCourses) => prevCourses.filter((course) => course.id !== droppedItem.id));
    }
    handleSave({ id: droppedItem.id, order: droppedItem.order });
  };

  const handleCreate = () => {
    storeApi.courses.create({
      ...newCourseProps,
      [containerField]: containerId,
      title: t("course_list_panel.new_title"),
      description: t("course_list_panel.new_description"),
    } as CourseType);
  };

  // TODO: The "order" attribute we usually save here doesn't exist on the backend model.
  // Is this just re-ordering locally?
  const handleSave = (course: Partial<OrderedCourseType> & { id: string }) => {
    storeApi.courses.partial_update({ id: course.id, ...course });
  };

  const handleClone = (courseId: string, courseCodeId?: string, termId?: string) => {
    serverCloneCourse(courseId, courseCodeId, termId)
      .then(() => {
        toastSuccessMessage(t("success.toasts.clone_course_started_success"));
      })
      .catch(toastLocalizedOperationCatcher("copy_course_failure"));
  };

  const handleExportCourse = (course: CourseType) => async () => {
    const courseJson = await serverExportCourse(course.id);
    downloadJsonFile(courseJson, `${course.title}.json`);
  };

  const handleMoveTo = (course: CourseType) => (courseCodeId: string, termId: string) => {
    updateCourse({
      id: course.id,
      course_code_id: courseCodeId,
      term_id: termId,
    });
  };

  return (
    <div role="list">
      <HoverableToolbar reference="course" onClickAdd={canAuthorOrg ? handleCreate : undefined}>
        <h3 className="h3 my-0 text-black-tint-40">{title}</h3>
      </HoverableToolbar>

      {courseList.map((course, index) => (
        <DragAndDrop
          draggable={!isEditingTitle}
          dragItemKind="course"
          dropContainerId={containerId}
          dropIndex={index}
          item={course}
          key={course.id}
          droppable
          onDraggedItemHover={moveItemsLocally}
          onDropItem={onDropItem}
        >
          <ListItem className="my-1 justify-between" to={courseHomeUrl(course.id)}>
            <div className="w-full min-w-12 flex-auto">
              <HoverableToolbar
                className="w-full min-w-12"
                reference="course"
                showEditButton={canAuthorOrg}
                onEditModeChange={setIsEditingTitle}
              >
                <div className="body-s flex w-full min-w-12 items-center gap-2">
                  <BookCourseIcon />

                  <HoverableEditField
                    className="w-full min-w-12 truncate"
                    label={t("glossary.course_title")}
                    textSize="s"
                    value={course.title}
                    onValueChanged={(newTitle) => onTitleChanged("courses", course.id, newTitle)}
                  />
                </div>

                {canAuthorOrg && (
                  <CourseSidebarOptionsMenu
                    buttonKind="tertiary"
                    course={course}
                    optionsMenuActions={{
                      onDelete: () => showDeleteConfirmationModal({ course, courseCode }),
                      onDuplicate: () => handleClone(course.id),
                      onCopyTo: handleClone,
                      onMoveTo: handleMoveTo(course),
                      onExport: handleExportCourse(course),
                    }}
                  />
                )}
              </HoverableToolbar>
            </div>

            {/* TODO: Until we support LMS linking, we are intentionally hiding these
                LMS related buttons to avoid causing confusion during the Fall 2024 pilot.
                Note: when these are re-enabled check to confirm that long course titles are
                displayed properly as it currently has a bug in rendering.
                Also re-enable the 2 tests that are commented out in OrgView.test.tsx when this
                is re-enabled.
            <div className="flex items-center gap-1">
              <CircleExclamationIcon className="unlinked-icon" />

              {t("main_header.course_unlinked")}

              <Link className="text-blue" target="_blank" to="/learn-more">
                {t("common.learn_more")}
              </Link>

              <Button
                color="orange"
                kind="primary"
                size="xs"
                title={t("common.take_action")}
                onClick={showComingSoonAlert}
              >
                {t("common.take_action")}
              </Button>
            </div>
            */}
          </ListItem>
        </DragAndDrop>
      ))}

      {canAuthorOrg && (
        <Button
          className="gap-2"
          kind="tertiary"
          title={t("course_list_panel.add_course")}
          onClick={handleCreate}
        >
          <AddPlusIcon />
          <span>{t("course_list_panel.add_course")}</span>
        </Button>
      )}
    </div>
  );
};
