import clsx from "clsx";
import type { FC } from "react";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { ChevronUpIcon } from "mds/icons";
import { ChevronDownIcon } from "mds/icons/ChevronDown";

interface BlockMoveButtonsProps {
  className?: string;
  onMoveUp: () => void;
  onMoveDown: () => void;
  reachedTop: boolean;
  reachedBottom: boolean;
}

export const BlockMoveButtons: FC<BlockMoveButtonsProps> = ({
  className,
  onMoveUp,
  onMoveDown,
  reachedTop,
  reachedBottom,
}) => (
  <div className={clsx("absolute left-[-1.375rem] top-0 z-[1]", className)}>
    {onMoveUp && (
      <Button
        className={clsx("move-button cursor-pointer", reachedTop && "disabled")}
        disabled={reachedTop}
        kind="secondary"
        size="xs"
        title={t("tooltip.move_up")}
        iconOnly
        onClick={onMoveUp}
      >
        <ChevronUpIcon className={clsx(reachedTop && "icon-black-tint-70")} />
      </Button>
    )}

    {onMoveDown && (
      <Button
        className={clsx("move-button mt-1 cursor-pointer", reachedBottom && "disabled")}
        disabled={reachedBottom}
        kind="secondary"
        size="xs"
        title={t("tooltip.move_down")}
        iconOnly
        onClick={onMoveDown}
      >
        <ChevronDownIcon className={clsx(reachedBottom && "icon-black-tint-70")} />
      </Button>
    )}
  </div>
);
