import type { FC } from "react";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";

interface JoinGroupButtonProps {
  kind: "primary" | "secondary";
  disabled: boolean;
  isLoading: boolean;
  onChangeGroup: () => void;
  index: number;
}

export const JoinGroupButton: FC<JoinGroupButtonProps> = ({
  disabled,
  isLoading,
  kind,
  onChangeGroup,
  index,
}) => {
  return (
    <Button
      disabled={disabled}
      isLoading={isLoading}
      kind={kind}
      size="xs"
      title={
        disabled
          ? t("student_self_select_card.tooltip.group_full")
          : t("student_self_select_card.tooltip.become_member")
      }
      onClick={onChangeGroup}
    >
      {t("common.join_group", {
        number: index + 1,
      })}
    </Button>
  );
};
