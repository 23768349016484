import type { FC } from "react";
import { useContext } from "react";

import { t } from "i18n/i18n";
import { Banner } from "mds/components/Banner";
import { PageTabContext } from "providers/PageTabProvider";

interface MismatchedVersionBannerProps {
  onDismiss: () => void;
  onlySeeded: boolean;
}

export const MismatchedVersionBanner: FC<MismatchedVersionBannerProps> = ({
  onDismiss,
  onlySeeded,
}) => {
  const { setTabVariant } = useContext(PageTabContext);
  return (
    <Banner
      actionButtonText={t("common.view.template")}
      kind="warning"
      onAction={onlySeeded ? () => setTabVariant("seeded_content") : null}
      onDismiss={onDismiss}
    >
      <div className="body-m">
        <span className="font-medium">
          {t(onlySeeded ? "warning.seeded_content_updated" : "warning.page_updated")}
        </span>{" "}
        <span>
          {t(
            onlySeeded
              ? "warning.seeded_content_updated_secondary"
              : "warning.page_updated_secondary",
          )}
        </span>
      </div>
    </Banner>
  );
};
