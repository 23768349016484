import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const LockUnlockedIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M13.1336 15H2.86693C2.35173 15 1.93359 14.5818 1.93359 14.0666V7.53331C1.93359 7.01811 2.35173 6.59998 2.86693 6.59998H13.1336C13.6488 6.59998 14.0669 7.01811 14.0669 7.53331V14.0666C14.0669 14.5818 13.6488 15 13.1336 15Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      className="stroke"
      d="M4.7334 6.6V4.26667C4.7334 2.46253 6.19593 1 8.00006 1C9.48033 1 10.7301 1.98373 11.1314 3.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
  </svg>
);
