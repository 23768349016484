import { FC } from "react";

import { PagesCard } from "components/materials/topic/activity/detail/PagesCard";
import { t } from "i18n/i18n";
import { Card } from "mds/components/Card";
import { TextAddButton } from "mds/components/TextAddButton";
import { useAppSelector } from "store/index";
import { type ActivityWithPagesType, selectCanAuthorCourse } from "store/selectors";
import { usePageActions } from "utils/page";

export type AdditionalResourceListProps = {
  additionalResourceActivity: ActivityWithPagesType;
  activities: ActivityWithPagesType[];
};

/**
 * This component is for displaying a list of additional resources (Pages) related to a topic.
 * Note that there is exactly 1 addition resource activity per topic, and it cannot be archived or
 * renamed. There is no time estimate for an additional resource activity.
 */
export const AdditionalResourceList: FC<AdditionalResourceListProps> = ({
  additionalResourceActivity,
  activities,
}) => {
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const pages = additionalResourceActivity?.pages || [];
  const activity_id = additionalResourceActivity.id;

  const { createPage } = usePageActions();

  const createAndWaitForPage = () => createPage("activity_page", pages, activity_id);

  return (
    <div>
      <PagesCard
        activities={activities}
        activity_id={activity_id}
        canManagePages={canAuthorCourse}
        header={t("glossary.additional_resources")}
        pages={pages}
      />

      {canAuthorCourse && pages?.length === 0 && (
        <Card className="mb-3 mt-2">
          <div className="h4 flex items-center justify-start">
            <TextAddButton hideUnlessEditMode underlined onClick={createAndWaitForPage} />
          </div>
        </Card>
      )}
    </div>
  );
};
