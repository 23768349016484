import { FC } from "react";

import { t } from "i18n/i18n";
import { Button, ButtonProps } from "mds/components/Button";

export type StopPresentingButtonProps = {
  className: string;
  color: ButtonProps["color"];
  isPracticing?: boolean;
  onStopPresenting: () => void;
};

export const StopPresentingButton: FC<StopPresentingButtonProps> = ({
  className,
  color,
  isPracticing,
  onStopPresenting,
}) => (
  <Button className={className} color={color} kind="primary" size="xs" onClick={onStopPresenting}>
    {isPracticing ? t("main_header.stop_practicing") : t("main_header.stop_presenting")}
  </Button>
);
