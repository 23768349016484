import {
  ACCESS_ID_QUERY_PARAM,
  PAGE_ID_QUERY_PARAM,
  SUBMISSION_ID_QUERY_PARAM,
} from "components/constants";
import { OrgType, PageCategory } from "components/server-types";

const BASE_COURSE_URL = "/course/";

export const CONTENT_PATH_REGEX = /\/course\/[a-z0-9]+\/topic\/[a-z0-9]*/;
export const RESOURCE_PATH_REGEX = /\/course\/[a-z0-9]*\/course-page/;
export const PROJECTOR_PATH_REGEX = /\/course\/[a-z0-9]*\/projector/;

/** CourseViewVariant is used to set or determine what type of view is shown within the course.
 *  Currently, this is used to determine what is shown in the header.
 *  `main` covers all the standard views at the highest level of navigation within a course.
 *  Update `getCourseViewVariantFromPath` when adding new variants.
 */
export type CourseViewVariantType = "main" | "topic" | "course_page" | "profile";

export const isMpOrg = (org: OrgType | null) => {
  return org && org.short_name === "mp";
};

export const isValidUrl = (url: string) => {
  try {
    return Boolean(new URL(url));
  } catch {
    return false;
  }
};

export const withQueryParams = (path: string, params: Record<string, string>) => {
  const url = new URL(path, window.location.origin);
  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.set(key, value);
  });
  return url.toString();
};

/**
 * Determines the CourseViewVariant from the given path.
 */
export const getCourseViewVariantFromPath = (path: string): CourseViewVariantType => {
  if (CONTENT_PATH_REGEX.test(path)) {
    return "topic";
  }
  if (RESOURCE_PATH_REGEX.test(path)) {
    return "course_page";
  }
  return "main";
};

// Courses
export const courseHomeUrl = (courseId?: string) =>
  courseId ? `${BASE_COURSE_URL}${courseId}` : BASE_COURSE_URL;

export const courseContentUrl = (courseId: string, topicId: string, pageId?: string) => {
  const pageParam = pageId ? `?${PAGE_ID_QUERY_PARAM}=${pageId}` : "";
  return `${courseHomeUrl(courseId)}/topic/${topicId}${pageParam}`;
};

export const courseOutcomesUrl = (courseId: string, outcomeId?: string) => {
  if (outcomeId) {
    return `${courseHomeUrl(courseId)}?tab=outcomes&outcome-id=${outcomeId}`;
  }
  return `${courseHomeUrl(courseId)}?tab=outcomes`;
};

export const courseProjectorUrl = (courseId: string) => `${courseHomeUrl(courseId)}/projector`;

export const coursePageUrl = (courseId: string, pageCategory: PageCategory, pageId?: string) => {
  const idParam = pageId ? `&${PAGE_ID_QUERY_PARAM}=${pageId}` : "";
  return `${courseHomeUrl(courseId)}/course-page?category=${pageCategory}${idParam}`;
};

// Dev

export const devCourseMdsUrl = (courseId: string) => `${courseHomeUrl(courseId)}/mds`;
export const devCourseUsersUrl = (courseId: string) => `${courseHomeUrl(courseId)}/users`;

// Org

export const orgHomeUrl = "/org";
export const catalogUrl = "/org/catalog";
export const termsUrl = "/org/terms";
export const outcomesUrl = "/org/outcomes";
export const settingsUrl = "/org/settings";
export const usersUrl = "/org/users";

// Hard-coded url paths
// TODO: Add real value
export const ON_BOARDING_GUIDE_URL = "#";
export const BUG_REPORT_ROUTE = "https://form.asana.com/?k=QdgSyq5mH-JWNCJMXfsVcg&d=914015496185";

// Navigation

/**
 * Given a list of IDs and a query/search param to get the current ID from, determine which
 * is the previous/next ID in the list from the current position, and generate to link to them.
 */
export const getNavigationValues = (
  ids: string[],
  { queryParam, currentId }: { queryParam?: string; currentId?: string },
) => {
  let currentIndex = ids.indexOf(currentId);
  if (currentIndex === -1) {
    // If not found, assume we're on the first index
    currentIndex = 0;
  }

  const nextId = currentIndex < ids.length - 1 ? ids[currentIndex + 1] : null;
  const prevId = currentIndex > 0 ? ids[currentIndex - 1] : null;

  let nextURL: URL | undefined;
  let prevURL: URL | undefined;

  if (nextId && queryParam) {
    nextURL = new URL(location.href);
    nextURL.searchParams.set(queryParam, nextId);

    if (queryParam !== ACCESS_ID_QUERY_PARAM) {
      // Make sure we delete these
      nextURL.searchParams.delete(ACCESS_ID_QUERY_PARAM);
      nextURL.searchParams.delete(SUBMISSION_ID_QUERY_PARAM);
    }
  }

  if (prevId && queryParam) {
    prevURL = new URL(location.href);
    prevURL.searchParams.set(queryParam, prevId);

    if (queryParam !== ACCESS_ID_QUERY_PARAM) {
      // Make sure we delete these
      prevURL.searchParams.delete(ACCESS_ID_QUERY_PARAM);
      prevURL.searchParams.delete(SUBMISSION_ID_QUERY_PARAM);
    }
  }

  return { nextId, prevId, nextURL, prevURL };
};

export const relativeLink = (url: URL) => {
  return `${url.pathname}${url.search}`;
};
