import clsx from "clsx";
import type { FC, ReactNode } from "react";

import "./Card.scss";

interface CardProps {
  className?: string;
  children: ReactNode;
  isActive?: boolean;
  small?: boolean;
}

/**
 * A simple stylized card component that adds some padding and a white background.
 * The `isActive` prop can be used to add a border to the card.
 */
export const Card: FC<CardProps> = ({ children, small, className, isActive = false }) => {
  return (
    <div className={clsx("card", small && "card-small", isActive && "active", className)}>
      {children}
    </div>
  );
};
