import { ErrorMessage } from "@hookform/error-message";
import { AxiosError } from "axios";
import { isEqual } from "lodash";
import { FormProvider, SubmitHandler, useFieldArray, useForm } from "react-hook-form";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { ToggleButton } from "mds/components/ToggleButton";
import { CircleInfoIcon, ScoreIcon } from "mds/icons";
import { storeApi, useAppSelector } from "store/index";
import { selectOrgs } from "store/selectors";
import { toastLocalizedOperationError, toastSuccessMessage } from "utils/alerts";

type FormValues = {
  rubric: { name: string; score: number; conversion_percent: number }[];
};

export const OrgLmsSettings = () => {
  const orgs = useAppSelector(selectOrgs);
  const currentOrg = orgs.find((org) => org.current === true);

  const handleAllowCoursesChange = (allowCourses: boolean) => {
    storeApi.orgs.partial_update({
      id: currentOrg.id,
      courses_can_change_rubric_conversion_percent: allowCourses,
    });
  };

  const form = useForm<FormValues>({
    defaultValues: {
      rubric: currentOrg.rubric,
    },
  });
  const { fields: rubricFields } = useFieldArray({
    control: form.control,
    name: "rubric",
  });

  if (!currentOrg) {
    return null;
  }

  const toastUnknownError = () => {
    toastLocalizedOperationError("failed_to_update_rubric");
  };

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    if (!isEqual(formData.rubric, currentOrg.rubric)) {
      storeApi.orgs
        .partial_update({ id: currentOrg.id, rubric: formData.rubric })
        .then(() => toastSuccessMessage(t("success.toasts.saved_rubric")))
        .catch((error: unknown) => {
          if (!(error instanceof AxiosError)) {
            toastUnknownError();
            return;
          }
          if (error.response?.status !== 400) {
            toastUnknownError();
            return;
          }
          const respData = error.response?.data as {
            input: string;
            loc: [] | ["levels", number, "name" | "score" | "conversion_percent"];
            msg: string;
            type: string;
            url?: string;
          }[];
          if (!respData) {
            toastUnknownError();
            return;
          }
          respData.forEach(({ loc, msg }) => {
            if (loc.length === 0) {
              form.setError("root", {
                type: "server",
                message: msg,
              });
            } else if (loc[0] === "levels") {
              form.setError(`rubric.${loc[1]}.${loc[2]}`, {
                type: "server",
                message: msg,
              });
            }
          });
        });
    }
  };

  return (
    <FormProvider {...form}>
      <form
        className="shadow-level-1 bg-black-tint-99 px-3 py-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <h3 className="h3 mt-0 text-black-tint-40">{t("org.settings.lms.header")}</h3>
        <div className="mt-4 flex flex-row items-center gap-2 text-black-tint-20">
          <CircleInfoIcon className="icon-blue" />
          <span>{t("org.settings.lms.scale_change_alert")}</span>
        </div>
        <div className="h6 mt-2 text-black-tint-40">
          {t("org.settings.lms.rubric_conversion_scale")}
        </div>
        <div className="body-s ml-2 mt-2 text-black-tint-40">
          {t("org.settings.lms.rubric_conversion_scale_description")}
        </div>

        <div className="my-4 flex flex-col gap-4">
          <ErrorMessage name="root" />
          {rubricFields.map((field, index) => (
            <div className="flex flex-row gap-2" key={field.id}>
              <ScoreIcon className="mt-0.5" value={field.score} />
              <div className="flex flex-col items-start gap-2">
                <label>
                  <span className="h4 text-black-tint-20">{field.name}</span>
                  <div className="text-field-icon">
                    <input
                      key={field.id}
                      {...form.register(`rubric.${index}.conversion_percent`)}
                      className="text-field text-field-number text-black-tint-20"
                    />
                    <span>%</span>
                  </div>
                </label>
                <ErrorMessage name={`rubric.${index}.conversion_percent`} />
              </div>
            </div>
          ))}
        </div>

        <label className="body-s mt-4 flex items-center gap-2 text-black-tint-20">
          <ToggleButton
            id="allow-adjust"
            label={t("org.settings.lms.allow_courses_adjust_scale")}
            value={currentOrg.courses_can_change_rubric_conversion_percent}
            onChange={handleAllowCoursesChange}
          />
          <span>{t("org.settings.lms.allow_courses_adjust_scale")}</span>
        </label>
        <Button className="mt-4" type="submit">
          {t("common.save")}
        </Button>
      </form>
    </FormProvider>
  );
};
