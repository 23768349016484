import {
  getGroupIdFromAccessId,
  isGroupAccessId,
  isStudentAccessId,
} from "../../../../worksheets/shared/access-id";
import { CourseUserActivityType } from "../../../../worksheets/shared/types/analytics";

import { isUserOnline } from "components/materials/course/helpers";
import { CourseUserType, GroupingCategory, PageType } from "components/server-types";
import {
  CourseUserActivityStatus,
  NestedPageGroupType,
  NestedPageGroupUserType,
  UserWithCourseActivity,
  determineActivityStatus,
} from "store/selectors";

export type GroupChoiceType = GroupingCategory.ASSIGN_STUDENTS | GroupingCategory.SELF_ASSIGN;
export const GROUP_TYPE_CHOICES = [GroupingCategory.ASSIGN_STUDENTS, GroupingCategory.SELF_ASSIGN];

export const hasTotalUsersReachedMaxGroupSize = (pageGroup: NestedPageGroupType, page: PageType) =>
  page.max_group_size !== null && pageGroup.page_group_users.length >= page.max_group_size;

export const hasReadiedUsersReachedMaxGroupSize = (
  pageGroup: NestedPageGroupType,
  page: PageType,
) =>
  page.max_group_size !== null &&
  pageGroup.page_group_users.filter((pageGroupUser) => pageGroupUser.is_ready).length >=
    page.max_group_size;

export const isSharedClassPage = (page: PageType) =>
  page.grouping_category === GroupingCategory.SHARED_CLASS;
export const isGroupingCategory = (category: GroupingCategory) => {
  return GROUP_TYPE_CHOICES.includes(category);
};
export const isGroupPage = (page: PageType) => isGroupingCategory(page?.grouping_category);
export const isIndividualPage = (page: PageType) =>
  page?.grouping_category === GroupingCategory.INDIVIDUAL;
export const isValidAccessIdForPageType = (page: PageType, accessId: string) =>
  page &&
  ((isGroupPage(page) && isGroupAccessId(accessId)) ||
    (isIndividualPage(page) && isStudentAccessId(accessId)));

export const showPageDisabled = (page: PageType) =>
  !page.released_at && page.category !== "instructor_workspace";

export const isPageGroupUserInPageGroup = (
  pageGroupUser: NestedPageGroupUserType | null,
  pageGroup: NestedPageGroupType,
) => pageGroupUser && pageGroupUser.page_group_id === pageGroup.id;

export type DraggableCourseUserType = UserWithCourseActivity & {
  order: number;
  page_group_id?: string;
};

export type DraggablePageGroupUserType = NestedPageGroupUserType & {
  order: number;
};

export const findPageGroupIndexOfStudentUser = (
  pageGroups: NestedPageGroupType[],
  courseUser: CourseUserType,
) =>
  pageGroups.findIndex(
    (pageGroup) =>
      courseUser &&
      pageGroup.page_group_users.find(
        (pageGroupUser) => pageGroupUser.course_user_id === courseUser.id && pageGroupUser.is_ready,
      ),
  );

export const findPageGroupIndexOfAccessId = (
  pageGroups: NestedPageGroupType[],
  accessId: string | undefined,
) => {
  if (!accessId || !isGroupAccessId(accessId)) {
    return -1;
  }
  const groupId = getGroupIdFromAccessId(accessId);
  return pageGroups.findIndex((pageGroup) => pageGroup.id === groupId);
};

export const getUngroupedStudentUsers = (
  pageGroups: NestedPageGroupType[],
  studentUsers: UserWithCourseActivity[],
): DraggableCourseUserType[] => {
  const pageGroupUserIds = pageGroups
    .map((pageGroup) =>
      pageGroup.page_group_users.map((pageGroupUser) => pageGroupUser.course_user_id),
    )
    .flat();

  return studentUsers
    .filter((user) => !pageGroupUserIds.includes(user.course_user_id))
    .map((user, index) => ({ ...user, order: index }));
};

export const atLeastOneStudentNotInGroup = (
  pageGroups: NestedPageGroupType[],
  studentUsers: UserWithCourseActivity[],
  userActivityStatus: Record<string, CourseUserActivityType>,
) => {
  const ungroupedStudentUsers = getUngroupedStudentUsers(pageGroups, studentUsers);
  const atLeastOneUngroupedOnlineStudent =
    ungroupedStudentUsers.filter((studentUser) => isUserOnline(studentUser.courseActivityStatus))
      .length > 0;
  const atLeastOneStudentInGroup = ungroupedStudentUsers.length !== studentUsers.length;
  const atLeastOneUnreadyActiveStudent = pageGroups.some((pageGroup) =>
    pageGroup.page_group_users.find(
      (pageGroupUser) =>
        !pageGroupUser.is_ready &&
        determineActivityStatus(userActivityStatus[pageGroupUser.user.id]) ===
          CourseUserActivityStatus.ACTIVE,
    ),
  );

  return (
    (atLeastOneUngroupedOnlineStudent && atLeastOneStudentInGroup) || atLeastOneUnreadyActiveStudent
  );
};
