import "./CourseSidebarOptionsMenu.scss";

import { FC, Ref, useRef, useState } from "react";

import { SidebarOptionsMenu, SidebarOptionsMenuProps } from "./SidebarOptionsMenu";

import { CourseCodeMenu } from "components/menus/CourseCodeMenu";
import { TermMenu } from "components/menus/TermMenu";
import { CourseType } from "components/server-types";
import { t } from "i18n/i18n";
import { CancelSaveWidget } from "mds/components/CancelSaveWidget";
import { IconText } from "mds/components/IconText";
import { MenuInstance, MenuItem, SubMenu } from "mds/components/Menu";
import { ArrowDownIcon, CopySendArrowIcon } from "mds/icons";

interface CourseSidebarOptionsMenuProps extends SidebarOptionsMenuProps {
  course: CourseType;
  optionsMenuActions: SidebarOptionsMenuProps["optionsMenuActions"] & {
    onCopyTo?: (courseId: string, courseCodeId?: string, termId?: string) => void;
    onMoveTo?: (courseCodeId: string, termId: string) => void;
    onExport?: () => void;
  };
}

export const CourseSidebarOptionsMenu: FC<CourseSidebarOptionsMenuProps> = ({
  styles,
  size = "s",
  className,
  buttonKind = "secondary",
  direction = "right",
  course,
  optionsMenuActions,
}) => {
  const courseSidebarMenuRef: Ref<MenuInstance> = useRef(null);
  const [selectedCourseCodeId, setSelectedCourseCodeId] = useState(course.course_code_id);
  const [selectedTermId, setSelectedTermId] = useState(course.term_id);

  // NOTE: We use the same state for both copy and move to, this should be fine as these menus will never
  // render at the same time
  const handleCourseCodeChange = (newCourseCodeId: string) => {
    setSelectedCourseCodeId(newCourseCodeId);
  };

  const handleTermChange = (newTermId: string) => {
    setSelectedTermId(newTermId);
  };

  const copyToMenuItem = optionsMenuActions.onCopyTo && (
    <SubMenu
      label={
        <IconText iconStart={<CopySendArrowIcon />} text={t("sidebar_options_menu.copy_to")} />
      }
      withArrow
    >
      <div className="copy-course-menu h4 m-2 min-w-[280px]">
        <div className="pb-2 text-black-tint-40">{t("common.copy_to")}</div>

        <div className="flex">
          <CourseCodeMenu
            selectedCourseCodeId={selectedCourseCodeId}
            onCourseCodeChange={handleCourseCodeChange}
          />

          <TermMenu selectedTermId={selectedTermId} onTermChange={handleTermChange} />
        </div>

        <CancelSaveWidget
          className="right pt-2"
          saveTextKey="copy"
          onClickCancel={() => {
            courseSidebarMenuRef.current.closeMenu();
          }}
          onClickSave={() =>
            optionsMenuActions.onCopyTo(course.id, selectedCourseCodeId, selectedTermId)
          }
        />
      </div>
    </SubMenu>
  );

  const moveToMenuItem = optionsMenuActions.onMoveTo && (
    <SubMenu
      label={
        <IconText iconStart={<CopySendArrowIcon />} text={t("sidebar_options_menu.move_course")} />
      }
      withArrow
    >
      <div className="copy-course-menu h4 m-2 min-w-[280px]">
        <div className="pb-2 text-black-tint-40">{t("sidebar_options_menu.move_course_to")}</div>

        <div className="flex">
          <CourseCodeMenu
            selectedCourseCodeId={selectedCourseCodeId}
            onCourseCodeChange={handleCourseCodeChange}
          />

          <TermMenu selectedTermId={selectedTermId} onTermChange={handleTermChange} />
        </div>

        <CancelSaveWidget
          className="right pt-2"
          saveTextKey="move"
          onClickCancel={() => {
            courseSidebarMenuRef.current.closeMenu();
          }}
          onClickSave={() => {
            optionsMenuActions.onMoveTo(selectedCourseCodeId, selectedTermId);
            courseSidebarMenuRef.current.closeMenu();
          }}
        />
      </div>
    </SubMenu>
  );

  const exportMenuItem = optionsMenuActions.onExport && (
    <MenuItem onClick={optionsMenuActions.onExport}>
      <IconText iconStart={<ArrowDownIcon />} text={t("common.export")} />
    </MenuItem>
  );

  return (
    <SidebarOptionsMenu
      buttonKind={buttonKind}
      className={className}
      direction={direction}
      instanceRef={courseSidebarMenuRef}
      optionsMenuActions={optionsMenuActions}
      size={size}
      styles={styles}
    >
      {copyToMenuItem}
      {moveToMenuItem}
      {exportMenuItem}
    </SidebarOptionsMenu>
  );
};
