import "./CourseAtRiskView.scss";

import clsx from "clsx";
import { type FC } from "react";

import { AtRiskLevel, AtRiskScore } from "components/server-types";
import { Trans, t } from "i18n/i18n";
import { IconText } from "mds/components/IconText";
import { Tooltip } from "mds/components/Tooltip";
import { OneBarIcon, PersonSingleIcon, ThreeBarsIcon, TwoBarsIcon } from "mds/icons";
import type { UserWithCourseUserType } from "store/selectors";
import { getUserFullName } from "utils/user-utils";

const PERF_FIXED_DECIMALS = 1;
const EFFORT_FIXED_DECIMALS = 2;

export type UserAtRiskSectionProps = {
  level: "low" | "medium" | "high";
  users: UserWithCourseUserType[];
  scores: Record<string, AtRiskScore>;
};

const riskLevelIcons = {
  high: <OneBarIcon />,
  medium: <TwoBarsIcon />,
  low: <ThreeBarsIcon />,
};

const getScoreData = (score: AtRiskScore, level: UserAtRiskSectionProps["level"]) => {
  const effortLevel =
    level === AtRiskLevel.LOW || score?.effort_risk_level === AtRiskLevel.LOW
      ? null
      : score?.effort_risk_level;
  const effortMessage = effortLevel ? t(`course_at_risk_view.effort_message.${effortLevel}`) : "";

  const perfLevel =
    level === AtRiskLevel.LOW || score?.performance_risk_level === AtRiskLevel.LOW
      ? null
      : score?.performance_risk_level;
  const performanceMessage = perfLevel
    ? t(`course_at_risk_view.performance_message.${perfLevel}`, {
        perfScore: (score?.performance_score || 0).toFixed(PERF_FIXED_DECIMALS),
      })
    : "";

  const effortScore = score?.effort_score?.toFixed(0);
  const effortZScore = score?.effort_z_score?.toFixed(EFFORT_FIXED_DECIMALS);

  return {
    effortMessage,
    performanceMessage,
    effortScore,
    effortZScore,
  };
};

export const UserAtRiskSection: FC<UserAtRiskSectionProps> = ({ level, users, scores }) => (
  <div className="at-risk-section min-w-0 pr-3">
    <h4 className={`at-risk-level-${level} mb-3`}>
      <IconText iconStart={riskLevelIcons[level]} text={t(`course_at_risk_view.level.${level}`)} />
    </h4>

    <ol className="mb-0 flex list-none flex-col gap-2 p-0">
      {users.map((user) => {
        const { effortMessage, performanceMessage, effortScore, effortZScore } = getScoreData(
          scores[user.id],
          level,
        );

        return (
          <Tooltip
            element={
              <li className={clsx("my-1 flex min-w-0 flex-row items-center justify-between gap-2")}>
                <div className="flex min-w-0 flex-row items-center gap-2">
                  <PersonSingleIcon />
                  <span className="block min-w-0 truncate">{getUserFullName(user)}</span>
                </div>
              </li>
            }
            key={user.id}
          >
            <Trans
              components={{
                div: <div />,
                smallDiv: <div className="mt-3 text-xs" />,
                list: <ul />,
                effortItem: effortMessage ? <li /> : <span />,
                performanceItem: performanceMessage ? <li /> : <span />,
              }}
              i18nKey={`course_at_risk_view.user_summary_${level}`}
              t={t}
              values={{
                effortMessage,
                performanceMessage,
                effortScore,
                effortZScore,
              }}
            />
          </Tooltip>
        );
      })}
    </ol>
  </div>
);
