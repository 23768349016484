import clsx from "clsx";
import type { FC, InputHTMLAttributes } from "react";

interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  containerClassName?: string;
}

export const RadioButton: FC<RadioButtonProps> = ({
  containerClassName,
  id,
  children,
  ...props
}) => {
  return (
    <div className={clsx("mc-authoring flex items-center", containerClassName)}>
      <input className="mc-authoring-radio mr-2" id={id} type="radio" {...props} />
      <label htmlFor={id}>{children}</label>
    </div>
  );
};
