import "./CourseAssessmentView.scss";

import { useEffect } from "react";

import { PageLink } from "./PageLink";
import { useFetchAndSelectMyAssessments } from "./assessment-data";
import { average } from "./utils";

import { tableDateFormat } from "components/materials/page/helpers";
import { displayOutcomeName } from "components/outcomes/helpers";
import { t } from "i18n/i18n";
import { Tooltip } from "mds/components/Tooltip";
import { ChatIcon, ScoreIcon } from "mds/icons";
import { storeApi, useAppSelector } from "store/index";
import {
  selectCurrentCourseId,
  selectCurrentUserPagesWithAssessments,
  selectFullCourseOutcomes,
  selectPageOutcomes,
} from "store/selectors";

export const CourseAssessmentStudentView = () => {
  const courseId = useAppSelector(selectCurrentCourseId);
  const courseOutcomes = useAppSelector((s) => selectFullCourseOutcomes(s, courseId));
  // TODO: Should we bundle these in the selectors somehow?
  const pageOutcomes = useAppSelector(selectPageOutcomes);

  // This includes page groups and submissions
  useFetchAndSelectMyAssessments();

  useEffect(() => {
    storeApi.page_outcomes.list({ page__course_id: courseId });
  }, [courseId]);

  const pagesWithAssessments = useAppSelector(selectCurrentUserPagesWithAssessments);
  const allAssessments = pagesWithAssessments.map((p) => p.assessments).flat();
  const allOutcomeAssessments = allAssessments.map((a) => a.outcome_assessments || []).flat();

  return (
    <div className="assessment-dashboard overflow-hidden">
      <div className="mb-8 w-full overflow-hidden">
        <div className="flex w-full items-center justify-between">
          <h2 className="h3 m-0 text-black-tint-40">{t("assessment_dashboard.all.title")}</h2>
        </div>
        <div className="assessment-dashboard-table overflow-hidden">
          <table className="mds-table">
            {/* Header like: Page/Points/Outcomes/Submitted At */}
            <thead>
              <tr>
                <th className="th-l">{t("assessment_dashboard.all.page_header_title")}</th>
                <th className="th-s">{t("assessment_dashboard.student.points_header_title")}</th>
                <th className="th-s">{t("assessment_dashboard.all.outcomes_header_title")}</th>
                <th className="th-s">{t("assessment_dashboard.student.submitted_header_title")}</th>
              </tr>
            </thead>
            <tbody className="body-xs">
              {pagesWithAssessments.map((page) => {
                // TODO: The selector should allow for non-final submissions so that we can display comments here
                // There should never be more than one assessment for a page and user, but just
                // in case something fishy happens, we handle it gracefully here.
                const officialAssessment = page.assessments[0];
                const points = officialAssessment?.points;
                const submittedDate =
                  officialAssessment?.submission &&
                  tableDateFormat(officialAssessment?.submission?.created_at);

                const assessmentsWithComments = page.nonOfficialAssessments
                  .concat(page.assessments)
                  .filter((a) => a.comment?.trim());
                const pageSpecificOutcomes = pageOutcomes.filter((po) => po.page_id === page.id);

                return (
                  <tr key={page.id}>
                    <td className="flex w-full justify-start gap-2">
                      <PageLink page={page} />
                      {assessmentsWithComments.length > 0 &&
                        assessmentsWithComments.map((assessment) => (
                          <Tooltip element={<ChatIcon />} key={assessment.id}>
                            <div className="body-xs text-black-tint-40">{assessment.comment}</div>
                          </Tooltip>
                        ))}
                    </td>
                    <td>{points || "-"}</td>
                    <Tooltip element={<td>{pageSpecificOutcomes.length}</td>}>
                      {pageSpecificOutcomes
                        .map((po) => courseOutcomes.find((co) => co.id === po.course_outcome_id))
                        .filter(Boolean)
                        .map((co) => displayOutcomeName(co.outcome))
                        .join(", ")}
                    </Tooltip>
                    <td>{submittedDate || "-"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="assessment-dashboard-section">
        <div className="flex w-full items-center justify-between">
          <h2 className="h3 m-0 flex items-center gap-2 text-black-tint-40">
            <span className="pb-1">{t("assessment_dashboard.student_outcome_index_title")}</span>
          </h2>
        </div>
        <div className="assessment-dashboard-table">
          <div className="assessment-dashboard-table overflow-hidden">
            <table className="mds-table">
              {/* Header like: Page/Outcome A/Outcome B/Outcome C */}
              {/* One row per student, showing each student's aggregate outcome scores */}
              <thead>
                <tr>
                  <th className="th-l">{t("assessment_dashboard.all.page_header_title")}</th>
                  {courseOutcomes.map((courseOutcome) => (
                    <th className="th-xs content-end" key={courseOutcome.id}>
                      <div className="vertical" title={displayOutcomeName(courseOutcome.outcome)}>
                        {displayOutcomeName(courseOutcome.outcome)}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody />
              <tbody className="body-xs">
                {pagesWithAssessments.map((page) => (
                  <tr key={page.id}>
                    <td>
                      <PageLink page={page} />
                    </td>
                    {courseOutcomes.map((courseOutcome) => {
                      const userOutcomeAssessmentsForThisOutcome = allOutcomeAssessments.filter(
                        (oa) =>
                          pageOutcomes.some(
                            (po) =>
                              po.page_id === page.id &&
                              po.course_outcome_id === courseOutcome.id &&
                              oa.page_outcome_id === po.id,
                          ),
                      );
                      const outcomeScore = userOutcomeAssessmentsForThisOutcome.length
                        ? average(userOutcomeAssessmentsForThisOutcome.map((oa) => oa.score))
                        : NaN;
                      return (
                        <td key={courseOutcome.id}>
                          <ScoreIcon value={outcomeScore} />
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
