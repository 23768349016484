import clsx from "clsx";
import { FC } from "react";
import { Link } from "react-router-dom";

import { ScoreIcon } from "mds/icons";

interface OutcomeChipPropType {
  title: string;
  score?: number;
  selected?: boolean;
  to?: string;
  onClick?: () => void;
  className?: string;
  size?: "s" | "m";
}

export const OutcomeChip: FC<OutcomeChipPropType> = ({
  score,
  title,
  to,
  onClick,
  size = "s",
  selected,
  className,
}) => {
  const hoverable = (to || onClick) && !selected;
  const chip = (
    <div
      className={clsx(
        "flex w-max items-center gap-2.5 rounded-xl font-medium",
        size === "s" ? "px-2 py-1 text-sm" : "px-2.5 py-1.5 text-base",
        selected ? "bg-blue text-white" : "bg-blue-tint-95 text-blue-shade-20",
        hoverable && "cursor-pointer hover:bg-blue-tint-90",
        className,
      )}
    >
      <div>{title}</div>
      {score !== undefined && <ScoreIcon size={size} value={score} />}
    </div>
  );

  if (to) {
    return (
      <Link to={to} onClick={onClick}>
        {chip}
      </Link>
    );
  }

  if (onClick) {
    return (
      <button type="button" onClick={onClick}>
        {chip}
      </button>
    );
  }
  return chip;
};
