import "../CourseDetailView.scss";

import { FC } from "react";

import { LoadingScreen } from "components/LoadingScreen";
import { HoverableEditField } from "components/hover-widgets/EditableField";
import { HoverableToolbar } from "components/hover-widgets/HoverableToolbar";
import { CourseListPanel } from "components/materials/org/CourseListPanel";
import { CourseCodeType } from "components/server-types";
import { t } from "i18n/i18n";
import { selectTermCoursesByCourseCode } from "store/selectors";
import { useListSelector } from "store/store-hooks";
import { onTitleChanged } from "utils/store-utils";

type CourseDetailTermViewProps = {
  courseCode: CourseCodeType;
};

export const CourseDetailTermView: FC<CourseDetailTermViewProps> = ({ courseCode }) => {
  const termCourses = useListSelector((s) => selectTermCoursesByCourseCode(s, courseCode?.id));

  if (!courseCode) {
    return <LoadingScreen text={t("org.settings.no_courses")} />;
  }

  if (!termCourses.length) {
    return <LoadingScreen text={t("org.settings.no_terms")} />;
  }

  return (
    <div className="course-detail-page">
      <HoverableToolbar
        contentClassName="mt-1.5"
        disableUnlessEditMode={false}
        reference="course_code"
        showEditButton
        uncentered
      >
        <h2 className="my-0">
          <HoverableEditField
            className="code-title"
            label={t("glossary.course_code_title")}
            textSize="h2"
            value={courseCode.title}
            onValueChanged={(newTitle) => onTitleChanged("course_codes", courseCode.id, newTitle)}
          />
        </h2>
      </HoverableToolbar>

      {termCourses.map((term) => (
        <div className="course-panel" key={term.id}>
          <CourseListPanel
            containerField="term_id"
            containerId={term.id}
            courses={term.courses}
            newCourseProps={{ course_code_id: courseCode.id }}
            title={term.title}
          />
        </div>
      ))}
    </div>
  );
};
