import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const HomeHouseIcon: FC<IconProps> = (props) => {
  return (
    <svg {...initializeSvgIconProps(props)}>
      <g clipPath="url(#clip0_197_40878)">
        <path className="stroke" d="M1 7.04545L8 1.31818L15 7.04545" strokeMiterlimit="10" />
        <path
          className="stroke"
          d="M6.72705 14.6818V10.8636H9.27251V14.6818"
          strokeMiterlimit="10"
        />
        <path
          className="stroke"
          d="M2.90918 8.31818V13.4091C2.90918 14.1123 3.47873 14.6818 4.18191 14.6818H11.8183C12.5215 14.6818 13.091 14.1123 13.091 13.4091V8.31818"
          strokeLinecap="square"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_197_40878">
          <rect fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};
