import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const CircleQuestionIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <rect className="stroke" height="14" rx="7" width="14" x="1" y="1" />
    <path
      className="fill"
      d="M7.24801 9.57513V9.50766C7.25274 9.06732 7.29654 8.71694 7.3794 8.45652C7.46463 8.1961 7.58536 7.9854 7.74161 7.82442C7.89786 7.66343 8.08607 7.51665 8.30625 7.38408C8.44829 7.29412 8.57613 7.1935 8.68977 7.08223C8.8034 6.97096 8.89337 6.84312 8.95965 6.69871C9.02594 6.5543 9.05909 6.39449 9.05909 6.2193C9.05909 6.0086 9.00937 5.82631 8.90994 5.67243C8.81051 5.51855 8.67793 5.40018 8.51221 5.31732C8.34886 5.23209 8.16657 5.18947 7.96534 5.18947C7.78304 5.18947 7.60904 5.22735 7.44332 5.30311C7.2776 5.37887 7.14029 5.49724 7.03139 5.65822C6.92249 5.81684 6.85975 6.02162 6.84318 6.27257H5.76363C5.7802 5.84644 5.88792 5.48659 6.08678 5.19303C6.28565 4.8971 6.54843 4.67338 6.87514 4.52186C7.20421 4.37035 7.56761 4.29459 7.96534 4.29459C8.40094 4.29459 8.7821 4.37626 9.1088 4.53962C9.43551 4.7006 9.68882 4.92669 9.86875 5.21788C10.051 5.50671 10.1422 5.84407 10.1422 6.22996C10.1422 6.49511 10.1008 6.73422 10.0179 6.94729C9.93503 7.15799 9.81666 7.3462 9.66278 7.51192C9.51126 7.67764 9.32897 7.82442 9.1159 7.95226C8.91467 8.07773 8.75132 8.20794 8.62585 8.34288C8.50274 8.47783 8.41278 8.63763 8.35596 8.82229C8.29914 9.00695 8.26837 9.2354 8.26363 9.50766V9.57513H7.24801ZM7.78423 11.7342C7.5901 11.7342 7.4232 11.6656 7.28352 11.5283C7.14384 11.3886 7.074 11.2205 7.074 11.024C7.074 10.8299 7.14384 10.6641 7.28352 10.5268C7.4232 10.3872 7.5901 10.3173 7.78423 10.3173C7.97599 10.3173 8.14171 10.3872 8.28139 10.5268C8.42343 10.6641 8.49446 10.8299 8.49446 11.024C8.49446 11.1542 8.46131 11.2738 8.39502 11.3827C8.3311 11.4892 8.24588 11.5744 8.13934 11.6383C8.03281 11.7023 7.91444 11.7342 7.78423 11.7342Z"
    />
  </svg>
);
