import { FC } from "react";

import { useFetchAssessmentStatus } from "./course/assessment/assessment-data";
import { PagesCard } from "./topic/activity/detail/PagesCard";

import { t } from "i18n/i18n";
import { Card } from "mds/components/Card";
import { IconText } from "mds/components/IconText";
import { AssessPointsMarkerIcon } from "mds/icons";
import { selectActivities, selectAllRecentlyAssessedPagesInCurrentCourse } from "store/selectors";
import { useListSelector } from "store/store-hooks";

export const RecentlyAssessedPagesList: FC = () => {
  const activities = useListSelector(selectActivities);

  const recentlyAssessedPagesInTheLastSevenDays = useListSelector((s) =>
    selectAllRecentlyAssessedPagesInCurrentCourse(s, 7),
  );

  useFetchAssessmentStatus(recentlyAssessedPagesInTheLastSevenDays);

  const header = (
    <IconText
      iconStart={<AssessPointsMarkerIcon />}
      text={t(`course.sidebar.planner.recently_assessed`)}
    />
  );

  if (!recentlyAssessedPagesInTheLastSevenDays.length) {
    return (
      <>
        <div className="m-2 text-black-tint-40">{header}</div>
        <Card small>
          <div className="body-s p-2 font-light text-black-tint-40">
            {t("course.sidebar.planner.no_pages_recently_assessed")}
          </div>
        </Card>
      </>
    );
  }

  return (
    <PagesCard
      activities={activities}
      header={header}
      pages={recentlyAssessedPagesInTheLastSevenDays}
    />
  );
};
