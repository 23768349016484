import clsx from "clsx";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useBoolean } from "usehooks-ts";

import {
  PASSWORD_MIN_LENGTH,
  hasLowercase,
  hasNumber,
  hasUppercase,
} from "../helpers/password-errors";

import { ErrorMessageBanner } from "components/forms/ErrorMessageBanner";
import { PasswordRequirementTooltip } from "components/sso/PasswordRequirementTooltip";
import { t } from "i18n/i18n";

export type PasswordFormValue = {
  password: string;
  confirm_password: string;
};

interface PasswordFormProps {
  currentPassword?: string;
  useEmailLoginFormStyling?: boolean;
  isNewPassword?: boolean;
}

export const PasswordForm: FC<PasswordFormProps> = ({
  currentPassword,
  useEmailLoginFormStyling,
  isNewPassword,
}) => {
  const {
    value: isPasswordFieldFocused,
    setTrue: focusPasswordField,
    setFalse: blurPasswordField,
  } = useBoolean(false);

  const form = useFormContext<PasswordFormValue>();
  const password = form.watch("password");

  return (
    <>
      <div className={clsx(useEmailLoginFormStyling ? "body-m-bold" : "body-s", "flex flex-col")}>
        <label htmlFor="password">
          {isNewPassword ? t("user_info.password") : t("user_info.new_password")}
        </label>

        <PasswordRequirementTooltip
          element={
            <div>
              <input
                {...form.register("password", {
                  required: t("error.field_required"),
                  minLength: {
                    value: PASSWORD_MIN_LENGTH,
                    message: t("error.change_password.password_too_short", {
                      count: PASSWORD_MIN_LENGTH,
                    }),
                  },
                  validate: {
                    mustBeDifferent: (value: string) => {
                      if (value === currentPassword) {
                        return t("error.change_password.must_be_different");
                      }
                    },
                    mustHaveNumber: (value: string) => {
                      if (!hasNumber(value)) {
                        return t("error.change_password.no_digit");
                      }
                    },
                    mustHaveUppercase: (value: string) => {
                      if (!hasUppercase(value)) {
                        return t("error.change_password.no_uppercase");
                      }
                    },
                    mustHaveLowercase: (value: string) => {
                      if (!hasLowercase(value)) {
                        return t("error.change_password.no_lowercase");
                      }
                    },
                  },
                })}
                aria-invalid={form.formState.errors.password ? "true" : "false"}
                autoComplete="new-password"
                className={
                  useEmailLoginFormStyling
                    ? "email-login-form-input"
                    : "text-field body-m w-full text-black-tint-20"
                }
                id="password"
                type="password"
                onBlur={() => blurPasswordField()}
                onFocus={() => focusPasswordField()}
              />
            </div>
          }
          isFieldFocused={isPasswordFieldFocused}
          password={password}
        />
        <ErrorMessageBanner className="body-s mt-1" name="password" />
      </div>

      <div className={useEmailLoginFormStyling ? "body-m-bold" : "body-s"}>
        <label htmlFor="confirm_password">
          {isNewPassword ? t("user_info.confirm_password") : t("user_info.confirm_new_password")}
        </label>

        <div>
          <input
            {...form.register("confirm_password", {
              required: t("error.field_required"),
              validate: {
                match: (value: string) => {
                  if (value !== password) {
                    return t("error.change_password.do_not_match");
                  }
                },
              },
            })}
            aria-invalid={form.formState.errors.confirm_password ? "true" : "false"}
            autoComplete="new-password"
            className={
              useEmailLoginFormStyling
                ? "email-login-form-input"
                : "text-field body-m w-full text-black-tint-20"
            }
            id="confirm_password"
            type="password"
          />
        </div>
        <ErrorMessageBanner className="body-s mt-1" name="confirm_password" />
      </div>
    </>
  );
};
