import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const PersonClassIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M5.5 7.5C7.15685 7.5 8.5 6.15685 8.5 4.5C8.5 2.84315 7.15685 1.5 5.5 1.5C3.84315 1.5 2.5 2.84315 2.5 4.5C2.5 6.15685 3.84315 7.5 5.5 7.5Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M12.5 6.5C13.6046 6.5 14.5 5.60457 14.5 4.5C14.5 3.39543 13.6046 2.5 12.5 2.5C11.3954 2.5 10.5 3.39543 10.5 4.5C10.5 5.60457 11.3954 6.5 12.5 6.5Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M5.5 9.5C4.17392 9.5 2.90215 10.0268 1.96447 10.9645C1.02678 11.9021 0.5 13.1739 0.5 14.5H10.5C10.5 13.1739 9.97322 11.9021 9.03553 10.9645C8.09785 10.0268 6.82608 9.5 5.5 9.5Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M10.8408 9C11.3322 8.67303 11.9096 8.49903 12.4998 8.5C13.2955 8.5 14.0585 8.81607 14.6211 9.37868C15.1838 9.94129 15.4998 10.7044 15.4998 11.5V12.5H12.4998"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
