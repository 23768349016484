import type { FC } from "react";

import { BlockVariantType } from "../../../../../../collaboration/src/shared/types/worksheet";

import { t } from "i18n/i18n";
import { IconText } from "mds/components/IconText";
import { PresentPlayOutlineIcon } from "mds/icons";
import { FeatureStatus } from "utils/presentation";

type FeatureHeaderProps = {
  // Apparently typescript isn't smart enough to see we pass these to another function???
  // eslint-disable-next-line react/no-unused-prop-types
  isPresenting: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  featureStatus: FeatureStatus;
  // eslint-disable-next-line react/no-unused-prop-types
  responseName?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  blockType: BlockVariantType;
};

const getHeaderText = ({
  featureStatus,
  isPresenting,
  responseName,
  blockType,
}: FeatureHeaderProps) => {
  if (featureStatus)
    switch (featureStatus) {
      case FeatureStatus.ALL_RESPONSES:
        return isPresenting
          ? t("presentation.featuring.all_responses")
          : t("presentation.featuring.status.all_responses");

      case FeatureStatus.PARTICIPANT_RESPONSE:
        return isPresenting
          ? t("presentation.featuring.student_responses", { name: responseName })
          : t("presentation.featuring.status.participant_response");

      case FeatureStatus.OUTCOME:
        return t("presentation.featuring.status.outcome");

      case FeatureStatus.BLOCK:
        return t(`presentation.featuring.blocks.${blockType}`);

      default:
        return t(`presentation.featuring.status.${featureStatus}`);
    }
};

export const FeatureHeader: FC<FeatureHeaderProps> = (props) => {
  const headerText = getHeaderText(props);

  return (
    <IconText
      className="icon-green gap-4 font-medium"
      iconStart={<PresentPlayOutlineIcon />}
      text={headerText}
    />
  );
};
