import "./EmbedField.scss";

import clsx from "clsx";
import { get } from "lodash";
import { type FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import type { Doc } from "sharedb/lib/client";

import { deleteBlockFromWorksheet } from "../../shared/doc-helpers";
import type {
  OEmbedObjectType,
  OEmbedPhotoType,
  OEmbedRichType,
  OEmbedVideoType,
} from "../../shared/types/oembed";
import type {
  EmbedFieldType,
  OEmbedDataType,
  PathType,
  WorksheetType,
} from "../../shared/types/worksheet";

import { showOEmbedModal } from "./EmbedModal";

import { HoverableToolbar } from "components/hover-widgets/HoverableToolbar";
import {
  VideoChangeMessage,
  WindowMessages,
  addProjectorMessageListener,
  removeProjectorMessageListener,
  sendMessageToProjectorView,
} from "components/materials/presentation/projector/projector-messaging";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { PencilEditIcon, TrashDeleteIcon } from "mds/icons";
import { DocViewContext } from "providers/DocViewProvider";
import { fetchOEmbed } from "utils/oembed";
import { usePresentation } from "utils/presentation";

interface EmbedProps {
  doc: Doc<WorksheetType>;
  path: PathType;
}

type EmbedInfoType = {
  id: string;
  userIsProjecting: boolean;
};

enum PlayerState {
  UNSTARTED = -1,
  ENDED = 0,
  PLAYING = 1,
  PAUSED = 2,
  BUFFERING = 3,
  VIDEO_CUED = 5,
}

type YouTubeMessage = {
  event: "infoDelivery";
  info: {
    playerState: PlayerState;
    currentTime: number;
    playbackRate: number;
    videoData?: {
      video_id: string;
    };
  };
};

export const EmbedField: FC<EmbedProps & EmbedFieldType> = ({ doc, path, url, o, id }) => {
  const { isAuthoringPage, canAuthorPage, isProjecting, isFeaturing } = useContext(DocViewContext);
  const [oEmbed, setOEmbed] = useState<OEmbedObjectType>(o as OEmbedObjectType);
  const { isPresenting, hasProjectorView } = usePresentation();

  const playbackRateRef = useRef(1);
  // This field serves dual purposes as it's used both by the instructor view and the projector view
  // Each of them set it at a different point as well.
  const videoPlayingRef = useRef(false);
  const shouldSyncWithProjectorView = isPresenting && hasProjectorView;
  const embedId = isFeaturing ? `featuring-embed-${id}` : `embed-${id}`;

  useEffect(() => {
    const checkEmbed = async () => {
      const embed = await fetchOEmbed(url);
      if (!embed) {
        setOEmbed(null);
      }
    };

    if (url) {
      checkEmbed();
    }
  }, [url]);

  const handleYouTubeMessage = useCallback(
    (event: MessageEvent<string>) => {
      if (event.origin !== "https://www.youtube.com" || !shouldSyncWithProjectorView) {
        return;
      }

      const data = JSON.parse(event.data) as YouTubeMessage;

      const playerState = data?.info?.playerState;
      const currentTime = data?.info?.currentTime;
      const playbackRate = data?.info?.playbackRate;
      const videoId = data?.info?.videoData?.video_id;

      if (
        (videoPlayingRef.current || url.includes(videoId)) &&
        [PlayerState.PLAYING, PlayerState.ENDED, PlayerState.PAUSED].includes(playerState)
      ) {
        const message: VideoChangeMessage = {
          type: WindowMessages.VIDEO_CHANGE,
          id,
          playing: playerState === PlayerState.PLAYING,
        };

        videoPlayingRef.current = playerState === PlayerState.PLAYING;

        if (currentTime) {
          message.currentTime = currentTime;
        }

        sendMessageToProjectorView(message);
      } else if (
        videoPlayingRef.current &&
        playbackRate &&
        playbackRate !== playbackRateRef.current
      ) {
        const message: VideoChangeMessage = {
          type: WindowMessages.VIDEO_CHANGE,
          id,
          playbackRate,
        };

        sendMessageToProjectorView(message);
        playbackRateRef.current = playbackRate;
      }
    },
    [id, url, shouldSyncWithProjectorView],
  );

  useEffect(() => {
    if (shouldSyncWithProjectorView && oEmbed) {
      const embedIframe: HTMLIFrameElement = document.getElementById(embedId) as HTMLIFrameElement;

      // Tell youtube we want to listen to status changes
      embedIframe.onload = () => {
        embedIframe.contentWindow.postMessage(JSON.stringify({ event: "listening" }), "*");
      };

      // Listen for messages from the iframe
      window.addEventListener("message", handleYouTubeMessage);

      return () => {
        window.removeEventListener("message", handleYouTubeMessage);
      };
    }
  }, [oEmbed, embedId, shouldSyncWithProjectorView, handleYouTubeMessage]);

  useEffect(() => {
    const handleYoutubeChangeMessage = (message: MessageEvent<VideoChangeMessage>) => {
      if (
        message.data.type === WindowMessages.VIDEO_CHANGE &&
        isProjecting &&
        message.data.id === id
      ) {
        const embedIframe: HTMLIFrameElement = document.getElementById(
          embedId,
        ) as HTMLIFrameElement;

        if (message.data.currentTime) {
          embedIframe.contentWindow.postMessage(
            JSON.stringify({ event: "command", func: "seekTo", args: [message.data.currentTime] }),
            "*",
          );
        }

        if (message.data.playbackRate) {
          embedIframe.contentWindow.postMessage(
            JSON.stringify({
              event: "command",
              func: "setPlaybackRate",
              args: [message.data.playbackRate],
            }),
            "*",
          );
        }

        if (message.data.playing && !videoPlayingRef.current) {
          embedIframe.contentWindow.postMessage(
            JSON.stringify({ event: "command", func: "playVideo", args: [] }),
            "*",
          );
          videoPlayingRef.current = true;
        } else if (message.data.playing === false && videoPlayingRef.current) {
          embedIframe.contentWindow.postMessage(
            JSON.stringify({ event: "command", func: "pauseVideo", args: [] }),
            "*",
          );
          videoPlayingRef.current = false;
        }
      }
    };

    if (isProjecting) {
      addProjectorMessageListener(handleYoutubeChangeMessage);
    }

    return () => {
      removeProjectorMessageListener(handleYoutubeChangeMessage);
    };
  }, [isProjecting, id, embedId]);

  const onDelete = useCallback(() => {
    const blockIndex = Number(path[1]);
    deleteBlockFromWorksheet(doc, blockIndex);
  }, [doc, path]);

  const updateDoc = useCallback(
    (newData: OEmbedDataType) => {
      setOEmbed(newData.o as OEmbedObjectType);

      const fieldData = get(doc.data, path) as EmbedFieldType;
      const newFieldData = { ...fieldData, ...newData };
      doc.submitOp([{ p: path, ld: fieldData, li: newFieldData }]);
    },
    [doc, path],
  );

  const onEdit = () => showOEmbedModal(url, updateDoc);

  const errorKey = url && canAuthorPage ? "url_no_longer_valid" : "url_missing";

  const toolbar = (
    <div className={clsx("toolbar-buttons flex items-center gap-1", oEmbed ? "py-1 pr-2" : "p-3")}>
      <Button kind="secondary" title={t("common.edit")} iconOnly onClick={onEdit}>
        <PencilEditIcon />
      </Button>

      <Button kind="destructive" title={t("common.delete")} iconOnly onClick={onDelete}>
        <TrashDeleteIcon />
      </Button>
    </div>
  );

  return oEmbed ? (
    <>
      {isAuthoringPage && (
        <div className="embed-toolbar" role="toolbar">
          {toolbar}
        </div>
      )}
      <OEmbedIframe id={embedId} userIsProjecting={shouldSyncWithProjectorView} {...oEmbed} />
    </>
  ) : (
    <HoverableToolbar>
      <div className="no-content">{t(`fields.embed.${errorKey}`)}</div>
      {isAuthoringPage && toolbar}
    </HoverableToolbar>
  );
};

const OEmbedVideoIframe: FC<OEmbedVideoType & EmbedInfoType> = ({
  id,
  userIsProjecting,
  ...oEmbed
}) => {
  // Get iframe src from html
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(oEmbed.html, "text/html");
  const htmlSrc = htmlDoc.querySelector("iframe")?.src;
  const paramSeparator = htmlSrc.includes("?") ? "&" : "?";
  const mirrorParams = userIsProjecting ? `&mute=1` : "";

  const src = `${htmlSrc}${paramSeparator}enablejsapi=1${mirrorParams}`;

  const aspectRatio = oEmbed.width / oEmbed.height;

  return (
    <iframe
      allow="autoplay; fullscreen"
      className="w-full max-w-[--page-default-max-width]"
      frameBorder="0"
      id={id}
      referrerPolicy="strict-origin-when-cross-origin"
      src={src}
      style={{ aspectRatio }}
      title={`Embedded video: ${oEmbed.title}`}
      allowFullScreen
    />
  );
};

const OEmbedImg: FC<OEmbedPhotoType & EmbedInfoType> = ({ id, ...oEmbed }) => {
  const aspectRatio = oEmbed.width / oEmbed.height;

  return (
    <img
      alt={oEmbed.title}
      className="w-full max-w-[--page-default-max-width]"
      id={id}
      src={oEmbed.url}
      style={{ aspectRatio }}
    />
  );
};

const OEmbedRichIframe: FC<OEmbedRichType & EmbedInfoType> = ({ id, ...oEmbed }) => {
  // Get iframe src from html
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(oEmbed.html, "text/html");
  const src = htmlDoc.querySelector("iframe")?.src;

  const aspectRatio = oEmbed.width / oEmbed.height;

  return (
    <iframe
      className="w-full max-w-[--page-default-max-width]"
      frameBorder="0"
      id={id}
      referrerPolicy="strict-origin-when-cross-origin"
      src={src}
      style={{ aspectRatio }}
      title={oEmbed.title}
    />
  );
};

const oEmbedTypeMap: Record<OEmbedObjectType["type"], React.FC<OEmbedObjectType>> = {
  photo: OEmbedImg,
  rich: OEmbedRichIframe,
  video: OEmbedVideoIframe,
};

const OEmbedIframe: FC<OEmbedObjectType & EmbedInfoType> = (props) => {
  const OEmbedComponent = oEmbedTypeMap[props.type];

  if (OEmbedComponent) {
    return <OEmbedComponent {...props} />;
  }

  return <div>{t("fields.embed.unsupported_type", { type: props.type })}</div>;
};
