/**
 * This file defines types for permissions and adjacent concepts that are used to
 * evaluate authentication/authorization on websocket connections between
 * Collaboration and Client.
 */

import type WebSocket from "ws";

/**
 * These roles only apply to Pubsub and Collaboration.
 *
 * In pubsub, these roles are used to determine whether a model update should be broadcast to a
 * user depending on whether they can see information generally available to either students or staff.
 * There might be cases where some staff can see content that other staff can't, in the future, in which
 * case this will need to be slightly reworked. Right now, this edge case doesn't exist.
 *
 * In collaboration, someone with a "staff" role can write to a Page's template and see all other user's
 * responses on a Page (but not modify them). Someone with a "student" role can have similar read access,
 * but it depends on a variety of factors, such as ownership, group membership, whether the "All Responses" view
 * has been shown, etc. Student can never write to a Page's template, but by default, write to their own response
 * for Pages that they can also specifically read (e.g. released Pages).
 */
export type WebsocketRoleType = "student" | "staff";

/**
 * Used to set granular permissions for specific access IDs on a page.
 */
export type AccessIDPermissionType = "read" | "write";
/**
 * Specifies permissions of a user for a specific page. By default, unless overwritten,
 * an empty PagePermission means a user can read/write to the access ID matching their own user ID.
 * Instructors/Admins can read all access IDs by default, write to the "global" access ID,
 * and author the page content.
 * "rollup" indicates that the user can read-only view all other student/group access IDs.
 */
export type PagePermissionType = {
  rollup: boolean;
  group_ids: string[];
};

export type PageRemovalPermissionType = {
  visible: false;
};

/**
 * Each websocket connection to Collaboration is associated with a user and course,
 * and this object tracks the read/write permissions that the websocket has.
 *
 * Django updates Collaboration when the permissions of a user change.
 * Collaboration sometimes re-validates permissions when a user tries
 * to perform an action, to prevent cases where an update goes missing or is stale.
 *
 * For update messages, the entirety of the permissions that are relevant for
 * Collaboration and the Pubsub forwarding system are serialized in one object,
 * which represent the new permission state. Any permissions not included are
 * assumed to be removed.
 */
export interface WebsocketUserDataType {
  user_id: string;
  email: string;
  // course_id is null only if you're not in any course, and you will only get org-level
  // data synced via the websocket.
  course_id: string | null;
  org_id: string;
  role: WebsocketRoleType;
  pages: Record<string, PagePermissionType>;
}

export interface WebsocketUserDataMessageType extends Omit<WebsocketUserDataType, "pages"> {
  scope: "individual" | "all";
  pages: Record<string, PagePermissionType | PageRemovalPermissionType>;
}

export type ExtendedWsType = WebSocket & {
  userData: WebsocketUserDataType;
  uniqueId?: string;
  isAlive?: boolean;
};

export interface WebsocketErrorType {
  code: number;
  message: string;
}

export type WebsocketAuthErrorType =
  | "DOC_CREATE_DISALLOWED"
  | "DOC_DELETE_DISALLOWED"
  | "DOC_GLOBAL_EDIT_DISALLOWED"
  | "MISSING_READ_PERMISSION"
  | "MISSING_WRITE_PERMISSION"
  | "MISSING_EDIT_PERMISSION"
  | "EDIT_MALFORMED"
  | "MALFORMED_RICHTEXT"
  | "DOC_READ_ONLY"
  | "OVERWRITE_DISALLOWED"
  | "MISSING_WRITE_PERMISSION_FOR_PATH"
  | "ACCESS_ID_WRAPPER_MALFORMED"
  | "FIELD_DATA_WRAPPER_MALFORMED"
  | "UNKNOWN_ERROR";

export const AUTH_ERRORS: Record<WebsocketAuthErrorType, WebsocketAuthErrorType> = {
  DOC_CREATE_DISALLOWED: "DOC_CREATE_DISALLOWED",
  DOC_DELETE_DISALLOWED: "DOC_DELETE_DISALLOWED",
  DOC_GLOBAL_EDIT_DISALLOWED: "DOC_GLOBAL_EDIT_DISALLOWED",
  MISSING_READ_PERMISSION: "MISSING_READ_PERMISSION",
  MISSING_WRITE_PERMISSION: "MISSING_WRITE_PERMISSION",
  MISSING_WRITE_PERMISSION_FOR_PATH: "MISSING_WRITE_PERMISSION_FOR_PATH",
  ACCESS_ID_WRAPPER_MALFORMED: "ACCESS_ID_WRAPPER_MALFORMED",
  FIELD_DATA_WRAPPER_MALFORMED: "FIELD_DATA_WRAPPER_MALFORMED",
  MISSING_EDIT_PERMISSION: "MISSING_EDIT_PERMISSION",
  EDIT_MALFORMED: "EDIT_MALFORMED",
  MALFORMED_RICHTEXT: "MALFORMED_RICHTEXT",
  OVERWRITE_DISALLOWED: "OVERWRITE_DISALLOWED",
  DOC_READ_ONLY: "DOC_READ_ONLY",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
};

export const AUTH_ERROR_MESSAGES: Record<WebsocketAuthErrorType, string> = {
  DOC_CREATE_DISALLOWED: "Creating new documents is not allowed.",
  DOC_DELETE_DISALLOWED: "Creating new documents is not allowed.",
  DOC_GLOBAL_EDIT_DISALLOWED: "Can't modify the base document structure.",
  MISSING_READ_PERMISSION: "You don't have permission to view this document.",
  MISSING_WRITE_PERMISSION: "You don't have permission to write in this document.",
  MISSING_EDIT_PERMISSION: "You don't have permission to modify this document.",
  MISSING_WRITE_PERMISSION_FOR_PATH: "You don't have permission to write in this path.",
  ACCESS_ID_WRAPPER_MALFORMED: "This is not a valid access ID wrapper.",
  FIELD_DATA_WRAPPER_MALFORMED: "This is not a valid field data wrapper.",
  EDIT_MALFORMED: "The submitted operation does not fit the schema of the given path.",
  OVERWRITE_DISALLOWED: "Overwriting this data is not allowed.",
  MALFORMED_RICHTEXT: "The rich text operation includes invalid data.",
  DOC_READ_ONLY: "Can't edit a read-only document.",
  UNKNOWN_ERROR: "Failed to perform the requested operation.",
};
