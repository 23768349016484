import {
  createAppSelector,
  selectActivities,
  selectCurrentCourseId,
  selectPageOutcomes,
  selectPages,
  selectTopics,
} from "./base";
import { selectCurrentCourse, selectPagesForCurrentCourse } from "./course";
import { selectCanAuthorCourse, selectCanAuthorOrg } from "./permissions";
import { ActivityWithPagesType, PageWithDueDateType, TopicWithActivitiesType } from "./types";

import { isDateWithinLastXDays } from "components/materials/page/helpers";
import { ActivityPageType, ReleaseStatus } from "components/server-types";
import { RootState } from "store";
import { sortActivities } from "utils/activity";
import { getReleaseStatus } from "utils/page";

// This is different from selectPagesForCurrentCourse in that it also selects topics-related pages
export const selectAllPagesWithDueDatesInCurrentCourse = createAppSelector(
  [selectPages, selectCanAuthorCourse, selectCurrentCourseId],
  (pages, canAuthorCourse, courseId) => {
    return pages.filter(
      (page) => page.course_id === courseId && page.due_at && (canAuthorCourse || page.released_at),
    ) as PageWithDueDateType[];
  },
);

export const selectAllRecentlyAssessedPagesInCurrentCourse = createAppSelector(
  [(state, daysAgo: number) => daysAgo, (state) => selectPagesForCurrentCourse(state)],
  (daysAgo, pages) => {
    return pages.filter((page) => isDateWithinLastXDays(page.assessments_published_at, daysAgo));
  },
);

export const selectPagesForTopic = createAppSelector(
  [(state, topicId: string) => topicId, selectPages, (state) => state.activities],
  (topicId, pages, activities) => {
    return pages.filter((page) => {
      const activity = activities[page.activity_id];
      return activity && activity.topic_id === topicId;
    });
  },
);

export const selectPagesForOutcome = createAppSelector(
  [(state, courseOutcomeId: string) => courseOutcomeId, selectPages, selectPageOutcomes],
  (courseOutcomeId, pages, pageOutcomes) => {
    return pages.filter((page) =>
      pageOutcomes.some((po) => po.course_outcome_id === courseOutcomeId && po.page_id === page.id),
    );
  },
);

export const selectChunkedPagesForOutcomeByTopic = createAppSelector(
  [
    (state, courseOutcomeId: string) => selectPagesForOutcome(state, courseOutcomeId),
    selectActivities,
    selectTopics,
  ],
  (pages, activities, topics) => {
    const byTopic = topics.map((topic) => {
      const topicPages = pages.filter((page) => {
        const activity = activities.find((act) => act.id === page.activity_id);
        return activity?.topic_id === topic.id;
      });
      return { ...topic, pages: topicPages };
    });
    return byTopic;
  },
);

export const selectTopicStatus = createAppSelector(
  [(state, topicId: string) => selectPagesForTopic(state, topicId)],
  (pages): ReleaseStatus | null => getReleaseStatus(pages),
);

export const selectTopicsForCurrentCourse = createAppSelector(
  [selectTopics, selectCurrentCourse],
  (topics, currentCourse) => topics.filter((topic) => topic.course_id === currentCourse.id),
);

/**
 * This selector is very similar to `selectTopicsForCurrentCourse`, but it filters out topics
 * that should not be visible to the current user.
 * For instructors, the result of this selector is identical to `selectTopicsForCurrentCourse`.
 * But for students, this selector will filter out topics that have no released pages.
 */
export const selectVisibleTopicsForCurrentCourse = createAppSelector(
  [selectPages, (state) => state.activities, selectTopicsForCurrentCourse, selectCanAuthorCourse],
  (pages, activities, topicsForCurrentCourse, canAuthorCourse) => {
    if (canAuthorCourse) {
      return topicsForCurrentCourse;
    }

    const releasedPages = pages.filter((page) => page.released_at);

    return topicsForCurrentCourse.filter((topic) => {
      // Topics have a `release_status` attribute, but we can't rely on it,
      // because it's not updated when pages are released/unreleased.
      // Instead, we'll see if the topic has any pages that are released.
      const topicHasReleasedPages = releasedPages.some((page) => {
        const activity = activities[page.activity_id];
        return activity && activity.topic_id === topic.id;
      });
      return topicHasReleasedPages;
    });
  },
);

export const selectTopicById = (state: RootState, topicId: string) => state.topics[topicId];

export const selectHasImported = createAppSelector(
  [(state, courseId: string) => courseId, selectTopics, selectActivities, selectPages],
  (courseId, topics, activities, pages) => {
    const courseTopics = topics.filter((topic) => topic.course_id === courseId);
    if (courseTopics.some((topic) => topic.progenitor_id)) {
      return true;
    }

    if (
      activities.some(
        (activity) =>
          courseTopics.find((topic) => topic.id === activity.topic_id) && activity.progenitor_id,
      )
    ) {
      return true;
    }

    if (pages.some((page) => page.course_id === courseId && page.progenitor_id)) {
      return true;
    }

    return false;
  },
);

export const selectSortedTopicActivities = createAppSelector(
  [(state, topicId: string) => topicId, selectActivities, selectPages, selectCanAuthorCourse],
  (topicId, activities, pages, canAuthorCourse) => {
    const sortedActivities = activities
      .filter((activity) => activity.topic_id === topicId)
      .sort(sortActivities);

    return sortedActivities.map((activity) => {
      const activityPages = pages
        .filter((page) => page.activity_id === activity.id)
        .filter((page) => canAuthorCourse || page.released_at);
      return { ...activity, pages: activityPages } as ActivityWithPagesType;
    });
  },
);

export const selectSortedTopicActivitiesForCurrentCourse = createAppSelector(
  [
    (state, pageFilter: (page: ActivityPageType) => boolean) => pageFilter,
    selectTopicsForCurrentCourse,
    selectActivities,
    selectPages,
    selectCanAuthorCourse,
    selectCanAuthorOrg,
  ],
  (pageFilter, topics, activities, pages, canAuthorCourse, canAuthorOrg) => {
    return topics.reduce((topicsAccum, topic) => {
      const sortedActivities = activities
        .filter((activity) => activity.topic_id === topic.id)
        .sort(sortActivities);

      const canAuthor = canAuthorCourse || canAuthorOrg;

      const activityWithPages = sortedActivities.reduce((activitiesAccum, activity) => {
        const activityPages = pages
          .filter((page) => page.activity_id === activity.id)
          .filter((page) => canAuthor || page.released_at)
          .filter(pageFilter || (() => true));

        if (activityPages.length > 0) {
          activitiesAccum.push({ ...activity, pages: activityPages } as ActivityWithPagesType);
        }
        return activitiesAccum;
      }, [] as ActivityWithPagesType[]);

      if (activityWithPages.length > 0) {
        topicsAccum.push({ ...topic, activities: activityWithPages } as TopicWithActivitiesType);
      }
      return topicsAccum;
    }, [] as TopicWithActivitiesType[]);
  },
);
