import { FC } from "react";

import {
  useAssessedCountData,
  useAssessmentMode,
  useAssessmentPublishActions,
} from "../../../../utils/assessment";

import {
  getMeanScoreByPageOutcomeId,
  showUnpublishAssessmentModal,
} from "components/materials/page/assessment/helpers";
import { displayOutcomeName } from "components/outcomes/helpers";
import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { HorizontalDivider } from "mds/components/HorizontalDivider";
import { OutcomeChip } from "mds/components/OutcomeChip";
import { AssessPointsMarkerIcon, CrossRemoveIcon, ScoreIcon } from "mds/icons";
import { useAppDispatch, useAppSelector } from "store/index";
import { selectNestedPageOutcomes, selectOfficialFullSubmissionsByPageId } from "store/selectors";
import { viewStateActions } from "store/slices/view";
import { useListSelector } from "store/store-hooks";

interface AssessmentSummaryProps {
  page: PageType;
}
export const AssessmentSummary: FC<AssessmentSummaryProps> = ({ page }) => {
  const dispatch = useAppDispatch();
  const pageOutcomes = useAppSelector((s) => selectNestedPageOutcomes(s, page.id));
  const officialSubmissions = useListSelector((s) =>
    selectOfficialFullSubmissionsByPageId(s, page?.id),
  );

  const { unpublishGrades } = useAssessmentPublishActions(page);
  const { startAssessing } = useAssessmentMode(page);
  const { count, total: totalSubmissionsCount } = useAssessedCountData(page);
  const isAllAssessed = officialSubmissions.length === totalSubmissionsCount;

  const onUnpublishGrades = () => unpublishGrades();

  const outcomeAssessments = officialSubmissions.map((s) => s.outcome_assessments).flat();
  const meanScoreByPageOutcomeId = getMeanScoreByPageOutcomeId(outcomeAssessments);

  return (
    <div className="flex h-full w-full flex-col gap-4 rounded-2xl border border-black-tint-90 px-5 py-4">
      <div className="flex flex-row flex-wrap items-center justify-between">
        <div className="flex flex-wrap items-center gap-1 text-black-tint-20">
          <AssessPointsMarkerIcon />
          <h3 className="m-0">{t("glossary.assessment")}</h3>
        </div>

        <Button
          kind="secondary"
          iconOnly
          onClick={() => dispatch(viewStateActions.hideEditAssessmentBanner())}
        >
          <CrossRemoveIcon />
        </Button>
      </div>
      <div className="text-sm text-black-tint-20">
        {t("assessment.assessment_summary.description", {
          context: isAllAssessed ? "all" : "partial",
          count: officialSubmissions.length,
          total: totalSubmissionsCount,
        })}
      </div>
      <Button kind="secondary" fullWidth onClick={() => startAssessing()}>
        {isAllAssessed ? t("assessment.edit_assessments") : t("assessment.resume_assessment")}
      </Button>
      <Button
        className={outcomeAssessments.length > 0 && "mb-2"}
        kind="secondary"
        fullWidth
        onClick={() => showUnpublishAssessmentModal(count, onUnpublishGrades)}
      >
        {t("assessment.unpublish_all")}
      </Button>
      {outcomeAssessments.length > 0 ? (
        <>
          <HorizontalDivider />
          <div className="mt-2 text-sm text-black-tint-20">
            {t("assessment.assessment_summary.outcome_summary")}
          </div>
          <div className="flex w-full flex-col gap-1">
            {pageOutcomes.map((pageOutcome) => (
              <div className="flex flex-row items-center justify-between" key={pageOutcome.id}>
                <OutcomeChip size="s" title={displayOutcomeName(pageOutcome.outcome)} />
                <ScoreIcon size="m" value={meanScoreByPageOutcomeId[pageOutcome.id]} />
              </div>
            ))}
          </div>
          <Button
            kind="secondary"
            fullWidth
            onClick={() => dispatch(viewStateActions.hideEditAssessmentBanner())}
          >
            {t("common.close")}
          </Button>
        </>
      ) : null}
    </div>
  );
};
