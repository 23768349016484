import { createContext } from "react";

import type { PageTabVariant } from "components/materials/page/helpers";
import type { TabItem } from "mds/components/TabBar";

type PageTabContextType = {
  tabs: TabItem[];
  tabIndex: number;
  setTab: (index: number) => void;
  setTabVariant: (tabVariant: PageTabVariant) => void;
};

export const PageTabContext = createContext<PageTabContextType>({
  tabs: [],
  tabIndex: 0,
  setTab: () => {},
  setTabVariant: () => {},
});
