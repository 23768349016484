import { GLOBAL_SHARED_ACCESS_ID, INSTRUCTOR_SHARED_ACCESS_ID } from "./access-id";
import {
  AccessIdDataType,
  AccessIdMetaDataType,
  AssetFieldType,
  BlockType,
  CellType,
  ChoiceFieldType,
  CodeCellType,
  CodeCellTypeOption,
  CodeFieldType,
  EmbedCellType,
  EmbedFieldType,
  FieldType,
  FileCellType,
  FileFieldType,
  FreeResponseFieldType,
  InstructionsFieldType,
  MultipleChoiceCellType,
  RichTextCellType,
  TableCellId,
  TableCellsMapType,
  TableDimensionsType,
  TableFieldType,
  WorksheetType,
} from "./types/worksheet";
import { createId } from "./utils";

export const MAX_BLOCK_COUNT = 999;
export const DEFAULT_FIELD_VERSION_NUMBER = 1;

// Location of blocks in Worksheet
export const BLOCKS_PATH = "b";
// Location of user content in Worksheet
export const DATA_PATH = "d";
// Location of fields list in a block
export const FIELDS_KEY = "f";
// Location of value within a field or choice
export const VALUE_KEY = "v";

export const ASSET_BLOCK = "Asset";

export const BLOCKS_WITH_BUILT_IN_TOOLBAR = ["Embed", ASSET_BLOCK] as BlockType["t"][];
export const STATIC_CONTENT_BLOCK_TYPES = [
  "Instructions",
  "Embed",
  ASSET_BLOCK,
] as BlockType["t"][];
export const STATIC_CONTENT_FIELD_TYPES = ["Text", "AssetField", "EmbedField"] as FieldType["t"][];
export const INSTRUCTOR_NOTES_BLOCK_PATH = [BLOCKS_PATH, "0", FIELDS_KEY, "0", VALUE_KEY];

export const STUDENT_DATA_INITIALIZATION: Record<
  FieldType["t"],
  (initialData?: RichTextCellType | TableDimensionsType | string) => CellType
> = {
  RichTextField: (d?: RichTextCellType): RichTextCellType => d || { ops: [] },
  ChoiceField: (): MultipleChoiceCellType => [],
  TableField: (d?: TableDimensionsType): TableDimensionsType => d,
  CodeField: (d?: string): CodeCellType => ({
    c: d || "",
    o: [],
    p: [],
    ec: 0,
  }),
  EmbedField: (): EmbedCellType => ({ url: "" }),
  FileField: (): FileCellType => [],
  // These should stay unused, but are required so we can have strict types
  Text: () => {
    throw new Error("Text Field has no student data");
  },
  AssetField: () => {
    throw new Error("AssetField has no student data");
  },
};

export const initializeStudentDataForField = (field: FieldType): CellType => {
  if (field.t === "RichTextField") {
    return STUDENT_DATA_INITIALIZATION[field.t](field.v);
  }
  if (field.t === "TableField") {
    return STUDENT_DATA_INITIALIZATION[field.t](field.v);
  }
  if (field.t === "CodeField") {
    return STUDENT_DATA_INITIALIZATION[field.t](field.c);
  }
  if (field.t === "FileField") {
    return STUDENT_DATA_INITIALIZATION[field.t]();
  }
  if (field.t === "ChoiceField") {
    return STUDENT_DATA_INITIALIZATION[field.t]();
  }
  if (field.t === "EmbedField") {
    return STUDENT_DATA_INITIALIZATION[field.t]();
  }
  throw new Error(`Can't initialize student data for incompatible field '${field.t}'`);
};

export const isEditableCodeBlock = (block: BlockType) =>
  // Read-only and hidden code fields don't count as questions
  block.t === "Code" &&
  block.f.some((field) => "cellType" in field && field.cellType === "editable");

export const isQuestionBlock = (block: BlockType) =>
  !STATIC_CONTENT_BLOCK_TYPES.includes(block.t) &&
  (block.t !== "Code" || isEditableCodeBlock(block));

// The ID is used for React "key" property to track fields during re-renders
// specifically when e.g. in a Table, rows or columns are added or removed, or
// multiple choice order is changed. Four characters are sufficient to avoid glitchy
// renders in these cases.
export const getFieldShortId = () => createId().slice(-4);

export const createTableCellId = (rowId: string, colId: string): TableCellId => `${rowId},${colId}`;

export const createTableDimensions = ({
  rows,
  cols,
  readOnlyHeader = false,
}: {
  rows: number | string[];
  cols: number | string[];
  readOnlyHeader?: boolean;
}): TableDimensionsType => {
  const r = Array.isArray(rows) ? rows : Array.from(Array(rows), getFieldShortId);
  const c = Array.isArray(cols) ? cols : Array.from(Array(cols), getFieldShortId);
  if (!readOnlyHeader) {
    return {
      r,
      c,
      cm: {},
    };
  }

  // if roHeader is true, the first row is read-only
  const firstRowId = r[0];
  const firstRowCells = c.reduce((cells, colId) => {
    // eslint-disable-next-line no-param-reassign
    cells[createTableCellId(firstRowId, colId)] = { ro: true, v: { ops: [] } };
    return cells;
  }, {} as TableCellsMapType);
  return {
    r,
    c,
    cm: firstRowCells,
  };
};

export const DEFAULT_FIELDS: Record<FieldType["t"], Omit<FieldType, "id">> = {
  Text: {
    t: "Text",
    ver: DEFAULT_FIELD_VERSION_NUMBER,
    v: { ops: [] },
  } as InstructionsFieldType,
  RichTextField: {
    t: "RichTextField",
    ver: DEFAULT_FIELD_VERSION_NUMBER,
    maxLength: null,
  } as FreeResponseFieldType,
  ChoiceField: {
    t: "ChoiceField",
    ver: DEFAULT_FIELD_VERSION_NUMBER,
    m: false,
    c: [
      { v: "0", l: "A", id: "0000" },
      { v: "1", l: "B", id: "0001" },
    ],
  } as Omit<ChoiceFieldType, "id">,
  TableField: {
    t: "TableField",
    ver: DEFAULT_FIELD_VERSION_NUMBER,
    v: createTableDimensions({ rows: 3, cols: 3, readOnlyHeader: true }),
  } as Omit<TableFieldType, "id">,
  CodeField: {
    t: "CodeField",
    ver: DEFAULT_FIELD_VERSION_NUMBER,
    c: "",
    cellType: "editable",
  } as CodeFieldType,
  EmbedField: {
    t: "EmbedField",
    ver: DEFAULT_FIELD_VERSION_NUMBER,
    url: "",
  } as EmbedFieldType,
  FileField: {
    t: "FileField",
    ver: DEFAULT_FIELD_VERSION_NUMBER,
    m: true,
  } as FileFieldType,
  AssetField: {
    t: "AssetField",
    ver: DEFAULT_FIELD_VERSION_NUMBER,
    url: null,
    size: null,
    name: null,
    fileType: null,
    view: "inline",
  } as AssetFieldType,
};

export const DEFAULT_BLOCKS: Record<
  BlockType["t"],
  { t: BlockType["t"]; f: Omit<FieldType, "id">[] }
> = {
  Instructions: {
    t: "Instructions",
    f: [DEFAULT_FIELDS.Text],
  },
  Asset: {
    t: "Asset",
    f: [DEFAULT_FIELDS.AssetField],
  },
  FreeResponse: {
    t: "FreeResponse",
    f: [DEFAULT_FIELDS.Text, DEFAULT_FIELDS.RichTextField],
  },
  MultipleChoice: {
    t: "MultipleChoice",
    f: [DEFAULT_FIELDS.Text, DEFAULT_FIELDS.ChoiceField],
  },
  Table: {
    t: "Table",
    f: [DEFAULT_FIELDS.TableField],
  },
  Code: {
    t: "Code",
    f: [DEFAULT_FIELDS.CodeField],
  },
  File: {
    t: "File",
    f: [DEFAULT_FIELDS.Text, DEFAULT_FIELDS.FileField],
  },
  Embed: {
    t: "Embed",
    f: [DEFAULT_FIELDS.EmbedField],
  },
};

export const CODE_CELLTYPE_OPTIONS: { label: string; value: CodeCellTypeOption }[] = [
  {
    label: "Student-editable",
    value: "editable",
  },
  {
    label: "Read-only",
    value: "readonly",
  },
  {
    label: "Hidden",
    value: "hidden",
  },
];

export const EMPTY_WORKSHEET_DATA_WITHOUT_ID: Partial<WorksheetType> = {
  b: [],
  d: {
    [INSTRUCTOR_SHARED_ACCESS_ID]: { versions: {} } as AccessIdDataType,
    [GLOBAL_SHARED_ACCESS_ID]: { versions: {} } as AccessIdDataType,
  },
  locked: false,
};

export const ACCESS_ID_WRAPPER = {
  versions: {},
};

// Should be in line with all keys of AccessIdMetaDataType
export const ACCESS_ID_DATA_META_FIELDS: (keyof AccessIdMetaDataType)[] = [
  "versions",
  "done",
  "kernelState",
  "runningCell",
  "runningCellStartedAt",
];
