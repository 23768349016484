import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const ChatIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M1 14.5295V2.56506C1 1.7007 1.69645 1 2.55556 1H13.4444C14.3036 1 15 1.7007 15 2.56506V10.3904C15 11.2547 14.3036 11.9554 13.4444 11.9554H4.85875C4.3862 11.9554 3.93927 12.1716 3.64406 12.5428L1.83107 14.8228C1.5555 15.1694 1 14.9734 1 14.5295Z"
    />
  </svg>
);
