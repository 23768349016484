import { SVGProps } from "react";

import { BaseSizeType } from "mds/global-types";

export type IconColorType = "gray" | "green" | "orange" | "red";

export const COLOR_TO_FILL_CLASS: Record<IconColorType, string> = {
  gray: "fill-black-tint-70",
  green: "fill-green",
  orange: "fill-orange",
  red: "fill-red",
};

export const ICON_SIZES: Record<BaseSizeType, number> = {
  xs: 12,
  s: 14,
  m: 16,
  l: 20,
};

export type IconProps = SVGProps<SVGSVGElement> & {
  size?: BaseSizeType;
};

export type IconColorProps = IconProps & {
  color?: IconColorType;
};

/*
 * Initializes svg properties for an icon.  Mainly we use this to add
 * sizing to each icon in one place.
 */
export const initializeSvgIconProps = ({ size = "m", ...svgProps }: IconProps) => {
  return {
    fill: "none",
    height: ICON_SIZES[size],
    viewBox: "0 0 16 16",
    width: ICON_SIZES[size],
    xmlns: "http://www.w3.org/2000/svg",
    ...svgProps,
  };
};

/*
 * This is kind of working solution for initializing icons for our basic
 * 3 color options.  We may change this to a more flexible solution
 * and remove the color parameter all together in the future.  Some of this
 * discussion can be found in the PR for this change:
 * https://github.com/minervaproject/monorepo/pull/427
 */
export const initializeIconColorProps = ({ color, ...svgProps }: IconColorProps) => {
  return {
    fillClassName: COLOR_TO_FILL_CLASS[color],
    ...initializeSvgIconProps(svgProps),
  };
};
