import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import {
  AnalyticsMessageDatasetType,
  AnalyticsMessageType,
  CourseUserActivityType,
} from "../../worksheets/shared/types/analytics";

import { createReplaceAllReducer } from "store/utils";

type AnalyticsState = {
  courseUserActivities: Record<string, CourseUserActivityType>;
};

const defaultState: AnalyticsState = {
  courseUserActivities: {},
};

const initialState = () => defaultState;

const updateUserActivityInstance = (state: AnalyticsState, activity: CourseUserActivityType) => {
  state.courseUserActivities[activity.userId] = activity;
};

export const AnalyticsStore = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    // TODO: refactor this when we have a better idea of other data we'll be receiving
    updateAnalytics: (state, action: PayloadAction<CourseUserActivityType[]>) => {
      action.payload.forEach((activity) => {
        updateUserActivityInstance(state, activity);
      });
    },
    updateAnalyticsRecord: (state, action: PayloadAction<AnalyticsMessageType>) => {
      if (action.payload.datasetType === AnalyticsMessageDatasetType.COURSE_USER_ACTIVITY) {
        updateUserActivityInstance(state, action.payload);
      }
    },
  },
  extraReducers: createReplaceAllReducer<AnalyticsState>("analytics", initialState),
});

export const analyticsStateActions = AnalyticsStore.actions;
