import { sub } from "date-fns";
import type { ChangeEvent, FC, FormEvent } from "react";
import { useLocalStorage } from "usehooks-ts";

import { AtRiskOptions } from "components/server-types";
import { t } from "i18n/i18n";
import { AccordionTitle } from "mds/components/AccordionTitle";
import { Button } from "mds/components/Button";
import { useAppSelector } from "store/index";
import { selectCurrentUserIsMPAdmin } from "store/selectors";

export type AtRiskOptionsFormProps = {
  onSubmit: (options: AtRiskOptions) => void;
};

// NOTE: These should match up with the values in
// server/material/services/at_risk_service.py
const defaults: AtRiskOptions = {
  start_date: sub(new Date(), { days: 14 }).toISOString().slice(0, 10),
  end_date: new Date().toISOString().slice(0, 10),

  // Effort score constants
  multiple_choice_answer_factor: 50,
  minimum_median_effort_score: 1000,
  minimum_coefficient_of_variation: 0.2,
  high_risk_z_score: 2,
  medium_risk_z_score: 1,

  // Performance score constants
  minimum_outcomes_assessed: 5,
  high_risk_mean_score: 1,
  medium_risk_mean_score: 2,
};

export const AtRiskOptionsForm: FC<AtRiskOptionsFormProps> = ({ onSubmit }) => {
  const [options, setOptions] = useLocalStorage<AtRiskOptions>("at-risk-options-form", {});

  const convertToNumber = (e: ChangeEvent<HTMLInputElement>) => parseFloat(e.target.value);
  const isMpAdmin = useAppSelector(selectCurrentUserIsMPAdmin);

  const setSelectedOptions = (key: keyof AtRiskOptions, value: string | number | boolean) => {
    setOptions({ ...options, [key]: value });
  };

  const getOptionValue = (key: keyof AtRiskOptions) =>
    options[key] !== undefined ? (options[key] as number | null) : (defaults[key] as number);

  const handleSubmit = (e: FormEvent) => {
    Object.keys(options).forEach((key) => {
      if (!["start_date", "end_date"].includes(key) && isNaN(options[key] as number)) {
        delete options[key];
      }
    });

    e.preventDefault();
    onSubmit(options);
  };

  if (!isMpAdmin) {
    return null;
  }

  return (
    <div className="at-risk-options-form mb-6 w-[--page-default-max-width] max-w-[--page-default-max-width]">
      <AccordionTitle iconDirection="end" title={t("course_at_risk_view.options.title")} showBorder>
        <form onSubmit={handleSubmit}>
          <div className="body-xs flex flex-col gap-1">
            <div className="flex items-center gap-2 !px-0 !py-1">
              <div className="flex flex-auto flex-col !px-0 !py-1">
                <label htmlFor="start-date">{t("course_at_risk_view.options.start_date")}</label>
                <input
                  className="text-field"
                  id="start-date"
                  type="date"
                  value={options.start_date || defaults.start_date}
                  onChange={(e) => setSelectedOptions("start_date", e.target.value)}
                />
              </div>

              <div className="flex flex-auto flex-col">
                <label htmlFor="end-date">{t("course_at_risk_view.options.end_date")}</label>
                <input
                  className="text-field"
                  id="end-date"
                  type="date"
                  value={options.end_date || defaults.end_date}
                  onChange={(e) => setSelectedOptions("end_date", e.target.value)}
                />
              </div>
            </div>

            <div className="flex items-center">
              <input
                id="individual-only"
                type="checkbox"
                onChange={(e) => setSelectedOptions("individual_only", !!e.target.checked)}
              />
              <label className="individual-only ml-1" htmlFor="individual-only">
                {t("course_at_risk_view.options.individuals_only")}
              </label>
            </div>

            <h6 className="mb-0 mt-2">{t("course_at_risk_view.effort")}</h6>

            <div className="flex flex-col">
              <label htmlFor="minimum-median-effort-score">
                {t("course_at_risk_view.options.minimum_median_effort_score")}
              </label>
              <input
                className="text-field"
                id="minimum-median-effort-score"
                step="0.01"
                type="number"
                value={getOptionValue("minimum_median_effort_score")}
                onChange={(e) =>
                  setSelectedOptions("minimum_median_effort_score", convertToNumber(e))
                }
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="minimum-coefficient-of-variation">
                {t("course_at_risk_view.options.minimum_coefficient_of_variation")}
              </label>
              <input
                className="text-field"
                id="minimum-coefficient-of-variation"
                step="0.01"
                type="number"
                value={getOptionValue("minimum_coefficient_of_variation")}
                onChange={(e) =>
                  setSelectedOptions("minimum_coefficient_of_variation", convertToNumber(e))
                }
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="multiple-choice-answer-factor">
                {t("course_at_risk_view.options.multiple_choice_answer_factor")}
              </label>
              <input
                className="text-field"
                id="multiple-choice-answer-factor"
                step="0.01"
                type="number"
                value={getOptionValue("multiple_choice_answer_factor")}
                onChange={(e) =>
                  setSelectedOptions("multiple_choice_answer_factor", convertToNumber(e))
                }
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="high-risk-z-score">
                {t("course_at_risk_view.options.high_risk_z_score")}
              </label>
              <input
                className="text-field"
                id="high-risk-z-score"
                step="0.01"
                type="number"
                value={getOptionValue("high_risk_z_score")}
                onChange={(e) => setSelectedOptions("high_risk_z_score", convertToNumber(e))}
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="medium-risk-z-score">
                {t("course_at_risk_view.options.medium_risk_z_score")}
              </label>
              <input
                className="text-field"
                id="medium-risk-z-score"
                step="0.01"
                type="number"
                value={getOptionValue("medium_risk_z_score")}
                onChange={(e) => setSelectedOptions("medium_risk_z_score", convertToNumber(e))}
              />
            </div>

            <h6 className="mb-0 mt-2">{t("course_at_risk_view.performance")}</h6>

            <div className="flex flex-col">
              <label htmlFor="minimum-outcomes-assessed">
                {t("course_at_risk_view.options.minimum_outcomes_assessed")}
              </label>
              <input
                className="text-field"
                id="minimum-outcomes-assessed"
                type="number"
                value={getOptionValue("minimum_outcomes_assessed")}
                onChange={(e) =>
                  setSelectedOptions("minimum_outcomes_assessed", convertToNumber(e))
                }
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="high-risk-mean-score">
                {t("course_at_risk_view.options.high_risk_mean_score")}
              </label>
              <input
                className="text-field"
                id="high-risk-mean-score"
                step="0.01"
                type="number"
                value={getOptionValue("high_risk_mean_score")}
                onChange={(e) => setSelectedOptions("high_risk_mean_score", convertToNumber(e))}
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="medium-risk-mean-score">
                {t("course_at_risk_view.options.medium_risk_mean_score")}
              </label>
              <input
                className="text-field"
                id="medium-risk-mean-score"
                step="0.01"
                type="number"
                value={getOptionValue("medium_risk_mean_score")}
                onChange={(e) => setSelectedOptions("medium_risk_mean_score", convertToNumber(e))}
              />
            </div>

            <div className="mt-2 flex justify-end">
              <Button kind="primary" type="submit">
                {t("course_at_risk_view.submit")}
              </Button>
            </div>
          </div>
        </form>
      </AccordionTitle>
    </div>
  );
};
