import "./DeleteCourseModal.scss";

import { FC, useState } from "react";

import { CourseCodeType, CourseType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { showConfirmationModal } from "mds/components/Modal";
import { TextField } from "mds/components/TextField";
import { storeApi } from "store/index";

export const showDeleteConfirmationModal = ({
  course,
  courseCode,
}: {
  course: CourseType;
  courseCode: CourseCodeType;
}) => {
  const deleteCourseTitle = t("confirm_delete_modal.delete_item", {
    item: t("glossary.course"),
  });

  return showConfirmationModal({
    title: deleteCourseTitle,
    hideCancelConfirmButtons: true,
    labelText: t("confirm_delete_modal.delete_label", { name: course.title }),

    children: (onClose) => (
      <DeleteCourseModal
        course={course}
        courseCode={courseCode}
        deleteCourseTitle={deleteCourseTitle}
        onClose={onClose}
      />
    ),
  });
};

type DeleteCourseModalProps = {
  course: CourseType;
  deleteCourseTitle: string;
  courseCode: CourseCodeType;
  onClose: () => void;
};

// eslint-disable-next-line react-refresh/only-export-components
const DeleteCourseModal: FC<DeleteCourseModalProps> = ({
  course,
  courseCode,
  deleteCourseTitle,
  onClose,
}) => {
  const [disableDelete, setDisableDelete] = useState<boolean>(true);

  const handleCourseCodeConfirmationCheck = (text: string) => {
    setDisableDelete(text !== courseCode.title);
  };

  const handleDelete = () => {
    storeApi.courses.destroy(course.id);
    onClose();
  };

  return (
    <div className="course-delete-modal flex flex-col items-start">
      <div className="course-delete-warning">{t("course_list_panel.delete_course_warning")}</div>

      <label className="mb-2" htmlFor="course-code">
        {t("course_list_panel.course_code_confirm_label")}
      </label>

      <TextField
        id="course-code"
        placeholder={t("course_list_panel.course_code_placeholder")}
        isEditing
        onChange={(event) => handleCourseCodeConfirmationCheck(event.target.value)}
      />

      <div className="mt-6 flex w-full justify-end gap-2">
        <Button kind="secondary" title={t("common.cancel")} onClick={onClose}>
          {t("common.cancel")}
        </Button>

        <Button
          disabled={disableDelete}
          kind="destructive"
          title={deleteCourseTitle}
          onClick={handleDelete}
        >
          {deleteCourseTitle}
        </Button>
      </div>
    </div>
  );
};
