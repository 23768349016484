import {
  createAppSelector,
  selectCourseUsers,
  selectPageGroupUsers,
  selectPageGroups,
} from "./base";
import { NestedPageGroupType, NestedPageGroupUserType, UserWithCourseUserType } from "./types";

import { selectUsersInCurrentCourse } from "store/selectors/course";

export const selectPageGroupsByPage = createAppSelector(
  [
    (state, pageId: string) => pageId,
    selectUsersInCurrentCourse,
    selectPageGroups,
    selectPageGroupUsers,
  ],
  (pageId, users_with_course_activity, page_groups, page_group_users): NestedPageGroupType[] => {
    const usersByCourseUserId: Record<string, UserWithCourseUserType> =
      users_with_course_activity.reduce((acc, user) => {
        const course_user_id = user.course_user_id;
        return { [course_user_id]: user, ...acc };
      }, {});

    const pageGroupsForRequestedPage = page_groups.filter(
      (page_group) => page_group.page_id === pageId,
    );

    return pageGroupsForRequestedPage.map((page_group): NestedPageGroupType => {
      const pageGroupUsersForPageGroup = page_group_users.filter(
        (page_group_user) => page_group_user.page_group_id === page_group.id,
      );
      const nestedPageGroupUsers = pageGroupUsersForPageGroup
        .map((page_group_user): NestedPageGroupUserType => {
          const user = usersByCourseUserId[page_group_user.course_user_id];
          if (!user || user.is_disabled) {
            return null;
          }
          return {
            ...page_group_user,
            user,
          };
        })
        .filter(Boolean);

      return {
        ...page_group,
        page_group_users: nestedPageGroupUsers,
      };
    });
  },
);

export const selectPageGroupForReadyUser = createAppSelector(
  [
    (state, pageId: string) => pageId,
    (state, pageId: string, userId: string) => userId,
    selectPageGroups,
    selectPageGroupUsers,
    selectCourseUsers,
  ],
  (pageId, userId, page_groups, page_group_users, course_users) => {
    if (!pageId || !userId) {
      return null;
    }

    const courseUserIds = course_users
      .filter((course_user) => course_user.user_id === userId)
      .map((course_user) => course_user.id);

    // Get a list of the user's page group ids
    const userGroupIds = page_group_users
      .filter(
        (group_user) => courseUserIds.includes(group_user.course_user_id) && group_user.is_ready,
      )
      .map((group_user) => group_user.page_group_id);

    // Go through the groups and find the group for the given page and user
    return page_groups.find(
      (page_group) => page_group.page_id === pageId && userGroupIds.includes(page_group.id),
    );
  },
);

export const selectReadiedUsersInPageGroup = createAppSelector(
  [
    (state, pageGroupId: string) => pageGroupId,
    selectPageGroupUsers,
    selectCourseUsers,
    (state) => state.users,
  ],
  (pageGroupId, page_group_users, course_users, users) => {
    if (!pageGroupId) {
      return [];
    }

    // Get a list of the course_user's for this group
    const courseUserIds = page_group_users
      .filter((group_user) => group_user.page_group_id === pageGroupId && group_user.is_ready)
      .map((group_user) => group_user.course_user_id);

    // Get a list of all users that match a course_user.user_id
    return course_users
      .filter((course_user) => courseUserIds.includes(course_user.id))
      .map((course_user) => {
        const user = users[course_user.user_id];
        return {
          ...user,
        };
      });
  },
);
