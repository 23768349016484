/**
 * Access IDs are used to gate access to subpaths of a worksheet's collaborative data.
 * They represent both a specific view or version of a worksheet, as well as the
 * necessary permissions to view that version.
 * E.g. an access ID `student-123` represents the view of a worksheet as seen by the student
 * with ID 123, and we assume that the student always has permission to use this access ID.
 * An instructor, however, might choose to view a document as seen by a student, and use their
 * access ID.
 * Access IDs are not stored, but generated on the fly. Collaboration would e.g. generate a list
 * of access IDs that a user is allowed to use, and the client would generate its own, and
 * choose which of them to use. Hence, the logic for this is shared between client and collaboration
 * in this file.
 */
import { DEFAULT_ID_SIZE } from "./utils";

/**
 * All instructors share a single accessId for their collaborative data.
 * It's a single letter to optimize network performance, see types/worksheet.ts for more details.
 */
export const INSTRUCTOR_SHARED_ACCESS_ID = "i";
/**
 * If a worksheet is shared with the entire class ("Class Collaboration"), all students and instructors
 * share this single accessId for their collaborative data.
 * It's a single letter to optimize network performance, see types/worksheet.ts for more details.
 */
export const GLOBAL_SHARED_ACCESS_ID = "g";

const GROUP_PREFIX = "group-";
const STUDENT_PREFIX = "student-";

export const canRollupAccessId = (id: string) => isGroupAccessId(id) || isStudentAccessId(id);

export const isGlobalAccessId = (id: string) => id === GLOBAL_SHARED_ACCESS_ID;
export const isGroupAccessId = (id?: string) => id?.includes(GROUP_PREFIX);
export const isStudentAccessId = (id?: string) => id?.includes(STUDENT_PREFIX);

export const studentAccessIdFromUserId = (id: string) => `${STUDENT_PREFIX}${id}`;
export const groupAccessIdFromGroupId = (id: string) => (id ? `${GROUP_PREFIX}${id}` : null);

export const getStudentIdFromAccessId = (id: string) => {
  if (!isStudentAccessId(id)) {
    throw new Error(`Wrong student access ID: ${id}`);
  }

  const cuid = id.split(STUDENT_PREFIX)[1];
  if (!cuid || cuid.length !== DEFAULT_ID_SIZE) {
    throw new Error(`Invalid student access ID: ${id}`);
  }

  return cuid;
};

export const getGroupIdFromAccessId = (id: string) => {
  if (!isGroupAccessId(id)) {
    throw new Error(`Wrong group access ID: ${id}`);
  }

  const cuid = id.split(GROUP_PREFIX)[1];
  if (!cuid || cuid.length !== DEFAULT_ID_SIZE) {
    throw new Error(`Invalid group access ID: ${id}`);
  }

  return cuid;
};

/**
 * Create a docAccessId from a docId and an accessId. This is passed along to wkm and used to identify, allocate, and
 * manage kernel resources for a specific user or group. We allow one worksheet kernel per accessId per docId which
 * allows us to manage kernel resources on a per-user or per-group basis. This is why the accessId is needed in the
 * docAccessId.
 * @param docId
 * @param accessId
 */
export const createDocAccessId = (docId: string, accessId: string) =>
  docId ? `${docId}-${accessId}` : null;

/**
 * Parse a docAccessId into a docId and an accessId.
 * @param docAccessId
 */
export const parseDocAccessId = (docAccessId: string) => {
  const [docId, ...rest] = docAccessId.split("-");
  const accessId = rest.join("-");
  return { docId, accessId };
};
