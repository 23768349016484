import type { FC } from "react";

import { t } from "i18n/i18n";
import { ChatIcon } from "mds/icons";

interface AssessmentCommentProps {
  comment: string;
  className?: string;
}

export const AssessmentComment: FC<AssessmentCommentProps> = ({ comment, className }) => (
  <div className={className}>
    <span className="flex items-center gap-2">
      <ChatIcon className="h-3 w-3" />
      <h2 className="text-base text-black-tint-20">
        {t("submission_assessment.instructor_comment")}
      </h2>
    </span>
    <div className="mt-1 italic text-black-tint-20">{comment}</div>
  </div>
);
