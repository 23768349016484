import clsx from "clsx";
import { FC, Ref, useEffect, useRef } from "react";

import { CourseCodeType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { IconText } from "mds/components/IconText";
import { ClickEvent, Menu, MenuInstance, MenuItem, MenuType, SubMenu } from "mds/components/Menu";
import { ChevronDownIcon, TextBooksIcon } from "mds/icons";
import { storeApi, useAppSelector } from "store/index";
import { selectCourseCodes } from "store/selectors";

export type CourseCodeMenuProps = {
  selectedCourseCodeId: string;
  onCourseCodeChange: (courseCodeId: string) => void;
  menuType?: MenuType;
};

// This is very similar to TermMenu, consider refactoring to a shared general dropdown component
export const CourseCodeMenu: FC<CourseCodeMenuProps> = ({
  selectedCourseCodeId,
  onCourseCodeChange,
  menuType = "submenu",
}) => {
  const subMenuRef: Ref<MenuInstance> = useRef(null);
  const courseCodes = useAppSelector(selectCourseCodes);
  const courseCodeOptions = [
    { id: "", title: t("import_modal.select_course_code") },
    ...courseCodes,
  ];
  const selectedCourseCode = courseCodeOptions.find(
    (courseCode) => courseCode?.id === selectedCourseCodeId,
  );

  useEffect(() => {
    storeApi.course_codes.list();
  }, []);

  const onItemClick = (e: ClickEvent) => {
    const newCourseCode = e.value as CourseCodeType;
    onCourseCodeChange(newCourseCode.id);
    // This is a supported feature of szhsin/react-menu which allows
    // the menu to stay open even if a menu item is clicked. We use this to keep parent menus open
    // eslint-disable-next-line no-param-reassign
    e.keepOpen = true;
    subMenuRef.current.closeMenu();
  };

  const menuButton = (
    <Button className="w-full gap-1" kind="secondary" size="xs">
      <IconText iconStart={<TextBooksIcon />} text={selectedCourseCode?.title} />
      <ChevronDownIcon />
    </Button>
  );

  const DropdownMenu = courseCodeOptions.map((courseCodeOption) => {
    const selected = selectedCourseCodeId === courseCodeOption.id;
    return (
      <MenuItem
        checked={selected}
        className={clsx({ selected, "icon-white": selected }, "center dropdown-item")}
        key={courseCodeOption.id}
        value={courseCodeOption}
        onClick={onItemClick}
      >
        <IconText iconStart={<TextBooksIcon />} text={courseCodeOption.title} />
      </MenuItem>
    );
  });

  const menuProps = {
    className: "dropdown",
    instanceRef: subMenuRef,
    children: DropdownMenu,
  };

  return menuType === "menu" ? (
    <Menu {...menuProps} direction="bottom" menuButton={menuButton} position="initial" />
  ) : (
    <SubMenu
      {...menuProps}
      direction="bottom"
      label={menuButton}
      openTrigger="clickOnly"
      position="initial"
    />
  );
};
