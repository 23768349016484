import React, { useCallback, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import ReconnectingWebSocket from "reconnecting-websocket";

import { PAGE_ID_QUERY_PARAM } from "components/constants";
import { isProjectorView } from "components/materials/presentation/projector/projector-messaging";
import { store, useAppDispatch, useAppSelector } from "store/index";
import {
  selectCourseVersion,
  selectCurrentCourseId,
  selectIsCourseVersionOutOfSync,
  selectWebsocketCourseId,
} from "store/selectors";
import { viewStateActions } from "store/slices/view";

// TODO: Merge these constants with backend in worksheet constants so they are shared
const TERMINATE_TIMEOUT_MS = 60 * 60000; // 60 minutes
const PROJECTOR_TERMINATE_TIMEOUT_MS = 4 * 60 * 60000; // 4 hours
const PING_INTERVAL_MS = 30000; // 30 seconds
const DEBOUNCE_TIME_MS = 200; // .2 second
const NORMAL_SOCKET_CLOSE_CODE = 1000; // websocket close code for normal closure

interface PingComponentProps {
  socket: ReconnectingWebSocket;
}

// The ping component is responsible for sending pings to the server to keep the connection alive
// and to check if the server is still alive. If the server is not alive, it will attempt to reconnect
// the socket. We will also do an immediate ping if the page loses focus or the user's current page
// changes. If the user is inactive for 30 minutes, we will close the socket. To keep from sending
// too many events to the server, we swallow any events that happen within a specified debounce time.
export const PingComponent: React.FC<PingComponentProps> = ({ socket }) => {
  const lastPingTime = useRef<number>(0);
  const pingInterval = useRef<NodeJS.Timeout | null>(null);
  const closeTimeout = useRef<NodeJS.Timeout | null>(null);

  const serverAliveRef = useRef(false);
  const [searchParams] = useSearchParams();
  const pageId = searchParams.get(PAGE_ID_QUERY_PARAM);

  const isCourseVersionOutOfSync = useAppSelector(selectIsCourseVersionOutOfSync);

  const dispatch = useAppDispatch();

  // Using ref here to keep the component from unmounting when these values change
  const pageIdRef = useRef<string | null>(pageId);

  // Check whether the server is still alive and reconnect if not
  const checkServer = useCallback(() => {
    if (!serverAliveRef.current) {
      socket.reconnect(); // Try to reconnect if the backend server is down
    }

    serverAliveRef.current = false;
  }, [socket]);

  // Send a ping to the server if the socket is open and it's been long enough since the last ping
  const sendPing = useCallback(() => {
    const currentTime = Date.now();
    const websocketCourseId = selectWebsocketCourseId(store.getState());
    const currentCourseId = selectCurrentCourseId(store.getState());

    if (
      socket.readyState === WebSocket.OPEN &&
      currentTime - lastPingTime.current >= DEBOUNCE_TIME_MS
    ) {
      const message = {
        a: "ping",
        pageId: pageIdRef.current,
        active: document.hasFocus() ? "1" : "0",
        course_version:
          websocketCourseId === currentCourseId ? selectCourseVersion(store.getState()) : 0,
      };

      socket.send(JSON.stringify(message));
      lastPingTime.current = currentTime;
      return true;
    }

    return false;
  }, [socket]);

  // When we don't receive messages from the websocket in sequential order,
  // we know that the course version is out of sync, so we send a ping to
  // the server to receive all the messages from the current course version
  // up to the latest one.
  useEffect(() => {
    if (isCourseVersionOutOfSync) {
      sendPing();
      dispatch(viewStateActions.setIsCourseVersionOutOfSync(false));
    }
  }, [dispatch, sendPing, isCourseVersionOutOfSync]);

  // Stop pinging the server
  const stopPingInterval = useCallback(() => {
    clearInterval(pingInterval.current);
    pingInterval.current = null;
  }, []);

  // Stop waiting 30 minutes to close the socket
  const stopCloseTimeout = useCallback(() => {
    clearTimeout(closeTimeout.current);
    closeTimeout.current = null;
  }, []);

  // Start pinging the server every 30 seconds and checking if the server responsds
  const startPingInterval = useCallback(() => {
    // If we started to close due to inactivity, stop closing
    // but only if the tab is active.  This can happen if
    // we immediate leave the tab after receiving focus
    if (document.hasFocus()) {
      stopCloseTimeout();
    }

    // Check that we didn't get disconnected
    if (socket.readyState !== WebSocket.OPEN) {
      socket.reconnect();
    }

    const sentPing = sendPing();

    // Only start the interval if we sent a ping or there isn't one already running
    if (sentPing || !pingInterval.current) {
      // Make sure we clear any existing one
      stopPingInterval();

      pingInterval.current = setInterval(() => {
        checkServer();
        sendPing();
      }, PING_INTERVAL_MS);
    }
  }, [socket, sendPing, stopPingInterval, stopCloseTimeout, checkServer]);

  // Start the wait process to close the socket if the user is inactive
  const startCloseSocketTimeout = useCallback(() => {
    sendPing(); // We want to keep track of when user becomes inactive

    // Don't create another timer if we already have one
    if (closeTimeout.current) {
      return;
    }

    if (socket.readyState === WebSocket.OPEN || socket.readyState === WebSocket.CONNECTING) {
      const terminateSocketTimeoutMS = isProjectorView()
        ? PROJECTOR_TERMINATE_TIMEOUT_MS
        : TERMINATE_TIMEOUT_MS;

      closeTimeout.current = setTimeout(() => {
        if (socket.readyState === WebSocket.OPEN) {
          socket.close(NORMAL_SOCKET_CLOSE_CODE, "User inactive");
        }

        // Closed socket so we can stop pinging
        stopPingInterval();
      }, terminateSocketTimeoutMS);
    }
  }, [socket, stopPingInterval, sendPing]);

  // If the page changes send an immediate ping
  useEffect(() => {
    if (pageIdRef.current !== pageId) {
      pageIdRef.current = pageId;
      sendPing();
    }
  }, [pageId, sendPing]);

  // socket listeners
  useEffect(() => {
    const handlePong = (event: { data: string }) => {
      try {
        const message = JSON.parse(event.data) as { a: "pong" };

        if (message.a === "pong") {
          serverAliveRef.current = true;
        }
      } catch {
        // Other listeners will handle error checking
      }
    };

    socket.addEventListener("open", startPingInterval);
    socket.addEventListener("message", handlePong);
    socket.addEventListener("close", stopPingInterval);

    return () => {
      // remove all intervals and listeners
      stopPingInterval();
      stopCloseTimeout();

      socket.removeEventListener("open", startPingInterval);
      socket.removeEventListener("message", handlePong);
      socket.removeEventListener("close", stopPingInterval);
    };
  }, [socket, stopPingInterval, startPingInterval, stopCloseTimeout]);

  // window listeners
  useEffect(() => {
    window.addEventListener("focus", startPingInterval);
    window.addEventListener("blur", startCloseSocketTimeout);

    return () => {
      window.removeEventListener("focus", startPingInterval);
      window.removeEventListener("blur", startCloseSocketTimeout);
    };
  }, [startPingInterval, startCloseSocketTimeout]);

  return null;
};
