import { Selector } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";

import { useAppSelector } from "./index";

/**
 * The way Redux works, every time the store changes, it will re-execute all selectors, and see if any of them returns a different value than before. If the selector does something like:
 * courseId => state => state.courses[id]
 * then there's no issue, as the object for that course will not be different (unless the course changed). However, if you have a selector that returns a list of objects through a filter/map/reduce operation, like so:
 * courseIds => state => couresIds.map(courseId => state.courses[courseId])
 * the list will get re-built every time the selector gets executed, and the list reference will be different, even though the contents will be the same.
 * This even applies to just calling Object.values(state.courses) in a selector.
 *
 * For such cases, Redux allows you to pass a comparator function as a second argument to useSelector, which will return true/false on whether the selection result actually changed.
 * useListSelector pre-applies a comparator function that checks whether list contents contain the same references, which will fix the detection for most map/filter/reduce results.
 * If we didn't use it, then the components would be re-rendering constantly.
 *
 * See https://www.notion.so/minervaproject/Redux-and-React-patterns-f395d3903b14422ebef19b6f1a91d1a2
 */
export const useListSelector: typeof useAppSelector = (selector: Selector) => {
  return useAppSelector(selector, (left, right) => {
    if (!Array.isArray(left) || !Array.isArray(right)) {
      throw new Error("useListSelector can only be used with arrays");
    }
    if (left.length !== right.length) {
      return false;
    }
    return left.every((item, index) => item === right[index]);
  });
};

/**
 * A hook that wraps a promise-returning function, similar to useEffect, but returns the
 * loading and error states of the promise, re-rendering on changes.
 */
export const usePromiseEffect = <T>(effect: () => Promise<T>, deps: unknown[]) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    const returnPromise = effect();

    // If the effect doesn't return a promise, we wait until the useEffect is reloaded
    // with a different effect that does return a promise.
    if (returnPromise.then) {
      returnPromise
        .then(setData)
        .catch(setError)
        .finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return { isLoading, error, data };
};
