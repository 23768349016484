import "./BackToCourseButton.scss";

import { FC } from "react";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { IconText } from "mds/components/IconText";
import { ChevronLeftIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import { selectCurrentCourse } from "store/selectors";
import { courseHomeUrl } from "utils/urls";

export const BackToCourseButton: FC = () => {
  const currentCourse = useAppSelector(selectCurrentCourse);

  if (!currentCourse?.id) {
    return null;
  }

  return (
    <Button
      className="back-to-course-button icon-white gap-2"
      kind="primary"
      size="s"
      to={courseHomeUrl(currentCourse?.id)}
    >
      <IconText iconStart={<ChevronLeftIcon />} text={t("org.header.back_to_course")} />
    </Button>
  );
};
