import { FC } from "react";

import {
  isGroupPage,
  isSharedClassPage,
  showPageDisabled,
} from "components/materials/page/groups/helpers";
import { PageType } from "components/server-types";
import { EyeNotVisibleIcon, PersonClassIcon, PersonGroupIcon } from "mds/icons";

export const IconFromPage: FC<{ page: PageType }> = ({ page }) => {
  if (showPageDisabled(page)) {
    return <EyeNotVisibleIcon className="icon-black-tint-55" />;
  }

  if (isGroupPage(page)) {
    return <PersonGroupIcon />;
  }

  if (isSharedClassPage(page)) {
    return <PersonClassIcon />;
  }

  return null;
};
