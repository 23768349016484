import "./ContentSidebar.scss";

// eslint-disable-next-line no-restricted-imports
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { FC, Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import type { AIChatContextType } from "components/ai/chat-query";
import { t } from "i18n/i18n";
import { useOnChanged } from "mds/hooks/use-on-changed";
import {
  CircleQuestionIcon,
  ContactsIcon,
  DoubleChevronLeftIcon,
  DoubleChevronRightIcon,
  GearSettingsIcon,
  InstructorNotesIcon,
  PageListIcon,
  PersonClassIcon,
  PersonSingleIcon,
  RobotAIIcon,
} from "mds/icons";
import { forwardRefComponent } from "mds/utils/components";
import { useAppDispatch, useAppSelector } from "store/index";
import { selectIsAssessing, selectIsSidebarOpen } from "store/selectors";
import { localStateActions } from "store/slices/local";
import { trackEvent } from "utils/amplitude";
import { ErrorBoundary } from "worksheets/views/ErrorBoundary";

// eslint-disable-next-line react-refresh/only-export-components
export const SIDEBAR_PANEL_ICONS = {
  Content: PageListIcon,
  Planner: ContactsIcon,
  InstructorNotes: InstructorNotesIcon,
  AiAssistant: RobotAIIcon,
  Settings: GearSettingsIcon,
  Users: PersonClassIcon,
  Profile: PersonSingleIcon,
  HelpCenter: CircleQuestionIcon,
};

// This is only used for the Amplitude logging names.
const TAB_EVENT_NAMES: Record<ContentSidebarTab, string> = {
  Content: "Sidebar - Content",
  Planner: "Sidebar - My planner",
  InstructorNotes: "Sidebar - Notes",
  AiAssistant: "Sidebar - AI assistant",
  Settings: "Sidebar - Settings",
  Users: "Sidebar - Users",
  Profile: "Sidebar - Profile",
  HelpCenter: "Sidebar - Help center",
};

// These log names can be enforced in Typescript later.
const SIDEBAR_EXPAND_LOG_NAME = "Sidebar - Expand";
const SIDEBAR_COLLAPSE_LOG_NAME = "Sidebar - Collapse";

const TabKeys = Object.keys(SIDEBAR_PANEL_ICONS);

export type ContentSidebarTab = keyof typeof SIDEBAR_PANEL_ICONS;

type TabProps = {
  [Tab in ContentSidebarTab as `${Tab}Panel`]?: ReactNode;
};

type ContentSidebarProps = TabProps & {
  context?: AIChatContextType;
};

/**
 * This sidebar component is used with various components to show the standard sidebar
 * nav items.  It's a simple tab component styled for icons.  To add a new tab add
 * a new icon to the SIDEBAR_PANEL_ICONS map.  Consumers of this component will
 * pick which tabs to display by passing in the tab content for the given tab.
 * Ex.
 *  <ContentSidebar
 *    ContentViewPanel={<div>Topic View Content</div>}
 *    AiAssistantPanel={<div>AI Assistant Content</div>}
 *    ...
 *  />
 *  We pass in the `context` for logging purposes only. This corresponds
 *  to the context for the AI Assistant (if its enabled).
 */
export const ContentSidebar: FC<ContentSidebarProps> = ({ context, ...tabs }) => {
  const isSidebarOpen = useAppSelector(selectIsSidebarOpen);
  const dispatch = useAppDispatch();

  const isAssessing = useAppSelector(selectIsAssessing);

  const [selectedTabKey, setSelectedTabKey] = useState<ContentSidebarTab>(undefined);

  const openSidebar = useCallback(() => {
    dispatch(localStateActions.openSidebar());
  }, [dispatch]);

  const closeSidebar = useCallback(() => {
    dispatch(localStateActions.closeSidebar());
  }, [dispatch]);

  const toggleSidebarDisclosure = useCallback(() => {
    dispatch(localStateActions.toggleSidebarDisclosure());
  }, [dispatch]);

  const tabNames = useMemo(
    () =>
      Object.keys(tabs)
        // Remove tabs that weren't specified, or tabs that render `null` (due to conditional
        // rendering, like not having the right permissions).
        .filter((tab) => tab && tabs[tab])
        .map((tab) => tab.replace(/Panel$/, "") as ContentSidebarTab)
        .sort((a, b) => TabKeys.indexOf(a) - TabKeys.indexOf(b)),
    [tabs],
  );

  const selectedTabIndex = tabNames.indexOf(selectedTabKey);

  // When available tabs change, then the previously select tab index might not correspond to
  // the same tab name anymore, so we update it here, or to the first available tab if not found.
  useEffect(() => {
    if (!selectedTabKey) {
      setSelectedTabKey(tabNames[0]);
    } else if (!tabNames.includes(selectedTabKey)) {
      setSelectedTabKey(tabNames[0]);
    }
  }, [tabNames, selectedTabKey]);

  useOnChanged(isAssessing, (nowAsessing, wasAssessing) => {
    if (!wasAssessing && nowAsessing) {
      trackEvent(SIDEBAR_COLLAPSE_LOG_NAME, {
        eventCategory: "General",
        context,
      });
      closeSidebar();
    } else if (wasAssessing && !nowAsessing) {
      trackEvent(SIDEBAR_EXPAND_LOG_NAME, {
        eventCategory: "General",
        context,
      });

      openSidebar();
    }
  });

  const onClick = (index: number) => {
    const shouldOpenSidebar = !isSidebarOpen;
    if (shouldOpenSidebar) {
      openSidebar();
    }

    const newSelectedTabKey = tabNames[index];
    const eventName = TAB_EVENT_NAMES[newSelectedTabKey];
    if (eventName) {
      trackEvent(eventName, {
        eventCategory: "Button press",
        context,
        expanded: shouldOpenSidebar,
      });
    }

    onSelectTab(index);
  };

  const onSelectTab = (index: number) => {
    setSelectedTabKey(tabNames[index]);
  };

  const tabItems = tabNames.map((tab, index) => {
    const IconComponent = forwardRefComponent(SIDEBAR_PANEL_ICONS[tab]);

    return (
      <Tab
        as="div"
        className="tab"
        key={tab}
        title={t(`content_sidebar.title.${tab}`)}
        onClick={() => onClick(index)}
      >
        <IconComponent />
      </Tab>
    );
  });

  let tabPanels = null;
  if (isSidebarOpen) {
    tabPanels = tabNames.map((tab) => {
      const tabKey = `${tab}Panel` as const;
      const PanelComponent = forwardRefComponent(() => tabs[tabKey], {
        className: "w-full h-full content-sidebar-panel",
      });

      return (
        <TabPanel as={Fragment} key={tabKey}>
          <PanelComponent />
        </TabPanel>
      );
    });
  }

  const openStatus = isSidebarOpen ? "open" : "closed";

  return (
    <ErrorBoundary>
      <TabGroup
        aria-label={t("glossary.sidebar")}
        as="aside"
        className="content-sidebar"
        selectedIndex={selectedTabIndex}
        onChange={onSelectTab}
      >
        <TabList className="content-sidebar-buttons-bar">
          <div
            className="tab"
            title={t(`content_sidebar.${openStatus}_action`)}
            onClick={() => {
              trackEvent(isSidebarOpen ? SIDEBAR_COLLAPSE_LOG_NAME : SIDEBAR_EXPAND_LOG_NAME, {
                eventCategory: "Button press",
                context,
              });
              toggleSidebarDisclosure();
            }}
          >
            {isSidebarOpen ? <DoubleChevronLeftIcon /> : <DoubleChevronRightIcon />}
          </div>

          {tabItems}
        </TabList>
        <TabPanels as={Fragment}>{tabPanels}</TabPanels>
      </TabGroup>
    </ErrorBoundary>
  );
};
