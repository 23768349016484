// eslint-disable-next-line no-restricted-imports
import { toast } from "react-toastify";

import { selectAccessIdFromSubmission, serverAssessSubmission } from "../assessment-query";

import { calculatePointsFromOutcomeScores } from "components/materials/page/helpers";
import { AIModelType, PageType } from "components/server-types";
import { RootState } from "store/index";
import {
  NestedAssessmentType,
  NestedPageOutcomeType,
  selectAssessments,
  selectCurrentOrg,
  selectNestedPageOutcomes,
  selectOutcomeAssessments,
  selectPageById,
  selectSubmissions,
} from "store/selectors";
import { toastErrorCatcher, toastSuccessMessage } from "utils/alerts";

type AssessedSubmissionType = {
  assessment: NestedAssessmentType;
  page: PageType;
  pageOutcomes: NestedPageOutcomeType[];
  accessId: string;
};

export type AIAssessmentComparisonType = {
  page_id: string;
  page_title: string;
  access_id: string;
  submission_id: string;
  outcomes: {
    title: string;
    score: number | null;
    ai_score?: number;
    page_outcome_id: string;
    course_outcome_id: string;
  }[];
  comment: string;
  points: number;
  ai_comment?: string;
  ai_error?: string;
  ai_refused?: boolean;
  ai_points?: number;
};

const selectAllAssessedSubmissions = (state: RootState): AssessedSubmissionType[] => {
  const _assessments = selectAssessments(state);
  const _submissions = selectSubmissions(state);
  const _outcomeAssessments = selectOutcomeAssessments(state);

  const assessments: NestedAssessmentType[] = _assessments
    .filter((a) => a.comment !== null)
    .map((a) => {
      const submission = _submissions.find((s) => s.id === a.submission_id);
      const outcomeAssessments = _outcomeAssessments.filter((oa) => oa.assessment_id === a.id);
      return { ...a, submission, outcome_assessments: outcomeAssessments };
    })
    .filter((a) => a.submission && a.submission.official_at && a.outcome_assessments.length > 0);

  const fullAssessments: AssessedSubmissionType[] = assessments.map((a) => {
    const page = selectPageById(state, a.submission.page_id);
    const pageOutcomes = selectNestedPageOutcomes(state, page.id);
    const accessId = selectAccessIdFromSubmission(state, a.submission);

    return { assessment: a, page, pageOutcomes, accessId };
  });

  return fullAssessments;
};

export const generateAiAssessmentsForAllAssessedSubmissions = async (
  state: RootState,
  model?: AIModelType,
): Promise<AIAssessmentComparisonType[]> => {
  try {
    const assessments = selectAllAssessedSubmissions(state);
    const org = selectCurrentOrg(state);

    const toastId = toastSuccessMessage(
      `Generating AI Assessment CSV for ${assessments.length} submissions`,
      {
        isLoading: true,
      },
    );

    let finishedCount = 0;
    const aiAssessments = await Promise.all(
      assessments.map((assessment) =>
        serverAssessSubmission(assessment.assessment.submission, model)
          .then((res) => {
            finishedCount += 1;
            toast.update(toastId, {
              render: `Generating AI Assessment CSV for ${assessments.length} submissions (${finishedCount}/${assessments.length})`,
            });
            return res;
          })
          .catch((e: Error) => {
            finishedCount += 1;
            toast.update(toastId, {
              render: `Generating AI Assessment CSV for ${assessments.length} submissions (${finishedCount}/${assessments.length})`,
            });
            return e;
          }),
      ),
    );

    const errorCount = aiAssessments.filter((a) => a instanceof Error || "error" in a).length;

    toast.dismiss(toastId);

    const items: AIAssessmentComparisonType[] = assessments.map((a, index) => {
      const item: AIAssessmentComparisonType = {
        page_id: a.page.id,
        page_title: a.page.title,
        access_id: a.accessId,
        submission_id: a.assessment.submission.id,
        outcomes: [],
        comment: a.assessment.comment,
        points: a.assessment.points,
      };

      const pageOutcomes = a.pageOutcomes;

      // Human assessment
      item.outcomes = pageOutcomes.map((po) => {
        const oa = a.assessment.outcome_assessments.find((aoa) => aoa.page_outcome_id === po.id);
        return {
          page_outcome_id: po.id,
          course_outcome_id: po.course_outcome_id,
          title: po.outcome.title,
          score: oa?.score || null,
        };
      });

      // AI assessment
      const aiAssessment = aiAssessments[index];
      if (aiAssessment instanceof Error) {
        item.ai_error = aiAssessment.message;
      } else if ("refused" in aiAssessment) {
        item.ai_refused = true;
        item.ai_error = aiAssessment.refused;
      } else {
        item.outcomes.forEach((o, i) => {
          // eslint-disable-next-line no-param-reassign
          o.ai_score = aiAssessment.outcomes.find(
            (ao) => ao.pageOutcomeId === pageOutcomes[i].id,
          )?.score;
        });
        item.ai_comment = aiAssessment.feedback;
        item.ai_points = calculatePointsFromOutcomeScores(
          item.outcomes.map((o) => o.ai_score),
          a.page.max_points,
          org.rubric,
        );
      }

      return item;
    });

    toastSuccessMessage(
      `Successfully assessed ${assessments.length - errorCount}/${assessments.length} submissions`,
    );

    return items;
  } catch (e) {
    console.error(e);
    toastErrorCatcher(e);
  }
};

export const csvObjUrlFromAiComparisonData = (data: AIAssessmentComparisonType[]): string => {
  const head: (string | number)[] = [
    "Page title",
    "User/group access ID",
    "Submission ID",
    "Assessed Outcome 1 title",
    "Assessed Outcome 2 title",
    "Assessed Outcome 3 title",
    "Human Assessed Outcome 1 score",
    "Human Assessed Outcome 2 score",
    "Human Assessed Outcome 3 score",
    "Human Assessed Comment",
    "Human Assessed Points",
    "AI Assessed Outcome 1 score",
    "AI Assessed Outcome 2 score",
    "AI Assessed Outcome 3 score",
    "AI Assessed Comment",
    "AI Assessed Points",
    "AI assessment error",
  ];
  const csv = {
    head,
    rows: data.map((d) => [
      JSON.stringify(d.page_title),
      d.access_id,
      d.submission_id,
      d.outcomes[0]?.title || "",
      d.outcomes[1]?.title || "",
      d.outcomes[2]?.title || "",
      d.outcomes[0]?.score || "",
      d.outcomes[1]?.score || "",
      d.outcomes[2]?.score || "",
      JSON.stringify(d.comment),
      d.points || "",
      d.outcomes[0]?.ai_score || "",
      d.outcomes[1]?.ai_score || "",
      d.outcomes[2]?.ai_score || "",
      d.ai_comment ? JSON.stringify(d.ai_comment) : "",
      d.ai_points || "",
      d.ai_error || "",
    ]),
  };

  const csvString = [csv.head]
    .concat(csv.rows)
    .map((r) => r.join(","))
    .join("\n");
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
  const objUrl = URL.createObjectURL(blob);

  return objUrl;
};
