export type CodeKernelLanguage = "python" | "r";
export type CodeKernelSize = "small";
export type CodeKernelVersion = "1";

export type KernelSpecType = {
  lang?: CodeKernelLanguage;
  size?: CodeKernelSize;
  version?: CodeKernelVersion;
};

export const DEFAULT_CODE_KERNEL_LANG = "python" as CodeKernelLanguage;
export const DEFAULT_CODE_KERNEL_SIZE = "small";
export const DEFAULT_CODE_KERNEL_VERSION = "1";

export const CODE_KERNEL_LANGUAGES = ["python", "r"] as CodeKernelLanguage[];
export const CODE_KERNEL_SIZES = ["small"] as CodeKernelSize[];
export const CODE_KERNEL_VERSIONS = ["1"] as CodeKernelVersion[];

export const CODE_LANG_OPTIONS: { id: CodeKernelLanguage; title: string }[] = [
  { id: "python", title: "Python" },
  { id: "r", title: "R" },
];

export const KERNEL_COMMANDS = {
  ENSURE: "ensure",
  EXECUTE: "execute",
  HEARTBEAT: "heartbeat",
  DESTROY: "destroy",
  REPLY: "reply",
  INTERRUPT: "interrupt",
  RESTART: "restart",
  RUNALL: "runall",
};

export const KERNEL_LANG_TO_LANGUAGE_RUNTIME_NAME: Record<CodeKernelLanguage, string> = {
  python: "python3",
  r: "ir",
};

export const jhubLanguageRuntimeFromKernelSpec = (kernelSpec: KernelSpecType = {}) => {
  const { lang } = kernelSpec;
  const usedLang = lang || DEFAULT_CODE_KERNEL_LANG;
  return KERNEL_LANG_TO_LANGUAGE_RUNTIME_NAME[usedLang];
};

export const jhubProfileFromKernelSpec = (kernelSpec: KernelSpecType = {}) => {
  const { lang, size, version } = kernelSpec;

  const usedLang = lang || DEFAULT_CODE_KERNEL_LANG;
  const usedVersion = version || DEFAULT_CODE_KERNEL_VERSION;
  const usedSize = size || DEFAULT_CODE_KERNEL_SIZE;

  // Note: the resulting string needs to match 1:1 with the jhub profiles defined
  // in minerva-ops/terraform/cluster_setup/production_jupyterhub/helm_values.yaml
  // and staging_jupyterhub equivalent.
  return `monarch-${usedLang}-${usedVersion}-${usedSize}`;
};

// NOTE: Keep this in sync with @jupyterlab/services type definitions on dependency
// updates The type is not directly exported by @jupyterlab/services, and we also
// add our own custom states to the list.
export type KernelStateType =
  | "initial"
  | "unavailable"
  | "launching"
  | "starting"
  | "idle"
  | "busy"
  | "reconnecting"
  | "dead"
  | "unknown"
  | "connected"
  | "autorestarting"
  | "restarting";

export const KERNEL_STATE: Record<string, KernelStateType> = {
  // ----------------
  // Below are custom kernel states not from @jupyterlab/services

  // The kernel is waiting to start.
  INITIAL: "initial",

  // Unprovisioning, culled, or otherwise gone. Needs to be [re-]launched.
  UNAVAILABLE: "unavailable",

  // In the process of being launched by a WorksheetKernelManager
  LAUNCHING: "launching",

  // ----------------
  // Below are from Kernel.Status in @jupyterlab/services/lib/kernel.d.ts

  // The kernel will publish state 'starting' exactly once at process startup
  STARTING: "starting",

  // Available to accept execution requests
  IDLE: "idle",

  // Busy processing an execution request
  BUSY: "busy",

  // Reconnecting (from @jupyterlab.services.Kernel)
  RECONNECTING: "reconnecting",

  // Could not reconnect (from @jupyterlab.services.Kernel)
  DEAD: "dead",

  // From what we can tell, when reconnecting to a kernel that is busy, we can't
  // actually determine the state of the kernel (since it's too busy to respond),
  // so the state will be "unknown" and later "connected", without being "busy" or "idle"
  // (from @jupyterlab.services.Kernel)
  UNKNOWN: "unknown",

  // From what we can tell, this is a different variation of "unknown"
  // that occurrs slightly later in the connection lifecycle (from @jupyterlab.services.Kernel)
  CONNECTED: "connected",

  // Autorestarting (from @jupyterlab.services.Kernel)
  AUTORESTARTING: "autorestarting",

  // TODO: Added after typescript migration, currently unused in backend. Should we react to this state?
  RESTARTING: "restarting",
};

export const mimeTypeDisplayOrder = [
  "application/vnd.jupyter.widget-view+json",
  "application/vnd.vega.v5+json",
  "application/vnd.vega.v4+json",
  "application/vnd.vega.v3+json",
  "application/vnd.vega.v2+json",
  "application/vnd.vegalite.v3+json",
  "application/vnd.vegalite.v2+json",
  "application/vnd.vegalite.v1+json",
  "application/geo+json",
  "application/vnd.plotly.v1+json",
  "text/vnd.plotly.v1+html",
  "application/x-nteract-model-debug+json",
  "application/vnd.dataresource+json",
  "application/vdom.v1+json",
  "application/json",
  "application/javascript",
  "text/html",
  "text/markdown",
  "text/latex",
  "image/svg+xml",
  "image/gif",
  "image/png",
  "image/jpeg",
  "text/plain",
];
