import { determineOrderForNewAppend } from "./collections";

import { serverMoveActivityWithinCourse } from "api/api-server";
import {
  ActivityCategory,
  ActivityPageType,
  ActivityType,
  LessonOrArchiveActivityCategory,
} from "components/server-types";
import { t } from "i18n/i18n";
import { DEFAULT_ORDER_FN } from "mds/hooks/use-ordered";
import { storeApi } from "store/index";
import { ActivityWithPagesType } from "store/selectors";
import { toastMoveOperationCatcher, toastSuccessMessage } from "utils/alerts";

export const moveActivityWithinCourse = (itemId: string, destinationTopicId: string) => {
  const destinationKey = "topic";
  serverMoveActivityWithinCourse(itemId, destinationTopicId)
    .then(() => {
      toastSuccessMessage(
        t("content_view.item_moved", { destinationCategory: t(`glossary.${destinationKey}`) }),
      );
    })
    .catch(toastMoveOperationCatcher(destinationKey));
};

const ORDERED_ACTIVITY_CATEGORIES = Object.values(ActivityCategory);
export const sortActivities = (a: ActivityType, b: ActivityType) => {
  const prioA = ORDERED_ACTIVITY_CATEGORIES.indexOf(a.category);
  const prioB = ORDERED_ACTIVITY_CATEGORIES.indexOf(b.category);

  if (prioA === prioB) {
    return a.order - b.order;
  }

  return prioA - prioB;
};

export const getSortedPages = (activities: ActivityWithPagesType[]) =>
  activities.reduce(
    (acc, activity) => [...acc, ...activity.pages.sort(DEFAULT_ORDER_FN)],
    [] as ActivityPageType[],
  );

export const isArchived = (category: ActivityCategory) => category === ActivityCategory.ARCHIVE;

export const isLesson = (category: ActivityCategory) => category === ActivityCategory.LESSON;

export const isPrep = (category: ActivityCategory) => category === ActivityCategory.PREP;

export const isAdditionalResource = (category: ActivityCategory) =>
  category === ActivityCategory.ADDITIONAL;

/*
 * The following hook returns functions for maintaining activities in the store.
 */
export const useActivityActions = () => {
  const createActivity = (
    category: ActivityWithPagesType["category"],
    activities: ActivityType[],
    topicId: string,
  ) => {
    if (topicId === null) {
      // TODO: raise an error?
      return;
    }

    return storeApi.activities.create({
      title: t("glossary.activity"),
      topic_id: topicId,
      order: determineOrderForNewAppend(activities.filter((a) => a.category === category)),
      category,
      estimated_time: undefined,
    });
  };

  const saveActivity = (activity: Partial<ActivityWithPagesType> & { id: string }) =>
    storeApi.activities.partial_update(activity);

  // Temporary confirm dialog until we get the final design. Returns true if the user confirms wanting to archive.
  const confirmArchiveActivity = () => {
    return window.confirm(
      "Are you sure you want to move this activity to the archive? It will become unavailable to students, even if already released.",
    );
  };

  const unpublishAllPagesForActivity = (activity: ActivityWithPagesType) =>
    Promise.all(
      activity.pages.map((page) =>
        // TODO: We currently don't have a way of doing this in bulk. Also, it might be better
        // to handle this on the backend.
        storeApi.pages.partial_update({
          id: page.id,
          activity_id: activity.id,
          released_at: null,
        }),
      ),
    );

  // Changes the activity type between `lesson` or `archive`, presenting alerts if necessary. Not for use
  // with drag and drop, which modifies the activity locally during the drag operation.
  const onActivityTypeChanged = async (
    activity: ActivityWithPagesType,
    activities: ActivityWithPagesType[],
    newCategory: LessonOrArchiveActivityCategory,
  ) => {
    if (activity.category === newCategory) {
      return false;
    }

    const activityPages = activity.pages.filter((r) => r.activity_id === activity.id);
    // Pages are unpublished on archive. We confirm with the user before unpublishing.
    if (isArchived(newCategory) && activityPages.some((r) => r.released_at)) {
      if (!confirmArchiveActivity()) {
        return;
      }
      await unpublishAllPagesForActivity(activity);
    }

    const newCategoryCollection = activities.filter((a) => a.category === newCategory);
    const newOrder = determineOrderForNewAppend(newCategoryCollection);

    return saveActivity({ id: activity.id, category: newCategory, order: newOrder });
  };

  const deleteActivity = (activity: ActivityType) => storeApi.activities.destroy(activity.id);

  const archiveActivity = (activity: ActivityWithPagesType, activities: ActivityWithPagesType[]) =>
    onActivityTypeChanged(activity, activities, ActivityCategory.ARCHIVE);

  const unarchiveActivity = (
    activity: ActivityWithPagesType,
    activities: ActivityWithPagesType[],
  ) => onActivityTypeChanged(activity, activities, ActivityCategory.LESSON);

  const dropActivity = async (activity: ActivityWithPagesType) => {
    // Pages are unpublished on archive. We confirm with the user before unpublishing.
    if (isArchived(activity.category) && activity.pages.some((r) => r.released_at)) {
      const confirm = confirmArchiveActivity();
      if (!confirm) {
        return;
      }

      await unpublishAllPagesForActivity(activity);
    }

    return saveActivity({ id: activity.id, category: activity.category, order: activity.order });
  };

  return {
    createActivity,
    saveActivity,
    deleteActivity,
    dropActivity,
    archiveActivity,
    unarchiveActivity,
  };
};
