import clsx from "clsx";
import type { FC } from "react";
import { useBoolean } from "usehooks-ts";

import { groupAccessIdFromGroupId } from "../../../../worksheets/shared/access-id";
import { accessIdHasData } from "../../../../worksheets/shared/doc-helpers";

import { isUserOnline, userActivityIcons } from "components/materials/course/helpers";
import {
  DraggableCourseUserType,
  DraggablePageGroupUserType,
  GroupChoiceType,
} from "components/materials/page/groups/helpers";
import { PageType } from "components/server-types";
import { Trans, t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { Card } from "mds/components/Card";
import { ConfirmDeletionDialog } from "mds/components/ConfirmDeletionDialog";
import { DragAndDrop, DraggableItemType } from "mds/components/DragAndDrop";
import { ListItem } from "mds/components/ListItem";
import { LongFormPencilIcon, PersonSingleIcon, TrashDeleteIcon } from "mds/icons";
import { useWorksheet } from "providers/ShareDBDoc";
import { storeApi, useAppSelector } from "store/index";
import {
  NestedPageGroupType,
  determineActivityStatus,
  selectActivityStatus,
} from "store/selectors";
import { getUsername } from "utils/user-utils";

interface PageGroupCardProps {
  pageGroup: NestedPageGroupType;
  number: number;
  totalPageGroups: number;
  page: PageType;
  moveCourseUserLocally: (
    item: DraggableItemType,
    toIndex: number,
    dropContainerId: string,
  ) => void;
  onDropCourseUser: (
    droppedCoursedUser: DraggableCourseUserType,
    newIndex: number,
    sourceType: "course_user" | "page_group_user",
  ) => void;
  canDeleteGroup: boolean;
}
export const PageGroupCard: FC<PageGroupCardProps> = ({
  pageGroup,
  number,
  totalPageGroups,
  page,
  moveCourseUserLocally,
  onDropCourseUser,
  canDeleteGroup,
}) => {
  const { doc } = useWorksheet(page?.worksheet_id);
  const userActivity = useAppSelector(selectActivityStatus);
  const draggablePageGroupUsers: DraggablePageGroupUserType[] = pageGroup.page_group_users.map(
    (pageGroupUser, index) => ({
      ...pageGroupUser,
      order: index,
    }),
  );

  const isDeleteGroupEnabled = totalPageGroups > 2;

  const {
    value: confirmationDialogIsOpen,
    setTrue: openConfirmationDialog,
    setFalse: closeConfirmationDialog,
  } = useBoolean(false);

  const confirmDeleteGroup = () => {
    const hasData = accessIdHasData(doc, groupAccessIdFromGroupId(pageGroup.id));
    if (hasData) {
      openConfirmationDialog();
    } else {
      storeApi.page_groups.destroy(pageGroup.id);
    }
  };

  return (
    <>
      <DragAndDrop
        className="w-full"
        dragItemKind="course_user"
        dropContainerId={pageGroup.id}
        droppable
        onDraggedItemHover={moveCourseUserLocally}
      >
        <Card className="flex w-full flex-col flex-wrap">
          <div className="mb-1 flex w-full justify-between">
            <div className="h3 ml-2 truncate">{t("common.group_title", { number })}</div>
            <div className="flex gap-1">
              {draggablePageGroupUsers.length === 0 ? null : (
                <div className="h6 flex items-center justify-center gap-1 px-2 text-black-tint-40">
                  <PersonSingleIcon />
                  {draggablePageGroupUsers.length}
                </div>
              )}
              {canDeleteGroup && (
                <Button
                  disabled={!isDeleteGroupEnabled}
                  kind="secondary"
                  size="xs"
                  title={
                    isDeleteGroupEnabled
                      ? t("group_configuration_card.tooltip.delete_group")
                      : undefined
                  }
                  iconOnly
                  onClick={confirmDeleteGroup}
                >
                  <TrashDeleteIcon />
                </Button>
              )}
            </div>
          </div>
          {draggablePageGroupUsers.length === 0 ? (
            <div className="body-s ml-2 mt-1 text-black-tint-55">
              <Trans
                i18nKey={`group_configuration_card.empty_group_tip.${
                  page.grouping_category as GroupChoiceType
                }`}
                t={t}
              >
                Click <b>Distribute</b> or drag students here
              </Trans>
            </div>
          ) : (
            draggablePageGroupUsers.map((pageGroupUser) => {
              const userActivityStatus = userActivity[pageGroupUser.user.id];
              const userActivePageId = userActivityStatus?.pageId;
              const userActivityType = determineActivityStatus(userActivityStatus);

              /**
               * To be an active page group user (i.e. for the student to have a blue
               * draft icon next to their name), they must:
               * 1. Be a "ready" user (have clicked the Join -> Start from student self-select,
               *  or have been placed in a group by the instructor)
               * 2. Be online
               * 3. Be on the group page */
              const isPageGroupUserActive =
                pageGroupUser.is_ready &&
                userActivePageId === pageGroup.page_id &&
                isUserOnline(userActivityType);

              return (
                <DragAndDrop
                  className="w-full"
                  dragItemKind="course_user"
                  dropContainerId="page_group_user"
                  item={pageGroupUser}
                  key={pageGroupUser.id}
                  draggable
                  onDropItem={onDropCourseUser}
                >
                  <ListItem
                    className={clsx(
                      "flex w-full items-center justify-between",
                      isPageGroupUserActive && "bg-blue-tint-95",
                    )}
                    key={pageGroupUser.id}
                    title={
                      isPageGroupUserActive
                        ? t("group_configuration_card.tooltip.user_is_ready")
                        : t("group_configuration_card.tooltip.user_is_not_ready")
                    }
                    grabbable
                  >
                    <div className="flex items-center gap-1.5">
                      {userActivityIcons[userActivityType]}
                      <div className="min-w-12 truncate">{getUsername(pageGroupUser.user)}</div>
                    </div>
                    {isPageGroupUserActive ? <LongFormPencilIcon className="icon-blue" /> : null}
                  </ListItem>
                </DragAndDrop>
              );
            })
          )}
        </Card>
      </DragAndDrop>
      <ConfirmDeletionDialog
        glossaryKey="group"
        instanceName={t("common.group_title", { number })}
        open={confirmationDialogIsOpen}
        onClose={closeConfirmationDialog}
        onDelete={() => storeApi.page_groups.destroy(pageGroup.id)}
      >
        <div className="mt-4 border-l-2 border-red pl-4 font-bold">
          {t("confirm_delete_modal.student_content_warning", {
            name: t("common.group_title", { number }),
          })}
        </div>
      </ConfirmDeletionDialog>
    </>
  );
};
