import "./ProjectorView.scss";

import clsx from "clsx";
import { FC, useCallback, useEffect, useRef } from "react";

import { hasEditableBlocks } from "../../../../worksheets/shared/doc-helpers";

import { ErrorScreen, LoadingScreen } from "components/LoadingScreen";
import { PageDetailView } from "components/materials/page/PageDetailView";
import { PageOverviewHeader } from "components/materials/page/PageOverviewHeader";
import { FeatureProjectorView } from "components/materials/presentation/featuring/FeatureProjectorView";
import { SCALING_FACTOR } from "components/materials/presentation/projector/ProjectorScroller";
import {
  PROJECTOR_ID_KEY,
  PageScrollMessage,
  RequestFrameMessage,
  SyncClassSessionMessage,
  WindowFrameMessage,
  WindowMessages,
  addProjectorMessageListener,
  confirmCorrectProjectorView,
  deregisterProjectorView,
  getScrollTopFromMessage,
  removeProjectorMessageListener,
  sendMessageToInstructorView,
} from "components/materials/presentation/projector/projector-messaging";
import { PageOutcomeScore } from "components/outcomes/PageOutcomeScore";
import { t } from "i18n/i18n";
import { useFetchGroupData } from "mds/hooks/use-fetch-group-data";
import { useWorksheet } from "providers/ShareDBDoc";
import { storeApi, useAppSelector } from "store/index";
import { selectCanAuthorCourse, selectNestedPageOutcomes, selectPageById } from "store/selectors";
import { FeatureStatus, usePresentation } from "utils/presentation";

// Featuring seems to be about 8 pixels off.
const WAIT_FOR_RENDER_INTERVAL_MS = 500;
const RESIZE_EVENT_DELAY_MS = 600;

// This page is meant to work in conjunction with the ProjectorScroller component.  It opens as a new
// tab and sends back the scroll frame and the total height of the page to the parent window.  The
// parent window then resizes the div container to match the size of this frame (taking into account
// the 20% less real estate on the div container vs this frame) so that the content in both scrolling
// windows can match perfectly.  This allows for instructors to see exactly the content they are scrolling
// into view without having to look at the projector screen.
export const ProjectorView: FC = () => {
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const presentationDivRef = useRef<HTMLDivElement>(null);
  const featureDivRef = useRef<HTMLDivElement>(null);
  const lastScrollMessageRef = useRef<PageScrollMessage | null>(null);

  const {
    presentedSessionId,
    presentedPageId: pageId,
    presentingRollup,
    presentedAccessId,
    hasProjectorView,
    featureStatus,
  } = usePresentation();
  const page = useAppSelector((s) => selectPageById(s, pageId));
  const pageOutcomes = useAppSelector((s) => selectNestedPageOutcomes(s, pageId));
  const isFeaturing = featureStatus !== FeatureStatus.NONE;

  const { doc } = useWorksheet(page?.worksheet_id);
  const isStaticContent = !doc || !hasEditableBlocks(doc);

  const groupingCategory = page?.grouping_category;

  const getScrollerDiv = useCallback(
    () => (isFeaturing ? featureDivRef.current : presentationDivRef.current),
    [isFeaturing],
  );

  const updateScrollPosition = useCallback(() => {
    const scrollerDiv = getScrollerDiv();
    if (!scrollerDiv || !lastScrollMessageRef.current || !hasProjectorView) {
      return;
    }

    if (lastScrollMessageRef.current.scrollY === 0 || !lastScrollMessageRef.current.blockMarkerId) {
      scrollerDiv.scroll({
        left: lastScrollMessageRef.current.scrollX * SCALING_FACTOR,
        top: lastScrollMessageRef.current.scrollY * SCALING_FACTOR,
        behavior: "smooth",
      });

      return;
    }

    const scrollY = getScrollTopFromMessage(scrollerDiv, lastScrollMessageRef.current);
    const scrollX = lastScrollMessageRef.current.scrollX * SCALING_FACTOR;

    // Scroll our window to match what the instructor is scrolling
    scrollerDiv.scroll({
      left: scrollX,
      top: scrollY,
      behavior: "smooth",
    });
  }, [hasProjectorView, getScrollerDiv]);

  const postFrameMessage = useCallback(() => {
    const scrollerDiv = getScrollerDiv();
    if (hasProjectorView && scrollerDiv) {
      const message: WindowFrameMessage = {
        type: WindowMessages.WINDOW_FRAME,
        viewport: {
          width: scrollerDiv.offsetWidth,
          height: scrollerDiv.offsetHeight,
        },
      };

      sendMessageToInstructorView(message);
      updateScrollPosition();
    }
  }, [hasProjectorView, updateScrollPosition, getScrollerDiv]);

  // Load the page groups, page course users depending on the type of page
  useFetchGroupData(pageId, groupingCategory);

  // channel listeners
  useEffect(() => {
    const handleProjectorMessage = (
      message: MessageEvent<PageScrollMessage | RequestFrameMessage | SyncClassSessionMessage>,
    ) => {
      const scrollerDiv = getScrollerDiv();
      if (!scrollerDiv) {
        // We can't process anything until we have a ref to the scroller div
        return;
      }

      // TODO: Switch statement might be more apropriate here
      if (message.data.type === WindowMessages.PAGE_SCROLL) {
        lastScrollMessageRef.current = message.data;
        updateScrollPosition();
      } else if (message.data.type === WindowMessages.REQUEST_FRAME) {
        postFrameMessage();
      } else if (message.data.type === WindowMessages.SESSION_SYNC) {
        // If the instructor is presenting, we need to make sure we are in sync with them
        storeApi.class_sessions.retrieve(presentedSessionId, { skipToast: true, skipCache: true });
      }
    };

    addProjectorMessageListener(handleProjectorMessage);

    return () => {
      removeProjectorMessageListener(handleProjectorMessage);
    };
  }, [postFrameMessage, updateScrollPosition, presentedSessionId, getScrollerDiv]);

  // Reset the projecting state when the window closes
  useEffect(() => {
    const closeWindow = () => {
      if (hasProjectorView) {
        deregisterProjectorView();
      }
    };

    window.addEventListener("beforeunload", closeWindow);

    return () => {
      window.removeEventListener("beforeunload", closeWindow);
    };
  }, [hasProjectorView]);

  // Pass the window pane dimensions to the caller window
  useEffect(() => {
    let interval: NodeJS.Timeout;
    let mutationObserver: MutationObserver;

    if (!hasProjectorView) {
      return;
    }

    const delayedPostFrameMessage = () => {
      setTimeout(postFrameMessage, RESIZE_EVENT_DELAY_MS);
    };

    const handleProjectorChange = (event: StorageEvent) => {
      if (event.key === PROJECTOR_ID_KEY) {
        confirmCorrectProjectorView();
      }
    };

    // Unfortunately, we can't rely on the page being fully rendered when we start projecting
    // So we need to wait until the page is fully rendered before we can send the correct
    // dimensions to the parent window
    const checkDiv = () => {
      const scrollerDiv = getScrollerDiv();
      if (scrollerDiv) {
        postFrameMessage();

        clearInterval(interval);
        mutationObserver = new MutationObserver(delayedPostFrameMessage);

        mutationObserver.observe(scrollerDiv, {
          childList: true,
          subtree: true,
          attributes: true,
        });
        window.addEventListener("resize", delayedPostFrameMessage);
      }
    };

    // Set up an interval to check periodically
    confirmCorrectProjectorView();
    window.addEventListener("storage", handleProjectorChange);
    interval = setInterval(checkDiv, WAIT_FOR_RENDER_INTERVAL_MS); // check every half second

    // Clean up interval on component unmount
    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", delayedPostFrameMessage);
      window.removeEventListener("storage", handleProjectorChange);
      mutationObserver?.disconnect();
    };
  }, [hasProjectorView, isFeaturing, pageId, postFrameMessage, getScrollerDiv]);

  if (canAuthorCourse === false) {
    return (
      <div className="h-screen w-screen">
        <ErrorScreen className="h-full" text={t("course.404")} />
      </div>
    );
  }

  // If we are not in projectorView by default we show a loading screen
  if (!hasProjectorView || !page) {
    return <LoadingScreen text={t("presentation.presentation_ended")} />;
  }

  return (
    <>
      {isFeaturing && (
        <FeatureProjectorView isStaticContent={isStaticContent} ref={featureDivRef} />
      )}
      <main
        className={clsx("projector-view h-full bg-white", {
          "hide-during-featuring": isFeaturing,
        })}
      >
        <div className="projector-view-header flex w-full items-center justify-center">
          <div className="flex w-full justify-between">
            <h2 className="my-1 ml-4 min-w-0 truncate text-black-tint-20">{page.title}</h2>

            <div className="items-top flex h-fit gap-2 px-6 text-blue-tint-20">
              {pageOutcomes.map((pageOutcome) => (
                <PageOutcomeScore key={pageOutcome.id} pageOutcome={pageOutcome} />
              ))}
            </div>
          </div>
        </div>

        <div
          className="projector-view-main w-full"
          id="projector-scroller"
          ref={presentationDivRef}
        >
          <div className="projector-view-main-content flex flex-col items-center">
            <PageOverviewHeader isStaticContent={isStaticContent} page={page} readOnly />

            <PageDetailView
              isStaticContent={isStaticContent}
              page={page}
              selectedAccessId={presentedAccessId}
              selectedTabVariant={presentingRollup ? "responses" : null}
              isProjecting
            />
          </div>
        </div>
      </main>
    </>
  );
};
