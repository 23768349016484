import "./PdfAsset.scss";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

import clsx from "clsx";
import { throttle } from "lodash";
import { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Doc } from "sharedb/lib/client";

import type { WorksheetType } from "../../../worksheets/shared/types/worksheet";

import { PageToolbar } from "./PageToolbar";
import { ZoomToolbar } from "./ZoomToolbar";

import { BlockNavigationButtons } from "components/materials/presentation/featuring/BlockNavigationButtons";
import {
  SCALING_FACTOR,
  SCROLL_DEBOUNCER_MS,
} from "components/materials/presentation/projector/ProjectorScroller";
import {
  AssetChangeMessage,
  WindowMessages,
  addProjectorMessageListener,
  removeProjectorMessageListener,
  sendMessageToProjectorView,
} from "components/materials/presentation/projector/projector-messaging";
import { FeatureStatus, usePresentation } from "utils/presentation";

/*
This line of code is needed to make `react-pdf` work, but for some reason
it causes the unit tests to fail with the following error:
  TypeError: 'toString' called on an object that is not a valid instance of Location.

Based on my (David) investigations, it has something to do with the value of
`import.meta.url` in the test environment; trying to construct a `URL` object
using that value always results in the error above. I don't understand why.

Skipping this line of code in the test environment is the best solution I can find,
even though I don't like it.

See:
- https://vite.dev/guide/env-and-mode
- https://github.com/wojtekmaj/react-pdf?tab=readme-ov-file#configure-pdfjs-worker
*/
if (import.meta.env.NODE_ENV !== "test") {
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.js",
    import.meta.url,
  ).toString();
}

type PdfAssetProps = {
  url: string;
  className?: string;
  footer?: "WIDE" | "NARROW";
  doc?: Doc<WorksheetType>;
  shouldResize?: boolean;
  shouldSyncWithInstructorView?: boolean;
  shouldShowBlockNavigation?: boolean;
};

const DEFAULT_ZOOM = 100;
const DEFAULT_PAGE_NUMBER = 1;
const PDF_LOAD_OFFSET_MS = 500;

const PdfAsset: FC<PdfAssetProps> = ({
  url,
  className,
  footer,
  doc,
  shouldResize,
  shouldSyncWithInstructorView = false,
  shouldShowBlockNavigation = false,
}) => {
  const scrollerRef = useRef<HTMLDivElement>(null);
  const documentContainerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(600);
  const [totalPages, setTotalPages] = useState<number>(1);

  const { isPresenting, featureStatus } = usePresentation();
  const isFeaturing = featureStatus !== FeatureStatus.NONE;

  const [pageNumber, setPageNumber] = useState<number>(DEFAULT_PAGE_NUMBER);
  const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM);

  const isInstructorView = isPresenting && isFeaturing && !shouldSyncWithInstructorView;

  // When we area in projector view we transform all pixels to SCALING_FACTOR
  // react-pdf sees the increased size and improperly sets the height based on unscaled pixels
  // which makes it much larger than it should be.  We set it back by removing the scaling
  // factor from the height.
  const resetDimensions = () => {
    if (documentContainerRef.current) {
      const canvas = documentContainerRef.current.querySelector("canvas");
      const transformedWidth = parseInt(canvas?.style?.width, 10);
      const transformedHeight = parseInt(canvas?.style?.height, 10);

      canvas.style.width = `${transformedWidth / SCALING_FACTOR}px`;
      canvas.style.height = `${transformedHeight / SCALING_FACTOR}px`;
    }
  };

  const onDocumentLoad = ({ numPages }: { numPages: number }) => {
    setTotalPages(numPages);
    if (shouldResize) {
      setTimeout(resetDimensions, PDF_LOAD_OFFSET_MS);
    }
  };

  useLayoutEffect(() => {
    if (documentContainerRef.current) {
      setWidth(documentContainerRef.current.getBoundingClientRect().width);
    }
  }, [documentContainerRef]);

  const sendAssetChange = (assetAttributes: Partial<Omit<AssetChangeMessage, "type">>) => {
    const message: AssetChangeMessage = {
      type: WindowMessages.ASSET_CHANGE,
      ...assetAttributes,
    };

    sendMessageToProjectorView(message);
  };

  const onPageChange = (newPageNumber: number) => {
    setPageNumber(newPageNumber);

    if (isInstructorView) {
      sendAssetChange({ pageNumber: newPageNumber });
    }
  };

  const onZoomChange = (newZoom: number) => {
    setZoom(newZoom);

    if (isInstructorView) {
      sendAssetChange({ zoom: newZoom });
    }
  };

  const postScrollMessage = useCallback(() => {
    if (scrollerRef.current) {
      // Send the scroll position to the projector with the scaling factor applied
      sendAssetChange({
        scrollX: scrollerRef.current.scrollLeft,
        scrollY: scrollerRef.current.scrollTop,
      });
    }
  }, []);

  // linter seems to be confused by the throttle function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(
    throttle(postScrollMessage, SCROLL_DEBOUNCER_MS, {
      trailing: true,
      leading: true,
    }),
    [postScrollMessage],
  );

  // Here we add a listener to keep track of our scrolling of the pdf.  The
  // listener than broadcast the scroll position to the projector view.
  // We remove the listener if this tab is no longer the controlling tab for
  // the presentation.
  useEffect(() => {
    if (!scrollerRef.current) {
      return;
    }

    const scrollingDiv = scrollerRef.current;

    if (isInstructorView) {
      scrollingDiv.addEventListener("scroll", handleScroll);
    } else {
      scrollingDiv.removeEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollingDiv) {
        scrollingDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isInstructorView, handleScroll]);

  // Here we listen for any messages being broadcast to the projector view
  // for asset changes (page number, zoom, scroll position, etc).  This
  // should only effect the projector.
  useEffect(() => {
    const handleAssetChangeMessage = (message: MessageEvent<AssetChangeMessage>) => {
      if (message.data.type === WindowMessages.ASSET_CHANGE) {
        const {
          pageNumber: presentedPageNumber,
          zoom: presentedZoom,
          scrollX,
          scrollY,
        } = message.data;

        if (shouldSyncWithInstructorView) {
          if (presentedPageNumber) {
            setPageNumber(presentedPageNumber);
          }

          if (presentedZoom) {
            setZoom(presentedZoom);
          }

          if (scrollX !== undefined && scrollerRef.current) {
            scrollerRef.current.scroll({
              left: scrollX,
              top: scrollY,
              behavior: "smooth",
            });
          }
        }
      }
    };

    if (shouldSyncWithInstructorView) {
      addProjectorMessageListener(handleAssetChangeMessage);
    }

    return () => {
      removeProjectorMessageListener(handleAssetChangeMessage);
    };
  }, [shouldSyncWithInstructorView]);

  return (
    <div className="pdf-asset-container" ref={scrollerRef}>
      <div className={clsx(className, "pdf-asset")} ref={documentContainerRef}>
        <Document externalLinkTarget="_blank" file={url} onLoadSuccess={onDocumentLoad}>
          <Page pageNumber={pageNumber} scale={zoom / 100} width={width} />
        </Document>
      </div>

      {footer && (
        <div
          className={clsx("enlarged-preview__footer flex items-stretch gap-2", {
            "justify-between": !shouldShowBlockNavigation,
          })}
        >
          <PageToolbar
            narrow={footer === "NARROW"}
            pageNumber={pageNumber}
            setPageNumber={onPageChange}
            totalPages={totalPages}
          />

          {shouldShowBlockNavigation && <BlockNavigationButtons doc={doc} />}

          <ZoomToolbar narrow={footer === "NARROW"} setZoom={onZoomChange} zoom={zoom} />
        </div>
      )}
    </div>
  );
};

// For Lazy loading it's easier to load a default component
// eslint-disable-next-line import/no-default-export
export default PdfAsset;
