import { Avatar } from "mds/components/Avatar";
import { useAppSelector } from "store/index";
import { selectUserById } from "store/selectors";
import { getUserInitials } from "utils/user-utils";
import { getUserColor } from "worksheets/helpers/colors";

export const CourseUserAvatar = ({ userId }: { userId: string }) => {
  const user = useAppSelector((s) => selectUserById(s, userId));
  const color = getUserColor(userId);
  const initials = getUserInitials(user);

  return <Avatar className="text-white" style={{ backgroundColor: color }} text={initials} />;
};
