import "./DoneProgress.css";

import { type FC, useContext } from "react";
import type { Doc } from "sharedb/lib/client";

import {
  getGroupIdFromAccessId,
  getStudentIdFromAccessId,
  isGroupAccessId,
  isStudentAccessId,
} from "../../shared/access-id";
import { DATA_PATH } from "../../shared/constants";
import type { WorksheetType } from "../../shared/types/worksheet";

import { isGroupPage } from "components/materials/page/groups/helpers";
import { t } from "i18n/i18n";
import { DocViewContext } from "providers/DocViewProvider";
import { useAppSelector } from "store/index";
import {
  selectPageById,
  selectPageGroupsByPage,
  selectUsersInCurrentCourse,
} from "store/selectors";
import { useListSelector } from "store/store-hooks";

interface DoneProgressProps {
  doc: Doc<WorksheetType>;
}

export const DoneProgress: FC<DoneProgressProps> = ({ doc }) => {
  const { pageId } = useContext(DocViewContext);
  const page = useAppSelector((s) => selectPageById(s, pageId));
  const users = useListSelector(selectUsersInCurrentCourse);
  const students = users.filter((user) => user.role === "student");
  const groups = useAppSelector((s) => selectPageGroupsByPage(s, pageId));
  const isGrouped = isGroupPage(page);
  const docData = doc.data[DATA_PATH];

  // After a page is due, editing is either disabled (making mark done irrelevant),
  // or late submissions are allowed, in which case the submit button is shown instead
  // and the mark done button is hidden.
  if (page.due_at && new Date(page.due_at) < new Date()) {
    return null;
  }

  const accessIds = Object.keys(docData);
  const doneCount = accessIds.filter((accessId) => {
    if (isGrouped && !isGroupAccessId(accessId)) {
      return false;
    }
    if (!isGrouped && !isStudentAccessId(accessId)) {
      return false;
    }
    // This protects against the rare case where users made progress in one group, but then
    // got switched to a new group and the old group was deleted.
    if (isGrouped && !groups.find((group) => group.id === getGroupIdFromAccessId(accessId))) {
      return false;
    }
    // This shouldn't happen, but just in case.
    if (!isGrouped && !students.find((user) => user.id === getStudentIdFromAccessId(accessId))) {
      return false;
    }
    const value = docData[accessId];
    return value?.done;
  }).length;

  const totalCount = isGrouped ? groups.length : students.length;

  return (
    <div className="page-block-width" title={t("presentation_aggregates.tooltip")}>
      <label className="self-start">
        <progress className="done-progress" max={totalCount} value={doneCount} />
        <div className="body-xs text-black-tint-40">
          {t(
            isGrouped
              ? "presentation_aggregates.done_groups"
              : "presentation_aggregates.done_students",
            { doneCount, count: totalCount },
          )}
        </div>
      </label>
    </div>
  );
};
