// This is a light wrapper around the native waffle JS API.

// General //

export const isWaffleFlagEnabled = (flag: string): boolean => {
  if (!window.waffle?.flag_is_active) {
    return false;
  }
  return window.waffle.flag_is_active(flag);
};

export const isWaffleSampleEnabled = (sample: string): boolean => {
  if (!window.waffle?.sample_is_active) {
    return false;
  }
  return window.waffle.sample_is_active(sample);
};

export const isWaffleSwitchEnabled = (switchName: string): boolean => {
  if (!window.waffle?.switch_is_active) {
    return false;
  }
  return window.waffle.switch_is_active(switchName);
};

// Specific flags //

export const isAVEnabled = (): boolean => {
  return isWaffleFlagEnabled("av_enabled");
};
