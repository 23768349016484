import { FC, memo } from "react";

import { CourseUsersSidebarPanel } from "../course/CourseUsersSidebarPanel";
import { SettingsSidebarPanel } from "../course/SettingsSidebarPanel";
import { ContentSidebar } from "../sidebar/ContentSidebar";
import { InstructorNotesSidebarPanel } from "../sidebar/InstructorNotesSidebarPanel";
import { UserProfileSidebarPanel } from "../sidebar/UserProfileSidebarPanel";

import { TopicSidebarPanel } from "./TopicSidebarPanel";

import { AIChatPanel } from "components/ai/AIChatPanel";
import { HelpCenterSidebarPanel } from "components/materials/sidebar/HelpCenterSidebarPanel";
import { useUserCourseSettings } from "utils/course";

export type TopicSidebarProps = {
  topicId: string;
};

export const TopicSidebar: FC<TopicSidebarProps> = memo(
  ({ topicId }) => {
    const { canAuthorCourse, isMPAdmin, canSeeAIPanel } = useUserCourseSettings();

    return (
      <ContentSidebar
        AiAssistantPanel={canSeeAIPanel ? <AIChatPanel context="topic" /> : undefined}
        ContentPanel={<TopicSidebarPanel topicId={topicId} />}
        context="topic"
        HelpCenterPanel={<HelpCenterSidebarPanel />}
        InstructorNotesPanel={canAuthorCourse && <InstructorNotesSidebarPanel />}
        ProfilePanel={<UserProfileSidebarPanel />}
        SettingsPanel={canAuthorCourse && <SettingsSidebarPanel />}
        UsersPanel={(isMPAdmin || canAuthorCourse) && <CourseUsersSidebarPanel />}
      />
    );
  },
  (prevProps, nextProps) => prevProps.topicId === nextProps.topicId,
);

TopicSidebar.displayName = "TopicSidebar";
