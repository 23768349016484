import { FC } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useBoolean } from "usehooks-ts";

import { useFetchAssessmentStatus } from "../course/assessment/assessment-data";

import { TopicFooter } from "./TopicFooter";
import { TopicHeader } from "./TopicHeader";
import { AdditionalResourceList } from "./activity/AdditionalResourceList";
import { ArchiveActivitiesList } from "./activity/ArchiveActivityList";
import { LessonActivityList } from "./activity/LessonActivityList";
import { PrepWorkActivityCard } from "./activity/PrepWorkActivityCard";
import { TopicDevTools } from "./activity/TopicDevTools";

import { DEVTOOL_QUERY_PARAM } from "components/constants";
import { ActivityCategory } from "components/server-types";
import { t } from "i18n/i18n";
import { ConfirmDeletionDialog } from "mds/components/ConfirmDeletionDialog";
import { HorizontalDivider } from "mds/components/HorizontalDivider";
import { useAppSelector } from "store/index";
import {
  selectIsEditModeEnabled,
  selectPagesForTopic,
  selectSortedTopicActivities,
  selectTopicById,
} from "store/selectors";
import { isAdditionalResource, isLesson, isPrep } from "utils/activity";
import { useTopicActions } from "utils/topic";
import { courseHomeUrl } from "utils/urls";

export type TopicSidebarPanelProps = {
  topicId: string;
};

// This panel displays each type of activity and its child pages for a given topic.
// It is also for selecting the individual page/worksheet to be displayed in main page.
export const TopicSidebarPanel: FC<TopicSidebarPanelProps> = ({ topicId }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const topic = useAppSelector((s) => selectTopicById(s, topicId));
  const topicPages = useAppSelector((s) => selectPagesForTopic(s, topic.id));
  const topicHasPages = topicPages.length > 0;
  const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);

  const activities = useAppSelector((s) => selectSortedTopicActivities(s, topicId));
  const { deleteTopic } = useTopicActions();

  const {
    value: confirmationDialogIsOpen,
    setTrue: openConfirmationDialog,
    setFalse: closeConfirmationDialog,
  } = useBoolean(false);

  const deleteTopicAndRedirect = async () => {
    await deleteTopic(topic);
    navigate(courseHomeUrl(topic.course_id));
  };

  // There should always be one prep category activity per topic.
  const prepWorkActivity = activities.find((activity) => isPrep(activity.category));

  const additionalResourceActivity = activities.find((activity) =>
    isAdditionalResource(activity.category),
  );

  const hasLessonPages = activities.some(
    (activity) => isLesson(activity.category) && activity.pages.length > 0,
  );

  const archiveHasPages = activities.some(
    (activity) => activity.category === ActivityCategory.ARCHIVE && activity.pages.length > 0,
  );

  const showDeveloperTools = searchParams.get(DEVTOOL_QUERY_PARAM) === "1";

  const confirmDeleteTopic = () => {
    if (topicHasPages) {
      openConfirmationDialog();
    } else {
      deleteTopicAndRedirect();
    }
  };

  useFetchAssessmentStatus(topicPages);

  return (
    <>
      <div className="content-sidebar-header">
        <TopicHeader topic={topic} onDelete={confirmDeleteTopic} />

        <HorizontalDivider className="-ml-2 mt-2" />
      </div>

      {topicHasPages || isEditModeEnabled ? (
        <div className="content-sidebar-main pt-2">
          {prepWorkActivity && (isEditModeEnabled || prepWorkActivity?.pages?.length > 0) && (
            <>
              <PrepWorkActivityCard activities={activities} prepWorkActivity={prepWorkActivity} />

              <HorizontalDivider className="mb-2 mt-5" />
            </>
          )}

          {(hasLessonPages || isEditModeEnabled) && (
            <>
              <LessonActivityList activities={activities} topicId={topicId} />

              <HorizontalDivider className="mb-2 mt-5" />
            </>
          )}

          {additionalResourceActivity &&
            (isEditModeEnabled || additionalResourceActivity?.pages?.length > 0) && (
              <>
                <AdditionalResourceList
                  activities={activities}
                  additionalResourceActivity={additionalResourceActivity}
                />

                <HorizontalDivider className="mb-2 mt-5" />
              </>
            )}

          {(archiveHasPages || isEditModeEnabled) && (
            <ArchiveActivitiesList activities={activities} topicId={topicId} />
          )}

          {showDeveloperTools && <TopicDevTools activities={activities} />}
        </div>
      ) : (
        <div className="content-sidebar-main ml-2 mt-2.5">
          <div className="body-s text-black-tint-40">{t("topic_sidebar.no_pages")}</div>
        </div>
      )}

      <HorizontalDivider className="mb-2" />

      <TopicFooter topic={topic} />

      <ConfirmDeletionDialog
        glossaryKey="topic"
        instanceName={topic.title}
        open={confirmationDialogIsOpen}
        onClose={closeConfirmationDialog}
        onDelete={deleteTopicAndRedirect}
      />
    </>
  );
};
