import clsx from "clsx";
import { useEffect } from "react";
import { Outlet } from "react-router";

import { MobileRoutingOverlay } from "components/MobileRoutingOverlay";
import { OrgSidebar } from "components/materials/org/OrgSidebar";
import { OrgHeader } from "components/materials/org/header/OrgHeader";
import { TermsView } from "components/materials/org/terms/TermsView";
import { useIsLgOrLarger } from "mds/hooks/use-responsive";
import { storeApi, useAppSelector } from "store/index";
import { selectCanAuthorOrg, selectIsSidebarOpen } from "store/selectors";
import { ErrorBoundary } from "worksheets/views/ErrorBoundary";

export const Layout = () => {
  const canAuthorOrg = useAppSelector(selectCanAuthorOrg);
  const isSidebarOpen = useAppSelector(selectIsSidebarOpen);
  const isLargeScreen = useIsLgOrLarger();

  useEffect(() => {
    storeApi.courses.list();
    storeApi.org_users.list();
  }, []);

  if (!canAuthorOrg) {
    // For internal dogfooding, we have student accounts that have access to multiple orgs and so
    // provide the org-switching header for people to manually navigate to this page and switch orgs.
    // The org header will still only show org options to switch to an org the user has access to.
    return (
      <>
        <OrgHeader />
        <div className="h-full">
          <TermsView />
        </div>
      </>
    );
  }

  return (
    <div className="flex h-full flex-col">
      {/* TODO: Org mobile header */}
      <OrgHeader />
      <div
        className={clsx(
          "flex grow overflow-y-auto",
          isLargeScreen
            ? {
                "sidebar-open": isSidebarOpen,
                "sidebar-closed": !isSidebarOpen,
              }
            : {},
        )}
      >
        {isLargeScreen && <OrgSidebar />}

        <main className="relative h-full grow overflow-x-hidden">
          <ErrorBoundary>
            <MobileRoutingOverlay />
            <Outlet />
          </ErrorBoundary>
        </main>
      </div>
    </div>
  );
};

export default Layout;
