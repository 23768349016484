import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const PersonSingleIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M14.0664 12.6443C14.0666 12.4941 14.0306 12.3461 13.9613 12.2129C13.8921 12.0797 13.7918 11.9651 13.6688 11.8789C11.9851 10.7885 10.004 10.2484 7.99977 10.3333C5.99555 10.2484 4.01449 10.7885 2.33071 11.8789C2.20776 11.9651 2.10742 12.0797 2.0382 12.2129C1.96899 12.3461 1.93293 12.4941 1.93311 12.6443V15H14.0664V12.6443Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M7.99958 7.53333C9.80371 7.53333 11.2662 6.0708 11.2662 4.26667C11.2662 2.46254 9.80371 1 7.99958 1C6.19545 1 4.73291 2.46254 4.73291 4.26667C4.73291 6.0708 6.19545 7.53333 7.99958 7.53333Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
