import { ErrorMessage, type FieldValuesFromFieldErrors } from "@hookform/error-message";
import type { FieldErrors, FieldName, Message } from "react-hook-form";

import { Banner } from "mds/components/Banner";

export type ErrorMessageBannerProps<TFieldErrors extends FieldErrors> = {
  errors?: TFieldErrors;
  name: FieldName<FieldValuesFromFieldErrors<TFieldErrors>>;
  className?: string;
  message?: Message;
};

export const ErrorMessageBanner = <TFieldErrors extends FieldErrors>({
  className,
  ...rest
}: ErrorMessageBannerProps<TFieldErrors>) => (
  <ErrorMessage
    render={({ message }) => (
      <Banner className={className} kind="error">
        {message}
      </Banner>
    )}
    {...rest}
  />
);
