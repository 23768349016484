import "./AssetField.scss";

import clsx from "clsx";
import { get } from "lodash";
import { FC, Suspense, lazy, useCallback, useContext, useEffect, useRef } from "react";
// eslint-disable-next-line no-restricted-imports
import { Provider as ReduxStoreProvider } from "react-redux";
import { BrowserRouter, useSearchParams } from "react-router-dom";
import { Doc } from "sharedb/lib/client";

import { deleteBlockFromWorksheet, getEditableBlocks } from "../../shared/doc-helpers";
import { AssetFieldType, PathType, WorksheetType } from "../../shared/types/worksheet";

import VideoAsset from "./VideoAsset";

import { FOLLOWING_QUERY_PARAM } from "components/constants";
import { BlockNavigationButtons } from "components/materials/presentation/featuring/BlockNavigationButtons";
import { FeatureButton } from "components/materials/presentation/featuring/FeatureButton";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { Menu, MenuItem, MenuRadioGroup, SubMenu } from "mds/components/Menu";
import { showConfirmationModal } from "mds/components/Modal";
import { useHoverable } from "mds/hooks/use-hoverable";
import {
  ArrowDownIcon,
  CrossRemoveIcon,
  DotMenuVerticalIcon,
  ExpandFullScreenIcon,
  EyeVisibleIcon,
  LoadingDotsIcon,
  TrashDeleteIcon,
} from "mds/icons";
import { PaperClipLinkIcon } from "mds/icons/PaperClipLink";
import { DocViewContext } from "providers/DocViewProvider";
import { store, useAppDispatch, useAppSelector } from "store/index";
import { selectAssetModalIsOpen, selectIsEditModeEnabled } from "store/selectors";
import { viewStateActions } from "store/slices/view";
import { toastLocalizedOperationCatcher } from "utils/alerts";
import { FeatureStatus, stopFeaturing, usePresentation } from "utils/presentation";
import { isImageFile, isPDFFile, isVideoFile } from "worksheets/helpers/files";

const PdfAsset = lazy(() => import("./PdfAsset"));

type AssetModalProps = {
  shortName: string;
  name: string;
  size: string;
  doc: Doc<WorksheetType>;
  blockId: string;
  url: string;
  canAuthorPage: boolean;
  isImage: boolean;
  isVideo: boolean;
  isPdf: boolean;
  isFeaturingAsset: boolean;
  shouldSyncWithInstructorView?: boolean;
  onClose: (manualClose?: boolean) => void;
  onDelete: () => void;
};

const AssetModal: FC<AssetModalProps> = ({
  shortName,
  name,
  size,
  doc,
  url,
  blockId,
  canAuthorPage,
  isImage,
  isVideo,
  isPdf,
  isFeaturingAsset,
  shouldSyncWithInstructorView = false,
  onClose,
  onDelete,
}) => {
  const { featureStatus, isFollowing, isPresenting, presentingRollup, presentedBlockId } =
    usePresentation();
  const isFeaturing = featureStatus !== FeatureStatus.NONE;
  const isPresentingAsset = presentedBlockId === blockId;
  const blocks = presentingRollup ? getEditableBlocks(doc) : doc?.data?.b;
  const assetModalIsOpen = useAppSelector(selectAssetModalIsOpen);
  const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);
  const showBlockNavigation = isPresenting && blocks?.length > 1 && !!presentedBlockId;

  useEffect(() => {
    if (
      ((isFeaturingAsset && (!isFeaturing || !isPresentingAsset)) ||
        (isFollowing && !isFeaturingAsset && isFeaturing)) &&
      assetModalIsOpen
    ) {
      onClose(false);
    }
    // We don't want to close again if another asset modal suddenly opens
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFeaturingAsset, isPresentingAsset, isFeaturing, isFollowing, onClose]);

  return (
    <>
      <div className="modal-preview__toolbar flex w-full items-center justify-between">
        <div className="ml-1 flex items-center gap-2">
          <h4 className="title h4 m-0 flex items-center gap-2">
            <PaperClipLinkIcon />
            <span className="break-words pr-2">
              <span className="mr-2">{shortName}</span>
              <span className="body-s whitespace-nowrap text-black-tint-70">{`(${size})`}</span>
            </span>
          </h4>
        </div>

        <div className="flex items-center gap-2">
          {/* TODO: For projector view, we are temporarily not allow closing or any other manipulation of the asset */}
          {!shouldSyncWithInstructorView && (
            <>
              {(!canAuthorPage || !isEditModeEnabled) && (
                <Button download={name} kind="secondary" size="s" to={url} iconOnly>
                  <ArrowDownIcon />
                </Button>
              )}

              <Button kind="secondary" size="s" iconOnly onClick={() => onClose()}>
                <CrossRemoveIcon />
              </Button>

              {canAuthorPage && isEditModeEnabled && (
                <Menu
                  menuButton={
                    <Button kind="tertiary">
                      <DotMenuVerticalIcon />
                    </Button>
                  }
                >
                  <MenuItem download={name} href={url}>
                    <ArrowDownIcon />
                    {t("common.download")}
                  </MenuItem>

                  <MenuItem kind="destructive" onClick={onDelete}>
                    <TrashDeleteIcon /> {t("common.delete")}
                  </MenuItem>
                </Menu>
              )}
            </>
          )}
        </div>
      </div>

      {isImage && (
        <div className="flex justify-center">
          <img alt={name} className="h-auto w-auto max-w-full object-contain" src={url} />
        </div>
      )}

      {isVideo && (
        <VideoAsset
          className="enlarged-preview__media"
          id={blockId}
          shouldSyncWithInstructorView={shouldSyncWithInstructorView}
          url={url}
        />
      )}

      {isPdf && (
        <Suspense fallback={<LoadingDotsIcon />}>
          <PdfAsset
            className="enlarged-preview__media"
            doc={doc}
            footer="WIDE"
            shouldResize={shouldSyncWithInstructorView}
            shouldShowBlockNavigation={showBlockNavigation}
            shouldSyncWithInstructorView={shouldSyncWithInstructorView}
            url={url}
          />
        </Suspense>
      )}

      {showBlockNavigation && !isPdf && (
        <div className="asset-navigation">
          <BlockNavigationButtons doc={doc} />
        </div>
      )}
    </>
  );
};

interface AssetFieldProps extends AssetFieldType {
  path: PathType;
  doc: Doc<WorksheetType>;
  blockId: string;
}

export const AssetField: FC<AssetFieldProps> = ({ path, doc, blockId }) => {
  const { name, size, url, fileType, view } = get(doc?.data, path) as AssetFieldType;
  const { isHovering, hoverParentProps } = useHoverable();
  const { canAuthorPage, isProjecting, isFeaturing } = useContext(DocViewContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  const { presentedBlockId, isFollowing, isPresentingUser, presentedSessionId, featureStatus } =
    usePresentation();
  const dispatch = useAppDispatch();
  const assetModalIsOpen = useAppSelector(selectAssetModalIsOpen);
  const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);
  const isProjectingAndNotFeaturing = featureStatus === FeatureStatus.NONE && isProjecting;

  const isInline = view === "inline";
  const isCondensed = view === "condensed";

  const isImage = isImageFile(fileType);
  const isVideo = isVideoFile(fileType);
  const isPdf = isPDFFile(fileType);
  const hasThumbnail = (isImage || isVideo) && isCondensed;
  const canPreview = isImage || isVideo || isPdf;
  const shortName = name.length > 50 ? `${name.slice(0, 50)}...` : name;
  const isPresentingAsset = presentedBlockId === blockId;
  const presentingAssetRef = useRef(false);
  presentingAssetRef.current = isPresentingAsset;

  const onDelete = useCallback(() => {
    const blockIndex = Number(path[1]);
    deleteBlockFromWorksheet(doc, blockIndex);
  }, [doc, path]);

  const onViewChange = (newView: AssetFieldType["view"]) => {
    doc.submitOp([{ p: path.concat("view"), oi: newView, od: view }]);
  };

  const onShowModal = useCallback(
    async (isFeaturingAsset = false) => {
      // Because we need to set some router state and we can't just use a router provider
      // below as it would have a separate context.  We need to set the search params
      // in function outside the modal.  Hence we create the function here as we also
      // need the onClose function to close the modal.  We also use the manualClose
      // here to differentiate between the user closing the modal and the modal closing
      // automatically as the state of the presentation changes.
      const onFeatureClose = isFeaturingAsset
        ? (manualClose = true) => {
            dispatch(viewStateActions.closeAssetModal());

            if (isPresentingUser && manualClose) {
              stopFeaturing(presentedSessionId);

              // if we're still presenting the asset then the user is the one chose
              // to exit out.  Need to use ref here because this function gets cached
            } else if (presentingAssetRef.current && manualClose) {
              setSearchParams((searchParams) => {
                searchParams.delete(FOLLOWING_QUERY_PARAM);
                return searchParams;
              });
            }
          }
        : undefined;

      await showConfirmationModal({
        hideCloseButton: true,
        className: clsx("modal-preview min-h-[250px] bg-white", {
          "modal-preview--featuring": isFeaturingAsset,
          "flex flex-col items-center": isImage,
        }),
        onConfirm: null,
        hideCancelConfirmButtons: true,
        fullScreen: true,
        // TODO: For projector view, we are temporarily not allow closing
        allowManualClose: !isProjecting,
        onClose: onFeatureClose,
        // This modal will not be getting updated so this nesting issue is not a problem
        // eslint-disable-next-line react/no-unstable-nested-components
        children: (onClose: () => void) => {
          // It's important to note that the BrowserRouter is necessary for the AssetModal
          // but that it should be read-only as changes made to search params will not
          // affect the router provider in the main app (and visa versa) hence why we do the
          // setting of the searchParams above.  We need to use the providers here because
          // promise modals are rendered outside of the main app.
          return (
            <ReduxStoreProvider store={store}>
              <BrowserRouter>
                <AssetModal
                  blockId={blockId}
                  canAuthorPage={canAuthorPage}
                  doc={doc}
                  isFeaturingAsset={isFeaturingAsset}
                  isImage={isImage}
                  isPdf={isPdf}
                  isVideo={isVideo}
                  name={name}
                  shortName={shortName}
                  shouldSyncWithInstructorView={isProjecting}
                  size={size}
                  url={url}
                  onClose={onClose}
                  onDelete={onDelete}
                />
              </BrowserRouter>
            </ReduxStoreProvider>
          );
        },
      });

      return null;
    },
    // TODO: look into simplifying this list
    [
      canAuthorPage,
      isImage,
      isPdf,
      doc,
      blockId,
      dispatch,
      isVideo,
      name,
      shortName,
      size,
      url,
      onDelete,
      isProjecting,
      setSearchParams,
      isPresentingUser,
      presentedSessionId,
    ],
  );

  // TODO: this should be refactored.  We shouldn't be opening this modal here.
  // The asset gets dismounted and re-mounted when certain fields change causing
  // this to open 2 modals.  For now we are keeping track of the modal state to
  // prevent this from happening.
  useEffect(() => {
    if (
      isPresentingAsset &&
      (isPresentingUser || isFollowing) &&
      !isFeaturing &&
      !assetModalIsOpen
    ) {
      dispatch(viewStateActions.openAssetModal());
      onShowModal(true);
    }
  }, [
    isPresentingAsset,
    onShowModal,
    isPresentingUser,
    isFollowing,
    isFeaturing,
    dispatch,
    assetModalIsOpen,
  ]);

  if (canPreview && isInline) {
    return (
      <div className="w-full">
        <div className="inline-preview" {...hoverParentProps} role="toolbar">
          <div
            className={clsx(
              "inline-preview__toolbar flex items-center justify-between",
              isHovering && "--hover",
            )}
          >
            <div className="ml-1 flex items-center gap-2">
              <h4 className="title h4 m-0 flex items-center gap-2">
                {!hasThumbnail && <PaperClipLinkIcon className="mt-1" />}

                <span className="break-words pr-2">
                  <span className="mr-2">{shortName}</span>
                  <span className="body-s whitespace-nowrap text-black-tint-70">{`(${size})`}</span>
                </span>
              </h4>
            </div>

            <div className="flex items-center gap-2">
              {/* TODO: For projector view, we are temporarily not allow closing or any other manipulation of the asset */}
              {!isProjecting && (
                <>
                  {(!canAuthorPage || !isEditModeEnabled) && (
                    <Button download={name} kind="secondary" size="s" to={url} iconOnly>
                      <ArrowDownIcon />
                    </Button>
                  )}

                  <ViewButton
                    blockId={blockId}
                    onView={() => {
                      onShowModal().catch(toastLocalizedOperationCatcher("preview_failure"));
                    }}
                  />

                  {canAuthorPage && isEditModeEnabled && (
                    <Menu
                      menuButton={
                        <Button kind="tertiary">
                          <DotMenuVerticalIcon />
                        </Button>
                      }
                    >
                      <MenuItem download={name} href={url}>
                        <ArrowDownIcon /> {t("common.download")}
                      </MenuItem>
                      <ViewOptionsMenu value={view} onRadioChange={onViewChange} />
                      <MenuItem kind="destructive" onClick={onDelete}>
                        <TrashDeleteIcon /> {t("common.delete")}
                      </MenuItem>
                    </Menu>
                  )}
                </>
              )}
            </div>
          </div>

          {isImage && (
            <div className="flex justify-center">
              <img alt={name} className="h-auto w-auto max-w-full object-contain" src={url} />
            </div>
          )}

          {isVideo && (
            <VideoAsset
              className="inline-preview__media"
              id={blockId}
              shouldSyncWithInstructorView={isProjectingAndNotFeaturing}
              url={url}
            />
          )}

          {isPdf && (
            <Suspense fallback={<LoadingDotsIcon />}>
              <PdfAsset
                className="inline-preview__media overflow-hidden"
                shouldResize={isProjecting}
                url={url}
              />
            </Suspense>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={clsx("flex-start flex w-full", hasThumbnail && "-with-thumbnail")}>
      {hasThumbnail && (
        <div className="preview-container">
          {isImage && (
            <div className="flex justify-center">
              <img alt={name} className="h-auto w-auto max-w-full object-contain" src={url} />
            </div>
          )}

          {isVideo && <video className="preview-container__media" src={url} controls />}
        </div>
      )}

      <div className="body-s container w-full gap-2">
        <div className="flex items-center justify-between">
          <h4 className="title h4 m-0 flex items-center gap-2">
            {!hasThumbnail && <PaperClipLinkIcon className="mt-1" />}

            <span className="break-words pr-2">
              <span className="mr-2">{shortName}</span>
              <span className="body-s whitespace-nowrap text-black-tint-70">{`(${size})`}</span>
            </span>
          </h4>

          <div className="flex items-center gap-2">
            {canPreview ? (
              <>
                <Button download={name} kind="secondary" size="s" to={url} iconOnly>
                  <ArrowDownIcon />
                </Button>

                <ViewButton
                  blockId={blockId}
                  onView={() => {
                    onShowModal().catch(toastLocalizedOperationCatcher("preview_failure"));
                  }}
                />
              </>
            ) : (
              <Button className="gap-2" download={name} kind="secondary" size="s" to={url}>
                <ArrowDownIcon />
                {t("common.download")}
              </Button>
            )}

            {(canAuthorPage || canPreview) && (
              <Menu
                menuButton={
                  <Button kind="tertiary" size="m">
                    <DotMenuVerticalIcon />
                  </Button>
                }
              >
                {canPreview && (
                  <MenuItem className="gap-2" download={name} href={url}>
                    <ArrowDownIcon />
                    {t("common.download")}
                  </MenuItem>
                )}

                {canPreview && canAuthorPage && (
                  <ViewOptionsMenu value={view} onRadioChange={onViewChange} />
                )}

                {canAuthorPage && (
                  <MenuItem kind="destructive" onClick={onDelete}>
                    <TrashDeleteIcon /> {t("common.delete")}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

type ViewButtonProps = {
  onView: () => void;
  blockId: string;
};

export const ViewButton: FC<ViewButtonProps> = ({ onView, blockId }) => {
  const { showResponses, canAuthorPage, pageId, accessId } = useContext(DocViewContext);
  const { isPresenting } = usePresentation();
  const canFeature = isPresenting && canAuthorPage;

  if (canFeature) {
    return (
      <FeatureButton
        accessId={accessId}
        blockId={blockId}
        isRollup={Boolean(showResponses)}
        pageId={pageId}
      />
    );
  }

  return (
    <Button className="gap-2" kind="secondary" size="s" onClick={onView}>
      <ExpandFullScreenIcon />
      {t("asset_field.view")}
    </Button>
  );
};

export const ViewOptionsMenu = ({
  value,
  onRadioChange,
}: {
  value: AssetFieldType["view"];
  onRadioChange: (newView: AssetFieldType["view"]) => void;
}) => {
  return (
    <SubMenu
      className="gap-2"
      label={
        <>
          {/* TODO: Replace with different "eye" icon once it is added to the icon list in Figma */}
          <EyeVisibleIcon />
          {/* TODO: Is there a better way to localize SubMenu? We probably want Display As Condensed Preview or
           *   Display As Inline Preview rather than three substrings: Display As, Inline Preview, and
           *   Condensed Preview */}
          {t("asset_field.display_as")}
        </>
      }
      withArrow
    >
      <MenuRadioGroup
        value={value}
        onRadioChange={(e) => onRadioChange(e.value as AssetFieldType["view"])}
      >
        <MenuItem type="radio" value="inline">
          {t("asset_field.inline_preview")}
        </MenuItem>
        <MenuItem type="radio" value="condensed">
          {t("asset_field.condensed_preview")}
        </MenuItem>
      </MenuRadioGroup>
    </SubMenu>
  );
};
