import clsx from "clsx";
import { type FC, useCallback } from "react";

import { AddOutcomePlusButton } from "components/materials/page/outcomes/AddOutcomePlusButton";
import { PageOutcomeRubricPanel } from "components/materials/page/outcomes/PageOutcomeRubricPanel";
import { FeatureButton } from "components/materials/presentation/featuring/FeatureButton";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { useAppDispatch, useAppSelector } from "store/index";
import {
  selectAssessmentsByPageId,
  selectIsAssessing,
  selectNestedPageOutcomes,
} from "store/selectors";
import { viewStateActions } from "store/slices/view";
import { FeatureStatus, usePresentation } from "utils/presentation";

interface PageOutcomeRubricSidebarProps {
  pageId: string;
  className?: string;
}

export const PageOutcomeRubricSidebar: FC<PageOutcomeRubricSidebarProps> = ({
  pageId,
  className,
}) => {
  const isAssessing = useAppSelector(selectIsAssessing);
  const assessments = useAppSelector((s) => selectAssessmentsByPageId(s, pageId));
  const pageOutcomes = useAppSelector((s) => selectNestedPageOutcomes(s, pageId));

  const dispatch = useAppDispatch();
  const closeOutcomesSidebar = useCallback(
    () => dispatch(viewStateActions.closeOutcomesSidebar()),
    [dispatch],
  );

  const { isPresenting, featureStatus } = usePresentation();

  const canFeature = isPresenting && featureStatus === FeatureStatus.NONE && !!pageOutcomes.length;

  if (isAssessing) return null;

  return (
    <div
      className={clsx(
        "flex min-h-full w-full flex-col gap-2 rounded-2xl border border-black-tint-90 px-5 py-4",
        className,
      )}
    >
      <div className="flex flex-row flex-wrap items-center justify-between">
        <h3 className="m-0">{t("glossary.outcomes")}</h3>
        <div className="flex flex-wrap gap-2">
          <AddOutcomePlusButton
            isDisabled={assessments.length > 0}
            pageId={pageId}
            title={
              assessments.length > 0
                ? t("tooltip.outcome_config_has_assessments")
                : t("common.add.outcome")
            }
          />

          {canFeature && <FeatureButton pageId={pageId} size="m" isPresentingOutcomes />}

          <Button kind="secondary" onClick={closeOutcomesSidebar}>
            {t("page_outcome_rubric_card.hide_rubric")}
          </Button>
        </div>
      </div>

      <PageOutcomeRubricPanel pageId={pageId} />
    </div>
  );
};
