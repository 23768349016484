import "./BlockNavigationButtons.scss";

import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { Doc } from "sharedb/lib/client";

import { BLOCKS_PATH } from "../../../../worksheets/shared/constants";
import { getEditableBlocks } from "../../../../worksheets/shared/doc-helpers";
import { WorksheetType } from "../../../../worksheets/shared/types/worksheet";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { ChevronLeftIcon, ChevronRightIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import { selectPageById } from "store/selectors";
import { featurePage, usePresentation } from "utils/presentation";
import { getNavigationValues } from "utils/urls";

export type BlockNavigationButtonsProps = {
  doc: Doc<WorksheetType>;
  className?: string;
};

const getBlocks = (doc: Doc<WorksheetType>, isRollup: boolean) =>
  isRollup ? getEditableBlocks(doc) : doc?.data?.[BLOCKS_PATH] || [];

export const BlockNavigationButtons: FC<BlockNavigationButtonsProps> = ({ doc, className }) => {
  const {
    presentedSessionId,
    presentedPageId,
    presentedAccessId,
    presentedBlockId,
    presentingRollup,
  } = usePresentation();

  // TODO: If we don't conflict with assessments we could use the content navigation subnav
  // but right now the PageOverview component uses subnav for assetIds with assessments
  const [blocks, setBlocks] = useState(getBlocks(doc, presentingRollup));
  const page = useAppSelector((s) => selectPageById(s, presentedPageId));
  const blockIds = blocks.map((b) => b.id);

  const blockId = presentedBlockId;
  const blockIndex = blocks.findIndex((b) => b.id === blockId);

  const { nextId, prevId } = getNavigationValues(blockIds, { currentId: blockId });

  useEffect(() => {
    setBlocks(getBlocks(doc, presentingRollup));
  }, [doc, presentingRollup]);

  const changeFeatureBlock = (newBlockId: string) => {
    featurePage(presentedSessionId, page, {
      blockId: newBlockId,
      accessId: presentedAccessId,
      isRollup: presentingRollup,
    });
  };

  return (
    <div className={clsx("feature-block-navigation", className)}>
      <Button
        aria-label={t("presentation.previous_block")}
        disabled={!prevId}
        kind="tertiary"
        size="xs"
        onClick={() => changeFeatureBlock(prevId)}
      >
        <ChevronLeftIcon />
        {t("presentation.previous_block")}
      </Button>

      <div className="body-xs text-black-tint-55">
        {blockIndex + 1}/{blocks.length}
      </div>

      <Button
        aria-label={t("presentation.next_block")}
        disabled={!nextId}
        kind="tertiary"
        size="xs"
        onClick={() => changeFeatureBlock(nextId)}
      >
        {t("presentation.next_block")}
        <ChevronRightIcon />
      </Button>
    </div>
  );
};
