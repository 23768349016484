import { forwardRef } from "react";

import { INDEX_NOT_FOUND } from "components/constants";
import { GROUPING_OPTION_ICONS } from "components/materials/page/grouping-category/helpers";
import { GroupingCategory, PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { DualButton } from "mds/components/DualButton";
import { IconText } from "mds/components/IconText";
import { ChevronDownIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import { selectAssessmentsByPageId } from "store/selectors";

interface GroupButtonProps {
  title: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  page: PageType;
  text: string;
}

const GroupButton = ({ title, onClick, page, text }: GroupButtonProps) => {
  return (
    <Button kind="secondary" size="xs" title={title} onClick={onClick}>
      <IconText iconStart={GROUPING_OPTION_ICONS[page.grouping_category]} text={text} />
    </Button>
  );
};

interface GroupMenuButtonProps {
  page: PageType;
  onGroupConfigCardToggle: (groupingCategory: GroupingCategory) => void;
  onStudentGroupCardToggle?: () => void;
  pageGroupIndex: number;
  /** When the GroupMenuButton is restricted, this means that instructors:
   *   - will not see the dual group menu button (they may not modify the page's grouping category).
   *   - will be able to click on the group button to see either 1) the student group card or 2) the group config card.
   *   - will only be allowed the following actions on the group config card: 1) drag and drop individual students
   *   or 2) create another page group. */
  restricted: boolean;
  onClick?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}
export const GroupMenuButton = forwardRef<HTMLDivElement, GroupMenuButtonProps>(
  (
    {
      page,
      onGroupConfigCardToggle,
      onStudentGroupCardToggle,
      pageGroupIndex,
      restricted,
      onClick,
      onKeyDown,
    },
    ref,
  ) => {
    const assessments = useAppSelector((s) => selectAssessmentsByPageId(s, page.id));
    const shouldOpenStudentGroupCard =
      pageGroupIndex !== INDEX_NOT_FOUND && onStudentGroupCardToggle;

    const buttonTitle = shouldOpenStudentGroupCard
      ? t("common.view_group", {
          number: pageGroupIndex + 1,
        })
      : restricted
        ? assessments.length > 0
          ? t("tooltip.group_config_has_assessments")
          : t("tooltip.type_of_activity")
        : t("tooltip.configure_groups");

    const buttonText = shouldOpenStudentGroupCard
      ? t("common.group_title", {
          number: pageGroupIndex + 1,
        })
      : t(`page_grouping_menu.grouping.${page.grouping_category}`);
    const onGroupButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      if (shouldOpenStudentGroupCard) {
        onStudentGroupCardToggle();
      } else {
        onGroupConfigCardToggle(page.grouping_category);
      }
    };

    const groupButton = (
      <GroupButton page={page} text={buttonText} title={buttonTitle} onClick={onGroupButtonClick} />
    );

    if (restricted) {
      return groupButton;
    }
    return (
      // If we're grouped, then we want to show a dualbutton, the left half of which just re-opens the grouping
      // menu without triggering the dropdown, and the right half of which causes a dropdown which then allows
      // changing of grouping mode.
      <DualButton
        buttonLeft={groupButton}
        buttonRight={
          <Button kind="secondary" size="xs" title={t("tooltip.change_type_of_activity")}>
            <ChevronDownIcon />
          </Button>
        }
        ref={ref}
        gap
        onClick={onClick}
        onKeyDown={onKeyDown}
      />
    );
  },
);

GroupMenuButton.displayName = "GroupMenuButton";
