import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const BurgerMenuIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M13 10.5L3 10.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.2"
    />
    <path
      className="stroke"
      d="M13 5.5L3 5.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.2"
    />
  </svg>
);
