import "./ChoiceField.scss";

import clsx from "clsx";
import { get, isEqual } from "lodash";
import { useContext, useEffect, useState } from "react";
import type { ChangeEvent, FC } from "react";
import { Doc } from "sharedb/lib/client";

import { ChoiceButtonProps } from "../../../types";
import { PresenceDataType } from "../../shared/types/sharedb";
import {
  ChoiceFieldType,
  MultipleChoiceCellType,
  PathType,
  WorksheetType,
} from "../../shared/types/worksheet";

import { DocViewContext } from "providers/DocViewProvider";
import { useAppSelector } from "store/index";
import { selectCurrentUserId } from "store/selectors";
import { rollbarAndLogError } from "utils/logger";

interface ChoiceFieldProps extends ChoiceFieldType {
  path: PathType;
  doc: Doc<WorksheetType>;
}

type MCPresenceDataType = {
  index: number;
  user_id: string;
  srcPresenceId: string;
};

// TODO: Replace with MDS component
export const ChoiceButton: FC<ChoiceButtonProps> = ({
  checked,
  children,
  id,
  isDisabled,
  name,
  onChange,
  onClick,
  highlighted,
  readOnly,
  type = "radio",
  value,
}) => (
  <label
    className={clsx("block-choice-button body-s flex items-center", highlighted && "highlighted")}
  >
    <input
      checked={checked}
      className="mr-2 flex-none"
      disabled={isDisabled}
      id={id}
      name={name}
      readOnly={readOnly}
      type={type}
      value={value}
      onChange={onChange}
      onClick={onClick}
    />
    <div className="block-choice-button-text leading-6">{children}</div>
  </label>
);

export const ChoiceField: FC<ChoiceFieldProps> = ({ c: choices, path, doc, m: multiple }) => {
  const user_id = useAppSelector(selectCurrentUserId);
  const {
    readOnly: docReadOnly,
    isAuthoringPage,
    canInteractAsStudent,
    isFeaturing,
  } = useContext(DocViewContext);
  const [userPresences, setUserPresences] = useState<MCPresenceDataType[]>([]);
  const readOnly = docReadOnly || !canInteractAsStudent;

  const data = get(doc.data, path) as MultipleChoiceCellType;
  const allowsMultipleResponses = Boolean(multiple);
  const allowUnSelect = isAuthoringPage && !allowsMultipleResponses;
  const [selectedIndex, setSelectedIndex] = useState<number | null>(data?.[0]);

  // This is a bit of a hack but we want Authors to be able to unselect a radio button
  const onAuthorClick = (index: number) => () => {
    const selected = selectedIndex !== index;
    setSelectedIndex(selected ? index : null);

    const p = [...path, 0];
    const op = [{ p, ld: null, li: selected ? index : null }];
    doc.submitOp(op);
  };

  const onChange = (index: number) => (evt: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = evt;
    const p = [...path, allowsMultipleResponses ? index : 0];
    const op = [{ p, ld: null, li: checked ? index : null }];

    doc.submitOp(op);

    if (!readOnly) {
      doc.localPresence.submit({
        t: "mc",
        i: index,
        p: path,
        u: user_id,
      });
    }
  };

  useEffect(() => {
    const onPresence = (srcPresenceId: string, presence: PresenceDataType) => {
      if (!srcPresenceId || !presence || !presence.p) {
        // Remove the cursor if the source is disconnected
        setUserPresences((prev) => prev.filter((p) => p.srcPresenceId !== srcPresenceId));
        return;
      }

      if (!isEqual(presence.p, path)) {
        // Remove presence if the source user is active in another field
        setUserPresences((prev) => prev.filter((p) => p.user_id !== presence.u));
        return;
      }

      if (presence.t !== "mc") {
        rollbarAndLogError("Got invalid presence for ChoiceField", {
          presence,
        });
        return;
      }

      setUserPresences((prev) => [
        ...prev.filter((p) => p.user_id !== presence.u),
        { index: presence.i, user_id: presence.u, srcPresenceId },
      ]);
    };

    doc.presence.on("receive", onPresence);
    return () => {
      doc.presence.off("receive", onPresence);
    };
  }, [doc, path]);

  return (
    <div className="px-2">
      {choices.map((choice, index) => {
        const type = allowsMultipleResponses ? "checkbox" : "radio";
        const label = choice.l;

        // TODO: This is fully working in terms of highlighting the user's presence,
        // but displaying the name of the user as a cursor is currently only implemented
        // in CodeEditor/Cursor.tsx, which is specific to the code editor. We should generalize this.
        const highlighted = userPresences.some((p) => p.index === index);

        // Multiple responses are stored in `data` in the format [0, null, 2] to denote index 0 and 2
        // are selected. Note in this example there may be more than 3 options to select, but all the
        // options after index 2 are undefined and thus not selected.
        const isChecked = !data
          ? false
          : allowsMultipleResponses
            ? data[index] === index
            : index === data[0];

        const choiceGlobalUniqueId = `${path.join("-")}-${choice.id}${isFeaturing ? "-feature" : ""}`;

        return (
          <ChoiceButton
            checked={isChecked}
            highlighted={highlighted}
            id={choiceGlobalUniqueId}
            isDisabled={readOnly}
            key={choiceGlobalUniqueId}
            name={choiceGlobalUniqueId}
            readOnly={readOnly}
            type={type}
            value={String(index)}
            onChange={(e) => (!readOnly && !allowUnSelect ? onChange(index)(e) : null)}
            onClick={allowUnSelect ? onAuthorClick(index) : null}
          >
            {label}
          </ChoiceButton>
        );
      })}
    </div>
  );
};
