import { createContext } from "react";

type BypassReleaseWarningContextType = {
  showWarning: boolean;
  bypassWarning: () => void;
  resetWarning: () => void;
};

export const BypassReleaseWarningContext = createContext<BypassReleaseWarningContextType>({
  showWarning: true,
  bypassWarning: () => {},
  resetWarning: () => {},
});
