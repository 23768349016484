import "./ZoomToolbar.scss";
import React from "react";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";

type ZoomToolbarProps = {
  setZoom: (value: number) => void;
  zoom: number;
  narrow?: boolean;
};

const MAX_ZOOM_PERCENT = 400;
const MIN_ZOOM_PERCENT = 25;
const ZOOM_STEP = 25;

export const ZoomToolbar: React.FC<ZoomToolbarProps> = ({ setZoom, zoom, narrow }) => {
  const zoomIn = () => {
    const newZoom = zoom + ZOOM_STEP;
    setZoom(newZoom > MAX_ZOOM_PERCENT ? MAX_ZOOM_PERCENT : newZoom);
  };

  const zoomOut = () => {
    const newZoom = zoom - ZOOM_STEP;
    setZoom(newZoom < MIN_ZOOM_PERCENT ? MIN_ZOOM_PERCENT : newZoom);
  };

  if (narrow) {
    return (
      <div className="zoom-toolbar flex items-stretch">
        <div>
          <input
            max={MAX_ZOOM_PERCENT}
            min={MIN_ZOOM_PERCENT}
            step={ZOOM_STEP}
            type="number"
            value={zoom}
            onChange={(e) => setZoom(parseInt(e.target.value, 10))}
          />
        </div>
        <div className="number-spinner">
          <button
            aria-label={t("common.zoom_in")}
            className="incr"
            disabled={zoom >= MAX_ZOOM_PERCENT}
            type="button"
            onClick={zoomIn}
          >
            {t("common.increase_size_icon")}
          </button>
          <button
            aria-label={t("common.zoom_out")}
            className="decr"
            disabled={zoom <= MIN_ZOOM_PERCENT}
            type="button"
            onClick={zoomOut}
          >
            {t("common.decrease_size_icon")}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="zoom-toolbar flex items-center gap-2">
      <Button
        aria-label={t("common.zoom_out")}
        disabled={zoom <= MIN_ZOOM_PERCENT}
        kind="secondary"
        size="s"
        iconOnly
        onClick={zoomOut}
      >
        {t("common.decrease_size_icon")}
      </Button>

      <span className="page-toolbar__label body-s text-black-tint-20">
        {t("common.percentage", { number: zoom })}
      </span>

      <Button
        aria-label={t("common.zoom_in")}
        disabled={zoom >= MAX_ZOOM_PERCENT}
        kind="secondary"
        size="s"
        iconOnly
        onClick={zoomIn}
      >
        {t("common.increase_size_icon")}
      </Button>
    </div>
  );
};
