import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const ChevronDownIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="fill"
      d="M14 5.75625C14 5.58423 13.9408 5.4107 13.8193 5.27009C13.55 4.9567 13.0768 4.92005 12.7625 5.18931L8.00012 9.2589L3.23772 5.18856C2.92348 4.9193 2.45099 4.95595 2.18099 5.26934C1.911 5.58273 1.947 6.05543 2.26274 6.32395L7.51263 10.8116C7.79313 11.0517 8.20787 11.0517 8.48836 10.8116L13.7383 6.32395C13.9115 6.1766 14 5.96643 14 5.75625Z"
    />
  </svg>
);
