import { AxiosError } from "axios";
// eslint-disable-next-line no-restricted-imports
import i18n from "i18next";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { LoadingScreen } from "components/LoadingScreen";
import { FullCourseType } from "components/server-types";
import { ToastKeys } from "i18n/i18next.js";
import { ListItem } from "mds/components/ListItem";
import { BookCourseIcon } from "mds/icons";
import { storeApi, useAppSelector } from "store/index";
import { selectFullCoursesSorted, selectTerms } from "store/selectors";
import { useListSelector } from "store/store-hooks";
import {
  toastLocalizedOperationCatcher,
  toastLocalizedOperationError,
  toastSuccessMessage,
} from "utils/alerts";

interface CoursesByTerm {
  [termId: string]: FullCourseType[];
}

interface CourseListItemProps {
  course: FullCourseType;
  isLinked: boolean;
  onSelectCourse: (courseId: string) => void;
}

const CourseListItem: React.FC<CourseListItemProps> = ({ course, isLinked, onSelectCourse }) => (
  <ListItem
    className="flex justify-between"
    // @ts-expect-error disabled is not typed
    disabled={isLinked}
    label={course.full_title}
    onClick={isLinked ? undefined : () => onSelectCourse(course.id)}
  >
    <div className="flex items-center gap-2">
      <BookCourseIcon />
      <span className={isLinked ? "text-black-tint-55" : ""}>{course.full_title}</span>
      {isLinked && (
        <span className="ml-2 text-black-tint-55">(Linked to course #{course.lms_section_id})</span>
      )}
    </div>
  </ListItem>
);

export default function Layout() {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const courses = useListSelector(selectFullCoursesSorted);
  const terms = useAppSelector(selectTerms);
  const navigate = useNavigate();

  useEffect(() => {
    storeApi.course_codes.list();
    storeApi.terms.list();
  }, []);

  const coursesByTerm = useMemo(() => {
    return courses.reduce((acc: CoursesByTerm, course) => {
      const termId = course.term_id;
      return {
        ...acc,
        [termId]: [...(acc[termId] || []), course],
      };
    }, {} as CoursesByTerm);
  }, [courses]);

  const lmsSectionId = parseInt(searchParams.get("lmsSectionId"), 10);
  if (courses.length === 0 || !lmsSectionId) {
    return <LoadingScreen text="No courses available" />;
  }

  const onSelectCourse = (courseId: string) => {
    storeApi.courses
      .partial_update(
        {
          id: courseId,
          lms_section_id: lmsSectionId,
        },
        { skipToast: true },
      )
      .then(() => {
        toastSuccessMessage(t("success.toasts.link_course_started_success"));
        navigate(`/redirect?courseId=${courseId}`);
      })
      .catch((error: AxiosError) => {
        // TODO: make a generic function for this
        const data = error.response?.data as Record<string, string>;
        if (data && "error" in data) {
          if (!i18n.exists(`error.toasts.${data.error}`)) {
            // If it doesn't exist, default to a generic error
            toastLocalizedOperationError("unknown");
          } else toastLocalizedOperationError(data.error as ToastKeys);
        } else {
          toastLocalizedOperationCatcher("link_lms_failure")(error);
        }
      });
  };

  return (
    <div>
      {Object.entries(coursesByTerm).map(([termId, termCourses]) => {
        const term = terms.find((termItem) => termItem.id === termId);
        const termTitle = term ? term.title : "Unassigned";
        return (
          <div key={termId}>
            <h2 className="mb-2 text-lg font-bold">{termTitle}</h2>
            {termCourses.map((course) => (
              <CourseListItem
                course={course}
                isLinked={course.lms_section_id !== null}
                key={course.id}
                onSelectCourse={onSelectCourse}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
}
