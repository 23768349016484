import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const PersonGroupIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path className="stroke" d="M8 5.66663V8.93329" strokeLinecap="round" strokeLinejoin="round" />
    <path
      className="stroke"
      d="M5.15527 11.2097L8.00007 8.93335L10.8458 11.2097"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M8.00033 5.66667C9.28899 5.66667 10.3337 4.622 10.3337 3.33333C10.3337 2.04467 9.28899 1 8.00033 1C6.71166 1 5.66699 2.04467 5.66699 3.33333C5.66699 4.622 6.71166 5.66667 8.00033 5.66667Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M3.33333 15C4.622 15 5.66667 13.9554 5.66667 12.6667C5.66667 11.378 4.622 10.3334 3.33333 10.3334C2.04467 10.3334 1 11.378 1 12.6667C1 13.9554 2.04467 15 3.33333 15Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M12.6663 15C13.955 15 14.9997 13.9554 14.9997 12.6667C14.9997 11.378 13.955 10.3334 12.6663 10.3334C11.3777 10.3334 10.333 11.378 10.333 12.6667C10.333 13.9554 11.3777 15 12.6663 15Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
