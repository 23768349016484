import "./PageTitlebar.scss";

import { FC } from "react";

import { HoverableEditField } from "components/hover-widgets/EditableField";
import { HoverableToolbar } from "components/hover-widgets/HoverableToolbar";
import { ContentNavigationButtons } from "components/materials/ContentNavigationButtons";
import { PageSidebarOptionsMenu } from "components/menus/PageSidebarOptionsMenu";
import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { useAppSelector } from "store/index";
import {
  selectCanAuthorCourse,
  selectCurrentUserIsMPAdmin,
  selectIsAssessing,
  selectIsEditModeEnabled,
} from "store/selectors";
import { usePageActions } from "utils/page";
import { relativeLink } from "utils/urls";

type PageTitlebarProps = {
  page: PageType;
  selectedTopicId?: string;
  pages: PageType[];
};

export const PageTitlebar: FC<PageTitlebarProps> = ({ page, selectedTopicId, pages }) => {
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const isAssessing = useAppSelector(selectIsAssessing);
  const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);
  const isMPAdmin = useAppSelector(selectCurrentUserIsMPAdmin);

  const { savePage, navigateToPage } = usePageActions();

  const onPageTitleChanged = (newTitle: string) => savePage({ id: page.id, title: newTitle });
  const onPageChange = (selectedPageId: string, pageUrl: URL) => {
    const selectedPage = pages.find((p) => p.id === selectedPageId);
    navigateToPage(selectedPage, relativeLink(pageUrl));
  };

  return (
    <div className="flex w-full shrink-0 grow-0 flex-col items-center px-4">
      <div className="page-titlebar">
        <div className="title-text flex-auto pr-2">
          <HoverableToolbar
            className="relative my-1"
            contentClassName="mt-2.5 bg-white"
            readOnly={!canAuthorCourse}
            reference="page"
            disableUnlessEditMode
            showEditButton
            uncentered
          >
            <h2 className="my-1">
              <HoverableEditField
                label={t("glossary.page_title")}
                textSize="h2"
                value={page.title}
                disableUnlessEditMode
                shouldWrap
                onValueChanged={onPageTitleChanged}
              />
            </h2>
          </HoverableToolbar>
        </div>

        <div className="navigation-buttons flex items-center gap-2 pt-1">
          {canAuthorCourse && (
            <PageSidebarOptionsMenu
              currentTopicId={selectedTopicId}
              direction="bottom"
              editMode={isEditModeEnabled}
              page={page}
              showDownloadUploadOptions={isMPAdmin}
            />
          )}

          {!isAssessing && (
            <ContentNavigationButtons pages={pages} reference="page" onChange={onPageChange} />
          )}
        </div>
      </div>
    </div>
  );
};
