/**
 * This file contains utility functions for tracking events and user properties in Amplitude.
 * If you want to test this locally, you can set the AMPLITUDE_ENABLED and AMPLITUDE_API_KEY
 * in the public/config.js file. Only use an API key for a local development environment to
 * avoid tracking events in the production bucket.
 */
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

const isAmplitudeEnabled = window._env_?.AMPLITUDE_ENABLED || false;
const amplitudeApiKey = window._env_?.AMPLITUDE_API_KEY || "";

let isAmplitudeInitialized = false;

/**
 * This will initialize Amplitude if it is enabled for the current environment
 * and has not already been initialized.
 * - `currentUserEmail` is used for linking to that user in Amplitude. We are intentionally not tracking users who are
 * not logged in to avoid tracking users who have not consented to tracking. Set to null to disable tracking.
 * - `sessionReplaySampleRate` is used to determine the chance the session is recorded, from
 * from 1.0 (100%) to 0.0 (0%).
 * We need to initialize session replay before Amplitude is initialized, otherwise session replays will not work.
 */
export function maybeInitAmplitude(currentUserEmail: string, sessionReplaySampleRate: number) {
  if (!currentUserEmail) {
    resetAndStopTrackingInAmplitude();
    return;
  }

  if (isAmplitudeEnabled && !isAmplitudeInitialized) {
    try {
      if (!amplitudeApiKey || amplitudeApiKey === "") {
        console.error("Amplitude API key is not set. Cannot initialize amplitude.");
        return;
      }

      if (sessionReplaySampleRate > 0) {
        // Create and Install Session Replay Plugin, see https://amplitude.com/docs/session-replay/session-replay-plugin
        // Note: if trying to test session replays locally, add `debugMode: true` to the plugin options.
        // Note: sessionReplayTracking will enable sessions to be tracked by default, but this is not
        // required so we override the behavior.
        const sessionReplayTracking = sessionReplayPlugin({
          sampleRate: sessionReplaySampleRate,
          forceSessionTracking: false,
        });
        amplitude.add(sessionReplayTracking);
      }

      // We don't use autocapture most things because we want to be very explicit about what we're tracking.
      // Autocapture can add a lot of noise to the data (and potentially hit our event limit for our plan sooner).
      amplitude.init(amplitudeApiKey, currentUserEmail, {
        autocapture: {
          attribution: false,
          pageViews: false,
          sessions: false,
          formInteractions: false,
          fileDownloads: false,
          elementInteractions: false,
        },
      });
      // TODO: Later we can track on a per user basis if they have opted out of tracking and disable here.
      amplitude.setOptOut(false);
      isAmplitudeInitialized = true;
    } catch (e) {
      console.error("Failed to initialize amplitude", e);
    }
  }
}

/**
 * This will clear out the current user information and stop event tracking in Amplitude.
 * This is intended to be used when the user logs out of our system or opts out of tracking
 * within a session.
 */
export function resetAndStopTrackingInAmplitude() {
  if (isAmplitudeEnabled && isAmplitudeInitialized) {
    amplitude.reset();
    amplitude.setOptOut(true);
    isAmplitudeInitialized = false;
  }
}

type EventCategoryType = "General" | "Button press" | "Page view" | "Screen view";

/**
 * This is a collection of any possible event properties that we might want to track in Amplitude. We are not
 * enforcing the specific properties on an event but just want to ensure the naming is consistent across events.
 */
interface AmplitudeEventPropertiesType {
  eventCategory: EventCategoryType;
  courseTitle?: string;
  expanded?: boolean;
  messageLength?: number;
  message?: string;
  messageNumber?: number;
  pageUrl?: string;
  context?: string;
  shareStudentResponse?: boolean;
  newLanguage?: string;
  isEnabled?: boolean;
  userEmail?: string;
  userGivenName?: string;
  userFamilyName?: string;
  newRole?: string;
  lmsSectionId?: number;
}

/**
 * This function is used to track events in Amplitude and enforce the typing of the event properties
 * to avoid accidental drift in their naming.
 */
export function trackEvent(eventName: string, properties: AmplitudeEventPropertiesType) {
  amplitude.track(eventName, properties);
}

/**
 * This is a collection of all possible user properties that we track in Amplitude. This list
 * will be expanded as we add more properties to track.
 */
type AmplitudeUserProperties = {
  courseId?: string;
};

/**
 * This function is used to track user properties in Amplitude that ensures only properties we expect
 * are set. Amplitude links these properties with any future events that are tracked.
 * More info on setting user properties: https://amplitude.com/docs/sdks/analytics/browser/browser-sdk-2#user-properties
 */
export function trackUserProperty<K extends keyof AmplitudeUserProperties>(
  propertyName: K,
  propertyValue: AmplitudeUserProperties[K],
) {
  const identifyEvent = new amplitude.Identify();
  identifyEvent.set(propertyName, propertyValue);
  amplitude.identify(identifyEvent);
}
