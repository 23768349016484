import clsx from "clsx";

import { AIModelType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { Checkbox } from "mds/components/Checkbox";
import { Menu, MenuItem } from "mds/components/Menu";
import { TextArea } from "mds/components/TextArea";
import { ChevronDownIcon, GearSettingsIcon } from "mds/icons";

// Keep in sync with the server's AI model options in ai_constants.py
// The first option in the list will be the default selection.
// eslint-disable-next-line react-refresh/only-export-components
export const AI_MODEL_OPTIONS: AIModelType[] = [
  "gpt-4o-mini",
  "gpt-4o",
  "o3-mini",
  "claude-3-5-sonnet-latest",
  "claude-3-7-sonnet-latest",
];

const DEFAULT_MODEL: AIModelType = "gpt-4o-mini";

// eslint-disable-next-line react-refresh/only-export-components
export const AI_DEFAULT_SETTINGS: AISettingsType = {
  useContext: true,
  aiModel: DEFAULT_MODEL,
  systemPrompt: t("ai_chat_panel.default_system_prompt"),
};

export interface AISettingsType {
  useContext: boolean;
  aiModel: AIModelType;
  systemPrompt: string;
}
export const AISettings = ({
  settings,
  setSettings,
  canUseContext,
}: {
  settings: AISettingsType;
  setSettings: (cb: (s: AISettingsType) => AISettingsType) => void;
  canUseContext?: boolean;
}) => {
  return (
    <Menu
      direction="bottom"
      menuButton={
        <Button kind="secondary" size="xs">
          <GearSettingsIcon />
        </Button>
      }
      onItemClick={(e) => setSettings((s) => ({ ...s, aiModel: e.value as AIModelType }))}
    >
      <div className="body-s w-[400px] max-w-full">
        <div className="h4 mb-2">{t("ai_chat_panel.settings.title")}</div>

        <Menu
          direction="bottom"
          menuButton={
            <Button className="mb-2" kind="secondary" size="xs">
              <div className="mr-1">{settings.aiModel.toUpperCase()}</div>
              <ChevronDownIcon />
            </Button>
          }
          onItemClick={(e) => setSettings((s) => ({ ...s, aiModel: e.value as AIModelType }))}
        >
          {AI_MODEL_OPTIONS.map((modelOption) => {
            const selected = settings.aiModel === modelOption;
            return (
              <MenuItem
                checked={selected}
                className={clsx({ selected, "icon-white": selected })}
                key={modelOption}
                value={modelOption}
              >
                {modelOption.toUpperCase()}
              </MenuItem>
            );
          })}
        </Menu>

        {canUseContext && (
          <div className="mb-4 flex items-center">
            <Checkbox
              checked={settings.useContext}
              className="mr-1"
              onChange={() => setSettings((s) => ({ ...s, useContext: !settings.useContext }))}
            />

            <div>{t("ai_chat_panel.settings.page_content_label")}</div>
          </div>
        )}

        <div>{t("ai_chat_panel.settings.prompt_label")}</div>

        <TextArea
          className="w-full !overflow-scroll"
          minRows={8}
          placeholder={t("ai_chat_panel.settings.prompt_placeholder")}
          value={settings.systemPrompt}
          autoFocus
          onChange={(e) => setSettings((s) => ({ ...s, systemPrompt: e.target.value }))}
        />
      </div>
    </Menu>
  );
};
