import "./DueDateButton.scss";

import clsx from "clsx";
import { format, isPast } from "date-fns";
import { type FC, useEffect, useMemo, useState } from "react";

import formatDistanceToNow from "../../../lib/formatDistanceToNow";

import { showPageDisabled } from "./groups/helpers";
import { getLateSubmissionDueDate, isInLateSubmissionWindow } from "./helpers";

import { DueDateForm } from "components/forms/DueDateForm";
import { PageType } from "components/server-types";
import { DAY_AND_TIME_FORMAT } from "i18n/helpers";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { IconText } from "mds/components/IconText";
import { Popover, PopoverButton, PopoverPanel } from "mds/components/Popover";
import { CalendarIcon, ChevronDownIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import { selectIsEditModeEnabled } from "store/selectors";

type DueDateButtonProps = {
  page: PageType;
  readOnly?: boolean;
  className?: string;
  kind?: "page" | "list-item";
  shortText?: boolean;
  clickStopPropagation?: boolean;
};

const UPDATE_DISTANCE_INTERVAL = 30000;

type DisplayProps = {
  dueDateString: string;
  dueDateTitle: string;
  isWithinLateWindow?: boolean;
};

const defaultLateSubmissionDisplayInfo = (lateDueDate: Date, distance: string): DisplayProps => {
  const dueDateTitle = lateDueDate
    ? t(`due_date.tooltips.accepting_late_submissions_date`, {
        date: format(lateDueDate, DAY_AND_TIME_FORMAT),
      })
    : t(`due_date.tooltips.accepting_late_submissions_indefinite`, { distance });

  return {
    dueDateString: t("due_date.accepting_late_submissions"),
    dueDateTitle,
  };
};

const defaultDisplayInfo = (dueDate: Date, distance: string): DisplayProps => {
  const dueDateString = dueDate
    ? t("due_date.due_with_distance", {
        date: dueDate,
        distance,
      })
    : t("due_date.title");

  return { dueDateString, dueDateTitle: dueDateString };
};

const getDueDateDisplayInfo = (
  page: PageType,
  dueDate: Date,
  distance: string,
  shortText: boolean,
): DisplayProps => {
  const lateDueDate =
    dueDate && page.late_submission_interval ? getLateSubmissionDueDate(page) : undefined;
  const pastLateDueDate = lateDueDate && isPast(lateDueDate);
  const isWithinLateWindow = isInLateSubmissionWindow(page);

  // Typescript doesn't allow destructuring with different variable declaration qualifiers

  let { dueDateString, dueDateTitle } = isWithinLateWindow
    ? defaultLateSubmissionDisplayInfo(lateDueDate, distance)
    : defaultDisplayInfo(dueDate, distance);

  if (shortText && dueDate) {
    const dateFormatter = new Intl.DateTimeFormat("default", { month: "short", day: "numeric" });
    const timeFormatter = new Intl.DateTimeFormat("default", {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });

    dueDateString = `${dateFormatter.format(dueDate)}, ${timeFormatter.format(dueDate)}`;
  }

  if (pastLateDueDate) {
    dueDateTitle = t("due_date.tooltips.accepted_late_submissions", { distance });
  }

  return { dueDateString, dueDateTitle, isWithinLateWindow };
};

export const DueDateButton: FC<DueDateButtonProps> = ({
  page,
  readOnly,
  className,
  kind = "page",
  shortText,
  clickStopPropagation,
}) => {
  const dueDate = useMemo(() => (page.due_at ? new Date(page.due_at) : undefined), [page.due_at]);
  const pageIsReleased = Boolean(page.released_at);
  const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);
  const isListItem = kind === "list-item";

  const showDisabled = isListItem && showPageDisabled(page);
  const showIcon = !isListItem;
  const dueDateClassName = isListItem
    ? "due-date-button-list-item list-item-chip"
    : "due-date-button-page";

  const [distance, setDistance] = useState<string>(
    dueDate ? formatDistanceToNow(dueDate, { addSuffix: true }) : "",
  );

  const { dueDateString, dueDateTitle, isWithinLateWindow } = useMemo(
    () => getDueDateDisplayInfo(page, dueDate, distance, shortText),
    [page, dueDate, distance, shortText],
  );

  useEffect(() => {
    if (dueDate) {
      setDistance(formatDistanceToNow(dueDate, { addSuffix: true }));

      const intervalId = setInterval(() => {
        // update `distance` string every 30s
        setDistance(formatDistanceToNow(dueDate, { addSuffix: true }));
      }, UPDATE_DISTANCE_INTERVAL);

      return () => clearInterval(intervalId);
    }
  }, [dueDate]);

  if (!readOnly && isEditModeEnabled) {
    return (
      <Popover
        className={clsx(
          "due-date-button relative bg-white text-black-tint-20",
          isListItem ? "rounded-md" : "rounded-lg",
        )}
      >
        <PopoverButton
          as={Button}
          className={clsx(className, dueDateClassName, {
            "within-late-window": isWithinLateWindow,
          })}
          kind="secondary"
          size="xs"
          title={dueDateTitle}
        >
          {showIcon && <CalendarIcon className={clsx(isWithinLateWindow && "icon-orange")} />}

          <span className="px-1" title={dueDateTitle}>
            {dueDateString}
          </span>

          <ChevronDownIcon />
        </PopoverButton>

        <PopoverPanel
          anchor="bottom"
          onClick={clickStopPropagation ? (e) => e.stopPropagation() : undefined}
        >
          {({ close }) => (
            <>
              {pageIsReleased && (
                <div className="w-80 rounded border-orange bg-orange-tint-90 p-2">
                  {isPast(dueDate)
                    ? t("due_date.reopen_submissions_warning")
                    : t("due_date.page_released_warning")}
                </div>
              )}

              <DueDateForm
                close={close}
                date={dueDate}
                lateInterval={page.late_submission_interval}
                pageId={page.id}
              />
            </>
          )}
        </PopoverPanel>
      </Popover>
    );
  }

  if (dueDate) {
    const text = <span title={dueDateTitle}>{dueDateString}</span>;

    return (
      <div
        className={clsx(
          "due-date-button flex cursor-default items-center justify-center gap-1 whitespace-nowrap border-black-tint-90 px-2",
          className,
          dueDateClassName,
          showDisabled ? "text-black-tint-55" : "text-black-tint-20",
          isListItem ? "bg-black-tint-97 py-[3px]" : "bg-white py-1",
          isWithinLateWindow && "within-late-window",
        )}
      >
        {showIcon ? (
          <IconText
            iconStart={
              <CalendarIcon
                aria-label={dueDate ? t("due_date.title") : undefined}
                className={clsx(isWithinLateWindow && "icon-orange")}
              />
            }
            text={text}
          />
        ) : (
          text
        )}
      </div>
    );
  }

  return null;
};

DueDateButton.displayName = "DueDateButton";
