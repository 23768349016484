import { FC, Ref, useRef, useState } from "react";
import { useBoolean } from "usehooks-ts";

import { hasEditableBlocks } from "../../worksheets/shared/doc-helpers";

import { CopyToCourseSubMenu } from "./CopyToCourseSubMenu";
import { MoveToSubMenu } from "./MoveToSubMenu";
import { SidebarOptionsMenu, SidebarOptionsMenuProps } from "./SidebarOptionsMenu";

import { isSharedClassPage } from "components/materials/page/groups/helpers";
import { STATUS_COLORS } from "components/materials/page/helpers";
import {
  ActivityCategory,
  AssessmentStatus,
  CoursePageCategory,
  PageType,
} from "components/server-types";
import { toastReleasePage, toastUnreleasePage } from "helpers/toasts";
import { t } from "i18n/i18n";
import { ConfirmDeletionDialog } from "mds/components/ConfirmDeletionDialog";
import { IconText } from "mds/components/IconText";
import { MenuDivider, MenuInstance, MenuItem, SubMenu } from "mds/components/Menu";
import {
  AssessPointsMarkerIcon,
  CopySendArrowIcon,
  LockLockedIcon,
  LockUnlockedIcon,
  PageStatusIcon,
} from "mds/icons";
import { useWorksheet } from "providers/ShareDBDoc";
import { storeApi, useAppDispatch, useAppSelector } from "store/index";
import {
  selectActivityById,
  selectAssessedSubmissionsByPageId,
  selectCourseUsersByRole,
} from "store/selectors";
import { computedStateActions } from "store/slices/computed";
import { useListSelector } from "store/store-hooks";
import { isCoursePage, usePageActions } from "utils/page";
import { usePresentation } from "utils/presentation";
import { useSubmissions } from "utils/submission";

const COLLECTION_DISABLED_TIMEOUT_MS = 10000;

export interface PageSidebarOptionsMenuProps
  extends Omit<SidebarOptionsMenuProps, "optionsMenuActions"> {
  page: PageType;
  currentTopicId?: string;
  editMode?: boolean;
  showDownloadUploadOptions?: boolean;
}

export const PageSidebarOptionsMenu: FC<PageSidebarOptionsMenuProps> = ({
  styles,
  size = "s",
  className,
  buttonKind = "secondary",
  direction = "right",
  page,
  currentTopicId,
  editMode,
  showDownloadUploadOptions,
}) => {
  const isReleased = !!page?.released_at;
  const isLocked = !!page?.locked_at;

  const [isCollectDisabled, setIsCollectDisabled] = useState(false);
  const { doc } = useWorksheet(page?.worksheet_id);
  const isStaticContent = !doc || !hasEditableBlocks(doc);

  const { lockPage, unlockPage, copyPage, savePage, movePageWithinCourse } = usePageActions();
  const { presentedPageId } = usePresentation();
  const activity = useAppSelector((s) => selectActivityById(s, page.activity_id));
  const students = useListSelector((s) => selectCourseUsersByRole(s, "student"));
  const dispatch = useAppDispatch();

  const { createSubmissions } = useSubmissions(page);

  const {
    value: confirmationDialogIsOpen,
    setTrue: openConfirmationDialog,
    setFalse: closeConfirmationDialog,
  } = useBoolean(false);

  const pageReleasable =
    page.category !== "instructor_workspace" &&
    (!activity || activity?.category !== ActivityCategory.ARCHIVE);
  const showCopyToCourse = isCoursePage(page);

  const menuRef: Ref<MenuInstance> = useRef(null);
  const assessedSubmissions = useListSelector((s) =>
    selectAssessedSubmissionsByPageId(s, page?.id),
  );

  const canDeletePage = assessedSubmissions.length === 0;
  const cannotCollect =
    !isReleased ||
    isCollectDisabled ||
    isSharedClassPage(page) ||
    students.length === 0 ||
    isStaticContent;

  const disableCollectionTemporarily = () => {
    setIsCollectDisabled(true);
    setTimeout(() => setIsCollectDisabled(false), COLLECTION_DISABLED_TIMEOUT_MS);
  };

  const closeMenu = () => menuRef.current.closeMenu();

  // Menu actions
  const onRelease = async () => {
    await savePage({ id: page.id, released_at: new Date().toJSON() });
    toastReleasePage();
  };

  const onUnrelease = async () => {
    await savePage({ id: page.id, released_at: null });
    toastUnreleasePage();
  };

  const onLock = () => lockPage(page);
  const onUnlock = () => unlockPage(page);

  const onCollect = async () => {
    await createSubmissions();
    disableCollectionTemporarily();

    // Mark page as ready for assessment
    dispatch(
      computedStateActions.savePageAssessmentStatuses({ [page.id]: AssessmentStatus.ASSESSABLE }),
    );
  };

  const onDuplicate = () => {
    const dupProps = page.activity_id
      ? { activityIds: [page.activity_id] }
      : { courseIds: [page.course_id] };

    copyPage(page.category, page.id, dupProps);
  };

  const onMoveTo = (destinationCategory: CoursePageCategory, destinationTopicId?: string) => {
    movePageWithinCourse(page.id, { destinationCategory, destinationTopicId });
    closeMenu();
  };

  const onCopyToCourse = (courseIds: string[]) => {
    copyPage(page.category, page.id, { courseIds });
    closeMenu();
  };

  return (
    <SidebarOptionsMenu
      buttonKind={buttonKind}
      className={className}
      direction={direction}
      instanceRef={menuRef}
      optionsMenuActions={{
        onDelete: openConfirmationDialog,
        onDuplicate,
        isDeleteDisabled: !canDeletePage || !editMode,
        isDuplicateDisabled: !editMode,
        deleteTooltipText: canDeletePage ? undefined : t("tooltip.delete_page_has_assessments"),
        showDownloadUploadOptions,
        page,
      }}
      size={size}
      styles={styles}
    >
      {pageReleasable && (
        <>
          {isReleased ? (
            <>
              <MenuItem disabled={presentedPageId === page.id} onClick={onUnrelease}>
                <IconText
                  iconStart={<PageStatusIcon color={STATUS_COLORS.unreleased} />}
                  text={t("sidebar_options_menu.unrelease_page")}
                />
              </MenuItem>
              <MenuDivider />
            </>
          ) : (
            <>
              <MenuItem className="hover-green" onClick={onRelease}>
                <IconText
                  iconStart={<PageStatusIcon color={STATUS_COLORS.released} />}
                  text={t("sidebar_options_menu.release_page")}
                />
              </MenuItem>
              <MenuDivider />
            </>
          )}

          <MenuItem disabled={cannotCollect} onClick={onCollect}>
            <IconText
              iconStart={<AssessPointsMarkerIcon />}
              text={t("sidebar_options_menu.collect")}
            />
          </MenuItem>

          {isLocked ? (
            <>
              <MenuItem onClick={onUnlock}>
                <IconText
                  iconStart={<LockUnlockedIcon />}
                  text={t("sidebar_options_menu.unlock")}
                />
              </MenuItem>
              <MenuDivider />
            </>
          ) : (
            <>
              <MenuItem onClick={onLock}>
                <IconText iconStart={<LockLockedIcon />} text={t("sidebar_options_menu.lock")} />
              </MenuItem>
              <MenuDivider />
            </>
          )}
        </>
      )}

      {showCopyToCourse && (
        <SubMenu
          disabled={!editMode}
          label={
            <IconText
              iconStart={<CopySendArrowIcon />}
              text={t("sidebar_options_menu.copy_to_course")}
            />
          }
          withArrow
        >
          <CopyToCourseSubMenu onCancel={closeMenu} onConfirm={onCopyToCourse} />
        </SubMenu>
      )}

      <SubMenu
        disabled={!editMode}
        label={
          <IconText iconStart={<CopySendArrowIcon />} text={t("sidebar_options_menu.move_to")} />
        }
        overflow="auto"
        withArrow
      >
        <MoveToSubMenu
          currentPageCategory={page?.category}
          currentTopicId={currentTopicId}
          itemKind="page"
          onCancel={closeMenu}
          onConfirm={onMoveTo}
        />
      </SubMenu>
      <ConfirmDeletionDialog
        glossaryKey="page"
        instanceName={page.title}
        open={confirmationDialogIsOpen}
        onClose={closeConfirmationDialog}
        onDelete={() => storeApi.pages.destroy(page.id)}
      />
    </SidebarOptionsMenu>
  );
};
