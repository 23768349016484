import "./ChoiceFieldAggregate.scss";

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import type { ChartData, ChartOptions } from "chart.js";
import clsx from "clsx";
import { get, sum } from "lodash";
import type { FC, ReactNode } from "react";
import { useContext, useEffect, useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { Doc } from "sharedb/lib/client";

import { canRollupAccessId, isGroupAccessId } from "../../shared/access-id";
import { DATA_PATH } from "../../shared/constants";
import type {
  ChoiceFieldChoiceType,
  ChoiceFieldType,
  MultipleChoiceCellType,
  PathType,
  WorksheetType,
} from "../../shared/types/worksheet";

import { isValidAccessIdForPageType } from "components/materials/page/groups/helpers";
import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { DropdownMenu } from "mds/components/DropdownMenu";
import { PersonGroupIcon, PersonSingleIcon } from "mds/icons";
import { DocViewContext } from "providers/DocViewProvider";
import { useAppSelector } from "store/index";
import { selectCanAuthorCourse, selectPageGroupsByPage } from "store/selectors";
import { getUsername } from "utils/user-utils";
import { useUsersFromAccessId } from "utils/worksheet";
import { theme } from "virtual:tailwind-config";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

interface ChoiceFieldAggregateProps extends ChoiceFieldType {
  fieldPath: PathType;
  page: PageType;
  doc: Doc<WorksheetType>;
}

interface AnswersByStudent {
  [studentId: string]: number[];
}

interface AnswersByChoice {
  [choiceId: string]: string[];
}

// Chart.js doesn't accept CSS variables as colors,
// but we can look up the values and pass them as strings.
const colorNames = ["redviolet", "yellow", "yellowgreen", "orange", "bluegreen", "violet"] as const;
const COLORS = (["shade", "tint"] as const)
  .map((modifier) => colorNames.map((color) => theme.colors[color][`${modifier}-20`]))
  .flat();

type ChartType = "pie" | "bar";

interface ChartWrapperProps {
  children: ReactNode;
  choices: ChoiceFieldChoiceType[];
  answersByChoice: AnswersByChoice;
  percentage?: boolean;
  showDropdown?: boolean;
  chartType?: ChartType;
  onChartTypeChange?: (chartType: ChartType) => void;
}

const UserListItem: FC<{ accessId: string }> = ({ accessId }) => {
  const users = useUsersFromAccessId(accessId);
  const { pageId } = useContext(DocViewContext);
  const pageGroups = useAppSelector((s) => selectPageGroupsByPage(s, pageId));

  if (isGroupAccessId(accessId)) {
    const groupId = accessId.replace("group-", "");
    const group = pageGroups.find((g) => g.id === groupId);
    const groupIndex = pageGroups.findIndex((g) => g.id === groupId);
    return (
      <li>
        {group ? t("common.group_title", { number: groupIndex + 1 }) : t("user_info.invalid_user")}
      </li>
    );
  }

  return users ? (
    <li>{users.map(getUsername).join(", ")}</li>
  ) : (
    <li>{t("user_info.invalid_user")}</li>
  );
};

const ChartWrapper: FC<ChartWrapperProps> = ({
  children,
  choices,
  answersByChoice,
  percentage = true,
  showDropdown = true,
  chartType = "pie",
  onChartTypeChange,
}) => {
  const total = sum(choices.map(({ v: value }) => answersByChoice[value].length));
  return (
    <div className="flex flex-col items-start gap-2 rounded-lg border border-black-tint-93 bg-blue-tint-95 px-4 py-2">
      {showDropdown && total > 0 && (
        <div className="flex flex-row items-center gap-2">
          <div className="body-xs text-black-tint-20">
            {t("presentation_aggregates.display_as")}
          </div>
          <DropdownMenu
            items={[
              { id: "pie", title: t("presentation_aggregates.pie_chart") },
              { id: "bar", title: t("presentation_aggregates.bar_chart") },
            ]}
            selectedValue={chartType}
            onSelectionChange={(item) => onChartTypeChange(item.id as ChartType)}
          />
        </div>
      )}

      <div className="flex flex-col items-start gap-6 md:flex-row">
        {total > 0 && (
          <div className="relative w-full min-w-[100px] max-w-[200px] md:w-1/4 md:min-w-[200px]">
            <div className="w-full">{children}</div>
          </div>
        )}

        <ol className="list-style-none w-full overflow-hidden p-0 md:w-auto">
          {choices.map(({ v: value, l: label }, index) => {
            const count = answersByChoice[value].length;
            return (
              <li className="mb-3 flex flex-col" key={value}>
                <div className="flex flex-row items-baseline gap-2">
                  <div
                    className="legend-color-circle mt-1.5"
                    style={{
                      backgroundColor: COLORS[index % COLORS.length],
                    }}
                  />
                  <div className="text-black-tint-70">
                    {isGroupAccessId(answersByChoice[value][0]) ? (
                      <PersonGroupIcon />
                    ) : (
                      <PersonSingleIcon />
                    )}
                  </div>

                  <div className="shrink-0 text-end text-base font-semibold text-black-tint-20">
                    {percentage
                      ? total > 0
                        ? `${Math.round((count / total) * 100)}%`
                        : "0%"
                      : count}
                  </div>

                  <div className="text-black-tint-20">–</div>

                  <div
                    className={clsx("break-words text-base font-medium", {
                      "text-black-tint-70": count === 0,
                      "text-black-tint-20": count > 0,
                    })}
                  >
                    {label}
                  </div>
                </div>

                <ul className="list-style-none comma-separated-list flex flex-wrap gap-1 p-0 text-sm font-medium text-black-tint-40">
                  {answersByChoice[value].map((accessId) => (
                    <UserListItem accessId={accessId} key={accessId} />
                  ))}
                </ul>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
};

export const ChoiceFieldAggregate: FC<ChoiceFieldAggregateProps> = ({
  doc,
  fieldPath,
  page,
  c: choices,
  m: multiple,
}) => {
  const { id: fieldId, ct: defaultChartType } = get(doc.data, fieldPath) as ChoiceFieldType;
  const [chartType, setChartType] = useState<ChartType>(defaultChartType || "pie");

  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const { isProjecting } = useContext(DocViewContext);

  useEffect(() => {
    // We could always add student checks in here or a presentation check if
    // we want to change chart type for more than just the projector view on
    // presenter change.
    if (isProjecting) {
      setChartType(defaultChartType);
    }
  }, [defaultChartType, isProjecting]);

  const onChartTypeChange = (newChartType: ChartType) => {
    setChartType(newChartType);

    if (canAuthorCourse) {
      // Save the chart type to the worksheet
      doc.submitOp([{ p: [...fieldPath, "ct"], od: chartType, oi: newChartType }]);
    }
  };

  // Restructure document data to be keyed by student ID
  const answersByUsers = Object.entries(doc.data[DATA_PATH]).reduce((memo, [accessId, value]) => {
    if (canRollupAccessId(accessId) && isValidAccessIdForPageType(page, accessId)) {
      // eslint-disable-next-line no-param-reassign
      memo[accessId] = value[fieldId] as MultipleChoiceCellType;
    }
    return memo;
  }, {} as AnswersByStudent);

  // Initialize an object with empty arrays for each choice
  const emptyAnswersByChoice = choices.reduce((memo, { v: value }) => {
    // eslint-disable-next-line no-param-reassign
    memo[value] = [];
    return memo;
  }, {} as AnswersByChoice);

  // Populate the object with student IDs for each choice
  const answersByChoice = Object.entries(answersByUsers).reduce(
    (memo, [accessId, answerChoices]) => {
      (answerChoices || [])
        .filter((c) => c !== null)
        .forEach((choice) => {
          const selectedChoice = memo[choice.toString()];
          // Check if the choice is valid; the instructor could have deleted it
          // after the user selected it
          if (selectedChoice) {
            selectedChoice.push(accessId);
          }
        });
      return memo;
    },
    emptyAnswersByChoice,
  );

  const options: ChartOptions = {
    plugins: {
      legend: {
        // We will display our own legend in React
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const barData: ChartData<"bar"> = {
    labels: choices.map(({ l: label }) => label),
    datasets: [
      {
        data: choices.map(({ v: value }) => answersByChoice[value].length),
        backgroundColor: COLORS,
        minBarLength: 1,
      },
    ],
  };

  const barOptions: ChartOptions<"bar"> = {
    ...options,
    indexAxis: "y",
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
  };

  if (multiple) {
    // Multiple-response questions cannot be displayed as a pie chart
    return (
      <ChartWrapper
        answersByChoice={answersByChoice}
        choices={choices}
        percentage={false}
        showDropdown={false}
      >
        <Bar data={barData} options={barOptions} />
      </ChartWrapper>
    );
  }

  if (chartType === "bar") {
    return (
      <ChartWrapper
        answersByChoice={answersByChoice}
        chartType={chartType}
        choices={choices}
        percentage
        onChartTypeChange={onChartTypeChange}
      >
        <Bar data={barData} options={barOptions} />
      </ChartWrapper>
    );
  }

  const chartData: ChartData<"doughnut"> = {
    labels: choices.map(({ l: label }) => label),
    datasets: [
      {
        data: choices.map(({ v: value }) => answersByChoice[value].length),
        backgroundColor: COLORS,
      },
    ],
  };
  return (
    <ChartWrapper
      answersByChoice={answersByChoice}
      chartType={chartType}
      choices={choices}
      percentage
      onChartTypeChange={onChartTypeChange}
    >
      <Doughnut data={chartData} options={options as ChartOptions<"doughnut">} />
    </ChartWrapper>
  );
};
