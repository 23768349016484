import { configureStore } from "@reduxjs/toolkit";
// eslint-disable-next-line no-restricted-imports
import { type TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { EmptyModel, createPubsubSlice } from "./pubsub-slice";
import { AnalyticsStore } from "./slices/analytics";
import { AuthStore } from "./slices/auth";
import { ComputedStore } from "./slices/computed";
import { LocalStore } from "./slices/local";
import { ViewStore } from "./slices/view";

import { avSliceReducer } from "av/store/av_state";
import {
  ActivityListFilterType,
  ActivityType,
  AssessmentListFilterType,
  AssessmentType,
  ClassSessionType,
  CourseCodeType,
  CourseOutcomeType,
  CourseType,
  CourseUserType,
  FullAssessmentType,
  OrgType,
  OrgUserListFilterType,
  OrgUserType,
  OutcomeAssessmentListFilterType,
  OutcomeAssessmentType,
  OutcomeGroupType,
  OutcomeSubgroupType,
  OutcomeType,
  PageExtendedInsertType,
  PageGroupListFilterType,
  PageGroupType,
  PageGroupUserListFilterType,
  PageGroupUserType,
  PageListFilterType,
  PageOutcomeType,
  PageOutcomesListFilterType,
  PageType,
  SubmissionListFilterType,
  SubmissionType,
  TermType,
  TopicExtendedInsertType,
  TopicType,
  UserType,
} from "components/server-types";

/// Pubsub Model Slices - Keep in sync with backend models ///
export const slices = {
  courses: createPubsubSlice<CourseType>("material", "courses"),
  course_users: createPubsubSlice<CourseUserType>("material", "course_users"),
  class_sessions: createPubsubSlice<ClassSessionType>("material", "class_sessions"),
  users: createPubsubSlice<UserType>("auth", "users"),
  topics: createPubsubSlice<TopicType, EmptyModel, TopicExtendedInsertType>("material", "topics"),
  activities: createPubsubSlice<ActivityType, ActivityListFilterType>("material", "activities"),
  pages: createPubsubSlice<PageType, PageListFilterType, PageExtendedInsertType>(
    "material",
    "pages",
  ),
  terms: createPubsubSlice<TermType>("material", "terms"),
  course_codes: createPubsubSlice<CourseCodeType>("material", "course_codes"),
  orgs: createPubsubSlice<OrgType>("orgs", "orgs"),
  org_users: createPubsubSlice<OrgUserType, OrgUserListFilterType>("orgs", "org_users"),
  outcomes: createPubsubSlice<OutcomeType>("material", "outcomes"),
  outcome_subgroups: createPubsubSlice<OutcomeSubgroupType>("material", "outcome_subgroups"),
  outcome_groups: createPubsubSlice<OutcomeGroupType>("material", "outcome_groups"),
  course_outcomes: createPubsubSlice<CourseOutcomeType>("material", "course_outcomes"),
  page_outcomes: createPubsubSlice<PageOutcomeType, PageOutcomesListFilterType>(
    "material",
    "page_outcomes",
  ),
  page_groups: createPubsubSlice<PageGroupType, PageGroupListFilterType>("material", "page_groups"),
  page_group_users: createPubsubSlice<PageGroupUserType, PageGroupUserListFilterType>(
    "material",
    "page_group_users",
  ),
  outcome_assessments: createPubsubSlice<OutcomeAssessmentType, OutcomeAssessmentListFilterType>(
    "material",
    "outcome_assessments",
  ),
  assessments: createPubsubSlice<AssessmentType, AssessmentListFilterType, FullAssessmentType>(
    "material",
    "assessments",
  ),
  submissions: createPubsubSlice<SubmissionType, SubmissionListFilterType>(
    "material",
    "submissions",
  ),
};

export const createStore = (initialState?: unknown) =>
  configureStore({
    reducer: {
      av: avSliceReducer,
      auth: AuthStore.reducer,
      view: ViewStore.reducer,
      local: LocalStore.reducer,
      analytics: AnalyticsStore.reducer,
      computed: ComputedStore.reducer,

      /// Pubsub Model Slices - Keep in sync with backend models ///
      assessments: slices.assessments.slice.reducer,
      courses: slices.courses.slice.reducer,
      course_users: slices.course_users.slice.reducer,
      class_sessions: slices.class_sessions.slice.reducer,
      users: slices.users.slice.reducer,
      topics: slices.topics.slice.reducer,
      activities: slices.activities.slice.reducer,
      pages: slices.pages.slice.reducer,
      terms: slices.terms.slice.reducer,
      course_codes: slices.course_codes.slice.reducer,
      orgs: slices.orgs.slice.reducer,
      org_users: slices.org_users.slice.reducer,
      outcomes: slices.outcomes.slice.reducer,
      outcome_subgroups: slices.outcome_subgroups.slice.reducer,
      outcome_groups: slices.outcome_groups.slice.reducer,
      outcome_assessments: slices.outcome_assessments.slice.reducer,
      course_outcomes: slices.course_outcomes.slice.reducer,
      page_outcomes: slices.page_outcomes.slice.reducer,
      page_groups: slices.page_groups.slice.reducer,
      page_group_users: slices.page_group_users.slice.reducer,
      submissions: slices.submissions.slice.reducer,
    },
    preloadedState: initialState,
  });

export const store = createStore();

/// Pubsub Model APIs - Keep in sync with backend models ///
Object.values(slices).forEach((slice) => slice.addListener(store.dispatch));
export const storeApi = {
  assessments: slices.assessments.getApi(store.dispatch),
  courses: slices.courses.getApi(store.dispatch),
  course_users: slices.course_users.getApi(store.dispatch),
  class_sessions: slices.class_sessions.getApi(store.dispatch),
  users: slices.users.getApi(store.dispatch),
  topics: slices.topics.getApi(store.dispatch),
  activities: slices.activities.getApi(store.dispatch),
  pages: slices.pages.getApi(store.dispatch),
  terms: slices.terms.getApi(store.dispatch),
  course_codes: slices.course_codes.getApi(store.dispatch),
  orgs: slices.orgs.getApi(store.dispatch),
  org_users: slices.org_users.getApi(store.dispatch),
  outcomes: slices.outcomes.getApi(store.dispatch),
  outcome_subgroups: slices.outcome_subgroups.getApi(store.dispatch),
  outcome_groups: slices.outcome_groups.getApi(store.dispatch),
  outcome_assessments: slices.outcome_assessments.getApi(store.dispatch),
  course_outcomes: slices.course_outcomes.getApi(store.dispatch),
  page_outcomes: slices.page_outcomes.getApi(store.dispatch),
  page_groups: slices.page_groups.getApi(store.dispatch),
  page_group_users: slices.page_group_users.getApi(store.dispatch),
  submissions: slices.submissions.getApi(store.dispatch),
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
// (window as any).replaceAll = (state) => store.dispatch(replaceAllAction(state));
