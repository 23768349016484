import { useEffect } from "react";

import { isGroupingCategory } from "components/materials/page/groups/helpers";
import { GroupingCategory } from "components/server-types";
import { storeApi, useAppSelector } from "store/index";
import { selectCurrentCourse } from "store/selectors";

export const useFetchGroupData = (pageId: string, groupingCategory: GroupingCategory) => {
  const course = useAppSelector(selectCurrentCourse);
  const courseId = course?.id;

  useEffect(() => {
    if (!pageId || !courseId) {
      return;
    }

    storeApi.submissions.list({ page_id: pageId });
    storeApi.page_outcomes.list({ page_id: pageId });

    if (!isGroupingCategory(groupingCategory)) {
      storeApi.page_groups.list({ page_id: pageId });
      storeApi.page_group_users.list({ page_group__page_id: pageId });
    } else if (groupingCategory === GroupingCategory.INDIVIDUAL) {
      storeApi.course_users.list({ course_id: courseId });
    }
  }, [groupingCategory, pageId, courseId]);
};
