import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const ContentBlockIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M5.66667 1.93335H1V6.60002H5.66667V1.93335Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M8.4668 2.8667H15.0001"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M8.4668 6.60004H15.0001"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path className="stroke" d="M1 10.3334H15" strokeLinecap="round" strokeLinejoin="round" />
    <path className="stroke" d="M1 14.0667H15" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
