import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";

import { toastLocalizedOperationError } from "./alerts";
import { rollbarAndLogError } from "./logger";

import { DraggableItemType } from "mds/components/DragAndDrop";

const DEFAULT_ORDER_INCREMENT_AMOUNT = 2; // 2 because we halve it when reordering

export const useLocalCopy = <T>(collection: T): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [localCollection, setLocalCollection] = useState<T>(collection);
  useEffect(() => {
    // If the collection references changes this causes an infinite loop
    // So we do the deep equality check to avoid that
    if (!isEqual(collection, localCollection)) {
      setLocalCollection(collection);
    }

    // We don't want to change this back when the local collection changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection]);

  return [localCollection, setLocalCollection];
};

/**
 * Insert an item into a collection at a specific index by updating the order
 * of the item according to the surrounding items, and return the newly sorted collection.
 *
 * See OrderedModel on the backend for more details on our ordering system and choice of
 * algorithm and data types.
 */
export const reorderItemInCollection = <T extends DraggableItemType>(
  itemToMove: T,
  collection: T[],
  newIndex: number,
) => {
  // Ensure the item is not in the collection yet
  const newCollection = collection.filter((item) => item.id !== itemToMove.id);

  const beforeOrder =
    newCollection[newIndex - 1]?.order ||
    (newCollection.length && newCollection[0].order - DEFAULT_ORDER_INCREMENT_AMOUNT) ||
    0;
  const afterOrder = newCollection[newIndex]?.order || beforeOrder + DEFAULT_ORDER_INCREMENT_AMOUNT;

  const newOrder = (beforeOrder + afterOrder) / 2;

  const hasCollision = newOrder === beforeOrder || newOrder === afterOrder;
  if (hasCollision) {
    const error = new Error("New order is the same as the adjacent item. Float precision limit?");
    rollbarAndLogError(error);
    toastLocalizedOperationError("failed_to_reorder_item");
    return newCollection;
  }
  // eslint-disable-next-line no-param-reassign
  itemToMove.order = newOrder;

  newCollection.push(itemToMove);
  newCollection.sort((a, b) => a.order - b.order);
  return newCollection;
};

export const determineOrderForNewAppend = <T extends DraggableItemType>(collection: T[]) => {
  return (collection[collection.length - 1]?.order || 0) + 1;
};

/**
 * This function is used to change the order of an item in a list of draggable items.
 * It's specific to our Drag and Drop implementation and may need some refactoring
 * to be useful it that code changes
 *
 * @param typeAttribute If we move an item between categories, we need to know which
 * attribute identifies the item as belonging to a category, so we can update it.
 */
export const useDnDItemOrdering = <T extends DraggableItemType>(
  itemCollection: T[],
  setFunction: (newCollection: T[]) => void,
  typeAttribute?: string,
) => {
  return useCallback(
    (item: T, toIndex: number, dropContainerId: string | number) => {
      const itemIndex = itemCollection.findIndex((ci) => ci.id === item.id);
      if (itemIndex === toIndex) {
        return;
      }

      if (typeAttribute) {
        // eslint-disable-next-line no-param-reassign
        item[typeAttribute] = dropContainerId;
      }

      const newCollection = reorderItemInCollection(item, itemCollection, toIndex);
      setFunction(newCollection);
    },
    [itemCollection, setFunction, typeAttribute],
  );
};
