import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const TrashDeleteIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M2.8667 5.66669L3.67403 13.7391C3.70851 14.0845 3.87009 14.4048 4.12741 14.6378C4.38473 14.8708 4.71943 14.9999 5.06657 15H10.9335C11.2806 14.9999 11.6153 14.8708 11.8727 14.6378C12.13 14.4048 12.2916 14.0845 12.326 13.7391L13.1334 5.66669"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path className="stroke" d="M1 3.79999H15" strokeLinecap="round" strokeLinejoin="round" />
    <path
      className="stroke"
      d="M5.6665 3.8V1H10.3332V3.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
