import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const DotMenuHorizontalIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="fill"
      d="M8 9.5C8.82843 9.5 9.5 8.82843 9.5 8C9.5 7.17157 8.82843 6.5 8 6.5C7.17157 6.5 6.5 7.17157 6.5 8C6.5 8.82843 7.17157 9.5 8 9.5Z"
    />
    <path
      className="fill stroke"
      d="M4.5 8C4.5 8.55228 4.05228 9 3.5 9C2.94772 9 2.5 8.55228 2.5 8C2.5 7.44772 2.94772 7 3.5 7C4.05228 7 4.5 7.44772 4.5 8Z"
    />
    <path
      className="fill stroke"
      d="M13.5 8C13.5 8.55228 13.0523 9 12.5 9C11.9477 9 11.5 8.55228 11.5 8C11.5 7.44772 11.9477 7 12.5 7C13.0523 7 13.5 7.44772 13.5 8Z"
    />
  </svg>
);
