import { FC, useState } from "react";

import { ContentNavigationButtons } from "../ContentNavigationButtons";

import { CourseCodeView } from "./course-codes/CourseCodeView";
import { TermsView } from "./terms/TermsView";

import { OrgOutcomesView } from "components/materials/org/outcomes/OrgOutcomesView";
import { t } from "i18n/i18n";
import { IconText } from "mds/components/IconText";
import { TabBar } from "mds/components/TabBar";
import { CalendarIcon, OutcomeHashtagIcon, TextBooksIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import { selectCurrentOrg } from "store/selectors";

export const OrgView: FC = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const tabContent = tabIndex === 0 ? "course" : tabIndex === 1 ? "term" : "outcome";
  const org = useAppSelector(selectCurrentOrg);

  const tabs = [
    {
      label: <IconText iconStart={<TextBooksIcon />} text={t("org.tabs.catalog")} />,
      title: t("org.tabs.catalog"),
      panel: <CourseCodeView />,
    },
    {
      label: <IconText iconStart={<CalendarIcon />} text={t("org.tabs.terms")} />,
      title: t("org.tabs.terms"),
      panel: <TermsView />,
    },
    {
      label: <IconText iconStart={<OutcomeHashtagIcon />} text={t("glossary.outcome_index")} />,
      title: t("glossary.outcomes"),
      panel: <OrgOutcomesView />,
    },
  ];

  return (
    <div>
      <div className="flex w-full items-center justify-between text-black-tint-40">
        <h2 className="flex items-center gap-2 text-base text-black-tint-20">
          <div title={t("glossary.org_title")}>{org?.name}</div>
        </h2>
      </div>
      <TabBar
        selectedIndex={tabIndex}
        tabListClassName="ml-2"
        tabListEnd={<ContentNavigationButtons className="p-2" reference={tabContent} />}
        tabs={tabs}
        onChange={setTabIndex}
      />
    </div>
  );
};
