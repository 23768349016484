import { FC } from "react";
import { useSearchParams } from "react-router-dom";

import { useNameFromPageAccessId } from "../../page/helpers";

import { ACCESS_ID_QUERY_PARAM } from "components/constants";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { Tooltip } from "mds/components/Tooltip";
import { CircleInfoIcon } from "mds/icons";
import { FeatureStatus, usePresentation } from "utils/presentation";

export type FeatureStatusOptionsProps = {
  onPresentRollup: () => void;
};

export type FeaturedParticipantProps = {
  pageId: string;
  accessId: string;
  featureStatus: FeatureStatus;
};

const FeaturedParticipant: FC<FeaturedParticipantProps> = ({ pageId, accessId, featureStatus }) => {
  const participantName = useNameFromPageAccessId(accessId, pageId);

  if (featureStatus === FeatureStatus.PARTICIPANT_RESPONSE_WITH_BLOCK) {
    return (
      <div className="body-s flex items-center gap-1">
        {t("main_header.feature_status.status.participant_response_with_block", {
          name: participantName,
        })}
        <Tooltip
          element={<CircleInfoIcon className="icon-white" height="16" width="16" />}
          place="left"
        >
          {t("main_header.feature_status.tooltip.featured_response")}
        </Tooltip>
      </div>
    );
  }

  return t(`main_header.feature_status.status.${featureStatus}`, { name: participantName });
};

export type UnfeaturedOptionsProps = FeatureStatusOptionsProps & {
  pageId: string;
};

const UnfeaturedOptions: FC<UnfeaturedOptionsProps> = ({ pageId, onPresentRollup }) => {
  const [searchParams] = useSearchParams();
  const selectedAccessId = searchParams.get(ACCESS_ID_QUERY_PARAM);
  const participantName = useNameFromPageAccessId(selectedAccessId, pageId);

  const name = participantName || t("common.all");

  return (
    <div className="presenting body-s flex items-center gap-2">
      {t(`main_header.feature_status.not_featured`)}

      <Button color="orange" kind="primary" size="xs" onClick={onPresentRollup}>
        {t("main_header.feature_status.feature_button_text", { name })}
      </Button>
    </div>
  );
};

export const FeatureStatusOptions: FC<FeatureStatusOptionsProps> = ({ onPresentRollup }) => {
  const {
    featureStatus,
    presentedPageId,
    isPracticing,
    presentedAccessId,
    hasProjectorView,
    unfeaturedTab,
  } = usePresentation();

  const tooltipKey = hasProjectorView ? "projecting" : isPracticing ? "practicing" : "presenting";

  const content =
    featureStatus === FeatureStatus.NONE ? (
      <Tooltip
        element={<CircleInfoIcon className="icon-white" height="16" width="16" />}
        place="left"
      >
        {t(`main_header.feature_status.tooltip.${tooltipKey}`)}
      </Tooltip>
    ) : (
      t(`main_header.feature_status.status.${featureStatus}`)
    );

  if (unfeaturedTab) {
    return <UnfeaturedOptions pageId={presentedPageId} onPresentRollup={onPresentRollup} />;
  }

  return (
    <div className="presenting body-s flex items-center gap-1">
      {presentedAccessId ? (
        <FeaturedParticipant
          accessId={presentedAccessId}
          featureStatus={featureStatus}
          pageId={presentedPageId}
        />
      ) : (
        <div className="flex items-center gap-1">{content}</div>
      )}
    </div>
  );
};
