import "./MainHeader.scss";

import clsx from "clsx";
import { FC, type ReactNode, useEffect } from "react";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";

import { FollowButton } from "../materials/presentation/FollowButton";

import { EditModeButton } from "./EditModeButton";

import {
  LOGO_HEADER_SIZE,
  MOBILE_OVERLAY_ROUTES,
  MOBILE_OVERLAY_ROUTE_QUERY_PARAM,
} from "components/constants";
import { PresentationOptions } from "components/materials/presentation/PresentationOptions";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { IconText } from "mds/components/IconText";
import { useIsLgOrLarger, useIsMdOrLarger } from "mds/hooks/use-responsive";
import {
  BurgerMenuIcon,
  ChevronLeftIcon,
  CrossRemoveIcon,
  ForumLogoIcon,
  PresentPlayIcon,
} from "mds/icons";
import { storeApi, useAppSelector } from "store/index";
import {
  selectCanAuthorCourse,
  selectCurrentCourse,
  selectIsAssessing,
  selectPageById,
} from "store/selectors";
import { usePresentation } from "utils/presentation";
import {
  courseContentUrl,
  courseHomeUrl,
  coursePageUrl,
  getCourseViewVariantFromPath,
} from "utils/urls";

/**
 * This is an alternative for the MainHeader component to be displayed on small screens.
 * It has a tiny subset of the tablet/desktop functionality, mainly removing all the
 * assessments, editing and instructor controls, breadcrumbs, and connection status,
 * but adding in mobile-related navigation functionality and menus.
 */
export const MobileMainHeader: FC = () => {
  const course = useAppSelector(selectCurrentCourse);
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedPageId = searchParams.get("page");
  const selectedPage = useAppSelector((s) => selectPageById(s, selectedPageId));

  const params = useParams();
  const selectedTopicId = params.topicId;
  const isMobileMainMenuOpen =
    searchParams.get(MOBILE_OVERLAY_ROUTE_QUERY_PARAM) === MOBILE_OVERLAY_ROUTES[0];
  const isAssessing = useAppSelector(selectIsAssessing);

  const toggleMobileMainMenu = () => {
    const newParams = new URLSearchParams(searchParams);
    if (!isMobileMainMenuOpen) {
      newParams.set(MOBILE_OVERLAY_ROUTE_QUERY_PARAM, MOBILE_OVERLAY_ROUTES[0]);
    } else {
      newParams.delete(MOBILE_OVERLAY_ROUTE_QUERY_PARAM);
    }
    setSearchParams(newParams);
  };

  const isSmallScreen = !useIsMdOrLarger();
  const isLargeScreen = useIsLgOrLarger();
  const { pathname } = useLocation();
  const courseViewVariant = getCourseViewVariantFromPath(pathname);

  const { isFollowing, presentedPageId, isPresentingUser } = usePresentation();

  useEffect(() => {
    storeApi.org_users.list();
  }, []);

  if (isLargeScreen) {
    throw new Error("MainHeader.tsx should be used for large screens.");
  }

  if (presentedPageId && isFollowing) {
    // If the user is following the presentation, we ONLY show the "following presentation" header
    return (
      <header className="flex h-[--navbar-height] w-full justify-center border-b border-b-score-3 bg-score-light-3 py-2">
        <div className="flex w-full max-w-[--page-default-max-width] items-center justify-between text-sm">
          <div className="flex items-center">
            <PresentPlayIcon className="icon-green mx-4" />
            {t("main_header.following_presentation")}
          </div>
          <Link
            aria-label={t("common.close")}
            className="p-2"
            title={t("tooltip.close_topic")}
            to={courseHomeUrl(course.id)}
          >
            <CrossRemoveIcon />
          </Link>
        </div>
      </header>
    );
  }

  function backTextAndUrl(): [string, string] {
    const isTopic = courseViewVariant === "topic";
    const isCoursePage = courseViewVariant === "course_page";
    const coursePageCategory = searchParams.get("category");
    const isAssignment = isCoursePage && coursePageCategory === "assignment";
    const isInstructorWorkspace = isCoursePage && coursePageCategory === "instructor_workspace";

    // TODO: Whether we go back to "all assignments" or directly back to "home" should depend
    // on how we got there in the first place (should act similar to a back button but not affect query params).
    // This also applies to course resources, topics, etc.
    if (isAssignment && selectedPageId) {
      return [t("main_header.back_to_assignments"), coursePageUrl(course.id, "assignment")];
    }
    if (isInstructorWorkspace && selectedPageId) {
      return [t("main_header.back_to_resources"), coursePageUrl(course.id, "instructor_workspace")];
    }
    if (isCoursePage && selectedPageId) {
      return [t("main_header.back_to_resources"), coursePageUrl(course.id, "course_resource")];
    }
    if (isTopic && selectedTopicId && selectedPageId) {
      return [t("main_header.back_to_topic"), courseContentUrl(course.id, selectedTopicId)];
    }

    return [t("main_header.back_to_home"), courseHomeUrl(course.id)];
  }

  const [backText, urlWithoutPage] = backTextAndUrl();

  return (
    <header
      className={clsx("main-navbar flex items-center justify-between bg-white text-black-tint-20")}
    >
      <div className="flex w-full justify-between">
        {courseViewVariant === "main" || isMobileMainMenuOpen ? (
          <div className="logo flex items-center gap-2">
            <Link
              aria-label="Forum"
              className="flex flex-shrink-0 cursor-pointer items-center border-0 text-black lg:border"
              to={courseHomeUrl(course?.id)}
            >
              <ForumLogoIcon width={LOGO_HEADER_SIZE} />
            </Link>
          </div>
        ) : (
          <div className="flex items-center justify-between truncate">
            <Link className="truncate" to={urlWithoutPage}>
              <IconText
                iconStart={<ChevronLeftIcon />}
                text={<span className="truncate">{backText}</span>}
              />
            </Link>
          </div>
        )}

        <div className="flex items-center gap-2">
          {/* Only do this for medium screens */}
          {(!isAssessing || !selectedPage) && !isSmallScreen && (
            <>
              <PresentationOptions
                courseViewVariant={courseViewVariant}
                selectedPage={selectedPage}
              />

              {["topic", "course_page"].includes(courseViewVariant) && canAuthorCourse && (
                <EditModeButton />
              )}
            </>
          )}

          <Button
            className="icon-black ml-2"
            kind="tertiary"
            title={t("main_nav.toggle_mobile_main_menu")}
            iconOnly
            onClick={toggleMobileMainMenu}
          >
            {isMobileMainMenuOpen ? <CrossRemoveIcon /> : <BurgerMenuIcon />}
          </Button>
        </div>
      </div>

      <MobileScreenBanners>
        {presentedPageId && !isFollowing && !isPresentingUser && (
          <div className="flex flex-col items-center justify-center">
            <div className="flex w-full max-w-[--page-default-max-width] items-center justify-between rounded-lg bg-score-3 p-2 text-white md:-ml-4">
              <IconText
                iconStart={<PresentPlayIcon className="icon-white mx-2" />}
                text={t("main_header.not_following_presentation")}
              />
              <FollowButton pageId={presentedPageId} />
            </div>
          </div>
        )}
      </MobileScreenBanners>
    </header>
  );
};

interface MobileScreenBannersProps {
  children?: ReactNode;
}

const MobileScreenBanners: FC<MobileScreenBannersProps> = ({ children }) => {
  if (!children) return null;

  return <div className="fixed bottom-2 left-2 right-2 z-10">{children}</div>;
};
