import { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useResponsesForPage } from "./helpers";

import { ACCESS_ID_QUERY_PARAM, SUBMISSION_ID_QUERY_PARAM } from "components/constants";
import { ResponseType } from "components/server-types";
import { DropdownMenu } from "mds/components/DropdownMenu";
import { PersonGroupIcon, PresentPlayOutlineIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import { selectPageById } from "store/selectors";
import { useFindItemBySearchParamId } from "utils/hooks/use-find-item-by-search-param-id";
import { usePresentation } from "utils/presentation";

type AllResponsesDropdownProps = {
  activelyPresented: boolean;
  pageId: string;
};

// TODO: Look at combining this with the StudentDropdown component
export const AllResponsesDropdown: FC<AllResponsesDropdownProps> = ({
  activelyPresented,
  pageId,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = useAppSelector((s) => selectPageById(s, pageId));
  const responses = useResponsesForPage(page);
  const { presentedAccessId, presentingRollup, isFollowing, presentedPageId } = usePresentation();
  const pageIsPresenting = presentedPageId === page?.id;

  const response = useFindItemBySearchParamId(responses, ACCESS_ID_QUERY_PARAM, true);

  // If we are following a user response and the presenter
  // changes it we need to update the selected response we are seeing
  useEffect(() => {
    if (presentingRollup && pageIsPresenting && isFollowing) {
      const accessId = presentedAccessId || "";
      if (accessId) {
        searchParams.set(ACCESS_ID_QUERY_PARAM, accessId);
      } else {
        searchParams.delete(ACCESS_ID_QUERY_PARAM);
      }

      // Clear submission id if it's not for the selected response
      searchParams.delete(SUBMISSION_ID_QUERY_PARAM);

      setSearchParams(searchParams);
    }
  }, [
    presentedAccessId,
    pageIsPresenting,
    presentingRollup,
    isFollowing,
    searchParams,
    setSearchParams,
  ]);

  const onSelectionChange = (item: ResponseType) => {
    if (item.id) {
      searchParams.set(ACCESS_ID_QUERY_PARAM, item.id);
    } else {
      searchParams.delete(ACCESS_ID_QUERY_PARAM);
    }

    // Clear submission id if it's not for the selected response
    searchParams.delete(SUBMISSION_ID_QUERY_PARAM);

    setSearchParams(searchParams);
  };

  return (
    <div className="flex items-center">
      {activelyPresented ? <PresentPlayOutlineIcon /> : <PersonGroupIcon />}

      <DropdownMenu
        className="responses-dropdown"
        items={responses}
        kind="tertiary"
        menuProps={{
          position: "initial",
        }}
        selectedValue={response?.id}
        size="s"
        truncateWidth="m"
        onSelectionChange={(item) => onSelectionChange(item)}
      />
    </div>
  );
};
