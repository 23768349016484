import { type FC, useState } from "react";

import { GROUP_TYPE_CHOICES, GroupChoiceType } from "components/materials/page/groups/helpers";
import { CancelSaveMenu } from "components/menus/CancelSaveMenu";
import { GroupingCategory, PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { RadioButton } from "mds/components/RadioButton";
import { ChevronDownIcon, GearSettingsIcon } from "mds/icons";
import { storeApi } from "store/index";
import { NestedPageGroupType } from "store/selectors";

interface GroupCategoryConfigProps {
  page: PageType;
  pageGroups: NestedPageGroupType[];
  isDisabled?: boolean;
  isReadOnly?: boolean;
  title: string;
}

type GroupGroupingCategory = GroupingCategory.ASSIGN_STUDENTS | GroupingCategory.SELF_ASSIGN;

export const GroupCategoryConfig: FC<GroupCategoryConfigProps> = ({
  page,
  pageGroups,
  isDisabled,
  isReadOnly,
  title,
}) => {
  const groupingCategory = page.grouping_category as GroupGroupingCategory;
  const [currentGroupingCategory, setCurrentGroupingCategory] =
    useState<GroupGroupingCategory>(groupingCategory);

  const onSave = () => {
    storeApi.pages.partial_update({ id: page.id, grouping_category: currentGroupingCategory });

    // When switching group mode to "ASSIGN_STUDENTS", mark all staged students
    // (who may be in this state because of student self-select) as ready.
    if (currentGroupingCategory === GroupingCategory.ASSIGN_STUDENTS) {
      pageGroups.forEach((pageGroup) => {
        pageGroup.page_group_users.forEach((pageGroupUser) => {
          storeApi.page_group_users // TODO: Bulk update
            .partial_update({
              id: pageGroupUser.id,
              is_ready: true,
            });
        });
      });
    }
  };

  return (
    <CancelSaveMenu
      button={
        <Button
          className="gap-1"
          disabled={isDisabled || isReadOnly}
          kind="secondary"
          size="xs"
          title={title}
        >
          <GearSettingsIcon />
          {t(
            `group_configuration_card.group_type.${
              page.grouping_category as GroupGroupingCategory
            }`,
          )}
          {!isReadOnly && <ChevronDownIcon />}
        </Button>
      }
      title={t("group_configuration_card.config.category")}
      onCancel={() => setCurrentGroupingCategory(groupingCategory)}
      onSave={onSave}
    >
      <div className="flex flex-col gap-1">
        {GROUP_TYPE_CHOICES.map((choice) => {
          return (
            <RadioButton
              checked={currentGroupingCategory === choice}
              containerClassName="text-black-tint-20 body-s"
              id={choice}
              key={choice}
              name="pageGroupType"
              value={choice}
              onChange={(event) => {
                setCurrentGroupingCategory(
                  (event.target as HTMLInputElement).value as GroupGroupingCategory,
                );
              }}
            >
              {t(`group_configuration_card.choice.${choice as GroupChoiceType}`)}
            </RadioButton>
          );
        })}
      </div>
    </CancelSaveMenu>
  );
};
