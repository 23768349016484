import clsx from "clsx";
import React, { FC, useContext, useEffect } from "react";

import { HoverableToolbarContext } from "./HoverableToolbarProvider";

import { TextArea } from "mds/components/TextArea";
import { TextField } from "mds/components/TextField";
import { useAppSelector } from "store/index";
import { selectIsEditModeEnabled } from "store/selectors";

type EditableFieldProps = {
  multiline?: boolean;
  isEditing?: boolean;
  canDoubleClickToEdit?: boolean;
  shouldWrap?: boolean;
  className?: string;
  textSize?: "xs" | "s" | "m" | "h6" | "h4" | "h3" | "h2" | "h1";
  value: string;
  label?: string;
  validatorFn?: (value: string) => string[];
  onValueChanged?: (newValue: string) => void;
  onSetIsEditing?: (editing: boolean) => void;
  onDoubleClick?: () => void;
};

function isValidTextareaSize(size: string): size is "xs" | "s" | "m" {
  return ["xs", "s", "m"].includes(size);
}

/**
 * A component with a single editable field.
 */
export const EditableField: FC<EditableFieldProps> = ({
  value,
  shouldWrap = false,
  canDoubleClickToEdit = false,
  isEditing,
  className,
  label,
  multiline,
  textSize,
  validatorFn,
  onSetIsEditing,
  onValueChanged,
  onDoubleClick,
}) => {
  const textAreaRef = React.createRef<HTMLTextAreaElement>();

  const onTextFieldSave = (newVal: string) => {
    onSetIsEditing(false);

    const savedVal = newVal.trim();
    if (onValueChanged && savedVal !== value) {
      onValueChanged(newVal);
    }
  };

  const onTextAreaSave = () => {
    onSetIsEditing(false);

    const savedVal = (textAreaRef.current?.value ?? "").trim();
    if (onValueChanged && savedVal) {
      onValueChanged(savedVal);
    }
  };

  return (
    <>
      {multiline && (
        <TextArea
          aria-label={label}
          className={clsx("w-full", className, {
            "cursor-pointer": !isEditing && canDoubleClickToEdit,
          })}
          readOnly={!isEditing}
          ref={textAreaRef}
          size={isValidTextareaSize(textSize) ? textSize : undefined}
          value={value}
          autoFocus
          onBlur={() => isEditing && onTextAreaSave()}
          onDoubleClick={onDoubleClick}
        />
      )}

      {/*
       * Because we need the ability to wrap text in the editable field, we need to use a div
       * instead of a readonly input field.  This is because input types don't support wrapping.
       * To make this still seem like a textbox we add some aria attributes.
       */}
      {!multiline && !isEditing && (
        <div
          aria-label={label}
          className={clsx(
            "my-1 w-full",
            shouldWrap ? "break-words" : "truncate",
            className,
            textSize ? (textSize.startsWith("h") ? textSize : `body-${textSize}`) : undefined,
            { "cursor-pointer": canDoubleClickToEdit },
          )}
          role="textbox"
          tabIndex={0}
          aria-readonly
          onDoubleClick={onDoubleClick}
        >
          {value}
        </div>
      )}

      {!multiline && isEditing && (
        <div className="flex w-full items-center">
          <TextField
            aria-label={label}
            className={clsx("w-full", className, {
              "cursor-pointer": !isEditing && canDoubleClickToEdit,
            })}
            isEditing={isEditing}
            readOnly={!isEditing}
            size={textSize}
            validatorFn={validatorFn}
            value={value}
            autoFocus
            onDoubleClick={onDoubleClick}
            onSave={onTextFieldSave}
          />
        </div>
      )}
    </>
  );
};

export type HoverableEditFieldProps = Omit<
  EditableFieldProps,
  "isEditing" | "onSetIsEditing" | "onDoubleClick"
> & {
  disableUnlessEditMode?: boolean;
  editingOnStart?: boolean;
};

/*
 * This editable field is a variant where we want it to work with hoverable toolbar.
 * Originally the toolbar and the editable field where one component, but this meant
 * we needed to use the editable field even if we only wanted the toolbar on text
 * and required passing in pre and post elements to change how the field was displayed.
 * By breaking the field up we can style the text field how we want without the toolbar
 * having to determine the layout.  We now need, however, to use a context to manage the
 * editing state between the toolbar and this control.
 */
export const HoverableEditField: FC<HoverableEditFieldProps> = ({
  disableUnlessEditMode = false,
  editingOnStart,
  ...props
}) => {
  const { isEditing, setIsEditing } = useContext(HoverableToolbarContext);
  const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);

  const onDoubleClick = () => {
    if (disableUnlessEditMode && !isEditModeEnabled) return;
    setIsEditing(true);
  };

  useEffect(() => {
    if (editingOnStart) {
      setIsEditing(true);
    }
  }, [editingOnStart, setIsEditing]);

  return (
    <EditableField
      {...props}
      canDoubleClickToEdit={isEditModeEnabled || !disableUnlessEditMode}
      isEditing={isEditing}
      onDoubleClick={onDoubleClick}
      onSetIsEditing={setIsEditing}
    />
  );
};
