import { isCoursePage } from "./page";
import { courseContentUrl, coursePageUrl } from "./urls";

import { FOLLOWING_QUERY_PARAM } from "components/constants";
import { ActivityType, TopicType } from "components/server-types";
import { store, storeApi } from "store/index";

export const getUrlForPage = async (pageId: string, following = false) => {
  const state = store.getState();

  const topics = Object.values(state.topics);
  const activities = Object.values(state.activities);
  const pages = Object.values(state.pages);

  // If we haven't loaded everything return null;
  if (!pages.length) {
    return null;
  }

  const followingParam = following ? `&${FOLLOWING_QUERY_PARAM}=1` : "";
  let presentedPage = pages.find((r) => r.id === pageId);
  if (!presentedPage) {
    // This happens from a race condition where a new resource is created during presentation and we get the
    // presentation pubsub event before the page pubsub event or it can happen when the new presented page
    // is not part of this topic.  Retrieving the page should fix this.
    presentedPage = await storeApi.pages.retrieve(pageId, { skipToast: true });
    if (!presentedPage) {
      throw new Error(`Could not find page with id ${pageId}`);
    }
  }

  if (isCoursePage(presentedPage)) {
    return `${coursePageUrl(presentedPage.course_id, presentedPage.category, pageId)}${followingParam}`;
  }

  let presentedActivity = activities.find((a) => a.id === presentedPage.activity_id);
  if (!presentedActivity) {
    presentedActivity = await storeApi.activities
      .retrieve(presentedPage.activity_id, {
        skipToast: true,
      })
      .catch(() => null as ActivityType);
    if (!presentedActivity) {
      throw new Error(
        `Could not find activity with id ${presentedPage.activity_id} for page with id ${pageId}`,
      );
    }
  }

  let presentedTopic = topics.find((p) => p.id === presentedActivity?.topic_id);
  if (!presentedTopic) {
    presentedTopic = await storeApi.topics
      .retrieve(presentedActivity.topic_id, {
        skipToast: true,
      })
      .catch(() => null as TopicType);
    if (!presentedTopic) {
      throw new Error(
        `Could not find topic with id ${presentedActivity.topic_id} for activity with id ${presentedActivity.id}`,
      );
    }
  }

  return `${courseContentUrl(presentedPage.course_id, presentedTopic.id, pageId)}${followingParam}`;
};
