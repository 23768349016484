import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const CircleInfoBlueIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <g clipPath="url(#clip0_328_38740)">
      <path
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="#0A78BF"
        fillRule="evenodd"
      />
      <path
        d="M9.20188 7.04008L9.20194 7.29838L9.20184 12.9617C9.20184 13.1272 9.06445 13.2646 8.89822 13.2654L7.10172 13.2653C6.93549 13.2646 6.79847 13.1276 6.79847 12.9621L6.79851 9.70256L6.7985 7.03967C6.79888 6.87306 6.93552 6.73642 7.10175 6.73643L8.89825 6.73645C9.06448 6.73645 9.2015 6.87347 9.20188 7.04008Z"
        fill="#E7F2F9"
      />
      <circle cx="7.99023" cy="4.25" fill="#E7F2F9" r="1.25" />
    </g>
    <defs>
      <clipPath id="clip0_328_38740">
        <rect fill="white" height="16" width="16" />
      </clipPath>
    </defs>
  </svg>
);
