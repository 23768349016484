import type { FC, ReactNode } from "react";

import { ConfirmationDialog } from "./ConfirmationDialog";

import { t } from "i18n/i18n";
import type { GlossaryKey } from "i18n/i18next";
import { toastLocalizedOperationCatcher } from "utils/alerts";

type ConfirmDeletionDialogProps = {
  open: boolean;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDelete: () => Promise<any>;
  glossaryKey: GlossaryKey;
  instanceName: string;
  title?: string;
  description?: string;
  children?: ReactNode;
};

export const ConfirmDeletionDialog: FC<ConfirmDeletionDialogProps> = ({
  open,
  onClose,
  onDelete,
  glossaryKey,
  instanceName,
  children,
  title: titleProp,
  description,
}) => {
  const title =
    titleProp || t(`confirm_delete_modal.delete_item`, { item: t(`glossary.${glossaryKey}`) });
  const body = t(`confirm_delete_modal.delete_label`, { name: instanceName });

  return (
    <ConfirmationDialog
      confirmButtonKind="destructive"
      confirmButtonText={title}
      description={description}
      open={open}
      title={title}
      standardButtons
      onClose={onClose}
      onConfirm={() =>
        onDelete().catch(
          toastLocalizedOperationCatcher("delete_failure", { item: t(`glossary.${glossaryKey}`) }),
        )
      }
    >
      <div className="my-4">{body}</div>
      {children}
    </ConfirmationDialog>
  );
};
