import { type CourseVersionModelUpdateType } from "../worksheets/shared/types/pubsub";

type MessageCallback<T> = (message: T) => void;
const broadcastCallbacks = new Set<MessageCallback<CourseVersionModelUpdateType>>();

/**
 * Send a message to all listeners registered with addBroadcastListener.
 */
export const sendBroadcast = (message: CourseVersionModelUpdateType) => {
  broadcastCallbacks.forEach((cb) => cb(message));
};

/**
 * Register a callback to be called whenever we get a pubsub broadcast message.
 * The callback is responsible for determining whether the message is relevant.
 */
export const addBroadcastListener = (callback: MessageCallback<CourseVersionModelUpdateType>) => {
  broadcastCallbacks.add(callback);
  return () => {
    broadcastCallbacks.delete(callback);
  };
};
