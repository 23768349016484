import "./OutcomeGroupCard.scss";

import clsx from "clsx";
import type { FC } from "react";
import { useSearchParams } from "react-router-dom";

import { HoverableEditField } from "components/hover-widgets/EditableField";
import { HoverableToolbar } from "components/hover-widgets/HoverableToolbar";
import { OutcomeCount } from "components/materials/org/outcomes/OutcomeCount";
import { OutcomeGroupMenu } from "components/menus/OutcomeGroupMenu";
import { SidebarOptionsMenu } from "components/menus/SidebarOptionsMenu";
import { OutcomeType } from "components/server-types";
import { t } from "i18n/i18n";
import { Card } from "mds/components/Card";
import { ListItem } from "mds/components/ListItem";
import { TextAddButton } from "mds/components/TextAddButton";
import { storeApi, useAppSelector } from "store/index";
import { NestedOutcomeGroupType, selectCanAuthorOrg } from "store/selectors";
import { onTitleChanged } from "utils/store-utils";

type OutcomeGroupCardProps = {
  outcomeGroup: NestedOutcomeGroupType;
  queryParam: string;
  currentOutcome: OutcomeType;
};

export const OutcomeGroupCard: FC<OutcomeGroupCardProps> = ({
  outcomeGroup,
  queryParam,
  currentOutcome,
}) => {
  const canAuthorOrg = useAppSelector(selectCanAuthorOrg);
  const [searchParams, setSearchParams] = useSearchParams();

  const foundCurrentOutcome =
    currentOutcome?.outcome_group_id === outcomeGroup.id ||
    outcomeGroup.outcome_subgroups.find((os) => os.id === currentOutcome?.outcome_subgroup_id);

  const onOutcomeClick = (outcome: OutcomeType) => {
    searchParams.set(queryParam, outcome.id);
    setSearchParams(searchParams);
  };

  const onAddOutcome = (
    createOutcomeData: { outcome_subgroup_id: string } | { outcome_group_id: string },
  ) => {
    storeApi.outcomes.create(createOutcomeData).then((newOutcome) => {
      onOutcomeClick(newOutcome);
    });
  };

  const onDeleteOutcome = (outcomeId: string) => {
    storeApi.outcomes.destroy(outcomeId);
  };

  const onAddOutcomeSubgroup = (createOutcomeSubgroupData: { outcome_group_id: string }) => {
    storeApi.outcome_subgroups.create({
      ...createOutcomeSubgroupData,
      title: t("common.untitled.outcome_subgroup"),
    });
  };

  const onOutcomeGroupDelete = () => {
    storeApi.outcome_groups.destroy(outcomeGroup.id);
  };

  const onOutcomeSubgroupDelete = (outcomeSubgroupId: string) => {
    storeApi.outcome_subgroups.destroy(outcomeSubgroupId);
  };

  return (
    <Card className="outcome-group-card mb-3 mt-2" isActive={!!foundCurrentOutcome}>
      <div className="flex w-full items-center justify-between">
        <HoverableToolbar
          className="mb-1 ml-1 flex w-full items-center justify-between"
          readOnly={!canAuthorOrg}
          reference="outcome_group"
          showEditButton
        >
          <h3 className="my-0 w-full">
            <HoverableEditField
              className="w-full"
              label={t("glossary.outcome_group_title")}
              textSize="h3"
              value={outcomeGroup.title}
              shouldWrap
              onValueChanged={(newTitle) =>
                onTitleChanged("outcome_groups", outcomeGroup.id, newTitle)
              }
            />
          </h3>

          <div className="flex items-center">
            {outcomeGroup.outcomes?.length === 0 &&
              outcomeGroup.outcome_subgroups?.length === 0 && (
                <OutcomeGroupMenu
                  buttonKind="tertiary"
                  onAddOutcome={() => onAddOutcome({ outcome_group_id: outcomeGroup.id })}
                  onAddOutcomeSubgroup={() =>
                    onAddOutcomeSubgroup({ outcome_group_id: outcomeGroup.id })
                  }
                />
              )}
            <SidebarOptionsMenu
              buttonKind="tertiary"
              optionsMenuActions={{
                onDelete: onOutcomeGroupDelete,
              }}
            />
          </div>
        </HoverableToolbar>

        <OutcomeCount count={outcomeGroup.outcome_count} />
      </div>

      <div className="flex flex-col gap-4">
        {outcomeGroup.outcome_subgroups.map((outcomeSubgroup) => (
          <div key={outcomeSubgroup.id}>
            <HoverableToolbar
              className="mb-1 ml-1 flex w-full items-center justify-between"
              readOnly={!canAuthorOrg}
              reference="outcome_subgroup"
              showEditButton
              onClickAdd={() => onAddOutcome({ outcome_subgroup_id: outcomeSubgroup.id })}
            >
              <HoverableEditField
                className="w-full"
                label={t("glossary.outcome_subgroup_title")}
                textSize="h4"
                value={outcomeSubgroup.title}
                shouldWrap
                onValueChanged={(newTitle) =>
                  onTitleChanged("outcome_subgroups", outcomeSubgroup.id, newTitle)
                }
              />

              <SidebarOptionsMenu
                buttonKind="tertiary"
                optionsMenuActions={{
                  onDelete: () => onOutcomeSubgroupDelete(outcomeSubgroup.id),
                }}
              />
            </HoverableToolbar>

            {outcomeSubgroup.outcomes.map((outcome: OutcomeType) => (
              <ListItem
                className="w-full"
                isSelected={currentOutcome?.id === outcome.id}
                key={outcome.id}
                label={outcome.title}
                onClick={() => onOutcomeClick(outcome)}
              >
                <HoverableToolbar
                  className="flex w-full items-center justify-between"
                  readOnly={!canAuthorOrg}
                >
                  <div className="body-s ml-4 flex w-full items-center">
                    #
                    <div className="outcome-title my-1 w-full cursor-pointer truncate px-2">
                      {outcome.title}
                    </div>
                  </div>

                  <SidebarOptionsMenu
                    buttonKind="tertiary"
                    optionsMenuActions={{
                      onDelete: () => onDeleteOutcome(outcome.id),
                    }}
                  />
                </HoverableToolbar>
              </ListItem>
            ))}

            <TextAddButton
              className="add-subgroup-outcome"
              reference="outcome"
              onClick={() => onAddOutcome({ outcome_subgroup_id: outcomeSubgroup.id })}
            />
          </div>
        ))}
      </div>

      {outcomeGroup.outcomes.map((outcome: OutcomeType) => (
        <ListItem
          className="w-full"
          isSelected={currentOutcome?.id === outcome.id}
          key={outcome.id}
          label={outcome.title}
          onClick={() => onOutcomeClick(outcome)}
        >
          <HoverableToolbar
            className="flex w-full items-center justify-between"
            readOnly={!canAuthorOrg}
          >
            <div className="body-s ml-1 flex w-full items-center">
              #
              <div className="outcome-title my-1 w-full cursor-pointer truncate px-2">
                {outcome.title}
              </div>
            </div>

            <SidebarOptionsMenu
              buttonKind="tertiary"
              optionsMenuActions={{
                onDelete: () => onDeleteOutcome(outcome.id),
              }}
            />
          </HoverableToolbar>
        </ListItem>
      ))}

      {outcomeGroup.outcome_subgroups?.length === 0 && (
        <TextAddButton
          className="add-group-outcome"
          reference="outcome"
          onClick={() => onAddOutcome({ outcome_group_id: outcomeGroup.id })}
        />
      )}

      {outcomeGroup.outcomes?.length === 0 && (
        <TextAddButton
          className={clsx("add-subgroup", outcomeGroup.outcome_subgroups?.length > 0 && "mt-4")}
          reference="outcome_subgroup"
          onClick={() => onAddOutcomeSubgroup({ outcome_group_id: outcomeGroup.id })}
        />
      )}
    </Card>
  );
};
