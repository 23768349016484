import { UserInformationType, UserType } from "components/server-types";
import { UserWithCourseUserType } from "store/selectors";

// TODO: Consider localizing these name formats
export const getUsername = (user: UserType | UserWithCourseUserType): string => {
  if (!user?.id) {
    return "Anonymous User";
  }
  return user.first_name && user.last_name
    ? getUserFullName(user)
    : user.email || `User ${user.id}`;
};

export const getUserFullName = (user: UserType | UserWithCourseUserType): string => {
  return `${user.first_name || "?"} ${user.last_name || "?"}`;
};

export const getUserShortName = (user: UserType | UserWithCourseUserType): string => {
  return `${user.first_name} ${user.last_name?.[0]}.`;
};

export const getUserInitials = (user: UserInformationType): string => {
  return `${user.first_name?.[0] || "?"}${user.last_name?.[0] || "?"}`;
};

export const getUserLastNameSortKey = (user: UserInformationType) =>
  `${user?.last_name || "?"} ${user?.first_name || "?"}`;
