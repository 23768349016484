import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const ArrowTopRightIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="fill"
      clipRule="evenodd"
      d="M2 1H14C14.5523 1 15 1.44772 15 2V14C15 14.5523 14.5523 15 14 15H2C1.44772 15 1 14.5523 1 14V2C1 1.44772 1.44772 1 2 1ZM0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2ZM10.1667 10.6667C10.1667 10.9428 10.3905 11.1667 10.6667 11.1667C10.9428 11.1667 11.1667 10.9428 11.1667 10.6667V5.33333C11.1667 5.05719 10.9428 4.83333 10.6667 4.83333L5.33333 4.83333C5.05719 4.83333 4.83333 5.05719 4.83333 5.33333C4.83333 5.60948 5.05719 5.83333 5.33333 5.83333L9.45956 5.83333L4.97978 10.3131C4.78452 10.5084 4.78452 10.825 4.97978 11.0202C5.17504 11.2155 5.49162 11.2155 5.68689 11.0202L10.1667 6.54044V10.6667Z"
      fillRule="evenodd"
    />
  </svg>
);
