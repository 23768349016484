import FileSaver from "file-saver";
import React, { FC, useRef } from "react";

import { WorksheetType } from "../../worksheets/shared/types/worksheet";
import { createId } from "../../worksheets/shared/utils";

import { collaborationPutWorksheetJson } from "api/api-worksheets";
import { PageType } from "components/server-types";
import { IconText } from "mds/components/IconText";
import { MenuItem } from "mds/components/Menu";
import { ArrowDownIcon, ArrowUpIcon } from "mds/icons";
import { useWorksheet } from "providers/ShareDBDoc";
import { storeApi } from "store/index";

type DownloadUploadOptionsProps = {
  page: PageType;
};

type DownloadedDataType = {
  page: PageType;
  worksheet: WorksheetType;
};

export const DownloadUploadOptions: FC<DownloadUploadOptionsProps> = ({ page }) => {
  const { doc } = useWorksheet(page.worksheet_id);
  const fileInput = useRef<HTMLInputElement>(null);

  const onClickDownload = () => {
    // Gather the data to be downloaded.
    const data: DownloadedDataType = {
      page,
      worksheet: doc.data,
    };

    // Format the data into a JSON blob.
    const json = JSON.stringify(data, null, 4);
    const blob = new Blob([json], { type: "application/json" });

    // Prompt the user to save the JSON blob as a file.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    FileSaver.saveAs(blob, `page-${page.id}.json`);
  };

  const onChangeFile = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    if (changeEvent.target.files.length) {
      const file = changeEvent.target.files[0];
      const reader = new FileReader();

      reader.onload = async (loadEvent) => {
        // Load and parse the uploaded JSON data.
        const json = loadEvent.target.result as string;
        const data = JSON.parse(json) as DownloadedDataType;

        // Create a new worksheet from the uploaded worksheet data.
        const newWorksheetId = createId();
        await collaborationPutWorksheetJson(newWorksheetId, data.worksheet);

        // Modify the uploaded page data to have the same id as the current page, make it refer to the
        // worksheet we just created, and delete properties that could interfere with the original page,
        // then update the current page with the uploaded and modified page data.
        data.page.id = page.id;
        data.page.is_imported = true;
        data.page.worksheet_id = newWorksheetId;
        delete data.page.activity_id;
        delete data.page.course_id;
        delete data.page.instructor_notes_worksheet_id;
        delete data.page.order;
        delete data.page.progenitor_id;
        await storeApi.pages.partial_update(data.page);

        // Clear the selected file state to allow the user to re-upload the same file again if they choose.
        fileInput.current.value = "";
      };

      reader.readAsText(file);
    }
  };

  return (
    <>
      <MenuItem onClick={onClickDownload}>
        <IconText iconStart={<ArrowDownIcon />} text="Download JSON" />
      </MenuItem>
      <MenuItem onClick={() => fileInput.current.click()}>
        <IconText iconStart={<ArrowUpIcon />} text="Upload JSON" />
      </MenuItem>
      <input
        accept="application/json"
        className="hidden"
        ref={fileInput}
        type="file"
        onChange={onChangeFile}
      />
    </>
  );
};
