import { FC } from "react";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";

export type FollowButtonProps = {
  onClick: () => void;
};

export const MovePresentationButton: FC<FollowButtonProps> = ({ onClick }) => {
  return (
    <Button color="orange" kind="primary" size="xs" onClick={onClick}>
      {t("main_header.move_presentation")}
    </Button>
  );
};
