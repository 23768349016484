import clsx from "clsx";
import { type FC, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { IconFromPage } from "./IconFromPage";
import { PageSubmissionStatus } from "./PageSubmissionStatus";

import { PAGE_ID_QUERY_PARAM } from "components/constants";
import { HoverableEditField } from "components/hover-widgets/EditableField";
import { HoverableToolbar } from "components/hover-widgets/HoverableToolbar";
import { showPageDisabled } from "components/materials/page/groups/helpers";
import { PageSidebarOptionsMenu } from "components/menus/PageSidebarOptionsMenu";
import { PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { DragAndDrop, DraggableItemType } from "mds/components/DragAndDrop";
import { ListItem, ListItemProps } from "mds/components/ListItem";
import { LockLockedIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import { selectCanAuthorCourse, selectIsEditModeEnabled } from "store/selectors";
import { onTitleChanged } from "utils/store-utils";

type PageListItemProps = {
  pageIndex: number;
  page: PageType;
  className?: string;
  containerId?: string;
  topicId?: string;
  readOnly?: boolean;
  kind?: ListItemProps["kind"];
  editModeDependent?: boolean;
  onPageClick: () => void;
  onMovePageLocally?: (item: DraggableItemType, toIndex: number, dropContainerId: string) => void;
  onDropPage?: (item: DraggableItemType, index: number, sourceContainerId: string) => void;
};

/*
 * This is intended to be used for displaying a Page in a list anywhere in the app (e.g.
 * Course Overview page or various sidebars), currently only implemented for sidebar Pages within
 * a Topic. Note that the topicId will be undefined if the Page is not contained within a Topic.
 */
export const PageListItem: FC<PageListItemProps> = ({
  className,
  pageIndex,
  page,
  containerId,
  topicId,
  readOnly = false,
  editModeDependent = true,
  kind,
  onMovePageLocally,
  onDropPage,
  onPageClick,
}) => {
  const [searchParams] = useSearchParams();
  const selectedPageId = searchParams.get(PAGE_ID_QUERY_PARAM);
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);
  const canEdit = !editModeDependent || isEditModeEnabled;
  const canUpdatePage = canAuthorCourse && !readOnly;

  return (
    <DragAndDrop
      draggable={canUpdatePage && !isEditingTitle && !!onDropPage}
      dragItemKind="page"
      dropContainerId={containerId}
      dropIndex={pageIndex}
      droppable={canUpdatePage && !!onDropPage}
      item={page}
      key={page.id}
      onDraggedItemHover={onMovePageLocally}
      onDropItem={onDropPage}
    >
      <ListItem
        className={clsx(
          "flex w-full items-center justify-between gap-2",
          {
            "show-disabled": selectedPageId !== page.id && showPageDisabled(page),
            "!pr-[3px]": kind !== "primary",
          },
          className,
        )}
        isSelected={selectedPageId === page.id}
        kind={kind}
        label={page.title}
        onClick={onPageClick}
      >
        <HoverableToolbar
          className="min-w-12 flex-grow"
          disableUnlessEditMode={editModeDependent}
          readOnly={!canUpdatePage}
          reference="page"
          showEditButton
          onEditModeChange={setIsEditingTitle}
        >
          <div className="flex w-full min-w-12 flex-row items-center justify-between">
            <div
              className={clsx("flex w-full min-w-12 items-center gap-2 font-medium", {
                "text-black-tint-55": selectedPageId !== page.id && showPageDisabled(page),
              })}
            >
              <IconFromPage page={page} />

              {/*  TODO: The page-title class intends to restrict the max width, but it doesn't take into account
                      the new lock/due date items on pages, which causes the content to get expanded wider than it should */}
              <HoverableEditField
                className="page-title flex-grow"
                disableUnlessEditMode={editModeDependent}
                label={t("glossary.page_title")}
                textSize={kind === "primary" ? "m" : "s"}
                value={page.title}
                onValueChanged={(newTitle) => onTitleChanged("pages", page.id, newTitle)}
              />
            </div>

            {page.locked_at && <LockLockedIcon />}
          </div>

          <PageSidebarOptionsMenu
            buttonKind="tertiary"
            currentTopicId={topicId}
            editMode={canUpdatePage && canEdit}
            page={page}
          />
        </HoverableToolbar>

        <PageSubmissionStatus page={page} readOnly={readOnly || !editModeDependent} />
      </ListItem>
    </DragAndDrop>
  );
};
