import { AssignedPagesList } from "../AssignedPagesList";

import { RecentlyAssessedPagesList } from "components/materials/RecentlyAssessedPagesList";
import { t } from "i18n/i18n";
import { HorizontalDivider } from "mds/components/HorizontalDivider";

export const PlannerSidebarPanel = () => {
  return (
    <>
      <div className="content-sidebar-header">
        <h3 className="my-1">{t("course.sidebar.planner.title")}</h3>

        <HorizontalDivider className="-ml-2 mt-2" />
      </div>

      <div className="content-sidebar-main h-full">
        <AssignedPagesList category="all" timeframe="due-soon" />

        <HorizontalDivider className="mb-3 mt-5" />

        <AssignedPagesList category="all" timeframe="late-only" />

        <HorizontalDivider className="mb-3 mt-5" />

        <RecentlyAssessedPagesList />
      </div>
    </>
  );
};
