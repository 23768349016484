/* TODO: Update MDS Modal to HeadlessUI Dialog, import from there */
// eslint-disable-next-line no-restricted-imports
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  CloseButton as HeadlessCloseButton,
} from "@headlessui/react";
import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { LargeCloseButton } from "../../../../mds/components/CloseButton";
import { BLOCKS_PATH } from "../../../../worksheets/shared/constants";
import { blockIsAsset, getEditableBlocks } from "../../../../worksheets/shared/doc-helpers";

import { FeatureAllUsersBreadcrumbs } from "./FeatureAllUsersBreadcrumbs";
import { FeatureHeader } from "./FeatureHeader";
import { FeatureUserBreadcrumbs } from "./FeatureUserBreadcrumbs";

import { FOLLOWING_QUERY_PARAM } from "components/constants";
import { PageDetailView } from "components/materials/page/PageDetailView";
import { useNameFromPageAccessId } from "components/materials/page/helpers";
import { BlockNavigationButtons } from "components/materials/presentation/featuring/BlockNavigationButtons";
import { FeatureOutcomes } from "components/materials/presentation/featuring/FeatureOutcomes";
import { ProjectorScroller } from "components/materials/presentation/projector/ProjectorScroller";
import { useFetchGroupData } from "mds/hooks/use-fetch-group-data";
import { useWorksheet } from "providers/ShareDBDoc";
import { useAppSelector } from "store/index";
import { selectIsSidebarOpen, selectPageById } from "store/selectors";
import { FeatureStatus, stopFeaturing, usePresentation } from "utils/presentation";

export const FeatureModal: FC = () => {
  const {
    featureStatus,
    presentedSessionId,
    presentedPageId: pageId,
    isPresenting,
    presentedAccessId,
    presentedBlockId,
    presentingOutcomes,
    presentingRollup,
  } = usePresentation();
  const page = useAppSelector((s) => selectPageById(s, pageId));
  const [searchParams, setSearchParams] = useSearchParams();
  const isSidebarOpen = useAppSelector(selectIsSidebarOpen);
  const [originatingStatus, setOriginatingStatus] = useState<FeatureStatus>();

  const { doc } = useWorksheet(page?.worksheet_id);
  const blocks = presentingRollup ? getEditableBlocks(doc) : doc?.data?.[BLOCKS_PATH];
  const presentedBlock = blocks?.find((b) => b.id === presentedBlockId);
  const isStaticContent = !doc || blocks?.length > 0;
  const showBlockNavigation = isPresenting && blocks?.length > 1 && !!presentedBlockId;
  const showSimpleHeader =
    !isPresenting ||
    ![
      FeatureStatus.ALL_RESPONSES_WITH_BLOCK,
      FeatureStatus.PARTICIPANT_RESPONSE_WITH_BLOCK,
    ].includes(featureStatus);

  const groupingCategory = page?.grouping_category;
  const responseName = useNameFromPageAccessId(presentedAccessId, pageId);
  const title = showSimpleHeader ? (
    <FeatureHeader
      blockType={presentedBlock?.t}
      featureStatus={featureStatus}
      isPresenting={isPresenting}
      responseName={responseName}
    />
  ) : originatingStatus === FeatureStatus.PARTICIPANT_RESPONSE ? (
    <FeatureUserBreadcrumbs
      accessId={presentedAccessId}
      classSessionId={presentedSessionId}
      page={page}
      responseName={responseName}
    />
  ) : (
    <FeatureAllUsersBreadcrumbs
      classSessionId={presentedSessionId}
      featureBlockId={presentedBlockId}
      featureStatus={featureStatus}
      page={page}
      showSingleQuestionStep={originatingStatus === FeatureStatus.ALL_RESPONSES_WITH_BLOCK}
    />
  );

  // Load the page groups, page course users depending on the type of page
  useFetchGroupData(pageId, groupingCategory);

  // Set the singleUserResponses state when the featureStatus changes
  useEffect(() => {
    // Showing an individual response should not set the singleUserResponses state
    // as both paths have the indvidual response state
    if (featureStatus !== FeatureStatus.PARTICIPANT_RESPONSE_WITH_BLOCK) {
      setOriginatingStatus(featureStatus);
    }
  }, [featureStatus]);

  if (blockIsAsset(doc, presentedBlockId)) {
    // The asset field itself will open a modal, so we don't need to render this one
    return;
  }

  const onClose = () => {
    if (isPresenting) {
      stopFeaturing(presentedSessionId);
    } else {
      searchParams.delete(FOLLOWING_QUERY_PARAM);
      setSearchParams(searchParams);
    }
  };

  return (
    <Dialog className="relative z-50" open onClose={onClose}>
      <div
        className={clsx("fixed bottom-0 left-0 right-0 top-[--navbar-height] z-50", {
          "lg:left-[--sidebar-open-width]": isSidebarOpen,
          "lg:left-[--sidebar-closed-width]": !isSidebarOpen,
        })}
      >
        <DialogBackdrop className="absolute inset-0 bg-black/50" />

        <div className="absolute left-0 right-0 top-0 flex h-full w-full items-center justify-center px-2 lg:px-4">
          <div className="flex max-h-[calc(100vh-200px)] w-[calc(var(--page-default-max-width)+2*40px)] flex-col overflow-hidden rounded-lg border border-black-tint-90 bg-white p-1.5">
            <DialogPanel className="flex-grow-1 flex flex-col gap-4 overflow-hidden">
              <div className="flex min-h-10 items-center justify-between pl-2">
                <DialogTitle className="body-s text-base font-medium">{title}</DialogTitle>
                <HeadlessCloseButton as={LargeCloseButton} onClick={onClose} />
              </div>
              <ProjectorScroller id="feature-modal-scroller">
                {presentingOutcomes ? (
                  <FeatureOutcomes page={page} />
                ) : (
                  <PageDetailView
                    isStaticContent={isStaticContent}
                    page={page}
                    selectedAccessId={presentedAccessId}
                    selectedTabVariant={presentingRollup ? "responses" : null}
                    isFeaturing
                  />
                )}
              </ProjectorScroller>

              {showBlockNavigation && <BlockNavigationButtons className="mt-4" doc={doc} />}
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
