import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const ChevronUpIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="fill"
      d="M2 10.2437C2 10.4157 2.05925 10.5892 2.18075 10.7299C2.44999 11.0432 2.92323 11.0799 3.23747 10.8106L7.99988 6.74104L12.7623 10.8114C13.0765 11.0806 13.549 11.044 13.819 10.7306C14.089 10.4172 14.053 9.94451 13.7373 9.67599L8.48737 5.1883C8.20687 4.9482 7.79213 4.9482 7.51164 5.1883L2.26174 9.67599C2.0885 9.82334 2 10.0335 2 10.2437Z"
    />
  </svg>
);
