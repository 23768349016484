import clsx from "clsx";
import { FC, useMemo } from "react";

import { HoverableToolbar } from "components/hover-widgets/HoverableToolbar";
import { ActivityCard } from "components/materials/topic/activity/detail/ActivityCard";
import { DurationDisplayBox } from "components/materials/topic/activity/detail/DurationDisplayBox";
import { ActivityCategory } from "components/server-types";
import { t } from "i18n/i18n";
import { Card } from "mds/components/Card";
import { TextAddButton } from "mds/components/TextAddButton";
import { useAppSelector } from "store/index";
import {
  type ActivityWithPagesType,
  selectCanAuthorCourse,
  selectIsEditModeEnabled,
} from "store/selectors";
import { isLesson, useActivityActions } from "utils/activity";
import { useDnDItemOrdering, useLocalCopy } from "utils/collections";
import { sumActivityTimes } from "utils/page";

export type LessonActivityListProps = {
  activities: ActivityWithPagesType[];
  topicId: string;
};

// This component is for displaying a list of lesson activities related to a topic.
export const LessonActivityList: FC<LessonActivityListProps> = ({ activities, topicId }) => {
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const { createActivity } = useActivityActions();
  const isEditModeEnabled = useAppSelector(selectIsEditModeEnabled);

  // Students should not see activities without pages.
  const memoLessonActivities = useMemo(
    () =>
      activities.filter(
        (activity) => (canAuthorCourse || activity.pages.length > 0) && isLesson(activity.category),
      ),
    [activities, canAuthorCourse],
  );

  const [lessonActivities, setLessonActivities] = useLocalCopy(memoLessonActivities);
  const moveLessonActivityLocally = useDnDItemOrdering(
    lessonActivities,
    setLessonActivities,
    "category",
  );

  const totalLessonTime = sumActivityTimes(lessonActivities);
  const hasTotalLessonTime = canAuthorCourse && Number(totalLessonTime) > 0;

  const createNewActivity = () => {
    createActivity(ActivityCategory.LESSON, activities, topicId);
  };

  return (
    // TODO: This DragAndDrop -> ListItem -> HoverableToolbar -> Icon+EditableField needs
    // to be abstracted so we don't have style drift between all the activity card types,
    // and other list types (like TopicList, CoursePageList, etc). We should instead express
    // any style differences as part of the ListItem MDS styles using the `kind` property.
    <>
      <div className="flex items-center">
        <div className="flex-auto">
          <HoverableToolbar
            readOnly={!canAuthorCourse}
            reference="lesson"
            disableUnlessEditMode
            onClickAdd={createNewActivity}
          >
            <div
              className={clsx("body-s my-1 w-full truncate px-2 text-black-tint-40", {
                "cursor-pointer": isEditModeEnabled,
              })}
            >
              {t("glossary.lesson")}
            </div>
          </HoverableToolbar>
        </div>
        {hasTotalLessonTime && <DurationDisplayBox duration={totalLessonTime} />}
      </div>

      {lessonActivities.map((activity, activityIndex) => (
        <ActivityCard
          activities={activities}
          activity={activity}
          activityIndex={activityIndex}
          key={activity.id}
          moveActivityLocally={moveLessonActivityLocally}
        />
      ))}

      {isEditModeEnabled && (
        <Card small>
          <div className="-ml-1">
            <TextAddButton
              reference="activity"
              hideUnlessEditMode
              underlined
              onClick={createNewActivity}
            />
          </div>
        </Card>
      )}
    </>
  );
};
