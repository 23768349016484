import { FC } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { FOLLOWING_QUERY_PARAM } from "components/constants";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { Menu, MenuItem } from "mds/components/Menu";
import { Tooltip } from "mds/components/Tooltip";
import { ChevronDownIcon, CircleInfoIcon, PresentPlayIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import { selectCanAuthorCourse, selectCurrentUserId } from "store/selectors";
import { trackEvent } from "utils/amplitude";
import { getUrlForPage } from "utils/navigation";
import { setPresentationWindow, takeOverPresenting, usePresentation } from "utils/presentation";
import { CourseViewVariantType } from "utils/urls";

export type PresentationControlMenuProps = {
  courseViewVariant: CourseViewVariantType;
};

export const PresentationControlMenu: FC<PresentationControlMenuProps> = ({
  courseViewVariant,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { presentedSessionId, presentedPageId, isPresentingUser, isFollowing } = usePresentation();
  const userId = useAppSelector(selectCurrentUserId);
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse) && !isPresentingUser;
  const navigate = useNavigate();

  const navigateToActiveClass = async () => {
    const pageUrl = await getUrlForPage(presentedPageId);

    navigate(pageUrl);
  };

  const onTakeOverPresentation = () => {
    setPresentationWindow();
    trackEvent("Presentation - Take over presentation", { eventCategory: "Button press" });
    takeOverPresenting(presentedSessionId, userId).then(() => {
      searchParams.delete(FOLLOWING_QUERY_PARAM);
      setSearchParams(searchParams);

      navigateToActiveClass();
    });
  };

  const buttonText =
    courseViewVariant === "main"
      ? t("main_header.not_following_presentation")
      : isPresentingUser
        ? t("main_header.presenting_in_other_tab")
        : isFollowing
          ? t("main_header.following_presentation")
          : t("main_header.no_longer_following_presentation");

  const MenuButton = (
    <Button disabled={!canAuthorCourse} kind="tertiary" size="xs" title={buttonText}>
      <PresentPlayIcon className="icon-white mr-2 align-middle" />
      <span className="h4 text-white">{buttonText}</span>
      {canAuthorCourse && <ChevronDownIcon className="icon-white ml-2 align-middle" />}
    </Button>
  );

  return (
    <div className="presenting flex items-center">
      <Menu menuButton={MenuButton} onClick={(e) => e.stopPropagation()}>
        <MenuItem onClick={onTakeOverPresentation}>
          {t("main_header.take_over_presentation")}
        </MenuItem>
      </Menu>

      {isPresentingUser && (
        <Tooltip
          element={<CircleInfoIcon className="icon-white" height="16" width="16" />}
          place="left"
        >
          {t("main_header.move_presentation_tooltip")}
        </Tooltip>
      )}
    </div>
  );
};
