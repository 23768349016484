import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";

export interface LocalStorageRingBufferType<T> {
  push: (item: T) => void;
  toArray: () => T[];
}

export interface StoreWithAv {
  dispatch: ThunkDispatch<{ av: AvStateType }, undefined, AnyAction>;
  getState: () => { av: AvStateType };
}

export type AvDispatchType = ThunkDispatch<{ av: AvStateType }, undefined, AnyAction>;

export enum AvSubsystemAvServiceType {
  Daily = "daily",
  Fake = "fake",
  Licode = "licode",
  Opentok = "opentok",
}

export enum AvBandwidthModeType {
  AudioOnly = "audio-only",
  AutomaticBandwidth = "high",
  LimitedBandwidth = "low",
}

export enum AvMonitoringEventTypeType {
  DEVICES_SELECTED = "devices-selected",
  DEVICES_ERROR = "devices-error",
  FAILED_CONNECTION_ALERT = "failed-connection-alert",
  AV_CONNECTION_QUALITY_CHANGED = "av-connection-quality-changed",
  STREAM_STARTED = "stream-started",
  STREAM_FAILED = "stream-failed",
  AV_REFRESH = "av-refresh",
  RELOAD_STREAM = "reload-stream",
}

export type AvServiceInfo = {
  avService: AvSubsystemAvServiceType;
  licodeServer: string | undefined;
  // For opentok
  opentokSessionId: string;
  // For Daily and Licode
  roomId: string;
};

export type AvServiceCredentials = {
  apiKey: string;
  token: string;
};

// type that represents a function that gets the required credentials for a given av service as a promise
export type AvServiceCredentialsGetter = (
  avServiceInfo: AvServiceInfo,
) => Promise<AvServiceCredentials>;

export enum AvConnectionSignals {
  MONITORING_EVENT = "monitoring-event",
}

export enum AvServiceSignals {
  CONNECTION_CONNECTED = "connection-connected",
  CONNECTION_FAILED = "connection-failed", // Connection has failed - will retry
  CONNECTION_FATAL_ERROR = "connection-fatal-error", // Connection has failed and cannot be recovered
  CONNECTION_DISCONNECTED = "connection-disconnected",
  CONNECTION_NEEDS_REFRESH = "connection-needs-refresh", // For operations that require a refresh in an A/V service
  CONNECTION_QUALITY_CHANGED = "connection-quality-changed",
  SCREENSHARE_ADDED = "screenshare-added",
  SCREENSHARE_FAILED = "screenshare-failed",
  SCREENSHARE_PUBLISHED = "screenshare-published",
  SCREENSHARE_REMOVED = "screenshare-removed",
  SCREENSHARE_SUBSCRIBED = "screenshare-subscribed",
  SCREENSHARE_UNPUBLISHED = "screenshare-unpublished",
  SCREENSHARE_UNSUBSCRIBED = "screenshare-unsubscribed",
  STREAM_ADDED = "stream-added",
  STREAM_VIDEO_AVAILABILITY_CHANGED = "stream-video-availability-changed",
  STREAM_FAILED = "stream-failed",
  STREAM_NOT_FOUND = "stream-not-found",
  STREAM_PUBLISHED = "stream-published",
  STREAM_REMOVED = "stream-removed",
  STREAM_SUBSCRIBED = "stream-subscribed",
  STREAM_UNPUBLISHED = "stream-unpublished",
  STREAM_UNSUBSCRIBED = "stream-unsubscribed",
}

export enum BlurGlobalState {
  INITIAL = "INITIAL",
  LOADING_LIB = "LOADING_LIB",
  LOADED_LIB = "LOADED_LIB",
  LOADING_MODEL = "LOADING_MODEL",
  LOADED = "LOADED",
  SLOW_LOADING = "SLOW_LOADING",
  ERROR = "ERROR",
}

export interface AvLogger {
  log: (message: string) => void;
  error: (message: string) => void;
  warn: (message: string) => void;
}

export enum AvRefreshReasons {
  // Admin clicked reload AV in the PIM
  ADMIN_REQUESTED = "admin-requested",
  // User clicked reload AV in the PIM or settings menu
  USER_REQUESTED = "user-requested",
  // Something went wrong with a stream
  STREAM_FAILED = "stream-failed",
  // Something went wrong with the connection to the A/V service
  CONNECTION_FAILED = "connection-failed",
  // Something has changed in the A/V service configuration
  UPDATE_SERVICE_INFO = "update-service-info",
  // We need to refresh for some operations depending on the A/V service
  AV_SERVICE_REQUESTED = "av-service-requested",
  // An unexpected disconnection from the A/V service control layer
  UNEXPECTED_DISCONNECTION = "unexpected-disconnection",
}

export type AvStateType = {
  local_user_id: string | undefined;
  room_id: string | undefined;
  av_service: AvSubsystemAvServiceType;
  connection_state: ConnectionStates;
  connection_error: string | null;
  connection_quality: ConnectionQualityLevels | null;
  bandwidth_mode: AvBandwidthModeType;
  streams: { [user_id: string]: StreamStateType };
  aggregated_stats: AvAggregatedStatsReport;
  screensharing_streams: { [user_id: string]: StreamStateType };
  device_list: DeviceEntry[];
  screenshare_state: DeviceGlobalState;
  // As of Chrome 113 the camera and mic are requested together - we keep separate states for backwards compatibility
  mic_state: DeviceGlobalState;
  camera_state: DeviceGlobalState;
  blur_state: BlurGlobalState;
};

export type DeviceStateRepresentationType = {
  device_list: DeviceEntry[];
  screenshare_state: DeviceGlobalState;
  mic_state: DeviceGlobalState;
  camera_state: DeviceGlobalState;
  blur_state: BlurGlobalState;
};

export type AvStatsReport = {
  // userId-screen for screensharing streams
  [composedUserId: string]: AvStreamStatsReport;
};

export type AvStreamStatsEntry = {
  [entryName: string]: number;
};

export type AvConnectionInfo = {
  [reportName: string]: { [entryName: string]: string };
};

export type AvAggregatedStatsReport = {
  published: { [entryName: string]: number | string };
  subscribed: { [entryName: string]: number | string };
};

export type AvStreamStatsReport = {
  video: { [trackId: string]: AvStreamStatsEntry };
  audio: { [trackId: string]: AvStreamStatsEntry };
  connection?: AvConnectionInfo;
};

export type AvStreamStatsReportKey = keyof AvStreamStatsReport;

export enum ConnectionQualityLevels {
  GOOD = "good",
  POOR = "poor",
}

export enum ConnectionStates {
  initial = "INITIAL",
  connecting = "CONNECTING",
  connected = "CONNECTED",
  disconnecting = "DISCONNECTING",
  disconnected = "DISCONNECTED",
  failed = "FAILED", // Recoverable failure
  fatalError = "FATAL_ERROR", // Unrecoverable failure
  reconnecting = "RECONNECTING",
}

export type PreferredDevicesType = {
  preferredCameraLabel: string | null;
  preferredMicLabel: string | null;
};

export type DeviceEntry = {
  id: string;
  label: string;
  kind: string;
  selected: boolean;
};

export enum DeviceErrors {
  AUDIO_MAC_NOT_ALLOWED = "audio_mac_not_allowed",
  AUDIO_WINDOWS_CAMERA_IN_USE = "audio_windows_camera_in_use",
  AUDIO_NOT_ALLOWED = "audio_not_allowed",
  AUDIO_NOT_FOUND = "audio_not_found",
  AUDIO_FAIL = "audio_fail",
  SCREENSHARE_FAIL = "screenshare_fail",
  VIDEO_MAC_NOT_ALLOWED = "video_mac_not_allowed",
  VIDEO_WINDOWS_CAMERA_IN_USE = "video_windows_camera_in_use",
  VIDEO_NOT_ALLOWED = "video_not_allowed",
  VIDEO_NOT_FOUND = "video_not_found",
  VIDEO_FAIL = "video_fail",
  MEDIA_TIMEOUT = "media_timeout",
}

export enum DevicesSignals {
  DEVICE_ERROR = "device-error",
  DEVICES_LIST = "devices-list",
  DEVICES_LIST_ERROR = "devices-list-error",
  DEVICES_SELECTED = "devices-selected",
  SCREENSHARE_DEVICE_ERROR = "screenshare-device-error",
  SCREENSHARE_DEVICE_SELECTED = "screenshare-device-selected",
  SCREENSHARE_DEVICE_STOPPED = "screenshare-device-stopped",
}

export enum DevicesRequestStates {
  initial = "INITIAL",
  requested = "REQUESTED",
  granted = "GRANTED",
  blocked = "BLOCKED",
}

// Global state and configuration for a type of device
export type DeviceGlobalState = {
  request_state: DevicesRequestStates;
  // Default device id does not apply to screenshare
  default_device_id: string | null;
  disabled: boolean;
  error: DeviceErrors | null;
};

export enum QualityLevel {
  High = "HIGH",
  LowFullMotion = "LOW_FULL_MOTION", // Reduced resolution
  Low = "LOW", // Restricted framerate or slideshow
  Screenshare = "SCREENSHARE",
  HiddenScreenshare = "HIDDEN_SCREENSHARE",
}

export enum StreamStates {
  initial = "INITIAL", // Requested stream
  connecting = "CONNECTING", // Stream requested and negotiating in av service
  failed = "FAILED", // Something went wrong
  playable = "PLAYABLE", // Stream available after negotiation
  closing = "CLOSING", // Stream closing, unsubscribing or unpublishing
}

export type StreamStateType = {
  id: string | null; // the av provider id for this stream
  dom_id: string;
  state: StreamStates;
  retries: number; // number of times we have tried to connect to this stream
  quality_level: QualityLevel | null;
  is_screensharing: boolean | undefined;
  video_enabled: boolean; // We have enabled the video for this stream locally
  video_available: boolean; // The stream has a video track ready to be played
  audio_enabled: boolean; // We have enabled the audio for this stream locally
  audio_available: boolean; // The stream has audio available to be played
  // note that all "playable" streams will have audio_available=true because there is always an audio track
  // screensharing streams can have audio_available=false if the user is not sharing audio
  copy_requested: boolean;
  stats: AvStreamStatsReport;
};
