import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const AddPlusIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M8 3V13"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      className="stroke"
      d="M13.0005 8H3.00049"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
  </svg>
);
