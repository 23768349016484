import { STATUS_COLORS } from "components/materials/page/helpers";
import { t } from "i18n/i18n";
import { PageStatusIcon } from "mds/icons";
import { toastSuccessMessage } from "utils/alerts";

export const toastReleasePage = (count = 1) => {
  toastSuccessMessage(t("success.toasts.page_released", { count }), {
    icon: <PageStatusIcon color={STATUS_COLORS.released} />,
  });
};

export const toastUnreleasePage = (count = 1) => {
  toastSuccessMessage(t("success.toasts.page_unreleased", { count }), {
    icon: <PageStatusIcon color={STATUS_COLORS.unreleased} />,
    progressClassName: "bg-black-tint-70",
  });
};
