import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const OneBarIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <rect className="fill-light" height="12" transform="matrix(-1 0 0 1 13.5 2)" width="2" />
    <rect className="fill-light" height="8" transform="matrix(-1 0 0 1 9 6)" width="2" />
    <rect className="fill" height="4" transform="matrix(-1 0 0 1 4.5 10)" width="2" />
  </svg>
);
