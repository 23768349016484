import { PageType, SubmissionType } from "components/server-types";
import { ActivityWithPagesType, selectAssessedSubmissionsByTopicId } from "store/selectors";
import { useListSelector } from "store/store-hooks";

export const useCanDeleteActions = (topicId: string, pages?: PageType[]) => {
  const assessedSubmissions = useListSelector((s) =>
    selectAssessedSubmissionsByTopicId(s, topicId),
  );
  const assessedSubmissionsByPageId = assessedSubmissions.reduce(
    (acc: { [key: string]: SubmissionType }, submission: SubmissionType) => ({
      ...acc,
      [submission.page_id]: submission,
    }),
    {},
  );

  const assessedPagesByActivityId = pages
    ? pages.reduce(
        (acc: { [key: string]: PageType }, page: PageType) =>
          page.activity_id && assessedSubmissionsByPageId[page.id]
            ? {
                ...acc,
                [page.activity_id]: page,
              }
            : acc,
        {},
      )
    : [];

  return {
    canDeleteActivity: (targetActivity: ActivityWithPagesType) =>
      !assessedPagesByActivityId[targetActivity.id],
    canDeleteTopic: !assessedSubmissions.length,
  };
};
