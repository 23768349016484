import type { FC } from "react";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";

interface StartButtonProps {
  disabled: boolean;
  isLoading: boolean;
  onClick: () => void;
}

export const StartButton: FC<StartButtonProps> = ({ disabled, isLoading, onClick }) => {
  return (
    <Button
      disabled={disabled}
      isLoading={isLoading}
      kind="primary"
      size="xs"
      title={
        disabled
          ? t("student_self_select_card.tooltip.group_full")
          : t("student_self_select_card.tooltip.start_activity")
      }
      onClick={onClick}
    >
      {t("common.start")}
    </Button>
  );
};
