import { FC } from "react";

import { IconColorProps, initializeIconColorProps } from "mds/utils/images";

export const PersonJoinedIcon: FC<IconColorProps> = (props) => {
  const { fillClassName, ...svgProps } = initializeIconColorProps(props);
  return (
    <svg {...svgProps}>
      <path
        d="M8.00002 10.3329C5.9958 10.248 4.01473 10.7881 2.33095 11.8785C2.20801 11.9647 2.10767 12.0792 2.03845 12.2125C1.96923 12.3457 1.93318 12.4937 1.93335 12.6438V14.9996H8.00002"
        stroke="#343434"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00007 7.53333C9.8042 7.53333 11.2667 6.0708 11.2667 4.26667C11.2667 2.46254 9.8042 1 8.00007 1C6.19593 1 4.7334 2.46254 4.7334 4.26667C4.7334 6.0708 6.19593 7.53333 8.00007 7.53333Z"
        stroke="#343434"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect className={fillClassName} height="6" rx="3" width="6" x="9" y="9" />
    </svg>
  );
};
