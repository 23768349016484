import { FC, ReactNode, useEffect, useState } from "react";

/**
 * This is a generic wrapper that will display it's children and then fade-out after a delay,
 * using a CSS transition.
 */
export const Fade: FC<{ delay?: number; children: ReactNode }> = ({ delay = 1000, children }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false);
    }, delay);

    return () => clearTimeout(timeout);
  }, [delay]);

  return (
    <div className={`transition-opacity duration-1000 ${visible ? "opacity-100" : "opacity-0"}`}>
      {children}
    </div>
  );
};
