// TODO: this should be moved to a shared location. Most of the colors already exist in FDS css classes.

export const colors = {
  /* UI Colors */
  black: "#000000",
  white: "#ffffff",
  blue: "#0A78BF",
  blueGreen: "#1596B4",
  green: "#33AB6F",
  yellowGreen: "#8EA604",
  yellow: "#F0B91E",
  orange: "#F0871E",
  red: "#DF2F26",
  redViolet: "#A5417D",
  violet: "#5B3E97",
  blueViolet: "#322864",

  /* Grays */
  gray0: "#fafafa",
  gray1: "#f5f5f5",
  gray2: "#eeeeee",
  gray3: "#cccccc",
  gray4: "#97979f",
  gray5: "#666666",
  gray6: "#505055",
  gray7: "#333333",

  /* Classroom Colors */
  minervaOrange: "#f45910",
  minervaOrangeDark: "#d14c03",
  minervaOrangeLight: "#ffa378",
  submitBlue: "#125ea2",
  submitBlueDark: "#08487d",
  submitBlueLight: "#0f83e3",
};

function mix(hexColor1: string, hexColor2: string, percentage: number) {
  function hexForInt(int: number): string {
    let hex = Math.round(int).toString(16);
    if (hex.length === 1) hex = `0${hex}`;
    return hex;
  }

  // Ensure the percentage is a fraction between 0 and 1
  const fraction = percentage / 100;
  // Remove the leading '#' if present
  const color1 = hexColor1.substring(1);
  const color2 = hexColor2.substring(1);

  const colorMix1 = [
    parseInt(color1[0] + color1[1], 16),
    parseInt(color1[2] + color1[3], 16),
    parseInt(color1[4] + color1[5], 16),
  ];
  const colorMix2 = [
    parseInt(color2[0] + color2[1], 16),
    parseInt(color2[2] + color2[3], 16),
    parseInt(color2[4] + color2[5], 16),
  ];

  return [
    (1 - fraction) * colorMix1[0] + percentage * colorMix2[0],
    (1 - fraction) * colorMix1[1] + percentage * colorMix2[1],
    (1 - fraction) * colorMix1[2] + percentage * colorMix2[2],
  ].reduce((hex: string, int: number) => hex + hexForInt(int), "#");
}

export function tint(color: string, percentage: number) {
  return mix(color, "#ffffff", percentage);
}

const userColors: string[] = [
  "#ed553b",
  "#f2b133",
  "#47ab6c",
  "#09d3e6",
  "#23638c",
  "#f52281",
  "#7dc161",
  "#f39d03",
  "#3bafbd",
  "#a51f6a",
  "#bda400",
  "#f08973",
  "#f7d969",
];

// TODO: rather than randomly assigning a color based on the hex representation of the user id,
// we should assign colors sequentially from the userColors array to guarantee no duplicates.
export const getUserColor = (userId: string): string =>
  userColors[
    userId
      .split("")
      .map((char) => char.charCodeAt(0))
      .reduce((acc, l) => acc + l, 0) % userColors.length
  ];
