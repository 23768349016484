import { toastSuccessMessage } from "../../../../utils/alerts";

import { EditDeleteOnHover } from "components/hover-widgets/EditDeleteOnHover";
import { PageOutcomeScore } from "components/outcomes/PageOutcomeScore";
import { RubricList } from "components/outcomes/RubricList";
import { OutcomeAssessmentType } from "components/server-types";
import { t } from "i18n/i18n";
import { ExpandableText } from "mds/components/ExpandableText";
import { storeApi, useAppSelector } from "store/index";
import {
  NestedPageOutcomeType,
  selectNestedPageOutcomes,
  selectPageOutcomeRubric,
} from "store/selectors";

export const PageOutcomeRubric = ({
  className,
  pageOutcome,
  outcomeAssessment,
  canModify = false,
}: {
  className?: string;
  pageOutcome: NestedPageOutcomeType;
  outcomeAssessment?: OutcomeAssessmentType;
  canModify?: boolean;
}) => {
  const rubric = useAppSelector((s) => selectPageOutcomeRubric(s, pageOutcome.id));
  const pageOutcomes = useAppSelector((s) => selectNestedPageOutcomes(s, pageOutcome.page_id));
  const onDeletePageOutcome = () => {
    storeApi.page_outcomes.destroy(pageOutcome.id).then(() => {
      if (pageOutcomes.filter((po) => po.id !== pageOutcome.id).length === 0) {
        toastSuccessMessage(t("success.toasts.deleted_last_page_outcome"));
      }
    });
  };

  return (
    <div className={className} key={pageOutcome.outcome.title}>
      <EditDeleteOnHover
        className="flex-none justify-between"
        reference="outcome"
        onClickDelete={canModify ? onDeletePageOutcome : undefined}
      >
        <PageOutcomeScore className="mb-2" pageOutcome={pageOutcome} />
      </EditDeleteOnHover>

      <ExpandableText className="flex-none">{pageOutcome.outcome.description}</ExpandableText>

      <RubricList
        className="flex flex-none flex-col gap-1"
        outcomeAssessment={outcomeAssessment}
        outcomeItem={pageOutcome}
        readOnly={!canModify}
        rubric={rubric}
        showHeader={false}
      />
    </div>
  );
};
