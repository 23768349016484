import React from "react";

import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { DualButton } from "mds/components/DualButton";
import { ChevronLeftIcon, ChevronRightIcon } from "mds/icons";

type PageToolbarProps = {
  setPageNumber: (pageNumber: number) => void;
  pageNumber: number;
  totalPages: number;
  narrow?: boolean;
};

export const PageToolbar: React.FC<PageToolbarProps> = ({
  setPageNumber,
  pageNumber,
  totalPages,
  narrow,
}) => {
  const goToPrevPage = () => {
    setPageNumber(pageNumber - 1);
  };

  const goToNextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const prevButton = (
    <Button
      aria-label={t("common.previous")}
      disabled={pageNumber <= 1}
      kind="secondary"
      size="s"
      iconOnly
      onClick={goToPrevPage}
    >
      <ChevronLeftIcon />
    </Button>
  );
  const nextButton = (
    <Button
      aria-label={t("common.next")}
      disabled={pageNumber >= totalPages}
      kind="secondary"
      size="s"
      iconOnly
      onClick={goToNextPage}
    >
      <ChevronRightIcon />
    </Button>
  );

  if (narrow) {
    return <DualButton buttonLeft={prevButton} buttonRight={nextButton} />;
  }

  return (
    <div className="page-toolbar flex items-center gap-2">
      {prevButton}

      <span className="page-toolbar__label body-s text-black-tint-20">
        {t("page_toolbar.page_label", { page_number: pageNumber, total_pages: totalPages })}
      </span>

      {nextButton}
    </div>
  );
};
