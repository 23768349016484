import { FC } from "react";

import { StatusColorType } from "components/materials/page/helpers";
import { PageIcon, PageListIcon, PageListStatusIcon, PageStatusIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import { selectCanAuthorCourse } from "store/selectors";

interface PageItemStatusIconProps {
  color: StatusColorType;
  isMulti?: boolean;
}

export const PageItemStatusIcon: FC<PageItemStatusIconProps> = ({ color, isMulti }) => {
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);

  if (!canAuthorCourse) {
    return isMulti ? <PageListIcon /> : <PageIcon />;
  }

  return isMulti ? <PageListStatusIcon color={color} /> : <PageStatusIcon color={color} />;
};
