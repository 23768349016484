import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const EyeNotVisibleIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="fill"
      d="M13.7756 5.90002L6.60059 13.075C7.03809 13.1625 7.56309 13.25 8.00059 13.25C11.1506 13.25 13.6006 10.5375 14.6506 8.96252C15.0881 8.35002 15.0881 7.56252 14.6506 6.95002C14.4756 6.68752 14.1256 6.33752 13.7756 5.90002Z"
    />
    <path
      className="fill"
      d="M13.5125 1.2625L11.15 3.625C10.1875 3.1 9.1375 2.75 8 2.75C4.85 2.75 2.4 5.4625 1.35 7.0375C0.9125 7.65 0.9125 8.4375 1.35 8.9625C1.7875 9.6625 2.575 10.5375 3.45 11.325L1.2625 13.5125C0.9125 13.8625 0.9125 14.3875 1.2625 14.7375C1.4375 14.9125 1.6125 15 1.875 15C2.1375 15 2.3125 14.9125 2.4875 14.7375L14.7375 2.4875C15.0875 2.1375 15.0875 1.6125 14.7375 1.2625C14.3875 0.9125 13.8625 0.9125 13.5125 1.2625ZM5.6375 9.1375C5.4625 8.7875 5.375 8.4375 5.375 8C5.375 6.5125 6.5125 5.375 8 5.375C8.4375 5.375 8.7875 5.4625 9.1375 5.6375L5.6375 9.1375Z"
    />
  </svg>
);
