import { useState } from "react";

/** Use the {@link https://developer.mozilla.org/en-US/docs/Web/API/Screen_Wake_Lock_API Screen Wake Lock API}
 * to control whether the screen is allowed to dim or sleep.
 */
export const useWakeLock = () => {
  const [sentinel, setSentinel] = useState<WakeLockSentinel>(null);
  const [error, setError] = useState<DOMException>(null);
  const [awaitingLock, setAwaitingLock] = useState(false);
  const [awaitingRelease, setAwaitingRelease] = useState(false);

  /** Ask the device to prevent the screen from dimming or sleeping.
   * Note that the device may refuse this request; for example, if the battery
   * is running low, or the user has requested power-save mode.
   * It is safe to call this function multiple times; the request will only be sent once to the browser.
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Screen_Wake_Lock_API#requesting_a_wake_lock MDN docs}
   */
  const lock = async () => {
    if (sentinel && !sentinel.released) {
      // Already locked, nothing to do.
      return sentinel;
    }
    if (awaitingLock) {
      // Already requested a lock, don't request again.
      return sentinel;
    }
    setAwaitingLock(true);
    try {
      const newSentinel = await navigator.wakeLock.request("screen");
      setSentinel(newSentinel);
      clearError();
      return newSentinel;
    } catch (err: unknown) {
      // The wake lock request failed - usually system-related, such as low battery.
      if (err instanceof DOMException) {
        setError(err);
      } else {
        console.error(err);
      }
      return sentinel;
    } finally {
      setAwaitingLock(false);
    }
  };

  /** Allow the device screen to dim or sleep as normal.
   * It is safe to call this function even if no wake lock is currently active.
   * It is safe to call this function multiple times; the request will only be sent once to the browser.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Screen_Wake_Lock_API#releasing_wake_lock MDN docs}
   */
  const release = async () => {
    if (!sentinel || sentinel.released) {
      // Already released, nothing to do.
      return;
    }
    if (awaitingRelease) {
      // Already requested a release, don't request again.
      return;
    }
    setAwaitingRelease(true);
    const promise = sentinel.release();
    return promise.then(() => setSentinel(null)).finally(() => setAwaitingRelease(false));
  };

  const clearError = () => setError(null);

  return {
    isSupported: "wakeLock" in navigator,

    lock,
    release,

    sentinel,
    error,
    clearError,
  };
};
