import "./EmailLoginForm.scss";

import { AxiosError } from "axios";
import { FC, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import { useBoolean } from "usehooks-ts";

import {
  emailMustBeValid,
  emailMustHaveAtSign,
  getPasswordErrorMessages,
} from "../../helpers/password-errors";

import {
  serverGetCourseMetadataFromInviteKey,
  serverRegisterUserWithInviteKey,
} from "api/api-server";
import { LoadingScreen } from "components/LoadingScreen";
import { PasswordForm, PasswordFormValue } from "components/PasswordForm";
import { LOGO_FORM_SIZE } from "components/constants";
import { ErrorMessageBanner } from "components/forms/ErrorMessageBanner";
import { FormattedValidationError } from "components/server-types";
import { LoginView } from "components/sso/LoginView";
import { Trans, t } from "i18n/i18n";
import { PasswordErrorKey } from "i18n/i18next";
import { Button } from "mds/components/Button";
import { ForumLogoIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import { selectCurrentCourse, selectCurrentUserId } from "store/selectors";
import { toastErrorMessage, toastLocalizedOperationCatcher } from "utils/alerts";
import { courseHomeUrl } from "utils/urls";

type SignupFormValue = PasswordFormValue & {
  email: string;
  first_name: string;
  last_name: string;
  eula: boolean;
};

interface SignupViewProps {
  checkActiveUser: (justLoggedIn?: boolean) => void;
  userInviteError?: string;
  setUserInviteError?: (error: string | null) => void;
}

/**
 * A view with a registration form, for users who are not logged in and have an invite key.
 *
 * We don't currently support registration outside of following invite links, so
 * users arriving at this view will be expected to have an `invite_key` query parameter set,
 * and not be logged in already.
 * They'll be show a registration page to create an account and join the course. After creation,
 * the user will be redirected to the course page.
 *
 * Logged-in user with an `invite_key` should never be shown this view, as the index route
 * should redeem the key to ensure the user is part of the desired course before
 * forwarding them to the course route.
 */
export const SignupView: FC<SignupViewProps> = ({
  checkActiveUser,
  userInviteError,
  setUserInviteError,
}) => {
  const [searchParams] = useSearchParams();
  const inviteKey = searchParams.get("invite_key");
  const userId = useAppSelector(selectCurrentUserId);
  const currentCourse = useAppSelector(selectCurrentCourse);

  const [isLoading, setIsLoading] = useState<boolean>(!userInviteError);
  const [inviteError, setInviteError] = useState<string | null>(userInviteError);
  const [courseTitle, setCourseTitle] = useState<string | null>(null);
  const {
    value: isShowingLoginView,
    setTrue: showLoginView,
    setFalse: hideLoginView,
  } = useBoolean(false);

  const form = useForm<SignupFormValue>();

  useEffect(() => {
    const fetchCourseTitle = async () => {
      try {
        const course = await serverGetCourseMetadataFromInviteKey(inviteKey);

        setCourseTitle(course.title);
      } catch (error) {
        const errorKey =
          error instanceof AxiosError && error.response?.status === 403
            ? "invalid_invite_key"
            : "unknown_error";

        setInviteError(t(`user_options_menu.redeem.${errorKey}`));
      } finally {
        setIsLoading(false);
      }
    };

    if (inviteKey && !inviteError) {
      fetchCourseTitle();
    }
  }, [setCourseTitle, inviteKey, inviteError]);

  const handleSubmit: SubmitHandler<SignupFormValue> = async (formData) => {
    await serverRegisterUserWithInviteKey(inviteKey, {
      ...formData,
      // TODO: Rename password_confirm to confirm_password on the CourseInvite model.
      password_confirm: formData.confirm_password,
    })
      .then(() => {
        window.location.href = "/";
      })
      .catch((error: AxiosError) => {
        if (error instanceof AxiosError && error.response?.status === 400 && error.response?.data) {
          const data = error.response?.data as
            | FormattedValidationError<PasswordErrorKey>
            | { email: string[] };

          let message = "";
          let field = "";
          if ("email" in data) {
            message = (data as { email: string[] }).email.join(" "); // TODO: Localize email errors
          } else {
            for (const [errorField, { details, t: translationKeys }] of Object.entries(data)) {
              message += getPasswordErrorMessages(translationKeys, details);
              field = errorField;
            }
          }

          if (!message) {
            toastLocalizedOperationCatcher("signup_action_failure")(error);
          } else {
            if (field === "password" || field === "confirm_password") {
              form.setError(field, {
                type: "server",
                message,
              });
            }
            toastErrorMessage(message);
          }
        } else {
          toastLocalizedOperationCatcher("signup_action_failure")(error);
        }
      });
  };

  if (isLoading) {
    return (
      <div className="m-2 w-full max-w-96 p-4">
        <LoadingScreen text={t("common.loading")} />
      </div>
    );
  }

  if (inviteError) {
    // If we do sign in here, we don't want to include the bad invitation but rather
    // start fresh from the course home page.
    const buttonKey = userId ? "main_header.back_to_home" : "user_info.sign_in";

    return (
      <div className="m-2 w-full max-w-96 p-4">
        <h2 className="my-2">{inviteError}</h2>

        <div className="mt-8">
          {!userId && t("user_options_menu.redeem.already_have_account")}

          <Button
            kind="secondary"
            to={courseHomeUrl(currentCourse?.id)}
            // This call here is actually to force the parent component to reload.  Without it the
            // url might switch but the parent wouldn't refresh to point to the new location.
            onClick={() => setUserInviteError?.(null)}
          >
            {t(buttonKey)}
          </Button>
        </div>
      </div>
    );
  }

  if (isShowingLoginView) {
    return (
      <div className="m-2 w-full max-w-96">
        <LoginView onLoggedIn={() => checkActiveUser(true)}>
          <div className="w-full">
            <Button className="mt-4" kind="secondary" size="s" onClick={hideLoginView}>
              {t("user_options_menu.redeem.back_to_sign_up")}
            </Button>
          </div>
        </LoginView>
      </div>
    );
  }

  return (
    <div className="m-2 w-full max-w-96 p-4">
      {/* TODO: Rewrite main-navbar so that we don't need !pl-0 override its properties */}
      <div className="main-navbar mb-3 flex w-full items-center justify-between bg-white !pl-0 text-black-tint-20">
        <span className="mb-2 flex cursor-pointer items-center">
          <ForumLogoIcon width={LOGO_FORM_SIZE} />
        </span>
      </div>

      <h2 className="mb-2">{t("user_options_menu.redeem.signup_title")}</h2>

      <div className="body-m mb-4 pt-1">
        <Trans
          components={{ bold: <b /> }}
          i18nKey="user_options_menu.redeem.signup_subtitle"
          t={t}
          values={{ courseTitle }}
        />
      </div>

      <FormProvider {...form}>
        <form noValidate onSubmit={form.handleSubmit(handleSubmit)}>
          <div className="mb-3 flex w-full flex-col gap-3">
            <div>
              <label>
                <div className="body-m-bold">{t("user_info.email")}</div>
                <input
                  {...form.register("email", {
                    required: t("error.field_required"),
                    validate: {
                      emailMustHaveAtSign,
                      emailMustBeValid,
                    },
                  })}
                  aria-invalid={form.formState.errors.email ? "true" : "false"}
                  autoComplete="email"
                  className="email-login-form-input"
                  id="email"
                  type="email"
                />
              </label>

              <ErrorMessageBanner className="body-s mt-1 text-wrap break-all" name="email" />
            </div>

            <div>
              <label>
                <div className="body-m-bold">{t("user_info.first_name")}</div>
                <input
                  {...form.register("first_name", {
                    required: t("error.field_required"),
                  })}
                  aria-invalid={form.formState.errors.first_name ? "true" : "false"}
                  autoComplete="given-name"
                  className="email-login-form-input"
                  id="first_name"
                  type="text"
                />
              </label>
              <ErrorMessageBanner className="body-s mt-1" name="first_name" />
            </div>

            <div>
              <label>
                <div className="body-m-bold">{t("user_info.last_name")}</div>
                <input
                  {...form.register("last_name", {
                    required: t("error.field_required"),
                  })}
                  aria-invalid={form.formState.errors.last_name ? "true" : "false"}
                  autoComplete="family-name"
                  className="email-login-form-input"
                  id="last_name"
                  type="text"
                />
              </label>
              <ErrorMessageBanner className="body-s mt-1" name="last_name" />
            </div>
          </div>

          <div className="mb-3 flex w-full flex-col gap-3">
            <PasswordForm isNewPassword useEmailLoginFormStyling />
          </div>

          <div>
            <label className="flex items-baseline gap-2">
              <input
                {...form.register("eula", {
                  required: t("user_options_menu.redeem.eula_required"),
                })}
                aria-invalid={form.formState.errors.eula ? "true" : "false"}
                type="checkbox"
              />
              <div>
                <Trans i18nKey="user_info.eula">
                  I agree to the
                  <Link className="text-blue-shade-20" target="_blank" to="/terms-of-service-en">
                    Terms of Service
                  </Link>
                  and
                  <Link className="text-blue-shade-20" target="_blank" to="/privacy-policy-en">
                    Privacy Policy
                  </Link>
                </Trans>
              </div>
            </label>
            <ErrorMessageBanner className="body-s mt-1" name="eula" />
          </div>

          {/* This invisible field is required for LastPass to recognize the autofill */}
          <input className="sr-only" type="submit" value="Signup" />
          <Button
            className="mt-4"
            isLoading={form.formState.isSubmitting}
            kind="primary"
            type="submit"
          >
            {t("user_info.sign_up")}
          </Button>
        </form>
      </FormProvider>

      <div className="mt-8">
        {t("user_options_menu.redeem.already_have_account")}
        <Button className="mt-4" kind="secondary" onClick={showLoginView}>
          {t("user_info.sign_in")}
        </Button>
      </div>
    </div>
  );
};
