import { cloneDeep } from "lodash";
import { FC, useEffect, useMemo } from "react";

import { NavList } from "../NavList";

import { TERM_ID_QUERY_PARAM } from "components/constants";
import { CourseDetailCourseCodeView } from "components/materials/org/terms/CourseDetailCourseCodeView";
import { t } from "i18n/i18n";
import { CalendarIcon } from "mds/icons";
import { storeApi, useAppSelector } from "store/index";
import { selectCourseCodeCoursesByTerms, selectTerms } from "store/selectors";
import { selectCanAuthorOrg } from "store/selectors/permissions";
import { useFindItemBySearchParamId } from "utils/hooks/use-find-item-by-search-param-id";

export const TermsView: FC = () => {
  const terms = useAppSelector(selectTerms);
  const canAuthorOrg = useAppSelector(selectCanAuthorOrg);
  const courseCodeCoursesByTerm = useAppSelector(selectCourseCodeCoursesByTerms);
  const orderedTerms = useMemo(() => {
    const termsToOrder = canAuthorOrg
      ? terms
      : terms.filter((term) => {
          const courseCodeCourses = courseCodeCoursesByTerm[term.id];
          return courseCodeCourses.some(
            (courseCode) => courseCode.courses && courseCode.courses.length > 0,
          );
        });

    return termsToOrder.sort((a, b) => a.order - b.order).map(cloneDeep);
  }, [terms, canAuthorOrg, courseCodeCoursesByTerm]);

  const currentTerm = useFindItemBySearchParamId(orderedTerms, TERM_ID_QUERY_PARAM);

  useEffect(() => {
    storeApi.course_codes.list();
    storeApi.terms.list();
  }, []);

  return (
    <main className="h-full w-full bg-white">
      <div className="flex rounded-md border border-black-tint-90">
        {(orderedTerms.length > 0 || canAuthorOrg) && (
          <NavList
            api={storeApi.terms}
            Icon={CalendarIcon}
            items={orderedTerms}
            model="term"
            queryParam={TERM_ID_QUERY_PARAM}
            title={t("org.tabs.terms")}
          />
        )}
        <div className="h-full flex-auto overflow-y-auto">
          <CourseDetailCourseCodeView term={currentTerm} />
        </div>
      </div>
    </main>
  );
};
