import "../CourseDetailView.scss";

import { FC } from "react";

import { LoadingScreen } from "components/LoadingScreen";
import { HoverableEditField } from "components/hover-widgets/EditableField";
import { HoverableToolbar } from "components/hover-widgets/HoverableToolbar";
import { CourseListPanel } from "components/materials/org/CourseListPanel";
import { TermType } from "components/server-types";
import { t } from "i18n/i18n";
import { useAppSelector } from "store/index";
import { selectCourseCodeCoursesByTerm } from "store/selectors";
import { selectCanAuthorOrg } from "store/selectors/permissions";
import { useListSelector } from "store/store-hooks";
import { onTitleChanged } from "utils/store-utils";

type CourseDetailCourseCodeViewProps = {
  term: TermType;
};

export const CourseDetailCourseCodeView: FC<CourseDetailCourseCodeViewProps> = ({ term }) => {
  const courseCodeCourses = useListSelector((s) => selectCourseCodeCoursesByTerm(s, term?.id));
  const canAuthorOrg = useAppSelector(selectCanAuthorOrg);

  if (!term) {
    return <LoadingScreen text={t("org.settings.no_terms")} />;
  }

  if (!courseCodeCourses.length) {
    return <LoadingScreen text={t("org.settings.no_courses")} />;
  }

  const filteredCourseCodes = canAuthorOrg
    ? courseCodeCourses
    : courseCodeCourses.filter((code) => code.courses && code.courses.length > 0);

  return (
    <div className="course-detail-page">
      <HoverableToolbar
        contentClassName="mt-1.5"
        reference="term"
        showEditButton={canAuthorOrg}
        uncentered
      >
        <h2 className="my-0">
          <HoverableEditField
            className="code-title"
            label={t("glossary.term_title")}
            textSize="h2"
            value={term.title}
            onValueChanged={(newTitle) => onTitleChanged("terms", term.id, newTitle)}
          />
        </h2>
      </HoverableToolbar>

      {filteredCourseCodes.map((courseCode) => (
        <div className="course-panel" key={courseCode.id}>
          <CourseListPanel
            containerField="course_code_id"
            containerId={courseCode.id}
            courses={courseCode.courses}
            newCourseProps={{ term_id: term.id }}
            title={courseCode.title}
          />
        </div>
      ))}
    </div>
  );
};
