import clsx from "clsx";
import { type FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { SUBMISSION_ID_QUERY_PARAM } from "components/constants";
import { AssessmentStatusIcon } from "components/materials/page/assessment/AssessmentStatusIcon";
import { getSubmissionTimeText, useNameFromPageAccessId } from "components/materials/page/helpers";
import { GroupingCategory, PageType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { IconText } from "mds/components/IconText";
import { ClickEvent, Menu, MenuItem } from "mds/components/Menu";
import { ChevronDownIcon } from "mds/icons";
import { useAppSelector } from "store/index";
import {
  FullSubmissionType,
  selectCanAuthorCourse,
  selectCurrentCourse,
  selectFullSubmissionById,
  selectIsAssessing,
  selectSortedUserFullSubmissionsForPage,
} from "store/selectors";

export type SubmissionMenuProps = {
  page: PageType;
  selectedAccessId?: string;
  showDraft?: boolean;
};

type SubmissionMenuItemType = "draft" | FullSubmissionType;

/**
 * A dropdown menu that allows the user to select a submission to view. The menu assumes that there
 * is always at least one submission in the submissions list by the time the user is viewing this menu.
 * There are multiple ways to see this dropdown, 1) the student is on their My Submission tab, 2)
 * the instructor is in assessment mode, or 3) the instructor is on the All Responses tab (showResponses) and
 * there are student submissions (where the dropdown menu will show both Draft and all the student's submissions).
 *
 * Seeing either the My Submission tab or accessing assessment mode are gated by whether there are any
 * submissions.
 *
 * By nature of how we collect submissions, there should also always be a submission for each student
 * by the time we reach this menu. There are two ways to collect submissions:
 * - the "Collect" button from the instructor, which will collect a submission per student in the course
 * - automatically collecting a submission per student on due date
 *
 * There should be no early submission for an individual student, and even if a student turns in a late
 * submission late, there should already be a default submission collected on due date. */
export const SubmissionMenu: FC<SubmissionMenuProps> = ({
  page,
  selectedAccessId,
  showDraft = false,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const submissionId = searchParams.get(SUBMISSION_ID_QUERY_PARAM);
  const currentSubmission = useAppSelector((s) => selectFullSubmissionById(s, submissionId));
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const submissions = useAppSelector((s) =>
    selectSortedUserFullSubmissionsForPage(s, page, selectedAccessId),
  );
  const name = useNameFromPageAccessId(selectedAccessId, page.id);

  const officialSubmission = submissions.find((s) => s.official_at);

  // If there's an official submission, that should be select by default.
  // If not, and there's at least one unofficial submission, the most recent
  //   unofficial submission should be selected by default.
  // Only if there are no submissions at all, should the draft be selected by default
  //   -- and then, only if we are showing the draft.
  const defaultMenuItem = submissions.length > 0 ? officialSubmission || submissions[0] : null; // null means draft

  useEffect(() => {
    if (!submissionId && defaultMenuItem) {
      setSearchParams((sp) => {
        sp.set(SUBMISSION_ID_QUERY_PARAM, defaultMenuItem.id);
        return sp;
      });
    }
  }, [selectedAccessId, submissionId, defaultMenuItem, setSearchParams]);

  const isAssessing = useAppSelector(selectIsAssessing);
  const course = useAppSelector(selectCurrentCourse);
  const assessmentsPublished = page?.assessments_published_at !== null;
  const anonymousNames = isAssessing && !assessmentsPublished && course.anonymous_grading_enabled;

  if (submissions.length === 0 || (!currentSubmission && !showDraft)) {
    return null;
  }

  const selectedSubmissionIsDraft = submissionId === "draft";

  const MenuButton = (
    <Button className="gap-2" kind="secondary" size="xs">
      {selectedSubmissionIsDraft ? (
        t("submission_menu.draft")
      ) : currentSubmission ? (
        <IconText
          className={clsx({ "icon-green": assessmentsPublished })}
          iconEnd={
            <AssessmentStatusIcon
              accessId={selectedAccessId}
              assessmentsPublished={assessmentsPublished}
              canAuthorCourse={canAuthorCourse}
              page={page}
              selectedSubmission={currentSubmission}
              showLate
            />
          }
          text={getSubmissionTimeText(currentSubmission)}
        />
      ) : null}
      <ChevronDownIcon />
    </Button>
  );

  const handleItemClick = (e: ClickEvent) => {
    const newSubmission = e.value as SubmissionMenuItemType;
    if (newSubmission === "draft") {
      searchParams.set(SUBMISSION_ID_QUERY_PARAM, "draft");
    } else {
      searchParams.set(SUBMISSION_ID_QUERY_PARAM, newSubmission.id);
    }
    setSearchParams(searchParams);
  };

  return (
    <div className="flex flex-col gap-2">
      {submissions.length > 1 && (
        <div className="text-black-tint-40">
          {canAuthorCourse
            ? t("submission_menu.multiple_name", {
                name: anonymousNames
                  ? page.grouping_category === GroupingCategory.INDIVIDUAL
                    ? t("glossary.student")
                    : t("glossary.group")
                  : name,
              })
            : t("submission_menu.multiple_self")}
        </div>
      )}
      <Menu
        direction="bottom"
        menuButton={MenuButton}
        position="initial"
        onItemClick={handleItemClick}
      >
        {showDraft && (
          <MenuItem
            checked={selectedSubmissionIsDraft}
            className={clsx({
              selected: selectedSubmissionIsDraft,
              "icon-white": selectedSubmissionIsDraft,
            })}
            value="draft"
          >
            {t("submission_menu.draft")}
          </MenuItem>
        )}
        {submissions.map((submission) => {
          const selected = !selectedSubmissionIsDraft && currentSubmission?.id === submission.id;
          return (
            <MenuItem
              checked={selected}
              className={clsx({
                selected,
                "icon-green": assessmentsPublished,
              })}
              key={submission.id}
              value={submission}
            >
              <IconText
                className="w-full justify-between"
                iconEnd={
                  <AssessmentStatusIcon
                    accessId={selectedAccessId}
                    assessmentsPublished={assessmentsPublished}
                    canAuthorCourse={canAuthorCourse}
                    page={page}
                    selected={selected}
                    selectedSubmission={submission}
                    showLate
                  />
                }
                text={getSubmissionTimeText(submission)}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};
