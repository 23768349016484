import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { PageAssessmentStatusType } from "components/server-types";
import { createReplaceAllReducer } from "store/utils";

type ComputedState = {
  pageAssessmentStatuses: PageAssessmentStatusType;
};

const initialState = () =>
  ({
    pageAssessmentStatuses: {},
  }) as ComputedState;

export const ComputedStore = createSlice({
  name: "computed",
  initialState,
  reducers: {
    savePageAssessmentStatuses: (state, action: PayloadAction<PageAssessmentStatusType>) => {
      state.pageAssessmentStatuses = {
        ...state.pageAssessmentStatuses,
        ...action.payload,
      };
    },
  },
  extraReducers: createReplaceAllReducer<ComputedState>("computed", initialState),
});

export const computedStateActions = ComputedStore.actions;
