import { ErrorMessage } from "@hookform/error-message";
import { AxiosError } from "axios";
import { type FC, useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { useBoolean } from "usehooks-ts";

import { getPasswordErrorMessages } from "../../../helpers/password-errors";
import { StudentPreview } from "../course/StudentPreview";

import { serverSetPassword } from "api/api-server";
import { LoadingScreen } from "components/LoadingScreen";
import { PasswordForm, PasswordFormValue } from "components/PasswordForm";
import { ErrorMessageBanner } from "components/forms/ErrorMessageBanner";
import { CourseUserAvatar } from "components/hover-widgets/CourseUserAvatar";
import { HoverableEditField } from "components/hover-widgets/EditableField";
import { HoverableToolbar } from "components/hover-widgets/HoverableToolbar";
import { FormattedValidationError, UserInformationType } from "components/server-types";
import { INTERFACE_LANGUAGES, LANGUAGE_LABELS, i18n, t } from "i18n/i18n";
import { PasswordErrorKey } from "i18n/i18next";
import { Button } from "mds/components/Button";
import { Card } from "mds/components/Card";
import { DropdownMenu } from "mds/components/DropdownMenu";
import { storeApi, useAppDispatch, useAppSelector } from "store/index";
import {
  selectCanAuthorCourse,
  selectCurrentCourseId,
  selectCurrentUserId,
  selectIsStudentPreview,
  selectPresentingSessions,
  selectUserById,
} from "store/selectors";
import { toastLocalizedOperationCatcher, toastSuccessMessage } from "utils/alerts";
import { trackEvent } from "utils/amplitude";
import { logoutAndClearCaches } from "utils/auth";
import { rollbarAndLogError } from "utils/logger";
import { stopPresenting } from "utils/presentation";

type SetPasswordFormValue = PasswordFormValue & {
  current_password: string;
};

export const UserProfileSidebarPanel: FC = () => {
  const dispatch = useAppDispatch();
  const { value: formIsShown, setFalse: hideForm, toggle: toggleShowForm } = useBoolean(false);

  const currentUserId = useAppSelector(selectCurrentUserId);
  const user = useAppSelector((s) => selectUserById(s, currentUserId));
  const presentingSessions = useAppSelector(selectPresentingSessions);
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);
  const isStudentPreview = useAppSelector(selectIsStudentPreview);
  const courseId = useAppSelector(selectCurrentCourseId);
  const showStudentPreviewCard = canAuthorCourse || isStudentPreview;

  const form = useForm<SetPasswordFormValue>();
  const currentPassword = form.watch("current_password");
  const onSubmit: SubmitHandler<SetPasswordFormValue> = (formData) => {
    serverSetPassword(formData)
      .then(() => {
        toastSuccessMessage(t("success.toasts.update_success", { item: t("glossary.password") }));
        hideForm();
        form.reset();
      })
      .catch((err: unknown) => {
        if (!(err instanceof AxiosError)) {
          rollbarAndLogError("serverSetPassword", err);
          return;
        }

        const respData = err.response?.data as FormattedValidationError<PasswordErrorKey>;

        if (!respData) {
          rollbarAndLogError("serverSetPassword", err);
          return;
        }

        for (const [field, { details, t: translationKeys }] of Object.entries(respData)) {
          form.setError(field as keyof SetPasswordFormValue, {
            type: "server",
            message: getPasswordErrorMessages(translationKeys, details),
          });
        }
      });
  };

  const handleInterfaceLanguageChange = (lang: string) => {
    i18n
      .changeLanguage(lang)
      .then(() =>
        trackEvent("UI language Changed", { eventCategory: "General", newLanguage: lang }),
      )
      .catch(toastLocalizedOperationCatcher("failed_to_change_language_option"));
    // Reload page, because we're not relying on react hooks to update the language
    window.location.reload();
  };

  useEffect(() => {
    storeApi.users.retrieve(currentUserId);
    storeApi.course_users.list({ user_id: currentUserId, course_id: courseId });
  }, [currentUserId, courseId]);

  const onLogout = () => {
    // Stop all sessions that the user is presenting in
    for (const session of presentingSessions) {
      stopPresenting(session.id);
    }

    logoutAndClearCaches(dispatch);
    trackEvent("Account settings - Logout", {
      eventCategory: "Button press",
    });
  };

  const onSaveUser = (data: Partial<Pick<UserInformationType, "first_name" | "last_name">>) => {
    storeApi.users.partial_update({ id: currentUserId, ...data });
  };

  if (!user) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div className="content-sidebar-header">
        <h3 className="mb-0">{t("profile_panel.title")}</h3>
      </div>

      <div className="content-sidebar-main">
        <Card>
          <h3>{t("profile_panel.info_title")}</h3>

          <div className="flex items-center gap-2">
            <CourseUserAvatar userId={currentUserId} />
            {user.email}
          </div>

          <div className="mt-2">
            <HoverableToolbar reference="first_name" showEditButton>
              <HoverableEditField
                className="px-2"
                label={t("user_info.first_name")}
                textSize="m"
                value={user.first_name}
                onValueChanged={(value) => onSaveUser({ first_name: value })}
              />
            </HoverableToolbar>
          </div>

          <div>
            <HoverableToolbar reference="last_name" showEditButton>
              <HoverableEditField
                className="px-2"
                label={t("user_info.last_name")}
                textSize="m"
                value={user.last_name}
                onValueChanged={(value) => onSaveUser({ last_name: value })}
              />
            </HoverableToolbar>
          </div>
        </Card>

        <Card className="mt-4">
          <div className="flex items-center justify-between">
            <h4 className="h4 my-2 text-black-tint-40">{t("profile_panel.choose_language")}</h4>

            <DropdownMenu
              items={INTERFACE_LANGUAGES.map((lang) => ({
                id: lang,
                title: LANGUAGE_LABELS[lang],
              }))}
              selectedValue={i18n.language}
              onSelectionChange={({ id }) => handleInterfaceLanguageChange(id)}
            />
          </div>
        </Card>

        <Card className="mt-4 flex flex-col gap-2">
          <Button
            className="-ml-2"
            kind="tertiary"
            onClick={() => {
              trackEvent("Account settings - Reset password", {
                eventCategory: "Button press",
              });
              toggleShowForm();
            }}
          >
            {t("profile_panel.reset_password")}
          </Button>

          {formIsShown && (
            // We need to determine how we should handle showing errors and success messages
            // globally though before we spend too much time on this.
            // Also the mechanism for password and confirm password should probably be shared
            // with the signup form.  Maybe the hidden lastpass field too.
            <FormProvider {...form}>
              <form
                onSubmit={form.handleSubmit((data) => {
                  trackEvent("Account settings - Update password", {
                    eventCategory: "Button press",
                  });
                  onSubmit(data);
                })}
              >
                <div className="items-first flex flex-col gap-3 pb-5">
                  <ErrorMessage name="root" />

                  <div className="body-s">
                    <label htmlFor="current_password">{t("user_info.current_password")}</label>

                    <div>
                      <input
                        {...form.register("current_password", {
                          required: t("error.field_required"),
                        })}
                        aria-invalid={form.formState.errors.current_password ? "true" : "false"}
                        autoComplete="current_password"
                        className="text-field body-m w-full text-black-tint-20"
                        id="current_password"
                        type="password"
                      />
                    </div>
                    <ErrorMessageBanner className="body-s mt-1" name="current_password" />
                  </div>

                  <PasswordForm currentPassword={currentPassword} />

                  {/* This invisible field is required for LastPass to recognize the autofill */}
                  <input className="m-0 hidden h-0 border-none p-0" type="submit" value="Signup" />

                  <Button kind="secondary" type="submit">
                    {t("user_info.update_password")}
                  </Button>
                </div>
              </form>
            </FormProvider>
          )}
        </Card>

        {showStudentPreviewCard && (
          <Card className="mt-4">
            <StudentPreview />
          </Card>
        )}

        <Card className="mt-4">
          <Button className="-ml-2 text-red" kind="tertiary" onClick={onLogout}>
            {t("profile_panel.logout")}
          </Button>
        </Card>

        <div className="body-xs ml-4 mt-2">
          <Trans i18nKey="user_info.eula_footer">
            <Link target="_blank" to="/terms-of-service-en">
              Terms of Service
            </Link>
            &
            <Link target="_blank" to="/privacy-policy-en">
              Privacy Policy
            </Link>
          </Trans>
        </div>
      </div>
    </>
  );
};
