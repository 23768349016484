import { selectCanAuthorCourse } from "./permissions";

import { RootState } from "store";

export const selectOnBoardingCourse = (state: RootState, courseId: string) =>
  state.local.onBoardingCourses[courseId];

export const selectIsSidebarOpen = (state: RootState) => state.local.isSidebarOpen;

export const selectIsEditModeEnabled = (state: RootState) =>
  state.local.editMode && selectCanAuthorCourse(state);

export const selectIsAssessWithAIModeEnabled = (state: RootState) =>
  state.local.assessWithAIMode && selectCanAuthorCourse(state);

export const selectIsStudentResponsesForAIEnabled = (state: RootState) =>
  state.local.isStudentResponsesForAIEnabled;
