import { get } from "lodash";
import { Op } from "sharedb/lib/client";

import {
  GLOBAL_SHARED_ACCESS_ID,
  groupAccessIdFromGroupId,
  studentAccessIdFromUserId,
} from "../shared/access-id";
import { ACCESS_ID_DATA_META_FIELDS, BLOCKS_PATH, DATA_PATH } from "../shared/constants";
import { AUTH_ERRORS, WebsocketUserDataType } from "../shared/types/websocket-auth";
import { AccessIdMetaDataType, WorksheetType } from "../shared/types/worksheet";

export const isStaff = (role: WebsocketUserDataType["role"]) => role === "staff";

/**
 * This is a helpers function to figure out the specific intention / meaning of
 * an operation performed on a sharedb document. We use this to determine whether
 * the operation is executing allowed actions or not.
 */
export const inspectOp = (op: Op) => {
  const path = op.p;
  const isDataOp = op.p[0] === DATA_PATH;
  // Our worksheet data structure defines subpaths for specific user/groups data, and in each,
  // subpaths for different fields. These subpaths need to be initialized by the client before
  // writing to them initially. We call these structures "accessIdWrapper" and "fieldDataWrapper".
  // Operations to initialize them are generally allowed, even if you don't have write permissions
  // otherwise, to simplify the client's logic, but in turn, we have to rigorously check that
  // operations writing to these paths are well-formed, so as to not overwrite existing data or
  // create malformed data.
  const isInitializingAccessIdWrapper = isDataOp && path.length === 2;
  const isInitializingFieldWrapper =
    isDataOp &&
    "oi" in op &&
    path.length === 3 &&
    !ACCESS_ID_DATA_META_FIELDS.includes(path[2] as keyof AccessIdMetaDataType);
  const isWrapperOp = "oi" in op && typeof op.oi === "object" && op.oi !== null;
  const isAccessIdWrapperOp =
    isWrapperOp && Object.keys(op.oi as object).length === 1 && "versions" in op.oi;
  // Every field wrapper is initialized with different kinds of data, so it's hard to make any specific
  // checks. For now, we just check for whether there is a wrapper object, and don't check the content.
  const isFieldDataWrapperOp = isWrapperOp;

  const checks = {
    isDataOp,
    isTemplateOp: op.p[0] === BLOCKS_PATH,
    isRootOp: op.p.length === 1,
    isAllowedAccessIdOp: (isStaffUser: boolean, allowedAccessIds: string[]) =>
      path.length >= 2 && (isStaffUser || allowedAccessIds.includes(String(path[1]))),
    isInitializingAccessIdWrapper,
    isInitializingFieldWrapper,
    isAccessIdWrapperOp,
    isFieldDataWrapperOp,
    isWrapperInitOp: isInitializingAccessIdWrapper || isInitializingFieldWrapper,
  };

  return checks;
};

export class AuthError extends Error {
  constructor(public code: keyof typeof AUTH_ERRORS) {
    super(`Auth error: ${code}`);
  }
}

/**
 * Returns a list of all access IDs the user is allowed to read/write in a document _specifically_.
 * This does not list implicit access IDs, like an admin having access to all documents, or a student
 * being able to see all other students' data after a rollup. Those cases are covered by
 * `userCanSeeOtherUsersDataAndPresence`
 */
export const getAllowedAccessIds = (docId: string, userData: WebsocketUserDataType) => {
  const { pages } = userData;
  const pagePermissions = pages[docId];
  if (isStaff(userData.role)) {
    // Staff users should not be calling this check
    throw new AuthError(AUTH_ERRORS.UNKNOWN_ERROR);
  }
  if (!pagePermissions) {
    return [];
  }
  return [
    GLOBAL_SHARED_ACCESS_ID,
    studentAccessIdFromUserId(userData.user_id),
    ...(pagePermissions.group_ids || []).map(groupAccessIdFromGroupId),
  ];
};

/**
 * Checks a write operation against the user's permissions.
 * Returns nothing if successful, or an error object detailing why the operation is not allowed.
 */
export const checkUserCanPerformOperation = (
  userData: WebsocketUserDataType,
  op: Partial<Op>,
  docId: string,
  docData: WorksheetType,
  // If we try to overwrite on the frontend we'll allow it to propogate to the backend as
  // some methods have special handling for the overwrite case.
  canOverwrite: boolean = false,
) => {
  const { role, pages } = userData;
  const path = op.p;
  const pagePermissions = pages[docId];
  const canAuthorPage = isStaff(role);
  const canWriteToPage = pagePermissions || canAuthorPage;
  const userOp = inspectOp(op as Op);
  const isOverWritingData = Boolean(get(docData, path));
  const allowedAccessIds = canAuthorPage ? [] : getAllowedAccessIds(docId, userData);
  const isAllowedAccessIdOp = userOp.isAllowedAccessIdOp(canAuthorPage, allowedAccessIds);

  // Read-only worksheets are never editable
  if (userOp.isRootOp) {
    // Can't replace any root elements except for "locked" status, and only for staff
    if (path[0] !== "locked" || !canAuthorPage) {
      throw new AuthError(AUTH_ERRORS.DOC_GLOBAL_EDIT_DISALLOWED);
    }
  } else if (userOp.isTemplateOp) {
    // Template data operations are only allowed for staff
    if (!canAuthorPage) {
      throw new AuthError(AUTH_ERRORS.MISSING_EDIT_PERMISSION);
    }
  } else if (docData.locked && !userOp.isWrapperInitOp) {
    // No operations are allowed on locked documents, except wrapper initialization
    throw new AuthError(AUTH_ERRORS.DOC_READ_ONLY);
  } else if (userOp.isDataOp) {
    // User data operations are only allowed for staff or users with explicit permissions
    if (userOp.isWrapperInitOp) {
      // It's fine to initialize a new access id wrapper (for anyone, with any permissions)
      // or a field data wrapper, even if the document is read-only and for other access IDs,
      // but you can't replace or delete existing wrappers.
      if (isOverWritingData && !canOverwrite) {
        throw new AuthError(AUTH_ERRORS.OVERWRITE_DISALLOWED);
      }
      // If you are initializing an accessId wrapper, it should have the right data in it
      if (userOp.isInitializingAccessIdWrapper && !userOp.isAccessIdWrapperOp) {
        throw new AuthError(AUTH_ERRORS.ACCESS_ID_WRAPPER_MALFORMED);
      }
      if (userOp.isInitializingFieldWrapper && !userOp.isFieldDataWrapperOp) {
        throw new AuthError(AUTH_ERRORS.FIELD_DATA_WRAPPER_MALFORMED);
      }
    } else if (typeof path[1] !== "string") {
      throw new AuthError(AUTH_ERRORS.EDIT_MALFORMED);
    } else if (!canWriteToPage) {
      throw new AuthError(AUTH_ERRORS.MISSING_WRITE_PERMISSION);
    } else if (!isAllowedAccessIdOp) {
      // You can only write to your own access id data, unless you're an admin
      throw new AuthError(AUTH_ERRORS.MISSING_WRITE_PERMISSION_FOR_PATH);
    }
  } else {
    // All operations not strictly allowed are disallowed
    throw new AuthError(AUTH_ERRORS.DOC_GLOBAL_EDIT_DISALLOWED);
  }
};
