import clsx from "clsx";
import { get } from "lodash";
import { type FC, Suspense, lazy, useContext, useEffect, useMemo, useState } from "react";
import { Doc, type ObjectInsertOp } from "sharedb/lib/client";

import { canRollupAccessId, groupAccessIdFromGroupId } from "../../shared/access-id";
import {
  DATA_PATH,
  DEFAULT_FIELD_VERSION_NUMBER,
  STATIC_CONTENT_FIELD_TYPES,
  VALUE_KEY,
  initializeStudentDataForField,
} from "../../shared/constants";
import { AUTH_ERRORS } from "../../shared/types/websocket-auth";
import {
  type CellType,
  type CodeCellType,
  type FieldType,
  type PathType,
  type RichTextCellType,
  type WorksheetType,
} from "../../shared/types/worksheet";
import { ChoiceFieldAggregate } from "../Aggregate/ChoiceFieldAggregate";

import { AssetField } from "./AssetField";
import { ChoicesAuthoringField } from "./AuthoringFields";
import { ChoiceField } from "./ChoiceField";
import { EmbedField } from "./EmbedField";
import { FileField } from "./FileField";
import { RichTextField } from "./RichText/RichTextField";
import { TableField } from "./TableField";

import { ErrorScreen, LoadingTextIndicator } from "components/LoadingScreen";
import { WAITED_TOO_LONG_TIMEOUT_MS } from "components/constants";
import { HoverableToolbar } from "components/hover-widgets/HoverableToolbar";
import { isGroupPage, isValidAccessIdForPageType } from "components/materials/page/groups/helpers";
import { FeatureButton } from "components/materials/presentation/featuring/FeatureButton";
import { PageType } from "components/server-types.ts";
import { t } from "i18n/i18n";
import { ORDER_BY_CREATED, useCustomOrdered } from "mds/hooks/use-ordered";
import { DocViewContext } from "providers/DocViewProvider";
import { useAppSelector } from "store/index";
import { selectPageById, selectPageGroupsByPage } from "store/selectors";
import { rollbarAndLogError } from "utils/logger";
import { FeatureStatus, usePresentation } from "utils/presentation";
import { getUsername } from "utils/user-utils";
import { useUsersFromAccessId } from "utils/worksheet";

const CodeField = lazy(() => import("./Code/CodeField"));

const DO_NOT_ROLL_UP_FIELDS = STATIC_CONTENT_FIELD_TYPES;

interface FieldComponentProps {
  fieldPath: PathType;
  doc: Doc<WorksheetType>;
  path: PathType;
  blockId: string;
}

const FieldComponent: FC<FieldComponentProps> = ({ fieldPath, doc, path, blockId }) => {
  const {
    showResponses,
    canAuthorPage,
    isAuthoringPage,
    canInteractAsStudent,
    isViewingSeededContentTab,
    isProjecting,
    readOnly,
    accessId,
    isFeaturing,
  } = useContext(DocViewContext);
  const field = get(doc.data, fieldPath) as FieldType;
  const type = field.t;

  // fieldUserData may be undefined if either:
  // - The field data hasn't been initialized yet (for various reasons)
  // - The access ID data hasn't been initialized yet (for various reasons)
  // e.g. a Submission page collected for a user who never accessed the page, or simply
  // during the loading process of the page, while we're waiting for initialization.
  const fieldUserData = get(doc.data, path) as CellType;

  // This determines whether we initialize user-specific data for this field for the current
  // accessId. This should only happen once per field per user/group, on first access, exists.
  const [initError, setInitError] = useState<string>(null);
  const needsFieldDataInit =
    !initError && !fieldUserData && !readOnly && !STATIC_CONTENT_FIELD_TYPES.includes(type);

  // If we are in readonly mode and haven't had a chance to initialize the field data yet,
  // we show the template data as the fallback user content, until user content is initialized
  // on the next non-readonly session.
  const shouldShowTemplateData =
    isViewingSeededContentTab ||
    ((canAuthorPage || isProjecting || !accessId || (!fieldUserData && readOnly)) &&
      !showResponses &&
      !canInteractAsStudent);
  const userFieldPath = shouldShowTemplateData ? fieldPath : path;

  // We don't know what users will access a document ahead of time, so we initialize
  // student paths ad-hoc, when a component using it is first mounted.
  useEffect(() => {
    if (needsFieldDataInit) {
      const defaultUserData = initializeStudentDataForField(field);
      const userDataOp = { p: path, oi: defaultUserData } as ObjectInsertOp;
      const ops = [userDataOp];

      const versionsPath = [...path.slice(0, -1), "versions"];
      const versionsData = get(doc.data, versionsPath) as Record<string, number>;
      if (versionsData && !versionsData[field.id]) {
        const fieldVersionOp = {
          p: [...versionsPath, field.id],
          // There are old Pages created before August 12, 2024 that don't have
          // version data on some fields and fails to initialize. This is to
          // prevent them from breaking in this situation.
          oi: field.ver || DEFAULT_FIELD_VERSION_NUMBER,
        };
        ops.push(fieldVersionOp);
      }

      // If we somehow take longer than three seconds to do this, assume it's a backend issue,
      // and give the user a chance to retry.
      const timeout = setTimeout(() => {
        setInitError("Timeout");
        rollbarAndLogError("Field data init error", {
          err: "useEffect timeout",
          path: path.join("."),
          docId: doc.id,
        });
      }, WAITED_TOO_LONG_TIMEOUT_MS);

      doc.submitOp(ops, {}, (err) => {
        clearTimeout(timeout);
        // We ignore overwrite errors, as we assume the remote state (where the data exists),
        // is the correct one, and will be synced to the local state soon.
        if (err && err.message !== AUTH_ERRORS.OVERWRITE_DISALLOWED) {
          setInitError(err.message);
          rollbarAndLogError("Field data init error", {
            err: err.message,
            path: path.join("."),
            docId: doc.id,
          });
        }
      });
      return () => clearTimeout(timeout);
    }
  }, [path, needsFieldDataInit, field, doc]);

  // RichText and Table fields expect their data to be nested under a "v" key.
  // For student content paths in these fields, we omit the "v" nested key,
  // because we store just the data and not the field metadata.
  const dataPathForRichText = useMemo(
    () =>
      shouldShowTemplateData || type === "Text" ? ([...fieldPath, VALUE_KEY] as string[]) : path,
    [fieldPath, type, path, shouldShowTemplateData],
  );

  // If an error happens during initialization, instead of re-trying automatically and possibly
  // getting stuck in a loop, allow for manual retries, and show a graceful message.
  if (initError) {
    return (
      <ErrorScreen
        retryText={t("worksheet.error.try_again")}
        text={<div className="body-m">{t("worksheet.error.field_init")}</div>}
        onRetry={() => setInitError(null)}
      />
    );
  }

  // If we decided to fire the useEffect for "needsFieldDataInit", wait until the
  // submitOp is through and the fieldUserData is initialized before rendering the field.
  if (needsFieldDataInit) {
    // TODO: Set the height of the loading screen to ESTIMATED_AVG_FIELD_HEIGHT_PX_MAP
    return <LoadingTextIndicator className="w-full" text={t("worksheet.loading.field_init")} />;
  }

  if (type === "Text") {
    return (
      <RichTextField
        characterCounter="NONE"
        doc={doc}
        offerEmbedOnPasteURL={isAuthoringPage}
        path={dataPathForRichText}
        readOnly={!isAuthoringPage || isFeaturing}
        showPlaceholder={canAuthorPage}
        {...field}
      />
    );
  }

  if (type === "RichTextField") {
    // If we're showing responses, do not show the character counter.
    const characterCounter = showResponses
      ? "NONE"
      : // Otherwise, the character counter is normally only readable, not editable.
        // But instructors can edit the character counter if edit mode is on
        // and the block is not being featured.
        isAuthoringPage && !isFeaturing
        ? "EDIT"
        : "READ";
    // Note the character counter will never be shown if no maxLength is set
    // and the counter is in a read-only state.
    return (
      <RichTextField
        characterCounter={characterCounter}
        dataPathForCounter={fieldPath}
        doc={doc}
        path={dataPathForRichText}
        {...field}
      />
    );
  }

  if (type === "TableField") {
    return <TableField {...field} doc={doc} fieldPath={fieldPath} path={dataPathForRichText} />;
  }

  if (type === "CodeField") {
    return <CodeField {...field} doc={doc} path={userFieldPath} />;
  }

  // The rest of the fields don't have collaborative data, or don't have collaborative data
  // that is used as a seed for students.

  if (type === "ChoiceField") {
    return isAuthoringPage && !isFeaturing ? (
      <ChoicesAuthoringField {...field} doc={doc} path={fieldPath} />
    ) : (
      <ChoiceField {...field} doc={doc} path={path} />
    );
  }

  if (type === "FileField") {
    return <FileField {...field} doc={doc} path={path} />;
  }

  if (type === "AssetField") {
    return <AssetField {...field} blockId={blockId} doc={doc} path={fieldPath} />;
  }

  if (type === "EmbedField") {
    return <EmbedField {...field} doc={doc} path={fieldPath} />;
  }

  throw new Error(`Field type "${String(type)}" is not supported.`);
};

const isStringEmpty = (stringData: RichTextCellType) => {
  // We first determine if it's rich text, then whether there is something
  // parseable in the data structure, and then look for any op with text
  // content which is longer than 0.
  if (!stringData) {
    return true;
  }
  if (!Array.isArray(stringData.ops)) {
    return true;
  }
  return !stringData.ops.some((op) => typeof op.insert === "string" && op.insert.trim().length > 0);
};

const isCodeEmpty = (codeData: CodeCellType) => {
  // value is the default contents from the template, we want to compare
  // against this to check if the trimmed template value (excluding white
  // space) is identical to the trimmed actual value, or if the trimmed
  // actual value is empty
  const trimData = ((codeData && codeData.c) || "").trim();
  return trimData.length === 0;
};

const isFieldEmpty = (type: FieldType["t"], data: CellType) => {
  // TODO: Add other field types
  return (
    (type === "RichTextField" && isStringEmpty(data as RichTextCellType)) ||
    (type === "CodeField" && isCodeEmpty(data as CodeCellType))
  );
};

interface DocAuthorListProps {
  accessId: string;
  groupIndex?: number;
  page: PageType;
}

const DocAuthorList: FC<DocAuthorListProps> = ({ accessId, groupIndex, page }) => {
  const users = useUsersFromAccessId(accessId, page);

  if (!users) {
    // This happens when we create groups, delete them, and then re-create new groups while
    // students are in them, or when users get removed from a course after they have already
    // added data. We log this for debugging but it's common enough we don't need to report it.
    console.warn("DocAuthorList: Missing user data", { accessId, page });
    return (
      <div className="flex items-center py-2">
        <div className="body-s-bold">{t("field.no_authors")}</div>
      </div>
    );
  }

  const usernameList = users.map(getUsername).join(", ");

  return (
    <div className="flex items-center truncate py-2">
      <div className="body-s-bold truncate">
        {groupIndex === undefined
          ? usernameList
          : t("common.group_title_and_members_list", {
              number: groupIndex + 1,
              members: usernameList,
            })}
      </div>
    </div>
  );
};

interface FieldProps {
  fieldPath: PathType;
  accessIdPath: PathType;
  doc: Doc<WorksheetType>;
  blockId: string;
}

export const Field: FC<FieldProps> = ({ doc, fieldPath, blockId, accessIdPath }) => {
  const { accessId, pageId, isProjecting, showResponses } = useContext(DocViewContext);
  const pageGroups = useCustomOrdered(
    useAppSelector((s) => selectPageGroupsByPage(s, pageId)),
    ORDER_BY_CREATED,
  );
  const pageGroupAccessIds = pageGroups.map((group) => groupAccessIdFromGroupId(group.id));

  const field = get(doc.data, fieldPath) as FieldType;

  // Arrays get re-created on render, so we memoize to prevent prop-based re-renders in Children
  // Path that the Field will use to read/write student content.
  const id = field?.id;
  const fieldUserDataPath = useMemo(() => [...accessIdPath, id], [accessIdPath, id]);
  const page = useAppSelector((s) => selectPageById(s, pageId));
  const { isPresenting, featureStatus } = usePresentation();

  if (!field) {
    return;
  }

  // If we are projecting we can't author the page
  const canFeature =
    !isProjecting &&
    isPresenting &&
    (showResponses === "ALL" ||
      featureStatus === FeatureStatus.PARTICIPANT_RESPONSE ||
      (featureStatus === FeatureStatus.NONE && showResponses === "ONE"));

  const type = field.t;
  const fieldClassName = `field-${type}`;
  const fieldDebugAttrs = {
    "data-test-id": fieldPath.join("."),
    "data-user-data-path": fieldUserDataPath.join("."),
  };

  const skipRollup = DO_NOT_ROLL_UP_FIELDS.includes(type);
  if (!showResponses || skipRollup) {
    return (
      // TODO: Add a suspense "component loading" animation.
      // TODO: We should React.lazy import all components that can get rendered here.
      // TODO: Also use the estimated base field height as the height of loading screen,
      //       see ESTIMATED_AVG_FIELD_HEIGHT_PX_MAP
      <Suspense fallback={null}>
        <div className={clsx(`pb-1 ${fieldClassName}`)} {...fieldDebugAttrs}>
          <FieldComponent
            blockId={blockId}
            doc={doc}
            fieldPath={fieldPath}
            path={fieldUserDataPath}
          />
        </div>
      </Suspense>
    );
  }

  if (type === "ChoiceField" && showResponses === "ALL") {
    // TODO: Lazy load this once we fix lazy loading support in our docker-compose setup
    return <ChoiceFieldAggregate {...field} doc={doc} fieldPath={fieldPath} page={page} />;
  }

  if (showResponses === "ONE") {
    const componentPath = [DATA_PATH, accessId, id];
    return (
      <Suspense fallback={null}>
        <div className={fieldClassName} {...fieldDebugAttrs}>
          <FieldComponent blockId={blockId} doc={doc} fieldPath={fieldPath} path={componentPath} />
        </div>
      </Suspense>
    );
  }

  const allAccessIds = Object.keys(get(doc.data, DATA_PATH)).filter(canRollupAccessId);
  const visibleAccessIds = allAccessIds
    .filter((currAccessId) => {
      // Skip access ids of the wrong type (e.g., student access ids on a group page or vice-versa).
      if (!isValidAccessIdForPageType(page, currAccessId)) {
        return false;
      }

      // For group Pages, skip access ids that refer to groups that are not in the current PageGroup
      // data. If the Page was imported from another environment, compensate for missing PageGroups
      // by allowing this type of access id.
      if (isGroupPage(page) && !pageGroupAccessIds.includes(currAccessId) && !page?.is_imported) {
        return false;
      }

      // Skip any access ids with empty entries for the given cell.
      const componentPath = [DATA_PATH, currAccessId, id];
      return !isFieldEmpty(type, get(doc.data, componentPath) as CellType);
    })
    .sort((a, b) => {
      const aPageGroupIndex = pageGroupAccessIds.indexOf(a);
      const bPageGroupIndex = pageGroupAccessIds.indexOf(b);

      // If there are group responses, display groups over individual responses.
      if (aPageGroupIndex !== -1 && bPageGroupIndex === -1) return -1;
      if (aPageGroupIndex === -1 && bPageGroupIndex !== -1) return 1;
      if (aPageGroupIndex === -1 && aPageGroupIndex === -1) return a.localeCompare(b);
      return aPageGroupIndex - bPageGroupIndex;
    });

  if (visibleAccessIds.length === 0) {
    return <div className="multiple-responses-block-container px-2">{t("field.no_responses")}</div>;
  }

  return (
    <div className="multiple-responses-block-container">
      {visibleAccessIds.map((currAccessId, index) => {
        const componentPath = [DATA_PATH, currAccessId, id];
        const studentFieldId = `${currAccessId}-${id}`;
        const emptyField = isFieldEmpty(type, get(doc.data, componentPath) as CellType);
        const groupIndex = pageGroupAccessIds.indexOf(currAccessId);
        return (
          <div
            className="multiple-responses-block-single-response"
            // Used for marker scrolling
            id={`userblock-${blockId}-${currAccessId}`}
            key={studentFieldId}
          >
            <HoverableToolbar uncentered>
              <div className="w-full">
                <DocAuthorList
                  accessId={currAccessId}
                  groupIndex={groupIndex === -1 ? undefined : groupIndex}
                  page={page}
                />

                {!emptyField && (
                  <Suspense fallback={null}>
                    <div className={fieldClassName} {...fieldDebugAttrs}>
                      <FieldComponent
                        blockId={blockId}
                        doc={doc}
                        fieldPath={fieldPath}
                        path={componentPath}
                      />
                    </div>
                  </Suspense>
                )}

                {index !== visibleAccessIds.length - 1 && <div className="my-4" />}
              </div>

              {canFeature && (
                <FeatureButton
                  accessId={currAccessId}
                  blockId={blockId}
                  isRollup={Boolean(showResponses)}
                  pageId={pageId}
                />
              )}
            </HoverableToolbar>
          </div>
        );
      })}
    </div>
  );
};
