import { ReactNode } from "react";

import { CircleDisabledIcon, PersonJoinedIcon, PersonSingleIcon } from "mds/icons";
import { CourseUserActivityStatus, UserWithCourseActivity } from "store/selectors";

export const userActivityIcons: Record<CourseUserActivityStatus | "disabled", ReactNode> = {
  active: <PersonJoinedIcon color="green" />,
  inactive: <PersonJoinedIcon color="green" />,
  offline: <PersonSingleIcon />,
  disabled: <CircleDisabledIcon />,
};

export const getActiveStateSortKeyForUser = (user: UserWithCourseActivity) => {
  return ["active", "inactive", "offline"].indexOf(user.courseActivityStatus) > 1 ? 1 : 0;
};

export const isUserOnline = (status: CourseUserActivityStatus) =>
  status === CourseUserActivityStatus.ACTIVE || status === CourseUserActivityStatus.INACTIVE;
