// Types from objects received from `server`.
// For now we are keeping the snake casing on the types shared with the server to be easy to lookup
// objects across the client and server. However, we could later potentially introduce
// a client-side API middleware to convert between the casing.

import { CodeKernelLanguage } from "../worksheets/shared/code-kernels";
import { BlockType, WorksheetType } from "../worksheets/shared/types/worksheet";

import { ModelType } from "utils/type-utils";

// TODO: This should be used for a generic API wrapper that allows to easily fetch previous/next
export type QuerySetResult<T> = {
  count: number;
  next: null;
  previous: null;
  results: T[];
};

// Should be in sync with org_user.py
// TODO: These roles are preliminary, we haven't decided on the roles yet, but just distinguish between
// admin and non-admin for now. Update the selectIsOrgAdmin function if this changes.
export type OrgRoleType = "admin" | "other";

// Should be in sync with course_user.py
// TODO: These roles are preliminary. Update selectIsCourseAdmin function if this changes.
export type CourseRoleType = "admin" | "instructor" | "student";

export type CoursePageCategory = "assignment" | "course_resource" | "instructor_workspace";

export const coursePageCategories: CoursePageCategory[] = [
  "assignment",
  "course_resource",
  "instructor_workspace",
];

export type PageCategory = CoursePageCategory | "activity_page";

export enum GroupingCategory {
  INDIVIDUAL = "individual",
  SHARED_CLASS = "all",

  // Instructors assign students to groups or students self-assign their groups.
  ASSIGN_STUDENTS = "assign_students",
  SELF_ASSIGN = "self_assign",
}

export enum AssessmentStatus {
  NOT_ASSESSABLE = "not_assessable",
  ASSESSABLE = "assessable",
  ASSESSED = "assessed",
}

export interface PageType extends ModelType {
  category: PageCategory;
  title: string;
  order: number;
  worksheet_id: string; // `collaboration` worksheet CUID
  released_at: string;
  last_rolled_up_at: string;
  instructor_notes_worksheet_id: string;
  grouping_category: GroupingCategory;
  max_points: number;
  course_id: string;
  is_imported: boolean;

  // Optional fields based on grouping category.
  max_group_size?: number;

  // Optional fields based on type of page.
  activity_id?: string;
  progenitor_id?: string;
  due_at?: string;
  late_submission_interval?: string;
  assessments_published_at?: string;
  locked_at?: string;
}

export type ActivityPageType = PageType & {
  category: "activity_page";
  activity_id: string;
};
export type CoursePageType = PageType & {
  category: CoursePageCategory;
  course_id: string;
};

export enum ActivityCategory {
  PREP = "prep",
  LESSON = "lesson",
  ADDITIONAL = "additional_resource",
  ARCHIVE = "archive",
}

export type ActivityCategoryType = keyof ActivityCategory;
export type LessonOrArchiveActivityCategory = ActivityCategory.LESSON | ActivityCategory.ARCHIVE;

export interface ActivityType extends ModelType {
  order: number;
  topic_id: string;
  title: string;
  estimated_time: number;

  /** A `prep` activity type only contains up to one page. There is
   *  only one `prep` and `additional_resource` activity type per topic/topic.
   *  The `order` field has no effect for a `prep` activity type.
   */
  category: ActivityCategory;
  progenitor_id?: string;
}

export type ReleaseStatus = "released" | "partially_released" | "unreleased";

// This type does not have any related objects in it, i.e. `activities`.
export interface TopicType extends ModelType {
  title: string;
  category: string;
  course_id?: string;
  order: number;
  progenitor_id?: string;
  // Note: this being a computed field on the backend and not derived client-side from page data,
  // means it's not picked up by pubsub when pages are updated, so it might become stale.
  release_status?: ReleaseStatus;
}

// This is the format that we do activity import/export in, which is similar to the ActivityType
// but contains fully serialized worksheet data.
export type StoredActivityType = {
  title: string;
  order: number;
  category: ActivityType["category"];
  pages: { title: string; order: number; category: string; worksheetBlocks: BlockType[] }[];
};

export type UserInformationType = {
  email: string;
  first_name: string;
  last_name: string;
};

export type UserType = UserInformationType &
  ModelType & {
    is_superuser: boolean;
  };

export interface CourseCodeType extends ModelType {
  title: string;
  order: number;
}

export interface TermType extends ModelType {
  title: string;
  order: number;
}

export interface CourseType extends ModelType {
  title: string;
  description: string;
  course_code_id?: string;
  term_id?: string;
  progenitor_id?: string;
  anonymous_grading_enabled: boolean;
  student_ai_assistant_enabled: boolean;
  code_blocks_enabled: boolean;
  code_blocks_default_language: CodeKernelLanguage;
  lms_section_id: number;
  course_version: number;
}

export type FullCourseType = CourseType & {
  full_title: string;
};

export interface CourseInviteType extends ModelType {
  course_id: string;
  created_by_id: string;
  invite_key: string;
  created_at: string;
}

export interface CourseUserType extends ModelType {
  course_id: string;
  user_id: string;
  role: CourseRoleType;
  creation_source: "lms" | "manual";
  is_disabled: boolean;
}

export interface OrgUserType extends ModelType {
  org_id: string;
  user_id: string;
  role: OrgRoleType;
}

export interface ClassSessionType extends ModelType {
  course_id: string;
  presenting_user_id?: string;
  presenting_rollup: boolean;
  presented_page_id?: string;
  presented_access_id?: string;
  presented_block_id?: string;
  presenting_outcomes?: boolean;
  is_practice: boolean;
  is_projecting: boolean;
}

export type OrgRubricType = {
  name: string;
  score: number;
  conversion_percent: number;
};

export interface OrgType extends ModelType {
  current: boolean;
  name: string;
  short_name: string;
  rubric: OrgRubricType[];
  courses_can_change_rubric_conversion_percent: boolean;
}

export type RubricDescriptionType = string;

export interface OutcomeType extends ModelType {
  order: number;
  title: string;
  description?: string;
  outcome_group_id?: string;
  outcome_subgroup_id?: string;
  rubric_descriptions?: RubricDescriptionType[];
}

export interface OutcomeSubgroupType extends ModelType {
  order: number;
  outcome_group_id: string;
  title: string;
}

export interface OutcomeGroupType extends ModelType {
  order: number;
  title: string;
}

export interface CourseOutcomeType extends ModelType {
  course_id: string;
  outcome_id: string;
  rubric_descriptions?: RubricDescriptionType[];
}

export interface PageOutcomeType extends ModelType {
  course_outcome_id: string;
  page_id: string;
  rubric_descriptions?: RubricDescriptionType[];
}

export interface PageGroupType extends ModelType {
  page_id: string;
  created_at_ms: number;
}

export interface PageGroupUserType extends ModelType {
  page_group_id: string;
  course_user_id: string;
  is_ready: boolean;
}

export type DjangoExtendedModelType<T extends ModelType, Prefix extends string> = {
  [key in keyof T as `${Prefix}__${string & key}`]: T[key];
};

export type ReferentialModelType<T extends ModelType> = T & {
  [K: `${string}__${string}`]: unknown;
};

// Keep in sync with ActivityViewSet's filterset_fields.
export type ActivityListFilterType = {
  topic_id: string;
  category: ActivityCategory;
  topic__course_id: string;
};

// Keep in sync with PageViewSet's filterset_fields.
export type PageListFilterType = {
  released_at__isnull: boolean;
  max_points__gt: number;
  category: PageCategory;
  activity_id: string;
  course_id: string;
  activity__topic_id: string;
  pageoutcome__course_outcome_id: string;
  assessments_published_at__isnull: boolean;
  assessments_published_at__gt: string;
  assessments_published_at__lt: string;
};

// Keep in sync with PageOutcomeViewSet's filterset_fields.
export type PageOutcomesListFilterType = {
  page_id: string;
  page_id__in: string[];
  course_outcome_id: string;
  course_outcome_id__in: string[];
  page__course_id: string;
};

// Keep in sync with PageGroupViewSet's filterset_fields.
export type PageGroupListFilterType = {
  page_id: string;
  page_id__in: string[];
  page__course_id: string;
  pagegroupuser__course_user_id: string;
  pagegroupuser__course_user_id__in: string[];
  pagegroupuser__course_user__course_id: string;
};

// Keep in sync with PageGroupUserViewSet's filterset_fields.
export type PageGroupUserListFilterType = {
  page_group_id: string;
  page_group__page_id: string;
  page_group__page_id__in: string[];
  page_group__page__course_id: string;
  course_user__course_id: string;
  course_user_id: string;
};

// Keep in sync with OrgUserViewSet's filterset_fields.
export type OrgUserListFilterType = {
  user_id: string;
  org_id: string;
  role: OrgRoleType;
};

// Keep in sync with OutcomeAssessmentViewSet's filterset_fields.
export type OutcomeAssessmentListFilterType = {
  assessment_id: string;
  page_outcome_id: string;
  assessment__submission__page_id: string;
  assessment__submission__page_id__in: string[];
  assessment__submission__page__course_id: string;
};

// Keep in sync with SubmissionViewSet's filterset_fields.
export type SubmissionListFilterType = {
  page_id: string;
  page_id__in: string[];
  course_user_id: string;
  page__course_id: string;
  page_group_id: string;
  page_group_id__in: string[];
  page_group__pagegroupuser__course_user_id: string;
  page_group__pagegroupuser__course_user_id__in: string[];
  official_at__isnull: boolean;
  official_at__gt: string;
  official_at__lt: string;
};

// Keep in sync with AssessmentViewSet's filterset_fields.
export type AssessmentListFilterType = {
  submission_id: string;
  submission_id__in: string[];
  submission__page_id: string;
  submission__page_id__in: string[];
  submission__page__course_id: string;
};

export type AIRoleType = "system" | "assistant" | "user";

export type AIModelType =
  | "gpt-4o"
  | "o3-mini"
  | "gpt-4o-mini"
  | "claude-3-5-sonnet-latest"
  | "claude-3-7-sonnet-latest";

export type AIMessageType = {
  role: AIRoleType;
  content: string;
};

// Insert model types - These are models that have additional fields that are not
// actually persisted directly in the database, but are used to create the model.
// Note: it's important to keep these in sync with the server-side views.
export type PageExtendedInsertType = {
  initial_worksheet_data: Partial<WorksheetType>;
  instructor_notes_worksheet_data: Partial<WorksheetType>;
  due_now?: boolean;
};
export type TopicExtendedInsertType = {
  instructor_notes_worksheet_data: Partial<WorksheetType>;
};

export type CsrfTokenType = {
  csrftoken: string;
};

export interface ImportCourseRequestType {
  source_course_id: string;
  target_course_id: string;
  include_topics?: boolean;
  include_assignments?: boolean;
  include_course_resources?: boolean;
  include_workspace?: boolean;
}

export interface SubmissionType extends ModelType {
  official_at?: string;
  created_at: string;
  page_id: string;
  page_group_id?: string;
  course_user_id?: string;
  worksheet_sharedb_version: number;
}

export interface AssessmentType extends ModelType {
  comment?: string;
  points?: number;
  submission_id: string;
  assessed_by_id: string;
  created_at: string;
}

// AssessmentType with fields required for creating or updating an assessment
export interface FullAssessmentType {
  page_outcomes?: Record<string, number>;
  is_official?: boolean;
}

export interface OutcomeAssessmentType extends ModelType {
  page_outcome_id: string;
  assessment_id: string;
  score: number;
}

export interface ResponseType {
  title: string;
  id: string;
  order?: number;
}

export enum AtRiskLevel {
  HIGH = "high",
  MEDIUM = "medium",
  LOW = "low",
}

export interface AtRiskData {
  data: AtRiskScore[];
  reasons: AtRiskMeasurabilityReason[];
}

export enum AtRiskMeasurabilityReason {
  NO_EFFORT_DATA = "no_effort_data",
  BELOW_CV = "below_cv",
  BELOW_MEDIAN_MINIMUM = "below_median_minimum",
  BELOW_OUTCOME_ASSESS_MINIMUM = "below_outcome_assessed_minimum",
}

export interface AtRiskScore {
  user_id: string;
  effort_score: number;
  effort_z_score: number;
  effort_risk_level: AtRiskLevel;
  performance_score: number;
  performance_risk_level: AtRiskLevel;
}

export type AtRiskOptions = Partial<{
  start_date: string;
  end_date: string;

  individual_only: boolean;
  // Effort options
  multiple_choice_answer_factor: number;
  minimum_median_effort_score: number;
  minimum_coefficient_of_variation: number;
  high_risk_z_score: number;
  medium_risk_z_score: number;
  // Performance options
  minimum_outcomes_assessed: number;
  high_risk_mean_score: number;
  medium_risk_mean_score: number;
}>;

export type InviteKeyCourseType = {
  title: string;
};

// Keep this in sync with server's raise_formatted_validation_exception
export type FormattedValidationError<TErrorKey = unknown> = {
  [field: string]: {
    details: string | string[];
    t?: TErrorKey | TErrorKey[];
  };
};

export type PageAssessmentStatusType = Record<string, AssessmentStatus>;
