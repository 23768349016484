import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const FolderIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="fill"
      d="M13.5998 3.99998H7.23931L6.18464 2.41507C6.00946 2.15469 5.72824 2.00035 5.42869 2H2.40004C2.02873 2 1.67269 2.15805 1.4101 2.4394C1.14752 2.72076 1 3.10222 1 3.50005V13.5C1 13.8978 1.14751 14.2794 1.4101 14.5606C1.6727 14.842 2.02873 15 2.40004 15H13.6C13.9713 15 14.3274 14.842 14.5899 14.5606C14.8525 14.2794 15 13.8978 15 13.5V5.50016C15 5.10233 14.8525 4.72072 14.5899 4.43951C14.3274 4.15815 13.9712 3.99998 13.5998 3.99998ZM2.39992 2.99999H5.42858L6.09596 3.99998H2.3998C2.2406 4.00134 2.08279 4.03186 1.93308 4.09004V3.50005C1.93308 3.36743 1.98229 3.24028 2.06982 3.14649C2.15735 3.0527 2.27614 2.99999 2.39992 2.99999ZM14.0664 13.4998C14.0664 13.6324 14.0172 13.7595 13.9296 13.8533C13.8421 13.9471 13.7234 13.9998 13.5997 13.9998H2.39973C2.27596 13.9998 2.15728 13.9471 2.06974 13.8533C1.98221 13.7595 1.933 13.6324 1.933 13.4998V5.49997C1.933 5.36735 1.98221 5.2402 2.06974 5.14641C2.15727 5.05262 2.27595 4.9999 2.39973 4.9999H13.5997C13.7234 4.9999 13.8421 5.05262 13.9296 5.14641C14.0172 5.24019 14.0664 5.36735 14.0664 5.49997V13.4998Z"
    />
  </svg>
);
