import { FC } from "react";

import { IconProps, initializeSvgIconProps } from "mds/utils/images";

export const MultipleChoiceSingleIcon: FC<IconProps> = (props) => (
  <svg {...initializeSvgIconProps(props)}>
    <path
      className="stroke"
      d="M9.3999 4.04999H14.9999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle className="stroke" cx="3.9" cy="4.15" r="2.9" />
    <path
      className="stroke"
      d="M9.3999 12.45H14.9999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M1 12.75L3 14.75L7 9.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
