import "components/outcomes/RubricList.scss";
import clsx from "clsx";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import type { FC } from "react";

import { OutcomeAssessmentType, OutcomeType, RubricDescriptionType } from "components/server-types";
import { t } from "i18n/i18n";
import { Button } from "mds/components/Button";
import { CancelSaveWidget } from "mds/components/CancelSaveWidget";
import { BaseTextArea } from "mds/components/TextArea";
import { TextField } from "mds/components/TextField";
import { PencilEditIcon, ScoreIcon } from "mds/icons";
import { storeApi, useAppSelector } from "store/index";
import {
  FullRubricType,
  NestedCourseOutcomeType,
  NestedPageOutcomeType,
  selectCanAuthorCourse,
} from "store/selectors";
import { FeatureStatus, usePresentation } from "utils/presentation";

interface RubricActionBarProps {
  canEdit: boolean;
  isEditing: boolean;
  outcomeItem: NestedPageOutcomeType | NestedCourseOutcomeType | OutcomeType;

  /**
   * The descriptions that the user is currently editing.
   */
  editedRubricDescriptions: string[];

  /**
   * The descriptions that are displayed when the user is not in edit mode.
   */
  actualRubricDescriptions: string[];
  setIsEditing: (isEditing: boolean) => void;
}

/**
 * An action bar for the rubric that includes a reset button and an edit button.
 *
 * When the edit button is clicked, the user can edit the rubric description. The edit button turns into a
 * Cancel / Save widget so that the descriptions can be saved once the user is done editing.
 *
 * The reset button is only shown if the user is not editing the rubric descriptions and the outcome item's
 * "rubric" field is not null. NOTE: This "rubric" field on the outcome item should NOT be used for display.
 * When it is null, this means that the rubric descriptions are inherited from another outcome item, and we
 * should use our selectors to determine which rubric descriptions to display. */
const RubricActionBar: FC<RubricActionBarProps> = ({
  outcomeItem,
  isEditing,
  setIsEditing,
  canEdit,
  editedRubricDescriptions,
  actualRubricDescriptions,
}) => {
  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);

  const modelType =
    "outcome" in outcomeItem && "course_outcome" in outcomeItem
      ? "page_outcomes"
      : "outcome" in outcomeItem
        ? "course_outcomes"
        : "outcomes";

  const onRubricReset = () => {
    storeApi[modelType].partial_update({
      id: outcomeItem.id,
      rubric_descriptions: null,
    });
  };

  const onSaveDescriptions = (newRubricDescriptions: RubricDescriptionType[]) => {
    storeApi[modelType].partial_update({
      id: outcomeItem.id,
      rubric_descriptions: newRubricDescriptions,
    });
  };

  const onSave = () => {
    setIsEditing(false);
    const trimmedRubricDescriptions = editedRubricDescriptions.map((description) =>
      (description || "").trim(),
    );
    if (canEdit && !isEqual(trimmedRubricDescriptions, actualRubricDescriptions)) {
      onSaveDescriptions(trimmedRubricDescriptions);
    }
  };

  return canAuthorCourse ? (
    <div className="flex gap-1">
      {/* TODO: Add a tooltip for this reset button. */}
      {!isEditing && outcomeItem.rubric_descriptions && (
        <Button kind="tertiary" size="xs" onClick={onRubricReset}>
          {t("rubric_list.reset_rubric")}
        </Button>
      )}
      {!isEditing ? (
        <Button
          kind="tertiary"
          size="xs"
          title={t("tooltip.edit_rubric")}
          iconOnly
          onClick={() => setIsEditing(true)}
        >
          <PencilEditIcon />
        </Button>
      ) : (
        <CancelSaveWidget
          size="xs"
          onClickCancel={() => setIsEditing(false)}
          onClickSave={onSave}
        />
      )}
    </div>
  ) : null;
};

interface RubricListProps {
  className?: string;
  outcomeItem?: NestedPageOutcomeType | NestedCourseOutcomeType | OutcomeType;
  readOnly?: boolean;

  /**
   * The full rubric for this outcome item. Not to be confused with the "rubric" field on an outcome item, which is
   * a list of rubric descriptions. This "rubric" prop should include any inherited rubric descriptions. See
   * selectPageOutcomeRubric, selectCourseOutcomeRubric, and selectOrgOutcomeRubric selectors for more details.
   *
   * A page outcome item may inherit its rubric descriptions from a course outcome item or an org outcome item.
   * A course outcome item may inherit its rubric descriptions from an org outcome item.
   * An org outcome item may inherit its rubric descriptions from the default rubric descriptions.
   */
  rubric: FullRubricType[];

  /**
   * Whether to show the "Rubric" title with the RubricActionBar. Defaults to true. If false, the RubricActionBar
   * will be shown next to the first rubric item.
   */
  showHeader?: boolean;
  outcomeAssessment?: OutcomeAssessmentType;
}

export const RubricList: FC<RubricListProps> = ({
  className = "",
  outcomeItem,
  readOnly,
  rubric,
  outcomeAssessment,
  showHeader = true,
}) => {
  const rubricClassName = clsx("w-full", className);

  const canAuthorCourse = useAppSelector(selectCanAuthorCourse);

  const canEdit = !readOnly && canAuthorCourse;
  const actualRubricDescriptions = rubric?.map((rubricItem) => rubricItem.description);
  const { featureStatus } = usePresentation();
  const showScoreIds = featureStatus === FeatureStatus.OUTCOME;

  const [isEditing, setIsEditing] = useState(false);
  const [rubricDescriptions, setRubricDescriptions] = useState(actualRubricDescriptions);

  useEffect(() => {
    if (!isEditing) {
      setRubricDescriptions(rubric?.map((rubricItem) => rubricItem.description));
    }
  }, [isEditing, rubric]);

  const onDescriptionChange = (newValue: string, index: number) => {
    const newRubricDescriptions = [...rubricDescriptions];
    newRubricDescriptions[index] = newValue;
    setRubricDescriptions(newRubricDescriptions);
  };

  const onDoubleClick = () => {
    if (canEdit) {
      setIsEditing(true);
    }
  };

  if (!rubric || !rubricDescriptions) return null;

  return (
    <div className={rubricClassName}>
      {showHeader && (
        <div className="flex items-center justify-between pb-2">
          <div className="h3 text-black-tint-30">{t("glossary.rubric")}</div>
          <RubricActionBar
            actualRubricDescriptions={actualRubricDescriptions}
            canEdit={canEdit}
            editedRubricDescriptions={rubricDescriptions}
            isEditing={isEditing}
            outcomeItem={outcomeItem}
            setIsEditing={setIsEditing}
          />
        </div>
      )}

      {rubric.map((rubricItem, index) => (
        <div
          className={clsx("rubric-list-item flex h-full w-full flex-col", {
            [`bg-score-light-${outcomeAssessment?.score}`]:
              rubricItem.score === outcomeAssessment?.score,
          })}
          key={rubricItem.score}
        >
          <div className="flex items-center justify-between">
            <div
              className="score-and-title flex items-center gap-1"
              id={showScoreIds ? `outcome-${outcomeItem.id}-${rubricItem.score}` : undefined}
            >
              <ScoreIcon value={rubricItem.score} />
              <TextField size="h4" value={rubricItem.name} readOnly />
            </div>

            {index === 0 && !showHeader && canEdit && (
              <RubricActionBar
                actualRubricDescriptions={actualRubricDescriptions}
                canEdit={canEdit}
                editedRubricDescriptions={rubricDescriptions}
                isEditing={isEditing}
                outcomeItem={outcomeItem}
                setIsEditing={setIsEditing}
              />
            )}
          </div>

          <div className="description flex h-full">
            <BaseTextArea
              className="text-sm"
              divProps={{ onDoubleClick }}
              readOnly={!canEdit || !isEditing}
              value={rubricDescriptions[index]}
              onChange={(e) => isEditing && onDescriptionChange(e.target.value, index)}
              onDoubleClick={onDoubleClick}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
